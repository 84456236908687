import { connect } from 'react-redux';
import React from 'react';
import Table from '../../../components/widgets/etable/custom';
import PageComponent from '../../../components/PageComponent';
import RenderLawyers from "../../../components/widgets/RenderLawyers";
import Props from '../../../components/widgets/etable/props';
import { Link } from 'react-router';
import { injectReducer } from '../../../store/reducers';
import axios from 'axios';
import Toaster from '../../../components/widgets/Toaster';

class Mylawya extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {
      user:[],
      lawyer:[],
      law: '',
      ticket:[],
      isVisible: false,
      isVerified: "Unverify",
      messages: 0,
      mylawyer:[],
      inbox:[],
      reply: '',
      response:'',
      id:''
    }
  }
  componentDidUpdate() {
   // this.setState({reply: this.state.law.reply || ""});
  }
  componentWillMount() {
    
    this.fetchLawyers();
    //<RenderLawyers lawyer={law} id={law.id} onVerify={this.verify} verifyText={this.state.isVerified} onExpand = {this.expand} onUnverify={this.unverify} key={index}/>
  }

  fetchLawyers = () => {
    fetch('https://immense-tor-56766.herokuapp.com/api/my_lawyer/read.php')
    .then(res => {
      console.log(res)
      return res.json();
    }).then(data=>{
      console.log(JSON.stringify(data))
      this.setState({mylawyer:  this.state.mylawyer.concat((data.records))});
      console.log(this.state.mylawyer);
    })
  }

  fetchLawyer = (id) => {
    fetch('https://immense-tor-56766.herokuapp.com/api/my_lawyer/read_one.php?id='+id)
    .then(res => {
      console.log(res)
      return res.json();
    }).then(data=>{
      this.setState({law:  data});
      console.log(this.state.law);
    })
  }

  handleChange = (e) => {
    const { name, value, checked } = e.target || e.srcElement;


    switch(name) {
        case 'reply':
            this.setState({reply: value})
            break;
        default:
          break;
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const response = this.state.reply;
    this.setState({response})
    const data = {
      id: this.state.id,
      reply: this.state.reply
    }
    axios({
      url: 'https://cors-anywhere.herokuapp.com/https://immense-tor-56766.herokuapp.com/api/my_lawyer/update.php',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data
    }).then(function(res){
      console.log(res.status);
      if((res.status == 200) || (res.status== 201)) {
        console.log(res.status);
        Toaster.success("Your message has been sent");
      } else {
        console.log(res.status, res);
    }
    }).catch(function(err){
      console.error(err)
    });

  }

  expand = (e) => {
    const id = e.target.id;
    this.fetchLawyer(id);
    this.setState({id});
    this.setState({isVisible: true});
  }

  unexpand = () => {
    this.setState({isVisible: false})
    this.setState({law: ""})
  }

  verify = (e) => {
    const ID = e.target.id;
    Toaster.info('waiting...');
    axios.get('https://cors-anywhere.herokuapp.com/https://immense-tor-56766.herokuapp.com/api/lawyer/verify_lawyer.php?id='+ID)
      .then(res => {
        Toaster.success('verified');
        this.setState({lawyer: []});
        this.fetchLawyers();
      });
    
  }

  unverify = (e) => {
    console.log(e.target.id);
  }

 



  getAuthType = () => ['admin'];

  render() {
    return (
      <section className="landing-page-section">
      <div className="landing-header">

      </div>
     



          <div className="w3-bar w3-top w3-black w3-large" style={{zIndex:'4'}}>
            <button className="w3-bar-item w3-button w3-hide-large w3-hover-none w3-hover-text-light-grey" onClick={this.handleSubmit}><i className="fa fa-bars"></i>Menu</button>
            <span className="w3-bar-item w3-right">LERU</span>
          </div>

          <nav className="w3-sidebar w3-collapse w3-white w3-animate-left" style={{zIndex:'3',width:"150px"}} id="mySidebar"><hr />
            <div className="w3-container w3-row">
              <div className="w3-col s4">
                <img src="/w3images/avatar2.png" className="w3-circle w3-margin-right" style={{width:"46px"}} />
              </div>
              <div className="w3-col s8 w3-bar">
                <span>Welcome</span><hr />
                <a href="#" className="w3-bar-item w3-button"><i className="fa fa-envelope"></i></a>
                <a href="#" className="w3-bar-item w3-button"><i className="fa fa-user"></i></a>
                <a href="#" className="w3-bar-item w3-button"><i className="fa fa-cog"></i></a>
              </div>
            </div>
            <hr />
            <div className="w3-container">
              <h5>Admin Area</h5>
            </div>
            <div className="w3-bar-block">
              <a href="#" className="w3-bar-item w3-button w3-padding-16 w3-hide-large w3-dark-grey w3-hover-black" onClick={this.handleSubmit} title="close menu"><i className="fa fa-remove fa-fw"></i>  Close Menu</a>
              <a href="#" className="w3-bar-item w3-button w3-padding w3-blue"><i className="fa fa-users fa-fw"></i>  Overview</a>
              <a href="#" className="w3-bar-item w3-button w3-padding"><i className="fa fa-eye fa-fw"></i>  Views</a>
              <a href="#" className="w3-bar-item w3-button w3-padding"><i className="fa fa-users fa-fw"></i>  Traffic</a>
              <a href="#" className="w3-bar-item w3-button w3-padding"><i className="fa fa-bank fa-fw"></i>  General</a>
              <a href="#" className="w3-bar-item w3-button w3-padding"><i className="fa fa-history fa-fw"></i>  History</a>
              <a href="#" className="w3-bar-item w3-button w3-padding"><i className="fa fa-cog fa-fw"></i>  Settings</a><hr /><hr />
            </div>
          </nav>


          <div className="w3-overlay w3-hide-large w3-animate-opacity" onClick={this.handleSubmit} style={{cursor:"pointer"}} title="close side menu" id="myOverlay"></div>

          <div className="w3-main" style={{marginLeft:'150px', marginTop:'43px'}}>

            <header className="w3-container" style={{paddingTop:'22px'}}>
              <h5><b><i className="fa fa-dashboard"></i>Mylawyer Dashboard</b></h5>
            </header>

            <div className="w3-row-padding w3-margin-bottom">
              <div className="w3-quarter">
                <div className="w3-container w3-red w3-padding-16">
                  <div className="w3-left"><i className="fa fa-comment w3-xxxlarge"></i></div>
                  <div className="w3-right">
                    <h3>{this.state.inbox.length}</h3>
                  </div>
                  <div className="w3-clear"></div>
                  <h4>inbox</h4>
                </div>
              </div>
              
              <div className="w3-quarter">
                <div className="w3-container w3-teal w3-padding-16">
                  <div className="w3-left"><i className="fa fa-gavel w3-xxxlarge"></i></div>
                  <div className="w3-right">
                    <h3>{this.state.mylawyer.length}</h3>
                  </div>
                  <div className="w3-clear"></div>
                  <h4>Mylawyer</h4>
                </div>
              </div>
              <div className="w3-quarter">
                <div className="w3-container w3-orange w3-text-white w3-padding-16">
                  <div className="w3-left"><i className="fa fa-users w3-xxxlarge"></i></div>
                  <div className="w3-right">
                    <h3>{this.state.user.length}</h3>
                  </div>
                  <div className="w3-clear"></div>
                  <h4>Users</h4>
                </div>
              </div>
            </div>

          
            
            

            <div>
            <div className="w3-container" style={{textAlign: "center"}}>
              <h5>My Lawyer</h5>
              <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.mylawyer.map(((law, index)=><tr key={index}>
                    <td>{law.name}</td>
                    <td>{law.email_addr}</td>
                    <td>{law.legex_id}</td>
                    <td><button id={law.id} className="w3-btn w3-green" onClick={this.expand}>Reply</button></td>
                  </tr> 
                    ))
                  }
                </tbody>
                  
              </table>
              <hr />
              
            </div>
            <hr />
            </div>
            <hr />
            {
                    this.state.isVisible? 
                    <div className="w3-modal" style={{display: this.state.isVisible?"block":"none", color: "white", }}>
                      <div className="w3-modal-content w3-card-4" style={{height: '280px'}}>
                        <header className="w3-container w3-green"> 
                          <span onClick={this.unexpand}
                          className="w3-button w3-display-topright">&times;</span>
                          <h2>chat menu - {this.state.law.name}</h2>
                        </header>
                        <div className="w3-container">
                          <div style={{width: "100%", margin:"8px", padding:"8px", fontWeight: "bold",  background: "tomato", color:"white", borderRadius: "5px" }}>
                          <p>{this.state.law.message}</p>
                          </div>
                          {this.state.law.reply !== "null" || this.state.response !== "" ?
                          <div style={{width: "100%", margin:"8px", padding:"8px", fontWeight: "bold", background: "green", color:"white", borderRadius: "5px" }}>
                          <p>{this.state.law.reply == "null"? this.state.response : this.state.law.reply}</p>
                          </div>:''
                          }
                        </div>
                        <footer className="w3-container" style={{marginTop:"50px"}}>
                        <form onSubmit={this.handleSubmit}>
                          <input type="text"  autoComplete="off" className="pt-input pt-fill" style={{width:"90%"}} onChange={this.handleChange} name="reply" required />
                          <button className="pt-button pt-intent-primary pull-right"  style={{width: "65px", height: "65px", borderRadius:"50%"}}>Send</button>
                        </form>
                        </footer>
                      </div>
                    </div>:
                    ""
                  }
            </div>
      </section>
    )
  }
  
};

export default store =>
  ({
    path: `/admin/mylawya`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ ...state }))(Mylawya)
      );
    }
  });