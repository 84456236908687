import React from 'react';

export default class TermsContent extends React.Component {
  render() {
    return (
      <div className="StaticPage">
        <div className="page-content">
          <div>
            <h1 className="text-center">TERMS OF SERVICE</h1>
            <div className="m-t-4">
              <p>This page states the Terms and Conditions (<strong>Terms</strong>) under which you may use the website www.mylaw.ng or mobile apps available for download (referred to as the <strong>Site</strong>). Please read these Terms carefully as it governs your access to and use of the Site and apply to all services and sub-sites available under the domain name mylaw.ng, as well as all text, audio, video, images, graphics, materials, online communications and other information and content that are or become available on the Site (collectively, the &ldquo;Information&rdquo;). By using the Site, you (&ldquo;you&rdquo; or &ldquo;User&rdquo;) signify your acceptance of these Terms and your acknowledgement that the information that you provide, directly or indirectly, through the Site will be managed in accordance with the mylaw Privacy Policy. As such, if you do not accept the Terms stated here, do not use the Site.</p>

              <p>The Site contains certain services and related features that may require registration or subscription. If you open and operate an account on the Site, you shall ensure that you are lawfully authorized to provide all information submitted by you and all such information is true and accurate. You are responsible for updating all information supplied once there is any change. mylaw reserves the right to suspend or terminate your access to any account created or any restricted portion of the Site should it discover that any information provided by you is no longer true or accurate.</p>

              <p>You are entirely responsible for all activities that occur under such account and you are solely responsible for keeping details of such password and account safe and secure at all times. If you detect or become aware of any unauthorized activity or use of your account or password on the Site, you shall immediately notify mylaw of same to enable mylaw immediately disable access to such account.</p>

              <p>mylaw shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with, use of your account on the Site or failure to comply with these Terms and Conditions.</p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <p><strong>General&nbsp;</strong></p>

              <p>mylaw facilitates access to competent, fit for purpose and affordable legal services on a technology platform. Legal services (the Services) which can be accessed via mylaw are broadly categorized as follows:&nbsp;</p>

              <ul>
                <li><strong>Customised Document Generation/</strong> <strong>Document Repository App </strong>&ndash; enables users generate documents through an interactive process. This application allows users input specific information to be used to create an executable document with little or no support from a lawyer.<strong>&nbsp; &nbsp;</strong></li>
                <li><strong>Virtual Law Firm</strong> (<strong>LEGEX Partners &amp; Co</strong>) &ndash;<strong> </strong>allows users consult lawyers via chat or telephone.<strong>&nbsp;</strong></li>
                <li><strong>Panel of Law Firms/Lawyers </strong>&ndash; Allows users access lawyers in cases where physical representation is required.<strong>&nbsp; &nbsp;</strong></li>
              </ul>

              <p>Payment for the Services can either be by way of subscription or on a Pay As You Go (PAYG) basis.</p>

              <ol>
                <li><strong>Interpretation and Definitions</strong>


                  <p>For the purposes of this Terms, the following words shall have the meaning provided below:</p>

                  <p><strong>&lsquo;Affiliate&rsquo;</strong> means the Virtual Platform Limited and any other entity that, directly or indirectly through one or more intermediaries, controls, is controlled by, or is under common control with, Virtual Platform Limited.</p>

                  <p><strong>&lsquo;mylaw website&rsquo; or &lsquo;Site&rsquo;</strong> shall mean all that proprietary information contained, prepared, presented and hosted by mylaw at [https//:www.mylaw.ng] and/or any other website so owned by mylaw for the purposes of facilitating access to the Services. &nbsp;</p>

                  <p><strong>&lsquo;Officers&rsquo;</strong> shall where the context so admits mean officers, employees, directors and agents of the organization, company or firm being referred to.&nbsp;</p>

                  <p><strong>&lsquo;Participating Attorneys&rsquo; </strong>means any and all registered lawyers and/or law firms on the Panel of Law Firms</p>

                  <p><strong>&lsquo;Suppliers&rsquo;</strong> means any individual, firm or organization designated by mylaw or with whom mylaw contracts as a partner, agent and such other relationships for the purpose of providing or distributing mylaw services to the User. &nbsp;</p>

                  <p><strong>&lsquo;Terms&rsquo; </strong>means the Terms and Conditions contained herein.&nbsp;</p>

                  <p><strong>&lsquo;Virtual Platform Limited</strong> means the body corporate with rights and ownership to mylaw and all such services as are provided on the mylaw website, which expression shall include its successors in title and assigns.</p>

                  <p><strong>&lsquo;User&rsquo;</strong> shall mean any and all visitors, and all registered accountholders on the mylaw website.</p>

                  <p>&nbsp;</p>

                </li>
                <li>
                  <strong>Contractual Relationship</strong>

                  <ol>
                    <li>These Terms govern your access to, and use of mylaw operated by Virtual Platform Limited.&nbsp;</li>
                    <li>By continuing usage of mylaw, you consent to be bound by these Terms for use of and access to mylaw.&nbsp;</li>
                    <li>The User is required to keep these terms and conditions as they constitute a binding agreement between User and mylaw.</li>
                  </ol>



                  <p>&nbsp;</p>
                </li>
                <li><strong>User&rsquo;s Capacity and Related Matters&nbsp;</strong>
                  <p>By accepting these Terms and/or the continued use of mylaw, the User represents and warrants:</p>
                  <ol>
                    <li>To have attained the legal age of majority in the User&rsquo;s country of residence or otherwise able to form a binding contract with mylaw in order to use the Services. Notwithstanding the foregoing, in no event is use of the Services permitted by those under the age of 18.</li>
                    <li>All information provided by the User on and for the purposes of mylaw are at all times true, complete, accurate, neither fraudulent or misleading in any respect.</li>
                    <li>User further represents and warrants to have the legal authority to accept the Terms herein set out and such acceptance will be binding on the User.&nbsp;</li>
                    <li>mylaw reserves the right in its sole discretion, to refuse to provide the Services to the User in any event that the User is in breach of any provisions of these Terms.&nbsp;</li>
                  </ol>

                  <p>&nbsp;</p>
                </li>
                <li><strong>Acceptance of Terms of Use</strong>
                  <ol>
                    <li>For the avoidance of doubt, use of mylaw and/or any of its Services constitutes acceptance of these Terms, and any subsequent amendments which may be made to the mylaw terms and conditions from time to time.</li>
                  </ol>
                  <p>&nbsp;</p>
                </li>
                <li><strong>Trial Offers, Auto-Renewals and Paid Accounts</strong>

                  <ol>
                    <li>mylaw may, at its sole discretion, make available trial offers for the limited use of mylaw to new or existing Users who provide a valid payment instrument, such as a debit card or other electronic payment method.&nbsp;</li>
                    <li>Any use of additional Services beyond those included in the trial offer, will require a separate purchase of those Services or an upgrade to a paid membership prior to the end of the trial offer period.</li>
                    <li>mylaw reserves the right to automatically charge the User at the end of the trial or for a membership renewal unless the User gives notice of intention to cancel or convert such membership.&nbsp;</li>
                    <li>To enable User conveniently purchase additional products and services, mylaw will store and update (e.g. upon expiration) User payment information.&nbsp;</li>
                    <li>In the event that mylaw is unable to charge the Users designated payment method for any reason, mylaw reserves the right to automatically convert User&rsquo;s paid plan to PAYG account or to suspend the paid membership until the designated payment method can be charged again.&nbsp;</li>
                    <li>For the avoidance of doubt, it is User&rsquo;s responsibility to maintain current billing information with mylaw.</li>
                  </ol>


                  <p>&nbsp;</p>

                </li>
                <li><strong>Payment Obligations</strong>
                  <ol>
                    <li>Upon initiation and confirmation of a transaction on mylaw, the User agrees to be bound by and pay for any such transaction. User is enjoined not to commit to a transaction unless able and ready to pay for and have confirmation of successful payment.&nbsp;</li>
                    <li>User warrants that all payment information provided is accurate, not misleading and not fraudulent in any respect; and all payment transactions for mylaw are final; and non-refundable.</li>
                    <li>To prevent financial loss or possible violations of the law, mylaw reserves the right to in its discretion disclose details of any payment associated with or made by the User, with law enforcement agencies, or impacted third parties (including other users). Such disclosures may originate from an order of any court of competent jurisdiction to compel such disclosure.&nbsp;</li>
                    <li>User agrees and confirms that User will not use mylaw in violation of any law in force in the Federal Republic of Nigeria or such other law which may occasion liability of any sort on mylaw.&nbsp;</li>
                    <li>SMS charges or any such other charges (where applicable) by communication network operators may be applied for each payment transaction conducted on the Site.</li>
                  </ol>


                  <p>&nbsp;</p>

                </li>
                <li><strong>Refund Policy&nbsp;</strong>

                  <ol>
                    <li>mylaw desires the optimum satisfaction of the User regarding services provided on the Site.&nbsp;</li>
                    <li>In the event that the User believes there has been an error in billing, User should contact us by email at info@mylaw.ng or by telephone at 08140000829 immediately so that such issue may be resolved, by providing a refund or offering credit that can be used for future Services.&nbsp;</li>
                    <li>The relevant mode of refund for complaints made will be determined at the sole discretion of mylaw, and the User shall under no circumstances be entitled to any claim arising from or in connection with the refund method chosen by mylaw.&nbsp;</li>
                    <li>When communicating such complaint, User should include any and all details relating to the exact mylaw service paid for. Refund requests must be made within 20 (twenty) days of purchase, provided that, in the event that specific services require different refund request periods, the User shall comply with such refund period.</li>
                  </ol>


                  <p>&nbsp;</p>

                </li>
                <li><strong>Confidentiality</strong>
                  <ol>
                    <li>In compliance with the professional rules and restrictions of the Rules of Professional Conduct, the Legal Practitioners&rsquo; Act and the Nigeria Bar Association, and for reasons of personal integrity, mylaw is bound by stringent professional standards of confidentiality.&nbsp; Any information received by us from Users is held in strict confidence and is not released to anyone outside of this practice, unless agreed with by you, or as required under applicable law.</li>
                    <li>All our records are securely retained in electronic files, along with secure backups.</li>
                  </ol>
                  <p>&nbsp;</p>

                </li>
                <li><strong>Use of Document Repository App&nbsp;</strong>

                  <ol>
                    <li>Information/service provided on the document repository app does not constitute Legal Advice (as defined below). As such, your use of information/service contained in the document repository app does not create a lawyer-client relationship.</li>
                    <li>No legal information provided in the document repository app is a substitute for legal advice from the Virtual Law Firm, Panel of Lawyer/Law Firm or any other lawyer qualified to practice in Nigeria.&nbsp;</li>
                    <li>You agree that all decisions and information you make while creating a document are your full responsibility and you agree to retain legal counsel either from the Virtual Law Firm, Panel of Lawyer/Law Firm or any other lawyer qualified to practice in Nigeria.</li>
                    <li>mylaw does not:&nbsp;
  
                    <ol>
                        <li>review any response provided by a User that generates a document on the Document Repository App for legal accuracy or sufficiency;</li>
                        <li>draw legal conclusions or provide opinions about your selection of documents; or</li>
                        <li>apply the law to the facts of the presented situation.</li>
                        <li>For the purposes of these Terms, legal advice is defined to include the following:</li>
                        <li>any legal related communication, work or service which, under Nigerian Law, is only allowed to be performed by or under the supervision of a lawyer qualified to practice in Nigeria;&nbsp;</li>
                        <li>advice on which legal document or documents you need or are best for your situation;</li>
                        <li>determining the legal consequences that will or could result from how you have created your legal document;</li>
                        <li>whether you have included inappropriate, conflicting, or ambiguous information in your legal documents;</li>
                        <li>whether you have omitted any necessary provisions or details from your legal documents; and</li>
                        <li>whether you require any additional legal documents or legal procedures.</li>
                        <li>When you purchase an agreement or any other document from the document repository app, you are purchasing a perpetual non-transferable, non-assignable license to use the form for your use only.&nbsp; You may not sell, transfer, assign or otherwise give the form or document you have purchased from us to another person for that person&rsquo;s separate use.&nbsp; These restrictions do NOT preclude you from having another person sign or accept a document (such as a lease, deed or agreement, as examples), as long as you are a party to that transaction.&nbsp; You may not allow another person to have or use a legal, business, real estate, or estate planning form or other document, or paper or electronic copies thereof, that you have purchased from us through this website in any manner inconsistent with the license described in this provision.</li>
                        <li>A User in need of legal advice for a specific problem should communicate such queries on the Virtual Law Firm.&nbsp;</li>
                      </ol>
                    </li>
                  </ol>

                  <p>&nbsp;</p>

                  <p>FOR THE AVOIDANCE OF ANY DOUBT IF YOU ARE UNSURE ABOUT THE LEGAL SERVICES YOU ARE PURCHASING FROM THE DOCUMENT REPOSITORY APP, YOU ARE STRONGLY ADVISED TO EITHER REQUEST TO SPEAK WITH A LAWYER VIA THE VIRTUAL LAW FIRM, SEEK OTHER LEGAL COUNSEL OR REFRAIN FROM COMPLETING YOUR PURCHASE.</p>

                  <p>&nbsp;</p>

                </li>
                <li><strong>Interface with the Virtual Law Firm (LEGEX Partners &amp; Co)</strong>

                  <ol>
                    <li>An attorney-client relationship is created or exists between a User and the Virtual Law Firm (VLF) where the User makes payment or draws from the User&rsquo;s subscription concerning a legal issue sent for a lawyer&rsquo;s attention in the VLF.</li>
                    <li>Specific legal advice can only be given with full knowledge by the firm of the facts and issues relevant to your situation that you are aware of.&nbsp;</li>
                    <li>By providing you with legal services as stated on the Site, the VLF has not agreed to provide physical legal representation on your behalf or provide any legal services extending beyond those services which you have purchased.&nbsp; After performing the services purchased by you, we have no further obligation to you.</li>
                    <li>In the event that you may require more traditional full-service legal services not offered by the VLF, you are encouraged to resort to the lawyers listed on the Panel of Lawyers/Law Firms. &nbsp;</li>
                  </ol>



                  <p>&nbsp;</p>

                </li>
                <li><strong>Panel of Lawyers/Law Firms Registered on mylaw&nbsp;</strong>

                  <ol>
                    <li>Where a User provides information on a particular legal matter that requires the physical intervention of a lawyer (e.g. negotiation or dispute resolution), mylaw will assign such matter to a lawyer registered on the Panel of Lawyers/Law Firms based on the lawyer&rsquo;s expertise, location and availability once the User makes payment for the service or draws on his or her existing subscription. &nbsp; &nbsp;</li>
                    <li>mylaw does not endorse or recommend any lawyer nor does it make any warranty as to the qualifications or competency of any lawyer. &nbsp;</li>
                    <li>The lawyers registered on the Panel of Lawyers/Law Firms are third-party independent contractors solely responsible for the advice they give, the services they provide and the representations they make about themselves. As such, Users are solely responsible for assessing the quality, integrity, suitability and trustworthiness of all persons with whom they communicate legal needs on the Site.</li>
                  </ol>



                  <p>&nbsp;</p>

                </li>
                <li><strong>Termination of subscription, membership and access restriction</strong>

                  <ol>
                    <li>mylaw reserves the right, in its sole discretion, to downgrade or terminate your access to the Site, for any reason and at any time without prior notice.&nbsp;</li>
                    <li>If mylaw elects to terminate your account, mylaw will send notice of same to your registered email address.</li>
                    <li>mylaw reserves the right to modify or discontinue, either temporarily or permanently, any part of the Services with or without notice to the User.</li>
                    <li>The User agrees that mylaw will not be liable to the User or any third party claiming through the User for any modification, suspension, or discontinuance of the User&rsquo;s subscription or membership or access on the Site.&nbsp;</li>
                  </ol>



                  <p>&nbsp;</p>

                </li>
                <li><strong>Ownership and preservation of documents</strong>

                  <ol>
                    <li>mylaw does not claim ownership of any documents, files or media (collectively referred to in this section as Documents) you either create, upload or store on the Site.</li>
                    <li>You hereby grant mylaw permission to use your Documents in connection with providing services to you.&nbsp;</li>
                    <li>The User acknowledges and agrees that mylaw may preserve these Documents as well as disclose them if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to accomplish any of the following:&nbsp;
              <ol>
                        <li>To comply with legal process, any applicable law or government requests;</li>
                        <li>To enforce the provisions of these Terms;&nbsp;</li>
                        <li>To respond to claims that any content violates the rights of third parties; or&nbsp;</li>
                        <li>To protect the rights, property, or personal safety or mylaw, its users and the public.</li>
                      </ol>
                    </li>
                    <li>The User understands that the technical processing and transmission of mylaw, including User&rsquo;s content, may involve transmissions over various networks and changes to conform and adapt to technical requirements of connecting networks or devices. As such, User agrees that mylaw has no responsibility or liability for deleting or failing to store any content maintained or uploaded for use on mylaw.&nbsp;</li>
                  </ol>



                  <p>&nbsp;</p>

                </li>
                <li><strong>Consent to receive emails</strong>

                  <ol>
                    <li>By creating an account, the User agrees that the User may receive email communications from mylaw, such as newsletters, special offers, account reminders and updates.&nbsp;</li>
                    <li>The User understands that the User can discontinue receipt of these communications by clicking the &lsquo;Unsubscribe&rsquo; link in the footer of the actual email.&nbsp;</li>
                  </ol>



                  <p>&nbsp;</p>

                </li>
                <li><strong>Acceptable use of Communication Services</strong>

                  <ol>
                    <li>The services provided by mylaw include a large number of what are referred to in this section as Communication Services which include but are not limited to live chats, comment threads, question and answer products, customer service communication forums, and other message services.&nbsp;</li>
                    <li>The User agrees to use the Communication Services only to post, send and receive messages or materials proper to and related to the particular Communication Services.&nbsp;</li>
                    <li>The User agrees that when using the Communication Services, the User will not:&nbsp;
              <ol>
                        <li>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of any others.</li>
                        <li>Publish, post, upload, distribute or disseminate any names, materials, or information that is considered inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful.&nbsp;</li>
                        <li>Create a false identity, represent self as someone else or otherwise falsify or delete in an uploaded file any significant attributions or notices.&nbsp;</li>
                        <li>Upload files that contain software or other material protected either by intellectual property laws or by the rights of privacy or publicity except when the necessary rights or consent to such material or software is owned or controlled, or obtained (as applicable).</li>
                        <li>Upload corrupted files, files that contain viruses, or any other files that may damage the operation of another&rsquo;s computer.&nbsp;</li>
                        <li>Advertise, offer to sell, or offer to buy anything for business purposes except to the limited extent any particular Communication Service specifically allows such activity.&nbsp;</li>
                        <li>Restrict or inhibit any other user from using and enjoying the Communication Services specifically allows such activity.&nbsp;</li>
                        <li>Harvest or otherwise collect personally identifiable information about others, without their consent.&nbsp;</li>
                        <li>Violate any code of conduct or other guidelines, which may be applicable for any particular Communication Services.</li>
                        <li>15.3.10.Violate any applicable laws or regulations.&nbsp;</li>
                      </ol>
                    </li>
                    <li>Although mylaw has no obligation to monitor the Communication Services, mylaw reserves the right, in its own discretion, to review and remove materials posted to a Communication Service, in whole or in part.&nbsp;</li>
                    <li>mylaw reserves the right to disclose any material posted, information or activity as necessary to satisfy any applicable law, regulation, legal process, or governmental request. &nbsp; &nbsp; &nbsp;</li>
                  </ol>



                  <p>&nbsp;</p>

                </li>
                <li><strong>No Unlawful or Prohibited Use</strong>

                  <ol>
                    <li>User can only make use of the Services if the Services do not conflict with or violate the laws of User&rsquo;s jurisdiction.</li>
                    <li>The availability of the Services in User&rsquo;s jurisdiction is not an invitation or offer by mylaw to access or use the Site and the Services.</li>
                    <li>By using the Site, the User accepts sole responsibility that such use does not violate any applicable law in User&rsquo;s jurisdiction&nbsp;</li>
                    <li>The following are specifically excluded and/or prohibited:
              <ol>
                        <li>Use in connection with any legal matter that is frivolous, immaterial or illegal in nature, as determined by mylaw;</li>
                        <li>Use in connection with any legal matter involving the laws of jurisdictions outside of Nigeria;</li>
                        <li>Use in connection with any legal matter for which the User is currently or prospectively represented by legal counsel;</li>
                        <li>Use in any legal matter that directly involves mylaw, Virtual Platform Limited or any of its affiliates, directors, agents, employees, or other mylaw service providers; or&nbsp;</li>
                        <li>Hack, &lsquo;scrape&rsquo; or &lsquo;crawl&rsquo; the Site whether directly or through intermediaries including but not limited to spiders</li>
                      </ol>
                    </li>
                    <li>In enforcement of this section, mylaw reserves the right to refuse membership, or suspend or terminate User&rsquo;s account immediately and without prior notice.</li>
                  </ol>



                  <p>&nbsp;</p>

                </li>
                <li><strong>Licence Grant</strong>

                  <ol>
                    <li>Subject to the User&rsquo;s compliance with these Terms, the User is hereby granted a non-exclusive, limited, non-transferable, revocable licence to access the Site as in accordance with the intention of mylaw.&nbsp;</li>
                    <li>Any and all rights not expressly granted in these Terms are reserved by mylaw.</li>
                    <li>Upon transmission of content on mylaw, User hereby grants mylaw and its affiliates a non-exclusive, royalty-free, perpetual, revocable and fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display any such content.&nbsp;</li>
                    <li>Any feedback and/or suggestions submitted by the User, may be used by mylaw without obligation to the User.</li>
                    <li>Any resale or unauthorized distribution or duplication of materials downloaded from the mylaw website is strictly prohibited. Use of any such materials is for personal use only. Any resale or redistribution of mylaw resources without express written consent by mylaw is strictly prohibited. &nbsp;</li>
                  </ol>



                  <p>&nbsp;</p>

                </li>
                <li><strong>Intellectual Property Rights</strong>

                  <ol>
                    <li>mylaw retains all right, title and interest in and to the products and services provided by it on the Site including without limitation: software, images, text, graphics, illustrations, logos, service marks, copyrights, photographs, videos, music, and all related intellectual property rights. Except as otherwise provided in this Terms, the User shall not and shall not permit others to:
              <ol>
                        <li>Reproduce, modify, translate, enhance, decompile, disassemble, reverse engineer or create derivative works of any of the Services;</li>
                        <li>Sell, license, sublicense, rent, lease, distribute, copy, publicly display, publish, adapt or edit any of the Services; or</li>
                        <li>Circumvent, or disable any security or technological features of the Services.&nbsp;</li>
                      </ol>
                    </li>
                    <li>The design, text, graphics and selection and arrangement thereof, and services and the legal documents, guidance and all other content found on the mylaw website and all other content found on the website are copyright of mylaw&copy; . All rights reserved.&nbsp;</li>
                    <li>mylaw&reg; is a registered trademark in the Nigerian Registry of Trade Marks, Patents, and Designs.&nbsp;
              <ol>
                        <li>User shall not copy, imitate or use them, in whole or in part, without prior consent of mylaw.&nbsp;</li>
                        <li>In addition, the look and feel of mylaw is the service mark, trademark and/or trade identity of mylaw and the User may not copy, imitate or use same without prior written consent of mylaw.&nbsp;</li>
                        <li>The names of actual companies and products which the User may encounter through mylaw may be the trademarks of its respective owners.&nbsp;</li>
                        <li>Nothing in this Terms shall be understood as in any way granting any license or right to use any of mylaw&rsquo;s trademarks displayed on the mylaw website.</li>
                        <li>All goodwill generated from the use of mylaw&rsquo;s trademarks is reserved for the use of mylaw, exclusively.</li>
                      </ol>
                    </li>
                  </ol>



                  <p>&nbsp;</p>

                </li>
                <li><strong>Copyright Infringement</strong>

                  <ol>
                    <li>If the User believes that any material on the Site infringes upon any copyright owned and/or controlled by the User, the User may send a notice of alleged infringement to the contact information provided below:</li>
                  </ol>


                  <p>info@mylaw.ng</p>

                  <p>&nbsp;</p>

                </li>
                <li><strong>Links to Third Party Sites</strong>

                  <ol>
                    <li>The Site may contain links to third party resources and businesses on the internet (referred to in this section as &lsquo;Links&rsquo;).&nbsp;</li>
                    <li>These Links are provided for User convenience to help the User identify and locate other internet resources that may be of interest to the User. mylaw does not sponsor and is not legally associated with any third party Links.&nbsp;</li>
                    <li>mylaw is not legally authorised to use any trade name, registered trademark, logo, official seal or copyrighted material that may appear in the Links.</li>
                    <li>mylaw does not control, endorse or monitor the content(s) of any Links, and any changes or updates to any such Link.</li>
                    <li>mylaw is not responsible for webcasting or for any other form of transmission received from any Link.&nbsp;</li>
                    <li>This Terms do not cover User interaction with Links.&nbsp;</li>
                    <li>User has the sole responsibility to carefully review the terms and conditions and privacy policy of any Links and Linked sites, and any third party sites connected thereto.</li>
                    <li>In respect of the use of any service provided by any link or on any Linked site:
              <ol>
                        <li>mylaw is not and will not be responsible for any act or omission of the third party; including any third party access to or use of User data.</li>
                        <li>mylaw does not warrant or support any service provided by the Third Party.&nbsp;</li>
                      </ol>
                    </li>
                  </ol>



                  <p>&nbsp;</p>

                </li>
                <li><strong>Disclaimer of Responsibility and Liability</strong>

                  <ol>
                    <li>TO THE FULLEST EXTENT PERMITTED BY LAW, MYLAW MAKES NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES AS THEY ARE PROVIDED &lsquo;AS IS&rsquo;.&nbsp;</li>
                    <li>MYLAW ALSO DISCLAIMS ANY WARRANTIES OR MERCHANTIBILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. INFORMATION AND OPINIONS RECEIVED VIA THE SITE SHOULD BE RELIED UPON FOR PERSONAL OR LEGAL DECISIONS ONLY UPON CONSULTATION WITH EITHER A LAWYER FROM THE VIRTUAL LAW FIRM, PANEL OF LAW FIRMS/LAWYERS OR ANY OTHER LAWYER QUALIFIED TO PRACTICE LAW IN NIGERIA FOR SPECIFIC ADVICE TAILORED TO THE USER&rsquo;S SITUATION.</li>
                    <li>IN NO EVENT WILL MYLAW AND/OR ITS AFFILIATES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, REGARDLESS OF LEGAL THEORY, WHETHER OR NOT MYLAW HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.&nbsp;</li>
                  </ol>



                  <p>&nbsp;</p>

                </li>
                <li><strong>Release and Indemnity&nbsp;</strong>

                  <ol>
                    <li>The User personally and on behalf of the User&rsquo;s heirs, executors, agents, representatives, and assigns, fully releases, forever discharges, and holds mylaw, and/or its affiliates, its respective harmless form any and all losses, damages, expenses, including reasonable attorney&rsquo;s fees, rights, clams, and actions of any kind and injury (including death) arising out of or relating to the use of the Services.</li>
                    <li>User agrees that this release has been freely and voluntarily consented to and confirm that the User fully understand the purpose, intent, form and import of this Terms.</li>
                    <li>The User agrees to indemnify and hold mylaw and/or its affiliates and their respective officers from any and all losses, damages, expenses, including reasonable attorneys&rsquo; fees, rights, claims, actions of any kind and injury (including death) arising out of any third party claims relating to use of the mylaw services, User violation of the Terms, and/or User violation of the rights of another in relation to the Terms.&nbsp; &nbsp;</li>
                  </ol>
                  <p>&nbsp;</p>
                </li>
                <li><strong>Dispute Resolution&nbsp;</strong>
                  <ol>
                    <li>User concerns can be resolved quickly and to the Users satisfaction by calling 08140000829 and/or sending an email to info@mylaw.ng</li>
                    <li>However, in the event that mylaw is not able to resolve a dispute with the User after attempts to do so informally, then as a condition to use of the services, the User agrees with mylaw that such disputes, claims, rights arising thereof shall be resolved through binding arbitration before a sole arbitrator.&nbsp;</li>
                    <li>The Arbitration and Conciliation Act, Cap A18 Laws of the Federal Republic of Nigeria shall be the applicable law for such proceedings.&nbsp;</li>
                    <li>The arbitration proceedings shall be held in Lagos, Nigeria or any other location as to which the mylaw and the User may then agree.&nbsp;</li>
                    <li>Any party seeking arbitration must first send to the other, by certified or registered mail a written notice of dispute.</li>
                    <li>Any notice to mylaw should be addressed to:</li>
                  </ol>
                  <p>Virtual Platform Limited&nbsp;</p>
                  <p>1b, Utomi Airie Avenue, Lekki Phase 1, Lagos</p>
                  <p>Attention: Legal Department.&nbsp;</p>
                </li>
                <li>
                  <ol>
                    <li>Any notice to be sent by mylaw to the User shall be sent to the User address set forth in mylaw records of account or such other legal address as mylaw is able to identify.</li>
                    <li>The User may only resolve disputes with mylaw, the Virtual Law Firm or the Panel of Law Firms/Lawyers on an individual basis, and may not bring a claim as claimant or plaintiff or a class member in a class, consolidated or representative action. Class arbitrations, class actions, private attorney general actions, and consolidations with other arbitrations are not allowed.&nbsp;</li>
                    <li>Notwithstanding this agreement to arbitrate disputes (as provided above), the following exceptions will apply to the resolution of disputes between mylaw and users:
                    <ol>
                        <li>mylaw may bring a lawsuit against the User in any court of competent jurisdiction for injunctive relief to stop any unauthorized use or abuse of the mylaw services without first engaging in arbitration or the informal dispute resolution process described above.&nbsp;</li>
                        <li>mylaw may bring a lawsuit against the User in any court of competent jurisdiction solely for injunctive relief to stop any intellectual property infringement without first engaging in arbitration or the informal dispute resolution process described above.</li>
                        <li>In the event that the agreement to arbitrate; as provided herein, is found to be inapplicable or unenforceable for any reason, then as a condition to the use of the &lsquo;mylaw services; mylaw and the User expressly agree that any resulting judicial proceedings will be brought in any court of competent jurisdiction, and by use, the User expressly consents to the venue and jurisdiction of the courts therein. &nbsp;</li>
                      </ol>
                    </li>
                  </ol>
                  <p>&nbsp;</p>
                </li>
                <li><strong>Applicable Law</strong>
                  <p>This Terms shall and will be governed by the Laws of the Federal Republic of Nigeria which shall for all intent and purposes be the governing law of this Terms.</p>
                  <p>&nbsp;</p>
                </li>
                <li><strong>Entire Agreement</strong>
                  <ol>
                    <li>This Terms constitute the entire agreement between the User and mylaw with respect to its subject matter, and supersedes and replaces any other prior or contemporaneous agreements, or terms and conditions applicable to the subject matter of this Terms.&nbsp;</li>
                    <li>This Terms shall in no way be construed as creating third party beneficiary rights.</li>
                  </ol>
                  <p>&nbsp;</p>
                </li>
                <li><strong>Waiver, Severability and Assignment</strong>
                  <ol>
                    <li>The failure of mylaw to enforce any provision in this Terms is not and shall not be construed as a waiver of its right to do so at a later time.&nbsp;</li>
                    <li>In the event that any provision of this Terms is found unenforceable, the remaining provisions of the Terms will remain in full effect and be enforceable, and such term which reflects the intent of mylaw as nearly as possible shall be reflected in the agreement instead of any severed term. &nbsp;</li>
                    <li>The User may not assign any rights under this Terms, and any such attempt to do so shall be void and of not effect. Provided that mylaw shall reserve the right to assign its rights to any of its affiliates or subsidiaries, or to any successor in interest of any business associated with the Services. &nbsp;</li>
                  </ol>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}