import React from 'react';
import Toaster from '../../components/widgets/Toaster';
import CreditCard from '../../components/widgets/CreditCard';
import { Link, browserHistory } from 'react-router';
import Icon from '../../components/widgets/Icon';
import { currency } from '../../lib/utils';
import axios from 'axios';
import uuid from 'uuid/v1';

export default class MylawyerPay extends React.Component {
  componentWillMount() {
    const { data: { amount } } = this.props;
  }

  payWithRave = () => {
    const { user, data: { amount }, callback, uData } = this.props;
    const txref = uuid();
    const payment = window.getpaidSetup({
      PBFPubKey: window.RAVE_API_KEY,
      customer_email: user.email,
      amount,
      payment_plan: user.plan,
      customer_phone: user.phone,
      currency: window.SW_CURRENCY,
      country: user.country,
      txref,
      meta: [{
        metaname: 'type',
        metavalue: 'API123',
      }],
      onclose: () => { },
      callback: response => {
        // var txref = response.tx.txRef;
        // collect flwRef returned and pass to a server page to complete status check.
        if (
          response.tx.chargeResponseCode == '00' ||
          response.tx.chargeResponseCode == '0'
        ) {
          console.clear();

          uData.txref = response.tx.txRef;
          console.log(uData);
          const data = {
            name: uData.name,
            email_addr: uData.email_addr,
            message: uData.message,
            status: "paid"
          };
          axios({
            url: 'https://cors-anywhere.herokuapp.com/https://immense-tor-56766.herokuapp.com/api/my_lawyer/create.php',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            data
          }).then(function(res){
            console.log(res.status);
            if((res.status == 200) || (res.status== 201)) {
              console.log(res.status);
              Toaster.success("Your message has been sent");
              browserHistory.push('/leruLogin');
            } else {
              console.log(res.status, res);
          }
          }).catch(function(err){
            console.error(err)
          });
        } else {
          callback({ message: 'Error with payment' })
        }

        payment.close();
      }
    });
  }

  

  render() {
    const { title, data: { amount } } = this.props;
    return <div className="Payment">
      <div className="page-title">{title}</div>
      <div className="p-a-2">
        <p className="text-center">
          <button type="button" className="pt-button pt-intent-success" onClick={this.payWithRave}>Make Payment</button>
        </p>
        <div className="pages m-t-2 text-center">
          <h3><strong className="text-muted">Total</strong> {currency(amount)}</h3>
        </div>
      </div>
    </div>;
  }
}