import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/widgets/Spinner';
import Toaster from '../../components/widgets/Toaster';
import { Link, browserHistory } from 'react-router';
import axios from 'axios';

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        e_addr: '',
        password: '',
        password_r: '',
        validation: '',
        notLoading: true
    };
  }


  componentWillMount() {
      if(localStorage.getItem('em_law_reset')){
        
        this.setState({e_addr: localStorage.getItem('em_law_reset')});
      } else {
        browserHistory.push('/leruLogin');
      }
  }

 

  handleChange = (e) => {
    const { name, value, checked } = e.target || e.srcElement;


    switch(name) {   
        case 'password':
            this.setState({password: value});
            break;
        default:
            break;
    }
  }

  handleValidation = (e) => {
    const {name, value} = e.target || e.srcElement;
    if(name === 'password2') {
      if (value === this.state.password){
        this.setState({validation: ""})
      } else {
        this.setState({validation: "PASSWORDS DOESN'T MATCH"})
        this.pass.focus();
      }
    }
  }
  
  handleLawyerSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0,0);
    const { password, e_addr} = this.state;
    this.setState({notLoading: false});



    const data = {
     email_addr : e_addr,
     password: password
  };
  const that = this;
  axios({
    url: 'https://cors-anywhere.herokuapp.com/https://immense-tor-56766.herokuapp.com/api/lawyer/forgotpassword.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  }).then(function(res){
    console.log(res);
    console.log(res.status);
    that.setState({notLoading: true});
    if((res.status == 200) || (res.status== 201)) {
      console.log(res.status);
      Toaster.success("Password reset successful");
      localStorage.removeItem('em_law_reset');
      browserHistory.push('/leruLogin');
      that.setState({notLoading: true});
    } else {
    console.log(res);
    Toaster.error("Password reset Failed, invalid parameters");
    that.setState({notLoading: true});
  }
  }).catch(function(err){
    console.log(err)
    Toaster.error("Network Error or invalid Login");
    that.setState({notLoading: true});
  });
}
  render() {
    return (
            <section  >
              
            <div className="w3-card-4 w3-center m-t-4" style={{width: '52%', height: '310px',  margin: '0 auto'}}>
              {this.state.notLoading?
                <div className=" m-t-4">>
                  <div className="w3-container w3-grey m-t-4">
                      <h1>Reset Your Password</h1>
                  </div>

                  <form className="w3-container m-t-4" method="POST" onSubmit={this.handleLawyerSubmit}>
                      <input type="email" style={{display: "none"}} />
                      <input type="password" style={{display: "none"}} />
                      
                  <p>
                    <label className="pt-label tooltiip">Enter password<span className="tooltiiptext">Make sure you type your password</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                    <input type="password" min={5} autoComplete="off" min={5} className="pt-input pt-fill" onChange={this.handleChange} name="password" required />
                  </p>
                  <p>
                    <p style={{fontWeight: "bold", color: "red"}}>{this.state.validation}</p>
                    <label className="pt-label tooltiip">Re-type password<span className="tooltiiptext">Re-type your password</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                    <input type="password" min={5} autocomplete="off" ref={(pass) => {this.pass = pass}}  className="pt-input pt-fill" onChange={this.handleValidation} name="password2" required />
                  </p>
                      <input onClick = {this.handleLawyerSubmit} type="submit" value="Update password" className="w3-btn w3-green m-t-2" />
                  </form> 
                </div>:
                <div>
                    <Spinner />
                </div>
              }
            </div>
        </section>
    );
  }
}

export default store =>
  ({
    path: `/passwordreset`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({

          }))(PasswordReset)
      );
    }
  });