/* global fetch localStorage */

import 'whatwg-fetch';
import Swagger from './swagger.js';
import { browserHistory } from 'react-router';
import { error as toastrError } from '../components/widgets/Toaster';
import { inflect } from 'inflection';

const headers = () => {
  const user = localStorage.getItem('user');
  const session = localStorage.getItem('session');

  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    user,
    session
  };
};

let factory = {};

for (let name in Swagger) {
  if (name.indexOf('inline') == -1) {
    factory[name] = { propTypes: Swagger[name] };

    factory[name].get = (body, endpoint) => {
      let query = [];
      if (body) {
        for (let param in body) {
          query.push((query.length ? '&' : '?') + `${param}=` + encodeURIComponent(body[param]));
        }
      }
      let headerValue = headers();
      return fetch(
        `${window.SW_DOMAIN}/api/v${window.SW_VERSION}/${endpoint === 's' ? inflect(name, 2) : name}${(endpoint === 's' ? '' : endpoint || '')}` + query.join(''),
        {
          method: 'GET',
          headers: headerValue
        }
      )
        .then(response => {
          if (response && response.status === 401) {
            window.localStorage.clear();
            browserHistory.push('/');
            toastrError('Your session has expired please login.');
          }

          return response.json();
        })
        .catch(error => console.error(error));
    };

    factory[name].gets = (body) => factory[name].get(body, 's');

    factory[name].post = (body, endpoint) => {
      return fetch(
        `${window.SW_DOMAIN}/api/v${window.SW_VERSION}/${name}${(endpoint || '')}`,
        {
          method: 'POST',
          headers: headers(),
          body: JSON.stringify(body || {})
        }
      )
        .then(response => response.json())
        .catch(error => console.error(error));
    };

    factory[name].put = (body, endpoint) => {
      return fetch(
        `${window.SW_DOMAIN}/api/v${window.SW_VERSION}/${name}${(endpoint || '')}`,
        {
          method: 'PUT',
          headers: headers(),
          body: JSON.stringify(body || {})
        }
      )
        .then(response => response.json())
        .catch(error => console.error(error));
    };

    factory[name].delete = (endpoint) => {
      return fetch(
        `${window.SW_DOMAIN}/api/v${window.SW_VERSION}/${name}${(endpoint || '')}`,
        {
          method: 'DELETE',
          headers: headers()
        }
      )
        .then(response => response.json())
        .catch(error => console.error(error));
    };
  }
}

export default factory;
