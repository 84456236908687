const key = 'vlfCategories';

import { connect } from 'react-redux';
import React from 'react';
import PageComponent from '../../../components/PageComponent';
import Props from '../../../components/widgets/etable/props';
import NoItem from '../../../components/widgets/NoItem';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getTemplates } from '../../../actions/documentActions';
import Toaster from '../../../components/widgets/Toaster';
import axios from 'axios';
import { get, post, put, delete_ } from '../../../actions/request';

class Partners extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {
      partners: [],
      packages: null,
      modal: false,
      editModal: false,
      code: '',
      count: 0,
      type: '',
      amount: 0,
      multiple: false,
      allowed: '',
      expiry: '',
      resourceId: '',
      editItem: {},
      modalType: '',
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  preAdd(e) {
    e.preventDefault();
    this.setState({ name: '', image: '', modalType: 'Add' }, () => {
      this.toggle();
    });
  }

  setEdit(e, item) {
    e.preventDefault();
    const { name, image } = item;
    this.setState({ name, image, modalType: 'Edit', editItem: item }, () => {
      this.toggle();
    });
  }

  componentWillMount() {
    this.getPartners();
  }
  getPartners() {
    get(`service-partners`, { json: true }).then((result) => {
      this.setState({ partners: result.json }, () => {});
    });
  }
  createPartner(e) {
    e.preventDefault();
    let { name, image } = this.state;
    let data = {
      name,
      image,
    };
    post(`service-partners`, { json: data })
      .then((result) => {
        Toaster.success('Service Partner Created Successfully');
        this.toggle();
        this.getPartners();
      })
      .catch((error) => {
        Toaster.error('An error occured, Please fill all fields');
      });
  }

  updatePartner(e) {
    e.preventDefault();
    let { name, image, editItem } = this.state;
    let body = {
      data: { name, image },
      _id: editItem._id,
    };
    put(`service-partners`, { json: body })
      .then((result) => {
        Toaster.success('Service Partner Updated Successfully');
        this.toggle();
        this.getPartners();
      })
      .catch((error) => {
        Toaster.error('An error occured, Please fill all fields');
      });
  }

  deletePackage(e, id) {
    e.preventDefault();
    delete_(`service-partners/${id}`, { json: {} })
      .then((result) => {
        Toaster.success('Service Partner Deleted Successfully');
        this.getPartners();
      })
      .catch((error) => {
        Toaster.error('An error occured');
      });
  }
  renderPartners() {
    const tableHeaders = ['Name', 'Slug'];
    let { partners } = this.state;
    const count = partners.length;

    return (
      <div className="m-t-2">
        <div className="table-container">
          <table className="pt-table">
            <thead>
              <tr>
                {tableHeaders.map((name, idx) => {
                  return <th key={idx}>{name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {partners &&
                partners.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{item.name}</td>
                      <td>
                        <a href={item.image} target="_blank">
                          View Image
                        </a>
                      </td>
                      <td>
                        <button
                          className="pt-button pt-intent-primary"
                          onClick={(e) => this.setEdit(e, item)}
                          type="submit"
                        >
                          Edit
                        </button>{' '}
                        <button
                          type="submit"
                          onClick={(e) => this.deletePackage(e, item._id)}
                          className="pt-button pt-intent-danger"
                        >
                          <i className /> Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {!count ? <NoItem>No Coupons found</NoItem> : null}
        </div>
      </div>
    );
  }

  getAuthType = () => ['admin'];
  static propTypes = Props;
  renderPage() {
    let { name, image, modalType } = this.state;
    return (
      <section className="pad_2 content-width">
        <Modal isOpen={this.state.modal} toggle={this.toggle} centered={true}>
          <ModalHeader toggle={this.toggle}>{modalType} Partner</ModalHeader>
          <ModalBody>
            <form onSubmit={this.onSubmit} className="form">
              <div className="row">
                <div className="col-12">
                  <p>
                    <label className="pt-label">Enter Partner Name</label>
                    <input
                      placeholder="Code"
                      value={name}
                      onChange={(event) =>
                        this.setState({ name: event.target.value })
                      }
                      type="text"
                      className="pt-input pt-fill"
                      name="name"
                      required
                    />
                  </p>
                </div>

                <div className="col-12">
                  <p>
                    <label className="pt-label">
                      Enter Service Partner Image Link
                    </label>
                    <input
                      placeholder="Count"
                      value={image}
                      onChange={(event) =>
                        this.setState({ image: event.target.value })
                      }
                      type="text"
                      className="pt-input pt-fill"
                      name="e.g cloudinary link"
                      required
                    />
                  </p>
                </div>
              </div>
              <hr />
              {modalType == 'Add' ? (
                <button
                  type="submit"
                  onClick={(e) => this.createPartner(e)}
                  className="pt-button pt-intent-primary"
                >
                  <i className /> Add Partner
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={(e) => this.updatePartner(e)}
                  className="pt-button pt-intent-primary"
                >
                  <i className /> Update Partner
                </button>
              )}
            </form>
          </ModalBody>
        </Modal>

        <div className="d-flex justify-content-between">
          <h2 className="m-b-2">Service Partners</h2>
          <button
            className="pt-button pt-intent-primary"
            onClick={(e) => this.preAdd(e)}
            type="button"
          >
            Create New
          </button>
        </div>
        {this.renderPartners()}
      </section>
    );
  }
}

export default (store) => ({
  path: `/admin/partners`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          getTemplates: (kwargs, callback) =>
            dispatch(getTemplates(kwargs, callback)),
        })
      )(Partners)
    );
  },
});
