import React from 'react';
import ReactDOM from 'react-dom';
import createStore from './store/createStore';
import App from './containers/app';
import { FocusStyleManager } from '@blueprintjs/core';

(() => {
  // Redirect to SSL route
  const location = window.location;
  if (
    location.protocol.indexOf('https') === -1 &&
    !/localhost|staging/i.test(location.hostname)
  ) {
    location.replace(`https://${location.host}${location.pathname}`);
  }
})();
// ========================================================
// Global Constants
// ========================================================

window.APP_TITLE = process.env.SW_PROJECT || 'MyLaw';
window.TZ = process.env.TZ || 'Africa/Lagos';
window.SW_PROJECT = process.env.SW_PROJECT || 'MyLaw';

window.SW_VERSION = process.env.SW_VERSION || '1';
window.SW_CURRENCY = process.env.SW_CURRENCY || 'NGN';
window.RAVE_API_KEY =
  process.env.RAVE_API_KEY || 'FLWPUBK-e36d958fb2fab075160722f8e8e50aa9-X';
//'FLWPUBK-cd7e4f2c0604b6ad66cebdfadafc54d4-X' ||
// TEST_KEY = 'FLWPUBK_TEST-15e31929e4cb047d583b1fbe152c7ca0-X';
// LIVE_KEY = 'FLWPUBK-e36d958fb2fab075160722f8e8e50aa9-X';

if (process.env.NODE_ENV === 'development') {
  window.SW_DOMAIN = 'http://localhost:8889';
} else if (process.env.NODE_ENV === 'staging') {
  window.SW_DOMAIN = 'https://my-law-backend.herokuapp.com';
} else {
  window.SW_DOMAIN = 'https://my-law-backend-prod.herokuapp.com'; // PRODUCTION
}

//https://my-law-backend-prod.herokuapp.com
// window.SW_DOMAIN = 'https://my-law-backend.herokuapp.com'; // STAGING
//window.SW_DOMAIN = 'http://localhost:8889'; // DEVELOP

// window.SW_DOMAIN = process.env.SW_DOMAIN_BE || 'https://api.mylaw.ng'; // PRODUCTION

// Extensions
String.prototype.ucFirst = function () {
  return this[0] ? `${this[0].toUpperCase()}${this.substr(1)}` : this;
};

String.prototype.isLawyer = function () {
  return /lawyer|admin/.test(this);
};

String.prototype.asHTML = function () {
  return this.replace(/\n/g, '<br />').replace(
    /\t/g,
    '&nbsp;&nbsp;&nbsp;&nbsp;'
  );
};

String.prototype.ellipsis = function (len = 50) {
  return this.length > len ? `${this.substr(0, len - 3)}…` : this;
};

Array.prototype.last = function () {
  return this[this.length - 1];
};
//

// ========================================================
// Store Instantiation
// ========================================================

const store = createStore(window.___INITIAL_STATE__);
// ========================================================
// Render Setup
// ========================================================

FocusStyleManager.onlyShowFocusOnTabs();
const MOUNT_NODE = document.getElementById('root');
let render = () => {
  const routes = require('./routes').default(store);

  ReactDOM.render(<App store={store} routes={routes} />, MOUNT_NODE);
};

// ========================================================
// Developer Tools Setup
// ========================================================

if (__DEV__) {
  if (window.devToolsExtension) {
    window.devToolsExtension.open();
  }
}

if (__DEV__) {
  if (module.hot) {
    const renderApp = render;
    render = () => {
      try {
        renderApp();
      } catch (error) {
        const RedBox = require('redbox-react').default;
        ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
      }
    };

    module.hot.accept('./routes', () => {
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render();
      });
    });
  }
}

render();
