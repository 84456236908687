import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { inflect } from 'inflection';
import { showConfirmModal, showUserSelectorModal } from '../../actions/modalActions';
import { injectReducer } from '../../store/reducers';
import moment from 'moment';
import { Tab2, Tabs2, Popover, Tooltip, Position, PopoverInteractionKind } from '@blueprintjs/core';
import Spinner from '../../components/widgets/Spinner';
import Icon from '../../components/widgets/Icon';
import NoItem from '../../components/widgets/NoItem';
import { currency } from '../../lib/utils';
import Toaster from '../../components/widgets/Toaster';
import { all, update } from '../../actions/plfActions';
import PageComponent from '../../components/PageComponent';

class AdminPLFs extends PageComponent {
  getAuthType = () => ['admin'];
  constructor(props) {
    super(props);
    this.limit = 20;
    this.q = null;
    this.type = 'pending';
    this.types = {
      pending: 'pending=true',
      accepted: 'accepted=true',
      taken: 'taken=true'
    };

    this.state = {
      plfs: {
        pending: {},
        accepted: {},
        taken: {},
      },
      page: 1
    };
  }

  componentWillMount() {
    this.fetchPLFs();
  }

  fetchPLFs = () => {
    const { getPLFs, types } = this;
    for (let i in types) {
      if (types.hasOwnProperty(i)) {
        getPLFs(types[i], i);
      }
    }
  };

  getPLFs = (path, status, page = 1) => {
    const { props: { all }, limit, q } = this;
    all({ q, page, limit, status }, (err, res) => {
      if (!err) {
        const plfs = this.state.plfs;
        plfs[status] = res;
        this.setState({ plfs, page });
      } else {
        Toaster.error(err.message || err);
      }
    });
  };

  handleSearch = e => {
    const { type, types } = this;
    const { value } = (e.target || e.srcElement);
    if (value && value.length > 1) {
      this.q = value;
      this.getPLFs(types[type], type, 1);
    } else if (!value) {
      this.q = null;
      this.getPLFs(types[type], type, 1);
    }
  };

  handleTabChange = (type) => {
    const { types } = this;
    this.type = type;
    this.setState({ page: 1 });
    this.getPLFs(types[type], type, 1);
  };

  handleAssign = (plf) => {
    const { showUserSelectorModal, update } = this.props;
    const { type, types } = this;
    showUserSelectorModal({
      lawyer: 'plf',
      cb: (user) => {
        const lawyer = user._id;
        update(plf._id, { lawyer }, (err) => {
          if (!err) {
            this.getPLFs(types[type], type, 1);
          }
        });
      }
    });
  };

  renderPaginator = (count, type) => {
    if (!count) {
      return null;
    }

    const { page } = this.state;
    const { payment: { isLoading } } = this.props.state;
    const total = Math.ceil(count / this.limit);
    let pages = [];
    for (let i = 0; i < total; i++) {
      pages.push(<div
        key={i}
        onClick={this.getPLFs.bind(this, this.types[type], type, i + 1)}
        className={`page ${isLoading ? 'loading' : ''} ${page === i + 1 ? 'selected' : ''}`}>
        {i + 1}
      </div>);
    }

    return pages;
  };

  renderMessage = ({ messages, stamp }) => {
    return <div className="p-a-1 tooltip-text">
      <p style={{ maxWidth: 320 }}>{messages[0].message.ellipsis(100)}</p>
      <hr />
      <span className="text-muted pull-right"><Icon name="access_time" /> {moment(stamp).fromNow()}</span>
      <div className="clearfix" />
    </div>;
  };

  renderWithDescriptionHover = (event, content) => {
    const popover = <div className="p-a-1 tooltip-text">
      <h6>{event.data.transactionreference}</h6><Link className="pull-right" to={`/admin/plfs/${event._id}`}><span className="pt-icon-size pt-icon-share pull-right"></span></Link>
      <p><b>{event.narration}</b></p>
      <p>Transaction: <code>{event.data.responsemessage}</code></p>
      <p>AVS Response: <code>{event.data.avsresponsemessage}</code></p>
      <p><span className="text-muted">{event.stamp && moment(event.stamp).fromNow()}</span></p>
    </div>;

    return <Popover interactionKind={PopoverInteractionKind.HOVER} content={popover} position={Position.TOP}>
      {content}
    </Popover>
  };

  renderPLFs = type => {
    const tableHeaders = ['Subject', 'Owner', 'PLF Category', 'Date', 'Status', 'Responder', 'Revert', '', ''];
    const plfs = this.state.plfs[type];
    const count = plfs.count || 0;
    const { session: { user } } = this.props.state;

    return <div className="m-t-2">
      <div className="table-container">
        <table className="pt-table">
          <thead>
            <tr>{tableHeaders.map((name, idx) => {
              if ((type === 'pending' && /Admin|Revert/.test(name)) ||
                (type !== 'pending' && /Decline|Approval/.test(name))) {
                return null;
              }
              return <th key={idx}>{name}</th>;
            })}</tr>
          </thead>
          <tbody>
            {plfs.items &&
              plfs.items.map((item, idx) => {
                const canOpen = !item.lawyer;
                return <tr key={idx}>
                  <td>
                    <Popover
                      interactionKind={PopoverInteractionKind.HOVER}
                      content={
                        <div className="p-a-2">
                          <h5>Description</h5>
                          <div dangerouslySetInnerHTML={{ __html: item.description }} />
                        </div>
                      }
                      position={Position.TOP}
                    >
                      {/* <Link to={`/plfs/${item._id}`}> */}
                      {item.subject}
                      {/* </Link> */}
                    </Popover>
                  </td>
                  <td>{(item.owner_expanded && item.owner_expanded.name) || 'N/A'}</td>
                  <td>
                    {
                      item.plf_category_expanded &&
                      <div>
                        <Link to={`/admin/plf-categories/${item.plf_category_expanded._id}`}>{item.plf_category_expanded.name}</Link>
                        <br /><small>{currency(item.plf_category_expanded.price)}</small>
                      </div>
                    }
                  </td>
                  <td>{moment(item.stamp).format('LL LT')}</td>
                  <td><small className={`plf-status pt-tag pt-intent-${item.status === 'open' ? 'success' : 'danger'}`}>{item.status.toUpperCase()}</small></td>
                  <td>{(item.lawyer_expanded && item.lawyer_expanded.name) || 'N/A'}</td>
                  {<td><button type="button" onClick={() => this.handleAssign(item)} type="button" disabled={!canOpen} className="pt-button pt-primary">Assign</button></td>}
                  <td></td>
                </tr>
              }
              )}
          </tbody>
        </table>
        {!count ? <NoItem>No PLF requests found</NoItem> : null}
      </div>
      <div className="paginator">
        {this.renderPaginator(count, type)}
      </div>
    </div>;
  };

  renderHeader = () => {
    const count = this.state.plfs[this.type].count || 0;

    return (<div className="row">
      <div className="col_20">
        <div className="pt-input-group">
          <span className="pt-icon pt-icon-search"></span>
          <input
            className="pt-input"
            type="search"
            placeholder="Search"
            dir="auto"
            onChange={this.handleSearch}
          />
        </div>
      </div>
      <div className="col_20">
        <span className="pt-navbar-divider"></span>
        <span className="pt-tag pt-minimal pt-large">
          {count} {inflect('entry', count)} found
        </span>
      </div>
    </div>);
  }

  renderPage() {
    const { user: { account } } = this.props.state;
    if (!account) {
      return <Spinner />;
    }

    return (<section className="content-width AdminPLFs">
      <h2 className="m-t-4 m-b-2">PLF Requests</h2>
      <div className="m-b-4">
        {this.renderHeader()}
      </div>
      <Tabs2 id="plfs" defaultSelectedTabId="pending" onChange={this.handleTabChange}>
        <Tab2 id="pending" title={<h5>Pending <div className="pending-count">{this.state.plfs.pending.count || 0}</div></h5>} panel={this.renderPLFs('pending')} />
        <Tab2 id="accepted" title={<h5>Accepted by you</h5>} panel={this.renderPLFs('accepted')} />
        <Tab2 id="taken" title={<h5>Taken</h5>} panel={this.renderPLFs('taken')} />
        <Tabs2.Expander />
      </Tabs2>
    </section>);
  }
}

export default store =>
  ({
    path: `/admin/plf-requests`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({
            showConfirmModal: (params) => dispatch(showConfirmModal(params)),
            showUserSelectorModal: (params) => dispatch(showUserSelectorModal(params)),
            update: (_id, json, callback) => dispatch(update(_id, json, callback)),
            all: (kwargs, callback) => dispatch(all(kwargs, callback)),
          }))(AdminPLFs)
      );
    }
  });