const key = 'vlfCategories';

import { connect } from 'react-redux';
import React from 'react';
import PageComponent from '../../../components/PageComponent';
import Props from '../../../components/widgets/etable/props';
import NoItem from '../../../components/widgets/NoItem';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getTemplates } from '../../../actions/documentActions';
import Toaster from '../../../components/widgets/Toaster';
import axios from 'axios';
import moment from 'moment';
import { packages } from '../../../actions/subscriptionActions';

class Coupons extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
      packages: null,
      modal: false,
      code: '',
      count: 0,
      type: '',
      amount: 0,
      multiple: false,
      allowed: '',
      expiry: '',
      resourceId: '',
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  componentWillMount() {
    const { getTemplates } = this.props;
    getTemplates({ limit: 100 });
    this.getCoupons();
    this.getPackages();
  }
  getCoupons() {
    axios({
      url: `${window.SW_DOMAIN}/api/v1/coupons`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        this.setState({ coupons: res.data.coupon }, () => {});
      })
      .catch(function (error) {
        return Toaster.error('An error occured, please try again');
      });
  }
  getPackages() {
    axios({
      url: `${window.SW_DOMAIN}/api/v1/subscription-packages`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        let subscriptionPackages = Object.keys(res.data);
        let packages = subscriptionPackages.filter((service) => {
          return res.data[service].active === true;
        });
        this.setState({ packages }, () => {});
      })
      .catch(function (error) {
        return Toaster.error('An error occured, please try again');
      });
  }
  createCoupons(e) {
    e.preventDefault();
    let {
      code,
      count,
      type,
      amount,
      multiple,
      allowed,
      expiry,
      resourceId,
      email,
      selectedPackageIndex,
    } = this.state;
    if (!code || !count || !type || !amount || !allowed) {
      return Toaster.error('Please fill all fields');
    }
    multiple = multiple === 'true' ? true : false;
    let data = {
      code,
      count,
      type,
      amount,
      multiple,
      allowed,
      expiry,
      email,
      resourceId,
      selectedPackageIndex,
    };
    axios({
      url: `${window.SW_DOMAIN}/api/v1/coupon`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
      .then((res) => {
        this.getCoupons();
        this.toggle();
        return Toaster.success('Coupon added successful');
      })
      .catch(function (error) {
        if (error.response.data.message) {
          return Toaster.error(error.response.data.message);
        }
        return Toaster.error('An error occured, please try again');
      });
  }
  deleteCoupon(e, _id) {
    e.preventDefault();
    axios({
      url: `${window.SW_DOMAIN}/api/v1/coupon/${_id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        this.getCoupons();
        return Toaster.success('Coupon deleted successful');
      })
      .catch(function (error) {
        if (error.response.data.message) {
          return Toaster.error(error.response.data.message);
        }
        return Toaster.error('An error occured, please try again');
      });
  }
  renderCalls() {
    const tableHeaders = [
      'Code',
      'Count',
      'Type',
      'Amount',
      'Multiple',
      'Service',
      'Expiry Date',
    ];
    let { coupons } = this.state;
    const count = coupons.length;

    return (
      <div className="m-t-2">
        <div className="table-container">
          <table className="pt-table">
            <thead>
              <tr>
                {tableHeaders.map((name, idx) => {
                  return <th key={idx}>{name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {coupons &&
                coupons.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{item.code}</td>
                      <td>{item.count || 'N/A'}</td>
                      <td>{item.type}</td>
                      <td>{item.amount}</td>
                      <td>{item.multiple ? 'Yes' : 'No'}</td>
                      <td>{item.allowed}</td>
                      <td>{moment(item.expiry).format('LL')}</td>
                      <td>
                        {' '}
                        <button
                          type="submit"
                          onClick={(e) => this.deleteCoupon(e, item._id)}
                          className="pt-button pt-intent-danger"
                        >
                          <i className /> Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {!count ? <NoItem>No Coupons found</NoItem> : null}
        </div>
      </div>
    );
  }

  getAuthType = () => ['admin'];
  static propTypes = Props;
  renderPage() {
    const {
      state: {
        document: {
          template: { items: templates, isLoading },
        },
      },
      params: { id },
    } = this.props;
    let tickets = this.props.state.vlfCategory.items;
    console.log({ tickets });
    let { type, multiple, allowed, packages } = this.state;

    return (
      <section className="pad_2 content-width">
        <Modal isOpen={this.state.modal} toggle={this.toggle} centered={true}>
          <ModalHeader toggle={this.toggle}>New Coupon</ModalHeader>
          <ModalBody>
            <form onSubmit={this.onSubmit} className="form">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <label className="pt-label">Enter Coupon Code</label>
                    <input
                      placeholder="Code"
                      onChange={(event) =>
                        this.setState({ code: event.target.value })
                      }
                      type="text"
                      className="pt-input pt-fill"
                      name="name"
                      required
                    />
                  </p>
                </div>

                <div className="col-md-6">
                  <p>
                    <label className="pt-label">
                      Enter Maximum amount of users
                    </label>
                    <input
                      placeholder="Count"
                      onChange={(event) =>
                        this.setState({ count: event.target.value })
                      }
                      type="number"
                      className="pt-input pt-fill"
                      name="count"
                      required
                    />
                  </p>
                </div>

                <div className="col-md-6">
                  <label className="pt-label">Select coupon type</label>
                  <p className="pt-select pt-fill">
                    <select
                      placeholder="Select Type"
                      defaultValue={type}
                      onChange={(event) =>
                        this.setState({ type: event.target.value })
                      }
                      className="pt-fill"
                      name="country"
                      required
                    >
                      <option value="">Select Coupon Type</option>
                      <option value="general">General</option>
                      <option value="individual">Individual</option>
                    </select>
                  </p>
                </div>

                {/* User Email */}
                {type == 'individual' && (
                  <div className="col-md-6">
                    <p>
                      <label className="pt-label">
                        Enter email of coupon user
                      </label>
                      <input
                        placeholder="e.g oluwaseunalamu@gmail.com"
                        onChange={(event) =>
                          this.setState({ email: event.target.value })
                        }
                        type="email"
                        className="pt-input pt-fill"
                        name="email"
                        required
                      />
                    </p>
                  </div>
                )}

                <div className="col-md-6">
                  <p>
                    <label className="pt-label">
                      Enter amount to be paid after coupon use
                    </label>
                    <input
                      placeholder="e.g 1000"
                      onChange={(event) =>
                        this.setState({ amount: event.target.value })
                      }
                      type="number"
                      className="pt-input pt-fill"
                      name="amount"
                      required
                    />
                  </p>
                </div>

                <div className="col-md-6">
                  <p>
                    <label className="pt-label">
                      Enter expiry date of Coupon
                    </label>
                    <input
                      placeholder="e.g oluwaseunalamu@gmail.com"
                      onChange={(event) =>
                        this.setState({ expiry: event.target.value })
                      }
                      type="date"
                      className="pt-input pt-fill"
                      name="expiry"
                      required
                    />
                  </p>
                </div>

                <div className="col-md-6">
                  <label className="pt-label">Select coupon service</label>
                  <p className="pt-select pt-fill">
                    <select
                      placeholder="Select Type"
                      defaultValue={allowed}
                      onChange={(event) =>
                        this.setState({ allowed: event.target.value })
                      }
                      className="pt-fill"
                      name="country"
                      required
                    >
                      <option value="">Select Service</option>
                      <option value="document">Documents</option>
                      <option value="call">Calls</option>
                      <option value="ticket">Tickets</option>
                      <option value="subscription">Subscription</option>
                    </select>
                  </p>
                </div>

                {/* Tickets Type */}
                {allowed == 'ticket' && (
                  <div className="col-md-6">
                    <label className="pt-label">Select Ticket to be used</label>
                    <p className="pt-select pt-fill">
                      <select
                        placeholder="Select Type"
                        onChange={(event) =>
                          this.setState({ resourceId: event.target.value })
                        }
                        className="pt-fill"
                        name="country"
                        required
                      >
                        <option>Select an option</option>
                        {tickets.map((ticket) => (
                          <option key={ticket._id} value={ticket._id}>
                            {ticket.name}
                          </option>
                        ))}
                      </select>
                    </p>
                  </div>
                )}

                {allowed == 'subscription' && (
                  <div className="col-md-6">
                    <label className="pt-label">
                      Select Subscription to be used
                    </label>
                    <p className="pt-select pt-fill">
                      <select
                        placeholder="Select Type"
                        onChange={(event) =>
                          this.setState({
                            selectedPackageIndex: event.target.value,
                          })
                        }
                        className="pt-fill"
                        name="country"
                        required
                      >
                        <option>Select an option</option>
                        {packages.map((ticket, index) => (
                          <option key={index} value={packages[index]}>
                            {packages[index]}
                          </option>
                        ))}
                      </select>
                    </p>
                  </div>
                )}

                {/* Document Type */}
                {allowed == 'document' && (
                  <div className="col-md-6">
                    <label className="pt-label">
                      Can the coupon be used for multiple documents?
                    </label>
                    <p className="pt-select pt-fill">
                      <select
                        placeholder="Select Type"
                        onChange={(event) =>
                          this.setState({ multiple: event.target.value })
                        }
                        className="pt-fill"
                        name="multiple"
                        required
                      >
                        <option value="">Select Service</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </p>
                  </div>
                )}

                {allowed == 'document' && multiple == 'false' && (
                  <div className="col-md-6">
                    <label className="pt-label">
                      Select Document the document can be used for
                    </label>
                    <p className="pt-select pt-fill">
                      <select
                        placeholder="Select Type"
                        onChange={(event) =>
                          this.setState({ resourceId: event.target.value })
                        }
                        className="pt-fill"
                        name="country"
                        required
                      >
                        <option>Select an option</option>
                        {templates.map((ticket) => (
                          <option key={ticket._id} value={ticket._id}>
                            {ticket.title}
                          </option>
                        ))}
                      </select>
                    </p>
                  </div>
                )}
              </div>
              <hr />
              <button
                type="submit"
                onClick={(e) => this.createCoupons(e)}
                className="pt-button pt-intent-primary"
              >
                <i className /> Add Coupon
              </button>
            </form>
          </ModalBody>
        </Modal>

        <div className="d-flex justify-content-between">
          <h2 className="m-b-2">Coupons</h2>
          <button
            className="pt-button pt-intent-primary"
            onClick={this.toggle}
            type="button"
          >
            Create New
          </button>
        </div>
        {this.renderCalls()}
      </section>
    );
  }
}

export default (store) => ({
  path: `/admin/coupons`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          getTemplates: (kwargs, callback) =>
            dispatch(getTemplates(kwargs, callback)),
        })
      )(Coupons)
    );
  },
});
