import { get, post, put, delete_ } from './request';

export function create(user_id, json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SUBSCRIPTION_CREATE',
    });
    put(`user/${user_id}/subscription`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'SUBSCRIPTION_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({
            type: 'SUBSCRIPTION_CREATE_OK',
            subscription: result.json,
          });
          if (window.ga) {
            window.ga('send', 'event', 'Subscription', json.type);
          }
        }
      })
      .catch((error) => dispatch({ type: 'SUBSCRIPTION_ERROR', error }));
  };
}

export function update(user_id, _id, json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SUBSCRIPTION_UPDATE',
    });

    put(`user/${user_id}/subscription/${_id}`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'SUBSCRIPTION_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({
            type: 'SUBSCRIPTION_UPDATE_OK',
            subscription: result.json,
          });
        }
      })
      .catch((error) => dispatch({ type: 'SUBSCRIPTION_ERROR', error }));
  };
}

export function remove(user_id, _id, callback = () => {}) {
  return (dispatch) => {
    dispatch({
      type: 'SUBSCRIPTION_REMOVE',
    });

    delete_(`user/${user_id}/subscription/${_id}`, { json: {} })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'SUBSCRIPTION_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({
            type: 'SUBSCRIPTION_REMOVE_OK',
            subscription: result.json,
          });
          dispatch(me(user_id));
        }
      })
      .catch((error) => dispatch({ type: 'SUBSCRIPTION_ERROR', error }));
  };
}

export function find(user_id, _id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SUBSCRIPTION_GET',
    });

    get(`subscription/${_id}`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'SUBSCRIPTION_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({ type: 'SUBSCRIPTION_GET_OK', subscription: result.json });
        }
      })
      .catch((error) => dispatch({ type: 'SUBSCRIPTION_ERROR', error }));
  };
}

export function me(user_id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SUBSCRIPTION_GET',
    });
    get(`user/${user_id}/subscription`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'SUBSCRIPTION_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({ type: 'SUBSCRIPTION_GET_OK', subscription: result.json });
        }
      })
      .catch((error) => dispatch({ type: 'SUBSCRIPTION_ERROR', error }));
  };
}

export function pricing(callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SUBSCRIPTION_PRICING_GET',
    });

    get(`pricing`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'SUBSCRIPTION_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({ type: 'SUBSCRIPTION_PRICING_OK', pricing: result.json });
        }
      })
      .catch((error) => dispatch({ type: 'SUBSCRIPTION_ERROR', error }));
  };
}

export function packages(callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SUBSCRIPTION_PACKAGES_GET',
    });

    get(`subscription-packages`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'SUBSCRIPTION_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({ type: 'SUBSCRIPTION_PACKAGES_OK', packages: result.json });
        }
      })
      .catch((error) => dispatch({ type: 'SUBSCRIPTION_ERROR', error }));
  };
}

export function all(kwargs = {}, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SUBSCRIPTIONS_GET',
    });

    const q = kwargs.q ? `&q=${kwargs.q}` : '';
    const limit = `?limit=${kwargs.limit || 10}`;
    get(`subscriptions${limit}${q}`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'SUBSCRIPTION_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({ type: 'SUBSCRIPTIONST_OK', data: result.json });
        }
      })
      .catch((error) => dispatch({ type: 'SUBSCRIPTIONSRROR', error }));
  };
}
