const initialState = {
  name: null,
  returnTo: null,
};

export function modalReducer(state = initialState, action) {
  const { type, params, returnTo } = action;
  switch (type) {

    case 'CLOSE_MODAL':
      return Object.assign({}, state, {
        name: null,
        returnTo: null,
      });

    case 'SHOW_SIGNIN_MODAL':
      return Object.assign({}, state, {
        name: 'signinModal',
        params
      });

    case 'SHOW_SIGNUP_MODAL':
      return Object.assign({}, state, {
        name: 'signupModal',
        params
      });


    case 'SHOW_CONFIRM_MODAL':
      return Object.assign({}, state, {
        name: 'confirmModal',
        params,
        returnTo
      });

    case 'SHOW_PASSWORD_RESET_MODAL':
      return Object.assign({}, state, {
        name: 'passwordResetModal',
        params,
        returnTo
      });

    case 'SHOW_CALL_MODAL':
      return Object.assign({}, state, {
        name: 'callModal',
        params,
        returnTo
      });
    
      case 'SHOW_USER_SELECTOR_MODAL':
      return Object.assign({}, state, {
        name: 'userSelectorModal',
        params,
        returnTo
      });

    default:
      return state;
  }
}
