import { delete_, get, post, put } from './request';

export function create(user_id, card, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CARD_CREATE'
    });
    const { name, number: cardno, expiry, cvv, type } = card;
    const [expirymonth, expiryyear] = expiry.split('/').map(i => i.trim());
    const json = {
      name,
      cardno,
      expirymonth,
      type,
      cvv,
      expiryyear
    };

    post(`user/${user_id}/card`, { json }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CARD_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all(user_id));
          dispatch({ type: 'CARD_CREATE_OK', card: result.json });
        }
      }).catch(error => dispatch({ type: 'CARD_ERROR', error }));
  };
}

export function update(user_id, json, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CARD_UPDATE'
    });

    put(`user/${user_id}/card`, { json }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CARD_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch({ type: 'CARD_UPDATE_OK', card: result.json });
        }
      }).catch(error => dispatch({ type: 'CARD_ERROR', error }));
  };
}

export function find(user_id, _id, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CARD_GET'
    });

    get(`user/${user_id}/card/${_id}`, { json: true }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CARD_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch({ type: 'CARD_GET_OK', card: result.json });
        }
      }).catch(error => dispatch({ type: 'CARD_ERROR', error }));
  };
}

export function remove(user_id, _id, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CARD_REMOVE'
    });

    delete_(`user/${user_id}/card/${_id}`, { json: true }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CARD_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all(user_id));
          dispatch({ type: 'CARD_REMOVE_OK', card: result.json });
        }
      }).catch(error => dispatch({ type: 'CARD_ERROR', error }));
  };
}

export function all(user_id, kwargs = {}, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CARDS_GET'
    });

    const q = kwargs.q ? `&q=${kwargs.q}` : '';
    const limit = `?limit=${kwargs.limit || 10}`;
    get(`user/${user_id}/cards${limit}${q}`, { json: true }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CARD_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch({ type: 'CARDS_GET_OK', data: result.json });
        }
      }).catch(error => dispatch({ type: 'CARD_ERROR', error }));
  };
}

