import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { ticketTypes } from '../../data';
import { Switch } from '@blueprintjs/core';
import FileUploader from './FileUploader';
import Icon from './Icon';
import prettyFormat from 'pretty-format';

export default class TicketEdit extends React.Component {
  static defaultProps = {
    title: 'New Ticket',
    canSubmit: true,
    submitText: 'Submit Ticket',
    noSubject: false,
    canChangeStatus: false,
    ticket: {
      message: '',
      files: [],
    },
  };
  static propTypes = {
    title: PropTypes.string,
    submitText: PropTypes.string,
    canSubmit: PropTypes.bool,
    noSubject: PropTypes.bool,
    canChangeStatus: PropTypes.bool,
    vlfCategories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        _id: PropTypes.string,
      })
    ).isRequired,
    ticket: PropTypes.shape({
      subject: PropTypes.string,
      _id: PropTypes.string,
      type: PropTypes.string,
      files: PropTypes.arrayOf(PropTypes.string),
    }),
    submit: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    props.ticket.files = props.ticket.files || [];
    if (!~props.ticket.files.indexOf(null)) {
      props.ticket.files.push(null, null);
    }
    const messageQuery = window.location.search.split(/message=/)[1] || '';
    const vlfCategoryQuery = window.location.search.split(/category=/)[1] || '';
    const message = window.decodeURIComponent(messageQuery.split('&')[0] || '');
    const vlf_category = vlfCategoryQuery.split('&')[0] || null;
    this.state = {
      ticket: {
        ...props.ticket,
        message,
        vlf_category,
      },
      form_title: 'New Ticket',
      formValue: '',
      inputStat: '',
      bizness: '',
      bizness_nature: '',
      surname: '',
      other_name: '',
      date_of_birth: '',
      nationality: '',
      gender: '',
      address: '',
      email: '',
      phone_no: '',
      Ident: '',
      Ident_no: '',
      occupation: '',
      avail_no: '',
      surn: '',
      e_addr: '',
      mainbiz: '',
      prefcoy: '',
      altcoy: '',
      regaddr: '',
      sharecap: '',
      subshare: '',
      subsharedate: '',
      subshare_addr: '',
      e_subshare_addr: '',
      phone_subshare_addr: '',
      subshare_shares: '',
      dob_dir: '',
      email_dir: '',
      phone_dir: '',

      pat_name: '',
      pat_address: '',
      pat_nationality: '',
      pat_applicant: '',
      pat_title: '',
      pat_description: '',

      ngo_name: '',
      ngo_address: '',
      ngo_objectives: '',
      ngo_trustee_name: '',
      ngo_trustee_address: '',
      ngo_trustee_number: '',
      ngo_trustee_email: '',
      ngo_secretary: {
        name: '',
        address: '',
        number: '',
        email: '',
        occupation: '',
        dob: '',
        rc_number: '',
      },
      ngo_additional_info: '',

      trademark_name: '',
      trademark_owner: '',
      trademark_address: '',
      trademark_email: '',
      trademark_phone: '',
      trademark_description: '',
      trademark_class: '',

      copyright_status: '',
      copyright_owner: '',
      copyright_country: '',
      copyright_state: '',
      copyright_city: '',
      copyright_phoneNumber: '',
      copyright_email: '',
      copyright_title: '',
      copyright_type: '',
      copyright_medium: '',
      copyright_year: '',
      copyright_published: '',
      copyright_registered: '',
      copyright_coOwner: '',
      copyright_author: '',
      copyright_DOB: '',
      copyright_nationality: '',
      author_city: '',
      author_country: '',
      author_state: '',
      copyright_author_phone: '',
      copyright_author_status: '',
      copyright_coAuthor: '',
      copyright_licence: '',
      copyright_licence_address: '',
      copyright_licence_phone: '',
      copyright_licence_email: '',

      busi_name: '',
      bus_name2: '',
      bus_name3: '',
      bus_name4: '',
      trademark_rc_number: '',

      business_name_prop: [
        {
          surname: '',
          first_name: '',
          dob: '',
          nationality: '',
          gender: '',
          email: '',
          address: '',
          phone_num: '',
          occupation: '',
          identification: '',
          id_num: '',
        },
      ],
      incorporation_bus_name: '',
      incorporation_bus_name2: '',
      incorporation_bus_name3: '',
      incorporation_bus_name4: '',
      incorporation_type: '',
      incorporation_address: '',
      incorporation_object: '',
      incorporation_scope: '',
      incorporation_share: '',
      incorporation_directors: [
        {
          name: '',
          address: '',
          number: '',
          email: '',
          occupation: '',
          dob: '',
          modeOfIdentification: '',
          file: [''],
        },
        {
          name: '',
          address: '',
          number: '',
          email: '',
          occupation: '',
          dob: '',
          modeOfIdentification: '',
          file: [''],
        },
      ],
      incorporation_secretary_type: '',
      incorporation_secretary: {
        name: '',
        address: '',
        number: '',
        email: '',
        occupation: '',
        dob: '',
        rc_number: '',
      },

      //Tin Reg
      tin_name: '',
      tin_address: '',
      tin_nature: '',
      tin_date: '',
      tin_reg_num: '',
      tin_commenced: '',
      tin_directors: [
        {
          name: '',
          address: '',
          number: '',
          email: '',
        },
      ],
      tin_proprietor: [
        {
          name: '',
          address: '',
          number: '',
          email: '',
        },
      ],

      //Web Development
      web_domain: '',
      web_color: '',
      user_story: '',
      company_name: '',
      visits: '',
      about_company: '',
      company_team: '',
      company_details: '',
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.addBusName = this.addBusName.bind(this);
    this.addIncorporationDirector = this.addIncorporationDirector.bind(this);
    this.addTinDirector = this.addTinDirector.bind(this);
    this.handleTinChange = this.handleTinChange.bind(this);
    this.addTinProprietor = this.addTinProprietor.bind(this);
    this.handleTinProprietorChange = this.handleTinProprietorChange.bind(this);
    this.handleBusNameChange = this.handleBusNameChange.bind(this);
    this.handleDirectorChange = this.handleDirectorChange.bind(this);
    this.handleImageChanges = this.handleImageChanges.bind(this);
    this.handleSecretaryChange = this.handleSecretaryChange.bind(this);
    this.handleIncorporationChange = this.handleIncorporationChange.bind(this);
    this.addMylaw = this.addMylaw.bind(this);
    this.handleNGOChange = this.handleNGOChange.bind(this);
  }

  addBusName(e) {
    e.preventDefault();
    this.setState({
      business_name_prop: [
        ...this.state.business_name_prop,
        {
          surname: '',
          first_name: '',
          dob: '',
          nationality: '',
          gender: '',
          email: '',
          address: '',
          phone_num: '',
          occupation: '',
          identification: '',
          id_num: '',
        },
      ],
    });
  }

  addTinDirector(e) {
    e.preventDefault();
    this.setState({
      tin_directors: [
        ...this.state.tin_directors,
        {
          name: '',
          address: '',
          number: '',
          email: '',
        },
      ],
    });
  }

  addTinProprietor(e) {
    e.preventDefault();
    this.setState({
      tin_proprietor: [
        ...this.state.tin_proprietor,
        {
          name: '',
          address: '',
          number: '',
          email: '',
        },
      ],
    });
  }

  addIncorporationDirector(e) {
    e.preventDefault();
    this.setState({
      incorporation_directors: [
        ...this.state.incorporation_directors,
        {
          name: '',
          address: '',
          number: '',
          email: '',
          occupation: '',
          dob: '',
          modeOfIdentification: '',
          file: [''],
        },
      ],
    });
  }

  addMylaw(e, value) {
    e.preventDefault();
    let temp = {
      name: 'Legex Partners',
      address: '1B Utomi Aire Avenue, Lekki Phase 1',
      number: '08140000829',
      email: 'info@legexpartners.com',
      rc_number: '2561808',
    };
    this.setState({ [value]: temp }, () => {});
  }
  handleIncorporationChange(e) {
    let val = e.target.value;
    const { ticket } = this.state;
    this.setState({ incorporation_share: e.target.value });
    if (val == '1 million') {
      ticket.price = 60000;
      this.setState({ ticket });
    }
    if (val == '2 million') {
      ticket.price = 80000;
      this.setState({ ticket });
    }
    if (val == '3 million') {
      ticket.price = 140000;
      this.setState({ ticket });
    }
    if (val == '4 million') {
      ticket.price = 120000;
      this.setState({ ticket });
    }
    if (val == '5 million') {
      ticket.price = 140000;
      this.setState({ ticket });
    }
    if (val == '6 million') {
      ticket.price = 160000;
      this.setState({ ticket });
    }
    if (val == '7 million') {
      ticket.price = 180000;
      this.setState({ ticket });
    }
    if (val == '8 million') {
      ticket.price = 200000;
      this.setState({ ticket });
    }
    if (val == '9 million') {
      ticket.price = 220000;
      this.setState({ ticket });
    }
    if (val == '10 million') {
      ticket.price = 240000;
      this.setState({ ticket });
    }
  }

  handleDirectorChange(e, index, prop) {
    let temp = this.state.incorporation_directors;
    let temp2 = Object.assign({}, this.state.incorporation_directors[index], {
      [prop]: e.target.value,
    });
    temp[index] = temp2;
    this.setState({ incorporation_directors: temp }, () => {});
  }

  handleTinChange(e, index, prop) {
    let temp = this.state.tin_directors;
    let temp2 = Object.assign({}, this.state.tin_directors[index], {
      [prop]: e.target.value,
    });
    temp[index] = temp2;
    this.setState({ tin_directors: temp }, () => {});
  }

  handleTinProprietorChange(e, index, prop) {
    let temp = this.state.tin_proprietor;
    let temp2 = Object.assign({}, this.state.tin_proprietor[index], {
      [prop]: e.target.value,
    });
    temp[index] = temp2;
    this.setState({ tin_proprietor: temp }, () => {});
  }

  handleNGOChange(e, prop) {
    let temp = this.state.ngo_secretary;
    let temp2 = Object.assign({}, this.state.ngo_secretary, {
      [prop]: e.target.value,
    });
    // temp[index] = temp2
    this.setState({ ngo_secretary: temp2 }, () => {});
  }

  handleSecretaryChange(e, prop) {
    let temp = this.state.incorporation_secretary;
    let temp2 = Object.assign({}, this.state.incorporation_secretary, {
      [prop]: e.target.value,
    });
    // temp[index] = temp2
    this.setState({ incorporation_secretary: temp2 }, () => {});
  }

  handleImageChanges(index, url) {
    let temp = this.state.incorporation_directors;
    let temp2 = Object.assign({}, this.state.incorporation_directors[index], {
      ['file']: [url],
    });
    temp[index] = temp2;
    this.setState({ incorporation_directors: temp }, () => {});
  }

  handleBusNameChange(e, index, prop) {
    let temp = this.state.business_name_prop;
    let temp2 = Object.assign({}, this.state.business_name_prop[index], {
      [prop]: e.target.value,
    });
    temp[index] = temp2;
    this.setState({ business_name_prop: temp }, () => {});
  }

  componentDidMount() {
    const ticketIdMap = {
      '5bbf11d3c7d6fe0013bd996d': 'Business Name',
      '5d603d6c3c5af11bf1242134': 'Copyright Registration',
      '5a395bafa788660014a74e56': 'Document Review',
      '5a3171cdeded770014f78f14': 'Company Incoporation',
      '5d6abdf93405c70d10137372': 'Legal Letters/Emails',
      '5cdd8fc1d93f225b6ece9e1f': 'Patent Registration',
      '5ce81be023eb7a212538ae80': 'Trademark Registration',
      '5ef614fcd5b9f9c0229400b5': 'Basic Website',
    };
    var tod = new Date();
    if (this.state.ticket.vlf_category === '5bbf11d3c7d6fe0013bd996d') {
      localStorage.setItem('userStringX', '5432aeod7891');
      this.setState(
        {
          formValue: '5bbf11d3c7d6fe0013bd996d',
          form_title: 'Business Name Registration',
        },
        () => {}
      );
    } else if (this.state.ticket.vlf_category === '5a3171cdeded770014f78f14') {
      this.setState(
        {
          formValue: '5a3171cdeded770014f78f14',
          form_title: 'Company Incorporation',
        },
        () => {}
      );
    } else if (this.state.ticket.vlf_category === '5ce6ccb0c3a033159e71e1bb') {
      this.setState(
        {
          formValue: '5ce6ccb0c3a033159e71e1bb',
          form_title: 'NGO/Incorporated Trustee Registration',
        },
        () => {}
      );
    } else if (this.state.ticket.vlf_category === '5ce81be023eb7a212538ae80') {
      this.setState(
        {
          formValue: '5ce81be023eb7a212538ae80',
          form_title: 'Trademark Registration',
        },
        () => {}
      );
    } else if (this.state.ticket.vlf_category === '5d603d6c3c5af11bf1242134') {
      this.setState(
        {
          formValue: '5d603d6c3c5af11bf1242134',
          form_title: 'Copyright Registration',
        },
        () => {}
      );
    } else if (this.state.ticket.vlf_category === '5cdd8fc1d93f225b6ece9e1f') {
      this.setState(
        {
          formValue: '5cdd8fc1d93f225b6ece9e1f',
          form_title: 'Patent Registration',
        },
        () => {}
      );
    } else if (this.state.ticket.vlf_category === '5dee3a5c376eaf7e253464e4') {
      this.setState(
        {
          formValue: '5dee3a5c376eaf7e253464e4',
          form_title: 'Tin Registration',
        },
        () => {}
      );
    } else if (this.state.ticket.vlf_category === '5ef614fcd5b9f9c0229400b5') {
      this.setState(
        {
          formValue: '5ef614fcd5b9f9c0229400b5',
          form_title: 'Basic Website',
        },
        () => {}
      );
    } else if (this.state.ticket.vlf_category === '5a395bafa788660014a74e56') {
      this.setState(
        {
          formValue: '5a395bafa788660014a74e56',
          form_title: 'Document Review',
        },
        () => {}
      );
    } else if (this.state.ticket.vlf_category === '5d6abdf93405c70d10137372') {
      this.setState(
        {
          formValue: '5d6abdf93405c70d10137372',
          form_title: 'Legal Letter/Email',
        },
        () => {}
      );
    }
  }

  handleRemoveFile = (idx) => {
    const { ticket } = this.state;
    ticket.files.splice(idx, 1);
    this.setState({ ticket });
  };
  handleChange = (e) => {
    const { name, value, checked } = e.target || e.srcElement;
    const ticket = this.state.ticket;
    ticket[name] = value;
    switch (name) {
      case 'status':
        ticket.status = checked ? 'closed' : 'open';
        break;
      case 'bizness':
        this.setState({ bizness: value });
        break;
      case 'bizness_nature':
        this.setState({ bizness_nature: value });
        break;
      case 'surname':
        this.setState({ surname: value });
        break;
      case 'other_name':
        this.setState({ other_name: value });
        break;
      case 'date_of_birth':
        this.setState({ date_of_birth: value });
        break;
      case 'nationality':
        this.setState({ nationality: value });
        break;
      case 'gender':
        this.setState({ gender: value });
        break;
      case 'address':
        this.setState({ address: value });
        break;
      case 'email':
        this.setState({ email: value });
        break;
      case 'phone_no':
        this.setState({ phone_no: value });
        break;
      case 'Ident':
        this.setState({ Ident: value });
        break;
      case 'Ident_no':
        this.setState({ Ident_no: value });
        break;
      case 'occupation':
        this.setState({ occupation: value });
        break;
      case 'avail_no':
        this.setState({ avail_no: value });
        break;
      case 'surn':
        this.setState({ surn: value });
        break;
      case 'e_addr':
        this.setState({ e_addr: value });
        break;
      case 'mainbiz':
        this.setState({ mainbiz: value });
        break;
      case 'prefcoy':
        this.setState({ prefcoy: value });
        break;
      case 'altcoy':
        this.setState({ altcoy: value });
        break;
      case 'regaddr':
        this.setState({ regaddr: value });
        break;
      case 'sharecap':
        this.setState({ sharecap: value });
        break;
      case 'subshare':
        this.setState({ subshare: value });
        break;
      case 'subsharedate':
        this.setState({ subsharedate: value });
        break;
      case 'subshare_addr':
        this.setState({ subshare_addr: value });
        break;
      case 'e_subshare_addr':
        this.setState({ e_subshare_addr: value });
        break;
      case 'phone_subshare_addr':
        this.setState({ phone_subshare_addr: value });
        break;
      case 'subshare_shares':
        this.setState({ subshare_shares: value });
        break;
      case 'dob_dir':
        this.setState({ dob_dir: value });
        break;
      case 'email_dir':
        this.setState({ email_dir: value });
        break;
      case 'phone_dir':
        this.setState({ phone_dir: value });
        break;
      default:
        ticket[name] = value;
    }
    this.setState({ ticket });
    this.setState({ formValue: ticket.vlf_category });
    this.setState({ vlf_category: ticket.vlf_category });
    const result = this.props.vlfCategories.find(
      (category) => category._id === ticket.vlf_category
    );
    this.setState({ form_title: result.name });
  };

  handleSelectChange(event) {
    this.setState(
      { vlf_category: event.target.value, formValue: event.target.value },
      () => {}
    );
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const { submit, canChangeStatus, noSubject, vlfCategories } = this.props;
    const {
      ticket,
      isClosed,
      bizness,
      bizness_nature,
      surname,
      other_name,
      date_of_birth,
      nationality,
      gender,
      address,
      email,
      phone_no,
      Ident,
      Ident_no,
      occupation,
      avail_no,
      surn,
      e_addr,
      mainbiz,
      prefcoy,
      altcoy,
      regaddr,
      sharecap,
      subshare,
      subsharedate,
      subshare_addr,
      e_subshare_addr,
      phone_subshare_addr,
      subshare_shares,
      dob_dir,
      email_dir,
      phone_dir,
      pat_name,
      pat_address,
      pat_nationality,
      pat_applicant,
      pat_title,
      pat_description,
      ngo_name,
      ngo_address,
      ngo_objectives,
      ngo_trustee_name,
      ngo_trustee_address,
      ngo_trustee_number,
      ngo_trustee_email,
      ngo_secretary_name,
      ngo_secretary_telephone,
      ngo_secretary_address,
      ngo_secretary_email,
      ngo_additional_info,
      trademark_name,
      trademark_owner,
      trademark_address,
      trademark_email,
      trademark_phone,
      trademark_description,
      author_city,
      author_country,
      author_state,
      copyright_status,
      copyright_owner,
      copyright_country,
      copyright_state,
      copyright_city,
      copyright_phoneNumber,
      copyright_email,
      copyright_title,
      copyright_type,
      copyright_medium,
      copyright_year,
      copyright_published,
      copyright_registered,
      copyright_coOwner,
      copyright_author,
      copyright_DOB,
      copyright_nationality,
      copyright_author_address,
      copyright_author_phone,
      copyright_author_status,
      copyright_coAuthor,
      copyright_licence,
      copyright_licence_address,
      copyright_licence_phone,
      copyright_licence_email,
      busi_name,
      bus_name2,
      bus_name3,
      bus_name4,
      business_name_prop,
      trademark_rc_number,
      incorporation_bus_name,
      incorporation_bus_name2,
      incorporation_bus_name3,
      incorporation_bus_name4,
      incorporation_type,
      incorporation_address,
      incorporation_object,
      incorporation_scope,
      incorporation_share,
      incorporation_directors,
      incorporation_secretary,
      ngo_secretary,
      tin_name,
      tin_address,
      tin_nature,
      tin_date,
      tin_reg_num,
      tin_commenced,
      tin_directors,
      tin_proprietor,

      web_domain,
      web_color,
      user_story,
      company_name,
      visits,
      about_company,
      company_team,
      company_details,
    } = this.state;

    this.setState({ ticket });
    ticket.subject = '';
    if (this.state.formValue === '5bbf11d3c7d6fe0013bd996d') {
      ticket.message = `${
        ticket.message
      } \n Business Name:${busi_name} \nAlternative Business Name 1: ${bus_name2} \nAlternative Business Name 2: ${bus_name3} \nAlternative Business Name 4: ${bus_name4} \n Business Nature: ${bizness_nature} Proprietors: ${prettyFormat(
        business_name_prop
      )}`;

      this.setState({ ticket });
    } else if (this.state.formValue === '5a3171cdeded770014f78f14') {
      ticket.vlf_category = '5a3171cdeded770014f78f14';
      ticket.shareCapital = incorporation_share;
      ticket.message = `${
        ticket.message
      } \n Business Name 1: ${incorporation_bus_name} \n Business Name 2: ${incorporation_bus_name2} \n Business Name 3: ${incorporation_bus_name3} \n Business Name 4: ${incorporation_bus_name4} \n Incorporation Type: ${incorporation_type} \n Incorporation Address: ${incorporation_address} \n Incorporation Object: ${incorporation_object} \n Incorporation Scope: ${incorporation_scope} \n Share Capital: ${incorporation_share} \n Directors: ${prettyFormat(
        incorporation_directors
      )}
       \n Secretaries: ${prettyFormat(incorporation_secretary)}`;
      this.setState({ ticket });
    } else if (this.state.formValue === '5cdd8fc1d93f225b6ece9e1f') {
      ticket.message = `${ticket.message} \n Name of Applicant: ${pat_name} \nAddress of Applicant ${pat_address} \nNationality of Applicant ${pat_nationality} \n Is the applicant the same as Inventor ${pat_applicant} \n Title of invention ${pat_title} \n Description ${pat_description}`;

      this.setState({ ticket });
    } else if (this.state.formValue === '5ce6ccb0c3a033159e71e1bb') {
      ticket.message = `${
        ticket.message
      } \nName of Association: ${ngo_name} \nAddress of Association ${ngo_address} \nObjectives of Association ${ngo_objectives} \nTrustee Name ${ngo_trustee_name} \nTrustee Address ${ngo_trustee_address} \nTrustee Phone Number ${ngo_trustee_number} \nTrustee Email ${ngo_trustee_email} \nNgo Secretary Name:  ${prettyFormat(
        ngo_secretary
      )} \nAdditional Info ${ngo_additional_info} `;
      this.setState({ ticket });
    } else if (this.state.formValue === '5ce81be023eb7a212538ae80') {
      ticket.message = `${ticket.message} \nTrademark Name: ${trademark_name} \nOwner: ${trademark_owner} \nAddress: ${trademark_address} \nEmail: ${trademark_email} \nPhone Number: ${trademark_phone} \nDescription: ${trademark_description} \nRC Number: ${trademark_rc_number}`;
      this.setState({ ticket });
    } else if (this.state.formValue === '5d603d6c3c5af11bf1242134') {
      ticket.message = `${ticket.message} \nApplicant Status: ${copyright_status} \nName of Copyright Owner: ${copyright_owner} \nCountry of Copyright Owner: ${copyright_country} \nState of Copyright Owner: ${copyright_state} \nCity of Copyright Owner: ${copyright_city} \nTelephone number: ${copyright_phoneNumber} \nEmail address: ${copyright_email} \nTitle of work: ${copyright_title} \nType of work: ${copyright_type} \nThe medium of Fixation: ${copyright_medium} \nYear of creation: ${copyright_year} \nIs the work published? ${copyright_published} \nHas the work been previously registered? ${copyright_registered} \nIs there a co-owner of the work? ${copyright_coOwner}\nAuthor's name: ${copyright_author} \nAuthor's date of birth: ${copyright_DOB} \nAuthor's nationality: ${copyright_nationality} \nAuthor's Country: ${author_country} \nAuthor's State: ${author_state} \nAuthor's City: ${author_city} \nTelephone number: ${copyright_author_phone}\nIs the Author living or dead? ${copyright_author_status} \nIs/Are there co-author(s)? ${copyright_coAuthor} \nName of person or organization to contact for licenses and permission: ${copyright_licence} \nAddress: ${copyright_licence_address} \nTelephone number: ${copyright_licence_phone}  \nEmail address: ${copyright_licence_email}`;
      this.setState({ ticket });
    } else if (this.state.formValue === '5dee3a5c376eaf7e253464e4') {
      ticket.message = `${
        ticket.message
      } \nName of Company/Business: ${tin_name} \nRegistered Address of  Company/Business: ${tin_address} \nNature of Business: ${tin_nature} \nRegistration Date on certificate: ${tin_date} \nCompany/Business Registration Number: ${tin_reg_num} \nHas your business/Company Commenced: ${tin_commenced} \nDirectors:  ${prettyFormat(
        tin_directors
      )} \nProprietors:  ${prettyFormat(tin_proprietor)} `;
      this.setState({ ticket });
    } else if (this.state.formValue === '5ef614fcd5b9f9c0229400b5') {
      ticket.message = `${ticket.message} \nDesired Web Domain: ${web_domain} \n Your desired website primary and secondary colour: ${web_color} \n A brief description of your user story.: ${user_story} \nYour business / company name:: ${company_name} \nVisits: ${visits} \nAbout Company: ${about_company}  \nTeam Mates: ${company_team}  \nCompany Information: ${company_details} `;
      this.setState({ ticket });
    }

    let test = this.props.vlfCategories;
    let tckID = test.filter((cat) => {
      if (this.state.vlf_category) {
        return cat.name.toLowerCase() === this.state.vlf_category.toLowerCase();
      }
    });

    if (!canChangeStatus) {
      delete ticket.status;
    }
    // if (!noSubject) {
    //   ticket.vlf_category = this.state.vlf_category || ticket.vlf_castategory || Object.keys(vlfCategories)[0]._id;
    // }

    submit(ticket, (err) => {
      console.table(ticket);
      if (!err) {
        if (this.messageElem) {
          this.messageElem.value = '';
        }
        if (this.subjectElem) {
          this.subjectElem.value = '';
        }
        this.setState({
          ticket: {
            message: '',
            files: [null],
          },
        });
      }
    });
  };
  render() {
    const {
      title,
      cancel,
      canChangeStatus,
      createFile,
      noSubject,
      submitText,
      canSubmit,
      vlfCategories,
    } = this.props;
    const { ticket, isClosed, form_title } = this.state;
    return (
      <div className="pt-card pt-elevation-1">
        <h4>{form_title}</h4>
        {canChangeStatus && (
          <div className="pull-right">
            <Switch
              name="status"
              checked={ticket.status === 'closed'}
              label="Close Ticket?"
              onChange={this.handleChange}
            />
          </div>
        )}
        <div className="TicketPanel">
          {this.state.formValue === '5bbf11d3c7d6fe0013bd996d' ? (
            <div>
              <p>
                Become your own boss! Fill in the required information, attach
                all means of identification and submit. Documents will be sent
                to the client to review and affix signature.
              </p>{' '}
              <br />
              <form onSubmit={this.handleSubmit}>
                <p>
                  <label className="pt-label">
                    What is your Preferred Name of Business?
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    onChange={(event) =>
                      this.setState({ busi_name: event.target.value })
                    }
                    name="message"
                    placeholder="Enter name of business"
                    required
                  />
                </p>
                <p>
                  <label className="pt-label">
                    What is your 2nd Most Preferred Name of the Business?
                  </label>
                  <input
                    type="text"
                    onChange={(event) =>
                      this.setState({ bus_name2: event.target.value })
                    }
                    className="pt-input pt-fill"
                    placeholder="Enter 2nd most preferred business name"
                    required
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is your 3rd Most Preferred Name of the Business?
                  </label>
                  <input
                    type="text"
                    onChange={(event) =>
                      this.setState({ bus_name3: event.target.value })
                    }
                    className="pt-input pt-fill"
                    placeholder="Enter 3rd most preferred business name"
                    required
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is your 4th Most Preferred Name of the Business?
                  </label>
                  <input
                    type="text"
                    onChange={(event) =>
                      this.setState({ bus_name4: event.target.value })
                    }
                    className="pt-input pt-fill"
                    placeholder="Enter 4th most preferred business name"
                    required
                  />
                </p>
                <div>
                  <label className="pt-label">
                    What is the General Nature of the Business?
                  </label>
                  <p className="pt-select pt-fill">
                    <select
                      readOnly
                      placeholder="Select category"
                      onChange={(event) =>
                        this.setState({ bizness_nature: event.target.value })
                      }
                      className="pt-fill"
                      name="bizness_nature"
                      required
                    >
                      <option label="Select one:" />
                      <option>Accomodation and Food Services </option>
                      <option>
                        Activities of Extraterritorial Organization and Bodies{' '}
                      </option>
                      <option>
                        Administrative and Support Service Activities{' '}
                      </option>
                      <option>Agriculture Forestry and Fisheries </option>
                      <option>Art, Entertainment and Recreation </option>
                      <option>Community Based Association </option>
                      <option>Construction</option>
                      <option>Cultural Based Applications</option>
                      <option>Education</option>
                      <option>Faith Based Association</option>
                      <option>Financial and Insurance Activities</option>
                      <option>Foundation Based Association</option>
                      <option>Human Health and Social Work Activities</option>
                      <option>Information and Communication</option>
                      <option>Manufacturing</option>
                      <option>Mining and Quarrying</option>
                      <option>Other Service Activities</option>
                      <option>Others</option>
                      <option>Power</option>
                      <option>
                        Professional, Scientific and Technical Activities
                      </option>
                      <option>Public Administration and Defense</option>
                      <option>Real Estate Activities</option>
                      <option>Repairs of Motor vehicles and Motocycles</option>
                      <option>Social Club Based Association</option>
                      <option>Sporting Based Association</option>
                      <option>Transportation</option>
                      <option>
                        WaterSupply, Sewage, Waste management and Remediation
                        Activities
                      </option>
                      <option>
                        Wholesale and Retail Trade,Repair of Motor Vehicles and
                        Motorcycles
                      </option>
                    </select>
                  </p>
                </div>

                {this.state.business_name_prop.map((person, index) => {
                  return (
                    <div key={index}>
                      <p>
                        <label className="pt-label">
                          What is the Surname of the Proprietor?
                        </label>
                        <input
                          type="text"
                          rows={3}
                          className="pt-input pt-fill"
                          value={this.state.business_name_prop[index].surname}
                          onChange={(e) =>
                            this.handleBusNameChange(e, index, 'surname')
                          }
                          name="surname"
                          placeholder="Enter surname of Proprietor"
                          required
                        />
                      </p>
                      <p>
                        <label className="pt-label">
                          What are the other names of the Proprietor? (First
                          name, middle name)
                        </label>
                        <input
                          type="text"
                          rows={3}
                          className="pt-input pt-fill"
                          value={
                            this.state.business_name_prop[index].first_name
                          }
                          onChange={(e) =>
                            this.handleBusNameChange(e, index, 'first_name')
                          }
                          placeholder="Enter Other name of proprietor"
                          required
                        />
                      </p>
                      <p>
                        <label className="pt-label">
                          What is the date of birth of the Proprietor?
                        </label>
                        <input
                          className="pt-input pt-fill"
                          value={this.state.business_name_prop[index].dob}
                          onChange={(e) =>
                            this.handleBusNameChange(e, index, 'dob')
                          }
                          type="date"
                          placeholder="Enter ticket subject"
                          required
                        />
                      </p>
                      <p>
                        <label className="pt-label">
                          What is the Nationality of the Proprietor?
                        </label>
                        <input
                          type="text"
                          rows={3}
                          className="pt-input pt-fill"
                          value={
                            this.state.business_name_prop[index].nationality
                          }
                          onChange={(e) =>
                            this.handleBusNameChange(e, index, 'nationality')
                          }
                          placeholder="Enter nationality of proprietor"
                          required
                        />
                      </p>
                      <div>
                        <label className="pt-label">
                          What is the Gender of the Proprietor?
                        </label>
                        <p className="pt-select pt-fill">
                          <select
                            readOnly
                            placeholder="Select category"
                            value={this.state.business_name_prop[index].gender}
                            onChange={(e) =>
                              this.handleBusNameChange(e, index, 'gender')
                            }
                            className="pt-fill"
                            name="gender"
                            required
                          >
                            <option label="Select one:" />
                            <option>Male</option>
                            <option>Female</option>
                          </select>
                        </p>
                      </div>
                      <p>
                        <label className="pt-label">
                          What is the contact address of the Proprietor? (State,
                          City, LGA)
                        </label>
                        <input
                          type="text"
                          rows={3}
                          className="pt-input pt-fill"
                          value={this.state.business_name_prop[index].address}
                          onChange={(e) =>
                            this.handleBusNameChange(e, index, 'address')
                          }
                          placeholder="Enter Address"
                          required
                        />
                      </p>
                      <p>
                        <label className="pt-label">
                          What is the email address of the proprietor?
                        </label>
                        <input
                          className="pt-input pt-fill"
                          value={this.state.business_name_prop[index].email}
                          onChange={(e) =>
                            this.handleBusNameChange(e, index, 'email')
                          }
                          name="email"
                          type="email"
                          placeholder="Enter ticket subject"
                          required
                        />
                      </p>
                      <p>
                        <label className="pt-label">
                          Please state your Phone Number?
                        </label>
                        <input
                          className="pt-input pt-fill"
                          value={this.state.business_name_prop[index].phone_num}
                          onChange={(e) =>
                            this.handleBusNameChange(e, index, 'phone_num')
                          }
                          name="phone_no"
                          type="number"
                          placeholder="Enter phone number"
                          required
                        />
                      </p>
                      <div>
                        <label className="pt-label">
                          What is the preferred form of Identification?
                        </label>
                        <p className="pt-select pt-fill">
                          <select
                            readOnly
                            placeholder="Select category"
                            value={
                              this.state.business_name_prop[index]
                                .identification
                            }
                            onChange={(e) =>
                              this.handleBusNameChange(
                                e,
                                index,
                                'identification'
                              )
                            }
                            className="pt-fill"
                            name="Ident"
                            required
                          >
                            <option label="Select one:" />
                            <option>Birth Certificate</option>
                            <option>International Passport</option>
                            <option>Permanent Voters Card</option>
                            <option>Drivers License</option>
                          </select>
                        </p>
                      </div>
                      <p>
                        <label className="pt-label">
                          Please state your ID No
                        </label>
                        <input
                          className="pt-input pt-fill"
                          value={this.state.business_name_prop[index].id_num}
                          onChange={(e) =>
                            this.handleBusNameChange(e, index, 'id_num')
                          }
                          name="Ident_no"
                          type="text"
                          placeholder="Enter ID Card number"
                          required
                        />
                      </p>
                      <p>
                        <label className="pt-label">
                          What is the occupation of the proprietor?
                        </label>
                        <input
                          className="pt-input pt-fill"
                          value={
                            this.state.business_name_prop[index].occupation
                          }
                          onChange={(e) =>
                            this.handleBusNameChange(e, index, 'occupation')
                          }
                          name="occupation"
                          type="text"
                          placeholder="Enter occupation"
                          required
                        />
                      </p>
                    </div>
                  );
                })}

                <button className="btn btn-primary" onClick={this.addBusName}>
                  Add Proprietor
                </button>

                <div className="">
                  <label className="pt-label">
                    Please attach your passport and ID Card(PDF file)
                  </label>
                  <div>
                    {ticket.files.map((file, idx) => {
                      let isUploading = false;
                      return (
                        <div key={idx} className="file-upload-box">
                          <FileUploader
                            path="tickets/"
                            create={createFile}
                            file={file}
                            onUpload={(err, res) => {
                              if (!err) {
                                ticket.files[idx] = res.url;
                                if (ticket.files.length <= 5) {
                                  ticket.files.push(null);
                                }
                                this.setState({ ticket });
                              }
                            }}
                          />
                          {file && (
                            <div>
                              <a
                                className="remove"
                                onClick={() => this.handleRemoveFile(idx)}
                              >
                                <Icon name="remove_circle" />
                              </a>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <p>
                  <button
                    disabled={!canSubmit}
                    className="pt-button pt-intent-primary pull-right"
                  >
                    {submitText}
                  </button>
                  {cancel && (
                    <button
                      type="button"
                      className="pt-button pt-intent-danger"
                      onClick={cancel}
                    >
                      Cancel
                    </button>
                  )}
                </p>
                <div className="clearfix" />
              </form>
            </div>
          ) : this.state.formValue === '5cdd8fc1d93f225b6ece9e1f' ? (
            <div>
              <p>
                Patent your novel idea or invention today! Fill in the required
                information, attach document and submit. Documents will be sent
                to the client to review.
              </p>
              <br />
              <form onSubmit={this.handleSubmit}>
                <p>
                  <label className="pt-label">
                    What is the name of the Applicant? ( State your full name,
                    Surname in caps lock)
                  </label>
                  <input
                    type="text"
                    value={this.state.pat_name}
                    onChange={(event) =>
                      this.setState({ pat_name: event.target.value })
                    }
                    className="form-control"
                    placeholder="What is the name of the Applicant? ( State your full name, Surname in caps lock)"
                  />
                </p>
                <p>
                  <label className="pt-label">
                    What is the address of the Applicant?
                  </label>
                  <input
                    type="text"
                    value={this.state.pat_address}
                    onChange={(event) =>
                      this.setState({ pat_address: event.target.value })
                    }
                    className="form-control"
                    placeholder="What is the address of the Applicant? "
                  />
                </p>
                <p>
                  <label className="pt-label">
                    What is the Nationality of the Applicant?
                  </label>
                  <input
                    type="text"
                    value={this.state.pat_nationality}
                    onChange={(event) =>
                      this.setState({ pat_nationality: event.target.value })
                    }
                    className="form-control"
                    placeholder="What is the Nationality of the Applicant? "
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the title of the invention ?
                  </label>
                  <input
                    type="text"
                    value={this.state.pat_title}
                    onChange={(event) =>
                      this.setState({ pat_title: event.target.value })
                    }
                    className="form-control"
                    placeholder="What is the title of the invention ?"
                  />
                </p>

                <label className="pt-label">
                  Briefly explain the invention and what makes it unique.
                  (Application must relate to a single invention. Explanation
                  must be clear and succinct . Please ensure your claims include
                  neither more nor less than you desire to protect by the
                  patent. Any unnecessary multiplicity or claims or prolixity of
                  language should be avoided.)
                </label>
                <textarea
                  value={this.state.pat_description}
                  rows={3}
                  className="pt-input pt-fill"
                  onChange={(event) =>
                    this.setState({ pat_description: event.target.value })
                  }
                  name="message"
                  placeholder="Enter ticket message"
                  required
                />

                <label>Is the Applicant the same as the Inventor?</label>
                <p className="pt-select pt-fill">
                  <select
                    className="pt-fill"
                    value={this.state.pat_applicant}
                    onChange={(event) =>
                      this.setState({ pat_applicant: event.target.value })
                    }
                  >
                    <option>Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </p>

                <div className="">
                  <label className="pt-label">
                    Kindly attach a means of identification of the applicant (
                    National ID Card, International Passport, Permanent Voters
                    Card or Drivers License)
                  </label>
                  <div>
                    {ticket.files.map((file, idx) => {
                      let isUploading = false;
                      return (
                        <div key={idx} className="file-upload-box">
                          <FileUploader
                            path="tickets/"
                            create={createFile}
                            file={file}
                            onUpload={(err, res) => {
                              if (!err) {
                                ticket.files[idx] = res.url;
                                if (ticket.files.length <= 5) {
                                  ticket.files.push(null);
                                }
                                this.setState({ ticket });
                              }
                            }}
                          />
                          {file && (
                            <div>
                              <a
                                className="remove"
                                onClick={() => this.handleRemoveFile(idx)}
                              >
                                <Icon name="remove_circle" />
                              </a>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <p>
                  <button
                    disabled={!canSubmit}
                    className="pt-button pt-intent-primary pull-right"
                  >
                    {submitText}
                  </button>
                  {cancel && (
                    <button
                      type="button"
                      className="pt-button pt-intent-danger"
                      onClick={cancel}
                    >
                      Cancel
                    </button>
                  )}
                </p>
                <div className="clearfix" />
              </form>
            </div>
          ) : this.state.formValue === '5dee3a5c376eaf7e253464e4' ? (
            <div>
              <form onSubmit={this.handleSubmit}>
                <p>
                  <label className="pt-label">
                    What is the Name of Company/Business
                  </label>
                  <input
                    type="text"
                    value={this.state.tin_name}
                    onChange={(event) =>
                      this.setState({ tin_name: event.target.value })
                    }
                    className="form-control"
                    placeholder="What is the Name of Company/Business"
                  />
                </p>
                <p>
                  <label className="pt-label">
                    What is the Registered Address of Company/Business?
                  </label>
                  <input
                    type="text"
                    value={this.state.tin_address}
                    onChange={(event) =>
                      this.setState({ tin_address: event.target.value })
                    }
                    className="form-control"
                    placeholder="What is the Registered Address of Company/Business? "
                  />
                </p>
                <p>
                  <label className="pt-label">
                    What is the Nature of Business?
                  </label>
                  <input
                    type="text"
                    value={this.state.tin_nature}
                    onChange={(event) =>
                      this.setState({ tin_nature: event.target.value })
                    }
                    className="form-control"
                    placeholder="What is the Nature of Business? "
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the Company/Business Registration Number?
                  </label>
                  <input
                    type="text"
                    value={this.state.tin_reg_num}
                    onChange={(event) =>
                      this.setState({ tin_reg_num: event.target.value })
                    }
                    className="form-control"
                    placeholder="What is the Company/Business Registration Number? "
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the Registration Date on certificate?
                  </label>
                  <input
                    type="date"
                    value={this.state.tin_date}
                    onChange={(event) =>
                      this.setState({ tin_date: event.target.value })
                    }
                    className="form-control"
                    placeholder="What is the Registration Date on certificate?"
                  />
                </p>

                <label>Has your business/Company Commenced?</label>
                <p className="pt-select pt-fill">
                  <select
                    className="pt-fill"
                    value={this.state.tin_commenced}
                    onChange={(event) =>
                      this.setState({ tin_commenced: event.target.value })
                    }
                  >
                    <option>Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </p>

                {this.state.tin_directors.map((person, index) => {
                  return (
                    <div key={index}>
                      <p>
                        <label className="pt-label">
                          What is the Full Name of the Director {index + 1}?
                        </label>
                        <input
                          type="text"
                          className="pt-input pt-fill"
                          value={this.state.tin_directors[index].name}
                          onChange={(e) =>
                            this.handleTinChange(e, index, 'name')
                          }
                          name="name"
                          placeholder="Enter full Name of the director"
                          required
                        />
                      </p>

                      <p>
                        <label className="pt-label">
                          What is the Address of the director {index + 1}?
                        </label>
                        <input
                          type="text"
                          className="pt-input pt-fill"
                          value={this.state.tin_directors[index].address}
                          onChange={(e) =>
                            this.handleTinChange(e, index, 'address')
                          }
                          name="address"
                          placeholder="Enter address of director"
                          required
                        />
                      </p>

                      <p>
                        <label className="pt-label">
                          What is the Phone Number of the director {index + 1}?
                        </label>
                        <input
                          type="text"
                          className="pt-input pt-fill"
                          value={this.state.tin_directors[index].number}
                          onChange={(e) =>
                            this.handleTinChange(e, index, 'number')
                          }
                          name="number"
                          placeholder="What is the Phone Number of the director?"
                          required
                        />
                      </p>

                      <p>
                        <label className="pt-label">
                          What is the Email of the director {index + 1}?
                        </label>
                        <input
                          type="email"
                          className="pt-input pt-fill"
                          value={this.state.tin_directors[index].email}
                          onChange={(e) =>
                            this.handleTinChange(e, index, 'email')
                          }
                          name="email"
                          placeholder="Enter email of director"
                          required
                        />
                      </p>
                    </div>
                  );
                })}
                <button
                  className="btn m-2 btn-primary"
                  onClick={this.addTinDirector}
                >
                  Add Director
                </button>

                {this.state.tin_proprietor.map((person, index) => {
                  return (
                    <div key={index}>
                      <p>
                        <label className="pt-label">
                          What is the Full Name of the Proprietor {index + 1}?
                        </label>
                        <input
                          type="text"
                          className="pt-input pt-fill"
                          value={this.state.tin_proprietor[index].name}
                          onChange={(e) =>
                            this.handleTinProprietorChange(e, index, 'name')
                          }
                          name="name"
                          placeholder="Enter full Name of the proprietor"
                          required
                        />
                      </p>

                      <p>
                        <label className="pt-label">
                          What is the Address of the Proprietor {index + 1}?
                        </label>
                        <input
                          type="text"
                          className="pt-input pt-fill"
                          value={this.state.tin_proprietor[index].address}
                          onChange={(e) =>
                            this.handleTinProprietorChange(e, index, 'address')
                          }
                          name="address"
                          placeholder="Enter address of proprietor"
                          required
                        />
                      </p>

                      <p>
                        <label className="pt-label">
                          What is the Phone Number of the proprietor {index + 1}
                          ?
                        </label>
                        <input
                          type="text"
                          className="pt-input pt-fill"
                          value={this.state.tin_proprietor[index].number}
                          onChange={(e) =>
                            this.handleTinProprietorChange(e, index, 'number')
                          }
                          name="number"
                          placeholder="What is the Phone Number of the proprietor?"
                          required
                        />
                      </p>

                      <p>
                        <label className="pt-label">
                          What is the Email of the proprietor {index + 1}?
                        </label>
                        <input
                          type="email"
                          className="pt-input pt-fill"
                          value={this.state.tin_proprietor[index].email}
                          onChange={(e) =>
                            this.handleTinProprietorChange(e, index, 'email')
                          }
                          name="email"
                          placeholder="Enter email of proprietor"
                          required
                        />
                      </p>
                    </div>
                  );
                })}
                <button
                  className="btn m-2 btn-primary"
                  onClick={this.addTinProprietor}
                >
                  Add Proprietor
                </button>

                <div className="">
                  <label className="pt-label">
                    Upload CAC 1, Memart, and Certificate (For Company) Upload
                    BN Form 1 and Certificate (For Business){' '}
                  </label>
                  <div>
                    {ticket.files.map((file, idx) => {
                      let isUploading = false;
                      return (
                        <div key={idx} className="file-upload-box">
                          <FileUploader
                            path="tickets/"
                            create={createFile}
                            file={file}
                            onUpload={(err, res) => {
                              if (!err) {
                                ticket.files[idx] = res.url;
                                if (ticket.files.length <= 5) {
                                  ticket.files.push(null);
                                }
                                this.setState({ ticket });
                              }
                            }}
                          />
                          {file && (
                            <div>
                              <a
                                className="remove"
                                onClick={() => this.handleRemoveFile(idx)}
                              >
                                <Icon name="remove_circle" />
                              </a>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <p>
                  <button
                    disabled={!canSubmit}
                    className="pt-button pt-intent-primary pull-right"
                  >
                    {submitText}
                  </button>
                  {cancel && (
                    <button
                      type="button"
                      className="pt-button pt-intent-danger"
                      onClick={cancel}
                    >
                      Cancel
                    </button>
                  )}
                </p>
                <div className="clearfix" />
              </form>
            </div>
          ) : this.state.formValue === '5ce6ccb0c3a033159e71e1bb' ? (
            <div>
              <form onSubmit={this.handleSubmit}>
                <p>
                  <label className="pt-label">
                    What is the name of the Applicant? ( State your full name,
                    Surname in caps lock)
                  </label>
                  <input
                    type="text"
                    value={this.state.ngo_name}
                    onChange={(event) =>
                      this.setState({ ngo_name: event.target.value })
                    }
                    className="form-control"
                    placeholder="What is the name of the association?"
                  />
                </p>
                <p>
                  <label className="pt-label">
                    What is the address of the association?
                  </label>
                  <input
                    type="text"
                    value={this.state.ngo_address}
                    onChange={(event) =>
                      this.setState({ ngo_address: event.target.value })
                    }
                    className="form-control"
                    placeholder="What is the address of the association? "
                  />
                </p>
                <p>
                  <label className="pt-label">
                    What are the objectives of the association?
                  </label>
                  <input
                    type="text"
                    value={this.state.ngo_objectives}
                    onChange={(event) =>
                      this.setState({ ngo_objectives: event.target.value })
                    }
                    className="form-control"
                    placeholder="What are the objectives of the association? "
                  />
                </p>

                <p>
                  <label className="pt-label">Enter Trustee Name</label>
                  <input
                    type="text"
                    value={this.state.ngo_trustee_name}
                    onChange={(event) =>
                      this.setState({ ngo_trustee_name: event.target.value })
                    }
                    className="form-control"
                    placeholder="e.g John Doe"
                  />
                </p>

                <p>
                  <label className="pt-label">Enter Trustee Address</label>
                  <input
                    type="text"
                    value={this.state.ngo_trustee_address}
                    onChange={(event) =>
                      this.setState({ ngo_trustee_address: event.target.value })
                    }
                    className="form-control"
                    placeholder="e.g No 1 Aso Rock Villa, White House"
                  />
                </p>

                <p>
                  <label className="pt-label">Enter Trustee Email</label>
                  <input
                    type="text"
                    value={this.state.ngo_trustee_email}
                    onChange={(event) =>
                      this.setState({ ngo_trustee_email: event.target.value })
                    }
                    className="form-control"
                    placeholder="e.g me@mail.com"
                  />
                </p>

                <p>
                  <label className="pt-label">Enter Trustee Phone Number</label>
                  <input
                    type="number"
                    value={this.state.ngo_trustee_number}
                    onChange={(event) =>
                      this.setState({ ngo_trustee_number: event.target.value })
                    }
                    className="form-control"
                    placeholder="e.g 08011111111"
                  />
                </p>

                <p>
                  Company Secretary Details (Must be one of Lawyer, Chartered
                  Accountant, Chartered Secretaries, Law Firm){' '}
                </p>
                <span className="text-muted">
                  Don't have a company secretary? No problem{' '}
                </span>
                <button
                  className="btn m-2 btn-primary"
                  onClick={(e) => this.addMylaw(e, 'ngo_secretary')}
                >
                  Use Mylaw as Secretary
                </button>
                <p>
                  <label className="pt-label">
                    What is name of the company secretary?
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    value={this.state.ngo_secretary.name}
                    onChange={(e) => this.handleNGOChanged(e, 'name')}
                    name="sec_name"
                    placeholder="Enter Name of Secretary"
                    required
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the address of company secretary?
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    value={this.state.ngo_secretary.address}
                    onChange={(e) => this.handleNGOChange(e, 'address')}
                    name="sec_address"
                    placeholder="Enter address of company secretary"
                    required
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is phone number Company?
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    value={this.state.ngo_secretary.number}
                    onChange={(e) => this.handleNGOChange(e, 'number')}
                    name="sec_number"
                    placeholder="Enter phone number Company"
                    required
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the email of company secretary?
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    value={this.state.ngo_secretary.email}
                    onChange={(e) => this.handleNGOChange(e, 'email')}
                    name="sec_email"
                    placeholder="Enter email of company secretary?"
                    required
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the occupation of Company secretary?{' '}
                    <span className="text-muted">
                      {' '}
                      *Only needed if company secretary is an individual{' '}
                    </span>
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    value={this.state.ngo_secretary.occupation}
                    onChange={(e) => this.handleNGOChange(e, 'occupation')}
                    name="sec_occupation"
                    placeholder="Enter occupation of Company secretary"
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the Date of Birth of Company secretary?{' '}
                    <span className="text-muted">
                      {' '}
                      *Only needed if company secretary is an indivudual{' '}
                    </span>
                  </label>
                  <input
                    type="date"
                    className="pt-input pt-fill"
                    value={this.state.ngo_secretary.dob}
                    onChange={(e) => this.handleNGOChange(e, 'dob')}
                    name="sec_dob"
                    placeholder="Enter date of Birth of Company secretary"
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is RC Number of Company?{' '}
                    <span className="text-muted">
                      {' '}
                      *Only needed if company secretary is a law firm{' '}
                    </span>
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    value={this.state.ngo_secretary.rc_number}
                    onChange={(e) => this.handleNGOChange(e, 'rc_number')}
                    name="sec_rc_number"
                    placeholder="Enter RC Number of Company"
                  />
                </p>

                <p>
                  <label className="pt-label">
                    Enter Additional Information
                  </label>
                  <input
                    type="text"
                    value={this.state.ngo_additional_info}
                    onChange={(event) =>
                      this.setState({ ngo_additional_info: event.target.value })
                    }
                    className="form-control"
                    placeholder="e.g Story Story"
                  />
                </p>

                <p>
                  <button
                    disabled={!canSubmit}
                    className="pt-button pt-intent-primary pull-right"
                  >
                    {submitText}
                  </button>
                  {cancel && (
                    <button
                      type="button"
                      className="pt-button pt-intent-danger"
                      onClick={cancel}
                    >
                      Cancel
                    </button>
                  )}
                </p>
                <div className="clearfix" />

                <div className="">
                  <label className="pt-label">
                    Kindly attach your passport
                  </label>
                  <div>
                    {ticket.files.map((file, idx) => {
                      let isUploading = false;
                      return (
                        <div key={idx} className="file-upload-box">
                          <FileUploader
                            path="tickets/"
                            create={createFile}
                            file={file}
                            onUpload={(err, res) => {
                              if (!err) {
                                ticket.files[idx] = res.url;
                                if (ticket.files.length <= 5) {
                                  ticket.files.push(null);
                                }
                                this.setState({ ticket });
                              }
                            }}
                          />
                          {file && (
                            <div>
                              <a
                                className="remove"
                                onClick={() => this.handleRemoveFile(idx)}
                              >
                                <Icon name="remove_circle" />
                              </a>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </form>
            </div>
          ) : this.state.formValue === '5d603d6c3c5af11bf1242134' ? (
            <div>
              <p>
                Fill in the required information, attach document and submit.
                Documents will be sent to the client to review and affix
                signature.
              </p>
              <br />
              <form onSubmit={this.handleSubmit}>
                <p className="pt-select pt-fill">
                  <label className="pt-label">Applicant Status</label>
                  <select
                    className="pt-fill"
                    value={this.state.copyright_status}
                    onChange={(event) =>
                      this.setState({ copyright_status: event.target.value })
                    }
                  >
                    <option>Select an option</option>
                    <option value="Author">Author</option>
                    <option value="CopyrightOwner">Copyright Owner</option>
                    <option value="Assignee">Assignee</option>
                    <option value="Licensee">Licensee</option>
                  </select>
                </p>
                <p>
                  <label className="pt-label">Name of Copyright Owner?</label>
                  <input
                    type="text"
                    value={this.state.copyright_owner}
                    onChange={(event) =>
                      this.setState({ copyright_owner: event.target.value })
                    }
                    className="form-control"
                    placeholder="Name of Copyright Owner? "
                  />
                </p>

                <div className="row">
                  <p className="col-md-4">
                    <label className="pt-label">
                      Country of Copyright Owner?
                    </label>
                    <input
                      type="text"
                      value={this.state.copyright_country}
                      onChange={(event) =>
                        this.setState({ copyright_country: event.target.value })
                      }
                      className="form-control"
                      placeholder="Country of Copyright Owner? "
                    />
                  </p>

                  <p className="col-md-4">
                    <label className="pt-label">
                      State of Copyright Owner?
                    </label>
                    <input
                      type="text"
                      value={this.state.copyright_state}
                      onChange={(event) =>
                        this.setState({ copyright_state: event.target.value })
                      }
                      className="form-control"
                      placeholder="State of Copyright Owner? "
                    />
                  </p>

                  <p className="col-md-4">
                    <label className="pt-label">City of Copyright Owner?</label>
                    <input
                      type="text"
                      value={this.state.copyright_city}
                      onChange={(event) =>
                        this.setState({ copyright_city: event.target.value })
                      }
                      className="form-control"
                      placeholder="City of Copyright Owner? "
                    />
                  </p>
                </div>

                <p>
                  <label className="pt-label">Telephone number</label>
                  <input
                    type="text"
                    value={this.state.copyright_phoneNumber}
                    onChange={(event) =>
                      this.setState({
                        copyright_phoneNumber: event.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Telephone number"
                  />
                </p>

                <p>
                  <label className="pt-label">Email address</label>
                  <input
                    type="text"
                    value={this.state.copyright_email}
                    onChange={(event) =>
                      this.setState({ copyright_email: event.target.value })
                    }
                    className="form-control"
                    placeholder="Email address"
                  />
                </p>

                <p>
                  <label className="pt-label">Title of work</label>
                  <input
                    type="text"
                    value={this.state.copyright_title}
                    onChange={(event) =>
                      this.setState({ copyright_title: event.target.value })
                    }
                    className="form-control"
                    placeholder="Title of work"
                  />
                </p>

                <p className="pt-select pt-fill">
                  <label className="pt-label">Type of work</label>
                  <select
                    className="pt-fill"
                    value={this.state.copyright_type}
                    onChange={(event) =>
                      this.setState({ copyright_type: event.target.value })
                    }
                  >
                    <option>Select an option</option>
                    <option value="Literary Work">Literary Work</option>
                    <option value="Musical Work">Musical Work</option>
                    <option value="Sound Recording">Sound Recording</option>
                    <option value="Artistic Work">Artistic Work</option>
                    <option value="Cinematography Film">
                      Cinematography Film
                    </option>
                  </select>
                </p>

                <p className="pt-select pt-fill">
                  <label className="pt-label">The medium of Fixation</label>
                  <select
                    className="pt-fill"
                    value={this.state.copyright_medium}
                    onChange={(event) =>
                      this.setState({ copyright_medium: event.target.value })
                    }
                  >
                    <option>Select an option</option>
                    <option value="CD">CD</option>
                    <option value="DVD">DVD</option>
                    <option value="VCD">VCD</option>
                    <option value="Paper">Paper</option>
                  </select>
                </p>

                <p>
                  <label className="pt-label">Year of creation</label>
                  <input
                    type="text"
                    value={this.state.copyright_year}
                    onChange={(event) =>
                      this.setState({ copyright_year: event.target.value })
                    }
                    className="form-control"
                    placeholder="Year of creation"
                  />
                </p>

                <p className="pt-select pt-fill">
                  <label className="pt-label">Is the work published?</label>
                  <select
                    className="pt-fill"
                    value={this.state.copyright_published}
                    onChange={(event) =>
                      this.setState({ copyright_published: event.target.value })
                    }
                  >
                    <option>Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </p>

                <p className="pt-select pt-fill">
                  <label className="pt-label">
                    Has the work been previously registered?
                  </label>
                  <select
                    className="pt-fill"
                    value={this.state.copyright_registered}
                    onChange={(event) =>
                      this.setState({
                        copyright_registered: event.target.value,
                      })
                    }
                  >
                    <option>Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </p>

                <p className="pt-select pt-fill">
                  <label className="pt-label">
                    Is there a co-owner of the work?
                  </label>
                  <select
                    className="pt-fill"
                    value={this.state.copyright_coOwner}
                    onChange={(event) =>
                      this.setState({ copyright_coOwner: event.target.value })
                    }
                  >
                    <option>Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </p>

                <p>
                  <label className="pt-label">Author's name</label>
                  <input
                    type="text"
                    value={this.state.copyright_author}
                    onChange={(event) =>
                      this.setState({ copyright_author: event.target.value })
                    }
                    className="form-control"
                    placeholder="Author's name"
                  />
                </p>

                <p>
                  <label className="pt-label">Author's date of birth</label>
                  <input
                    type="text"
                    value={this.state.copyright_DOB}
                    onChange={(event) =>
                      this.setState({ copyright_DOB: event.target.value })
                    }
                    className="form-control"
                    placeholder="Author's date of birth"
                  />
                </p>

                <p>
                  <label className="pt-label">Author's nationality</label>
                  <input
                    type="text"
                    value={this.state.copyright_nationality}
                    onChange={(event) =>
                      this.setState({
                        copyright_nationality: event.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Author's nationality"
                  />
                </p>

                <div className="row">
                  <p className="col-md-4">
                    <label className="pt-label">
                      Country of Copyright Author Owner?
                    </label>
                    <input
                      type="text"
                      value={this.state.copyright_country}
                      onChange={(event) =>
                        this.setState({ author_country: event.target.value })
                      }
                      className="form-control"
                      placeholder="Country of Copyright Author Owner? "
                    />
                  </p>

                  <p className="col-md-4">
                    <label className="pt-label">
                      State of Copyright Author Owner?
                    </label>
                    <input
                      type="text"
                      value={this.state.author_state}
                      onChange={(event) =>
                        this.setState({ author_state: event.target.value })
                      }
                      className="form-control"
                      placeholder="State of Copyright Author Owner? "
                    />
                  </p>

                  <p className="col-md-4">
                    <label className="pt-label">
                      City of Copyright Author Owner?
                    </label>
                    <input
                      type="text"
                      value={this.state.author_city}
                      onChange={(event) =>
                        this.setState({ author_city: event.target.value })
                      }
                      className="form-control"
                      placeholder="City of Copyright Author Owner? "
                    />
                  </p>
                </div>

                <p>
                  <label className="pt-label">Telephone number</label>
                  <input
                    type="text"
                    value={this.state.copyright_author_phone}
                    onChange={(event) =>
                      this.setState({
                        copyright_author_phone: event.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Telephone number"
                  />
                </p>

                <p className="pt-select pt-fill">
                  <label className="pt-label">
                    Is the Author living or dead?
                  </label>
                  <select
                    className="pt-fill"
                    value={this.state.copyright_author_status}
                    onChange={(event) =>
                      this.setState({
                        copyright_author_status: event.target.value,
                      })
                    }
                  >
                    <option>Select an option</option>
                    <option value="Living">Living</option>
                    <option value="Dead">Dead</option>
                  </select>
                </p>

                <p>
                  <label className="pt-label">Year of creation</label>
                  <input
                    type="text"
                    value={this.state.copyright_year}
                    onChange={(event) =>
                      this.setState({ copyright_year: event.target.value })
                    }
                    className="form-control"
                    placeholder="Year of creation"
                  />
                </p>

                <p className="pt-select pt-fill">
                  <label className="pt-label">Is/Are there co-author(s)?</label>
                  <select
                    className="pt-fill"
                    value={this.state.copyright_author}
                    onChange={(event) =>
                      this.setState({ copyright_author: event.target.value })
                    }
                  >
                    <option>Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </p>

                <p>
                  <label className="pt-label">
                    Name of person or organization to contact for licenses and
                    permission
                  </label>
                  <input
                    type="text"
                    value={this.state.copyright_licence}
                    onChange={(event) =>
                      this.setState({ copyright_licence: event.target.value })
                    }
                    className="form-control"
                    placeholder="Name of person or organization to contact for licenses and permission"
                  />
                </p>

                <p>
                  <label className="pt-label">Address</label>
                  <input
                    type="text"
                    value={this.state.copyright_licence_address}
                    onChange={(event) =>
                      this.setState({
                        copyright_licence_address: event.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Address"
                  />
                </p>

                <p>
                  <label className="pt-label">Telephone number</label>
                  <input
                    type="text"
                    value={this.state.copyright_licence_phone}
                    onChange={(event) =>
                      this.setState({
                        copyright_licence_phone: event.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Telephone number"
                  />
                </p>

                <p>
                  <label className="pt-label">Email address</label>
                  <input
                    type="text"
                    value={this.state.copyright_licence_email}
                    onChange={(event) =>
                      this.setState({
                        copyright_licence_email: event.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Email address"
                  />
                </p>

                <p>
                  <button
                    disabled={!canSubmit}
                    className="pt-button pt-intent-primary pull-right"
                  >
                    {submitText}
                  </button>
                  {cancel && (
                    <button
                      type="button"
                      className="pt-button pt-intent-danger"
                      onClick={cancel}
                    >
                      Cancel
                    </button>
                  )}
                </p>
                <div className="clearfix" />
              </form>
            </div>
          ) : this.state.formValue === '5ce81be023eb7a212538ae80' ? (
            <div>
              <p>
                Protect your business Identity! Fill in the required
                information, attach document, and submit. Documents will be sent
                to the client to review.
              </p>
              <br />
              <form onSubmit={this.handleSubmit}>
                <p>
                  <label className="pt-label">Name of Product</label>
                  <input
                    type="text"
                    value={this.state.trademark_name}
                    onChange={(event) =>
                      this.setState({ trademark_name: event.target.value })
                    }
                    className="form-control"
                    placeholder="Name of Product"
                  />
                </p>
                <p>
                  <label className="pt-label">
                    Name of the owner of the trademark (must be a registered
                    business or company)
                  </label>
                  <input
                    type="text"
                    value={this.state.trademark_owner}
                    onChange={(event) =>
                      this.setState({ trademark_owner: event.target.value })
                    }
                    className="form-control"
                    placeholder="Name of the owner of the trademark (must be a registered business or
                        company)"
                  />
                </p>
                <p>
                  <label className="pt-label">Registered Address?</label>
                  <input
                    type="text"
                    value={this.state.trademark_address}
                    onChange={(event) =>
                      this.setState({ trademark_address: event.target.value })
                    }
                    className="form-control"
                    placeholder="Registered Address? "
                  />
                </p>

                <p>
                  <label className="pt-label">Email Address:</label>
                  <input
                    type="text"
                    value={this.state.trademark_email}
                    onChange={(event) =>
                      this.setState({ trademark_email: event.target.value })
                    }
                    className="form-control"
                    placeholder="e.g mail@mail.com"
                  />
                </p>

                <p>
                  <label className="pt-label">Phone number:</label>
                  <input
                    type="text"
                    value={this.state.trademark_phone}
                    onChange={(event) =>
                      this.setState({ trademark_phone: event.target.value })
                    }
                    className="form-control"
                    placeholder="e.g 08011111111"
                  />
                </p>

                <p>
                  <label className="pt-label">Description of Product</label>
                  <input
                    type="text"
                    value={this.state.trademark_description}
                    onChange={(event) =>
                      this.setState({
                        trademark_description: event.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Description of Product"
                  />
                </p>

                <p>
                  <label className="pt-label">RC Number:</label>
                  <input
                    type="number"
                    value={this.state.trademark_rc_number}
                    onChange={(event) =>
                      this.setState({ trademark_rc_number: event.target.value })
                    }
                    className="form-control"
                    placeholder="RC Number"
                  />
                </p>

                <div className="">
                  <label className="pt-label">
                    Kindly Attach a scanned copy of logo (Preferably black and
                    white)
                  </label>
                  <div>
                    {ticket.files.map((file, idx) => {
                      let isUploading = false;
                      return (
                        <div key={idx} className="file-upload-box">
                          <FileUploader
                            path="tickets/"
                            create={createFile}
                            file={file}
                            onUpload={(err, res) => {
                              if (!err) {
                                ticket.files[idx] = res.url;
                                if (ticket.files.length <= 5) {
                                  ticket.files.push(null);
                                }
                                this.setState({ ticket });
                              }
                            }}
                          />
                          {file && (
                            <div>
                              <a
                                className="remove"
                                onClick={() => this.handleRemoveFile(idx)}
                              >
                                <Icon name="remove_circle" />
                              </a>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <p>
                  <button
                    disabled={!canSubmit}
                    className="pt-button pt-intent-primary pull-right"
                  >
                    {submitText}
                  </button>
                  {cancel && (
                    <button
                      type="button"
                      className="pt-button pt-intent-danger"
                      onClick={cancel}
                    >
                      Cancel
                    </button>
                  )}
                </p>
                <div className="clearfix" />
              </form>
            </div>
          ) : this.state.formValue === '5ef614fcd5b9f9c0229400b5' ? (
            <div>
              <p>
                Provide details for your basic website. Kindly note that we
                would be creating a basic website using the Wordpress Content
                Management System. Note further that payment does not cover
                subsequent annual Domain and Hosting Renewal for which we charge
                NGN 10,000 (Ten Thousand Naira per year).
              </p>
              <br />
              <form onSubmit={this.handleSubmit}>
                <p>
                  <label className="pt-label">Your desired domain name</label>
                  <input
                    type="text"
                    value={this.state.web_domain}
                    onChange={(event) =>
                      this.setState({ web_domain: event.target.value })
                    }
                    className="form-control"
                    placeholder="e.g. myname.com"
                  />
                </p>
                <p>
                  <label className="pt-label">
                    Your desired website primary and secondary colour.
                  </label>
                  <input
                    type="text"
                    value={this.state.web_color}
                    onChange={(event) =>
                      this.setState({ web_color: event.target.value })
                    }
                    className="form-control"
                    placeholder="e.g Blue and White"
                  />
                </p>
                <p>
                  <label className="pt-label">
                    A brief description of your user story.
                  </label>
                  <textarea
                    value={this.state.user_story}
                    onChange={(event) =>
                      this.setState({ user_story: event.target.value })
                    }
                    className="form-control"
                    placeholder="A brief description "
                  ></textarea>
                </p>

                <p>
                  <label className="pt-label">
                    Your business / company name:
                  </label>
                  <input
                    type="text"
                    value={this.state.company_name}
                    onChange={(event) =>
                      this.setState({ company_name: event.target.value })
                    }
                    className="form-control"
                    placeholder="e.g Mylaw"
                  />
                </p>

                <p>
                  <label className="pt-label">
                    The prospective amount of visitors you estimate your site
                    would be getting:
                  </label>
                  <input
                    type="text"
                    value={this.state.visits}
                    onChange={(event) =>
                      this.setState({ visits: event.target.value })
                    }
                    className="form-control"
                    placeholder="e.g 200"
                  />
                </p>

                <p>
                  <label className="pt-label">
                    Tell us about your Business and what the site is about - to
                    be put in the “About Us” Page
                  </label>
                  <textarea
                    value={this.state.about_company}
                    onChange={(event) =>
                      this.setState({ about_company: event.target.value })
                    }
                    className="form-control"
                    placeholder="About your company "
                  ></textarea>
                </p>

                <p>
                  <label className="pt-label">
                    Details of teammates (if any)
                  </label>
                  <textarea
                    value={this.state.company_team}
                    onChange={(event) =>
                      this.setState({ company_team: event.target.value })
                    }
                    className="form-control"
                    placeholder="About your company "
                  ></textarea>
                </p>

                <p>
                  <label className="pt-label">
                    Business Contact Information: Email, Phone, address, Social
                    media (facebook, twitter, Instagram, Linkedin).
                  </label>
                  <textarea
                    value={this.state.company_details}
                    onChange={(event) =>
                      this.setState({ company_details: event.target.value })
                    }
                    className="form-control"
                    placeholder="Email, Phone Number, Address "
                  ></textarea>
                </p>

                <div className="">
                  <label className="pt-label">
                    Please upload all media: Pictures, Videos, Logo, Header,
                    Background.
                  </label>
                  <div>
                    {ticket.files.map((file, idx) => {
                      let isUploading = false;
                      return (
                        <div key={idx} className="file-upload-box">
                          <FileUploader
                            path="tickets/"
                            create={createFile}
                            file={file}
                            onUpload={(err, res) => {
                              if (!err) {
                                ticket.files[idx] = res.url;
                                if (ticket.files.length <= 5) {
                                  ticket.files.push(null);
                                }
                                this.setState({ ticket });
                              }
                            }}
                          />
                          {file && (
                            <div>
                              <a
                                className="remove"
                                onClick={() => this.handleRemoveFile(idx)}
                              >
                                <Icon name="remove_circle" />
                              </a>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <p>
                  <button
                    disabled={!canSubmit}
                    className="pt-button pt-intent-primary pull-right"
                  >
                    {submitText}
                  </button>
                  {cancel && (
                    <button
                      type="button"
                      className="pt-button pt-intent-danger"
                      onClick={cancel}
                    >
                      Cancel
                    </button>
                  )}
                </p>
                <div className="clearfix" />
              </form>
            </div>
          ) : this.state.formValue === '5a3171cdeded770014f78f14' ? (
            <div>
              <p>
                Limit your personal liability! Fill in the required information,
                attach all means of identification and submit. Documents will be
                sent to the client to review and affix signature
              </p>
              <br />
              <form onSubmit={this.handleSubmit}>
                <p>
                  <label className="pt-label">
                    What is the main business of the company?
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    onChange={(event) =>
                      this.setState({
                        incorporation_bus_name: event.target.value,
                      })
                    }
                    value={this.state.incorporation_bus_name}
                    placeholder="Enter preferred business name"
                    required
                  />
                </p>
                <p>
                  <label className="pt-label">
                    What is your 2nd most preferred company name?
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    onChange={(event) =>
                      this.setState({
                        incorporation_bus_name2: event.target.value,
                      })
                    }
                    value={this.state.incorporation_bus_name2}
                    placeholder="What is your 2nd most preferred company name"
                    required
                  />
                </p>
                <p>
                  <label className="pt-label">
                    What is your 3rd most preferred company name?
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    onChange={(event) =>
                      this.setState({
                        incorporation_bus_name3: event.target.value,
                      })
                    }
                    value={this.state.incorporation_bus_name3}
                    placeholder="What is your 3rd most preferred company name?"
                    required
                  />
                </p>
                <p>
                  <label className="pt-label">
                    What is your 4th most preferred company name?
                  </label>
                  <textarea
                    className="pt-input pt-fill"
                    onChange={(event) =>
                      this.setState({
                        incorporation_bus_name4: event.target.value,
                      })
                    }
                    value={this.state.incorporation_bus_name4}
                    placeholder="What is your 4th most preferred company name?"
                    required
                  />
                </p>

                <p className="pt-select pt-fill">
                  <label className="pt-label">
                    What is the type of company to be incorporated?
                  </label>
                  <select
                    className="pt-fill"
                    value={this.state.incorporation_type}
                    onChange={(event) =>
                      this.setState({ incorporation_type: event.target.value })
                    }
                  >
                    <option>Select an option</option>
                    <option value="Limited by shares">Limited by shares</option>
                    <option value="Limited by Guarantee">
                      Limited by Guarantee
                    </option>
                    <option value="Unlimited">Unlimited</option>
                  </select>
                </p>
                <p>
                  <label className="pt-label">
                    State the proposed registered address of the company
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    onChange={(event) =>
                      this.setState({
                        incorporation_address: event.target.value,
                      })
                    }
                    value={this.state.incorporation_address}
                    placeholder="State the proposed registered address of the company"
                    required
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the object of business
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    onChange={(event) =>
                      this.setState({
                        incorporation_object: event.target.value,
                      })
                    }
                    value={this.state.incorporation_object}
                    placeholder="What is the object of business"
                    required
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the scope of operation
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    onChange={(event) =>
                      this.setState({ incorporation_scope: event.target.value })
                    }
                    value={this.state.incorporation_scope}
                    placeholder="What is the scope of operation"
                    required
                  />
                </p>

                {this.state.incorporation_directors.map((person, index) => {
                  return (
                    <div key={index}>
                      <p>
                        <label className="pt-label">
                          What is the Full Name of the director {index + 1}?
                        </label>
                        <input
                          type="text"
                          className="pt-input pt-fill"
                          value={this.state.incorporation_directors[index].name}
                          onChange={(e) =>
                            this.handleDirectorChange(e, index, 'name')
                          }
                          name="name"
                          placeholder="Enter full Name of the director"
                          required
                        />
                      </p>

                      <p>
                        <label className="pt-label">
                          What is the Address of the director {index + 1}?
                        </label>
                        <input
                          type="text"
                          className="pt-input pt-fill"
                          value={
                            this.state.incorporation_directors[index].address
                          }
                          onChange={(e) =>
                            this.handleDirectorChange(e, index, 'address')
                          }
                          name="address"
                          placeholder="Enter address of director"
                          required
                        />
                      </p>

                      <p>
                        <label className="pt-label">
                          What is the Phone Number of the director {index + 1}?
                        </label>
                        <input
                          type="text"
                          className="pt-input pt-fill"
                          value={
                            this.state.incorporation_directors[index].number
                          }
                          onChange={(e) =>
                            this.handleDirectorChange(e, index, 'number')
                          }
                          name="number"
                          placeholder="What is the Phone Number of the director?"
                          required
                        />
                      </p>

                      <p>
                        <label className="pt-label">
                          What is the Email of the director {index + 1}?
                        </label>
                        <input
                          type="email"
                          className="pt-input pt-fill"
                          value={
                            this.state.incorporation_directors[index].email
                          }
                          onChange={(e) =>
                            this.handleDirectorChange(e, index, 'email')
                          }
                          name="email"
                          placeholder="Enter email of director"
                          required
                        />
                      </p>

                      <p>
                        <label className="pt-label">
                          What is the Occupation of the director {index + 1}?
                        </label>
                        <input
                          type="text"
                          className="pt-input pt-fill"
                          value={
                            this.state.incorporation_directors[index].occupation
                          }
                          onChange={(e) =>
                            this.handleDirectorChange(e, index, 'occupation')
                          }
                          name="occupation"
                          placeholder="Enter occupation of director"
                          required
                        />
                      </p>

                      <p>
                        <label className="pt-label">
                          What is the Date of Birth of the director {index + 1}?
                        </label>
                        <input
                          type="date"
                          className="pt-input pt-fill"
                          value={this.state.incorporation_directors[index].dob}
                          onChange={(e) =>
                            this.handleDirectorChange(e, index, 'dob')
                          }
                          name="dob"
                          placeholder="Enter date of birth of director"
                          required
                        />
                      </p>

                      <p className="pt-select pt-fill">
                        <label className="pt-label">
                          What is the mode of identification of director{' '}
                          {index + 1}?
                        </label>
                        <select
                          className="pt-fill"
                          value={
                            this.state.incorporation_directors[index]
                              .modeOfIdentification
                          }
                          onChange={(e) =>
                            this.handleDirectorChange(
                              e,
                              index,
                              'modeOfIdentification'
                            )
                          }
                        >
                          <option>Select an option</option>
                          <option value="Voter’s cards">Voter’s cards</option>
                          <option value="Driver’s licence">
                            Driver’s licence
                          </option>
                          <option value="International Passport">
                            International Passport
                          </option>
                        </select>
                      </p>

                      <div className="">
                        <label className="pt-label">
                          Kindly attach your ID
                        </label>
                        <div>
                          {this.state.incorporation_directors[index].file.map(
                            (file, idx) => {
                              let isUploading = false;
                              return (
                                <div key={idx} className="file-upload-box">
                                  <FileUploader
                                    path="tickets/"
                                    create={createFile}
                                    file={file}
                                    onUpload={(err, res) => {
                                      if (err) {
                                        console.log(err);

                                        // ticket.files[idx] = res.url;
                                        // if (ticket.files.length <= 5) {
                                        //   ticket.files.push(null);
                                        // }
                                        // this.setState({ ticket });
                                      } else {
                                        this.handleImageChanges(index, res.url);
                                      }
                                    }}
                                  />
                                  {file && (
                                    <div>
                                      <a
                                        className="remove"
                                        onClick={() =>
                                          this.handleRemoveFile(idx)
                                        }
                                      >
                                        <Icon name="remove_circle" />
                                      </a>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}

                <button
                  className="btn m-2 btn-primary"
                  onClick={this.addIncorporationDirector}
                >
                  Add Director
                </button>

                <p className="pt-select pt-fill">
                  <label className="pt-label">
                    What is the share capital of the company??
                  </label>
                  <select
                    className="pt-fill"
                    value={this.state.incorporation_share}
                    onChange={(e) => this.handleIncorporationChange(e)}
                  >
                    <option>Select an option</option>
                    <option value="1 million">1 million - N60,000</option>
                    <option value="2 million">2 million- N 80,000</option>
                    <option value="3 million">3 million -N100,000</option>
                    <option value="4 million">4 million - N120,000</option>
                    <option value="5 million">5 million - N140,000</option>
                    <option value="6 million">6 million - N160,000</option>
                    <option value="7 million">7 million -N180,000</option>
                    <option value="8 million">8 million - N200,000</option>
                    <option value="9 million">9 million- N220,000</option>
                    <option value="10 million">10 million- N240,000</option>
                  </select>
                </p>

                <p>
                  Company Secretary Details (Must be one of Lawyer, Chartered
                  Accountant, Chartered Secretaries, Law Firm){' '}
                </p>
                <span className="text-muted">
                  Don't have a company secretary? No problem{' '}
                </span>
                <button
                  className="btn m-2 btn-primary"
                  onClick={(e) => this.addMylaw(e, 'incorporation_secretary')}
                >
                  Use Mylaw as Secretary
                </button>
                <p>
                  <label className="pt-label">
                    What is name of the company secretary?
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    value={this.state.incorporation_secretary.name}
                    onChange={(e) => this.handleSecretaryChange(e, 'name')}
                    name="sec_name"
                    placeholder="Enter Name of Secretary"
                    required
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the address of company secretary?
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    value={this.state.incorporation_secretary.address}
                    onChange={(e) => this.handleSecretaryChange(e, 'address')}
                    name="sec_address"
                    placeholder="Enter address of company secretary"
                    required
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is phone number Company?
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    value={this.state.incorporation_secretary.number}
                    onChange={(e) => this.handleSecretaryChange(e, 'number')}
                    name="sec_number"
                    placeholder="Enter phone number Company"
                    required
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the email of company secretary?
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    value={this.state.incorporation_secretary.email}
                    onChange={(e) => this.handleSecretaryChange(e, 'email')}
                    name="sec_email"
                    placeholder="Enter email of company secretary?"
                    required
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the occupation of Company secretary?{' '}
                    <span className="text-muted">
                      {' '}
                      *Only needed if company secretary is an individual{' '}
                    </span>
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    value={this.state.incorporation_secretary.occupation}
                    onChange={(e) =>
                      this.handleSecretaryChange(e, 'occupation')
                    }
                    name="sec_occupation"
                    placeholder="Enter occupation of Company secretary"
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is the Date of Birth of Company secretary?{' '}
                    <span className="text-muted">
                      {' '}
                      *Only needed if company secretary is an indivudual{' '}
                    </span>
                  </label>
                  <input
                    type="date"
                    className="pt-input pt-fill"
                    value={this.state.incorporation_secretary.dob}
                    onChange={(e) => this.handleSecretaryChange(e, 'dob')}
                    name="sec_dob"
                    placeholder="Enter date of Birth of Company secretary"
                  />
                </p>

                <p>
                  <label className="pt-label">
                    What is RC Number of Company?{' '}
                    <span className="text-muted">
                      {' '}
                      *Only needed if company secretary is a law firm{' '}
                    </span>
                  </label>
                  <input
                    type="text"
                    className="pt-input pt-fill"
                    value={this.state.incorporation_secretary.rc_number}
                    onChange={(e) => this.handleSecretaryChange(e, 'rc_number')}
                    name="sec_rc_number"
                    placeholder="Enter RC Number of Company"
                  />
                </p>

                <p>
                  <button
                    disabled={!canSubmit}
                    className="pt-button pt-intent-primary pull-right"
                  >
                    {submitText}
                  </button>
                  {cancel && (
                    <button
                      type="button"
                      className="pt-button pt-intent-danger"
                      onClick={cancel}
                    >
                      Cancel
                    </button>
                  )}
                </p>
                <div className="clearfix" />
              </form>
            </div>
          ) : (
            <form onSubmit={this.handleSubmit}>
              {!noSubject && (
                <p>
                  <label className="pt-label">Subject</label>
                  <input
                    className="pt-input pt-fill"
                    onChange={this.handleSelectChange}
                    defaultValue={ticket.name}
                    name="subject"
                    type="text"
                    placeholder="Enter ticket subject"
                    required
                  />
                </p>
              )}
              {!noSubject && (
                <div>
                  <label className="pt-label">Type</label>
                  <p className="pt-select pt-fill">
                    <select
                      readOnly
                      placeholder="Select category"
                      onChange={this.handleChange}
                      defaultValue={ticket.vlf_category}
                      className="pt-fill"
                      ref="vlf_category"
                      name="vlf_category"
                      required
                    >
                      <option label="Select one:" />
                      {vlfCategories.map((category, idx) => (
                        <option
                          key={idx}
                          label={category.name}
                          value={category._id}
                        >
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </p>
                </div>
              )}
              <p>
                <label className="pt-label">Message</label>
                <textarea
                  defaultValue={ticket.message}
                  ref={(ref) => (this.messageElem = ref)}
                  rows={3}
                  className="pt-input pt-fill"
                  onChange={this.handleChange}
                  name="message"
                  placeholder="Enter ticket message"
                  required
                />
              </p>
              <div className="">
                <label className="pt-label">Attach Files</label>
                <div>
                  {ticket.files.map((file, idx) => {
                    let isUploading = false;
                    return (
                      <div key={idx} className="file-upload-box">
                        <FileUploader
                          path="tickets/"
                          create={createFile}
                          file={file}
                          onUpload={(err, res) => {
                            if (!err) {
                              ticket.files[idx] = res.url;
                              if (ticket.files.length <= 5) {
                                ticket.files.push(null);
                              }
                              this.setState({ ticket });
                            }
                          }}
                        />
                        {file && (
                          <div>
                            <a
                              className="remove"
                              onClick={() => this.handleRemoveFile(idx)}
                            >
                              <Icon name="remove_circle" />
                            </a>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <p>
                <button
                  disabled={!canSubmit}
                  className="pt-button pt-intent-primary pull-right"
                >
                  {submitText}
                </button>
                {cancel && (
                  <button
                    type="button"
                    className="pt-button pt-intent-danger"
                    onClick={cancel}
                  >
                    Cancel
                  </button>
                )}
              </p>
              <div className="clearfix" />
            </form>
          )}
        </div>
      </div>
    );
  }
}
