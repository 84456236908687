import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router';
import io from 'socket.io-client';
import { showConfirmModal, closeModal } from '../../actions/modalActions';
import { Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import {
  find,
  remove,
  createMessage,
  unmarkUnread,
} from '../../actions/ticketActions';
import { create as createFile } from '../../actions/fileActions';
import { all as allVlfCategories } from '../../actions/vlfCategoryActions';
import Spinner from '../../components/widgets/Spinner';
import Icon from '../../components/widgets/Icon';
import FileUploader from '../../components/widgets/FileUploader';
import Toaster from '../../components/widgets/Toaster';
import Heading from '../../components/widgets/Heading';
import NoItem from '../../components/widgets/NoItem';
import PageComponent from '../../components/PageComponent';
import Checkout from '../../components/widgets/Checkout';
import TicketEdit from '../../components/widgets/TicketEdit';
import { cannotUseSubscription } from '../../lib/utils';

class Tickets extends PageComponent {
  getAuthType = () => ['guest', 'lawyer', 'admin', 'servicepartner'];
  componentWillMount() {
    this.state = {
      page: 1,
      ticket: null,
    };

    this.limit = 10;
    this.getTicket();
    const {
      closeModal,
      remove,
      showConfirmModal,
      allVlfCategories,
    } = this.props;
    this.createSocketConnection();
    allVlfCategories({ limit: 100 });
  }

  createSocketConnection = () => {
    const {
      props: {
        unmarkUnread,
        find,
        params: { id },
      },
    } = this;
    const session = this.props.state.session.session;
    const query = `user=${session.user}&session=${session._id}`;
    const url = window.SW_DOMAIN.split('/api/')[0];
    this.socket = io(`${url}/ims?${query}`);
    this.socket.on('connect', () => {
      console.log('Connection successful');
    });

    this.socket.on('chat', ({ _id, message }) => {
      const { ticket } = this.state;
      if (ticket._id === _id) {
        if (message.author !== session.user) {
          unmarkUnread(_id);
        }
        message.author_expanded =
          message.author === ticket.lawyer
            ? ticket.lawyer_expanded
            : ticket.owner_expanded;

        ticket.messages.push(message);
        if (message.status) {
          ticket.status = message.status;
        }
        this.setState({ ticket });
        this.handleScroll();
      }
    });
  };

  componentWillUnmount() {
    this.socket.close();
  }

  handleScroll = () => {
    const destination = document.getElementById('scroll-ref');
    if (destination) {
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName('body')[0].clientHeight;
      const destinationOffset =
        typeof destination === 'number' ? destination : destination.offsetTop;
      const destinationOffsetToScroll = Math.round(
        documentHeight - destinationOffset < windowHeight
          ? documentHeight - windowHeight
          : destinationOffset
      );
      window.scroll(0, destinationOffsetToScroll);
    } else {
      setTimeout(this.handleScroll, 1000);
    }
  };

  getTicket = () => {
    const {
      props: {
        unmarkUnread,
        find,
        params: { id },
        state: {
          session: { user: owner },
        },
      },
      limit,
      q,
    } = this;
    find(id, (err, ticket) => {
      if (!err) {
        this.setState({ ticket });
        this.setTitle(ticket.vlf_category_expanded.name);
        if (ticket.unread === owner) {
          unmarkUnread(ticket._id);
        }
        this.handleScroll();
      } else {
        Toaster.error(err.message || err);
      }
    });
  };

  renderMessages = (
    { message = '', author_expanded, stamp, files = [] },
    idx
  ) => {
    const {
      state: {
        session: { user },
      },
    } = this.props;
    return (
      <div className="Ticket m-t-2 pt-card pt-elevation-3" key={idx}>
        {author_expanded && (
          <h6 className="text-color-primary">
            <Icon name="mail_outline" />{' '}
            {author_expanded._id === user
              ? 'Me'
              : author_expanded
              ? author_expanded.name
              : 'N/A'}
          </h6>
        )}
        <hr />
        <p
          className="ticket-message"
          dangerouslySetInnerHTML={{ __html: message.asHTML() }}
        />
        {files.length ? (
          <div>
            <hr />
            {files.map((file, idx) => (
              <div key={idx}>
                <p>
                  <a
                    download
                    href={this.getUrl(file)}
                    target="_blank"
                    alt="Attachment"
                  >
                    <Icon name="attach_file" /> Attachment {idx + 1}{' '}
                  </a>
                </p>
              </div>
            ))}
          </div>
        ) : null}
        <p className="pull-right">
          <small>
            <Icon name="access_time" /> {moment(stamp).fromNow()}
          </small>
        </p>
        <div className="clearfix" />
      </div>
    );
  };

  handleSubmit = (message, cb2) => {
    console.log('here');
    const {
      state: {
        session: { user },
        ticket: { item: ticket },
      },
      createMessage,
      showConfirmModal,
    } = this.props;

    const cb = () => {
      createMessage(ticket._id, message, (err) => {
        cb2(err);
        if (err) {
          Toaster.error(err.message || err);
        }
      });
    };

    if (user !== ticket.owner && !ticket.lawyer) {
      showConfirmModal({
        title: 'Confirm Ticket Ownership',
        message: 'Are you sure you want to claim and manage this ticket?',
        buttons: [
          {
            label: 'Yes',
            intent: 'danger',
            action: cb,
          },
        ],
      });
    } else {
      cb();
    }
  };

  getUrl(file) {
    try {
      return JSON.parse(file).url;
    } catch (e) {
      return file;
    }
  }

  renderBreadCrumbs = (item) => {
    const name = item.subject;
    return (
      <div>
        <ul className="pt-breadcrumbs">
          <li>
            <Link className="pt-breadcrumbs-collapsed" to="/"></Link>
          </li>
          <li>
            <Link className="pt-breadcrumb" to="/tickets">
              Tickets
            </Link>
          </li>
          <li>
            <Link className="pt-breadcrumb pt-breadcrumb-current">
              {name && name.ucFirst()}
            </Link>
          </li>
        </ul>
        <br />
        <br />
      </div>
    );
  };

  getOnlyTickets(tickets) {
    let registrations_filter = [
      'Business Name Registration',
      'Company Incorporation',
      'Patent Registration',
      'NGO/Incoporated Trustee Registration',
      'Trademark Registration',
      'Copyright Registration',
      'Tin Registration',
    ];
    var sortedTickets = tickets.filter(function (item) {
      return (
        registrations_filter.indexOf(item.vlf_category_expanded.name) === -1
      );
    });
    return sortedTickets;
  }

  getRTicketsCount(tickets) {
    let registrations_filter = [
      'Business Name Registration',
      'Company Incorporation',
      'Patent Registration',
      'NGO/Incoporated Trustee Registration',
      'Trademark Registration',
      'Copyright Registration',
      'Tin Registration',
    ];
    var sortedTickets = tickets.filter(function (item) {
      return (
        registrations_filter.indexOf(item.vlf_category_expanded.name) === -1
      );
    });
    return sortedTickets.length;
  }

  renderPage() {
    const {
      createFile,
      state: {
        user: { account },
        subscription: { item: subscription, pricing, packages },
        session: { user },
        vlfCategory: { items: vlfCategories },
        ticket: { isLoading },
      },
    } = this.props;
    const { ticket } = this.state;
    if (isLoading || !ticket) {
      return <Spinner className="m-t-4 text-center" />;
    }

    ticket.vlf_category_expanded = ticket.vlf_category_expanded || {};
    const {
      vlf_category_expanded,
      lawyer_expanded,
      lawyer,
      messages = [],
      subject,
      stamp,
      status,
      owner,
      paid,
    } = ticket;
    const { subscriptions } = vlf_category_expanded;
    return (
      <div className="TicketPage">
        <div className="content-width">
          {this.renderBreadCrumbs(ticket)}
          <div className="clearfix" />
          <Heading title={subject} />
          {subscriptions && subscriptions.length ? (
            <div className="text-right m-b-2">
              <b className="text-muted">
                The following subscription packages do not cover this document
              </b>
              <div>
                {subscriptions.map((item) => (
                  <span
                    key={item}
                    className="pt-tag pt-intent-warning m-t-1 m-l-1"
                  >
                    {packages[item].name}
                  </span>
                ))}
              </div>
            </div>
          ) : null}
          <div className="pt-card pt-elevation-3 ticket-summary">
            <div className="pull-right">
              <p>Ticket Status</p>
              <h5
                className={`ticket-status pt-tag pt-intent-${
                  status === 'open' ? 'success' : 'danger'
                }`}
              >
                {status}
              </h5>
              {!paid && (
                <h5 className="ticket-status pt-tag pt-intent-danger m-r-1">
                  UNPAID
                </h5>
              )}
            </div>
            <h4>
              <Icon name="person" />{' '}
              {lawyer_expanded ? lawyer_expanded.name : 'No contact lawyer yet'}
            </h4>
            <p>{moment(stamp).format('LL LT')}</p>
            <p className="pt-tag pt-intent-primary pt-large pt-round">
              {vlf_category_expanded.name}
            </p>
          </div>
          <div>{messages.map(this.renderMessages)}</div>
          <div id="scroll-ref" />
          {paid ? (
            status === 'closed' ? (
              <NoItem>This ticket is closed</NoItem>
            ) : (
              <div className="m-t-2">
                {account.type.isLawyer() || owner === user ? (
                  owner === user || lawyer === user || !lawyer ? (
                    <TicketEdit
                      vlfCategories={vlfCategories}
                      canChangeStatus={user === lawyer}
                      submitText="Respond"
                      title="Reply Ticket"
                      noSubject
                      createFile={createFile}
                      submit={this.handleSubmit}
                      ticketId={this.props.routeParams.id}
                    />
                  ) : (
                    <NoItem>This Ticket already has a responder</NoItem>
                  )
                ) : (
                  <NoItem>Only Lawyers can respond to tickets</NoItem>
                )}
              </div>
            )
          ) : (
            <div>
              <NoItem>Make payment to get support from our Lawyers.</NoItem>
              <div className="pt-card pt-elevation-4 text-center">
                <Checkout
                  disabledPackages={subscriptions}
                  checkoutType={
                    cannotUseSubscription(
                      vlf_category_expanded,
                      subscription
                    ) && 'pay-as-you-go'
                  }
                  tick={this.state.ticket.vlf_category}
                  price={
                    ticket.price ||
                    vlf_category_expanded.price ||
                    pricing.ticket
                  }
                  resource={ticket}
                  type="ticket"
                  cb={this.getTicket}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default (store) => ({
  path: `/tickets/:id`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          showConfirmModal: (params) => dispatch(showConfirmModal(params)),
          find: (kwargs, callback) => dispatch(find(kwargs, callback)),
          allVlfCategories: (kwargs, callback) =>
            dispatch(allVlfCategories(kwargs, callback)),
          createMessage: (_id, json, callback) =>
            dispatch(createMessage(_id, json, callback)),
          createFile: (file, callback) => dispatch(createFile(file, callback)),
          unmarkUnread: (_id, callback) =>
            dispatch(unmarkUnread(_id, callback)),
          remove: (_id, json, callback) =>
            dispatch(remove(_id, json, callback)),
        })
      )(Tickets)
    );
  },
});
