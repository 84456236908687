import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router';
import { showConfirmModal } from '../../actions/modalActions';
import { all } from '../../actions/documentActions';
import Spinner from '../../components/widgets/Spinner';
import Icon from '../../components/widgets/Icon';
import Heading from '../../components/widgets/Heading';
import NoItem from '../../components/widgets/NoItem';
import PageComponent from '../../components/PageComponent';

class Documents extends PageComponent {
  getAuthType = () => ['guest', 'admin', 'servicepartner'];
  componentWillMount() {
    this.setTitle('Agreements');
    this.state = {
      page: 1,
    };

    this.limit = 10;
    this.getDocuments(1);
  }

  getDocuments = (page) => {
    const {
      props: {
        all,
        state: {
          session: { user: owner },
        },
      },
      limit,
      q,
    } = this;
    all({ q, page, limit, owner }, (err, res) => {
      if (!err) {
        this.setState({ page });
      } else {
        Toaster.error(err.message || err);
      }
    });
  };

  renderPaginator = (count) => {
    if (!count) {
      return null;
    }

    const { page } = this.state;
    const {
      document: { isLoading },
    } = this.props.state;
    const total = Math.ceil(count / this.limit);
    let pages = [];
    for (let i = 0; i < total; i++) {
      pages.push(
        <div
          key={i}
          onClick={this.getDocuments.bind(this, i + 1)}
          className={`page ${isLoading ? 'loading' : ''} ${
            page === i + 1 ? 'selected' : ''
          }`}
        >
          {i + 1}
        </div>
      );
    }

    return pages;
  };

  renderDocuments = (document, idx) => {
    const {
      state: {
        subscription: {
          item: { type },
        },
      },
    } = this.props;
    const { _id, title } = document.template_expanded;
    return (
      <div className="DocumentPanel" key={idx}>
        <Link
          className="block"
          to={`/document-category/templates/${_id}/documents/${document._id}`}
        >
          <div className="document-preview">
            <Icon name="insert_drive_file" />
          </div>
        </Link>
        <div className="document-summary">
          <h5 title={title} className="slim">
            {title.ellipsis(19)}
          </h5>
          <div className="document-footer">
            <small className="text-muted pull-left">
              {moment(document.stamp).fromNow()}
            </small>
            <small className="text-muted pull-right">
              {document.paid ? (
                <a
                  download
                  href={`${window.SW_DOMAIN}/api/v1/document/${document._id}/download?subscription=${type}`}
                >
                  <Icon name="file_download" /> Download
                </a>
              ) : (
                <a>
                  <Icon name="access_time" /> In-progress
                </a>
              )}
            </small>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    );
  };

  renderBreadCrumbs = () => {
    return (
      <div>
        <ul className="pt-breadcrumbs">
          <li>
            <Link className="pt-breadcrumbs-collapsed" to="/"></Link>
          </li>
          <li>
            <Link className="pt-breadcrumb" to="/document-categories">
              Agreement Templates
            </Link>
          </li>
          <li>
            <Link
              className="pt-breadcrumb pt-breadcrumb-current"
              to="/documents"
            >
              Agreements
            </Link>
          </li>
        </ul>
        <br />
        <br />
      </div>
    );
  };

  renderPage() {
    const {
      state: {
        document: { items, count, isLoading },
      },
    } = this.props;

    if (isLoading) {
      return <Spinner className="m-t-4 text-center" />;
    }

    return (
      <div className="Documents">
        <div className="content-width">
          {this.renderBreadCrumbs()}
          <Link
            to="/document-categories"
            onClick={handleClick}
            className="pull-right pt-button pt-intent-accent"
          >
            Create Agreement
          </Link>
          <div className="clearfix" />
          <Heading title="Standard Agreements" />
          <p>
            Choose any Standard Agreement, answer all questions, preview and
            download your personalized Agreement in 5 minutes or less.{' '}
          </p>
          <div className="categories">
            {count ? (
              <div className="pt-card pt-elevation-3">
                <div>{items.map(this.renderDocuments)}</div>
                <div className="paginator">{this.renderPaginator(count)}</div>
              </div>
            ) : (
              <NoItem>You haven't created any agreements yet</NoItem>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default (store) => ({
  path: `/documents`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          showConfirmModal: (params) => dispatch(showConfirmModal(params)),
          all: (kwargs, callback) => dispatch(all(kwargs, callback)),
        })
      )(Documents)
    );
  },
});
