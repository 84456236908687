import { connect } from 'react-redux';
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

class ServicePartners extends Component {
  getAuthType = () => ['admin'];

  state = {
    partnerTransaction: null,
    status: 'pending',
    tag: '',
    query: {},
  };

  componentDidMount() {
    this.getTransactions({});
  }

  getTransactions = async (data, limit = 30, page = 1) => {
    data ? data : {};
    data = JSON.stringify(data);

    let url = `${window.SW_DOMAIN}/api/v1/servicepartners/transactions?data=${data}`;
    limit = limit ? `&limit=${limit}` : '';
    page = page ? `&page=${page}` : '';

    url = url + limit + page;

    try {
      const res = await axios.get(url);
      this.setState({ status: 'resolved', partnerTransaction: res.data });
    } catch (err) {
      this.setState({ status: 'rejected' });
    }
  };

  handleQuery = (e) => {
    e.preventDefault();
    const { query } = this.state;
    const value = e.target.value.trim();
    this.setState({ tag: value });

    if (value.endsWith('.com')) {
      query.email = value.trim();
      this.setState({ tag: '', query });
    }

    if (
      [
        'patent',
        'standard',
        'premium',
        'subscription',
        'business_name',
        'awoof',
        'document',
        'platinum_startup_bundle',
        'call',
      ].includes(value)
    ) {
      query.transactionType = value;
      this.setState({ tag: '', query });
    }

    if (['fidelity', 'fbn'].includes(value)) {
      query.bankId = value;
      this.setState({ tag: '', query });
    }
  };

  handleTag = (tag) => {
    const { query } = this.state;
    delete query[tag];
    this.setState({ query });
  };

  handleSubmit = async () => {
    const { query } = this.state;
    this.setState({ status: 'pending', partnerTransaction: null });
    this.getTransactions(query);
  };

  render() {
    const tableHeaders = [
      'S/N',
      'BankId',
      'Amount',
      'Type',
      'paymentChannel',
      'Email',
      'SubscriptionId',
      'Date',
    ];
    const { partnerTransaction, status, tag, query } = this.state;
    if (status === 'pending') return <div>Loading ....</div>;
    if (status === 'rejected')
      return <div>Unable to fetch Data at this Time</div>;
    if (status === 'resolved') {
      return (
        <section className="pad_2 content-width">
          <div className="d-flex justify-content-between">
            <h2 className="m-b-2">Transactions</h2>
            <div>
              {Object.keys(query).map((tag, i) => (
                <span
                  key={i}
                  className="tags"
                  onClick={() => this.handleTag(tag)}
                >
                  {query[tag]}
                </span>
              ))}
            </div>
            <div>
              <input
                className="searchTags"
                onChange={this.handleQuery}
                value={tag}
                placeholder="tags"
                type="text"
                name="tags"
              />
              <button
                onClick={this.handleSubmit}
                className="pt-button pt-intent-primary"
                type="button"
              >
                Search
              </button>
            </div>
          </div>
          <div className="table-container m-t-3">
            <table className="pt-table">
              <thead>
                <tr>
                  {tableHeaders.map((name, idx) => {
                    return <th key={idx}>{name}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {partnerTransaction.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{item.bankId}</td>
                      <td>{item.transactionAmount}</td>
                      <td>{item.transactionType}</td>
                      <td>{item.paymentChannel}</td>
                      <td>{item.email}</td>
                      <td>{item.subscriptionId}</td>
                      <td>{moment(item.transactionDate).format('LL')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
      );
    }
  }
}

export default (store) => ({
  path: `/admin/servicePartner`,
  getComponent(next, cb) {
    cb(null, connect(null, null)(ServicePartners));
  },
});
