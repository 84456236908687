import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { inflect } from 'inflection';
import { showConfirmModal } from '../../actions/modalActions';
import { injectReducer } from '../../store/reducers';
import moment from 'moment';
import { Tab2, Tabs2, Popover, Tooltip, Position, PopoverInteractionKind } from '@blueprintjs/core';
import Spinner from '../../components/widgets/Spinner';
import { currency } from '../../lib/utils';
import NoItem from '../../components/widgets/NoItem';
import Toaster from '../../components/widgets/Toaster';
import { all, update } from '../../actions/callActions';
import PageComponent from '../../components/PageComponent';
import Icon from '../../components/widgets/Icon';

class LawyerCalls extends PageComponent {
  getAuthType = () => ['lawyer'];
  getLawyerType = () => ['vlf'];
  constructor(props) {
    super(props);
    this.limit = 20;
    this.q = null;
    this.type = 'accepted';
    this.types = {
      // pending: 'pending=true',
      accepted: 'accepted=true',
      // taken: 'taken=true'
    };

    this.state = {
      calls: {
        pending: {},
        accepted: {},
        taken: {},
      },
      page: 1
    };
  }

  componentWillMount() {
    this.fetchCalls();
  }

  fetchCalls = () => {
    const { getCalls, types } = this;
    for (let i in types) {
      if (types.hasOwnProperty(i)) {
        getCalls(types[i], i);
      }
    }
  };

  getCalls = (path, status, page = 1) => {
    console.log(path, status, 'dross')
    const { props: { all }, limit, q } = this;
    all({ q, page, limit, status }, (err, res) => {
      if (!err) {
        const calls = this.state.calls;
        calls[status] = res;
        this.setState({ calls, page });
      } else {
        Toaster.error(err.message || err);
      }
    });
  };

  handleSearch = e => {
    const { type, types } = this;
    const { value } = (e.target || e.srcElement);
    if (value && value.length > 1) {
      this.q = value;
      this.getCalls(types[type], type, 1);
    } else if (!value) {
      this.q = null;
      this.getCalls(types[type], type, 1);
    }
  };

  updateCall(_id, action) {
    const body = {};
    const { update, showConfirmModal, state: { session } } = this.props;
    switch (action) {
      case 'accept':
        body.accepted = true;
        body.lawyer = session.user;
        body.status = 'accepted';
        break;
      // case 'decline':
      //   body.accepted = false;
      //   body.admin_taken = true;
      //   body.status = 'taken';
      //   break;
      // case 'revert':
      //   body.accepted = false;
      //   body.admin_taken = false;
      //   body.status = 'pending';
      //   break;
      default:
        return;
    }

    showConfirmModal({
      title: `Accept Appointment`,
      message: `Are you sure you want accept this appointment?`,
      buttons: [
        {
          label: action.ucFirst(),
          intent: 'danger',
          action: () => {
            update(_id, body, (err, res) => {
              if (!err) {
                this.fetchCalls();
                Toaster.info('Your request is being processed');
              } else {
                Toaster.error(err.message || err);
              }
            });
          }
        }]
    });
  };

  handleTabChange = (type) => {
    this.type = type;
    this.setState({ page: 1 });
  };

  renderPaginator = (count, type) => {
    if (!count) {
      return null;
    }

    const { page } = this.state;
    const { payment: { isLoading } } = this.props.state;
    const total = Math.ceil(count / this.limit);
    let pages = [];
    for (let i = 0; i < total; i++) {
      pages.push(<div
        key={i}
        onClick={this.getCalls.bind(this, this.types[type], type, i + 1)}
        className={`page ${isLoading ? 'loading' : ''} ${page === i + 1 ? 'selected' : ''}`}>
        {i + 1}
      </div>);
    }

    return pages;
  };

  renderBank = (bank, banks) => {
    if (bank) {
      return <div className="p-a-1 tooltip-text">
        <h6>{banks ? banks[bank.bank_code] : bank.bank_code}</h6>
        <p>{bank.account_name}</p>
        <p>{bank.account_number}</p>
        <p>Bank Code: <b>{bank.bank_code}</b></p>
      </div>;
    } else {
      return <div className="p-a-1 tooltip-text text-muted">No Bank details</div>
    }
  };

  renderWithDescriptionHover = (event, content) => {
    const popover = <div className="p-a-1 tooltip-text">
      <h6>{event.data.transactionreference}</h6><Link className="pull-right" to={`/admin/calls/${event._id}`}><span className="pt-icon-size pt-icon-share pull-right"></span></Link>
      <p><b>{event.narration}</b></p>
      <p>Transaction: <code>{event.data.responsemessage}</code></p>
      <p>AVS Response: <code>{event.data.avsresponsemessage}</code></p>
      <p><span className="text-muted">{event.stamp && moment(event.stamp).fromNow()}</span></p>
    </div>;

    return <Popover interactionKind={PopoverInteractionKind.HOVER} content={popover} position={Position.TOP}>
      {content}
    </Popover>
  };

  renderCalls = type => {
    const tableHeaders = ['Client', 'Day', 'Time', 'Notes', 'Slots', 'Attachments', '', '', ''];
    const calls = this.state.calls[type];
    const count = calls.count || 0;

    return <div className="m-t-2">
      <div className="table-container">
        <table className="pt-table">
          <thead>
            <tr>
              {
                tableHeaders.map((name, idx) => {
                  if ((type === 'pending' && /Admin|Revert/.test(name)) ||
                    (type !== 'pending' && /Decline|Approval/.test(name))) {
                    return null;
                  }
                  return <th key={idx}>{name}</th>;
                })
              }
            </tr>
          </thead>
          <tbody>
            {calls.items &&
              calls.items.map((item, idx) => {
                const expired = (new Date(item.start)).getTime() < (new Date()).getTime();
                item.files = item.files || [];
                return <tr key={idx}>
                  {/* <td>{item._id}</td> */}
                  <td>{(item.owner_expanded && item.owner_expanded.name) || 'N/A'}</td>
                  <td>{moment(item.start).format('LL')}</td>
                  <td>{moment(item.start).format('LT')}</td>
                  <td>{item.notes}</td>
                  <td>{item.slot} <small>({(item.interval || 30) * item.slot} mins total)</small></td>
                  <td>
                    {
                      item.files.map((file, idx) => <a key={idx} href={file} download>
                        <div>
                          <Icon name="attach_file" size={15} />&nbsp;
                          <span>Attachment {idx + 1}</span>
                        </div>
                      </a>)
                    }
                    {item.files.length === 0 && <em>No attachments</em>}
                  </td>
                  {/* <td>{(item.lawyer_expanded && item.lawyer_expanded.name) || 'N/A'}</td> */}
                  {/* <td>
                  <Popover interactionKind={PopoverInteractionKind.HOVER} content={this.renderBank(item.bank, configuration.banks)} position={Position.TOP}>
                    {
                      item.bank ? <p>{configuration.banks[item.bank.bank_code]}</p> : 'No Bank details'
                    }
                  </Popover>
                </td>
                <td>
                  <code>{item.data.status}</code>
                </td> */}
                  {/* {type !== 'pending' && <td>{(item.admin_expanded && item.admin_expanded.name) || 'N/A'}</td>} */}
                  {/*type === 'pending' && <td><button disabled={expired} className="pt-button pt-primary" onClick={this.updateCall.bind(this, item._id, 'accept')}>{expired ? 'EXPIRED' : 'Accept'}</button></td>*/}
                  {/* {type === 'pending' && <td><button disabled={item.refund} className="pt-button pt-intent-danger" onClick={this.updateCall.bind(this, item._id, 'decline')}>Decline</button></td>} */}
                  {/* {type !== 'pending' && <td><button disabled={item.refund} className="pt-button pt-primary" onClick={this.updateCall.bind(this, item._id, 'revert')}>Revert</button></td>} */}
                  {/* <td></td> */}
                </tr>
              }
              )}
          </tbody>
        </table>
        {!count ? <NoItem>No calls found</NoItem> : null}
      </div>
      <div className="paginator">
        {this.renderPaginator(count, type)}
      </div>
    </div>;
  };

  renderHeader = () => {
    const count = this.state.calls[this.type].count || 0;

    return (<div className="row">
      <div className="col_20">
        <div className="pt-input-group">
          <span className="pt-icon pt-icon-search"></span>
          <input
            className="pt-input"
            type="search"
            placeholder="Search"
            dir="auto"
            onChange={this.handleSearch}
          />
        </div>
      </div>
      <div className="col_20">
        <span className="pt-navbar-divider"></span>
        <span className="pt-tag pt-minimal pt-large">
          {count} {inflect('entry', count)} found
        </span>
      </div>
    </div>);
  }

  renderPage() {
    const { user: { account } } = this.props.state;
    if (!account) {
      return <Spinner />;
    }

    return (<section className="content-width LawyerCalls">
      <h2 className="m-t-4 m-b-2">Calls</h2>
      <div className="m-b-4">
        {this.renderHeader()}
      </div>
      <Tabs2 id="calls" defaultSelectedTabId="accepted" onChange={this.handleTabChange}>
        {/* {<Tab2 id="pending" title={<h5>Pending <div className="pending-count">{this.state.calls.pending.count || 0}</div></h5>} panel={this.renderCalls('pending')} />} */}
        <Tab2 id="accepted" title={<h5>Assigned to you</h5>} panel={this.renderCalls('accepted')} />
        {/* <Tab2 id="taken" title={<h5>Taken</h5>} panel={this.renderCalls('taken')} /> */}
        <Tabs2.Expander />
      </Tabs2>
    </section>);
  }
}

export default store =>
  ({
    path: `/lawyer/calls`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({
            showConfirmModal: (params) => dispatch(showConfirmModal(params)),
            update: (_id, category, callback) => dispatch(update(_id, category, callback)),
            all: (kwargs, callback) => dispatch(all(kwargs, callback)),
          }))(LawyerCalls)
      );
    }
  });