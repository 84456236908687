export function closeModal(replace) {
  return (dispatch, getState) => {
    const { returnTo } = getState().modal;
    dispatch({ type: 'CLOSE_MODAL' });
    if (replace && returnTo) {
      const path = typeof (returnTo) === 'function' ? returnTo() : returnTo;
      replace(path);
    }
  };
}

export function showSigninModal(params) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_SIGNIN_MODAL',
      params
    });
  };
}

export function showSignupModal(params) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_SIGNUP_MODAL',
      params
    });
  };
}

export function showConfirmModal(params) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_CONFIRM_MODAL',
      params,
      returnTo: params.returnTo
    });
  };
}

export function showPasswordResetModal(params) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_PASSWORD_RESET_MODAL',
      params,
      returnTo: params.returnTo
    });
  };
}

export function showCallModal(params) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_CALL_MODAL',
      params
    });
  };
}

export function showUserSelectorModal(params) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_USER_SELECTOR_MODAL',
      params
    });
  };
}
