import React from 'react';

export default class PrivacyContent extends React.Component {
  render() {
    return (
      <div className="StaticPage">
        <div className="page-content">
          <div>
            <h1 className="text-center">PRIVACY POLICY</h1>
            <div className="m-t-4">
              <p>mylaw facilitates access to competent, fit for purpose and affordable legal services on a technology platform. Legal services (the Services) which can be accessed via mylaw are broadly categorized as follows:&nbsp;</p>

              <ul>
                <li><strong>Customised Document Generation/</strong> <strong>Document Repository App </strong>&ndash; enables users generate documents through an interactive process. This application allows users input specific information to be used to create an executable document with little or no support from a lawyer.<strong>&nbsp; &nbsp;</strong></li>
                <li><strong>Virtual Law Firm</strong> (<strong>LEGEX Partners &amp; Co</strong>) &ndash;<strong> </strong>allows users consult lawyers via chat or telephone.<strong>&nbsp;</strong></li>
                <li><strong>Panel of Law Firms/Lawyers </strong>&ndash; Allows users access lawyers in cases where physical representation is required.<strong> &nbsp;</strong></li>
              </ul>

              <p>&nbsp;</p>

              <p>Your privacy matters to mylaw (hereinafter referred to as either &ldquo;mylaw&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;us&rdquo;). This Privacy Policy explains how we collect, process, use, share and protect information about you. It also explains how you can access and update your information and make certain choices about how your information is used.&nbsp;</p>

              <p>&nbsp;</p>

              <p>The Privacy Policy covers both &ldquo;online&rdquo; (e.g. web and mobile services, including any websites operated by us, however accessed and/or used, whether via personal computers, mobile devices or otherwise) and &ldquo;offline&rdquo; (e.g. collection of data through mailings or telephone) activities owned, operated, provided, or made available by mylaw.&nbsp;</p>

              <p>&nbsp;</p>

              <p>This Privacy Policy also applies to your use of interactive features or downloads that: (i) we own or control; (ii) are available through the Services; or (iii) interact with the Services and post or incorporate this Privacy Policy.</p>

              <p>&nbsp;</p>

              <p>BY ACCEPTING THE MYLAW TERMS AND CONDITIONS, YOU AGREE TO THE TERMS OF THIS PRIVACY POLICY. If you do not agree to this Privacy Policy, do not accept the mylaw Terms and Conditions or use our Services. This Privacy Policy is incorporated by reference into the mylaw Terms and Conditions.</p>

              <p>&nbsp;</p>

              <p>If you have questions about this Privacy Policy, please contact us through the email address provided on our website.</p>

              <p>&nbsp;</p>

              <ol>
                <li><strong>COLLECTION OF INFORMATION&nbsp;</strong>

                  <p><strong>Information you provide to us</strong></p>

                  <p>We collect information you provide directly to us, such as when you create or modify your account, request on-demand services, contact customer support, or otherwise communicate with us. This information may include: name, email, phone number, postal address, profile picture, payment method and other information you choose to provide.</p>

                  <p>&nbsp;</p>

                  <p><strong>Information we collect through the use of the Services</strong></p>

                  <p>We and our third party service provider may use a variety of technologies that automatically (or passively) collect certain information whenever you visit or interact with the Services (&ldquo;Usage Information&rdquo;). This Usage Information may include the browser that you are using, the URL that referred you to our Services, all of the areas within our Services that you visit, and the time of day, among other information. In addition, we collect your device identifier (i.e. Internet Protocol (IP) address or other unique identifier) for your computer, mobile or other device used to access the Services.&nbsp;</p>

                  <p>&nbsp;</p>

                  <p>Usage Information may be collected using a cookie. If you do not want information to be collected through the use of cookies, your browser allows you to deny or accept the use of cookies. Cookies can be disabled or controlled by setting a preference within your web browser or on your device. If you choose to disable cookies or flash cookies on your device, some features of the Services may not function properly or may not be able to customize the delivery of information to you. mylaw cannot control the use of cookies (or the resulting information) by third parties, and use of third party cookies is not covered by our Privacy Policy.</p>

                  <p>&nbsp;</p>

                  <p><strong>Information collected by mylaw</strong></p>

                  <p>We may collect and use technical data and related information, including but not limited to, technical information about your device, system and application software, and peripherals, that is gathered periodically to facilitate the provision of software updates, product support and other services to you (if any) related to such mobile applications.</p>

                  <p>&nbsp;</p>

                  <p>mylaw may automatically collect and store, including without limitation, the following information from your device:</p>

                  <ul>
                    <li>Your preferred language and country site (if applicable)</li>
                    <li>The manufacturer and model of your device</li>
                    <li>Your device and operating system</li>
                    <li>The type of internet browsers you are using</li>
                    <li>Your geolocation</li>
                    <li>Transaction details related to your access to the Services, including the type of service requested, date and time the service was provided, amount charged, and other related transaction details. Additionally, if someone uses your promo code, we may associate your name with that person.</li>
                    <li>Information about how you interact with mylaw such as how many times you use a specific part of the Services over a given time period, how often you use any of the Services and how you generally engage with mylaw</li>
                    <li>Information to allow us to personalize the services and content available through mylaw.</li>
                  </ul>

                  <p>&nbsp;</p>

                  <p><strong>Information Collected from Third Parties&nbsp;</strong></p>

                  <p>We may, from time to time, supplement the information we collect about you through our website or mylaw or Services with external records from third parties. For instance:</p>

                  <ul>
                    <li>If you choose to link, create, or log in to your mylaw account with a payment provider or social media service, or if you engage with a separate app or website that uses our application programming interface &ldquo;API&rdquo; (or whose API we use), we may receive information about you or your connections from that site or app.</li>
                    <li>When you request on demand services, our drivers may provide us with a user rating after providing services to you.</li>
                    <li>If you also interact with our Services in another capacity, for instance as a driver or user of other apps we provide, we may combine or associate that information with information we have collected from you in your capacity as a User.</li>
                  </ul>

                  <p>&nbsp;</p>

                  <p><strong>Use of Information Collected</strong></p>

                  <p>Our primary goal in collecting your information is to provide you with an enhanced experience when using the Services. We may use your information to carry out the following:&nbsp;</p>

                  <ul>
                    <li>Closely monitor which features of the Services are used most, to allow you to view your trip history, rate trips, and to determine which features we need to focus on improving, including usage patterns and geographic locations to determine where we should offer or focus services, features and/or resources.</li>
                    <li>Prevent, discover and investigate violations of this Privacy Policy or any applicable terms of service or terms of use, and to investigate fraud, chargeback or other matters.</li>
                    <li>Provide some of your information such as your name, description of the legal matter and contact information to the lawyer assigned to handle your matter.&nbsp;</li>
                    <li>Provide you with information or services or process transactions that you have requested or agreed to receive including to send you electronic newsletters, or to provide you with special offers or promotional materials on behalf of us or third parties.&nbsp;</li>
                    <li>Contact you with regard to your use of the Services and, in our discretion, changes to the Services and/or the Services&rsquo; policies.&nbsp;</li>
                    <li>For internal business purposes, inclusion in our data analytics and for purposes disclosed at the time you provide your information or as otherwise set forth in this Privacy Policy.</li>
                    <li>mylaw may use the information collected from you for targeted advertising. This involves using information collected on an individual&#39;s web or mobile browsing behavior such as the pages they have visited or the searches they have made. This information is then used to select which advertisements should be displayed to a particular individual on websites other than our website. The information collected is only linked to an anonymous cookie ID (alphanumeric number); it does not include any information that could be linked back to a particular person, such as their name, address or credit card number.</li>
                    <li>mylaw uses the information collected from the website to provide the correct app version depending on your device type, for troubleshooting and in some cases, marketing purposes.</li>
                    <li>We use your IP address to help diagnose problems with our computer server, and to administer our website.&nbsp;</li>
                    <li>We will send you strictly service-related announcements on rare occasions when it is necessary to do so. For instance, if our Services are temporarily suspended for maintenance, we might send you an email. If you do not wish to receive them, you have the option to deactivate your account.</li>
                  </ul>

                  <p>&nbsp;</p>

                </li>
                <li><strong>CONDITIONS FOR DISCLOSING INFORMATION TO THIRD PARTIES&nbsp;</strong>

                  <p>We do not sell, share, rent or trade the information we have collected about you, other than as disclosed within this Privacy Policy or at the time you provide your information. The following are the situations when information may be shared:</p>

                  <p>&nbsp;</p>

                  <p><strong>When You Agree to Receive Information from Third Parties</strong></p>

                  <p>You may be presented with an opportunity to receive information and/or marketing offers directly from third parties. If you do agree to have your information shared, your information will be disclosed to such third parties and all information you disclose will be subject to the privacy policy and practices of such third parties. We are not responsible for the privacy policies and practices of such third parties and, therefore, you should review the privacy policies and practices of such third parties prior to agreeing to receive such information from them. If you later decide that you no longer want to receive communication from a third party, you will need to contact that third party directly.</p>

                  <p>&nbsp;</p>

                  <p><strong>Third Parties Providing Services on our Behalf</strong></p>

                  <p>We use third party companies and individuals to facilitate access to the Services, enable us to send you special offers, host our job application form, perform technical services (e.g., without limitation, maintenance services, database management, web analytics and improvement of the Services features), or perform other administrative services. These third parties will have access to User Information in order to carry out the services they are performing for you or for us. Each of these third parties are required to ensure the same level of data protection as us and are obligated not to disclose or use your information they receive from us for any other purpose.</p>

                  <p>&nbsp;</p>

                  <p><strong>Administrative and Legal Reasons</strong></p>

                  <p>mylaw cooperates with government, law enforcement officials and private parties to enforce and comply with the law. Thus, we may access, use, preserve, transfer and disclose your information to government, law enforcement officials or private parties as we reasonably determine is necessary and appropriate: (i) to satisfy any applicable law, regulation, subpoenas, governmental requests or legal process; (ii) to protect and/or defend the Terms for the Services or other policies applicable to any of the Services, including investigation of potential violations thereof; (iii) to protect the safety, rights, property or security of mylaw, the Services or any third party; (iv) to protect the safety of the public for any reason; (v) to detect, prevent or otherwise address fraud, security or technical issues; and /or (vi) to prevent or stop activity we may consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity.</p>

                  <p>&nbsp;</p>

                  <p><strong>When You Share Information</strong></p>

                  <p>Information may be collected and shared with third-parties if there is content from mylaw that you specifically and knowingly upload, share with or transmit to an email recipient, website, or to the public, e.g. uploaded photos, posted reviews or comments, or information about you or your ride that you choose to share with others through features which may be provided on our Services. This uploaded, shared or transmitted content will also be subject to the privacy policy of the email, online community website, social media or other platform to which you upload, share or transmit the content.</p>

                  <p>&nbsp;</p>

                  <p><strong>Business Transfer</strong></p>

                  <p>We may share your information with our subsidiaries and affiliates for internal reasons. We also reserve the right to disclose and transfer all such information: (i) to a subsequent owner, co-owner or operator of the Services or applicable database; or (ii) in connection with a corporate merger, consolidation, restructuring, the sale of substantially all of our membership interests and/or assets or other corporate change, including, during the course of any due diligence process.</p>

                  <p>&nbsp;</p>

                </li>
                <li><strong>CHANGE OF INFORMATION AND CANCELLATION OF ACCOUNT</strong>

                  <p>You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information provided as part of account registration. If any information changes, or if you no longer desire access to the Services, you may correct, delete inaccuracies, or amend such information by making the change on our member information page or by contacting us through the email address provided on our website. We will make good faith efforts to make requested changes in our active databases as soon as reasonably practicable.</p>

                  <p>&nbsp;</p>

                  <p>You may also cancel or modify your communications that you have elected to receive by following the instructions contained within an e-mail or by logging into your user account and changing your communication preferences.</p>

                  <p>&nbsp;</p>

                  <p>If upon modifying or changing the information earlier provided, we find it difficult to permit your access to the Services due to insufficiency/ inaccuracy of the information, we may, in our sole discretion terminate your access to the Services by providing you a written notice to this effect on your registered email id.</p>

                  <p>&nbsp;</p>

                  <p>Kindly contact us if you wish to cancel your account or request that we no longer use your information to provide you Services. Even after your account is terminated, we will retain some of your information (including email address, geo-location, and transaction history) as needed to comply with our legal and regulatory obligations, resolve disputes, conclude any activities related to cancellation of an account, investigate or prevent fraud and other inappropriate activity, to enforce our agreements, and for other business reason. After a period of time, your data may be anonymized and aggregated, and then may be held by us as long as necessary for us to provide access to the Services effectively, but our use of the anonymized data will be solely for analytic purposes.</p>

                  <p>&nbsp;</p>

                </li>
                <li><strong>SECURITY</strong>
                  <p>The information we collect is securely stored within our databases, and we use standard, industry-wide, commercially reasonable security practices such as encryption, firewalls and SSL (Secure Socket Layers) for protecting your information. However, as effective as encryption technology is, no security system is impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that information you supply won&#39;t be intercepted while being transmitted to us over the Internet or wireless communication, and any information you transmit to mylaw is done at your own risk. We recommend that you do not disclose your password to anyone.</p>

                  <p>&nbsp;</p>

                </li>
                <li><strong>CHANGES TO THE PRIVACY POLICY</strong>


                  <p>From time to time, we may update this Privacy Policy to reflect changes to our information practices. Any changes will be effective immediately upon the posting of the revised Privacy Policy. If we make any material changes, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on the Services prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.</p>

                  <p>&nbsp;</p>

                </li>
                <li><strong>CONTACT US</strong>
                  <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:mylawlegex@gmail.com">mylawlegex@gmail.com</a>, or write us at Virtual Platform Limited, 1b, Utomi Aire Avenue, Lekki Phase 1.</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}