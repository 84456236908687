import React from 'react';
import PropTypes from 'prop-types';
import { EditableText, Intent, Toast } from '@blueprintjs/core';
import Toaster from '../../components/widgets/Toaster';
import Icon from '../../components/widgets/Icon';
import Spinner from '../../components/widgets/Spinner';
import { currency } from '../../lib/utils';
import Payment from './Payment';
import ServicePartnerPackage from './Subscription/ServicePartnerPackage';
import { inflect } from 'inflection';
import moment from 'moment';

export default class Subscription extends React.Component {
  componentWillMount() {
    this.setState({
      selected: null,
      servicepartner: false,
      errors: {},
      years: 1,
      bundle: null,
    });

    if (localStorage.getItem('Template')) {
      localStorage.removeItem('Template');
    }

    let user_type = JSON.parse(localStorage.getItem('account')).type;
    if (user_type === 'servicepartner') {
      this.setState({ servicepartner: true });
    }
  }

  static propTypes = {
    disabledPackages: PropTypes.arrayOf(PropTypes.string),
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      _id: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    disabledPackages: [],
  };

  handleSelect(selected) {
    this.setState({ selected });
  }

  renderPaid() {
    const { bundle } = this.state;
    if (bundle) {
      return (
        <div>
          <h2 className="text-color-primary">
            <Icon name="star" /> {bundle.subscription_type} Subscription
          </h2>
          <p>Your subscription is active</p>
          <p>Your subscription gives you access to the following:</p>
          <p>
            <Icon name="start" />
            {bundle.callCount ? bundle.callCount : 0} calls
          </p>
          <p>
            <Icon name="start" />
            {bundle.documentCount ? bundle.documentCount : 0} Legal Documents
          </p>
          <p>
            <Icon name="start" />
            {bundle.documentReview ? bundle.documentReview : 0} Legal Document
            Review
          </p>
          <p>
            <Icon name="start" />
            {bundle.letterCount ? bundle.letterCount : 0} Legal Letters/Emails
          </p>
        </div>
      );
    }
  }

  selectCard = (card) => {
    this.setState({ card });
  };

  handleSubmit = (err, res) => {
    const { selected: type, packages, years } = this.state;
    const { createSubscription, user } = this.props;
    if (err) {
      Toaster.error('Payment failed, please try again');
    } else {
      const subscription = {
        type,
        txref: res.txRef,
        years: parseInt(years, 10),
        data: res,
      };
      createSubscription(user._id, subscription, (err, res) => {
        if (!err) {
          this.setState({ selected: null });
          Toaster.success('Your subscription has been processed');
        } else {
          Toaster.error(err.message || 'Error processing request');
        }
      });
    }
  };

  handleCancelSubscription = () => {
    const {
      subscriptions: { item },
      mySubscription,
      showConfirmModal,
      removeSubscription,
      user,
    } = this.props;
    showConfirmModal({
      title: 'Terminate Subscription',
      message: 'Are you sure you want to end your subscription?',
      buttons: [
        {
          intent: 'danger',
          label: 'End',
          action() {
            removeSubscription(user._id, item._id, (err, res) => {
              if (!err) {
                mySubscription(user._id);
              } else {
                Toaster.error(err.message || err);
              }
            });
          },
        },
      ],
    });
  };

  renderPackage = (item, i) => {
    const { selected } = this.state;
    const { disabledPackages = [] } = this.props;
    const disabled = disabledPackages.indexOf(i) !== -1;

    function fill(item) {
      switch (item.type) {
        case 'individual':
          return (
            <div>
              <p>
                <Icon name="start" />
                <b>
                  {item.documentCount > 100 ? 'Unlimited ' : item.documentCount}
                </b>{' '}
                Standard Agreements
              </p>
              <p>
                <Icon name="start" />
                <b>{item.letters}</b> Legal Letters{' '}
              </p>
              <p>
                <Icon name="start" />
                <b>{item.email ? item.email : '-'}</b>
                {item.email ? ' emails' : ''}
              </p>
              <p>
                <Icon name="start" />
                <b>-</b>{' '}
              </p>
              <p>
                <Icon name="start" />
                <b>{item.callCount > 12 ? 'Unlimited' : item.callCount}</b>{' '}
                Calls
              </p>
              <p>
                <Icon name="start" />
                <b>{item.ticketCount}</b> Document Review
              </p>
            </div>
          );
        case 'corporate':
          return (
            <div>
              <p>
                <Icon name="start" />
                <b>
                  {item.documentCount > 100 ? 'Unlimited ' : item.documentCount}
                </b>{' '}
                Standard Agreements
              </p>
              <p>
                <Icon name="start" />
                <b>{item.bespokeCount ? item.bespokeCount : '-'}</b>
                {item.bespokeCount ? ' Bespoke Agreements' : ''}
              </p>
              <p>
                <Icon name="start" />
                <b>{item.letters}</b> letters/emails
              </p>
              <p>
                <Icon name="start" />
                <b>{item.ticketCount}</b> Document Review
              </p>
              <p>
                <Icon name="start" />
                <b>{item.legalOpCount}</b> consultation per {item.legalOpType}
              </p>
              <p>
                <Icon name="start" />
                <b>{item.regEntCount ? item.regEntCount : '-'}</b>
                {item.regEntCount ? ' Registered Entity Search' : ''}
              </p>
              <p>
                <Icon name="start" />
                <b>{item.statFilCount ? item.statFilCount : '-'}</b>
                {item.statFilCount ? ' Statutory Fillings' : ''}
              </p>
              <p>
                <Icon name="start" />
                <b>{item.tinRegCount ? item.tinRegCount : '-'}</b>
                {item.tinRegCount ? ' TIN Registration Support' : ''}
              </p>
              <p>
                <Icon name="start" />
                <b>{item.landRegCount ? item.landRegCount : '-'}</b>
                {item.landRegCount ? ' Land Registry Search' : ''}
              </p>
              <p>
                <Icon name="start" />
                <b>{item.emCallCount ? item.emCallCount : '-'}</b>
                {item.emCallCount
                  ? ' Emergency Legal consultation a month '
                  : ''}
              </p>
              <p>
                <Icon name="start" /> Up to <b>{item.discount}</b> discount on
                selected services.
              </p>
            </div>
          );
      }
    }

    return (
      <div
        key={i}
        className={`page-block col_md_45 text-left m-r-2 m-t-1  ${
          disabled ? '' : 'pointer'
        } ${selected === i ? 'active' : ''}`}
        style={{ float: 'left', height: '525px', padding: '10px' }}
        onClick={() => (disabled ? null : this.handleSelect(i))}
      >
        {selected === i ? (
          <div className="item-selected">
            <Icon name="check_circle" size={2} />
          </div>
        ) : null}
        <h2>{item.alias ? item.alias : item.name}</h2>
        {disabled && (
          <p className="text-muted">
            The package is not supported for this service
          </p>
        )}
        <div className="clearfix" />
        <h6 className="text-muted">You get</h6>
        {fill(item)}

        <h3 className="text-right text-color-primary m-r-2">
          {currency(item.price, 0)}
        </h3>
      </div>
    );
  };

  handleChange = (years) => {
    const errors = this.state.errors;
    errors.years = !/^\d$/.test(years);
    years = errors.years ? null : years;
    this.setState({ years, errors });
  };

  render() {
    const {
      subscriptions: { item, packages: packages_, isLoading },
      update,
    } = this.props;
    const { servicepartner, selected, card, years = 1, errors } = this.state;

    if (!packages_) {
      return <Spinner className="m-t-4 text-center" />;
    }

    let package_ = {};

    if (item) {
      package_ = packages_[item.type];
    }

    const packages = Object.keys(packages_);

    let isServicePartner = false;

    if (item) {
      isServicePartner = item.isServicePartner;
    }

    return (
      <div className="Subscription">
        <div className="page-title">Subscription</div>
        <div className="page-body">
          <h4 className="section-header">Your subscription packages</h4>
          {isServicePartner && <ServicePartnerPackage package_={item} />}
          {!isServicePartner && !servicepartner && item && (
            <div>
              {item.type && (
                <div>
                  <h2 className="text-color-primary">
                    <Icon name="star" /> {packages_[item.type].name}{' '}
                    Subscription
                  </h2>
                  <div className="m-t-4">
                    <p>Your subscription is active</p>
                    <p>
                      Start Date: <b>{moment(item.start).format('LL LT')}</b>
                    </p>
                    <p>
                      Expiry Date: <b>{moment(item.end).format('LL LT')}</b>
                    </p>
                  </div>
                  <div>
                    <h4 className="m-t-4">Subscription usage:</h4>
                    <p>
                      Documents: <b>{item.documentCount}</b> of{' '}
                      <b>
                        {package_.documentCount > 100
                          ? 'Unlimited'
                          : package_.documentCount}
                      </b>
                    </p>
                    <p>
                      Calls: <b>{item.callCount}</b> of{' '}
                      <b>
                        {package_.callCount > 100
                          ? 'Unlimited '
                          : package_.callCount}
                      </b>
                    </p>
                    <p>
                      Document Review: <b>{item.ticketCount}</b> of{' '}
                      <b>{package_.ticketCount}</b>
                    </p>
                  </div>

                  <div className="text-right">
                    <button
                      onClick={this.handleCancelSubscription}
                      className="pt-button pt-full pt-intent-danger"
                    >
                      Cancel Subscription
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* {servicepartner && <div>{this.renderPaid()}</div>} */}
          {item && !item.active && (
            <React.Fragment>
              <h4 className="section-header">Purchase subscription packages</h4>

              <div className="text-center">
                <em>You don't have an active subscription</em>
              </div>

              <h6 className="m-t-4">Select a package below to subscribe</h6>

              <div className="row m-t-4">
                <div className="col_md_100">
                  <h4>Individual Packages</h4>
                  {packages.map(
                    (i) =>
                      packages_[i].type === 'individual' &&
                      packages_[i].active === true &&
                      this.renderPackage(packages_[i], i)
                  )}
                </div>
              </div>

              <div className="row m-t-4">
                <div className="col_md_100">
                  <h4>Corporate Packages</h4>
                  {packages.map(
                    (i) =>
                      packages_[i].type === 'corporate' &&
                      packages_[i].active === true &&
                      this.renderPackage(packages_[i], i)
                  )}
                </div>
              </div>

              <div>
                <h6 className="text-muted m-l-1 m-t-2">
                  I will like to subscribe for <br />
                  <h1>
                    <EditableText
                      intent={errors.years ? Intent.DANGER : Intent.NONE}
                      defaultValue={1}
                      maxLength={2}
                      width={10}
                      style={{ width: 10 }}
                      placeholder="years"
                      onChange={this.handleChange}
                    />{' '}
                    {inflect('years', years)}
                  </h1>
                </h6>
              </div>
            </React.Fragment>
          )}
        </div>
        {selected && !isLoading && (
          <div>
            <Payment
              callback={this.handleSubmit}
              title="Checkout"
              data={{
                type: selected,
                payment_plan: packages_[selected].payment_plan,
                amount: packages_[selected].price * years,
              }}
              {...this.props}
            />
          </div>
        )}
      </div>
    );
  }
}
