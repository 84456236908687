const key = 'vlfCategories';

import { connect } from 'react-redux';
import React from 'react';
import PageComponent from '../../../components/PageComponent';
import Props from '../../../components/widgets/etable/props';
import NoItem from '../../../components/widgets/NoItem';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getTemplates } from '../../../actions/documentActions';
import Toaster from '../../../components/widgets/Toaster';
import axios from 'axios';
import { get, post, put, delete_ } from '../../../actions/request';

class Partners extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {
      volunteers: [],
    };
  }

  componentWillMount() {
    this.getVolunteers();
  }
  getVolunteers() {
    get(`end-sars/volunteers`, { json: true }).then((result) => {
      this.setState({ volunteers: result.json }, () => {});
    });
  }

  renderPartners() {
    const tableHeaders = [
      'First Name',
      'Last Name',
      'Email',
      'Phone Number',
      'State',
      'City',
      'Enrollment Number',
    ];
    let { volunteers } = this.state;
    const count = volunteers.length;

    return (
      <div className="m-t-2">
        <div className="table-container">
          <table className="pt-table">
            <thead>
              <tr>
                {tableHeaders.map((name, idx) => {
                  return <th key={idx}>{name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {volunteers &&
                volunteers.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.email}</td>
                      <td>{item.phoneNumber}</td>
                      <td>{item.state}</td>
                      <td>{item.city}</td>
                      <td>{item.enrollmentNumber}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {!count ? <NoItem>No Volunteer found</NoItem> : null}
        </div>
      </div>
    );
  }

  getAuthType = () => ['admin'];
  static propTypes = Props;
  renderPage() {
    return (
      <section className="pad_2 content-width">
        <div className="d-flex justify-content-between">
          <h2 className="m-b-2">Volunteers</h2>
        </div>
        {this.renderPartners()}
      </section>
    );
  }
}

export default (store) => ({
  path: `/admin/volunteers`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          getTemplates: (kwargs, callback) =>
            dispatch(getTemplates(kwargs, callback)),
        })
      )(Partners)
    );
  },
});
