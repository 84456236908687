import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import CKEditor from '../../components/widgets/CKEditor';
import { questionTypes } from '../../data';
import Toaster from '../../components/widgets/Toaster';
import { showConfirmModal } from '../../actions/modalActions';
import { all as getSubcategories } from '../../actions/subcategoryActions';
import {
  createTemplate,
  getTemplate,
  updateTemplate,
} from '../../actions/documentActions';
import Spinner from '../../components/widgets/Spinner';
import PageComponent from '../../components/PageComponent';
import NoItem from '../../components/widgets/NoItem';
import axios from 'axios';
import { post } from '../../actions/request';
const NEW = 'new';
class DocumentTemplate extends PageComponent {
  getAuthType = () => ['admin'];
  componentWillMount() {
    this.state = {
      selectedIcon: false,
      selectedFile: {},
      iconLink: {
        link: '',
        required: true,
      },
    };
    const { getSubcategories } = this.props;
    console.log;
    getSubcategories({ limit: 100 });
    this.fieldKey = new Date().getTime();
    const _id = this.props.params.id;
    if (_id !== NEW) {
      this.getDocumentTemplate(_id);
    } else {
      this.state.template = {
        fields: [],
        title: '',
        subscriptions: [],
        body: '',
        iconurl: '',
      };
    }
  }

  getDocumentTemplate = (_id) => {
    this.props.getTemplate(_id, (err, template) => {
      if (!err) {
        template.fields = template.fields || [];
        template.subscriptions = template.subscriptions || [];
        this.setState({ template });
      } else {
        Toaster.error(err.message);
      }
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target || e.srcElement;
    const { template } = this.state;
    if (name === 'subscriptions') {
      const index = template.subscriptions.indexOf(value);
      if (index === -1) {
        template.subscriptions.push(value);
      } else {
        template.subscriptions.splice(index, 1);
      }
    } else {
      template[name] = value;
    }
    this.setState({ template });
  };

  handleSubmit = (e) => {
    const {
      state: { template },
      props: {
        createTemplate,
        updateTemplate,
        state: { document, subcategory },
      },
    } = this;
    e.preventDefault();
    if (template.fields) {
      template.fields.map((field) => {
        if (field.options) {
          field.options = field.options.filter((option) => option);
        }
        return field;
      });
    }

    template.price = Number(template.price);
    template.iconurl = template.iconurl? template.iconurl.toString() : '';

    if (template._id) {
      updateTemplate(template._id, template, (err, res) => {
        if (!err) {
          Toaster.success('Template updated');
        } else {
          Toaster.error(err.message);
        }
      });
    } else {
      if (!template.category && subcategory.count) {
        template.category = subcategory.items[0]._id;
      }

      createTemplate(template, (err, res) => {
        if (!err) {
          Toaster.success('Template created');
          browserHistory.push(`/admin/document-templates`);
        } else {
          Toaster.error(err.message);
        }
      });
    }
  };

  handleAddField = (e) => {
    const {
      template,
      template: { fields },
    } = this.state;
    const key = `[FIELD: ${this.fieldKey}]`;
    this.fieldKey++;
    fields.push({ key, required: true, type: questionTypes[0] });
    if (this.editor) {
      this.editor.insertText(` ${key} `);
      template.fields = fields;
      this.setState({ template });
      Toaster.success(`New entry with key "${key}" inserted`);
    } else {
      Toaster.error(`Error linking editor`);
    }
  };

  handleDeleteField(idx) {
    const {
      template: { fields },
      template,
    } = this.state;
    const _this = this;
    const key = fields[idx] && fields[idx].key;
    if (key) {
      this.props.showConfirmModal({
        title: 'Delete Field',
        message: `Are you sure you want to delete ${key}`,
        buttons: [
          {
            label: 'Delete Field',
            intent: 'danger',
            action() {
              _this.editor.replaceText(key, '');
              Toaster.success(`Field removed at "${key}".`);
              fields.splice(idx, 1);
              _this.setState({ fields });
            },
          },
        ],
      });
    } else {
      Toaster.success(`No Field found at "${idx}" not found.`);
      fields.splice(idx, 1);
      template.fields = fields;
      this.setState({ template });
    }
  }

  handleChangeField(idx, e) {
    const { name, value, checked } = e.target || e.srcElement;
    const { template } = this.state;
    if (name === 'required') {
      template.fields[idx][name] = checked;
    } else {
      if (/options\-/.test(name)) {
        const index = parseInt(name.split('-')[1], 10);
        template.fields[idx].options[index] = value;
        const options = template.fields[idx].options;
        if (options.filter((i) => !!i).length === options.length) {
          options.push('');
        }
      } else {
        template.fields[idx][name] = value;
        if (name === 'type' && value === 'options') {
          template.fields[idx].options = [''];
        }
      }
    }
    this.setState({ template });
  }

  handleIconLink = (event) => {
    event.persist();

    let { template } = this.state;
    template.iconurl = event.target.value;

    this.setState((prevState) => ({
      iconLink: {
        ...prevState.iconLink,
        link: event.target.value,
        required:
          event.target.value.trim() === ''
            ? !this.state.iconLink.required
            : false,
      },
    }));
  };

  iconInputUploadHandler = async (event) => {
    let iconData = event.target.files[0];
    this.setState({
      selectedIcon: !this.state.selectedIcon,
      selectedFile: iconData,
    });

    this.setState((prevState) => ({
      iconLink: {
        ...prevState.iconLink,
        required: !this.state.iconLink.required,
      },
    }));
    this.addIconData(iconData);
  };

  async addIconData(iconData) {
    const data = new FormData();
    data.append('file', iconData);
    data.append('path', '/ticket');

    const user = localStorage.getItem('user');
    const session = localStorage.getItem('session');

    const response = await axios.post(`${window.SW_DOMAIN}/api/v1/file`, data, {
      headers: {
        user,
        session,
      },
    });

    const {
      state: { template },
    } = this;

    if (response.status === 200) {
      const { url } = response.data;
      template.iconurl = url;
    }
  }

  deleteIconUploadHandler = async (event, id) => {
    let {
      iconLink: { required },
    } = this.state;
    required = !this.state.iconLink.required;

    this.setState({
      selectedFile: null,
      selectedIcon: !this.state.selectedIcon,
    });

    this.setState((prevState) => ({
      iconLink: {
        ...prevState.iconLink,
        required: !this.state.iconLink.required,
      },
    }));
  };

  renderBreadCrumbs = (item, id) => {
    const name = (item && item.title) || id;
    return (
      <div>
        <ul className="pt-breadcrumbs">
          <li>
            <Link className="pt-breadcrumbs-collapsed" to="/admin"></Link>
          </li>
          <li>
            <Link className="pt-breadcrumb" to="/admin">
              Dashboard
            </Link>
          </li>
          <li>
            <Link className="pt-breadcrumb" to="/admin/document-templates">
              Document Templates
            </Link>
          </li>
          <li>
            <span className="pt-breadcrumb pt-breadcrumb-current">
              {name.ucFirst()}
            </span>
          </li>
        </ul>
        <br />
        <br />
      </div>
    );
  };

  renderPage() {
    const { template, iconLink } = this.state;
    const {
      state: {
        subcategory: { items: subcategories },
        subscription: { packages },
        document: {
          template: { isLoading },
        },
      },
      params: { id },
    } = this.props;

    if (!template) {
      return <Spinner className="m-t-4 text-center" />;
    }

    return (
      <div className="DocumentTemplate">
        <div className="content-width">
          <form onSubmit={this.handleSubmit}>
            {this.renderBreadCrumbs(template, id)}
            <h3>Create Document Template</h3>
            <p>Here's a quick tip on how to format a document template…</p>
            <p>[TIP GOES HERE]</p>
            <div className="m-t-4">
              <div className="row">
                <label className="pt-label col_45">
                  <h4>Title</h4>
                  <input
                    maxLength={100}
                    placeholder="Enter template title"
                    onChange={this.handleChange}
                    type="text"
                    className="pt-input pt-fill"
                    name="title"
                    defaultValue={template.title}
                    required
                  />
                </label>
                <div className="col_10" />
                <label className="pt-label col_45">
                  <h4>Category</h4>
                  <div className="pt-select">
                    <select
                      name="category"
                      onChange={this.handleChange}
                      defaultValue={template.category}
                      required
                    >
                      {subcategories.map(({ _id, name }, idx) => (
                        <option key={idx} value={_id} label={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </label>
              </div>
              <div>
                <label className="pt-label">
                  <h4>Description</h4>
                  <textarea
                    rows={3}
                    placeholder="Enter template description"
                    onChange={this.handleChange}
                    className="pt-input pt-fill m-t-1"
                    name="description"
                    defaultValue={template.description}
                    required
                  />
                </label>
              </div>
              <div className="row">
                <label className="pt-label col_45">
                  <h4>Price</h4>
                  <input
                    min={500}
                    placeholder="Enter pay-as-you-go cost"
                    onChange={this.handleChange}
                    type="number"
                    className="pt-input pt-fill"
                    name="price"
                    defaultValue={template.price}
                    required
                  />

                  <div class="icon-link" style={{ padding: '3rem 0 1rem 0' }}>
                    {/* Icons section */}
                    <h4>Icon Link</h4>
                    <input
                      type="url"
                      placeholder="insert icon link here"
                      className="pt-input pt-fill"
                      name="iconurl"
                      onChange={this.handleIconLink}
                      defaultValue={template.iconurl}
                    />
                  </div>

                  {/* Icon Button */}
                  <h4>Or Select here</h4>
                  <div
                    class="icon-link"
                    style={{
                      padding: '0rem 0',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      ref={(IconInput) => (this.IconInput = IconInput)}
                      onChange={this.iconInputUploadHandler}
                      name="iconfile"
                      value=""
                    />
                    <button
                      disabled={this.state.selectedIcon}
                      type="button"
                      style={{
                        border: 'none',
                        backgroundColor: this.state.selectedIcon
                          ? 'grey'
                          : '#411f4a',
                        padding: '20px',
                        color: '#fff',
                        borderRadius: '40px',
                        fontWeight: 'bold',
                      }}
                      onClick={() => this.IconInput.click()}
                    >
                      Pick an Icon
                    </button>
                    {!this.state.selectedIcon ? null : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '5px 20px',
                          backgroundColor: '#411f4a',
                          color: '#fff',
                          margin: '10px 15px',
                          borderRadius: '20px',
                        }}
                      >
                        <p style={{ fontSize: '14px', margin: '0' }}>
                          {this.state.selectedFile.name}
                        </p>
                        <input
                          type="button"
                          style={{
                            border: 'none',
                            background: 'none',
                            fontSize: '14px ',
                            color: '#fff',
                          }}
                          onClick={this.deleteIconUploadHandler}
                          value="X"
                        />
                      </div>
                    )}
                  </div>
                </label>
                <div className="col_10" />
                <label className="pt-label col_45">
                  <h4>Subscription Packages</h4>
                  <p className="text-muted">
                    Select the packages that <b>DO NOT</b> support this
                    Template.
                  </p>
                  <div className="row">
                    {Object.keys(packages).map((key) => (
                      <div key={key} className="col_50">
                        <label className="pt-control pt-switch pt-large">
                          <span className="capitalize">
                            {packages[key].name}
                          </span>
                          <input
                            type="checkbox"
                            name="subscriptions"
                            disabled={isLoading}
                            onChange={this.handleChange}
                            value={key}
                            defaultChecked={
                              template.subscriptions.indexOf(key) > -1
                            }
                          />
                          <span className="pt-control-indicator"></span>
                        </label>
                      </div>
                    ))}
                  </div>
                </label>
              </div>
              <div className="m-t-4">
                <h4>Frequently Asked Questions</h4>
                <CKEditor
                  height={300}
                  getEditor={(editor) => (this.faqsEditor = editor)}
                  name="faqs"
                  label=""
                  onChange={this.handleChange}
                  defaultValue={template.faqs}
                />
              </div>
              <div className="m-t-4">
                <h4>Document Text</h4>
                <CKEditor
                  height={600}
                  required
                  getEditor={(editor) => (this.editor = editor)}
                  name="body"
                  label=""
                  onChange={this.handleChange}
                  defaultValue={template.body}
                />
              </div>
              <div className="m-t-4">
                <h4>
                  Entry Fields{' '}
                  <div className="bt-action-right m-b-1">
                    <button
                      className="pt-button pt-intent-accent pt-icon-insert "
                      onClick={this.handleAddField}
                    >
                      Create
                    </button>
                  </div>
                </h4>
                <div className="clearfix"></div>
                <div>
                  {!template.fields.length && (
                    <div className="pt-card">
                      <NoItem>No Fields Created</NoItem>
                    </div>
                  )}
                  {template.fields.map((field, idx) => (
                    <div className="pt-card" key={idx}>
                      <h4>{idx + 1}.</h4>
                      <h6 className="pull-right" style={{ marginTop: -30 }}>
                        <code>{field.key}</code>
                      </h6>
                      <div className="row m-t-1">
                        <div className="col_45">
                          <label className="pt-label">
                            <span>Question</span>
                            <br />
                            <input
                              placeholder="What's your maiden name?"
                              onChange={this.handleChangeField.bind(this, idx)}
                              type="text"
                              className="pt-input pt-fill"
                              name="question"
                              defaultValue={field.question}
                              required
                            />
                          </label>
                          <label className="pt-label">
                            <span>Type of Entry</span>
                            <div className="pt-select">
                              <select
                                defaultValue={field.type}
                                onChange={this.handleChangeField.bind(
                                  this,
                                  idx
                                )}
                                className="pt-select pt-fill"
                                name="type"
                              >
                                {questionTypes.map((type, idx) => (
                                  <option
                                    key={idx}
                                    label={type.ucFirst()}
                                    value={type}
                                  >
                                    {type.ucFirst()}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </label>
                          <label className="pt-control pt-checkbox">
                            <input
                              checked={field.required}
                              type="checkbox"
                              name="required"
                              required
                              onChange={this.handleChangeField.bind(this, idx)}
                            />
                            <span className="pt-control-indicator"></span>
                            <span>Required Field?</span>
                          </label>
                          {field.type === 'options' && (
                            <label className="pt-label">
                              <span>Options</span>
                              {field.options.map((option, index) => (
                                <input
                                  key={index}
                                  placeholder={`Option ${index + 1}`}
                                  defaultValue={option}
                                  maxLength={30}
                                  onChange={this.handleChangeField.bind(
                                    this,
                                    idx
                                  )}
                                  type="text"
                                  className="pt-input pt-fill"
                                  name={`options-${index}`}
                                />
                              ))}
                            </label>
                          )}
                        </div>
                        <div className="col_10"></div>
                        <div className="col_45">
                          Description
                          <br />
                          <textarea
                            rows={7}
                            placeholder="Enter your maiden name to reference…"
                            onChange={this.handleChangeField.bind(this, idx)}
                            className="pt-input pt-fill m-t-1"
                            name="description"
                            defaultValue={field.description}
                          ></textarea>
                        </div>
                      </div>
                      <p className="text-right">
                        {/*<button type="submit" className="pt-button pt-intent-primary m-l-1 pt-icon-floppy-disk">Save</button>*/}
                        <button
                          type="button"
                          onClick={this.handleDeleteField.bind(this, idx)}
                          className="pt-button m-l-1 pt-intent-danger pt-icon-trash"
                        >
                          Delete
                        </button>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="text-right m-t-4">
              <button
                disabled={isLoading || !template.body || !template.title}
                className="pt-button pt-intent-primary m-l-1 pt-icon-floppy-disk"
              >
                {template._id ? 'Update' : 'Create'} Template
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default (store) => ({
  path: `/admin/document-templates/:id`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          showConfirmModal: (params) => dispatch(showConfirmModal(params)),
          getSubcategories: (params) => dispatch(getSubcategories(params)),
          createTemplate: (template, callback) =>
            dispatch(createTemplate(template, callback)),
          updateTemplate: (_id, template, callback) =>
            dispatch(updateTemplate(_id, template, callback)),
          getTemplate: (_id, callback) => dispatch(getTemplate(_id, callback)),
        })
      )(DocumentTemplate)
    );
  },
});
