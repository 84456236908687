import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { inflect } from 'inflection';
import { debounce } from 'throttle-debounce';
import NoItem from './NoItem';
export default class Table extends Component {
  static propTypes = {
    fetchMore: PropTypes.func.isRequired,
    data: PropTypes.shape({
      isLoading: PropTypes.bool,
      items: PropTypes.array,
      count: PropTypes.number,
    }),
    headers: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      func: PropTypes.func,
    })),
    path: PropTypes.string,
    onSelect: PropTypes.func,
  };

  static defaultProps = {
    path: null,
    onSelect: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      q: '',
      user: null,
    };
  }

  handleSearch = e => {
    const { fetchMore } = this.props;
    debounce(500, () => {
      const { value: q } = (e.target || e.srcElement);
      if (q && q.length > 1) {
        this.q = q;
        fetchMore(q, 1);
      } else if (!q) {
        this.q = null;
        getCalls(q, 1);
      }

      this.setState({ q });
    })();
  };

  renderHeader = () => {
    const { count } = this.props.data;

    return (<div className="row">
      <div className="col_md_60">
        <div className="pt-input-group">
          <span className="pt-icon pt-icon-search"></span>
          <input
            className="pt-input"
            type="search"
            placeholder="Search"
            dir="auto"
            onChange={this.handleSearch}
          />
        </div>
      </div>
      <div className="col_md_40">
        <span className="pt-navbar-divider"></span>
        <span className="pt-tag pt-minimal pt-large">
          {count} {inflect('entry', count)} found
        </span>
      </div>
    </div>);
  }

  renderPaginator = (count) => {
    if (!count) {
      return null;
    }

    const { page, q } = this.state;
    const { data: { isLoading }, fetchMore } = this.props;
    const total = Math.ceil(count / this.limit);
    let pages = [];
    for (let i = 0; i < total; i++) {
      pages.push(<div
        key={i}
        onClick={() => fetchMore(q, i + 1)}
        className={`page ${isLoading ? 'loading' : ''} ${page === i + 1 ? 'selected' : ''}`}>
        {i + 1}
      </div>);
    }

    return pages;
  };

  onSelect = user => {
    const { onSelect } = this.props;
    console.log(user);
    if (onSelect) {
      this.setState({ user });
      onSelect(user);
    }
  };

  renderTable = type => {
    const { session: { user }, headers, data, path, onSelect } = this.props;
    const { user: selectedUser } = this.state;
    const count = data.count || 0;
    return <div className="m-t-2">
      <div className="table-container" style={{overflowY:'scroll', height: '200px', width: '100%'}}>
        <table className="pt-table">
          <thead>
            <tr>{headers.map(({ label }, idx) => {
              return <th key={idx}>{label}</th>;
            })}</tr>
          </thead>
          <tbody>
            {
              data.items &&
              data.items.map((item, idx) =>
                (<tr
                  key={idx}
                  className={`${onSelect ? 'pointer' : ''} ${selectedUser && selectedUser._id === item._id ? 'selected' : ''}`}
                  onClick={() => this.onSelect(item)}
                >
                  {
                    headers.map(({ key, func }, idx) => {
                      const text = func ? func(item[key]) : item[key];
                      return (<td key={idx}>
                        {
                          idx === 0 && path ?
                            <Link to={`${path}/${item._id}`}>
                              {text}
                            </Link> :
                            text
                        }
                      </td>)
                    })
                  }
                </tr>)
              )}
          </tbody>
        </table>
        {!count ? <NoItem>No data available</NoItem> : null}
      </div>
      <div className="paginator">
        {this.renderPaginator(count)}
      </div>
    </div>;
  };

  render() {
    return (<div className="Table">
      {this.renderHeader()}
      {this.renderTable()}
    </div>);
  }
} 