import React from 'react';

const Dashboard = () =>

<section className="row align_center content-width">
  <div className="col_40">
    Dashboard
    <br /><br />
    Stats will go here.
  </div>
</section>;

export default Dashboard;
