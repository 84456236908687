import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NoItem from './NoItem';
import { error as toastrError } from './Toaster';
export default class CKEditor extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    required: PropTypes.bool,
    name: PropTypes.string,
    getEditor: PropTypes.func,
    height: PropTypes.number,
    defaultValue: PropTypes.string,
  };

  static defaultProps = {
    height: 300,
  };

  componentDidMount() {
    const { name, getEditor, height } = this.props;
    if (!window.CKEDITOR) {
      return toastrError('Error loading Editor, please reload this page');
    }

    const editor = window.CKEDITOR.replace(this.props.name, {
      skin: 'moono',
      height,
    });

    editor.on('change', e => {
      this.event = e;
      this.onChange(e.editor.getData());
    });

    editor.replaceText = this.replaceText.bind(this, editor);
    if (getEditor) {
      getEditor(editor);
    }
  }

  replaceText(editor, query, text) {
    query = query.replace('[', '\\[').replace(']', '//]');
    const data = editor.getData().replace(new RegExp(query, 'g'), text);
    editor.setData(data);
  }

  componentWillUnmount() {
    if (this.event) {
      this.event.removeListener();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue
    };
  }

  onChange = (value) => {
    this.setState({ value });
    const { onChange, name } = this.props;
    onChange({
      target: {
        value,
        name
      }
    });
  };

  render() {
    const { name, required } = this.props;

    if (!window.CKEDITOR) {
      return <div className="pt-card">
        <NoItem>Error loading editor</NoItem>
      </div>;
    }

    return (
      <div style={{ margin: '0 0 20px 0', width: '100%' }} >
        <b className="block">{this.props.label}</b>
        <br />
        <textarea
          id={name}
          name={name}
          defaultValue={this.state.value}
        />
      </div>
    );
  }
}