import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link, browserHistory } from 'react-router';
import { showConfirmModal, closeModal } from '../../actions/modalActions';
import { Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { all, remove, create, update } from '../../actions/plfActions';
import { all as allPlfCategories } from '../../actions/plfCategoryActions';
import { create as createFile } from '../../actions/fileActions';
import Spinner from '../../components/widgets/Spinner';
import Icon from '../../components/widgets/Icon';
import FileUploader from '../../components/widgets/FileUploader';
import Toaster from '../../components/widgets/Toaster';
import Heading from '../../components/widgets/Heading';
import NoItem from '../../components/widgets/NoItem';
import PageComponent from '../../components/PageComponent';
import PLFRequestPanel from '../../components/widgets/PLFRequestPanel';
import Checkout from '../../components/widgets/Checkout';

class PLFRequests extends PageComponent {
  getAuthType = () => ['guest', 'admin'];
  componentWillMount() {
    this.setTitle('I need a lawyer');
    const {
      allPlfCategories,
      location: { query: { message } },
    } = this.props;

    this.state = {
      page: 1,
      plf: message ? { message, files: [null, null] } : null,
    };

    this.limit = 10;
    this.getRequests(1);
    allPlfCategories({ limit: 100 });
  }

  getRequests = page => {
    const { props: { all, state: { session: { user: owner } } }, limit, q } = this;
    all({ q, page, limit, owner }, (err, res) => {
      if (!err) {
        this.setState({ page });
      } else {
        Toaster.error(err.message || err);
      }
    });
  };

  renderPaginator = count => {
    if (!count) {
      return null;
    }

    const { page } = this.state;
    const { call: { isLoading } } = this.props.state;
    const total = Math.ceil(count / this.limit);
    let pages = [];
    for (let i = 0; i < total; i++) {
      pages.push(<div
        key={i}
        onClick={this.getRequests.bind(this, i + 1)}
        className={`page ${isLoading ? 'loading' : ''} ${page === i + 1 ? 'selected' : ''}`}>
        {i + 1}
      </div>);
    }

    return pages;
  };

  createRequest(plf = { files: [null, null] }) {
    this.setState({ plf });
  };

  subscriptionCheckout = (plf) => {
    const {
      update,
      state: {
        subscription: {
          item: { _id: subscription }
        }
      }
    } = this.props;

    update(plf._id, { checkoutType: plf.checkoutType, subscription }, (err, plf) => {
      if (!err) {
        this.setState({ plf });
      } else {
        Toaster.error(err.message || err);
      }
    });
  };

  renderRequests = ({ _id, subject, status, messages, lawyer_expanded, stamp, paid }, idx) => {
    const { showCallModal } = this.props;
    return (<Link className="block" to={`/plf-requests/${_id}`}>
      <div className="PlfRequest pt-card pt-elevation-3 m-t-2" key={idx}>
        <small className={`plf-status pt-tag pt-intent-${status === 'open' ? 'success' : 'danger'}`}>{status}</small>
        {
          !paid && <small className="plf-status pt-tag pt-intent-danger m-r-1">UNPAID</small>
        }
        <h2>{subject}</h2>
        <p><Icon name="person" /> {lawyer_expanded ? lawyer_expanded.name : 'No contact lawyer yet'}</p>
        <hr />
        {/* <p className="plf-message">{messages.last().message}</p> */}
        <p className="pull-right"><small><Icon name="access_time" /> {moment(stamp).fromNow()}</small></p>
        <div className="clearfix" />
      </div>
    </Link>);
  };

  onPayment = (plf) => {
    this.createRequest(null);
    browserHistory.push(`/plf-requests/${plf._id}`);
  };

  handleSubmit = (plf, cb = () => { }) => {
    const { create } = this.props;
    create(plf, (err, plf) => {
      cb(err, plf);
      if (!err) {
        browserHistory.push(`/plf-requests/${plf._id}`);
      } else {
        Toaster.error(err.message || err);
      }
    });
  };

  renderBreadCrumbs = () => {
    return (<div>
      <ul className="pt-breadcrumbs">
        <li><Link className="pt-breadcrumbs-collapsed" to="/"></Link></li>
        <li><Link className="pt-breadcrumb pt-breadcrumb-current" to="/plf-requests">PLF Requests</Link></li>
      </ul>
      <br />
      <br />
    </div>);
  };

  renderPage() {
    const {
      createFile,
      state: {
        subscription: { pricing },
        plfCategory: { items: plfCategories },
        plf: { items, count, isLoading }
      }
    } = this.props;

    const { plf } = this.state;
    if (isLoading) {
      return <Spinner className="m-t-4 text-center" />;
    }

    return <div className="PLFRequests">
      <div className="content-width">
        {this.renderBreadCrumbs()}
        <button style={{ margin: '10px 0 -50px' }} className="pull-right pt-button pt-intent-accent" onClick={this.createRequest.bind(this, undefined)}>Create Request</button>
        <div className="clearfix" />
        <Heading title="My PLF Requests" />
        {
          plf ?
            <div>
              <PLFRequestPanel plfCategories={plfCategories} plf={plf} canSubmit={!plf._id} createFile={createFile} cancel={this.createRequest.bind(this, null)} submit={this.handleSubmit} />
              <Checkout price={pricing.plf} resource={plf} type="plf" cb={this.onPayment} />
            </div> :
            <div className="plfs">
              {
                count ?
                  <div>
                    {items.map(this.renderRequests)}
                    <div className="paginator">
                      {this.renderPaginator(count)}
                    </div>
                  </div> :
                  <NoItem>You haven't created any plf requests yet</NoItem>
              }
            </div>
        }
      </div>
    </div>
  }
}

export default store =>
  ({
    path: `/plf-requests`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({
            showConfirmModal: (params) => dispatch(showConfirmModal(params)),
            allPlfCategories: (kwargs) => dispatch(allPlfCategories(kwargs)),
            all: (kwargs, callback) => dispatch(all(kwargs, callback)),
            create: (json, callback) => dispatch(create(json, callback)),
            createFile: (file, callback) => dispatch(createFile(file, callback)),
            remove: (_id, json, callback) => dispatch(remove(_id, json, callback))
          }))(PLFRequests)
      );
    }
  });
