import React from 'react';
import {Link} from 'react-router';

export default class BlogPosts extends React.Component {
  render() {
        return (
            
            <div>
                <a href={"/blog/"+ this.props.post.ID}>
                    {this.props.post.featured_image ? (
                        <img 
                          alt="article header"
                          src={this.props.post.featured_image}
                          />
                    ): (
                        ""
                    )}
                    <h1>{this.props.post.title}</h1>
                    <div className="content">{excerpt}</div>
                </a>
                    <Link to={"/blog/" + this.props.post.ID}>
                        <button className="btn"> Read More</button>
                    </Link>
            </div>
        );

  }

}