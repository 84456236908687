import { success as toastrSuccess } from '../../../../components/widgets/Toaster';
import factory from '../../../../props/factory';
import { browserHistory } from 'react-router';
function search(query, dispatch) {
  return factory['subcategory'].gets(query).then(items => {
    if (items) {
      dispatch({
        type: 'EVENT_SEARCH',
        payload:
        {
          items,
          selected: []
        }
      });
    }
  });
}

export const event_search = event => {
  if (event) {
    event.preventDefault();
  }

  return (dispatch, get_state) => {
    let { q, limit, page } = get_state()['subcategories'];
    let query = { limit, page };

    if (event) {
      q = event.target.value;
      dispatch({
        type: 'EVENT_SEARCH',
        payload: { q }
      });
    }

    if (q.length > 2 && q.length < 129) {
      query.q = q;
    }

    return search(query, dispatch);
  };
};

export const event_update_limit = event => {
  event.preventDefault();

  const limit = parseInt(event.target.textContent, 10);
  return (dispatch, get_state) => {
    dispatch({
      type: 'EVENT_UPDATE_LIMIT',
      payload: { limit }
    });

    const { q, page } = get_state()['subcategories'];
    const query = { limit, page };

    if (q.length > 2 && q.length < 129) {
      query.q = q;
    }

    return search(query, dispatch);
  };
};

export const event_update_page = (page, event) => {
  event.preventDefault();
  if (page === null) {
    page = parseInt(event.target.value, 10);
  }

  if (!Number.isInteger(page)) {
    page = 1;
  }

  return (dispatch, get_state) => {
    const { items, limit, q } = get_state()['subcategories'];
    const max = Math.ceil(items.count / limit);

    if (page > max) {
      page = max;
    }
    if (page < 1) {
      page = 1;
    }

    let query = { limit, page };

    dispatch({
      type: 'EVENT_UPDATE_PAGE',
      payload: { page }
    });

    if (q.length > 2 && q.length < 129) {
      query.q = q;
    }

    return search(query, dispatch);
  };
};

export const event_toggle_checkbox = id => {
  return (dispatch, get_state) => {
    let { selected } = get_state()['subcategories'];
    const index = selected.indexOf(id);

    if (index > -1) {
      selected.splice(index, 1);
    }
    else {
      selected.push(id);
    }

    dispatch({
      type: 'EVENT_TOGGLE_CHECKBOX',
      payload: { selected: Array.from(selected) }
    });
  };
};

export const event_toggle_all_checkboxes = event => {
  return (dispatch, get_state) => {
    const { items } = get_state()['subcategories'];
    const selected = (event.target.checked ? items.items.map(i => i._id) : []);

    dispatch({
      type: 'EVENT_TOGGLE_ALL_CHECKBOXES',
      payload: { selected }
    });
  };
};

export const event_open_delete_alert = () => {
  return (dispatch, get_state) => {
    const { selected } = get_state()['subcategories'];

    if (selected.length) {
      dispatch({
        type: 'EVENT_OPEN_DELETE_ALERT',
        payload: { is_valid_delete_alert_open: true }
      });
    }
    else {
      dispatch({
        type: 'EVENT_OPEN_DELETE_ALERT',
        payload: { is_invalid_delete_alert_open: true }
      });
    }
  };
};

export const event_cancel_valid_delete_alert = () => {
  return {
    type: 'EVENT_CANCEL_VALID_DELETE_ALERT',
    payload: { is_valid_delete_alert_open: false }
  };
};

export const event_confirm_invalid_delete_alert = () => {
  return {
    type: 'EVENT_CONFIRM_INVALID_DELETE_ALERT',
    payload: { is_invalid_delete_alert_open: false }
  };
};

export const event_confirm_valid_delete_alert = () => {
  return (dispatch, get_state) => {
    let promises = [];
    const { selected } = get_state()['subcategories'];

    for (let id of selected) {
      promises.push(
        factory['subcategory'].delete(`/${id}`)
      );
    }

    const { q, limit, page } = get_state()['subcategories'];
    const query = { limit, page };

    if (q.length > 2 && q.length < 129) {
      query.q = q;
    }

    return Promise.all(promises).then(
      () => search(query, dispatch)
    ).then(() => {
      toastrSuccess('Deletion successfully completed.');
      dispatch({
        type: 'EVENT_CONFIRM_VALID_DELETE_ALERT',
        payload: { is_valid_delete_alert_open: false }
      });
    });
  };
};

export const event_confirm_invalid_edit_alert = () => {
  return {
    type: 'EVENT_CONFIRM_INVALID_EDIT_ALERT',
    payload: { is_invalid_edit_alert_open: false }
  };
};

export const event_open_add_dialog = () => {
  return {
    type: 'EVENT_OPEN_ADD_DIALOG',
    payload:
    {
      item: {},
      dialog_icon: 'insert',
      dialog_title: 'Create New',
      is_dialog_open: true
    }
  };
};

export const event_open_edit_dialog = id => {
  if (id) {
    return dispatch => {
      factory['subcategory'].get(null, `/${id}`).then(item => {
        if (item) {
          dispatch({
            type: 'EVENT_OPEN_EDIT_DIALOG',
            payload:
            {
              item: item,
              dialog_icon: 'edit',
              dialog_title: 'Edit',
              is_dialog_open: true
            }
          });
        }
      });
    };
  }
  else {
    return (dispatch, get_state) => {
      const { selected } = get_state()['subcategories'];
      if (!selected.length || selected.length > 1) {
        dispatch({
          type: 'EVENT_OPEN_EDIT_DIALOG',
          payload:
          {
            is_invalid_edit_alert_open: true
          }
        });
      }
      else {
        dispatch(
          event_open_edit_dialog(selected[0])
        );
      }
    };
  }
};

export const event_close_dialog = () => {
  return {
    type: 'EVENT_CLOSE_DIALOG',
    payload:
    {
      is_dialog_open: false
    }
  };
};

export const event_save_dialog = (event) => {
  if (event) {
    event.preventDefault();
  }

  let data = {};
  for (let el of event.target) {
    if (el.tagName && el.tagName != 'BUTTON') {
      data[el.getAttribute('name')] = el.value;
    }
  }
  data.active = (data.active == 'on');
  delete data.stamp;
  if (!data.password.length) {
    delete data.password;
  }

  const method = (data._id ? 'put' : 'post');
  const uri = (data._id ? `/${data._id}` : '/');

  return (dispatch, get_state) => {
    let { q, limit, page } = get_state()['subcategories'];
    let query = { limit, page };

    if (q.length > 2 && q.length < 129) {
      query.q = q;
    }

    factory['subcategory'][method](data, uri).then(item => {
      if (item) {
        dispatch({
          type: 'EVENT_SAVE_DIALOG',
          payload:
          {
            is_dialog_open: false
          }
        });
      }
    }).then(
      () => search(query, dispatch)
      );
  };
};

const initial = {
  item: {},
  item_props: factory['subcategory'].propTypes,
  items: { count: 0, items: [] },
  q: '',
  limit: 10,
  page: 1,
  selected: [],

  dialog_icon: '',
  dialog_title: '',

  is_valid_delete_alert_open: false,
  is_invalid_delete_alert_open: false,
  is_invalid_edit_alert_open: false,
  is_dialog_open: false
};

export default (state = initial, action) => (
  action.payload ? Object.assign({}, state, action.payload) : state
);

export const event_delete_item = (id) => {
  return (dispatch, get_state) => {
    dispatch({
      type: 'EVENT_TOGGLE_CHECKBOX',
      payload: { selected: Array.from([id]) }
    });

    event_open_delete_alert()(dispatch, get_state);
  };
};