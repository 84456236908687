import { get, post, put, delete_ } from './request';

export function create(json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CALL_CREATE',
    });

    post(`call`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CALL_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all({ owner: result.json.owner }));
          dispatch({ type: 'CALL_CREATE_OK', call: result.json });
          if (window.ga) {
            window.ga('send', 'event', 'Call', 'create');
          }
        }
      })
      .catch((error) => dispatch({ type: 'CALL_CREATE_ERROR', error }));
  };
}

export function update(_id, json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CALL_UPDATE',
    });

    put(`call/${_id}`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CALL_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all({ owner: json.owner }));
          dispatch({ type: 'CALL_UPDATE_OK', call: result.json });
        }
      })
      .catch((error) => dispatch({ type: 'CALL_ERROR', error }));
  };
}

export function remove(_id, json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CALL_DELETE',
    });

    delete_(`call/${_id}`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CALL_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all({ owner: json.owner }));
          dispatch({ type: 'CALL_DELETE_OK', call: result.json });
        }
      })
      .catch((error) => dispatch({ type: 'CALL_ERROR', error }));
  };
}

export function find(_id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CALL_GET',
    });

    get(`call/${_id}`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CALL_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch({ type: 'CALL_GET_OK', call: result.json });
        }
      })
      .catch((error) => dispatch({ type: 'CALL_GET_ERROR', error }));
  };
}

export function getSlots(date, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CALL_SLOTS',
    });
    get(`call_slots?date=${date}`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CALL_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch({ type: 'CALL_SLOTS_OK', slots: result.json });
        }
      })
      .catch((error) => dispatch({ type: 'CALLS_ERROR', error }));
  };
}

export function all(kwargs = {}, callback = () => {}) {
  return (dispatch, getState) => {
    if (!kwargs.useCallback) {
      dispatch({
        type: 'CALLS_GET',
      });
    }

    const q = kwargs.q ? `&q=${kwargs.q}` : '';
    const owner = kwargs.owner ? `&owner=${kwargs.owner}` : '';
    const page = kwargs.page ? `&page=${kwargs.page}` : '';
    const status = kwargs.status ? `&status=${kwargs.status}` : '';
    const is_valid = kwargs.is_valid ? `&is_valid=${kwargs.is_valid}` : '';
    const limit = `?limit=${
      typeof kwargs.limit === 'number' ? kwargs.limit : 10
    }`;
    get(`calls${limit}${q}${owner}${page}${status}${is_valid}`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          if (!kwargs.useCallback) {
            dispatch({ type: 'CALL_ERROR', error: result.response.statusText });
          }
        } else {
          callback(null, result.json);
          if (!kwargs.useCallback) {
            dispatch({ type: 'CALLS_OK', data: result.json });
          }
        }
      })
      .catch((error) => {
        callback(error);
        if (!kwargs.useCallback) {
          dispatch({ type: 'CALLS_ERROR', error });
        }
      });
  };
}
