import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Button } from '@blueprintjs/core';

class BusinessStimulus extends React.Component {
  componentWillMount() {
    localStorage.setItem('business_stimulus', true);
  }
  render() {
    return (
      <div className="StaticPage">
        <div className="page-content">
          <div>
            <h1 className="text-center">BUSINESS STIMULUS ELIGIBILITY TEST</h1>
            <p className="m-b-8">
              Is Your Business Eligible to Benefit from the N50 billion Targeted
              Credit Facility (TCF) by the Central Bank of Nigeria (CBN)? Find
              Out Here! Powered by Mylaw.ng&nbsp;
            </p>

            {/* src="https://docs.google.com/forms/d/e/1FAIpQLSdsVR4SPXGLTNaRfRpmmDabx4NgQ1G-K2JHFneMES5DcUM-uA/viewform?embedded=true" */}
            <div className="m-t-4 text-center">
              <iframe
                src="https://docs.google.com/forms/d/1nCRdxlEqyp_7OX7u-D6Gn7J028xMtMkd8_EhTNGatCU/edit?ts=5f71efc1&gxids=7757"
                width="100%"
                height="700"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
              >
                Loading…
              </iframe>
              <div className="text-center mx-auto">
                <h5>
                  <strong>
                    Use this coupon{' '}
                    <span className="font-weight-bolder text-danger h3">
                      TCFC19
                    </span>{' '}
                    to get 80% discount on your consultation
                  </strong>
                  <Link className="d-flex" to="/calls">
                    <Button
                      className="pt-button pt-intent-primary mx-auto mt-2"
                      text="Book a Consultation"
                    />
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (store) => ({
  path: `/business_stimulus_test`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({})
      )(BusinessStimulus)
    );
  },
});
