import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';

const FOOTER_LINKS = [
  {
    name: 'Home',
    to: '/',
  },
  {
    name: 'My Account',
    to: '/account',
  },
  {
    name: 'Privacy Policy',
    to: '/privacy',
  },
  {
    name: 'Terms of use',
    to: '/terms',
  },
  {
    name: 'Blog',
    to: '/blog',
  },
  {
    name: 'FAQs',
    to: '/faqs',
  },
];

const THIS_YEAR = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="Footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 copyright-text">
            Copyright &copy;{THIS_YEAR} <b>Virtual Platform Limited</b>
          </div>
          <div className="col-md-6 col-sm-12 links">
            {FOOTER_LINKS.map(({ to, name }, idx) => (
              <Link key={idx} className="link" to={to}>
                {name}
              </Link>
            ))}
            <div className="social-links">
              {/*this.socialLinks.map(({ image, url }, idx) => <a key={idx} href={url} target="_blank"><img alt="Social Media" src={image} /></a>)*/}
            </div>
          </div>
        </div>
        <p className="need-help-text">
          Need Help? You can reach us on{' '}
          <a href="tel:08140000829">
            <b>0814-000-0829</b>
          </a>{' '}
          or{' '}
          <a href="mailto:info@mylaw.ng">
            <b>info@mylaw.ng</b>
          </a>
          .
        </p>
        <div className="m-t-4 disclaimer-text">
          <h3>Disclaimer</h3>
          <p>
            MYLAW.NG is not a law firm and does not engage in legal practice,
            legal advisory or legal consultancy services. All legal services are
            provided by independent lawyers and law firms. MYLAW.NG is not
            responsible for the services rendered by the legal practitioners on
            this platform.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
