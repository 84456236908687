import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { questionTypes } from '../../data';
import { cleanObject } from '../../lib/utils';
import Toaster from '../../components/widgets/Toaster';
import { showConfirmModal } from '../../actions/modalActions';
import { create, update, find } from '../../actions/vlfCategoryActions';
import Spinner from '../../components/widgets/Spinner';
import NoItem from '../../components/widgets/NoItem';
import PageComponent from '../../components/PageComponent';
const NEW = 'new';
class VlfCategory extends PageComponent {
  getAuthType = () => ['admin'];
  componentWillMount() {
    this.state = {};
    this.fieldKey = 1;
    const _id = this.props.params.id;
    if (_id !== NEW) {
      this.get(_id);
    } else {
      this.state.vlfCategory = {
        active: true,
        subscriptions: [],
      };
    }
  }

  get = (_id) => {
    this.props.find(_id, (err, vlfCategory) => {
      if (!err) {
        vlfCategory.subscriptions = vlfCategory.subscriptions || [];
        this.setState({ vlfCategory });
      } else {
        Toaster.error(err.message);
      }
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target || e.srcElement;
    const { vlfCategory } = this.state;
    if (name === 'subscriptions') {
      const index = vlfCategory.subscriptions.indexOf(value);
      if (index === -1) {
        vlfCategory.subscriptions.push(value);
      } else {
        vlfCategory.subscriptions.splice(index, 1);
      }
    } else {
      vlfCategory[name] = value;
    }

    this.setState({ vlfCategory });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      state: { vlfCategory },
      props: { create, update },
    } = this;

    vlfCategory.price = Number(vlfCategory.price);
    if (vlfCategory._id) {
      update(vlfCategory._id, cleanObject(vlfCategory), (err, res) => {
        if (!err) {
          Toaster.success('VLF Category updated');
          browserHistory.push(`/admin/vlf-categories`);
        } else {
          Toaster.error(err.message);
        }
      });
    } else {
      create(cleanObject(vlfCategory), (err, res) => {
        if (!err) {
          Toaster.success('VLF Category created');
          browserHistory.push(`/admin/vlf-categories`);
        } else {
          Toaster.error(err.message);
        }
      });
    }
  };

  renderBreadCrumbs = (item, id) => {
    const name = (item && item.name) || id;
    return (
      <div>
        <ul className="pt-breadcrumbs">
          <li>
            <Link className="pt-breadcrumbs-collapsed" to="/admin"></Link>
          </li>
          <li>
            <Link className="pt-breadcrumb" to="/admin">
              Dashboard
            </Link>
          </li>
          <li>
            <Link className="pt-breadcrumb" to="/admin/vlf-categories">
              VLF Categories
            </Link>
          </li>
          <li>
            <span className="pt-breadcrumb pt-breadcrumb-current">
              {name.ucFirst()}
            </span>
          </li>
        </ul>
        <br />
        <br />
      </div>
    );
  };

  renderPage() {
    const { vlfCategory } = this.state;
    const {
      state: {
        subscription: { packages },
        vlfCategory: { isLoading },
      },
      params: { id },
    } = this.props;

    if (!vlfCategory) {
      return <Spinner className="m-t-4 text-center" />;
    }

    return (
      <div className="VLFCategory">
        <form onSubmit={this.handleSubmit} className="content-width">
          {this.renderBreadCrumbs(vlfCategory, id)}
          <h3>{vlfCategory._id ? 'Edit' : 'Create'} VLF Category</h3>
          <div className="row m-t-4">
            <label className="pt-label col_45">
              Name
              <br />
              <input
                type="text"
                maxLength={100}
                onChange={this.handleChange}
                className="pt-input pt-fill"
                name="name"
                placeholder="Enter name"
                defaultValue={vlfCategory.name}
                required
              />
            </label>
            <div className="col_10"></div>
            <label className="pt-label col_45">
              Price
              <br />
              <input
                type="number"
                min={100}
                maxLength={100}
                onChange={this.handleChange}
                className="pt-input pt-fill"
                name="price"
                placeholder="Enter price"
                defaultValue={vlfCategory.price}
                required
              />
            </label>
          </div>

          <div className="row">
            <label className="pt-label col_md_45">
              Description
              <br />
              <textarea
                type="text"
                maxLength={140}
                rows={5}
                onChange={this.handleChange}
                className="pt-input pt-fill"
                name="description"
                placeholder="(Optional)"
                defaultValue={vlfCategory.description}
              />
            </label>
            <div className="col_md_10" />
            <label className="pt-label col_md_45">
              <h4>Subscription Packages</h4>
              <p className="text-muted">
                Select the packages that <b>DO NOT</b> support this Ticket.
              </p>
              {Object.keys(packages).map((key) => (
                <div key={key} className="col_50">
                  <label className="pt-control pt-switch pt-large">
                    <span className="capitalize">{packages[key].name}</span>
                    <input
                      type="checkbox"
                      name="subscriptions"
                      disabled={isLoading}
                      onChange={this.handleChange}
                      value={key}
                      defaultChecked={
                        vlfCategory.subscriptions.indexOf(key) > -1
                      }
                    />
                    <span className="pt-control-indicator"></span>
                  </label>
                </div>
              ))}
            </label>
          </div>
          <div>
            <br />
            <button
              type="submit"
              disabled={isLoading}
              className="pt-button pt-intent-primary"
            >
              <span>Save</span>
            </button>
            <span>&nbsp;</span>
            <Link to={isLoading ? null : '/admin/vlf-categories'}>
              <button type="button" className="pt-button" disabled={isLoading}>
                <span>Cancel</span>
              </button>
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default (store) => ({
  path: `/admin/vlf-categories/:id`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          showConfirmModal: (params) => dispatch(showConfirmModal(params)),
          create: (vlfCategory, callback) =>
            dispatch(create(vlfCategory, callback)),
          update: (_id, vlfCategory, callback) =>
            dispatch(update(_id, vlfCategory, callback)),
          find: (_id, callback) => dispatch(find(_id, callback)),
        })
      )(VlfCategory)
    );
  },
});
