const key = 'document-templates';

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { injectReducer } from '../../../store/reducers';
import PageComponent from '../../../components/PageComponent';
import reducer, * as actions from './modules';
import Table from '../../../components/widgets/etable/custom';
import Props from '../../../components/widgets/etable/props';
import { currency } from '../../../lib/utils';

class DocumentTemplates extends PageComponent {
  getAuthType = () => ['admin'];
  static propTypes = Props;
  renderPage() {
    const { props } = this;
    const options = Object.assign({}, props, {
      editKey: 'admin/document-templates',
      legend: [
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'price',
          className: 'text-muted',
          fn: price => (price ? `${currency(price)}` : 0),
          label: 'Price',
        },
        {
          key: 'stamp',
          className: 'text-muted',
          label: 'Last Updated',
          fn: stamp => moment(stamp).format('LL LT'),
        },
        {
          key: 'subscriptions',
          label: 'Non-subcription (packages)',
          className: 'text-muted',
          fn: (subcriptions = []) => 0,
        },
      ],
    });

    return (
      <section className="pad_2 content-width">
        <h2 className="m-b-2">Document Templates</h2>
        {React.createFactory(Table)(options)}
      </section>
    );
  }
}

export default store => ({
  path: `/admin/document-templates`,
  getComponent(next, cb) {
    injectReducer(store, { key, reducer });
    cb(
      null,
      connect(
        state => ({ ...state, ...state[key] }),
        actions
      )(DocumentTemplates)
    );
  },
});
