import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { showConfirmModal } from '../../actions/modalActions';
import { all as getCategories } from '../../actions/categoryActions';
import { all as getSubcategories } from '../../actions/subcategoryActions';
import { getTemplates } from '../../actions/documentActions';
import Spinner from '../../components/widgets/Spinner';
import NoItem from '../../components/widgets/NoItem';
import Heading from '../../components/widgets/Heading';

class DocumentCategories extends React.Component {
  componentWillMount() {
    const { getCategories, getSubcategories, getTemplates } = this.props;
    getCategories({ limit: 100 });
    getSubcategories({ limit: 100 });
    getTemplates({ limit: 100 });
    this.alphabets = [];
    for (var i = 97; i < 123; i++) {
      this.alphabets.push(String.fromCharCode(i));
    }

    this.state = {
      filter: 'a',
    };

    console.log(this.props);
    window.document.title = `Agreement Templates | ${window.APP_TITLE}`;
  }

  getDocumentTemplate = _id => {
    this.props.getTemplate(_id, (err, template) => {
      if (!err) {
        this.setState({ template });
      } else {
        Toaster.error(err.message);
      }
    });
  };

  handleFilter = filter => {
    this.setState({ filter });
  };

  render() {
    const {
      state: {
        subcategory: { items: subcategories, isLoading: isLoading3 },
        category: { items: categories, isLoading: isLoading2 },
        document: {
          template: { items: templates, isLoading },
        },
      },
      params: { id },
    } = this.props;

    const { filter } = this.state;

    // templates ? templates : [];
    console.log(this.state);

    const filteredTemplates = templates.filter(
      i => i.title.toLowerCase().indexOf(filter) === 0
    );
    if (isLoading || isLoading2) {
      return <Spinner className="m-t-4 text-center" />;
    }

    if (!templates) {
      return <NoItem className="m-t-4">No Templates exist</NoItem>;
    }

    return (
      <div className="DocumentCategories">
        <div className="content-width">
          <div style={{ minHeight: 300 }} className="m-t-4">
            <Heading title="Search by alphabets" />
            <p>
              Choose any Standard Agreement, answer all questions, preview and
              download your personalized Agreement in 5 minutes or less.{' '}
            </p>
            <div className="categories">
              <div className="alphabet-container">
                {this.alphabets.map(a => (
                  <a
                    key={a}
                    className={`alphabet ${filter === a ? 'selected' : ''}`}
                    onClick={() => this.handleFilter(a)}
                  >
                    {a}
                  </a>
                ))}
              </div>
              <div className="category pt-card pt-elevation-3">
                {filteredTemplates.length > 0 ? (
                  filteredTemplates.map((template, idx) => (
                    <Link
                      key={idx}
                      className="m-t-1 m-r-1 template pt-button pt-intent-primary pt-large"
                      to={`/document-category/templates/${template._id}`}
                    >
                      {template.title}
                    </Link>
                  ))
                ) : (
                  <NoItem>
                    No templates starting with{' '}
                    <em className="capitalize text-muted">{filter}</em>
                  </NoItem>
                )}
              </div>
            </div>
          </div>
          <div className="p-t-2">
            <Heading title="Search by category" />
            <div className="categories">
              <div className="row">
                {categories.map(({ name, _id, icon }, idx) => (
                  <div key={idx} className="col_md_50">
                    <div className="category pt-card pt-elevation-3 m-a-1">
                      <h3 className="">
                        {icon && <span className={`pt-icon pt-icon-${icon}`} />}
                        {name}
                      </h3>
                      {subcategories
                        .filter(subcategory => subcategory.category === _id)
                        .map(({ name, _id }, idx) => (
                          <div key={idx}>
                            <h5 className="slim m-t-3">{name}</h5>
                            <div className="templates">
                              {!templates.filter(
                                ({ category }) => _id === category
                              ).length && (
                                <NoItem>
                                  No templates in this subcategory
                                </NoItem>
                              )}
                              {templates
                                .filter(({ category }) => _id === category)
                                .map((template, idx) => (
                                  <Link
                                    key={idx}
                                    className="m-t-1 m-r-1 pt-button template pt-intent-primary pt-large"
                                    to={`/document-category/templates/${template._id}`}
                                  >
                                    {template.title}
                                  </Link>
                                ))}
                            </div>
                          </div>
                        ))}
                      {!subcategories.filter(({ category }) => category === _id)
                        .length && (
                        <NoItem>No Templates in this category</NoItem>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default store => ({
  path: `/document-categories`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        state => ({ state }),
        dispatch => ({
          showConfirmModal: params => dispatch(showConfirmModal(params)),
          getCategories: params => dispatch(getCategories(params)),
          getSubcategories: params => dispatch(getSubcategories(params)),
          getTemplates: (kwargs, callback) =>
            dispatch(getTemplates(kwargs, callback)),
        })
      )(DocumentCategories)
    );
  },
});
