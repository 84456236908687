import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import CKEditor from '../../components/widgets/CKEditor';
import { questionTypes } from '../../data';
import { cleanObject } from '../../lib/utils';
import Toaster from '../../components/widgets/Toaster';
import { showConfirmModal } from '../../actions/modalActions';
import { create, update, find } from '../../actions/categoryActions';
import Spinner from '../../components/widgets/Spinner';
import NoItem from '../../components/widgets/NoItem';
import PageComponent from '../../components/PageComponent';
const NEW = 'new';
class Category extends PageComponent {
  getAuthType = () => ['admin'];
  componentWillMount() {
    this.state = {};
    this.fieldKey = 1;
    const _id = this.props.params.id;
    if (_id !== NEW) {
      this.get(_id);
    } else {
      this.state.category = {
        active: true
      };
    }
  }

  get = (_id) => {
    this.props.find(_id, (err, category) => {
      if (!err) {
        this.setState({ category });
      } else {
        Toaster.error(err.message);
      }
    });
  };

  handleChange = e => {
    const { name, value } = e.target || e.srcElement;
    const { category } = this.state;
    category[name] = value;
    this.setState({ category });
  };


  handleSubmit = e => {
    e.preventDefault();
    const {
      state: { category },
      props: { create, update }
    } = this;

    if (category._id) {
      update(category._id, cleanObject(category), (err, res) => {
        if (!err) {
          Toaster.success('Category updated');
        } else {
          Toaster.error(err.message);
        }
      });
    } else {
      create(cleanObject(category), (err, res) => {
        if (!err) {
          Toaster.success('Category created');
          browserHistory.push(`/admin/categories`);
        } else {
          Toaster.error(err.message);
        }
      });
    }
  };

  handleAddField() {
    const { template: { fields } } = this.state;
    const key = `[FIELD: ${this.fieldKey}]`;
    this.fieldKey++;
    fields.push({ key });
    this.setState({ fields });
    if (this.editor) {
      this.editor.insertText(` ${key} `);
      Toaster.success(`New entry with key "${key}" inserted`);
    } else {
      Toaster.error(`Error linking editor`);
    }
  }

  handleDeleteField(idx) {
    const { template: { fields }, template } = this.state;
    const _this = this;
    const key = fields[idx] && fields[idx].key;
    if (key) {
      this.props.showConfirmModal({
        title: 'Delete Field',
        message: `Are you sure you want to delete ${key}`,
        buttons: [
          {
            label: 'Delete Field',
            intent: 'danger',
            action() {
              _this.editor.replaceText(key, '');
              Toaster.success(`Field removed at "${key}".`);
              fields.splice(idx, 1);
              _this.setState({ fields });
            }
          }]
      });
    } else {
      Toaster.success(`No Field found at "${idx}" not found.`);
      fields.splice(idx, 1);
      template.fields = fields;
      this.setState({ template });
    }
  }

  handleChangeField(idx, e) {
    const { name, value } = e.target || e.srcElement;
    const { category } = this.state;
    category.fields[idx][name] = value;
    this.setState({ category });
  }

  renderBreadCrumbs = (item, id) => {
    const name = item && item.name || id;
    return (<div>
      <ul className="pt-breadcrumbs">
        <li><Link className="pt-breadcrumbs-collapsed" to="/admin"></Link></li>
        <li><Link className="pt-breadcrumb" to="/admin">Dashboard</Link></li>
        <li><Link className="pt-breadcrumb" to="/admin/categories">Categories</Link></li>
        <li><span className="pt-breadcrumb pt-breadcrumb-current">{name.ucFirst()}</span></li>
      </ul>
      <br />
      <br />
    </div>);
  };

  renderPage() {
    const { category } = this.state;
    const { state: { category: { isLoading } }, params: { id } } = this.props;

    if (!category) {
      return <Spinner className="m-t-4 text-center" />;
    }

    return <div className="Category">
      <form onSubmit={this.handleSubmit} className="content-width">
        {this.renderBreadCrumbs(category, id)}
        <h3>{category._id ? 'Edit' : 'Create'} Category</h3>
        {/*<div className="row">
          <label className="pt-label col_25">
            <label className="pt-control pt-switch pt-large">
              <input
                type="checkbox"
                name="active"
                onChange={this.handleChange}
                defaultChecked={category.active}
              />
              <span className="pt-control-indicator"></span>
              Active
              </label>
          </label>
        </div>*/}
        <div className="row m-t-4">
          <label className="pt-label col_45">
            Name
          <br />
            <input type="text" maxLength={100} onChange={this.handleChange} className="pt-input pt-fill" name="name" placeholder="Enter Category Name" defaultValue={category.name} required />
          </label>
          <div className="col_10"></div>
          <label className="pt-label col_45">
            <span><span className={`pt-icon pt-icon-${category.icon}`}></span> Icon</span>
            <br />
            <input maxLength={15} type="text" onChange={this.handleChange} className="pt-input pt-fill" name="icon" placeholder="(Optional)" defaultValue={category.icon} />
          </label>
        </div>

        <div className="row">
          <label className="pt-label col_100">
            Description
          <br />
            <textarea type="text" maxLength={140} rows={5} onChange={this.handleChange} className="pt-input pt-fill" name="description" placeholder="(Optional)" defaultValue={category.description} />
          </label>
        </div>
        <div>
          <br />
          <button type="submit" disabled={isLoading} className="pt-button pt-intent-primary"><span>Save</span></button>
          <span>&nbsp;</span>
          <Link to={isLoading ? null : '/admin/categories'}><button type="button" className="pt-button" disabled={isLoading}><span>Cancel</span></button></Link>
        </div>
      </form>
    </div>;
  }
}

export default store =>
  ({
    path: `/admin/categories/:id`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({
            showConfirmModal: (params) => dispatch(showConfirmModal(params)),
            create: (category, callback) => dispatch(create(category, callback)),
            update: (_id, category, callback) => dispatch(update(_id, category, callback)),
            find: (_id, callback) => dispatch(find(_id, callback))
          }))(Category)
      );
    }
  });
