import React from 'react';
import { connect } from 'react-redux';
import PrivacyContent from '../../components/PrivacyContent';

class PrivacyPolicy extends React.Component {
  render() {
    return <PrivacyContent />
  }
}

export default store =>
  ({
    path: `/privacy`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({

          }))(PrivacyPolicy)
      );
    }
  });
