import React from 'react';

const renderLineItem = (title, count, maxCount) => {
  return (
    <tr className="package-line-item">
      <td className="line-item-amount">
        {count}/{maxCount}
      </td>
      <td>{title}</td>
    </tr>
  );
};

const ticketIdMap = {
  '5bbf11d3c7d6fe0013bd996d': 'Business Name',
  '5d603d6c3c5af11bf1242134': 'Copyright Registration',
  '5a395bafa788660014a74e56': 'Document Review',
  '5a3171cdeded770014f78f14': 'Company Incoporation',
  '5d6abdf93405c70d10137372': 'Legal Letters/Emails',
  '5cdd8fc1d93f225b6ece9e1f': 'Patent Registration',
  '5ce81be023eb7a212538ae80': 'Trademark Registration',
  '5ef614fcd5b9f9c0229400b5': 'Basic Website',
};

const ServicePartnerPackage = ({ package_ }) => {
  return (
    <div className="service-partner-package">
      <h4 className="package-name">{package_.name}</h4>
      <table>
        {renderLineItem(
          'Documents',
          package_.document.count,
          package_.document.maxCount > 100
            ? 'unlimited'
            : package_.document.maxCount
        )}
        {renderLineItem(
          'Consultation',
          package_.consultation.count,
          package_.consultation.maxCount
        )}
        {package_.ticket.allowed.map((item, idx) =>
          renderLineItem(ticketIdMap[item.id], item.count, item.maxCount)
        )}
      </table>
    </div>
  );
};

export default ServicePartnerPackage;
