import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../components/widgets/Spinner';
import CreditCard from '../../components/widgets/CreditCard';
import Payment from '../../routes/account/Payment';
import Toaster from '../../components/widgets/Toaster';
import NoItem from '../../components/widgets/NoItem';
import Icon from '../../components/widgets/Icon';
import Subscription from '../../routes/account/Subscription';
import { update } from '../../actions/userActions';
import {
  showConfirmModal,
  showSigninModal,
  showSignupModal,
} from '../../actions/modalActions';
import uuid from 'uuid/v1';
import {
  create as createCard,
  remove as removeCard,
  all as getAllCards,
} from '../../actions/cardActions';
import {
  create as createSubscription,
  me as mySubscription,
} from '../../actions/subscriptionActions';
import { create as createPayment } from '../../actions/paymentActions';
import { currency } from '../../lib/utils';
import { get, post, put, delete_ } from '../../actions/request';
import axios from 'axios';

class Checkout extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    cb: PropTypes.func.isRequired,
    onlyCash: PropTypes.bool,
    onlySubscription: PropTypes.bool,
    checkoutType: PropTypes.string,
    resource: PropTypes.shape({
      paid: PropTypes.bool,
      checkoutType: PropTypes.string,
      slot: PropTypes.number,
      _id: PropTypes.string,
    }),
  };

  static defaultProps = {
    resource: null,
    onlyCash: false,
    onlySubscription: false,
  };

  componentWillMount() {
    const {
      mySubscription,
      getAllCards,
      checkoutType,
      type,
      state: {
        session: { user },
      },
    } = this.props;
    this.checkoutMethod(checkoutType);

    if (user) {
      mySubscription(user);
      getAllCards(user);
    }
  }

  checkoutMethod(checkoutType, cb) {
    this.setState({ checkoutType }, cb);
  }

  handleSetCard = (card) => {
    this.setState({ card });
  };

  handleSubscriptionCheckout = (evt) => {
    const {
      state: {
        subscription: { item: subscription },
      },
    } = this.props;
    let { checkoutType } = this.state;

    if (subscription && subscription.isServicePartner) {
      checkoutType = 'subscription';
    }

    this.checkoutMethod(checkoutType, this.handleMakePayment);
  };

  handleCouponPayment = (data) => {
    const {
      props: { createPayment, type, resource, cb },
      state: { checkoutType },
    } = this;

    const payload = {
      data: resource,
      type,
      txref: '',
      checkoutType: 'coupon-payment',
      quantity: resource.slot || 1,
      resource: resource._id,
    };

    createPayment(payload, (err) => {
      if (!err) {
        resource.paid = true;
        resource.checkoutType = checkoutType;
        cb(resource);
        Toaster.success('Payment successful');
      } else {
        Toaster.error(err.message || err);
      }
    });
  };

  handleStartupPayment = () => {
    let email = this.props.resource.owner_expanded.email;
    let prod = this.props.resource.vlf_category_expanded.alias;
    let resource_id = this.props.resource._id;
    let vlf_id = this.props.resource.vlf_category;

    if (
      vlf_id == '5a3171cdeded770014f78f14' ||
      vlf_id == '5bbf11d3c7d6fe0013bd996d' ||
      vlf_id == '5ce81be023eb7a212538ae80' ||
      vlf_id == '5dee3a5c376eaf7e253464e4' ||
      vlf_id == '5ef614fcd5b9f9c0229400b5'
    ) {
      let data = {
        email,
        prod,
        resource_id,
      };

      axios({
        url: `${window.SW_DOMAIN}/api/v1/startup`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      })
        .then((res) => {
          this.props.resource.paid = true;
          this.props.cb(this.props.resource);
          Toaster.success('Payment successful');
        })
        .catch(function (error) {
          if (error.response) {
            Toaster.error(error.response.data.msg);
          } else {
            Toaster.error('An error occured, please try again');
          }
        });
    } else {
      return Toaster.error(
        'Startup bundle cannot be used to pay for this service '
      );
    }
  };

  handleMakePayment = (err, res) => {
    const {
      props: { createPayment, type, resource, cb },
      state: { checkoutType },
    } = this;
    const payload = {
      data: resource,
      type,
      txref: !!res ? res.txRef : '',
      checkoutType,
      quantity: resource.slot || 1,
      resource: resource._id,
    };

    if (checkoutType === 'pay-as-you-go') {
      if (err) {
        return Toaster.error('Error processing payment, please try again');
      } else if (res) {
        payload.txref = res.txRef ? res.txRef : uuid();
      }
    }

    createPayment(payload, (err) => {
      if (!err) {
        resource.paid = true;
        resource.checkoutType = checkoutType;
        cb(resource);
        Toaster.success('Payment successful');
      } else {
        Toaster.error(err.message || err);
      }
    });
  };

  handleDiscountPayment = (err, res, account_num) => {
    const {
      props: { createPayment, type, resource, cb },
    } = this;
    const payload = {
      data: resource,
      type,
      txref: !!res ? res.txRef : '',
      checkoutType: 'union-bank-discount',
      quantity: resource.slot || 1,
      resource: resource._id,
      account_num,
    };

    createPayment(payload, (err) => {
      if (!err) {
        resource.paid = true;
        resource.checkoutType = 'union-bank-discount';
        cb(resource);
        Toaster.success('Payment successful');
      } else {
        Toaster.error(err.message || err);
      }
    });
  };

  getType = (type) =>
    ({
      document: 'document',
      call: 'consultation',
      ticket: 'ticket',
      plfRequest: 'plfRequest',
    }[type]);

  renderCheckout = (checkoutType) => {
    const {
      showConfirmModal,
      params,
      createCard,
      removeCard,
      createSubscription,
      mySubscription,
      update,
      type,
      price,
      payment_plan,
      disabledPackages,
      state: {
        subscription,
        user: { account },
        payment: { isLoading },
        card: creditCard,
      },
    } = this.props;

    const { card } = this.state;
    if (!account) {
      return <NoItem>You need to create an account to proceed</NoItem>;
    }

    const subscription_ = subscription.item;
    const packages = subscription.packages;
    let package_;
    let currentCount;
    let packageCount;

    if (subscription_ && subscription_.type && subscription_.active) {
      package_ = packages[subscription_.type];

      currentCount = subscription_.isServicePartner
        ? subscription_[this.getType(type)].count
        : subscription_[`${type}Count`];

      packageCount = subscription_.isServicePartner
        ? subscription_[this.getType(type)].maxCount
        : package_[`${type}Count`];
    }

    /**
     * These utility constants are introduced here to help distinguish between
     * the current differences that will exist in structure between servicepartner
     * subscriptions and regular platform user subscriptions
     */

    const usedCount = (
      <h4 className="m-t-4">
        You have used <b>{currentCount || 0} </b>
        {type} slots
      </h4>
    );

    switch (checkoutType) {
      case 'subscription':
        return (
          <div>
            {subscription_ && subscription_.active ? (
              <div>
                {usedCount}
                <p className="text-muted">Hit continue to use one more</p>
                <button
                  type="button"
                  className="pt-button pt-intent-warning"
                  onClick={this.handleSubscriptionCheckout}
                >
                  Continue
                </button>
              </div>
            ) : (
              <div className="m-b-2">
                <Subscription
                  disabledPackages={disabledPackages}
                  update={update}
                  mySubscription={mySubscription}
                  createSubscription={createSubscription}
                  showConfirmModal={showConfirmModal}
                  params={params}
                  subscriptions={subscription}
                  createCard={createCard}
                  removeCard={removeCard}
                  user={account}
                  creditCard={creditCard}
                />
              </div>
            )}
          </div>
        );
      case 'pay-as-you-go':
        return (
          <div className="text-left">
            <Payment
              callback={this.handleMakePayment}
              data={{ type, amount: price, payment_plan }}
              {...this.props}
              showConfirmModal={showConfirmModal}
              user={account}
              callback2={this.handleCouponPayment}
              callback3={this.handleDiscountPayment}
            />
            <div className="m-t-4" />
          </div>
        );
        break;
      default:
        return <NoItem>Invalid checkout method</NoItem>;
    }
  };

  renderCheckoutTypes = (tick, price) => {
    const isUnknownCondition =
      tick === '5bbf11d3c7d6fe0013bd996d' ||
      tick === '5a3171cdeded770014f78f14';

    return (
      <div className="m-t-2">
        {/* TODO: Please refactor to state condition */}
        {isUnknownCondition ? (
          <h4>Checkout</h4>
        ) : (
          <h4>Select a checkout method to proceed</h4>
        )}
        <button
          disabled={!price}
          className="pt-button pt-button-primary m-a-1"
          onClick={() => this.handleStartupPayment()}
        >
          Pay with StartUp Bundles.
        </button>
        <button
          disabled={!price}
          className="pt-button pt-button-primary m-a-1"
          onClick={() => this.setState({ checkoutType: 'pay-as-you-go' })}
        >
          Pay-As-You-Go
        </button>
        {!isUnknownCondition && (
          <button
            className="pt-button pt-button-primary m-a-1"
            onClick={() => this.setState({ checkoutType: 'subscription' })}
          >
            Use Subscription
          </button>
        )}
      </div>
    );
  };

  render() {
    const {
      state: { checkoutType },
      props: {
        resource,
        price,
        tick,
        state: {
          subscription: { item: subscription },
        },
      },
    } = this;
    let owner = JSON.parse(localStorage.getItem('account'));
    const isServicePartner = subscription && subscription.isServicePartner;
    const vlf_category = this.props.resource.vlf_category;
    let show;
    if (
      vlf_category == '5a3171cdeded770014f78f14' ||
      vlf_category == '5bbf11d3c7d6fe0013bd996d' ||
      vlf_category == '5ce81be023eb7a212538ae80' ||
      vlf_category == '5dee3a5c376eaf7e253464e4' ||
      vlf_category == '5ef614fcd5b9f9c0229400b5'
    ) {
      show = true;
    }
    return (
      <div>
        {resource._id &&
          (!resource.paid ? (
            isServicePartner ? (
              <div>
                {show && (
                  <p className="card-title">
                    **Note that Startup Bundles can only be used to pay for TIN
                    Registration, Business Name Registration, Basic Website,
                    Trademark Registration and Company Incorporation
                  </p>
                )}
                <button
                  className="pt-button pt-button-primary m-a-1"
                  onClick={this.handleSubscriptionCheckout}
                >
                  Proceed
                </button>
                {show && (
                  <button
                    disabled={!price}
                    className="pt-button pt-button-primary m-a-1"
                    onClick={() => this.handleStartupPayment()}
                  >
                    Pay with StartUp Bundles.
                  </button>
                )}
              </div>
            ) : checkoutType ? (
              <div>
                {this.renderCheckout(checkoutType)}
                <p className="text-right">
                  <button
                    onClick={() => this.setState({ checkoutType: null })}
                    className="pt-button pt-intent-danger"
                  >
                    Cancel
                  </button>
                </p>
              </div>
            ) : (
              this.renderCheckoutTypes(tick, price)
            )
          ) : (
            <div>Your payment was successful.</div>
          ))}
      </div>
    );
    /* this should be added to the first button - pay-as-you-go */
  }
}
export default connect(
  (state) => ({ state }),
  (dispatch) => ({
    showConfirmModal: (params) => dispatch(showConfirmModal(params)),
    create: (json, callback) => dispatch(create(json, callback)),
    createPayment: (json, callback) => dispatch(createPayment(json, callback)),
    find: (_id, callback) => dispatch(find(_id, callback)),
    update: (_id, json, callback) => dispatch(update(_id, json, callback)),
    createCard: (user_id, payload, callback) =>
      dispatch(createCard(user_id, payload, callback)),
    createSubscription: (user_id, payload, callback) =>
      dispatch(createSubscription(user_id, payload, callback)),
    mySubscription: (user_id, payload, callback) =>
      dispatch(mySubscription(user_id, payload, callback)),
    packages: (callback) => dispatch(packages(callback)),
    removeCard: (user_id, _id, callback) =>
      dispatch(removeCard(user_id, _id, callback)),
    getAllCards: (user_id, payload, callback) =>
      dispatch(getAllCards(user_id, payload, callback)),
  })
)(Checkout);
