import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { create as createFile } from '../../actions/fileActions';

import PageComponent from '../../components/PageComponent';
import {
  showConfirmModal,
  showSigninModal,
  showSignupModal,
} from '../../actions/modalActions';
import { update } from '../../actions/userActions';

import Profile from './Profile';

class Account extends PageComponent {
  getAuthType = () => ['guest', 'lawyer', 'admin', 'servicepartner'];
  componentWillMount() {
    this.setTitle('Account Settings');
    this.tabs = [
      {
        key: 'profile',
        title: 'Profile',
        icon: 'moon-o',
      },
    ];

    const {
      props: {
        state: {
          orders,
          session: { user },
        }
      }
    } = this;
  }

  renderNavLink = (tab, idx) => {
    const {
      params: { tab: tabKey = 'profile' },
    } = this.props;
    return (
      <Link
        key={idx}
        to={`/account/${tab.key}`}
        className={`nav-item ${tabKey === tab.key ? 'active' : ''}`}
      >
        <div>
          <span className="hidden-xs">{tab.title}</span>
        </div>
      </Link>
    );
  };

  renderSection = () => {
    const {
      props: {
        state: {
          user: { account },
          card,
        },
        params: { tab, id },
        showConfirmModal,
        createFile,
        params,
        update,
      },
    } = this;

    switch (tab) {
      case 'profile':
        return (
          <Profile
            createFile={createFile}
            showConfirmModal={showConfirmModal}
            update={update}
            user={account}
          />
        );
      default:
        return <Profile user={account} update={update} />;
    }
  };

  renderPage() {
    return (
      <div className="Account">
        <div className="content-width">
          <div className="row">
            <div className="col_md_25">
              <div className="side-nav">
                <div className="nav-header">My Account</div>
                <div className="nav-content">
                  {this.tabs.map(this.renderNavLink.bind(this))}
                </div>
              </div>

            </div>
            <div className="col_md_5" />
            <div className="col_md_70">
              <div className="page-content">{this.renderSection()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const paths = ['/account', '/account/:tab', '/account/:tab/:id'];

let path;
const store = (store) => ({
  path,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          showConfirmModal: (params) => dispatch(showConfirmModal(params)),
          createFile: (...args) => dispatch(createFile(...args)),
          showSignupModal: (params) => dispatch(showSignupModal(params)),
          showSigninModal: (params) => dispatch(showSigninModal((params = {}))),
          update: (_id, payload, callback) =>
            dispatch(update(_id, payload, callback)),
        })
      )(Account)
    );
  },
});

export default (idx = 0) => {
  path = paths[idx];
  return store;
};
