import { get, post, put, delete_ } from './request';

export function create(json, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'PLF_CREATE'
    });

    json.files = json.files.filter(i => i);
    post(`plf`, { json }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'PLF_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all({ owner: result.json.owner }));
          dispatch({ type: 'PLF_CREATE_OK', plf: result.json });
          if (window.ga) {
            window.ga('send', 'event', 'PLF', 'create');
          }
        }
      }).catch(error => dispatch({ type: 'PLF_CREATE_ERROR', error }));
  };
}

export function createMessage(_id, json, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'PLF_CREATE_MESSAGE'
    });

    json.files = ([] || json.files).filter(i => i);
    post(`plf/${_id}/message`, { json }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'PLF_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all({ owner: result.json.owner }));
          dispatch({ type: 'PLF_CREATE_MESSAGE_OK', plf: result.json });
        }
      }).catch(error => dispatch({ type: 'PLF_CREATE_ERROR', error }));
  };
}

export function update(_id, json, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'PLF_UPDATE'
    });

    put(`plf/${_id}`, { json }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'PLF_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all({ owner: json.owner }));
          dispatch({ type: 'PLF_UPDATE_OK', plf: result.json });
        }
      }).catch(error => dispatch({ type: 'PLF_ERROR', error }));
  };
}

export function remove(_id, json, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'PLF_DELETE'
    });

    delete_(`plf/${_id}`, { json }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'PLF_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all({ owner: json.owner }));
          dispatch({ type: 'PLF_DELETE_OK', plf: result.json });
        }
      }).catch(error => dispatch({ type: 'PLF_ERROR', error }));
  };
}

export function find(_id, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'PLF_GET'
    });

    get(`plf/${_id}`, { json: true }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'PLF_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch({ type: 'PLF_GET_OK', plf: result.json });
        }
      }).catch(error => dispatch({ type: 'PLF_GET_ERROR', error }));
  };
}

export function all(kwargs = {}, callback = () => { }) {
  return (dispatch, getState) => {
    if (!kwargs.useCallback) {
      dispatch({
        type: 'PLFS_GET'
      });
    }

    const q = kwargs.q ? `&q=${kwargs.q}` : '';
    const owner = kwargs.owner ? `&owner=${kwargs.owner}` : '';
    const page = kwargs.page ? `&page=${kwargs.page}` : '';
    const status = kwargs.status ? `&status=${kwargs.status}` : '';
    const limit = `?limit=${kwargs.limit || 10}`;
    get(`plfs${limit}${q}${owner}${page}${status}`, { json: true }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          if (!kwargs.useCallback) {
            dispatch({ type: 'PLF_ERROR', error: result.response.statusText });
          }
        } else {
          callback(null, result.json);
          if (!kwargs.useCallback) {
            dispatch({ type: 'PLFS_OK', data: result.json });
          }
        }
      }).catch(error => dispatch({ type: 'PLFS_ERROR', error }));
  };
}