import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';

class RenderLawyers extends React.Component {
    constructor(props) {
        super(props);
        
    }

    

    render() {
        return (
                  <tr>
                    <td><img src={this.props.lawyer.profile_picture} width={70} height={70} /></td>
                    <td>{this.props.lawyer.fullname}</td>
                    <td>{this.props.lawyer.enrolment_no}</td>
                    <td>{this.props.lawyer.legex_id}</td>
                    <td><button id={this.props.id} className="w3-btn w3-green" onClick={this.props.onExpand}>Expand</button></td>
                    <td>{this.props.lawyer.status == "null"? "Not Verified": "verified"}</td>
                    <td>{this.props.lawyer.status == "null"? <button id={this.props.id} className="w3-btn w3-red" onClick={this.props.onVerify}>Verify</button> : <button id={this.props.id} className="w3-btn w3-red" onClick={this.props.onUnverify}>{this.props.verifyText}</button>}</td>
                  </tr>

        );
    }
}

export default RenderLawyers;