import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import PageComponent from '../../../components/PageComponent';
import Toaster from '../../../components/widgets/Toaster';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { countries, userTypes, lawyerTypes } from '../../../data';
import factory from '../../../props/factory';
import { get, post, put, delete_ } from '../../../actions/request';
const NEW = 'new';

export default class User extends PageComponent {
  getAuthType = () => ['admin'];
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      user: {
        active: true,
      },
      brands: {
        items: [],
      },
      isLoading: false,
      packages: [],
      type: '',
      format: '',
      data: {
        name: '',
        duration: '',
        ticket: {
          allowed: [],
          count: 0,
          maxCount: 0,
        },
        consultation: {
          count: 0,
          maxCount: 0,
        },
        document: {
          count: 0,
          maxCount: 0,
        },
      },
    };
  }

  handleTextChange = (e) => {
    let { data } = this.state;
    const { name, value } = e.target || e.srcElement;
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
    });
  };

  handleObjectChange = (value, target, key) => {
    let { data } = this.state;
    let allowed = data[target];
    this.setState({
      data: {
        ...data,
        [target]: {
          ...allowed,
          [key]: value,
        },
      },
    });
  };

  addTicket = (e) => {
    e.preventDefault();
    let { data } = this.state;
    let ticketData = data.ticket;
    let ticketAllowed = ticketData.allowed;
    let add = { id: '', slug: '', maxCount: '' };
    this.setState({
      data: {
        ...data,
        ticket: {
          ...ticketData,
          allowed: [...ticketAllowed, { ...add }],
        },
      },
    });
  };

  handleAllowedChange = (value, target, key, index) => {
    let { data } = this.state;
    let allowed = data[target].allowed;
    let initial = data[target];
    allowed[index][key] = value;
    this.setState({
      data: {
        ...data,
        [target]: {
          ...initial,
          allowed: [...allowed],
        },
      },
    });
  };

  componentDidMount() {
    this.getUser();
    this.getPackages();
  }

  isLoading = (isLoading = true) => {
    this.setState({ isLoading });
  };

  getPackages() {
    get(`subscription-package`, { json: true }).then((result) => {
      this.setState({ packages: Object.values(result.json) }, () => {});
    });
  }

  createSubscription(e) {
    e.preventDefault();
    const { type, data, format } = this.state;
    let temp = {
      type,
      _id: this.props.params.id,
      ...data,
      format,
    };
    post(`admin/subscription`, { json: temp })
      .then((result) => {
        Toaster.success('Subscription Created Successfully');
        this.getUser();
        this.setState({ modal: false }, () => {});
      })
      .catch((error) => {
        Toaster.error('An error occured, Please fill all fields');
      });
  }

  deleteSubscription(e) {
    e.preventDefault();
    let data = {
      _id: this.props.params.id,
    };
    delete_(`admin/subscription`, { json: data })
      .then((result) => {
        Toaster.success('Subscription deleted Successfully');
        this.getUser();
      })
      .catch((error) => {
        Toaster.error('An error occured');
      });
  }

  popModal(e) {
    e.preventDefault();
    this.setState({ modal: true }, () => {});
  }

  isNew = () => {
    return this.props.params.id === NEW && !this.state.user._id;
  };

  getUser = () => {
    if (this.isNew()) {
      return;
    }

    this.isLoading();
    factory.user.get({}, `/${this.props.params.id}`).then(
      (user) => {
        this.setState({ user });
        this.isLoading(false);
      },
      (error) => {
        this.isLoading(false);
        this.setState({ user: null });
      }
    );
  };

  saveUser = () => {
    this.isLoading();
    const method = this.isNew() ? 'post' : 'put';
    const { user } = this.state;
    const { password } = this.refs;

    factory.user[method](
      this.state.user,
      this.isNew() ? '' : `/${user._id}`
    ).then(
      (user) => {
        if (user._id) {
          Toaster.success('User updated successfully');
          this.setState({ user });
          password.value = '';
        } else {
          Toaster.error(`Error saving User — ${user.message}`);
        }
        this.isLoading(false);
      },
      (err) => {
        this.isLoading(false);
        Toaster.error(`Error saving user`);
      }
    );
  };

  getBrands = () => {
    factory.brand.get({}, `s`).then(
      (brands) => {
        if (brands && brands.items) {
          this.setState({ brands });
        }
      },
      (error) => {
        this.isLoading(false);
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.saveUser();
  };

  handleTabChange = () => {};
  handleChange = (e) => {
    const { name, value, checked } = e.target || e.srcElement;
    let { user } = this.state;
    user[name] = value;
    if (name === 'active') {
      user[name] = checked;
    }

    this.setState({ user });
  };

  renderBreadCrumbs = (item, id) => {
    const name = (item && item.name) || id;
    return (
      <div>
        <ul className="pt-breadcrumbs">
          <li>
            <Link className="pt-breadcrumbs-collapsed" to="/admin"></Link>
          </li>
          <li>
            <Link className="pt-breadcrumb" to="/admin">
              Dashboard
            </Link>
          </li>
          <li>
            <Link className="pt-breadcrumb" to="/admin/users">
              Users
            </Link>
          </li>
          <li>
            <span className="pt-breadcrumb pt-breadcrumb-current">
              {name[0].toUpperCase()}
              {name.substr(1)}
            </span>
          </li>
        </ul>
        <br />
        <br />
      </div>
    );
  };

  renderForm = (user) => {
    const { isLoading, brands } = this.state;
    const {
      user: { account },
    } = this.props.state;
    return (
      <div className="cv-stream-tab">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <label className="pt-label col_25">
              <label className="pt-control pt-switch pt-large">
                <input
                  type="checkbox"
                  name="active"
                  onChange={this.handleChange}
                  defaultChecked={user.active}
                />
                <span className="pt-control-indicator"></span>
                Active
              </label>
            </label>
          </div>
          <div className="row">
            <label className="pt-label col_100">
              Name
              <br />
              <input
                type="text"
                onChange={this.handleChange}
                className="pt-input pt-fill"
                name="name"
                placeholder="Full Name"
                defaultValue={user.name}
                required
              />
            </label>
          </div>

          <div className="row">
            <label className="pt-label col_25">
              Country
              <br />
              <div className="pt-select">
                <div className="pt-select">
                  <select
                    name="country"
                    onChange={this.handleChange}
                    defaultValue={user.country}
                    required
                  >
                    {countries.map(({ name, code }) => (
                      <option key={code} value={code} label={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </label>
            <div className="col_10"></div>
            <label className="pt-label col_25">
              Type
              <br />
              <div className="pt-select">
                <div className="pt-select">
                  <select
                    disabled={account._id === user._id}
                    name="type"
                    onChange={this.handleChange}
                    defaultValue={user.type}
                    required
                  >
                    {userTypes.map((type, idx) => (
                      <option key={idx} label={type.ucFirst()} value={type}>
                        {type.ucFirst()}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </label>
            <div className="col_10"></div>
            {user.type === 'lawyer' && (
              <label className="pt-label col_25">
                Lawyer Type
                <br />
                <div className="pt-select">
                  <div className="pt-select">
                    <select
                      name="lawyer"
                      onChange={this.handleChange}
                      defaultValue={user.lawyer}
                      required
                    >
                      <option label="Select One:"></option>
                      {lawyerTypes.map((type, idx) => (
                        <option key={idx} label={type.ucFirst()} value={type}>
                          {type.ucFirst()}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </label>
            )}
          </div>
          <div className="row">
            <label className="pt-label col_100">
              Email Address
              <br />
              <input
                type="email"
                onChange={this.handleChange}
                className="pt-input pt-fill"
                name="email"
                placeholder="Email Address"
                defaultValue={user.email}
              />
            </label>
          </div>

          <div className="row">
            <label className="pt-label col_100">
              Password
              <br />
              <input
                ref="password"
                type="password"
                defaultValue={user.password}
                name="password"
                onChange={this.handleChange}
                className="pt-input pt-fill external"
              />
            </label>
          </div>

          <div className="row">
            <label className="pt-label col_100">
              Phone
              <br />
              <input
                type="tel"
                defaultValue={user.phone}
                name="phone"
                onChange={this.handleChange}
                className="pt-input pt-fill external"
              />
            </label>
          </div>

          <div>
            <br />
            <button
              type="submit"
              disabled={isLoading}
              className="pt-button pt-intent-primary"
            >
              <span>Save</span>
            </button>
            <span>&nbsp;</span>
            <Link to={isLoading ? null : '/admin/users'}>
              <button type="button" className="pt-button" disabled={isLoading}>
                <span>Cancel</span>
              </button>
            </Link>
          </div>
        </form>
        {this.renderSubscription(user)}
      </div>
    );
  };

  renderSubscription = (user) => {
    return (
      <div className="cv-stream-tab">
        {user && user.subscription && (
          <div>
            <h4 className="section-header">Subscription Information</h4>
            <h3 className="text-color-primary">{user.subscription.name}</h3>
            <p>
              Expiry Date:{' '}
              <b>{moment(user.subscription.end).format('LL LT')}</b>
            </p>
            {user.subscription.document && (
              <div>
                <p className="">
                  {user.subscription.document.count} of{' '}
                  {user.subscription.document.maxCount} Documents used
                </p>
                <p className="">
                  {user.subscription.consultation.count} of{' '}
                  {user.subscription.consultation.maxCount} Consultations used
                </p>
                <p className="">
                  {user.subscription.ticket.count} of{' '}
                  {user.subscription.ticket.maxCount} Tickets used
                </p>
              </div>
            )}

            {!user.subscription.document && (
              <div>
                <p className="">
                  {user.subscription.documentCount} Documents left
                </p>
                <p className="">
                  {user.subscription.callCount} Consultations left
                </p>
                <p className="">{user.subscription.ticketCount} Tickets left</p>
              </div>
            )}
            <button
              type="submit"
              onClick={(e) => this.deleteSubscription(e)}
              className="pt-button pt-intent-primary"
            >
              <i className /> Delete Subscription
            </button>
          </div>
        )}
        {user && !user.subscription && (
          <div>
            <p className="">User has no subscription</p>
            <button
              type="submit"
              onClick={(e) => this.popModal(e)}
              className="pt-button pt-intent-primary"
            >
              <i className /> Assign Subscription
            </button>
          </div>
        )}
      </div>
    );
  };

  renderPage() {
    const {
      state: {
        document: {
          template: { items: templates, isLoading },
        },
      },
      params: { id },
    } = this.props;

    let tickets = this.props.state.vlfCategory.items;
    const { data, user, packages, format } = this.state;
    return (
      <section className="content-width UserComponent">
        <Modal isOpen={this.state.modal} toggle={this.toggle} centered={true}>
          <ModalHeader toggle={this.toggle}>Assign Subscription</ModalHeader>
          <ModalBody>
            <form className="form">
              <div className="row">
                <div className="col-12">
                  <label className="pt-label">Select Subscription Type</label>
                  <p className="pt-select pt-fill">
                    <select
                      placeholder="Select Type"
                      onChange={(event) =>
                        this.setState({ format: event.target.value })
                      }
                      className="pt-fill"
                      name="country"
                      required
                    >
                      <option>Select an option</option>
                      <option value="current">Existing Package</option>
                      <option value="new">Custom Package</option>
                    </select>
                  </p>
                </div>
                {format == 'new' && (
                  <main className="col-12 row">
                    <div className="col-md-6">
                      <p>
                        <label className="pt-label">
                          Enter Name of Subscription
                        </label>
                        <input
                          placeholder="e.g 12"
                          onChange={this.handleTextChange}
                          type="text"
                          className="pt-input pt-fill"
                          name="name"
                          value={data.name}
                          required
                        />
                      </p>
                    </div>

                    <div className="col-md-6">
                      <p>
                        <label className="pt-label">
                          Enter Duration of Subscription (in months)
                        </label>
                        <input
                          placeholder="e.g 12"
                          onChange={this.handleTextChange}
                          type="text"
                          className="pt-input pt-fill"
                          name="duration"
                          value={data.duration}
                          required
                        />
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p>
                        <label className="pt-label">
                          Enter Number of Documents allowed
                        </label>
                        <input
                          placeholder="e.g 12"
                          onChange={(event) =>
                            this.handleObjectChange(
                              event.target.value,
                              'document',
                              'maxCount'
                            )
                          }
                          type="number"
                          className="pt-input pt-fill"
                          value={data.document.maxCount}
                          required
                        />
                      </p>
                    </div>

                    <div className="col-md-6">
                      <p>
                        <label className="pt-label">
                          Enter Number of Calls allowed
                        </label>
                        <input
                          placeholder="e.g 3"
                          onChange={(event) =>
                            this.handleObjectChange(
                              event.target.value,
                              'consultation',
                              'maxCount'
                            )
                          }
                          type="number"
                          value={data.consultation.maxCount}
                          className="pt-input pt-fill"
                          required
                        />
                      </p>
                    </div>

                    <div className="col-md-6">
                      <p>
                        <label className="pt-label">
                          Enter Number of Tickets allowed
                        </label>
                        <input
                          placeholder="e.g 5"
                          onChange={(event) =>
                            this.handleObjectChange(
                              event.target.value,
                              'ticket',
                              'maxCount'
                            )
                          }
                          type="number"
                          value={data.ticket.maxCount}
                          className="pt-input pt-fill"
                          required
                        />
                      </p>
                    </div>

                    <div className="col-md-6">
                      <p>
                        <label className="pt-label">
                          Add Allowed Tickets (Optional)
                        </label>
                        <button
                          type="submit"
                          onClick={(e) => this.addTicket(e)}
                          className="pt-button pt-intent-primary"
                        >
                          <i className /> Add Allowed Ticket
                        </button>
                      </p>
                    </div>
                    {data.ticket.allowed.map((item, idx) => {
                      return (
                        <main className="col-12 row">
                          <div className="col-md-6">
                            <label className="pt-label">Select Ticket</label>
                            <p className="pt-select pt-fill">
                              <select
                                placeholder="Select Type"
                                value={data.ticket.allowed[idx].id}
                                onChange={(event) =>
                                  this.handleAllowedChange(
                                    event.target.value,
                                    'ticket',
                                    'id',
                                    idx
                                  )
                                }
                                className="pt-fill"
                                required
                              >
                                <option value="">Select Ticket</option>
                                {tickets.map((ticket) => (
                                  <option key={ticket._id} value={ticket._id}>
                                    {ticket.name}
                                  </option>
                                ))}
                              </select>
                            </p>
                          </div>

                          <div className="col-md-6">
                            <p>
                              <label className="pt-label">
                                Enter Maximum allowed
                              </label>
                              <input
                                placeholder="e.g 8"
                                onChange={(event) =>
                                  this.handleAllowedChange(
                                    event.target.value,
                                    'ticket',
                                    'maxCount',
                                    idx
                                  )
                                }
                                type="number"
                                value={data.ticket.allowed[idx].maxCount}
                                className="pt-input pt-fill"
                                name="duration"
                                required
                              />
                            </p>
                          </div>
                        </main>
                      );
                    })}
                  </main>
                )}
                {format == 'current' && (
                  <div className="col-12">
                    <label className="pt-label">Select Subscription Type</label>
                    <p className="pt-select pt-fill">
                      <select
                        placeholder="Select Type"
                        onChange={(event) =>
                          this.setState({ type: event.target.value })
                        }
                        className="pt-fill"
                        name="country"
                        required
                      >
                        <option>Select an option</option>
                        {packages.map((package_) => (
                          <option key={package_._id} value={package_.slug}>
                            {package_.name}
                          </option>
                        ))}
                      </select>
                    </p>
                  </div>
                )}
              </div>
              <hr />
              <button
                type="submit"
                onClick={(e) => this.createSubscription(e)}
                className="pt-button pt-intent-primary"
              >
                <i className /> Create Subscription
              </button>
            </form>
          </ModalBody>
        </Modal>
        {this.renderBreadCrumbs(user, id)}
        {this.isNew() && (
          <div>
            <h3>Create User</h3>
            <hr />
            <br />
          </div>
        )}
        {user && (user._id || this.isNew()) ? (
          this.renderForm(user)
        ) : user ? (
          <h4 className="pad_4 text-center">Loading...</h4>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <h1 className="pt-icon-error" style={{ fontSize: '10em' }}></h1>
            <h2>User not found</h2>
            <hr />
            <h4>
              <code>{id}</code> doesn't exist.
            </h4>
          </div>
        )}
      </section>
    );
  }
}
