import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router';
import {
  showConfirmModal,
  showCallModal,
  closeModal,
} from '../../actions/modalActions';
import {
  Menu,
  MenuItem,
  Popover,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import { all, remove } from '../../actions/callActions';
import Spinner from '../../components/widgets/Spinner';
import Icon from '../../components/widgets/Icon';
import Toaster from '../../components/widgets/Toaster';
import Heading from '../../components/widgets/Heading';
import NoItem from '../../components/widgets/NoItem';
import PageComponent from '../../components/PageComponent';

class Calls extends PageComponent {
  getAuthType = () => ['guest', 'admin', 'servicepartner'];
  componentWillMount() {
    this.setTitle('Calls');
    this.state = {
      page: 1,
      change: false,
    };

    this.limit = 10;
    this.getCalls(1);
    const { token } = this.props.location.query;
    console.log(token);
    const { closeModal, remove, showCallModal, showConfirmModal } = this.props;
    this.menu = [
      {
        name: 'Edit',
        onClick: (call) => {
          showCallModal({ call });
        },
        icon: 'edit',
      },
      {
        name: 'Cancel',
        onClick: (call) => {
          showConfirmModal({
            title: 'Cancel Call',
            message: 'Are you sure you want to Cancel this call?',
            buttons: [
              {
                label: 'Delete',
                intent: 'danger',
                action() {
                  remove(call._id, call, (err, res) => {
                    if (!err) {
                      closeModal();
                    } else {
                      Toaster.error(err.message || err);
                    }
                  });
                },
              },
            ],
          });
        },
        icon: 'trash',
      },
    ];
  }

  getCalls = (page) => {
    const {
      props: {
        all,
        state: {
          session: { user: owner },
        },
      },
      limit,
      q,
    } = this;
    all({ q, page, limit, owner }, (err, res) => {
      if (!err) {
        this.setState({ page });
      } else {
        console.log(err);
        Toaster.error(err.message || err);
      }
    });
  };

  renderPaginator = (count) => {
    if (!count) {
      return null;
    }

    const { page } = this.state;
    const {
      call: { isLoading },
    } = this.props.state;
    const total = Math.ceil(count / this.limit);
    let pages = [];
    for (let i = 0; i < total; i++) {
      pages.push(
        <div
          key={i}
          onClick={this.getCalls.bind(this, i + 1)}
          className={`page ${isLoading ? 'loading' : ''} ${
            page === i + 1 ? 'selected' : ''
          }`}
        >
          {i + 1}
        </div>
      );
    }

    return pages;
  };

  renderCalls = (call, idx) => {
    const { showCallModal } = this.props;
    return (
      <div className="element" key={idx}>
        <a onClick={() => showCallModal({ call })}>
          <div className="element-preview">
            <Icon name="phone" />
          </div>
        </a>

        <div className="call-summary">
          <div className="slim">{call.notes}</div>
          <b>{moment(call.start).format('MMM Do YYYY, hh:mm A')}</b>
          <div className="element-footer">
            <small className="text-muted pull-left">
              {moment(call.stamp).fromNow()}
            </small>
            {!call.paid && (
              <span className="text-muted pull-left">
                <Popover
                  interactionKind={PopoverInteractionKind.HOVER}
                  content={
                    <div className="p-a-2" style={{ minWidth: 200 }}>
                      <small>
                        Your call will not be booked until payment is made
                      </small>
                    </div>
                  }
                  position={Position.BOTTOM}
                  autoFocus={false}
                  inline
                >
                  <Icon className="text-color-danger" name="warning" />
                </Popover>
              </span>
            )}
            <span className="text-muted pull-right">
              <Popover
                content={
                  <div>
                    {this.menu.map((menu, idx) => {
                      if (
                        Date.now() >
                          new Date(call.start).getTime() - 1000 * 24 * 3600 &&
                        menu.name === 'Cancel'
                      ) {
                        return null;
                      }

                      return (
                        <Menu key={idx}>
                          <MenuItem
                            text={menu.name}
                            onClick={menu.onClick.bind(this, call)}
                            iconName={menu.icon}
                          />
                        </Menu>
                      );
                    })}
                  </div>
                }
                position={Position.BOTTOM}
                autoFocus={false}
                inline={true}
              >
                <Icon className="pointer" name="keyboard_arrow_down" />
              </Popover>
            </span>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    );
  };

  renderBreadCrumbs = () => {
    return (
      <div>
        <ul className="pt-breadcrumbs">
          <li>
            <Link className="pt-breadcrumbs-collapsed" to="/"></Link>
          </li>
          <li>
            <Link className="pt-breadcrumb pt-breadcrumb-current" to="/calls">
              Calls
            </Link>
          </li>
        </ul>
        <br />
        <br />
      </div>
    );
  };

  renderPage() {
    const {
      showCallModal,
      state: {
        call: { items, count, isLoading },
      },
    } = this.props;

    if (isLoading) {
      return <Spinner className="m-t-4 text-center" />;
    }

    return (
      <div className="Calls">
        <div className="content-width">
          {this.renderBreadCrumbs()}
          <button
            className="pull-right pt-button pt-intent-accent"
            onClick={showCallModal.bind(this, {})}
          >
            Create Appointment
          </button>
          <div className="clearfix" />
          <Heading title="My Calls" />
          <div className="service-information-col">
            <p>
              Book a call or chat slot with a lawyer today! Each slot is 30
              minutes. You can attach any document before consultation. After
              every consultation, a brief opinion or advice will be shared with
              you within 24 hours.
            </p>
          </div>
          <div className="calls">
            {count ? (
              <div className="pt-card pt-elevation-3">
                <div>{items.map(this.renderCalls)}</div>
                <div className="clearfix" />
                <div className="paginator">{this.renderPaginator(count)}</div>
              </div>
            ) : (
              <div>
                <NoItem>You haven't booked any calls yet</NoItem>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default (store) => ({
  path: `/calls`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          showConfirmModal: (params) => dispatch(showConfirmModal(params)),
          showCallModal: (params) => dispatch(showCallModal(params)),
          closeModal: (params) => dispatch(closeModal(params)),
          all: (kwargs, callback) => dispatch(all(kwargs, callback)),
          remove: (_id, json, callback) =>
            dispatch(remove(_id, json, callback)),
        })
      )(Calls)
    );
  },
});
