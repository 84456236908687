const key = 'vlfCategories';

import { connect } from 'react-redux';
import React from 'react';
import PageComponent from '../../../components/PageComponent';
import Props from '../../../components/widgets/etable/props';
import { getTemplates } from '../../../actions/documentActions';
import Toaster from '../../../components/widgets/Toaster';
import { get, post, put, delete_ } from '../../../actions/request';
import { Link, browserHistory } from 'react-router';

class SubscriptionPackagesEdit extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      partners: [],
      data: {
        ticket: {
          allowed: [],
          maxCount: 0,
          count: 0,
        },
        consultation: {
          maxCount: 0,
          count: 0,
        },
        document: {
          maxCount: 0,
          count: 0,
        },
      },
    };
  }

  handleChange = (e) => {
    let { data } = this.state;
    const { name, value } = e.target || e.srcElement;
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
    });
  };

  handleObjectChange = (value, target, key) => {
    let { data } = this.state;
    let allowed = data[target];
    this.setState({
      data: {
        ...data,
        [target]: {
          ...allowed,
          [key]: value,
        },
      },
    });
  };

  addTicket = (e) => {
    e.preventDefault();
    let { data } = this.state;
    let ticketData = data.ticket;
    let ticketAllowed = ticketData.allowed;
    let add = { id: '', slug: '', maxCount: '', name: '', count: 0 };
    this.setState({
      data: {
        ...data,
        ticket: {
          ...ticketData,
          allowed: [...ticketAllowed, { ...add }],
        },
      },
    });
  };

  handleAllowedChange = (value, target, key, index, event) => {
    let { data } = this.state;
    let allowed = data[target].allowed;
    let initial = data[target];
    if (key === 'id') {
      let tickets = this.props.state.vlfCategory.items;
      let output = tickets.find((ticket) => {
        return ticket._id === value;
      });
      allowed[index].id = output._id;
      allowed[index].slug = output.slug;
      allowed[index].name = output.name;
    } else {
      allowed[index][key] = value;
    }

    this.setState({
      data: {
        ...data,
        [target]: {
          ...initial,
          allowed: [...allowed],
        },
      },
    });
  };

  componentWillMount() {
    this.getPackage();
    this.getPartners();
  }

  getPackage() {
    const _id = this.props.params.id;
    get(`subscription-package/${_id}`, { json: true }).then((result) => {
      this.setState({ data: result.json[0] });
    });
  }

  getPartners() {
    get(`service-partners`, { json: true }).then((result) => {
      this.setState({ partners: result.json }, () => {});
    });
  }

  updatePackage(e) {
    e.preventDefault();
    const _id = this.props.params.id;
    let { data } = this.state;
    put(`subscription-package/${_id}`, { json: data })
      .then((result) => {
        Toaster.success('Subscription Created Successfully');
        return browserHistory.push(`/admin/subscriptions`);
      })
      .catch((error) => {
        Toaster.error('An error occured, Please fill all fields');
      });
  }

  getAuthType = () => ['admin'];
  static propTypes = Props;
  renderPage() {
    const {
      state: {
        document: {
          template: { items: templates, isLoading },
        },
      },
      params: { id },
    } = this.props;
    let tickets = this.props.state.vlfCategory.items;
    let { data, partners } = this.state;

    return (
      <section className="pad_2 content-width">
        <div className="d-flex justify-content-between">
          <h2 className="m-b-2">Edit Subscription Packages</h2>
        </div>
        <div className="mx-4">
          <form onSubmit={this.onSubmit} className="form">
            <div className="row">
              <div className="col-md-6">
                <p>
                  <label className="pt-label">Enter Name of Subscription</label>
                  <input
                    placeholder="Name"
                    onChange={this.handleChange}
                    type="text"
                    className="pt-input pt-fill"
                    name="name"
                    value={data.name}
                    required
                  />
                </p>
              </div>

              <div className="col-md-6">
                <p>
                  <label className="pt-label">
                    Enter Price of Subscription
                  </label>
                  <input
                    placeholder="e.g 5000"
                    onChange={this.handleChange}
                    type="number"
                    className="pt-input pt-fill"
                    name="price"
                    value={data.price}
                    required
                  />
                </p>
              </div>

              <div className="col-md-6">
                <p>
                  <label className="pt-label">
                    Enter Duration of Subscription (in months)
                  </label>
                  <input
                    placeholder="e.g 12"
                    onChange={this.handleChange}
                    type="number"
                    className="pt-input pt-fill"
                    name="duration"
                    value={data.duration}
                    required
                  />
                </p>
              </div>

              <div className="col-md-6">
                <label className="pt-label">Select Subscription type</label>
                <p className="pt-select pt-fill">
                  <select
                    placeholder="Select Type"
                    defaultValue={data.type}
                    onChange={this.handleChange}
                    className="pt-fill"
                    name="type"
                    required
                  >
                    <option value="">Select Subscription Type</option>
                    <option value="individual">Individual</option>
                    <option value="corporate">Corporate</option>
                  </select>
                </p>
              </div>

              <div className="col-md-6">
                <label className="pt-label">
                  Who can access this subscription?
                </label>
                <p className="pt-select pt-fill">
                  <select
                    placeholder="Select Type"
                    defaultValue={data.isServicePartner}
                    onChange={this.handleChange}
                    className="pt-fill"
                    name="isServicePartner"
                    required
                  >
                    <option value="">Select an option</option>
                    <option value={false}>General</option>
                    <option value={true}>Service Partner</option>
                  </select>
                </p>
              </div>

              {data.isServicePartner == 'true' && (
                <div className="col-md-6">
                  <label className="pt-label">Enter Service Partner</label>
                  <p className="pt-select pt-fill">
                    <select
                      placeholder="Select Partner"
                      defaultValue={data.partner}
                      onChange={this.handleChange}
                      className="pt-fill"
                      name="partner"
                      required
                    >
                      <option value="">Select Partner</option>
                      {partners.map((partner) => (
                        <option key={partner._id} value={partner.slug}>
                          {partner.name}
                        </option>
                      ))}
                    </select>
                  </p>
                </div>
              )}

              <div className="col-12">
                <p>Subscription Details</p>
                <hr />
              </div>

              <div className="col-md-6">
                <p>
                  <label className="pt-label">
                    Enter Number of Documents allowed
                  </label>
                  <input
                    placeholder="e.g 12"
                    onChange={(event) =>
                      this.handleObjectChange(
                        event.target.value,
                        'document',
                        'maxCount'
                      )
                    }
                    type="number"
                    className="pt-input pt-fill"
                    value={data.document.maxCount}
                    required
                  />
                </p>
              </div>

              <div className="col-md-6">
                <p>
                  <label className="pt-label">
                    Enter Number of Calls allowed
                  </label>
                  <input
                    placeholder="e.g 3"
                    onChange={(event) =>
                      this.handleObjectChange(
                        event.target.value,
                        'consultation',
                        'maxCount'
                      )
                    }
                    type="number"
                    value={data.consultation.maxCount}
                    className="pt-input pt-fill"
                    required
                  />
                </p>
              </div>

              <div className="col-md-6">
                <p>
                  <label className="pt-label">
                    Enter Number of Tickets allowed
                  </label>
                  <input
                    placeholder="e.g 5"
                    onChange={(event) =>
                      this.handleObjectChange(
                        event.target.value,
                        'ticket',
                        'maxCount'
                      )
                    }
                    type="number"
                    value={data.ticket.maxCount}
                    className="pt-input pt-fill"
                    required
                  />
                </p>
              </div>

              <div className="col-md-6">
                <p>
                  <label className="pt-label">
                    Add Allowed Tickets (Optional)
                  </label>
                  <button
                    type="submit"
                    onClick={(e) => this.addTicket(e)}
                    className="pt-button pt-intent-primary"
                  >
                    <i className /> Add Allowed Ticket
                  </button>
                </p>
              </div>
              {data.ticket.allowed &&
                data.ticket.allowed.map((item, idx) => {
                  return (
                    <main className="col-12 row">
                      <div className="col-md-6">
                        <label className="pt-label">Select Ticket</label>
                        <p className="pt-select pt-fill">
                          <select
                            placeholder="Select Type"
                            value={data.ticket.allowed[idx].id}
                            onChange={(event) =>
                              this.handleAllowedChange(
                                event.target.value,
                                'ticket',
                                'id',
                                idx
                              )
                            }
                            className="pt-fill"
                            required
                          >
                            <option value="">Select Ticket</option>
                            {tickets.map((ticket) => (
                              <option key={ticket._id} value={ticket._id}>
                                {ticket.name}
                              </option>
                            ))}
                          </select>
                        </p>
                      </div>

                      <div className="col-md-6">
                        <p>
                          <label className="pt-label">
                            Enter Maximum allowed
                          </label>
                          <input
                            placeholder="e.g 8"
                            onChange={(event) =>
                              this.handleAllowedChange(
                                event.target.value,
                                'ticket',
                                'maxCount',
                                idx
                              )
                            }
                            type="number"
                            value={data.ticket.allowed[idx].maxCount}
                            className="pt-input pt-fill"
                            name="duration"
                            required
                          />
                        </p>
                      </div>
                    </main>
                  );
                })}
            </div>
            <hr />
            <button
              type="submit"
              onClick={(e) => this.updatePackage(e)}
              className="pt-button pt-intent-primary"
            >
              <i className /> Update
            </button>
          </form>
        </div>
      </section>
    );
  }
}

export default (store) => ({
  path: `/admin/subscriptions/edit/:id`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          getTemplates: (kwargs, callback) =>
            dispatch(getTemplates(kwargs, callback)),
        })
      )(SubscriptionPackagesEdit)
    );
  },
});
