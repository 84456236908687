import React from 'react'
import { connect } from 'react-redux'
import Spinner from '../../components/widgets/Spinner'
import Toaster from '../../components/widgets/Toaster'
import { Link, browserHistory } from 'react-router'
import Select from 'react-select'
import Modal from 'react-modal'
import axios from 'axios'

class LeruLogin extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      e_addr: '',
      password: '',
      user: true,
      notLoading: true
    }
  }

  componentWillMount () {
    if (localStorage.getItem('UID-SESS')) {
      browserHistory.push('/leru/user_profile')
    } else if (localStorage.getItem('LAD-SESS')) {
      browserHistory.push('/leru/lawyer_profile')
    }
    if (localStorage.getItem('em_law_reset')) {
      localStorage.removeItem('em_law_reset')
    }
    if (localStorage.getItem('fname')) {
      localStorage.removeItem('fname')
    }
    if (localStorage.getItem('surn')) {
      localStorage.removeItem('surn')
    }
    if (localStorage.getItem('password')) {
      localStorage.removeItem('password')
    }
    if (localStorage.getItem('e_addr')) {
      localStorage.removeItem('e_addr')
    }
    if (localStorage.getItem('location')) {
      localStorage.removeItem('location')
    }
    if (localStorage.getItem('d_o_b')) {
      localStorage.removeItem('d_o_b')
    }
    if (localStorage.getItem('p_number')) {
      localStorage.removeItem('p_number')
    }
    if (localStorage.getItem('e_1_a')) {
      localStorage.removeItem('e_1_a')
    }
    if (localStorage.getItem('e_1_b')) {
      localStorage.removeItem('e_1_b')
    }
    if (localStorage.getItem('e_1_c')) {
      localStorage.removeItem('e_1_c')
    }
    if (localStorage.getItem('e_2_a')) {
      localStorage.removeItem('e_2_a')
    }
    if (localStorage.getItem('e_2_b')) {
      localStorage.removeItem('e_2_b')
    }
    if (localStorage.getItem('e_2_c')) {
      localStorage.removeItem('e_2_c')
    }
    if (localStorage.getItem('s_1_a')) {
      localStorage.removeItem('s_1_a')
    }
    if (localStorage.getItem('s_1_b')) {
      localStorage.removeItem('s_1_b')
    }
    if (localStorage.getItem('s_1_c')) {
      localStorage.removeItem('s_1_c')
    }
    if (localStorage.getItem('s_2_a')) {
      localStorage.removeItem('s_2_a')
    }
    if (localStorage.getItem('s_2_b')) {
      localStorage.removeItem('s_2_b')
    }
    if (localStorage.getItem('s_2_c')) {
      localStorage.removeItem('s_2_c')
    }
    if (localStorage.getItem('s_3_a')) {
      localStorage.removeItem('s_3_a')
    }
    if (localStorage.getItem('s_3_b')) {
      localStorage.removeItem('s_3_b')
    }
    if (localStorage.getItem('s_3_c')) {
      localStorage.removeItem('s_3_c')
    }
  }

  userLogin = (res) => {
    localStorage.setItem('UID-SESS', `${res.data.id}`)
    localStorage.setItem('Laccount', JSON.stringify(res.data))
    setTimeout(() => { browserHistory.push('/leru/user_profile') }, 1000)
  }
  lawyerLogin = (res) => {
    localStorage.setItem('LAD-SESS', `${res.data.id}`)
    localStorage.setItem('Laccount', JSON.stringify(res.data))
    // const back = this.Base64.toInt(localStorage.getItem("LAD-SESS"));
    // console.log(back);
    setTimeout(() => { browserHistory.push('/leru/lawyer_profile') }, 1000)
  }

  handleChange = (e) => {
    const { name, value, checked } = e.target || e.srcElement

    switch (name) {
      case 'e_addr':
        this.setState({ e_addr: value })
        break
      case 'password':
        this.setState({ password: value })
        break
      default:
        break
    }
  }
  showUser = (e) => {
    this.setState({ user: true })
  }
  showLawyer = (e) => {
    this.setState({ user: false })
  }
  handleUserSubmit = (e) => {
    e.preventDefault()
    window.scrollTo(0, 0)
    const { password, e_addr } = this.state
    this.setState({ notLoading: false })

    if (password == '' || password == ' ') {
        return false
      }
    if (e_addr == '' || e_addr == ' ') {
      return false
    }

    const data = {
      password: password,
      email_addr : e_addr
    }
    const that = this
    axios({
      url: 'https://cors-anywhere.herokuapp.com/https://immense-tor-56766.herokuapp.com/api/user/login.php',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data
    }).then(function (res) {
      console.log('Here 123')
      console.log(res)
      console.log(res.status)
      that.setState({ notLoading: true })
      if ((res.status == 200) || (res.status == 201)) {
        console.log(res.status)
        Toaster.success('Login successful')
        that.userLogin(res)
      } else {
        console.log(res)
        Toaster.error('Login Failed, invalid parameters')
        that.setState({ notLoading: true })
      }
    }).catch(function (err) {
      console.log(err)
      Toaster.error('Network Error or invalid Login')
      that.setState({ notLoading: true })
    })
  }
  handleLawyerSubmit = (e) => {
    e.preventDefault()
    window.scrollTo(0, 0)
    const { password, e_addr } = this.state
    this.setState({ notLoading: false })

    if (password == '' || password == ' ') {
      return false
    }
    if (e_addr == '' || e_addr == ' ') {
      return false
    }

    const data = {
      password: password,
      email_addr : e_addr
    }
    const that = this
    axios({
    url: 'https://cors-anywhere.herokuapp.com/https://immense-tor-56766.herokuapp.com/api/lawyer/login.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  }).then(function (res) {
    console.log(res)
    console.log(res.status)
    that.setState({ notLoading: true })
    if ((res.status == 200) || (res.status == 201)) {
      console.log(res.status)
      Toaster.success('Login successful')
      that.lawyerLogin(res)
      that.setState({ notLoading: true })
    } else {
      console.log(res)
      Toaster.error('Login Failed, invalid parameters')
    }
  }).catch(function (err) {
    console.log(err)
    Toaster.error('Network Error or invalid Login')
    that.setState({ notLoading: true })
  })
  }

  render () {
    return (
      <section >
        {
          this.state.user
            ? <div className='w3-card-4 w3-center m-t-4' style={{ width: '52%', height: '310px', margin: '0 auto' }}>
              {this.state.notLoading
            ? <div className=' m-t-4'>
              <div className='w3-container w3-grey m-t-4'>
                <button onClick={this.showUser} className={`w3-button ${this.state.user ? 'w3-blue-grey' : ''} w3-hover-black`}>User Login</button>
                <button onClick={this.showLawyer} className={`w3-button ${this.state.user ? '' : 'w3-green'} w3-hover-black`}>Lawyer Login</button>
              </div>

              <form className='w3-container m-t-4' method='POST' onSubmit={this.handleUserSubmit}>
                <input type='email' style={{ display: 'none' }} />
                <input type='password' style={{ display: 'none' }} />
                <label className='w3-text-teal'><b>Email address:</b></label>
                <input type='email' pattern='/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/' className='w3-input w3-border w3-light-grey' onChange={this.handleChange} name='e_addr' required />

                <label className='w3-text-teal'><b>Password:</b></label>
                <input type='password' className='w3-input w3-border w3-light-grey' onChange={this.handleChange} name='password' required />

                <input onClick={this.handleUserSubmit} type='submit' value='Login' className='w3-btn w3-blue-grey m-t-2' />
              </form>
            </div>
            : <Spinner />
          }
            </div>
          : <div className='w3-card-4 w3-center m-t-4' style={{ width: '52%', height: '310px', margin: '0 auto' }}>
            {this.state.notLoading
              ? <div className=' m-t-4'>
                <div className='w3-container w3-grey m-t-4'>
                  <button onClick={this.showUser} className={`w3-button ${this.state.user ? 'w3-blue-grey' : ''} w3-hover-black`}>User Login</button>
                  <button onClick={this.showLawyer} className={`w3-button ${this.state.user ? '' : 'w3-green'} w3-hover-black`}>Lawyer Login</button>
                </div>

                <form className='w3-container m-t-4' method='POST' onSubmit={this.handleLawyerSubmit}>
                  <input type='email' style={{ display: 'none' }} />
                  <input type='password' style={{ display: 'none' }} />
                  <label className='w3-text-green'><b>Email address:</b></label>
                  <input type='email' pattern='/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/' className='w3-input w3-border w3-light-grey' onChange={this.handleChange} name='e_addr' required />

                  <label className='w3-text-green'><b>Password:</b></label>
                  <input type='password' className='w3-input w3-border w3-light-grey' onChange={this.handleChange} name='password' required />
                  <Link to={`/forgotpassword`}>forgot password?</Link><br />
                  <input onClick={this.handleLawyerSubmit} type='submit' value='Login' className='w3-btn w3-green m-t-2' />
                </form>
              </div>
              : <Spinner />
            }
          </div>
        }
      </section>
    )
  }
}

export default store =>
  ({
    path: `/leruLogin`,
    getComponent (next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({

          }))(LeruLogin)
      )
    }
  })
