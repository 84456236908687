import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link, browserHistory } from 'react-router';
import { showConfirmModal, closeModal } from '../../actions/modalActions';
import { Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { all, remove, create, update } from '../../actions/ticketActions';
import { all as allVlfCategories } from '../../actions/vlfCategoryActions';
import { create as createFile } from '../../actions/fileActions';
import Spinner from '../../components/widgets/Spinner';
import Icon from '../../components/widgets/Icon';
import FileUploader from '../../components/widgets/FileUploader';
import Toaster from '../../components/widgets/Toaster';
import Heading from '../../components/widgets/Heading';
import NoItem from '../../components/widgets/NoItem';
import PageComponent from '../../components/PageComponent';
import TicketEdit from '../../components/widgets/TicketEdit';
import Checkout from '../../components/widgets/Checkout';

class Registrations extends PageComponent {
    getAuthType = () => ['guest', 'admin'];
    componentWillMount() {
        this.setTitle('Registrations');
        const {
            allVlfCategories,
            location: { query: { message } },
        } = this.props;

        this.state = {
            page: 1,
            ticket: message ? { message, files: [null, null] } : null,
        };

        this.limit = 10;
        this.getTickets(1);

        allVlfCategories({ limit: 100 });
    }

    getTickets = page => {
        const { props: { all, state: { session: { user: owner } } }, limit, q, } = this;
        all({ q, page, limit, owner }, (err, res) => {
            if (!err) {
                this.setState({ page });
            } else {
                Toaster.error(err.message || err);
            }
        });
    };

    renderPaginator = count => {
        if (!count) {
            return null;
        }

        const { page } = this.state;
        const { call: { isLoading } } = this.props.state;
        const total = Math.ceil(count / this.limit);
        let pages = [];
        for (let i = 0; i < total; i++) {
            pages.push(<div
                key={i}
                onClick={this.getTickets.bind(this, i + 1)}
                className={`page ${isLoading ? 'loading' : ''} ${page === i + 1 ? 'selected' : ''}`}>
                {i + 1}
            </div>);
        }

        return pages;
    };

    createTicket(ticket = { files: [null, null] }) {
        this.setState({ ticket });
    };

    subscriptionCheckout = (ticket) => {
        const {
            update,
            state: {
                subscription: {
                    item: { _id: subscription }
                }
            }
        } = this.props;

        update(ticket._id, { checkoutType: ticket.checkoutType, subscription }, (err, ticket) => {
            if (!err) {
                this.setState({ ticket });
            } else {
                Toaster.error(err.message || err);
            }
        });
    };

    getOnlyRegistrations(registrations) {
        let registrations_filter = [
            "Business Name Registration",
            "Company Incorporation",
            "Patent Registration",
            "NGO/Incoporated Trustee Registration",
            "Trademark Registration",
            "Copyright Registration",
            "Tin Registration"
          ];
          var sortedRegistrations = registrations.filter(function(item) {
            return registrations_filter.indexOf(item.vlf_category_expanded.name) !== -1;
          });
        return sortedRegistrations
    }

    getRegistrationsCount(registrations) {
        let registrations_filter = [
            "Business Name Registration",
            "Company Incorporation",
            "Patent Registration",
            "NGO/Incoporated Trustee Registration",
            "Trademark Registration",
            "Copyright Registration",
            "Tin Registration"
          ];
          var sortedRegistrations = registrations.filter(function(item) {
            return registrations_filter.indexOf(item.vlf_category_expanded.name) !== -1;
          });
        return sortedRegistrations.length
    }

    renderTickets = ({ _id, unread, subject, status, messages, lawyer_expanded, stamp, paid }, idx) => {
        const { showCallModal, state: { session } } = this.props;
        return (<Link className="block" key={_id} to={`/tickets/${_id}`}>
            <div className={`Ticket ${unread === session.user ? 'unread' : ''} pt-card pt-elevation-3 m-t-2 `} key={idx}>
                <div className="unread-notice" />
                <small className={`ticket-status pt-tag pt-intent-${status === 'open' ? 'success' : 'danger'}`}>{status}</small>
                {
                    !paid && <small className="ticket-status pt-tag pt-intent-danger m-r-1">UNPAID</small>
                }
                <h2>{subject}</h2>
                <p><Icon name="person" /> {lawyer_expanded ? lawyer_expanded.name : 'No contact lawyer yet'}</p>
                <hr />
                <p className="ticket-message">{messages.last().message}</p>
                <p className="pull-right"><small><Icon name="access_time" /> {moment(stamp).fromNow()}</small></p>
                <div className="clearfix" />
            </div>
        </Link>);
    };

    onPayment = (ticket) => {
        this.createTicket(null);
        browserHistory.push(`/tickets/${ticket._id}`);
    };

    c

    handleSubmit = (ticket, cb = () => { }) => {
        const { create } = this.props;
        create(ticket, (err, ticket) => {
            cb(err, ticket);
            if (!err) {
                browserHistory.push(`/tickets/${ticket._id}`);
            } else {
                Toaster.error(err.message || err);
            }
        });
    };

    renderBreadCrumbs = () => {
        return (<div>
            <ul className="pt-breadcrumbs">
                <li><Link className="pt-breadcrumbs-collapsed" to="/"></Link></li>
                <li><Link className="pt-breadcrumb pt-breadcrumb-current" to="/registrations">Registrations</Link></li>
            </ul>
            <br />
            <br />
        </div>);
    };

    renderPage() {
        const {
            createFile,
            state: {
                subscription: { pricing },
                vlfCategory: { items: vlfCategories },
                ticket: { items, count, isLoading }
            }
        } = this.props;

        const { ticket } = this.state;
        if (isLoading) {
            return <Spinner className="m-t-4 text-center" />;
        }

        return <div className="Tickets">
            <div className="content-width">
                {this.renderBreadCrumbs()}
                <button className="pull-right pt-button pt-intent-accent" onClick={this.createTicket.bind(this, undefined)}>Create Ticket</button>
                <div className="clearfix" />
                <Heading title="My Registrations" />
                {
                    ticket ?
                        <div>
                            <TicketEdit vlfCategories={vlfCategories} ticket={ticket} canSubmit={!ticket._id} createFile={createFile} cancel={this.createTicket.bind(this, null)} submit={this.handleSubmit} />
                            <Checkout price={pricing.ticket} resource={ticket} type="ticket" cb={this.onPayment} />
                        </div> :
                        <div className="tickets">
                            {
                                this.getRegistrationsCount(items) ?
                                    <div>
                                        {this.getOnlyRegistrations(items).map(this.renderTickets)}
                                        <div className="paginator">
                                            {this.renderPaginator(count)}
                                        </div>
                                    </div> :
                                    <NoItem>You haven't created any registrations yet</NoItem>
                            }
                        </div>
                }
            </div>
        </div>
    }
}

export default store =>
    ({
        path: `/registrations`,
        getComponent(next, cb) {
            cb(
                null,
                connect(state => ({ state }),
                    dispatch => ({
                        showConfirmModal: (params) => dispatch(showConfirmModal(params)),
                        allVlfCategories: (kwargs) => dispatch(allVlfCategories(kwargs)),
                        all: (kwargs, callback) => dispatch(all(kwargs, callback)),
                        create: (json, callback) => dispatch(create(json, callback)),
                        createFile: (file, callback) => dispatch(createFile(file, callback)),
                        remove: (_id, json, callback) => dispatch(remove(_id, json, callback))
                    }))(Registrations)
            );
        }
    });
