import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { inflect } from 'inflection';
import {
  showConfirmModal,
  showUserSelectorModal,
} from '../../actions/modalActions';
import { injectReducer } from '../../store/reducers';
import moment from 'moment';
import {
  Tab2,
  Tabs2,
  Popover,
  Tooltip,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import Table from '../../components/widgets/etable';
import Spinner from '../../components/widgets/Spinner';
import { currency } from '../../lib/utils';
import NoItem from '../../components/widgets/NoItem';
import Toaster from '../../components/widgets/Toaster';
import { all, update } from '../../actions/callActions';
import PageComponent from '../../components/PageComponent';
import Icon from '../../components/widgets/Icon';
import axios from 'axios';
import dashboard from '../dashboard';

class AdminCalls extends PageComponent {
  getAuthType = () => ['admin'];
  constructor(props) {
    super(props);
    this.state = {
      dashboard: {},
    };
  }

  componentWillMount() {
    this.fetchAnalytics();
  }

  fetchAnalytics() {
    axios({
      url: `${window.SW_DOMAIN}/api/v1/weekly/summary`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        this.setState({ dashboard: res.data });
      })
      .catch(function (error) {
        return Toaster.error('An error occured, please try again');
      });
  }
  renderPage() {
    const {
      user: { account },
    } = this.props.state;
    if (!account) {
      return <Spinner />;
    }
    const { dashboard } = this.state;

    return (
      <section className="content-width AdminCalls">
        <h2 className="m-t-4 m-b-2">Analytics</h2>

        <div className="row">
          <div className="col-md-3">
            <div class="card">
              <div class="card-body text-center ">
                <h5 class="card-title h1 font-weight-bolder">
                  {dashboard.subscriptionCount}
                </h5>
                <p class="card-text">Subscriptions</p>
              </div>
            </div>
          </div>

          <div className="col-md-2 ">
            <div class="card">
              <div class="card-body text-center ">
                <h5 class="card-title h1 font-weight-bolder">
                  {dashboard.ticketCount}
                </h5>
                <p class="card-text">Tickets</p>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div class="card">
              <div class="card-body text-center ">
                <h5 class="card-title h1 font-weight-bolder">
                  {dashboard.callCount}
                </h5>
                <p class="card-text">Calls</p>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div class="card">
              <div class="card-body text-center ">
                <h5 class="card-title h1 font-weight-bolder">
                  {dashboard.documentCount}
                </h5>
                <p class="card-text">Documents</p>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div class="card">
              <div class="card-body text-center ">
                <h5 class="card-title h1 font-weight-bolder">
                  {dashboard.startupCount}
                </h5>
                <p class="card-text">Startup Bundles</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <iframe
              width="600"
              height="371"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTFP_moAsnkDxChpDF6DkmoTXouiD01VWDd4kikLFEM0V9dwX7_vGbBkW4o1GWRIR5DxvXVXXPSn_cE/pubchart?oid=349260384&amp;format=interactive"
            ></iframe>
          </div>
          <div className="col-md-6">
            <iframe
              width="600"
              height="371"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTFP_moAsnkDxChpDF6DkmoTXouiD01VWDd4kikLFEM0V9dwX7_vGbBkW4o1GWRIR5DxvXVXXPSn_cE/pubchart?oid=1427278411&amp;format=interactive"
            ></iframe>
          </div>
          <div className="col-md-6">
            <iframe
              width="600"
              height="371"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTFP_moAsnkDxChpDF6DkmoTXouiD01VWDd4kikLFEM0V9dwX7_vGbBkW4o1GWRIR5DxvXVXXPSn_cE/pubchart?oid=1829281277&amp;format=interactive"
            ></iframe>
          </div>
          <div className="col-md-6">
            <iframe
              width="600"
              height="371"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTFP_moAsnkDxChpDF6DkmoTXouiD01VWDd4kikLFEM0V9dwX7_vGbBkW4o1GWRIR5DxvXVXXPSn_cE/pubchart?oid=2084598194&amp;format=interactive"
            ></iframe>
          </div>
        </div>
      </section>
    );
  }
}

export default (store) => ({
  path: `/admin/analytics`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          showConfirmModal: (params) => dispatch(showConfirmModal(params)),
          showUserSelectorModal: (params) =>
            dispatch(showUserSelectorModal(params)),
          update: (_id, category, callback) =>
            dispatch(update(_id, category, callback)),
          all: (kwargs, callback) => dispatch(all(kwargs, callback)),
        })
      )(AdminCalls)
    );
  },
});
