import React from 'react';
import { connect } from 'react-redux'
import { Button, Dialog } from "@blueprintjs/core";
import Toaster from '../widgets/Toaster';
import { closeModal, showSigninModal } from '../../actions/modalActions';
import { forgotPassword } from '../../actions/userActions';

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }

  handleChange = e => {
    const { name, value } = e.target || e.srcElement;
    this.setState({ [name]: value });
  }

  handleSubmit = e => {
    e.preventDefault();
    const { state: { email }, props: { forgotPassword } } = this;
    forgotPassword(email, (err, res) => {
      if (err) {
        Toaster.error(err.message);
      } else {
        Toaster.success(res.message);
      }
    });
  }

  render() {
    const {
      closeModal,
      showSigninModal,
      state: {
        user: {
          isLoading, password
        }
      }
    } = this.props;
    const { email } = this.state;

    return <Dialog
      isOpen={true}
      onClose={closeModal}
      className="PasswordReset"
    >
      <div className="pt-dialog-body text-center">
        <h2 className="slim">Reset Your Password</h2>
        <form onSubmit={this.handleSubmit}>
          <p className="m-t-2"><label className="pt-label">
            <input defaultValue={email} placeholder="Email Address" onChange={this.handleChange} type="email" className="pt-input pt-fill" name="email" required />
          </label>
          </p>
          {password && <div className="pt-panel">{password.message}</div>}
          <p>
            <button disabled={isLoading} className="pt-button pt-fill pt-intent-primary">Send</button>
          </p>
          <div className="row m-t-4">
            <div className="col_20"><hr className="v-align middle" /></div>
            <div className="col_60"><h5 className="slim">Got your password?</h5></div>
            <div className="col_20"><hr className="v-align middle" /></div>
          </div>
          <p className="m-t-2">
            <button disabled={isLoading} className="pt-button pt-fill" type="button" onClick={showSigninModal}>Sign In</button>
          </p>
        </form>
      </div>
    </Dialog>;
  }
}

export default connect(
  state => ({ state: state }),
  dispatch => ({
    closeModal: (replace) => dispatch(closeModal(replace)),
    showSigninModal: (params) => dispatch(showSigninModal(params)),
    forgotPassword: (json, callback) => dispatch(forgotPassword(json, callback)),
  })
)(PasswordReset);
