
import { post } from './request';
import { me, packages } from './subscriptionActions';
export function create(json, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'PAYMENT_CREATE'
    });

    const { session } = getState();

    post(`payment`, { json }).then(
      result => {
        if (!result.response.ok) {
          console.log(result)
          callback(result.json);
          dispatch({ type: 'PAYMENT_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          console.log(result);
          dispatch({ type: 'PAYMENT_CREATE_OK', payment: result.json });
          if (session) {
            dispatch(me(session.user));
          }
          if (window.ga) {
            window.ga('send', 'event', 'Payment', json.type);
          }
        }
      }).catch(error => {
        console.log(error)
        callback({ message: 'Failed to proceess payment, please try again' });
        dispatch({ type: 'PAYMENT_ERROR', error });
      });
  };
}