import React from 'react';
import Toaster from '../../components/widgets/Toaster';
import CreditCard from '../../components/widgets/CreditCard';
import Icon from '../../components/widgets/Icon';
import { currency } from '../../lib/utils';
import uuid from 'uuid/v1';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { get, post, put, delete_ } from '../../actions/request';
import axios from 'axios';

export default class Payment extends React.Component {
  constructor(props) {
    super();
    this.state = {
      amount: 0,
      code: '',
      isUnionBank: false,
      code: '',
      modal: false,
      account_num: '',
    };
    this.addCoupon = this.addCoupon.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.getDiscount = this.getDiscount.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  componentWillMount() {
    this.setState({ amount: this.props.data.amount }, () => {});
  }

  addCoupon() {
    const {
      resource,
      data: { type },
      callback,
    } = this.props;
    const { code } = this.state;
    let options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let dt = {
      code,
      type,
      resourceId: resource ? resource._id : '',
      email: this.props.user.email,
    };

    axios
      .post(`${window.SW_DOMAIN}/api/v1/coupons`, dt, options)
      .then((response) => {
        Toaster.success('Coupon applied successfully!');
        if (response.data.amount == 0) {
          resource.paid = true;
          this.props.callback2();
        } else {
          this.setState({ amount: response.data.amount });
        }
      })
      .catch(function (error) {
        return Toaster.error(error.response.data.message);
      });
  }

  getDiscount() {
    const {
      resource,
      data: { type },
    } = this.props;
    const { account_num } = this.state;
    let options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let dt = {
      account_num,
      type,
      resourceId: resource ? resource._id : '',
    };

    axios
      .post(
        `${window.SW_DOMAIN}/api/v1/servicepartners/services/discount`,
        dt,
        options
      )
      .then((response) => {
        Toaster.success('Discount applied successfully!');
        this.setState({ amount: response.data.amount });
      })
      .catch(function (error) {
        return Toaster.error(error.response.data.message);
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target || e.srcElement;
    this.setState({ [name]: value });
  };

  handleChange2(event) {
    this.setState({ code: event.target.value }, () => {});
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isUnionBank' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  payWithRave = () => {
    const {
      user,
      data: { amount, _id, type, payment_plan },
      callback,
      callback3,
    } = this.props;
    let { isUnionBank, account_num } = this.state;
    if (this.props.data.type === 'awoof') {
      let dt = {
        txref: uuid(),
        type: 'awoof',
        years: 1,
      };

      get(`awoof/${user._id}`)
        .then((result) => {
          if (result.response.status == 200) {
            this.props.createSubscription(user._id, dt);
          } else if (
            result.response.error === 'expired' ||
            result.json.error === 'expired'
          ) {
            return Toaster.error('Maximum number of awoof users exceeded');
          } else if (
            result.response.error === 'maximum' ||
            result.json.error === 'maximum'
          ) {
            return Toaster.error('You can only use the awoof plan once');
          }
        })
        .catch((error) => {
          return Toaster.error('An error occured');
        });
    } else if (this.state.amount == 0 && !payment_plan) {
      callback(null, 'coupon');
    } else if (this.state.amount == 0 && payment_plan) {
      let dt = {
        txref: uuid(),
        type: this.props.data.type,
        years: 1,
      };
      this.props.createSubscription(user._id, dt);
    } else {
      const txref = uuid();
      // amount: this.state.amount,
      const payment = window.getpaidSetup({
        PBFPubKey: window.RAVE_API_KEY,
        customer_email: user.email,
        amount: this.state.amount,
        payment_plan,
        customer_phone: user.phone,
        currency: window.SW_CURRENCY,
        country: user.country,
        txref,
        meta: [
          {
            metaname: 'type',
            metavalue: type,
          },
        ],
        onclose: () => {},
        callback: (response) => {
          // var txref = response.tx.txRef;
          // collect flwRef returned and pass to a server page to complete status check.
          console.log('This is the response returned after a charge', response);
          if (
            (isUnionBank && response.tx.chargeResponseCode == '00') ||
            response.tx.chargeResponseCode == '0'
          ) {
            callback3(null, response.tx, account_num);
          } else if (
            response.tx.chargeResponseCode == '00' ||
            response.tx.chargeResponseCode == '0'
          ) {
            callback(null, response.tx);
          } else {
            callback({ message: 'Error with payment' });
          }

          payment.close();
        },
      });
    }
  };

  render() {
    const {
      title,
      data: { amount },
    } = this.props;
    let { isUnionBank } = this.state;
    let isUnionBankUser = localStorage.getItem('isUnionBankUser');
    let isBusinessStimulus = localStorage.getItem('business_stimulus');
    return (
      <div className="Payment">
        <div className="page-title">{title}</div>
        <div className="p-a-2">
          {/* <h1><EditableText defaultValue={1} maxLength={2} width={10} style={{ width: 10 }} placeholder="Coupon" onChange={this.handleChange} /> {inflect('years', years)}</h1> */}
          {isUnionBankUser && (
            <main>
              <p>
                Your Union bank account gives you access to a discounted price.
                Use of this discount gives us the authority to share your data
                with Union Bank
              </p>
              <h5>
                <strong>
                  {' '}
                  Are you a Union Bank Customer
                  <input
                    name="isUnionBank"
                    type="checkbox"
                    className="mx-2 mt-1"
                    checked={this.state.isUnionBank}
                    onChange={this.handleInputChange}
                  />
                </strong>
              </h5>
            </main>
          )}
          {!isUnionBank && (
            <p className="text-center">
              <span>Enter coupon </span>
              <input
                placeholder="Coupon"
                onChange={this.handleChange2}
                type="text"
                className="pt-input pt-fill"
              />
              <button
                type="button"
                className="pt-button pt-intent-success mx-1 my-2"
                onClick={this.addCoupon}
              >
                Apply Coupon
              </button>
              <button
                type="button"
                className="pt-button pt-intent-success mx-1 my-2"
                onClick={this.payWithRave}
              >
                Make Payment
              </button>
            </p>
          )}
          {isUnionBank && (
            <p className="text-center">
              <span>Enter Union Bank Account Number </span>
              <input
                placeholder="Account Number"
                onChange={(event) =>
                  this.setState({ account_num: event.target.value }, () => {})
                }
                type="number"
                className="pt-input pt-fill"
              />

              <button
                type="button"
                className="pt-button pt-intent-success mx-1 my-2"
                onClick={this.getDiscount}
              >
                Get Discount
              </button>
              <button
                type="button"
                className="pt-button pt-intent-success mx-1 my-2"
                onClick={this.payWithRave}
              >
                Make Payment
              </button>
            </p>
          )}
          {isBusinessStimulus && (
            <p>
              Use this coupon <strong>TCFC19</strong> to get 80% discount on
              your consultation
            </p>
          )}
          <div className="pages m-t-2 text-center">
            <h3>
              <strong className="text-muted">Total</strong>{' '}
              {currency(this.state.amount)}
            </h3>
          </div>
        </div>
      </div>
    );
  }
}
