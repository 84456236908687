import { get, post, put } from './request';

export function create(json, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CATEGORY_CREATE'
    });

    post(`category`, { json }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CATEGORY_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch({ type: 'CATEGORY_CREATE_OK', category: result.json });
        }
      }).catch(error => dispatch({ type: 'CATEGORY_CREATE_ERROR', error }));
  };
}

export function update(_id, json, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CATEGORY_UPDATE'
    });

    put(`category/${_id}`, { json }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CATEGORY_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch({ type: 'CATEGORY_UPDATE_OK', category: result.json });
        }
      }).catch(error => dispatch({ type: 'CATEGORY_UPDATE_ERROR', error }));
  };
}

export function find(_id, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CATEGORY_GET'
    });

    get(`category/${_id}`, { json: true }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CATEGORY_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch({ type: 'CATEGORY_GET_OK', category: result.json });
        }
      }).catch(error => dispatch({ type: 'CATEGORY_GET_ERROR', error }));
  };
}

export function all(kwargs = {}, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CATEGORIES_GET'
    });

    const q = kwargs.q ? `&q=${kwargs.q}` : '';
    const limit = `?limit=${kwargs.limit || 10}`;
    get(`categories${limit}${q}`, { json: true }).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'CATEGORY_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch({ type: 'CATEGORIES_GET_OK', data: result.json });
        }
      }).catch(error => dispatch({ type: 'CATEGORIES_GET_ERROR', error }));
  };
}

