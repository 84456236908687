const key = 'users';

import { connect } from 'react-redux';
import { injectReducer } from '../../store/reducers';
import reducer, * as actions from './modules';
import Component from './components';

export default store => ({
  path: `/admin/${key}/:id`,
  getComponent(next, cb) {
    injectReducer(store, { key, reducer });
    cb(
      null,
      connect(state => ({state}), actions)(Component)
    );
  }
});
