import React from 'react';

export default class FAQs extends React.Component {
  render() {
    return (
      <div className="StaticPage">
        <div className="page-content">
          <div>
            <h1 className="text-center">FREQUENTLY ASKED QUESTIONS</h1>
            <div className="m-t-4">

              <p><strong>What is Mylaw.ng?</strong></p>
              <p>Mylaw.ng is a digital platform where legal services can be accessed from the comfort of any device. </p>

              <p><strong>Who owns Mylaw.ng?</strong></p>
              <p>Mylaw.ng is a product of Virtual Platform Limited (RC 1438232) </p>

              <p><strong>Is Mylaw.ng a Law Firm? </strong></p>
              <p>Mylaw.ng is NOT a Law firm and does not provide legal services. </p>


              <p><strong>Is there a physical touch to this Platform?</strong></p>
              <p>We have our registered office at 1b Utomi Airie Avenue, Off Fola Osibo, Lekki Phase 1, Lagos.</p>

              <p><strong>Who are the providers of Legal services on this platform? </strong></p>
              <p>The primary service provider is Legex Partners& Co. Other law firms are on the platform such as Primera Africa Legal and Pac Solicitors. Independent Lawyers are also registered on the platform to carry out legal services. </p>

              <p><strong>What type of legal services can be accessed via Mylaw?</strong></p>
              <p>Legal services on mylaw.ng include but are not limited to Agreements, Legal Consultations, Business Registrations, Intellectual Property Registrations, Phone a Lawyer, Chat with a Lawyer, , Lease or sell your Property,  Legal Representation, etc</p>

              <p><strong>How can I gain access to these services?</strong></p>
              <p>Signup ---- Choose a service ----  Make Payment ---- Access services</p>


              <p><strong>What is the price model on Mylaw?</strong></p>
              <p>We currently offer the ‘Pay as You Go’ option and the ‘Subscription’ option.</p>


              <p><strong>Can I purchase a single document?</strong></p>
              <p>Yes! You can buy any of our legal documents individually, without committing to a subscription.</p>


              <p><strong>Can I manually edit my document?</strong></p>
              <p>Our documents are encrypted via pdf. However, after payment, you can manually edit your document. We can also make minor customizations to your document for free. Our documents are created to be compliant with the Laws of the Federal Republic of Nigeria. Manually editing could compromise the compliance of the document. It is highly recommended to get your document reviewed by a Lawyer after manually editing.  </p>


              <p><strong>Can I download my documents? In what formats?</strong></p>
              <p>You can always download any of the documents that you've completed. Documents are downloaded as Portable Document Format (PDF).</p>


              <p><strong>What if I forgot or can't remember my password?</strong></p>
              <p>If you forgot your password or are just having trouble remembering it, we can help. Simply click on ‘Forgot Password’, enter the email address you used to register on mylaw.ng, and we will send you instructions.<br/>If you need further assistance please contact us any time by email: info@mylaw.ng or call us 0814 0000 829 Mon - Sun, 8am - 6pm WAT.</p>
             
             
                <li><strong>CONTACT US</strong>
                  <p>Please contact us at <a href="mailto:mylawlegex@gmail.com">mylawlegex@gmail.com</a>, or write us at Virtual Platform Limited, 1b, Utomi Aire Avenue, Lekki Phase 1.</p>
                </li>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}