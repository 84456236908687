import numeral from 'numeral';
// import gravatar from 'gravatar';
import moment from 'moment';
export function removeDuplicates(array, key) {
  let obj = {};
  let filteredArray = [];
  for (let i = 0, len = array.length; i < len; i++) {
    obj[array[i][key].toString()] = array[i];
  }

  for (let j in obj) {
    if (obj.hasOwnProperty(j)) {
      filteredArray.push(obj[j]);
    }
  }

  return filteredArray;
}

export function cleanObject(object_) {
  const object = Object.assign({}, object_);
  for (let i in object) {
    if (object.hasOwnProperty(i)) {
      if (object[i] === null || object[i] === undefined) {
        delete object[i];
      }
    }
  }

  return object;
}

export function numberAbbreviate(n = 0) {
  let value = n;
  if (n >= 1e6) {
    value = `${(n / 1e6).toFixed(1)}M`;
  } else if (n >= 1e3) {
    value = `${(n / 1e3).toFixed(1)}K`;
  }

  // Remove trailing decimal 0s returned by toFixed()
  return value.toString().replace('.0', '');
}
export function cannotUseSubscription(resource, subscription) {
  if (!resource || !subscription) {
    return false;
  }

  const subscriptions = resource.subscriptions || [];
  const index = subscriptions.indexOf(subscription.type);
  return index > -1;
}

export function currency(value, precision = 2, symbol = '₦') {
  const format = `0,0.${'0'.repeat(precision)}`;
  return `${symbol}${numeral(value).format(format)}`;
}

export function integerWithCommas(value) {
  return numeral(value).format('0,0');
}

export function duration(start, end, isDays) {
  start = moment(start);
  end = moment(end);
  if (isDays) {
    return end.diff(start, 'days');
  }

  let textDuration = '';
  if (start.format('M') === end.format('M')) {
    textDuration = `${start.format('MMMM Do')} - ${end.format('Do')}`;
  } else {
    textDuration = `${start.format('MMM Do')} - ${end.format('MMM Do')}`;
  }

  return `${textDuration}, ${end.format('YYYY')}`;
}

export function precisionWithCommas(value, precision = 2) {
  let format = `0.${'0'.repeat(precision)}`;
  return numeral(value).format(format);
}

export function ordinal(value) {
  return value && numeral(value).format('Oo')
}

export function slugify(string) {
  if (string) {
    return string.toLowerCase().replace(/\s+|\.|\\|\/|,/g, '-').replace(/-{2,}/g, '-');
  }
}

export function blobFromBase64(base64str) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (base64str.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(base64str.split(',')[1]);
  } else {
    byteString = unescape(base64str.split(',')[1]);
  }

  // separate out the mime component
  const type = base64str.split(',')[0].split(':')[1].split(';')[0];
  const filename = `${(new Date()).getTime()}.${type}`;
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type, filename, name: filename });
}

export function isImage(file) {
  return /\.(jpg|jpeg|gif|png)$/i.test(file)
}

// export function userImage(user, size) {
//   let { image } = user;
//   if (!image) {
//     const args = {};
//     if (size) { args.s = size.toString() }
//     image = gravatar.url(user.email, args);
//   }

//   return image;
// }

export function getSortedData(resource = {}, sort, filter) {
  let { items = [] } = resource;
  if (items.length) {
    switch (sort) {
      case 0:
        items.sort((a, b) =>
          new Date(b.stamp).getTime() - new Date(a.stamp).getTime());
        break;
      case 1:
        items.sort((a, b) =>
          new Date(b.stamp).getTime() - new Date(a.stamp).getTime());
        break;
      case 2:
        items.sort((a, b) => a.price - b.price);
        break;
      case 3:
        items.sort((a, b) => b.price - a.price);
        break;
    }

    if (filter) {
      items = items.filter(item => item.category === filter._id);
    }

    return items;
  }

  return [];
}
