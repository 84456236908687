import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../../../components/widgets/Spinner';
import Toaster from '../../../components/widgets/Toaster';
import { Link, browserHistory } from 'react-router';
import LeruPay from '../../account/LeruPay';
import Select from 'react-select';
import Modal from 'react-modal';
import axios from 'axios';


class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
    };
  }
  
  logout = (e)=> {
    localStorage.removeItem('Laccount');
    localStorage.removeItem('UID-SESS');
    this.setState({data: ""});
    this.forceUpdate();
  }

  componentWillMount() {
    if(localStorage.getItem("UID-SESS")) {

    } else if(localStorage.getItem("LAD-SESS")) {
      browserHistory.push('/leru/lawyer_profile');
    } else {
      browserHistory.push('/leruLogin');
    }
  }

  componentDidUpdate() {
    if(localStorage.getItem("UID-SESS")) {

    } else if(localStorage.getItem("LAD-SESS")) {
      browserHistory.push('/leru/lawyer_profile');
    } else {
      browserHistory.push('/leruLogin');
    }
  }

  fetchData() {
    if(this.state.data === "") {
      const back = localStorage.getItem("LAD-SESS");
      axios.get(`https://cors-anywhere.herokuapp.com/https://immense-tor-56766.herokuapp.com/api/user/read_one.php?id=${back}`)
        .then(res =>{
          console.log(res.data);
          localStorage.setItem('Laccount',JSON.stringify(res.data));
          this.setState({data: res.data})
          console.log('my post ',this.state.data);
        })
        .catch(err => {
          console.log(err);
        })
    }
  }

  componentDidMount() {
    if(localStorage.getItem('Laccount')){
        this.setState({data: JSON.parse(localStorage.getItem('Laccount')) }, () => {console.log(this.state.data)});
        
    } else {
      this.fetchData();
    }
  }
  
  render() {
     const {data} = this.state;
    return (
      <section >
       <section>
        <div className="w3-content w3-margin-top" style={{ maxWidth: "1300px" }}>
          <div className="w3-row-padding" >
            <div className="w3-third">
              <div className="w3-white w3-text-grey w3-card-4">
                <div className="w3-display-container">
                  <img src={this.state.data.profile_picture} style={{ width: "100%" }} alt="Avatar" />
                  <div className="w3-display-bottomleft w3-container w3-text-black">
                    <h2>{this.state.data.fullname}</h2>
                  </div>
                </div>
                <div className="w3-container">
                  <p><i className="fa fa-briefcase fa-fw w3-margin-right w3-large w3-text-teal"></i>User</p>
                  <h2>{this.state.data.plan} gg</h2>
                  <p><i className="fa fa-home fa-fw w3-margin-right w3-large w3-text-teal"></i>{this.state.data.location}</p>
                  <p><i className="fa fa-envelope fa-fw w3-margin-right w3-large w3-text-teal"></i>{this.state.data.email_addr}</p>
                  <p><i className="fa fa-phone fa-fw w3-margin-right w3-large w3-text-teal"></i>{this.state.data.phone_number}</p>
                  <hr />
                  <p className="w3-large"><b><i className="fa fa-asterisk fa-fw w3-margin-right w3-text-teal"></i>Actions</b></p>
                  <p>LERU meter</p>
                  <div className="w3-light-grey w3-round-xlarge w3-small">
                    <div className="w3-container w3-center w3-round-xlarge w3-teal" style={{ width: "0" }}>0%</div>
                  </div>
                  
                  <br />
                  <p className="w3-large w3-text-theme"><b><i className="fa fa-globe fa-fw w3-margin-right w3-text-teal"></i>Account</b></p>
                  <p><b>Log out</b></p>
                  <div className="w3-light-grey w3-round-xlarge">
                    <div onClick={this.logout} className="w3-round-xlarge w3-red" style={{ height: "24px", width: "100%" }}></div>
                  </div>
                  
                  <br />
                </div>
              </div><br />
            </div>
            <div className="w3-twothird">
              <div className="w3-container w3-card w3-white w3-margin-bottom">
                <h2 className="w3-text-grey w3-padding-16"><i className="fa fa-suitcase fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>Subscription</h2>
                <div className="w3-container">
                  <h5 className="w3-opacity"><b>{this.state.data.subscribed === "0"? " No Subscription": "Welcome to LERU, you can use the platform after 1 month membership"}</b></h5>
                  <hr />
                </div>
                <div className="w3-container">
                  <h5 className="w3-opacity"><b>{this.state.data.subscribed === "0"? "LERU": "LERU"}</b></h5>

                  <div>
                  {this.state.data.subscribed === "0"?  
                    <div>
                      <LeruPay
                            callback={this.handleSubmit}
                            title="Checkout"
                            uData = {{
                              name : this.state.data.fname,
                              email_addr : this.state.data.email_addr,
                          }}
                            user={{
                              country: 'NG',
                              phone: this.state.data.phone_number,
                              email: this.state.data.email_addr,
                              plan: 2198
                            }}
                            data={{
                              amount: 1000
                            }} {...this.props}
                      />
                    </div>
                  :
                  ""}
                  </div>
                  <hr />
                </div>
                
              </div>
              <div className="w3-container w3-card w3-white">
                <h2 className="w3-text-grey w3-padding-16"><i className="fa fa-map fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>Active Areas</h2>
                <div className="w3-container">
                  <h5 className="w3-opacity"><b>Lagos</b></h5>
                  <p>We are only active in all areas across Lagos state</p>
                  <hr />
                </div>
                <div className="w3-container">
                  <h5 className="w3-opacity"><b>Across Nigeria</b></h5>
                  <h6 className="w3-text-teal"><i className="fa fa-calendar fa-fw w3-margin-right"></i>Coming soon</h6>
                  <p>We are now active in Lagos state alone, with plans on expanding to the whole of Nigeria</p>
                  <hr />
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <footer className="w3-container w3-teal w3-center w3-margin-top">
          <p> <a href="" target="_blank">LERU &copy; 2018</a></p>
        </footer>
      </section>
      </section>
    );
  }
}

export default store =>
  ({
    path: `/leru/user_profile`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({

          }))(UserProfile)
      );
    }
  });