import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const getTimes = (start, stop, interval) => {
  const times = [];
  for (let i = start; i <= stop; i++) {
    for (let j = 0; j < 60; j += interval) {
      const hour = i < 10 ? `0${i}` : (i > 12 ? i - 12 : i);
      const amPm = i >= 12 ? 'PM' : 'AM';
      const minute = j < 10 ? `0${j}` : j
      const label = `${hour}:${minute} ${amPm}`;
      times.push({
        label,
        value: ((i * 3600) + (j * 60)),
      });
    }
  }

  return times;
};

export default class TimePicker extends Component {
  static propTypes = {
    date: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    takenTimes: PropTypes.arrayOf(PropTypes.shape({
      start: PropTypes.string,
      slot: PropTypes.number,
    })),
    required: PropTypes.bool,
    start: PropTypes.number,
    slot: PropTypes.number.isRequired,
    stop: PropTypes.number,
    name: PropTypes.string.isRequired,
    interval: PropTypes.number,
  };

  static defaultProps = {
    start: 8,
    name: 'time',
    stop: 17,
    takenTimes: [],
    date: moment().add(2, 'days').toDate(),
    interval: 30,
  };

  constructor(props) {
    super(props);
    this.state = {
      times: getTimes(props.start, props.stop, props.interval),
    };
  }

  isTaken = (time) => {
    const { slot, takenTimes, date, interval } = this.props;
    const today = moment(date).startOf('day');
    const booking = today.add(time.value - 60, 'second');
    const endTime = moment(booking).add((interval * slot) - 1, 'minute');
    for (let i = 0, len = takenTimes.length; i < len; i++) {

      const takenTime = takenTimes[i];
      const takenTimeStart = moment(takenTime.start);
      const takenTimeEnd = moment(takenTime.start).add(takenTime.slot * (takenTime.interval || interval), 'minute');
      if (takenTimeStart.isSame(today, 'day') &&
        (booking.isBetween(takenTimeStart, takenTimeEnd) ||
          endTime.isBetween(takenTimeStart, takenTimeEnd) ||
          takenTimeStart.isBetween(booking, endTime) ||
          takenTimeEnd.isBetween(booking, endTime)
        )
      ) {
        return true;
      }
    }

    return false;
  };

  handleChange = (e) => {
    const { value } = e.target || e.srcElement;
    const { name, date, onChange } = this.props;
    const { times } = this.state;
    const time = Number(value);

    if (time) {
      const today = moment(date).startOf('day');
      const value = today.add(time, 'second').toDate();
      onChange({ target: { name, value, _isLocal: true } });
      onChange({ target: { name: 'time', value: time } });
    }
  };

  render() {
    const { times } = this.state;
    const { required, name, time } = this.props;
    return <div className="TimePicker">
      <div className="pt-select pt-fill">
        <select defaultValue={time} name={`${name}-time`} required={required} onChange={this.handleChange}>
          <option label="Select Time:" />
          {times.map((time, idx) => <option disabled={this.isTaken(time)} label={time.label} key={idx} value={time.value}>{time.label}</option>)}
        </select>
      </div>
    </div>;
  }
}