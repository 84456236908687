import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router';
import io from 'socket.io-client'
import { showConfirmModal, closeModal } from '../../actions/modalActions';
import { Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { find, remove } from '../../actions/plfActions';
import { create as createFile } from '../../actions/fileActions';
import { all as allPlfCategories } from '../../actions/plfCategoryActions';
import Spinner from '../../components/widgets/Spinner';
import Icon from '../../components/widgets/Icon';
import FileUploader from '../../components/widgets/FileUploader';
import Toaster from '../../components/widgets/Toaster';
import Heading from '../../components/widgets/Heading';
import NoItem from '../../components/widgets/NoItem';
import PageComponent from '../../components/PageComponent';
import Checkout from '../../components/widgets/Checkout';
import PLFRequestPanel from '../../components/widgets/PLFRequestPanel';

class PLFRequest extends PageComponent {
  getAuthType = () => ['guest', 'lawyer', 'admin'];
  componentWillMount() {
    this.setTitle('I need a lawyer');
    this.state = {
      page: 1,
      plf: null,
    };

    this.limit = 10;
    this.getPLFRequest();
    const { closeModal, remove, showConfirmModal, allPlfCategories } = this.props;
    allPlfCategories({ limit: 100 });
  }

  getPLFRequest = () => {
    const { props: { find, params: { id } }, limit, q, } = this;
    find(id, (err, plf) => {
      if (!err) {
        this.setState({ plf });
      } else {
        Toaster.error(err.message || err);
      }
    });
  };

  handleSubmit = (message, cb2) => {
    const {
      state: { session: { user },
        plf: { item: plf } },
      createMessage,
      showConfirmModal,
    } = this.props;

    const cb = () => {
      createMessage(plf._id, message, (err) => {
        cb2(err);
        if (err) {
          Toaster.error(err.message || err);
        }
      });
    };

    if (user !== plf.owner && !plf.lawyer) {
      showConfirmModal({
        title: 'Confirm PLFRequest Ownership',
        message: 'Are you sure you want to claim and manage this plf?',
        buttons: [{
          label: 'Yes',
          intent: 'danger',
          action: cb
        }]
      })
    } else {
      cb();
    }
  };

  renderBreadCrumbs = item => {
    const name = item.subject;
    return (<div>
      <ul className="pt-breadcrumbs">
        <li><Link className="pt-breadcrumbs-collapsed" to="/"></Link></li>
        <li><Link className="pt-breadcrumb" to="/plf-requests">PLFRequests</Link></li>
        <li><Link className="pt-breadcrumb pt-breadcrumb-current">{name && name.ucFirst()}</Link></li>
      </ul>
      <br />
      <br />
    </div>);
  }

  renderPage() {
    const {
      createFile,
      state: {
        user: { account },
        subscription: { pricing },
        session: { user },
        plfCategory: { items: plfCategories },
        plf: { isLoading }
      },
    } = this.props;
    const { plf } = this.state;
    if (isLoading || !plf) {
      return <Spinner className="m-t-4 text-center" />;
    }

    plf.plf_category_expanded = plf.plf_category_expanded || {};
    const {
      files,
      description,
      plf_category_expanded,
      lawyer_expanded,
      lawyer,
      subject,
      stamp,
      status,
      owner,
      paid
    } = plf;

    return <div className="PlfRequestPage">
      <div className="content-width">
        {this.renderBreadCrumbs(plf)}
        <div className="clearfix" />
        <Heading title={subject} />
        <div className="pt-card pt-elevation-3 plf-summary">
          <div className="pull-right">
            {
              lawyer_expanded &&
              <small>
                <a href={`tel:${lawyer_expanded.phone}`}>{lawyer_expanded.phone}</a>,&nbsp;
              <a href={`mailto:${lawyer_expanded.email}`}>{lawyer_expanded.email}</a>
              </small>
            }
            {/* <h5 className={`plf-status pt-tag pt-intent-${status === 'open' ? 'success' : 'danger'}`}>{status}</h5> */}
            {!paid && <h5 className="plf-status pt-tag pt-intent-danger m-r-1">UNPAID</h5>}
          </div>
          <h4><Icon name="person" /> {lawyer_expanded ? lawyer_expanded.name : 'No contact lawyer yet'}</h4>
          <div className="text-muted m-t-2 m-b-2" dangerouslySetInnerHTML={{ __html: description }} />
          <p className="pt-tag pt-intent-primary pt-large pt-round">{plf_category_expanded.name}</p>
          <p>{moment(stamp).format('LL LT')}</p>
          <p>
            {
              files.length ?
                <div>
                  <hr />
                  {files.map((file, idx) => <p key={idx}><a download href={file} target="_blank" alt='Attachment'><Icon name="attach_file" /> Attachment {idx + 1}</a></p>)}
                </div>
                : null
            }
          </p>
        </div>
        <div id="scroll-ref" />
        {
          paid ? (status === 'closed' ?
            <NoItem>This plf is closed</NoItem> :
            <div className="m-t-2">
              {/* {
                account.type.isLawyer() || owner === user ? ((owner === user || lawyer === user || (!lawyer)) ?
                  <PLFRequestPanel plfCategories={plfCategories} canChangeStatus={user === lawyer} submitText="Respond" title="Reply PLFRequest" noSubject createFile={createFile} submit={this.handleSubmit} /> :
                  <NoItem>This PLF Request already has a responder</NoItem>) :
                  <NoItem>Only lawyers can respond to plf requests</NoItem>
              } */}
            </div>
          )
            :
            <div>
              <NoItem>Make payment to get support from our panel of lawyers.</NoItem>
              <div className="pt-card pt-elevation-4 text-center">
                <Checkout checkoutType="pay-as-you-go" price={plf_category_expanded.price || pricing.plf} resource={plf} type="plf" cb={this.getPLFRequest} />
              </div>
            </div>
        }
      </div>
    </div >
  }
}

export default store =>
  ({
    path: `/plf-requests/:id`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({
            showConfirmModal: (params) => dispatch(showConfirmModal(params)),
            find: (kwargs, callback) => dispatch(find(kwargs, callback)),
            allPlfCategories: (kwargs, callback) => dispatch(allPlfCategories(kwargs, callback)),
            createFile: (file, callback) => dispatch(createFile(file, callback)),
            remove: (_id, json, callback) => dispatch(remove(_id, json, callback))
          }))(PLFRequest)
      );
    }
  });
