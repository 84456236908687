const initialState = {
  items: [],
  count: 0,
  item: null,
  isLoading: false,
  template: {
    isLoading: false,
    items: []
  }
};

export function documentReducer(state = initialState, action) {
  const { data, document: item, type, error } = action;
  switch (type) {
    case 'DOCUMENT_TEMPLATE_ERROR':
      return Object.assign({}, state, {
        template: {
          isLoading: false,
          error
        }
      });
    case 'DOCUMENT_TEMPLATE_CREATE':
      return Object.assign({}, state, {
        template: {
          item: null,
          isLoading: true
        }
      });
    case 'DOCUMENT_TEMPLATE_CREATE_OK':
      return Object.assign({}, state, {
        template: {
          item,
          isLoading: false
        }
      });

    case 'DOCUMENT_TEMPLATE_GET':
      return Object.assign({}, state, {
        template: {
          item: null,
          isLoading: true
        }
      });
    case 'DOCUMENT_TEMPLATE_GET_OK':
      return Object.assign({}, state, {
        template: {
          item,
          isLoading: false
        }
      });

    case 'DOCUMENT_TEMPLATES_GET':
      return Object.assign({}, state, {
        template: {
          items: [],
          count: 0,
          isLoading: true
        }
      });
    case 'DOCUMENT_TEMPLATES_GET_OK':
      return Object.assign({}, state, {
        template: {
          items: data.items,
          count: data.count,
          isLoading: false
        }
      });
    case 'DOCUMENT_ERROR':
      return Object.assign({}, state, {
        isLoading: false,
        error
      });
    case 'DOCUMENT_GET':
    case 'DOCUMENT_CREATE':
    case 'DOCUMENT_UPDATE':
      return Object.assign({}, state, {
        item: null,
        isLoading: true
      });
    case 'DOCUMENT_CREATE_OK':
    case 'DOCUMENT_UPDATE_OK':
    case 'DOCUMENT_GET_OK':
      return Object.assign({}, state, {
        item,
        isLoading: false
      });
    case 'DOCUMENTS_GET':
      return Object.assign({}, state, {
        items: [],
        count: 0,
        isLoading: true
      });
    case 'DOCUMENTS_OK':
      return Object.assign({}, state, {
        items: data.items,
        count: data.count,
        isLoading: false
      });
    case 'DOCUMENT_TEMPLATES_GET_OK':
      return Object.assign({}, state, {
        items: data.items,
        count: data.count,
        isLoading: false
      });

    default:
      return state;
  }
}
