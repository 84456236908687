import React from 'react';
import { connect } from 'react-redux';
import TermsContent from '../../components/TermsContent';

class TermsOfUser extends React.Component {
  render() {
    return <TermsContent />
  }
}

export default store =>
  ({
    path: `/terms`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({

          }))(TermsOfUser)
      );
    }
  });
