import React from 'react';
import NumberFormat from 'react-number-format';
import creditcardutils from 'creditcardutils';
import { cardData } from '../../data';

export default class CreditCard extends React.Component {
  constructor(props) {
    super(props);
    this.editCard = this.editCard.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderCardImage = this.renderCardImage.bind(this);
    this.isValidCard = this.isValidCard.bind(this);
    this.itemExists = this.itemExists.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  editCard(card = {}) {
    if (card && card.number) {
      card.type = creditcardutils.parseCardType(card.number);
    }

    this.setState({ card });
  };

  handleChange(e) {
    const { name, value } = (e.target || e.srcElement);
    const { submit, noCTA } = this.props;
    const card = this.state.card;
    card[name] = value;
    card.type = creditcardutils.parseCardType(card.number);
    card.isValid = this.isValidCard(card);
    this.setState({ card });
    if (noCTA) {
      this.handleSubmit(card);
    }
  };

  renderCardImage(card, idx = 0, selected, className = 'm-r-2') {
    const type = cardData.type[card.type];
    return type && <img key={idx} style={{ opacity: selected ? 1 : 0.5 }} src={type.icon} alt={type.name} height={44} width={44} className={className} />
  }

  itemExists(item, len = 2) {
    return item && item.toString().length >= len;
  }

  isValidCard({ name, cvv, expiry, number, type }) {
    if (!this.itemExists(name)) {
      this.errors.name = true;
      return false;
    }

    delete this.errors.name;
    if (!creditcardutils.validateCardNumber(number)) {
      this.errors.number = true;
      return false;
    }

    delete this.errors.number;
    if (expiry) {
      const expiry_ = creditcardutils.parseCardExpiry(expiry);
      if (!creditcardutils.validateCardExpiry(expiry_.month, expiry_.year)) {
        this.errors.expiry = true;
        return false;
      }
    } else {
      this.errors.expiry = true;
      return false;
    }

    delete this.errors.expiry;
    if (!creditcardutils.validateCardCVC(cvv, type)) {
      this.errors.cvv = true;
      return false;
    }

    this.errors = {};
    return true;
  };

  componentWillMount() {
    this.errors = {};
    this.state = {
      card: this.props.card || {},
    };
  }

  handleSubmit(card) {
    const { submit } = this.props;
    const isValid = this.isValidCard(card);
    card.isValid = isValid;
    if (!isValid) {
      return submit(null);
    }

    submit(card);
    return false;
  };


  render() {
    const { errors,
      state: { card },
      props: { noCTA, showEmail },
      handleSubmit,
      handleChange,
      renderCardImage,
      isValidCard
    } = this;

    return <div className="CreditCard">
      <div className="row">
        <div className="col_50">
          <p>
            <label className="pt-label">Full Name</label>
            <input className={`pt-input pt-fill has-feedback ${errors.name ? 'pt-intent-danger' : ''}`} defaultValue={card.name} onChange={this.handleChange} name="name" type="text" ref="name" placeholder="Enter name as written on card" required />
          </p>
        </div>
        <div className="col_10"/>
        {
          showEmail &&
          <div className="col_40">
            <p>
              <label className="pt-label">Email Address</label>
              <input className={`pt-input pt-fill has-feedback ${errors.name ? 'pt-intent-danger' : ''}`} defaultValue={card.email} onChange={this.handleChange} name="email" type="text" ref="email" placeholder="Enter your email address" required />
            </p>
          </div>}
      </div>
      <div className="row">
        <div className="col_50">
          <p>
            <label className="pt-label">Card Number</label>
            <NumberFormat className={`pt-input pt-fill has-feedback ${errors.number ? 'pt-intent-danger' : ''}`} required format="#### #### #### ####" value={card.number} onChange={this.handleChange} name="number" ref="number" placeholder="Enter Card Number" />
          </p>
        </div>
        <div className="col_50">
          <div className="text-right m-t-3">
            {Object.keys(cardData.type).map((key, idx) => {
              const item = cardData.type[key];
              return renderCardImage({ type: key, name: item.name }, idx, card.type === key, 'm-l-2');
            })}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col_40">
          <p>
            <label className="pt-label">Expiration Date</label>
            <NumberFormat className={`pt-input pt-fill has-feedback ${errors.expiry ? 'pt-intent-danger' : ''}`} required format="## / ####" value={card.expiry} onChange={this.handleChange} name="expiry" ref="expiry" mask="_" placeholder="MM / YYYY" />
          </p>
        </div>
        <div className="col_20" />
        <div className="col_40">
          <p>
            <label className="pt-label">Security Code</label>
            <input className={`pt-input pt-fill has-feedback ${errors.cvv ? 'pt-intent-danger' : ''}`} defaultValue={card.cvv} onChange={this.handleChange} name="cvv" type="text" ref="cvv" placeholder="Enter cvv" maxLength={4} required />
          </p>
        </div>
      </div>
      <div className="pull-right">
        {!noCTA && <button className="pt-button pt-button-primary" onClick={this.handleSubmit.bind(this, card)}>Save</button>}
      </div>
      <div className="clearfix" />
    </div>
  }
}