import { post, put, get } from "./request";
// Can you see this?
// We might also need to share a terminal session
// How long is it taking you to get this setup? Seems like it took you about an hour tog et the VSCode thing working

//This is windows. I was trying to install the extension on linux, then the virtual box crashed.
//So I opted for windows so we can tackle the fe part.
export function signup(json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: "USER_SIGNUP"
    });

    post(`user`, {
      json
    })
      .then(result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: "USER_ERROR",
            error: result.response.statusText
          });
        } else {
          callback(null, result.json);
          dispatch({
            type: "USER_SIGNUP_OK",
            user: result.json
          });
          if (window.ga) {
            window.ga("send", "event", "Signup Form", "submit");
          }
        }
      })
      .catch(error => {
        dispatch({
          type: "USER_ERROR",
          error
        });
        callback(error);
      });
  };
}

export function find(_id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: "USER_GET"
    });

    get(`user/${_id}`)
      .then(result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: "USER_ERROR",
            error: result.response.statusText
          });
        } else {
          callback(null, result.json);
          dispatch({
            type: "USER_GET_OK",
            user: result.json
          });
        }
      })
      .catch(error => {
        dispatch({
          type: "USER_ERROR",
          error
        });
        callback(error);
      });
  };
}

export function forgotPassword(email, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: "USER_FORGOT_PASSWORD"
    });

    post(`user/${email}/reset`, {
      json: {}
    })
      .then(result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: "USER_ERROR",
            error: result.response.statusText
          });
        } else {
          callback(null, result.json);
          dispatch({
            type: "USER_FORGOT_PASSWORD_OK",
            password: result.json
          });
        }
      })
      .catch(error => {
        dispatch({
          type: "USER_ERROR",
          error
        });
        callback(error);
      });
  };
}

export function account(_id, callback = () => {}) {
  return (dispatch, getState) => {
    _id = localStorage.getItem("user") || _id;
    if (!_id) {
      const error = {
        message: "Not Signed in"
      };
      // callback(error);
      return dispatch({
        type: "USER_ERROR",
        error
      });
    }

    dispatch({
      type: "USER_ACCOUNT"
    });

    get(`user/${_id}`)
      .then(result => {
        if (!result.response.ok) {
          dispatch({
            type: "USER_ERROR",
            error: result.response.statusText
          });
          callback(result.json);
        } else {
          dispatch({
            type: "USER_ACCOUNT_OK",
            account: result.json
          });
          callback(null, result.json);
        }
      })
      .catch(error => {
        dispatch({
          type: "USER_ERROR",
          error
        });
        callback(error);
      });
  };
}

export function storeAccount(account) {
  return (dispatch, getState) => {
    dispatch({
      type: "USER_ACCOUNT"
    });
    dispatch({
      type: "USER_ACCOUNT_OK",
      account
    });
  };
}

export function all(q, kwargs = {}, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: "USERS_GET"
    });

    q = q ? `&q=${q}` : "";
    const lawyer = kwargs.lawyer ? `&lawyer=${kwargs.lawyer}` : "";
    const page = kwargs.page || 1;
    const limit = `&limit=${kwargs.limit || 20}`;
    get(`users?page=${page}${q}${lawyer}${limit}`)
      .then(result => {
        if (!result.response.ok) {
          callback(result.response.statusText);
          dispatch({
            type: "USER_ERROR",
            error: result.response.statusText
          });
        } else {
          callback(null, result.json);
          dispatch({
            type: "USERS_GET_OK",
            users: result.json
          });
        }
      })
      .catch(error => {
        dispatch({
          type: "USER_ERROR",
          error
        });
        callback(error);
      });
  };
}

export function update(_id, json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: "USER_UPDATE"
    });

    put(`user/${_id}`, {
      json
    })
      .then(result => {
        if (!result.response.ok) {
          callback(result.response.statusText);
          dispatch({
            type: "USER_ERROR",
            error: result.response.statusText
          });
        } else {
          callback(null, result.json);
          if (_id === window.localStorage.getItem("user")) {
            dispatch({
              type: "USER_ACCOUNT_OK",
              account: result.json
            });
          }

          dispatch({
            type: "USER_UPDATE_OK",
            user: result.json
          });
        }
      })
      .catch(error => {
        dispatch({
          type: "USER_ERROR",
          error
        });
        callback(error);
      });
  };
}
