import React from 'react';
import { connect } from 'react-redux'
import { Button, Dialog, Intent } from "@blueprintjs/core";
import { closeModal, showSigninModal } from '../../actions/modalActions';
import { Link } from 'react-router';
import Toaster from '../widgets/Toaster';
import { countries } from '../../data';
import { signup, storeAccount } from '../../actions/userActions';
import { login } from '../../actions/sessionActions';

class SignupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        country: 'NG'
      }
    };
  }

  handleChange(e) {
    const { name, value } = e.target || e.srcElement;
    const form = this.state.form;
    form[name] = value;
    this.setState({ form });
  }


  componentDidMount() {
    // this.refs.name.focus();
  }

  handleSubmit = e => {
    e.preventDefault();
    const { state: { form }, props: { signup, closeModal, login, storeAccount, params: { callback } } } = this;
    delete form.password_again;
    form.country = 'NG';
    signup(form, (err, user) => {
      if (err) {
        Toaster.error(err.message);
      } else {
        login({ email: form.email, password: form.password }, (err, res) => {
          if (err) {
            Toaster.error(err.message);
            console.log(err.message);// my added code
          } else {
            storeAccount(user);
            if (callback) {
              callback();
            } else {
              Toaster.success('Your account was created');
            }
            closeModal();
          }
        });
      }
    });
  };

  render() {
    const {
      closeModal,
      showSigninModal,
      state: {
        session: {
          isLoading: isLoading2
        },
        user: {
          isLoading
        }
      },
      params,
    } = this.props;

    const {
      form: { password, password_again, country }
    } = this.state;

    return <Dialog
      isOpen={true}
      onClose={closeModal}
      className="SignupModal"
    >
      <div className="pt-dialog-body text-center">
        <h2 className="slim">Create A New Account</h2>
        <form onSubmit={this.handleSubmit}>
          <p className="m-t-2">
            <label className="pt-label">
              <input placeholder="Full Name" ref="name" onChange={this.handleChange.bind(this)} type="text" className="pt-input pt-fill" name="name" required />
            </label>
          </p>
          <p className="m-t-2">
            <label className="pt-label">
              <input placeholder="Email Address" onChange={this.handleChange.bind(this)} type="email" className="pt-input pt-fill" name="email" required />
            </label>
          </p>

          <div className="pt-select pt-fill">
            <select readOnly placeholder="Select Country" defaultValue={country} onChange={this.handleChange.bind(this)} className="pt-fill" name="country" required>
              {countries.map(({ code, name }) => <option key={code} label={name} value={code}>{name}</option>)}
            </select>
          </div>

          <p>
            <label className="pt-label">
              <input placeholder="Password" onChange={this.handleChange.bind(this)} type="password" className="pt-input pt-fill" name="password" required />
            </label>
          </p>
          <p>
            <label className="pt-label">
              <input placeholder="Retype Password" onChange={this.handleChange.bind(this)} type="password" className="pt-input pt-fill" name="password_again" required />
            </label>
          </p>
          <p className="text-justify">
            <small>By clicking Create Account, you acknowledge you have read and agreed to our <a target="_blank" href="/terms">Terms of Use</a> and <a target="_blank" href="/privacy">Privacy Policy</a>.</small>
          </p>
          <p>
            <button disabled={isLoading || isLoading2 || (password !== password_again && password)} className="pt-button pt-fill pt-intent-primary">Create Account</button>
          </p>
          <div className="row m-t-4">
            <div className="col_20"><hr className="v-align middle" /></div>
            <div className="col_60"><h5 className="slim">Already have an account?</h5></div>
            <div className="col_20"><hr className="v-align middle" /></div>
          </div>
          <p className="m-t-2">
            <button className="pt-button pt-fill" type="button" onClick={showSigninModal}>Sign In</button>
          </p>
        </form>
      </div>
    </Dialog>;
  }
}

export default connect(
  state => ({ state: state }),
  dispatch => ({
    closeModal: (replace) => dispatch(closeModal(replace)),
    showSigninModal: (params) => dispatch(showSigninModal(params)),
    signup: (json, callback) => dispatch(signup(json, callback)),
    login: (json, callback) => dispatch(login(json, callback)),
    storeAccount: (json) => dispatch(storeAccount(json)),
  })
)(SignupModal);
