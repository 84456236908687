import { get, post, put } from './request';

export function createTemplate(json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DOCUMENT_TEMPLATE_CREATE',
    });

    post(`document-template`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'DOCUMENT_TEMPLATE_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({
            type: 'DOCUMENT_TEMPLATE_CREATE_OK',
            document: result.json,
          });
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'DOCUMENT_TEMPLATE_ERROR', error });
      });
  };
}

export function updateTemplate(_id, json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DOCUMENT_TEMPLATE_UPDATE',
    });

    put(`document-template/${_id}`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'DOCUMENT_TEMPLATE_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({
            type: 'DOCUMENT_TEMPLATE_UPDATE_OK',
            document: result.json,
          });
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'DOCUMENT_TEMPLATE_ERROR', error });
      });
  };
}

export function getTemplate(_id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DOCUMENT_TEMPLATE_GET',
    });

    get(`document-template/${_id}`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'DOCUMENT_TEMPLATE_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({ type: 'DOCUMENT_TEMPLATE_GET_OK', document: result.json });
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'DOCUMENT_TEMPLATE_ERROR', error });
      });
  };
}

export function getTemplates(kwargs = {}, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DOCUMENT_TEMPLATES_GET',
    });
    const q = kwargs.q ? `&q=${kwargs.q}` : '';
    const limit = `?limit=${kwargs.limit || 10}`;
    const page = `&page=${kwargs.page || 1}`;
    get(`document-templates${limit}${q}${page}`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'DOCUMENT_TEMPLATE_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({ type: 'DOCUMENT_TEMPLATES_GET_OK', data: result.json });
        }
      })
      .catch((error) => {
        callback(error);
        console.log(error);
        dispatch({ type: 'DOCUMENT_TEMPLATE_ERROR', error });
      });
  };
}

// Documents
export function create(json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DOCUMENT_CREATE',
    });

    post(`document`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'DOCUMENT_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({ type: 'DOCUMENT_CREATE_OK', document: result.json });
          if (window.ga) {
            window.ga('send', 'event', 'Document', 'create');
          }
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'DOCUMENT_ERROR', error });
      });
  };
}

export function update(_id, json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DOCUMENT_UPDATE',
    });

    put(`document/${_id}`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'DOCUMENT_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({ type: 'DOCUMENT_UPDATE_OK', document: result.json });
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'DOCUMENT_ERROR', error });
      });
  };
}

export function find(_id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DOCUMENT_GET',
    });

    get(`document/${_id}`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'DOCUMENT_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({ type: 'DOCUMENT_GET_OK', document: result.json });
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'DOCUMENT_ERROR', error });
      });
  };
}

export function all(kwargs = {}, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DOCUMENTS_GET',
    });

    const q = kwargs.q ? `&q=${kwargs.q}` : '';
    const owner = kwargs.owner ? `&owner=${kwargs.owner}` : '';
    const page = kwargs.page ? `&page=${kwargs.page}` : '';
    const limit = `?limit=${kwargs.limit || 10}`;
    get(`documents${limit}${q}${owner}${page}`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({
            type: 'DOCUMENT_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          dispatch({ type: 'DOCUMENTS_OK', data: result.json });
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'DOCUMENT_ERROR', error });
      });
  };
}
