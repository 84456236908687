const initialState = {
  items: [],
  count: 0,
  item: null,
  packages: null,
  pricing: {
    call: 1000,
    ticket: 2000,
  },
  isLoading: false
};

export function subscriptionReducer(state = initialState, action) {
  const { data, subscription: item, type, packages, pricing } = action;
  switch (type) {
    case 'SUBSCRIPTION_ERROR':
      return Object.assign({}, state, {
        isLoading: false
      });
    case 'SUBSCRIPTION_CREATE':
      return Object.assign({}, state, {
        item: null,
        isLoading: true
      });
    case 'SUBSCRIPTION_CREATE_OK':
      return Object.assign({}, state, {
        item,
        isLoading: false
      });

    case 'SUBSCRIPTION_GET':
      return Object.assign({}, state, {
        item: null,
        isLoading: true
      });
    case 'SUBSCRIPTION_GET_OK':
      return Object.assign({}, state, {
        item,
        isLoading: false
      });
    case 'SUBSCRIPTION_PACKAGES_GET':
      return Object.assign({}, state, {
        packages: [],
      });
    case 'SUBSCRIPTION_PACKAGES_OK':
      return Object.assign({}, state, {
        packages
      });
    case 'SUBSCRIPTION_PRICING_OK':
      return Object.assign({}, state, {
        pricing,
      });
    case 'SUBSCRIPTIONS_GET':
      return Object.assign({}, state, {
        items: [],
        count: 0,
        isLoading: true
      });
    case 'SUBSCRIPTIONS_GET_OK':
      return Object.assign({}, state, {
        items: data.items,
        count: data.count,
        isLoading: false
      });

    default:
      return state;
  }
}
