import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import CKEditor from '../../components/widgets/CKEditor';
import { questionTypes } from '../../data';
import Toaster from '../../components/widgets/Toaster';
import { cleanObject } from '../../lib/utils';
import { showConfirmModal } from '../../actions/modalActions';
import { create, update, find } from '../../actions/subcategoryActions';
import { all as getCategories } from '../../actions/categoryActions';
import Spinner from '../../components/widgets/Spinner';
import NoItem from '../../components/widgets/NoItem';
import PageComponent from '../../components/PageComponent';
const NEW = 'new';
class Subcategory extends PageComponent {
  getAuthType = () => ['admin'];
  componentWillMount() {
    this.state = {};
    this.fieldKey = 1;
    const { params: { id: _id }, getCategories } = this.props;
    if (_id !== NEW) {
      this.get(_id);
    } else {
      this.state.subcategory = {
        active: true
      };
    }

    getCategories();
  }

  get = _id => {
    this.props.find(_id, (err, subcategory) => {
      if (!err) {
        this.setState({ subcategory });
      } else {
        Toaster.error(err.message);
      }
    });
  };

  handleChange = e => {
    const { name, value } = e.target || e.srcElement;
    const { subcategory } = this.state;
    subcategory[name] = value;
    this.setState({ subcategory });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      state: { subcategory },
      props: { create, update,
        state: { category: { items: categories } }
      }
    } = this;

    if (!subcategory.category) {
      subcategory.category = categories[0]._id;
    }

    if (subcategory._id) {
      update(subcategory._id, cleanObject(subcategory), (err, res) => {
        if (!err) {
          Toaster.success('Subcategory updated');
        } else {
          Toaster.error(err.message);
        }
      });
    } else {
      create(cleanObject(subcategory), (err, res) => {
        if (!err) {
          Toaster.success('Subcategory created');
          browserHistory.push(`/admin/subcategories`);
        } else {
          Toaster.error(err.message);
        }
      });
    }
  };

  renderBreadCrumbs = (item, id) => {
    const name = item && item.name || id;
    return (<div>
      <ul className="pt-breadcrumbs">
        <li><Link className="pt-breadcrumbs-collapsed" to="/admin"></Link></li>
        <li><Link className="pt-breadcrumb" to="/admin">Dashboard</Link></li>
        <li><Link className="pt-breadcrumb" to="/admin/subcategories">Subcategories</Link></li>
        <li><span className="pt-breadcrumb pt-breadcrumb-current">{name.ucFirst()}</span></li>
      </ul>
      <br />
      <br />
    </div>);
  };

  renderPage() {
    const { subcategory } = this.state;
    const { state: { subcategory: { isLoading }, category: { items: categories } }, params: { id } } = this.props;

    if (!subcategory) {
      return <Spinner className="m-t-4 text-center" />;
    }

    return <div className="Subcategory">
      <form onSubmit={this.handleSubmit} className="content-width">
        {this.renderBreadCrumbs(subcategory, id)}
        <h3>{subcategory._id ? 'Edit' : 'Create'} Subcategory</h3>
        <div className="row m-t-4">
          <label className="pt-label col_45">
            Name
          <br />
            <input type="text" maxLength={100} onChange={this.handleChange} className="pt-input pt-fill" name="name" placeholder="Enter Category Name" defaultValue={subcategory.name} required />
          </label>
          <div className="col_10"></div>
          <label className="pt-label col_45">
            <span><span className={`pt-icon pt-icon-${subcategory.icon}`}></span> Icon</span>
            <br />
            <input maxLength={15} type="text" onChange={this.handleChange} className="pt-input pt-fill" name="icon" placeholder="(Optional)" defaultValue={subcategory.icon} />
          </label>
        </div>
        <div>
          <label className="pt-label col_45">
            Category
              <br />
            <div className="pt-select">
              <select name="category" onChange={this.handleChange} defaultValue={subcategory.category} required>
                {categories.map(({ _id, name }, idx) => <option key={idx} value={_id} label={name}>{name}</option>)}
              </select>
            </div>
          </label>
        </div>
        <div className="row">
          <label className="pt-label col_100">
            Description
          <br />
            <textarea type="text" maxLength={140} rows={5} onChange={this.handleChange} className="pt-input pt-fill" name="description" placeholder="(Optional)" defaultValue={subcategory.description} />
          </label>
        </div>
        <div>
          <br />
          <button type="submit" disabled={isLoading} className="pt-button pt-intent-primary"><span>Save</span></button>
          <span>&nbsp;</span>
          <Link to={isLoading ? null : '/admin/subcategories'}><button type="button" className="pt-button" disabled={isLoading}><span>Cancel</span></button></Link>
        </div>
      </form>
    </div>;
  }
}

export default store =>
  ({
    path: `/admin/subcategories/:id`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({
            showConfirmModal: (params) => dispatch(showConfirmModal(params)),
            create: (subcategory, callback) => dispatch(create(subcategory, callback)),
            getCategories: (q, callback) => dispatch(getCategories(q, callback)),
            update: (_id, subcategory, callback) => dispatch(update(_id, subcategory, callback)),
            find: (_id, callback) => dispatch(find(_id, callback))
          }))(Subcategory)
      );
    }
  });
