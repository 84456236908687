const initialState = {
  items: [],
  count: 0,
  item: null,
  isLoading: false
};

export function ticketReducer(state = initialState, action) {
  const { data, ticket: item, type, error } = action;
  switch (type) {
    case 'TICKET_ERROR':
      return Object.assign({}, state, {
        isLoading: false,
        error
      });
    case 'TICKET_GET':
    case 'TICKET_CREATE':
    case 'TICKET_UPDATE':
      return Object.assign({}, state, {
        item: null,
        isLoading: true
      });
    case 'TICKET_CREATE_OK':
    case 'TICKET_UPDATE_OK':
    case 'TICKET_GET_OK':
      return Object.assign({}, state, {
        item,
        isLoading: false
      });
    case 'TICKETS_GET':
      return Object.assign({}, state, {
        items: [],
        count: 0,
        isLoading: true
      });
    case 'TICKETS_OK':
      return Object.assign({}, state, {
        items: data.items,
        count: data.count,
        isLoading: false
      });

    default:
      return state;
  }
}
