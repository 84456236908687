const key = 'users';

import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { injectReducer } from '../../store/reducers';
import reducer, * as actions from './modules';
import PageComponent from '../../components/PageComponent';
import Table from '../../components/widgets/etable/custom';
import Props from '../../components/widgets/etable/props';

class Users extends PageComponent {
  static propTypes = Props;
  getAuthType = () => ['admin'];
  renderPage() {
    const { props } = this;
    const options = Object.assign({}, props, {
      editKey: 'admin/users',
      legend: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'email',
          label: 'Email Address',
        },
        {
          key: 'type',
          className: 'text-muted',
          fn: (type = 'guest') => type.ucFirst(),
          label: 'Type',
        },
        {
          key: 'bankId',
          className: 'text-muted',
          fn: (bankId) => (bankId ? bankId : 'Mylaw'),
          label: 'Partner',
        },
        {
          key: 'active',
          className: 'text-muted',
          fn: (active) => (active ? 'YES' : 'NO'),
          label: 'Active',
        },
      ],
    });

    return (
      <section className="pad_2 content-width">
        <h2 className="m-b-2">Users</h2>
        {React.createFactory(Table)(options)}
      </section>
    );
  }
}

export default (store) => ({
  path: `/admin/${key}`,
  getComponent(next, cb) {
    injectReducer(store, { key, reducer });
    cb(null, connect((state) => ({ ...state, ...state[key] }), actions)(Users));
  },
});
