import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link, browserHistory } from 'react-router';
import Toaster from '../widgets/Toaster';
import FileUploader from '../widgets/FileUploader';
import NoItem from '../widgets/NoItem';
import Icon from '../widgets/Icon';
import { Button, Dialog, Intent, NumericInput } from '@blueprintjs/core';
import { DatePicker, ITimePickerProps } from '@blueprintjs/datetime';
import { closeModal } from '../../actions/modalActions';
import { create as createFile } from '../../actions/fileActions';
import { create, update, all, getSlots } from '../../actions/callActions';
import Checkout from '../widgets/Checkout';
import TimePicker from '../widgets/TimePicker';
const INTERVAL = 30;
class CallModal extends React.Component {
  constructor(props) {
    super(props);
    const { call = {} } = props.params;
    call.slot = call.slot || 1;
    if (call.start) {
      const start = moment(call.start);
      call.time = start.hour() * 3600 + start.minute() * 60;
    }

    if (!call.start) {
      call.start = moment()
        .add(2, 'days')
        .toDate();
    }

    call.files = call.files || [];
    if (!~call.files.indexOf(null)) {
      call.files.push(null, null);
    }
    props.getSlots(call.start.toString());
    this.state = {
      call,
      selectedDate: moment(call.start) || defaultDate,
    };
  }

  componentWillMount() {
    this.setState({
      selectedDate: moment()
        .add(1, 'days')
        .toDate(),
    });
  }

  handleRemoveFile = idx => {
    const { call } = this.state;
    call.files.splice(idx, 1);
    this.setState({ call });
  };

  isSunday = date => {
    if (typeof date === 'object') {
      return date.getUTCDay() === 0 || date.getUTCDay() === 6;
    }
  };

  handleChange = e => {
    const { name, value, _isLocal } = e.target || e.srcElement;
    if (!value) {
      return;
    }
    const {
      state: { call },
      props: {
        state: {
          session: { user },
        },
        getSlots,
      },
    } = this;

    if ((call.owner && call.owner !== user) || call.lawyer) {
      return;
    }

    if (name === 'start' && !_isLocal) {
      getSlots(value.toString());
    }

    console.log(value);

    call[name] = value;
    this.setState({ call });

    try {
      if (!this.isSunday(value)) {
        this.setState({ selectedDate: value });
      }
    } catch (e) {
      console.log('error');
    }
    console.log(this.state);
  };

  onPayment = () => {
    Toaster.success('Your Call schedule has been created');
    const {
      props: {
        all,
        state: {
          session: { user: owner },
        },
      },
    } = this;
    all({ owner });
    closeModal();
  };

  handleSubmit = e => {
    e.preventDefault();
    let token = window.location.search.slice(1);
    console.log(token);
    const {
      state: {
        call: { start, notes, _id, slot },
      },
      props: { create, closeModal, update },
    } = this;

    if (_id) {
      update(_id, { start, notes, slot, token }, (error, res) => {
        if (!error) {
          Toaster.success('Your Call schedule has been updated');
          closeModal();
        } else {
          Toaster.error(error.message);
        }
      });
    } else {
      create(
        { start, notes, slot, token, interval: INTERVAL },
        (error, call) => {
          if (!error) {
            this.setState({ call }, () => {
              if (call.paid) {
                Toaster.success('Your Call has been scheduled');
                // browserHistory.push(`mylaw.ng/calls`);
                closeModal();
              }
            });
          } else {
            Toaster.error(error.message);
          }
        }
      );
    }
  };

  render() {
    const {
      closeModal,
      showSignupModal,
      createFile,
      showPasswordResetModal,
      state: {
        subscription: { item: subscription, pricing },
        session: { user },
        call: { isLoading, slots },
      },
    } = this.props;

    const modifiers = { isSunday: this.isSunday };
    const defaultDate = moment().toDate();
    const { call } = this.state;
    const selectedDate = new Date(call.start) || defaultDate;
    call.files = call.files || [];
    const { start, notes, owner, _id, lawyer, slot = 1, time } = call;
    const expired = new Date(start) < new Date();

    return (
      <Dialog isOpen={true} onClose={closeModal} className="CallModal sc-modal">
        <div className="pt-dialog-body">
          <div>
            <h2 className="slim">
              Schedule a Voice Call <Icon className="pull-right" name="phone" />
            </h2>
            {expired && <h2 className="pt-tag pt-intent-danger">EXPIRED</h2>}
            <form onSubmit={this.handleSubmit} className="m-t-2">
              <div className="row">
                <div className="col_md_50">
                  <p className="m-t-3 m-l-4">
                    <label className="pt-label">
                      Enter Notes for the Lawyer
                    </label>
                    <textarea
                      disabled={lawyer || expired}
                      defaultValue={notes}
                      maxLength="100"
                      placeholder="Notes"
                      name="notes"
                      rows={2}
                      onChange={this.handleChange}
                      className="pt-input pt-fill"
                      required
                    />
                  </p>

                  <div className="m-t-2 m-l-4">
                    <label className="pt-label">
                      No. of Slot (30 minutes each)
                    </label>
                    <NumericInput
                      className="pt-fill"
                      min={1}
                      max={10}
                      name="slot"
                      value={slot}
                      onChange
                      onValueChange={value =>
                        this.handleChange({ target: { name: 'slot', value } })
                      }
                    />
                  </div>
                  <div className="m-t-2 m-l-4">
                    <label className="pt-label">
                      Attach{call._id && 'ed'} Files
                    </label>
                    <div>
                      {!call._id &&
                        call.files.map((file, idx) => {
                          let isUploading = false;
                          return (
                            <div key={idx} className="file-upload-box">
                              <FileUploader
                                path="calls/"
                                create={createFile}
                                file={file}
                                onUpload={(err, res) => {
                                  if (!err) {
                                    call.files[idx] = res.url;
                                    if (call.files.length <= 5) {
                                      call.files.push(null);
                                    }

                                    this.setState({ call });
                                  }
                                }}
                              />
                              {file && (
                                <div>
                                  <a
                                    className="remove"
                                    onClick={() => this.handleRemoveFile(idx)}
                                  >
                                    <Icon name="remove_circle" />
                                  </a>
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {call._id &&
                        call.files.map((file, idx) => (
                          <a key={idx} href={file} download>
                            <div>
                              <Icon name="attach_file" size={15} />
                              &nbsp;
                              <span>Attachment {idx + 1}</span>
                            </div>
                          </a>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="col_10 sm_hidden" />
                <div className="col_md_40">
                  <div className="m-t-2 m-r-4">
                    {/* <label className="pt-label text-right p-r-2">Pick a day and time</label> */}
                    {expired ? (
                      <span>{moment(start).format('LL LT')}</span>
                    ) : (
                      <div>
                        <DatePicker
                          modifiers={modifiers}
                          // minDate={_id ? new Date(start) : defaultDate}
                          value={selectedDate}
                          onChange={value =>
                            this.handleChange({
                              target: { name: 'start', value },
                            })
                          }
                        />

                        <div className="p-a-1">
                          <TimePicker
                            time={time}
                            name="start"
                            interval={INTERVAL}
                            slot={slot}
                            takenTimes={slots}
                            onChange={this.handleChange}
                            date={new Date(start)}
                          />
                        </div>
                        {/* <DateTimePicker
                        timePickerProps={
                          {
                            showArrowButtons: true,
                            minTime: moment().add(1, 'days').toDate()
                          }}
                        datePickerProps={
                          !call._id ? {
                            minDate: moment().add(1, 'days').toDate()
                          } : {}
                        }
                        value={new Date(start) || moment().add(1, 'days').toDate()}
                        onChange={value => this.handleChange({ target: { name: 'start', value } })}
                      /> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <p>
                <small className="text-muted">
                  Note that every scheduled Call must be initiated by you, the
                  booker, and last for just 30 minutes per slot, after which the
                  Lawyer is obligated to end the call.
                </small>
              </p>
              <span> {lawyer} </span>
              <p>
                {(call._id && call.paid) || !call._id ? (
                  <button
                    disabled={!start || !slot || !time}
                    className="pt-button pt-fill pt-intent-primary"
                  >
                    {_id ? 'Update' : 'Book'} Appointment
                  </button>
                ) : null}
              </p>
            </form>
            {call._id && !call.paid && (
              <div>
                <NoItem>Make payment to get support from our Lawyers.</NoItem>
                <div className="text-center">
                  <Checkout
                    resource={call}
                    price={pricing.call}
                    type="call"
                    cb={this.onPayment}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    );
  }
}

export default connect(
  state => ({ state: state }),
  dispatch => ({
    closeModal: replace => dispatch(closeModal(replace)),
    create: (json, callback) => dispatch(create(json, callback)),
    createFile: (json, callback) => dispatch(createFile(json, callback)),
    all: (kwargs, callback) => dispatch(all(kwargs, callback)),
    getSlots: (date, callback) => dispatch(getSlots(date, callback)),
    update: (_id, json, callback) => dispatch(update(_id, json, callback)),
  })
)(CallModal);
