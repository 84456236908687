/* global localStorage */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import {
  Button,
  Menu,
  MenuItem,
  Popover,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import AppTitle from './widgets/AppTitle';
import Icon from './widgets/Icon';
import { account } from '../actions/userActions';
import { showSignupModal, showSigninModal } from '../actions/modalActions';
import { logout } from '../actions/sessionActions';
import { all as allTickets } from '../actions/ticketActions';
import { all as allCalls } from '../actions/callActions';
import { inflect } from 'inflection';
import { all as allPlfRequests } from '../actions/plfActions';
import { all as allVlfCategories } from '../actions/vlfCategoryActions';
import {
  packages,
  me as mySubscription,
  pricing,
} from '../actions/subscriptionActions';
import { numberAbbreviate } from '../lib/utils';
const numberSummarize = (number) => {
  if (number > 9) {
    return '9+';
  }

  return number || 0;
};

const services = [
  {
    type: 'document',
    icon: 'note',
  },
  {
    type: 'call',
    icon: 'phone',
  },
  {
    type: 'ticket',
    icon: 'label',
  },
  {
    type: 'plf',
    icon: 'people',
  },
];
class Header extends React.Component {
  static propTypes = {
    hide: PropTypes.bool.isRequired,
  };

  componentWillMount() {
    if (this.props.location.query.bank === 'union-bank') {
      localStorage.setItem('isUnionBankUser', true);
    }
    this.setState({
      newCalls: 0,
      newLawyerCalls: 0,
      newLawyerTickets: 0,
      newLawyerPlfRequests: 0,
      newTickets: 0,
      newPlfRequests: 0,
      newTicketMessage: 0,
    });

    const {
      account,
      logout,
      packages,
      pricing,
      mySubscription,
      allVlfCategories,
      state: {
        session: { session },
      },
    } = this.props;

    packages();
    pricing();
    allVlfCategories();
    this.fetchNotifications();
    if (session) {
      mySubscription(session.user);
    }

    account(null, (err, res) => {
      if (err) {
        logout();
      }
    });

    this.adminLinks = [
      {
        name: 'Calls',
        route: 'calls',
        icon: 'phone',
      },
      {
        name: 'Other Services',
        route: 'tickets',
        icon: 'label',
      },
      {
        name: 'Registrations',
        route: 'registrations',
        icon: 'people',
      },
      {
        name: 'Users',
        route: 'users',
        icon: 'user',
      },
      {
        name: 'Document Templates',
        route: 'document-templates',
        icon: 'page-layout',
      },
      {
        name: 'Coupons',
        route: 'coupons',
        icon: 'page-layout',
      },
      {
        name: 'Subscription Packages',
        route: 'subscriptions',
        icon: 'page-layout',
      },
      {
        name: 'Partner Transactions',
        route: 'servicePartner',
        icon: 'page-layout',
      },
      {
        name: 'All Partners',
        route: 'partners',
        icon: 'page-layout',
      },
      {
        name: 'Sars Volunteers',
        route: 'volunteers',
        icon: 'page-layout',
      },
      {
        name: 'Categories',
        route: 'categories',
        icon: 'comparison',
      },
      {
        name: 'Subcategories',
        route: 'subcategories',
        icon: 'comparison',
      },
      {
        name: 'VLF Categories',
        route: 'vlf-categories',
        icon: 'comparison',
      },
      {
        name: 'PLF Categories',
        route: 'plf-categories',
        icon: 'comparison',
      },
      {
        name: 'Leru Dashboard',
        route: 'leru',
        icon: 'comparison',
      },
      {
        name: 'My Lawyer',
        route: 'mylawya',
        icon: 'comparison',
      },
      {
        name: 'Site Analytics',
        route: 'analytics',
        icon: 'comparison',
      },
    ];

    this.lawyerLinks = [
      {
        name: 'Calls',
        route: 'calls',
        icon: 'phone',
      },
      {
        name: 'Other Services',
        route: 'tickets',
        icon: 'label',
      },
      {
        name: 'Registrations',
        route: 'registrations',
        icon: 'people',
      },
    ];

    this.userLinks = [
      {
        name: 'My Agreements',
        route: 'documents',
        className: 'd-block d-lg-none',
        icon: 'document',
      },
      {
        name: 'My Calls',
        route: 'calls',
        className: 'd-block d-lg-none',
        icon: 'phone',
      },
      {
        name: 'Other Services',
        route: 'tickets',
        className: 'd-block d-lg-none',
        icon: 'label',
      },
      {
        name: 'My Registrations',
        route: 'registrations',
        className: 'd-block d-lg-none',
        icon: 'people',
      },
      {
        name: 'My Account',
        route: 'account',
        icon: 'user',
      },
      {
        name: 'Logout',
        onClick: this.logout,
        route: '/',
        icon: 'log-out',
      },
    ];
  }

  fetchNotifications = () => {
    const {
      allCalls,
      allPlfRequests,
      allTickets,
      state: {
        user: { account },
      },
    } = this.props;

    this.count = 0;
    if (account) {
      if (/admin/.test(account.type)) {
        allCalls(
          { is_valid: true, status: 'pending', limit: 0, useCallback: true },
          (err, res) => {
            this.updateNotifications();
            if (!err) {
              this.setState({ newCalls: res.count });
            }
          }
        );

        allTickets(
          { status: 'pending', limit: 0, useCallback: true },
          (err, res) => {
            this.updateNotifications();
            if (!err) {
              this.setState({ newTickets: res.count });
            }
          }
        );

        allPlfRequests(
          { status: 'pending', limit: 0, useCallback: true },
          (err, res) => {
            this.updateNotifications();
            if (!err) {
              this.setState({ newPlfRequests: res.count });
            }
          }
        );
      }

      if (account.type === 'lawyer') {
        if (account.lawyer === 'vlf') {
          allCalls(
            { is_valid: true, status: 'accepted', limit: 0, useCallback: true },
            (err, res) => {
              this.updateNotifications();
              if (!err) {
                this.setState({ newLawyerCalls: res.count });
              }
            }
          );

          allTickets(
            { status: 'accepted', unread: true, limit: 0, useCallback: true },
            (err, res) => {
              this.updateNotifications();
              if (!err) {
                this.setState({ newLawyerTickets: res.count });
              }
            }
          );
        }

        if (account.lawyer === 'plf') {
          allPlfRequests(
            { status: 'accepted', unread: true, limit: 0, useCallback: true },
            (err, res) => {
              this.updateNotifications();
              if (!err) {
                this.setState({ newLawyerPlfRequests: res.count });
              }
            }
          );
        }
      }

      if (/admin|guest/.test(account.type)) {
        allTickets(
          {
            status: 'accepted',
            owner: account._id,
            unread: true,
            limit: 0,
            useCallback: true,
          },
          (err, res) => {
            this.updateNotifications();
            if (!err) {
              this.setState({ newTicketMessage: res.count });
            }
          }
        );
      }
    } else {
      // setTimeout(() => this.fetchNotifications(), 10000);
    }
  };

  updateNotifications = () => {
    this.count++;
    let limit = 1;
    if (account.type === 'admin') {
      limit = 4;
    }

    if (account.type === 'lawyer') {
      limit = 2;
      if (account.lawyer === 'plf') {
        limit = 1;
      }
    }

    if (this.count === limit) {
      setTimeout(() => this.fetchNotifications(), 15000);
    }
  };

  logout = () => {
    localStorage.removeItem('faad');
    this.props.logout();
  };

  renderMenu() {
    const { newTicketMessage } = this.state;

    return this.userLinks.map((menu, idx) => (
      <Menu key={idx} className={menu.className}>
        <Link to={`/${menu.route}`} key={idx}>
          <MenuItem
            text={
              <span>
                <span>{menu.name}</span>
                {menu.route === 'tickets' && (
                  <b className="pull-right">
                    ({numberAbbreviate(newTicketMessage)})
                  </b>
                )}
              </span>
            }
            onClick={menu.onClick}
            iconName={menu.icon}
          />
        </Link>
      </Menu>
    ));
  }

  renderGuestMobileMenu() {
    return (
      <Menu>
        <Link to="/documents">
          <Button className="pt-minimal xs_hidden" text="My Agreements" />
        </Link>
        <Link to="/calls">
          <Button className="pt-minimal xs_hidden" text="Consultations" />
        </Link>
        <Link to="/registrations">
          <Button className="pt-minimal xs_hidden" text="My Registrations" />
        </Link>
        <Link to="/account/subscription">
          <Button className="pt-minimal xs_hidden" text="Subscriptions" />
        </Link>
        <Link to="/tickets">
          <Button className="pt-minimal xs_hidden" text="Other Services" />
        </Link>
        <Link to="/account">
          <Button className="pt-minimal xs_hidden" text="My Account" />
        </Link>
        <Link to="/">
          <Button
            className="pt-minimal xs_hidden"
            text="Log Out "
            onClick={this.logout}
          />
        </Link>
      </Menu>
    );
  }

  renderRegistrations() {
    const {
      vlfCategory: { items },
    } = this.props.state;
    let registrations_filter = [
      'Business Name Registration',
      'Company Incorporation',
      'Patent Registration',
      'NGO/Incoporated Trustee Registration',
      'Trademark Registration',
      'Copyright Registration',
      'Tin Registration',
    ];
    var registrations = items.filter(function (item) {
      return registrations_filter.indexOf(item.name) !== -1;
    });
    return registrations.map((item, idx) => (
      <Menu key={idx}>
        <Link to={`/tickets?message=%20&category=${item._id}`}>
          <MenuItem
            text={
              <div>
                <span>{item.name}</span>
              </div>
            }
            iconName={item.icon}
          />
        </Link>
      </Menu>
    ));
  }

  renderOtherServices() {
    const {
      vlfCategory: { items },
    } = this.props.state;
    let services_filter = [
      'Business Name Registration',
      'Company Incorporation',
      'Patent Registration',
      'NGO/Incoporated Trustee Registration',
      'Trademark Registration',
      'Copyright Registration',
      'Tin Registration',
    ];
    var services = items.filter(function (item) {
      return services_filter.indexOf(item.name) === -1;
    });
    return services.map((item, idx) => (
      <Menu key={idx}>
        <Link to={`/tickets?message=%20&category=${item._id}`}>
          <MenuItem
            text={
              <div>
                <span>{item.name}</span>
              </div>
            }
            iconName={item.icon}
          />
        </Link>
      </Menu>
    ));
  }

  renderVlfMenu() {
    const {
      vlfCategory: { items },
    } = this.props.state;
    return items
      .filter((i) => i.name !== 'Bespoke/Customized Agreement')
      .map((item, idx) => (
        <Menu key={idx}>
          <Link to={`/tickets?message=%20&category=${item._id}`}>
            <MenuItem
              text={
                <div>
                  <span>{item.name}</span>
                </div>
              }
              iconName={item.icon}
            />
          </Link>
        </Menu>
      ));
  }

  renderAgreementMenu() {
    const {
      vlfCategory: { items },
    } = this.props.state;
    const menu = items
      .filter((i) => i.price === 0 && i.name === 'Bespoke/Customized Agreement')
      .map((item, idx) => (
        <Menu key={idx}>
          <Link to={`/tickets?message=%20&category=${item._id}`}>
            <MenuItem
              text={
                <div>
                  <span>{item.name}</span>
                </div>
              }
            />
          </Link>
        </Menu>
      ));
    menu.unshift(
      <Menu key={menu.length}>
        <Link to={`/documents`}>
          <MenuItem
            text={
              <div>
                <span>Standard Agreements</span>
              </div>
            }
          />
        </Link>
      </Menu>
    );
    return menu;
  }

  renderCallMenu() {
    const {
      vlfCategory: { items },
    } = this.props.state;
    const menu = items
      .filter((i) => i.name.indexOf('Chat') === 0)
      .map((item, idx) => (
        <Menu key={idx}>
          <Link to={`/tickets?message=%20&category=${item._id}`}>
            <MenuItem
              text={
                <div>
                  <span>{item.name}</span>
                </div>
              }
              iconName={item.icon || 'chat'}
            />
          </Link>
        </Menu>
      ));
    menu.push(
      <Menu key={menu.length}>
        <Link to={`/calls`}>
          <MenuItem
            text={
              <div>
                <span>Call a Lawyer</span>
              </div>
            }
            iconName="phone"
          />
        </Link>
      </Menu>
    );

    return menu;
  }

  renderAdminMenu() {
    const { newCalls, newTickets, newPlfRequests } = this.state;
    return this.adminLinks.map((menu, idx) => (
      <Menu key={idx}>
        <Link to={`/admin/${menu.route}`}>
          <MenuItem
            text={
              <div>
                <span>{menu.name}</span>
                {menu.route === 'calls' && (
                  <b className="pull-right">({numberAbbreviate(newCalls)})</b>
                )}
                {menu.route === 'tickets' && (
                  <b className="pull-right">({numberAbbreviate(newTickets)})</b>
                )}
                {menu.route === 'plf-requests' && (
                  <b className="pull-right">
                    ({numberAbbreviate(newPlfRequests)})
                  </b>
                )}
              </div>
            }
            iconName={menu.icon}
          />
        </Link>
      </Menu>
    ));
  }

  renderLawyerMenu() {
    const { account } = this.props.state.user;
    const { newLawyerCalls, newLawyerTickets, newPlfRequests } = this.state;
    return this.lawyerLinks.map((menu, idx) => {
      if (/lawyer/.test(account.type)) {
        if (account.lawyer === 'vlf' && idx === 2) {
          return null;
        }
        if (account.lawyer === 'plf' && idx < 2) {
          return null;
        }
      }

      return (
        <Menu key={idx}>
          <Link to={`/lawyer/${menu.route}`}>
            <MenuItem
              text={
                <div>
                  <span>{menu.name}</span>
                  {menu.route === 'calls' && (
                    <b className="pull-right">
                      ({numberAbbreviate(newLawyerCalls)})
                    </b>
                  )}
                  {menu.route === 'tickets' && (
                    <b className="pull-right">
                      ({numberAbbreviate(newLawyerTickets)})
                    </b>
                  )}
                  {menu.route === 'plf-requests' && (
                    <b className="pull-right">
                      ({numberAbbreviate(newPlfRequests)})
                    </b>
                  )}
                </div>
              }
              iconName={menu.icon}
            />
          </Link>
        </Menu>
      );
    });
  }

  renderSubscription = () => {
    const {
      subscription: { item, packages },
    } = this.props.state;
    if (item && item.active && packages) {
      const activePackage = packages[item.type];
      return services.map(({ icon, type }, idx) => {
        const key = `${type}Count`;
        const plural = type === 'plf' ? 'plf-requests' : inflect(type, 2);
        item[key] = item[key] || 0;
        const remaining = activePackage[key] - item[key];
        return (
          <div key={idx} className="header-icon">
            <Popover
              inheritDarkTheme={false}
              interactionKind={PopoverInteractionKind.HOVER}
              content={
                <div className="p-a-2">
                  You have used {item[key]} out of {activePackage[key]} {plural}
                </div>
              }
              position={Position.BOTTOM}
            >
              <Link to={`/${plural}`}>
                <div>
                  <Icon name={icon} />
                  {remaining}
                </div>
              </Link>
            </Popover>
          </div>
        );
      });
    }

    return null;
  };

  renderLinks = (location, idx) => {
    return (
      <span key={idx}>
        <Link
          to={location.uri}
          className="pt-button pt-minimal"
          activeClassName="pt-intent-primary pt-active"
        >
          {location.name}
        </Link>
        &nbsp;&nbsp;&nbsp;
      </span>
    );
  };

  render() {
    const {
      hide,
      state: {
        user: { account },
      },
      showSignupModal,
      showSigninModal,
    } = this.props;
    const {
      newCalls,
      newTickets,
      newLawyerCalls,
      newLawyerTickets,
      newTicketMessage,
      newLawyerPlfRequests,
      newPlfRequests,
    } = this.state;

    const newItems = newCalls + newTickets + newPlfRequests;
    const newLawyerItems =
      newLawyerCalls + newLawyerTickets + newLawyerPlfRequests;
    const isGuest = account ? account.type === 'guest' : false;

    const loggedOutMenu = (
      <div className="pt-navbar-group pt-align-right">
        <button
          onClick={showSigninModal.bind(this, {})}
          type="button"
          className="m-l-1 pt-button pt-inverse"
        >
          Sign in
        </button>
        <button
          onClick={showSignupModal.bind(this, {})}
          type="button"
          className="m-l-1 pt-button pt-inverse"
        >
          Sign up
        </button>
      </div>
    );

    const mainMenu = (
      <React.Fragment>
        {isGuest && <div>{/* {this.renderSubscription()} */}</div>}
        {account && account.type === 'admin' && (
          <Popover
            content={this.renderAdminMenu()}
            position={Position.BOTTOM}
            autoFocus={false}
            inline={true}
          >
            <Button
              className="pt-minimal notification-button"
              iconName="lock"
              rightIconName="caret-down"
              text={
                <span>
                  <span>Admin</span>
                  {newItems > 0 && (
                    <span className="notification">
                      {numberAbbreviate(newItems)}
                    </span>
                  )}
                </span>
              }
            />
          </Popover>
        )}

        <div className="d-none d-lg-flex">
          {isGuest && (
            <Popover
              content={this.renderAgreementMenu()}
              position={Position.BOTTOM_LEFT}
              interactionKind={PopoverInteractionKind.HOVER}
              inline={true}
            >
              <Link to="/documents">
                <Button
                  className="pt-minimal xs_hidden"
                  iconName="document"
                  text="My Agreements"
                />
              </Link>
            </Popover>
          )}

          {isGuest && (
            <Popover
              content={this.renderCallMenu()}
              position={Position.BOTTOM}
              interactionKind={PopoverInteractionKind.HOVER}
              inline={true}
            >
              <Link to="/calls">
                <Button
                  className="pt-minimal xs_hidden"
                  iconName="phone"
                  text="Consultation"
                />
              </Link>
            </Popover>
          )}

          {isGuest && (
            <Popover
              content={this.renderRegistrations()}
              position={Position.BOTTOM}
              interactionKind={PopoverInteractionKind.HOVER}
              inline={true}
            >
              <Link to="/registrations">
                <Button
                  className="pt-minimal xs_hidden"
                  iconName="label"
                  text="Registrations"
                />
              </Link>
            </Popover>
          )}

          {isGuest && (
            <Popover
              // content={this.renderOtherServices()}
              position={Position.BOTTOM}
              interactionKind={PopoverInteractionKind.HOVER}
              inline={true}
            >
              <Link to="/account/subscription">
                <Button
                  className="pt-minimal xs_hidden"
                  iconName="label"
                  text="Subscriptions"
                />
              </Link>
            </Popover>
          )}

          {isGuest && (
            <Popover
              content={this.renderOtherServices()}
              position={Position.BOTTOM}
              interactionKind={PopoverInteractionKind.HOVER}
              inline={true}
            >
              <Link to="/tickets">
                <Button
                  className="pt-minimal xs_hidden"
                  iconName="label"
                  text="Other Services"
                />
              </Link>
            </Popover>
          )}
        </div>

        {/admin|lawyer/.test(account && account.type) && (
          <Popover
            content={this.renderLawyerMenu()}
            position={Position.BOTTOM}
            autoFocus={false}
            inline={true}
          >
            <Button
              className="pt-minimal notification-button"
              iconName="take-action"
              rightIconName="caret-down"
              text={
                <span>
                  <span>Lawyer</span>
                  {newLawyerItems > 0 && (
                    <span className="notification">
                      {numberAbbreviate(newLawyerItems)}
                    </span>
                  )}
                </span>
              }
            />
          </Popover>
        )}
        {isGuest && <span className="pt-navbar-divider" />}
      </React.Fragment>
    );
    const loggedInMenu = (
      <React.Fragment>
        <div className="pt-navbar-group pt-align-right d-none d-md-flex">
          {mainMenu}
          <Popover
            content={this.renderMenu()}
            position={Position.BOTTOM}
            autoFocus={false}
            inline={true}
          >
            <Button
              className="pt-minimal notification-button"
              iconName="user"
              rightIconName="caret-down"
              text={
                <span>
                  <span>Hi {account && account.name.split(' ')[0]}</span>
                  {newTicketMessage > 0 && (
                    <span className="notification">
                      {numberSummarize(newTicketMessage)}
                    </span>
                  )}
                </span>
              }
            />
          </Popover>
        </div>
        <div className="pt-navbar-group pt-align-right d-flex-xs d-md-none">
          <Popover
            content={this.renderGuestMobileMenu()}
            position={Position.BOTTOM}
            autoFocus={false}
            inline={true}
          >
            <Button
              className="pt-minimal notification-button"
              iconName="user"
              rightIconName="caret-down"
              text={
                <span>
                  <span>Hi {account && account.name.split(' ')[0]}</span>
                  {newTicketMessage > 0 && (
                    <span className="notification">
                      {numberSummarize(newTicketMessage)}
                    </span>
                  )}
                </span>
              }
            />
          </Popover>
        </div>
      </React.Fragment>
    );

    return (
      <header style={hide ? { display: 'none' } : {}}>
        <nav className="pt-navbar pt-dark ">
          <div className="nav-content-width">
            <div className="pt-navbar-group pt-align-left co-nav">
              <Link
                to={
                  account
                    ? account.type == 'servicepartner'
                      ? '/service-partner-dashboard'
                      : '/'
                    : '/'
                }
                activeClassName="pt-intent-primary pt-active"
              >
                <AppTitle />
              </Link>
            </div>
            {!account && loggedOutMenu}
            {account && loggedInMenu}
          </div>
        </nav>
      </header>
    );
  }
}

export default connect(
  (state) => ({ state: state }),
  (dispatch) => ({
    account: (_id, callback) => dispatch(account(_id, callback)),
    allCalls: (...args) => dispatch(allCalls(...args)),
    allPlfRequests: (...args) => dispatch(allPlfRequests(...args)),
    allVlfCategories: (...args) => dispatch(allVlfCategories(...args)),
    allTickets: (...args) => dispatch(allTickets(...args)),
    showSignupModal: (params) => dispatch(showSignupModal(params)),
    showSigninModal: (params) => dispatch(showSigninModal(params)),
    logout: (callback) => dispatch(logout(callback)),
    packages: (callback) => dispatch(packages(callback)),
    pricing: (callback) => dispatch(pricing(callback)),
    mySubscription: (_id) => dispatch(mySubscription(_id)),
  })
)(withRouter(Header));
