import 'whatwg-fetch';
import inflection from 'inflection';
import _ from 'lodash';

import { apiURL } from '../constants';
const getHeaders = () => {
  const user = localStorage.getItem('user');
  const session = localStorage.getItem('session');

  return {
    // 'Accept': 'application/json',
    // 'Content-Type': 'application/json',
    user,
    session
  };
};


const hooks = {
  onResponse: [],
  onError: []
};

function _fetch(method, url, args = {}) {
  return new Promise((resolve, reject) => {
    // const headers = args.headers || {};
    const headers = getHeaders();
    const _args = _.merge(args, {
      headers: _.merge({
        Accept: '*/*'
      }, headers)
    }, {
        method: method,
        credentials: 'same-origin'
      });

    let queryUrl;
    if (_args.params) {
      const params = _.map(_args.params, (v, k) => { return `${k}=${v}` });
      queryUrl = [url, params.join('&')].join('?');
    }

    if (_args.json) {
      _args.headers['Accept'] = 'application/json';
      _args.headers['Content-type'] = 'application/json';
      if (_args.json !== true) {
        _args.body = JSON.stringify(_args.json);
      }
      delete _args.json;
    }

    if (_args.contentType) {
      _args.headers['Content-type'] = _args.contentType;
    }

    if (_args.file) {
      const data = new FormData();
      data.append('file', _args.file);
      data.append('path', _args.path);
      _args.body = data;
      delete _args.file;
    }

    const resolvedUrl = [apiURL, queryUrl || url].join('');
    fetch(resolvedUrl, _args).then((response) => {
      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.substring(0, 16) === 'application/json') {
        response.json().then((json) => {
          resolve({ response: response, json: json });
          for (let idx = 0; idx < hooks.onResponse; idx++) {
            hooks.onResponse[idx]({ response: response, json: json });
          }
        });
      } else {
        resolve({ response: response, json: {} });
        for (let idx = 0; idx < hooks.onResponse; idx++) {
          hooks.onResponse[idx]({ response: response, json: {} });
        }
      }
    }).catch((err) => {
      reject(err);
      for (let idx = 0; idx < hooks.onError; idx++) {
        hooks.onError[idx](err);
      }
    });
  });
}

export const get = (url, args) => {
  return _fetch('GET', url, args);
};

export const post = (url, args) => {
  return _fetch('POST', url, args);
};

export const put = (url, args) => {
  return _fetch('PUT', url, args);
};

export const patch = (url, args) => {
  return _fetch('PATCH', url, args);
};

export const delete_ = (url, args) => {
  return _fetch('DELETE', url, args);
};

export default {
  onResponse(callback) {
    hooks.onResponse.push(callback);
  },

  onError(callback) {
    hooks.onError.push(callback);
  },

  get,

  post,

  put,

  patch,

  delete: delete_,
  postResource(name, data) {
    const url = `/api/${inflection.pluralize(name)}`;
    return this.post(url, { json: { [name]: data } })
  },

  patchResource(name, id, data, root = {}) {
    const url = `/api/${inflection.pluralize(name)}/${id}`;
    return this.patch(url, { json: Object.assign({}, root, { [name]: data }) });
  },

  deleteResource(name, id, root = {}) {
    const url = `/api/${inflection.pluralize(name)}/${id}`;
    return this.delete(url);
  }
}
