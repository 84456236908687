const session = window.localStorage.getItem("session");
const user = window.localStorage.getItem("user");
const initialState = {
  session: session ? { _id: session, user } : null,
  user,
  isLoading: false
};

export function sessionReducer(state = initialState, action) {
  const { data, session, type, error } = action;
  switch (type) {
    case "SESSION_ERROR":
      return Object.assign({}, state, {
        isLoading: false,
        error
      });

    case "SESSION_LOGIN":
      return Object.assign({}, state, {
        isLoading: true,
        error: null
      });
    case "SESSION_LOGIN_OK":
      window.localStorage.setItem("session", session._id);
      window.localStorage.setItem("user", session.user);
      return Object.assign({}, state, {
        session,
        user: session.user,
        isLoading: false
      });

    case "SESSION_LOGOUT":
      return Object.assign({}, state, {
        session: null,
        isLoading: true,
        error: null
      });
    case "SESSION_LOGOUT_OK":
      window.localStorage.clear();
      return Object.assign({}, state, {
        session,
        isLoading: false
      });
    case "SERVICE_PARTNER_SEND_AUTH_CODE":
      return Object.assign({}, state, {
        isLoading: true,
        error: null
      });
    case "AUTH_CODE_OK":
      return Object.assign({}, state, {
        isLoading: false,
        error: null
      });
    default:
      return state;
  }
}
