import React from 'react';
import { connect } from 'react-redux'
import { Button, Dialog } from "@blueprintjs/core";
import { closeModal } from '../../actions/modalActions';

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen
    };
  }

  render() {
    const {
      closeModal,
      params: {
      title = 'Confirm Dialog',
        message = 'Are you sure you want to continue?',
        buttons = [{ label: 'Continue', intent: 'primary' }]
    } } = this.props;

    return <Dialog
      iconName="inbox"
      isOpen={true}
      onClose={closeModal}
      className="ConfirmModal"
    >
      <div className="pt-dialog-body text-center">
        <h3 className="slim">{title}</h3>
        {message}
      </div>
      <div className="pt-dialog-footer">
        <div className="text-center">
          <Button text="Close" onClick={closeModal} />
          {
            buttons.map(
              ({ label='Continue', action, noClose, intent = 'primary' }, idx) =>
                <button className={`pt-button pt-intent-${intent}`} key={idx} onClick={() => { action && action(); !noClose && closeModal(); }}>{label}</button>)
          }
        </div>
      </div>
    </Dialog>;
  }
}

export default connect(
  state => ({ state: state }),
  dispatch => ({
    closeModal: (replace) => dispatch(closeModal(replace)),
  })
)(ConfirmModal);
