import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ticketTypes } from '../../data';
import { Switch } from '@blueprintjs/core';
import FileUploader from './FileUploader';
import Icon from './Icon';
import { currency } from '../../lib/utils';

export default class PLFRequestPanel extends React.Component {
  static defaultProps = {
    title: 'New PLF Request',
    canSubmit: true,
    submitText: 'Submit PLF Request',
    noSubject: true,
    canChangeStatus: false,
    plf: {
      description: '',
      files: [],
    },
  };

  static propTypes = {
    title: PropTypes.string,
    submitText: PropTypes.string,
    canSubmit: PropTypes.bool,
    noSubject: PropTypes.bool,
    canChangeStatus: PropTypes.bool,
    plfCategories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        _id: PropTypes.string,
      })).isRequired,
    plf: PropTypes.shape({
      subject: PropTypes.string,
      _id: PropTypes.string,
      type: PropTypes.string,
      files: PropTypes.arrayOf(PropTypes.string),
    }),
    submit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    props.plf.files = props.plf.files || [];
    if (!~props.plf.files.indexOf(null)) {
      props.plf.files.push(null, null);
    }

    this.state = {
      plf: props.plf,
    };
  }

  handleRemoveFile = (idx) => {
    const { plf } = this.state;
    plf.files.splice(idx, 1);
    this.setState({ plf });
  };

  handleChange = (e) => {
    const { name, value, checked } = e.target || e.srcElement;
    const plf = this.state.plf;
    plf[name] = value;
    switch (name) {
      case 'status':
        plf.status = checked ? 'closed' : 'open';
        break;
      default:
        plf[name] = value;
    }

    this.setState({ plf });
  };


  handleSubmit = (e) => {
    e.preventDefault();
    const { submit, canChangeStatus, noSubject, plfCategories } = this.props;
    const { plf, isClosed } = this.state;
    if (!canChangeStatus) {
      delete plf.status;
    }

    if (!noSubject && plfCategories.length) {
      plf.plf_cateogry = plf.plf_cateogry || plfCategories[0]._id;
    }


    plf.plf_category_name = plfCategories.filter(i => i._id === plf.plf_category)[0].name;

    submit(plf, (err) => {
      if (!err) {
        if (this.refs.description) {
          this.refs.description.value = '';
        }

        this.setState({
          plf: {
            files: [null],
          }
        });
      }
    });
  };

  render() {
    const { title, cancel, canChangeStatus, createFile, noSubject, submitText, canSubmit, plfCategories } = this.props;
    const { plf, isClosed } = this.state;
    return <div className="pt-card pt-elevation-1">
      <h4>{title}</h4>
      {
        canChangeStatus &&
        <div className="pull-right">
          <Switch name="status" checked={plf.status === 'closed'} label="Close Ticket?" onChange={this.handleChange} />
        </div>
      }
      <div className="PLFRequestPanel">
        <form onSubmit={this.handleSubmit}>
          {
            !noSubject &&
            <p>
              <label className="pt-label">Subject</label>
              <input className="pt-input pt-fill" onChange={this.handleChange} defaultValue={plf.name} name="subject" type="text" ref="subject" placeholder="Enter plf subject" required />
            </p>
          }
          <div className="row">
            <div className="col_md_30 col_xs_100">
              <label className="pt-label">Subject</label>
              <input defaultValue={plf.subject} ref="subject" className="pt-input pt-fill" onChange={this.handleChange} name="subject" required />
            </div>
            <div className="col_md_5 col_xs_0" />
            <div className="col_md_30 col_xs_100">
              <label className="pt-label">Type of Request</label>
              <p className="pt-select pt-fill">
                <select placeholder="Select category" onChange={this.handleChange} defaultValue={plf.plf_cateogry} className="pt-fill" ref="plf_category" name="plf_category" required>
                  <option label="Select one:" />
                  {plfCategories.map((category, idx) => <option key={idx} label={category.name} value={category._id}>{category.name} {currency(category.price)}</option>)}
                </select>
              </p>
            </div>
            <div className="col_md_5 col_xs_0" />
            <div className="col_md_30 col_xs_100">
              <label className="pt-label">Location (City, State)</label>
              <input defaultValue={plf.location} ref="location" className="pt-input pt-fill" onChange={this.handleChange} name="location" placeholder="e.g Surulere, Lagos" required />
            </div>
          </div>
          <p>
            <label className="pt-label">Description</label>
            <textarea defaultValue={plf.description} ref="description" rows={3} className="pt-input pt-fill" onChange={this.handleChange} name="description" placeholder="Enter plf description" required />
          </p>
          <div className="">
            <label className="pt-label">Attach Files</label>
            <div>
              {
                plf.files.map((file, idx) => {
                  let isUploading = false;
                  return <div key={idx} className="file-upload-box">
                    <FileUploader path='plf/' create={createFile} file={file} onUpload={(err, res) => {
                      if (!err) {
                        plf.files[idx] = res.url;
                        if (plf.files.length <= 5) {
                          plf.files.push(null);
                        }

                        this.setState({ plf });
                      }
                    }} />
                    {
                      file &&
                      <div>
                        <a className="remove" onClick={() => this.handleRemoveFile(idx)}><Icon name="remove_circle" /></a>
                      </div>
                    }
                  </div>
                })
              }
            </div>
          </div>
          <p>
            <button disabled={!canSubmit} className="pt-button pt-intent-primary pull-right">{submitText}</button>
            {cancel && <button type="button" className="pt-button pt-intent-danger" onClick={cancel}>Cancel</button>}
          </p>
          <div className="clearfix" />
        </form>
      </div>
    </div>;
  }
}