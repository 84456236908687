const key = 'plfCategories';

import { connect } from 'react-redux';
import React from 'react';
import Table from '../../../components/widgets/etable/custom';
import PageComponent from '../../../components/PageComponent';
import Props from '../../../components/widgets/etable/props';
import { Link } from 'react-router';
import { injectReducer } from '../../../store/reducers';
import reducer, * as actions from './modules';
import { currency } from '../../../lib/utils';

class PLFCategories extends PageComponent {
  getAuthType = () => ['admin'];
  static propTypes = Props;
  renderPage() {
    const options = Object.assign({}, this.props, {
      editKey: 'admin/plf-categories',
      legend: [{
        key: 'name',
        label: 'Name'
      }, {
        key: 'price',
        className: 'text-muted',
        fn: i => i ? `${currency(i)}` : '—',
        label: 'Price'
      }, {
        key: 'active',
        className: 'text-muted',
        fn: active => active ? 'Yes' : 'No',
        label: 'Active'
      }]
    });

    return <section className="pad_2 content-width">
      <h2 className="m-b-2">PLF Categories</h2>
      {React.createFactory(Table)(options)}
    </section>;
  }
};

export default store =>
  ({
    path: `/admin/plf-categories`,
    getComponent(next, cb) {
      injectReducer(store, { key, reducer });
      cb(
        null,
        connect(state => ({ ...state, ...state[key] }), actions)(PLFCategories)
      );
    }
  });

