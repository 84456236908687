import { post } from './request';

export function create(data, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'FILE_CREATE'
    });

    post(`file`, data).then(
      result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'FILE_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch({ type: 'FILE_CREATE_OK', call: result.json });
        }
    }).catch(error => dispatch({ type: 'FILE_CREATE_ERROR', error }));
  };
}