/**
 * http://momentjs.com/docs/
 */

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router';
import { Alert, Button, Checkbox, Dialog, Intent, Tab2, Tabs2 } from '@blueprintjs/core';
import Props from './props.js';
import './index.scss';

class Table extends React.Component {
  static propTypes = Props

  constructor(props) {
    super(props);

    props.event_search();
  }

  event_stop(event) {
    event.stopPropagation();
  }

  render_overlays() {
    return <div>
      <Alert
        isOpen={this.props.is_invalid_delete_alert_open}
        confirmButtonText="Okay"
        onConfirm={this.props.event_confirm_invalid_delete_alert}
      >
        You must select at least one record to delete.
      </Alert>
      <Alert
        isOpen={this.props.is_valid_delete_alert_open}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        onConfirm={this.props.event_confirm_valid_delete_alert}
        onCancel={this.props.event_cancel_valid_delete_alert}
      >
        Are you sure you want to delete {this.props.selected.length} record{this.props.selected.length > 1 ? "s" : null}?
      </Alert>
      <Alert
        isOpen={this.props.is_invalid_edit_alert_open}
        confirmButtonText="Okay"
        onConfirm={this.props.event_confirm_invalid_edit_alert}
      >
        You must select one record to edit.
      </Alert>
      <Dialog
        iconName={this.props.dialog_icon}
        isOpen={this.props.is_dialog_open}
        onClose={this.props.event_close_dialog}
        title={this.props.dialog_title}
      >
        <form onSubmit={this.props.event_save_dialog}>
          <div className="pt-dialog-body">
            {this.render_dialog()}
          </div>
          <div className="pt-dialog-footer">
            <div className="pt-dialog-footer-actions">
              <Button
                type="button"
                text="Cancel"
                onClick={this.props.event_close_dialog}
              />
              <Button
                type="submit"
                intent={Intent.PRIMARY}
                text="Save"
              />
            </div>
          </div>
        </form>
      </Dialog>
    </div>;
  }

  render_dialog() {
    let tabs = { _: [] };
    for (let key in this.props.item_props) {
      let prop = this.props.item_props[key];
      let value = this.props.item[key];

      if (prop.active && (!prop.disabled || typeof value != 'undefined')) {
        if (prop.tab && !tabs[prop.tab]) {
          tabs[prop.tab] = [];
        }
        if (prop.fn) {
          tabs[prop.tab || '_'].push(this.props[prop.fn](this.props.item));
        }
        else {
          switch (prop.type) {
            case 'float':
              prop.pattern = "[-+]?[0-9]*\.?[0-9]*";
            case 'string':
              tabs[prop.tab || '_'].push(<label className="pt-label">
                {prop.label}
                <br /><br />
                <input
                  type="text"
                  className="pt-input pt-fill"
                  name={key}
                  placeholder={prop.placeholder || prop.label}
                  required={prop.required}
                  disabled={prop.disabled}
                  pattern={prop.pattern}
                  defaultValue={value}
                />
                <br />
              </label>);
              break;

            case 'textarea':
              tabs[prop.tab || '_'].push(<label className="pt-label">
                {prop.label}
                <br /><br />
                <textarea
                  type="text"
                  className="pt-input pt-fill"
                  name={key}
                  placeholder={prop.placeholder || prop.label}
                  required={prop.required}
                  disabled={prop.disabled}
                  pattern={prop.pattern}
                  defaultValue={value}
                />
                <br />
              </label>);
              break;

            case 'date':
              tabs[prop.tab || '_'].push(<label className="pt-label">
                {prop.label}
                <br /><br />
                <input
                  type="text"
                  className="pt-input pt-fill"
                  name={key}
                  placeholder={prop.placeholder || prop.label}
                  required={prop.required}
                  disabled={prop.disabled}
                  defaultValue={moment(value).format('MM/DD/YYYY hh:mm A')}
                />
                <br />
              </label>);
              break;

            case 'boolean':
              tabs[prop.tab || '_'].push(<label className="pt-label">
                {prop.label}
                <br /><br />
                <label className="pt-control pt-switch pt-large">
                  <input
                    type="checkbox"
                    name={key}
                    required={prop.required}
                    disabled={prop.disabled}
                    defaultChecked={value}
                  />
                  <span className="pt-control-indicator"></span>
                </label>
                <br />
              </label>);
              break;

            case 'enum':
              tabs[prop.tab || '_'].push(<label className="pt-label">
                {prop.label}
                <br /><br />
                <div className="pt-select">
                  <select
                    name={key}
                    required={prop.required}
                    disabled={prop.disabled}
                    defaultValue={value}
                  >
                    {prop.options.map(
                      o => <option key={o.value} value={o.value}>{o.label}</option>
                    )}
                  </select>
                </div>
                <br />
              </label>);
              break;

            case 'image':
              tabs[prop.tab || '_'].push(<label className="pt-label">
                {prop.label}
                <br /><br />
                <img src={value} id={`${key}-image`} style={{ maxWidth: '100%' }} />
                <br />
              </label>);
              break;

            case 'video':
              tabs[prop.tab || '_'].push(<label className="pt-label">
                {prop.label}
                <br /><br />
                <video controls="false" src={value} />
                <br />
              </label>);
              break;
          }
        }
      }
    }
    for (let label in tabs) {
      for (let i = 0; i < tabs[label].length; i += 2) {
        if (tabs[label][i] && tabs[label][i + 1]) {
          tabs[label][i] = <div className="row" key={`dialog_row_${i}`}>
            <div className="col_45">
              {tabs[label][i]}
            </div>
            <div className="col_10">&nbsp;</div>
            <div className="col_45">
              {tabs[label][i + 1]}
            </div>
          </div>;
          delete tabs[label][i + 1];
        }
        else {
          tabs[label][i] = <div className="row" key={`dialog_row_${i}`}>
            <div className="col_45">
              {tabs[label][i]}
            </div>
          </div>;
        }
      }
      tabs[label] = tabs[label].filter(i => !!i);
    }
    if (Object.keys(tabs).length > 1) {
      let body = [];
      for (let label in tabs) {
        if (tabs[label].length) {
          tabs[label].unshift(<br />);
          body.push(<Tab2 key={label} id={label} title={label} panel={tabs[label]} />);
        }
      }
      return <Tabs2 id={this.props.item._id + '_tabs'}>{body}</Tabs2>;
    }
    else {
      return Object.values(tabs);
    }
  }

  render_header() {
    return <div className="row">
      <div className="col_20">
        <div className="pt-input-group">
          <span className="pt-icon pt-icon-search"></span>
          <input
            className="pt-input"
            type="search"
            placeholder="Search"
            dir="auto"
            value={this.props.q}
            onChange={this.props.event_search}
          />
        </div>
      </div>
      <div className="col_20">
        <span className="pt-navbar-divider"></span>
        <span className="pt-tag pt-minimal pt-large">
          {this.props.items.count} entries found
        </span>
      </div>
      <div className="col_60 align_right">
        <Link to={`/${this.props.editKey}/new`}>
          <Button
            intent={Intent.PRIMARY}
            text="CREATE NEW"
          /></Link>
        {/*onClick={this.props.event_open_add_dialog}*/}
        &nbsp;&nbsp;&nbsp;
        <Button
          intent={Intent.DANGER}
          iconName="trash"
          text="Delete"
          onClick={this.props.event_open_delete_alert}
        />
      </div>
    </div>;
  }

  render_footer() {
    const pages = Math.ceil(this.props.items.count / this.props.limit);

    return <div className="row pt-pages">
      <div className="col_70">
        <div className="pt-button-group">
          {
            [10, 25, 50, 100, 200].map(limit =>
              <Button
                key={limit}
                type="button"
                className={(this.props.limit == limit ? "pt-active" : "")}
                text={limit}
                onClick={this.props.event_update_limit}
              />
            )
          }
        </div>
      </div>
      <div className="col_30 align_right">
        <Button
          type="button"
          iconName="double-chevron-left"
          onClick={this.props.event_update_page.bind(this, 1)}
        />
        &nbsp;&nbsp;
        <Button
          type="button"
          iconName="chevron-left"
          onClick={this.props.event_update_page.bind(this, this.props.page - 1)}
        />
        &nbsp;&nbsp;
        <input
          type="text"
          className="pt-input align_center page"
          placeholder="Page #"
          value={this.props.page}
          onChange={this.props.event_update_page.bind(this, null)}
        />
        &nbsp; of {pages} page{pages > 1 ? 's' : ''}
        &nbsp;&nbsp;
        <Button
          type="button"
          iconName="chevron-right"
          onClick={this.props.event_update_page.bind(this, this.props.page + 1)}
        />
        &nbsp;&nbsp;
        <Button
          type="button"
          iconName="double-chevron-right"
          onClick={this.props.event_update_page.bind(this, pages)}
        />
      </div>
    </div>;
  }

  render_thead() {
    return <thead>
      <tr>
        <th key="checkbox">
          <Checkbox
            onChange={this.props.event_toggle_all_checkboxes}
          />
        </th>
        {
          this.props.legend.map((item, idx) => <th key={idx}>{item.label}</th>)
        }
      </tr>
    </thead>;
  }

  render_tbody() {
    return <tbody>
      {
        (
          this.props.items.count
            ?
            this.props.items.items.map(item => {

              for (let key in item) {
                if (typeof item[key] == 'boolean') {
                  item[key] = (item[key] ? 'Yes' : 'No');
                }
              }
              return <tr
                key={item._id}

              >
                <td key={`${item._id}_checkbox`} onClick={this.event_stop}>
                  <Checkbox
                    checked={(this.props.selected.indexOf(item._id) > -1)}
                    onChange={this.props.event_toggle_checkbox.bind(this, item._id)}
                  />
                </td>

                {
                  this.props.legend.map(i =>
                    <td key={`${item._id}_${i.key}`} className={i.className}>
                      {
                        (i.fn ? i.fn(item[i.key]) : item[i.key]) || <span className="text-muted">N/A</span>
                      }
                    </td>
                  )
                }

                <td>
                  <span onClick={this.props.event_delete_item.bind(this, item._id)} className="pt-icon pt-icon-trash cursor_pointer text-color-primary"></span>
                  &nbsp;&nbsp;&nbsp;
                  <Link to={`/${this.props.editKey}/${item._id}`}>
                    <Button
                      intent={Intent.PRIMARY}
                      type="button"
                      text="EDIT"
                    />
                  </Link>
                </td>
              </tr>;
            })
            :
            <tr>
              <td colSpan={Object.keys(this.props.legend).length + 2}>
                No records found.
            </td>
            </tr>
        )
      }
    </tbody>;
  }

  render() {
    return <section className="Table">
      {this.render_overlays()}
      {this.render_header()}
      <br />
      <table className="pt-table pt-striped pt-interactive">
        {this.render_thead()}
        {this.render_tbody()}
      </table>
      <br /><br />
      {this.render_footer()}
    </section>;
  }
}

export default Table;
