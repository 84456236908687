import Toaster from '../components/widgets/Toaster';
import { Intent } from '@blueprintjs/core';
const initialState = {
  items: [],
  count: 0,
  item: null,
  account: JSON.parse(localStorage.getItem('account')),
  isLoading: false
};

export function userReducer(state = initialState, action) {
  const { users, user: item, account, type, error, password } = action;
  switch (type) {
    case 'USER_ERROR':
      // Toaster.error(error.message || error);
      return Object.assign({}, state, {
        isLoading: false,
        error
      });
    case 'USER_FORGOT_PASSWORD':
      return Object.assign({}, state, {
        isLoading: true,
        error: null
      });
    case 'USER_FORGOT_PASSWORD_OK':
      return Object.assign({}, state, {
        password,
        isLoading: false
      });
    case 'USER_SIGNUP':
      return Object.assign({}, state, {
        item: null,
        isLoading: true,
        error: null
      });
    case 'USER_SIGNUP_OK':
      return Object.assign({}, state, {
        item,
        isLoading: false
      });

    case 'USER_GET':
      return Object.assign({}, state, {
        item: null,
        isLoading: true,
        error: null
      });
    case 'USER_GET_OK':
      return Object.assign({}, state, {
        item,
        isLoading: false
      });
    case 'USER_ACCOUNT':
      return Object.assign({}, state, {
        item: null,
        isLoading: true,
        error: null
      });
    case 'USER_ACCOUNT_OK':
      window.localStorage.setItem('account', JSON.stringify(account));
      return Object.assign({}, state, {
        account,
        isLoading: false
      });

    case 'USERS_GET':
      return Object.assign({}, state, {
        items: [],
        count: 0,
        isLoading: true,
        error: null
      });
    case 'USERS_GET_OK':
      return Object.assign({}, state, {
        items: users.items,
        count: users.count,
        isLoading: false
      });

    default:
      return state;
  }
}
