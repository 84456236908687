import Core from '../layouts/core';
import Dashboard from './dashboard';
import DocumentTemplate from './admin/DocumentTemplate';
import Account from './account';
import DocumentTemplates from './admin/DocumentTemplates';
import LawyerCalls from './lawyer/LawyerCalls';
import LawyerTickets from './lawyer/LawyerTickets';
import LawyerPLF from './lawyer/LawyerPLF';
import DocumentCategories from './home/DocumentCategories';
import Documents from './home/Documents';
import Home from './home';
import Calls from './home/Calls';
import Tickets from './home/Tickets';
import Registrations from './home/Registrations';
import PLFRequests from './home/PLFRequests';
import Ticket from './home/Ticket';
import DocumentCreate from './home/DocumentCreate';
import Category from './admin/Category';
import Categories from './admin/Categories';
import VLFCategory from './admin/VLFCategory';
import VLFCategories from './admin/VLFCategories';
import PLFCategory from './admin/PLFCategory';
import PLFCategories from './admin/PLFCategories';
import Subcategory from './admin/Subcategory';
import AdminTickets from './admin/AdminTickets';
import AdminCalls from './admin/AdminCalls';
import AdminCoupons from './admin/Coupons';
import AdminSubscriptions from './admin/SubscriptionPackages';
import AdminCreateSubscriptions from './admin/SubscriptionPackages/create';
import AdminEditSubscriptions from './admin/SubscriptionPackages/edit';
import Subcategories from './admin/Subcategories';
import Users from './users';
import User from './user';
import AdminPLFs from './admin/AdminPLFs';
import PLFRequest from './home/PLFRequest';
import DocSeo from './home/DocSeo';
import TermsOfUser from './static/TermsOfUse';
import PrivacyPolicy from './static/PrivacyPolicy';
import BusinessStimulus from './static/promo/BusinessStimulus';
import FAQs from './static/FAQs';
import Blog from './static/Blog';
import LeruTerms from './static/LeruTerms';
import BlogPost from './static/BlogPost';
import Leru from './admin/Leru';
import Mylawya from './admin/Mylawya';
import JoinLeru from './leru/JoinLeru';
import LeruPartner from './leru/LeruPartner';
import LeruLogin from './leru/LeruLogin';
import UserProfile from './leru/user/UserProfile';
import LawyerProfile from './leru/lawyer/LawyerProfile';
import ForgotPassword from './leru/ForgotPassword';
import Mylawyer from './leru/Mylawyer';
import PasswordReset from './leru/PasswordReset';
import ServicePartnerTransactions from './admin/ServicePartnerTransactions/index';
import ServicePartners from './admin/ServicePartners/index';
import Volunteers from './admin/EndSars/index';
import Analytics from './admin/Analytics';

export const createRoutes = (store) => ({
  path: '/',
  component: Core,
  indexRoute: Home(store),
  childRoutes: [
    PrivacyPolicy(store),
    BusinessStimulus(store),
    FAQs(store),
    TermsOfUser(store),
    Blog(store),
    BlogPost(store),
    LeruTerms(store),
    JoinLeru(store),
    Mylawyer(store),
    LeruPartner(store),
    LeruLogin(store),
    ForgotPassword(store),
    PasswordReset(store),
    UserProfile(store),
    LawyerProfile(store),
    Dashboard(store),
    Home(store),
    DocSeo(store),
    DocumentCategories(store),
    DocumentCreate(0)(store),
    Account(0)(store),
    Account(1)(store),
    Account(2)(store),
    DocumentCreate(1)(store),
    Documents(store),
    Calls(store),
    Tickets(store),
    Registrations(store),
    PLFRequests(store),
    Ticket(store),
    PLFRequest(store),
    // Lawyer Routes //
    LawyerCalls(store),
    LawyerTickets(store),
    LawyerPLF(store),
    // Admin Routes //
    DocumentTemplate(store),
    DocumentTemplates(store),
    Leru(store),
    Mylawya(store),
    AdminCalls(store),
    AdminTickets(store),
    AdminPLFs(store),
    Category(store),
    Categories(store),
    AdminCoupons(store),
    AdminSubscriptions(store),
    AdminCreateSubscriptions(store),
    AdminEditSubscriptions(store),
    VLFCategory(store),
    VLFCategories(store),
    PLFCategory(store),
    PLFCategories(store),
    Subcategory(store),
    Subcategories(store),
    Users(store),
    User(store),
    ServicePartnerTransactions(store),
    ServicePartners(store),
    Volunteers(store),
    Analytics(store),
  ],
});

export default createRoutes;
