import React from 'react';
import PropTypes from 'prop-types';
export default {
  item: PropTypes.object.isRequired,
  item_props: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  q: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,

  dialog_icon: PropTypes.string.isRequired,
  dialog_title: PropTypes.string.isRequired,

  is_valid_delete_alert_open: PropTypes.bool.isRequired,
  is_invalid_delete_alert_open: PropTypes.bool.isRequired,
  is_invalid_edit_alert_open: PropTypes.bool.isRequired,
  is_dialog_open: PropTypes.bool.isRequired,

  event_search: PropTypes.func.isRequired,

  event_update_limit: PropTypes.func.isRequired,
  event_update_page: PropTypes.func.isRequired,

  event_toggle_checkbox: PropTypes.func.isRequired,
  event_toggle_all_checkboxes: PropTypes.func.isRequired,

  event_open_delete_alert: PropTypes.func.isRequired,
  event_cancel_valid_delete_alert: PropTypes.func.isRequired,
  event_confirm_invalid_delete_alert: PropTypes.func.isRequired,
  event_confirm_valid_delete_alert: PropTypes.func.isRequired,
  event_confirm_invalid_edit_alert: PropTypes.func.isRequired,

  event_open_add_dialog: PropTypes.func.isRequired,
  event_open_edit_dialog: PropTypes.func.isRequired,
  event_close_dialog: PropTypes.func.isRequired,
  event_save_dialog: PropTypes.func.isRequired
};
