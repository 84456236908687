import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/widgets/Spinner';
import Toaster from '../../components/widgets/Toaster';
import { Link, browserHistory } from 'react-router';
import Select from 'react-select';
import Modal from 'react-modal';
import axios from 'axios';


class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        e_addr: '',
        notLoading: true
    };
  }


  componentWillMount() {

  }

 

  handleChange = (e) => {
    const { name, value, checked } = e.target || e.srcElement;


    switch(name) {
        
        case 'e_addr':
            this.setState({e_addr: value});
            break;
            break;
    }
  }
 

  
  handleLawyerSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0,0);
    const { e_addr} = this.state;
    this.setState({notLoading: false});

 
    if(e_addr == "" || e_addr == " "){
      return false;
    }
    localStorage.setItem("em_law_reset",e_addr);
    
    const data = {
     email_addr : e_addr
  };
  const that = this;
  axios({
    url: 'https://cors-anywhere.herokuapp.com/https://immense-tor-56766.herokuapp.com/api/lawyer/forgotpassword_reset.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  }).then(function(res){
    console.log(res);
    console.log(res.status);
    that.setState({notLoading: true});
    if((res.status == 200) || (res.status== 201)) {
      console.log(res.status);
      Toaster.success("Password reset link sent to email");
      that.setState({notLoading: true});
    } else {
      console.log(res);
      Toaster.error("Network error");
    }
  }).catch(function(err){
    console.log(err)
    Toaster.error("Network Error");
    that.setState({notLoading: true});
  });
}
  
  render() {
    
    return (
      <section>
        
          <div className="w3-card-4 w3-center m-t-4" style={{width: '52%', height: '310px',  margin: '0 auto'}}>
            {this.state.notLoading?
              <div className=" m-t-4">
                <div className="w3-container w3-grey m-t-4">
                    <h1>Reset Your Password</h1>
                </div>

                <form className="w3-container m-t-4" method="POST" onSubmit={this.handleLawyerSubmit}>
                    <input type="email" style={{display: "none"}} />
                    <input type="password" style={{display: "none"}} />
                    <label className="w3-text-green"><b>Email address:</b></label>
                    <input type="email" pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/" className="w3-input w3-border w3-light-grey" onChange={this.handleChange} name="e_addr"  required/>
                    <input onClick = {this.handleLawyerSubmit} type="submit" value="Send Password reset link" className="w3-btn w3-green m-t-2" />
                </form> 
              </div>:
              <div>
                  <p>Password reset link sent to {this.state.e_addr}</p>
              </div>
            }
          </div>
      </section>
    );
  }
}

export default store =>
  ({
    path: `/forgotpassword`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({

          }))(ForgotPassword)
      );
    }
  });