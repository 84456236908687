import React from 'react';
import { connect } from 'react-redux';
import Toaster from '../widgets/Toaster';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import {
  closeModal,
  showSignupModal,
  showPasswordResetModal,
} from '../../actions/modalActions';
import { login } from '../../actions/sessionActions';
import { account } from '../../actions/userActions';

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: {},
    };
  }

  handleChange = e => {
    const { name, value } = e.target || e.srcElement;
    this.setState({ [name]: value });
  };

  componentDidUpdate() {
    // this.emailInput.focus();
  }

  handleSubmit = e => {
    e.preventDefault();
    const {
      state: { email, password },
      props: {
        login,
        closeModal,
        account,
        params: { callback },
      },
    } = this;

    login({ email, password }, (error, res) => {
      if (error) {
        Toaster.error(error.message);
        this.setState({ error });
      } else {
        setTimeout(
          () =>
            account(res.user, err => {
              if (!err) {
                closeModal();
                if (callback) {
                  callback();
                } else {
                  Toaster.success('Login successful');
                }
              } else {
                Toaster.error(err.message);
              }
            }),
          200
        );
      }
    });
  };

  render() {
    const {
      closeModal,
      showSignupModal,
      showPasswordResetModal,
      state: {
        session: { isLoading },
        user: { isLoading: isLoading2 },
      },
    } = this.props;
    const { email, password } = this.state;

    return (
      <Dialog isOpen={true} onClose={closeModal} className="LoginModal">
        <div className="pt-dialog-body text-center">
          <h2 className="slim">Signin to your account</h2>
          <form onSubmit={this.handleSubmit}>
            <p className="m-t-2">
              <label className="pt-label">
                <input
                  defaultValue={email}
                  placeholder="Email Address"
                  onChange={this.handleChange}
                  type="email"
                  className="pt-input pt-fill"
                  name="email"
                  required
                />
              </label>
            </p>
            <p>
              <label className="pt-label">
                <input
                  defaultValue={password}
                  placeholder="Password"
                  onChange={this.handleChange}
                  type="password"
                  className="pt-input pt-fill"
                  name="password"
                  required
                />
              </label>
            </p>
            <p>
              <a
                onClick={showPasswordResetModal}
                className="pointer pull-right"
              >
                Forgot Password?
              </a>
              <br />
            </p>
            <p>
              <button
                disabled={isLoading}
                className="pt-button pt-fill pt-intent-primary"
              >
                Sign In
              </button>
            </p>
            <div className="row m-t-4">
              <div className="col_20">
                <hr className="v-align middle" />
              </div>
              <div className="col_60">
                <h5 className="slim">Don't have an account?</h5>
              </div>
              <div className="col_20">
                <hr className="v-align middle" />
              </div>
            </div>
            <p className="m-t-2">
              <button
                disabled={isLoading || isLoading2}
                className="pt-button pt-fill"
                type="button"
                onClick={showSignupModal}
              >
                Create a new account
              </button>
            </p>
          </form>
        </div>
      </Dialog>
    );
  }
}

export default connect(
  state => ({ state: state }),
  dispatch => ({
    closeModal: replace => dispatch(closeModal(replace)),
    showSignupModal: params => dispatch(showSignupModal(params)),
    showPasswordResetModal: params => dispatch(showPasswordResetModal(params)),
    login: (json, callback) => dispatch(login(json, callback)),
    account: (_id, callback) => dispatch(account(_id, callback)),
  })
)(LoginModal);
