/* global localStorage */

import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import '../../styles/core.scss';
import Modals from '../modals';

export const Core = ({ children, params }) => (
  <div>
    <Header hide={!children.props.location.pathname} />
    <div className="core-layout">
      {children}
    </div>
    <Modals params={params} />
    <Footer />
  </div>
);

Core.propTypes = {
  children: PropTypes.element.isRequired
};

export default Core;
