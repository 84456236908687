const initialState = {
  items: [],
  count: 0,
  item: null,
  slots: [],
  isLoading: false
};

export function callReducer(state = initialState, action) {
  const { data, call: item, type, error } = action;
  switch (type) {
    case 'CALL_ERROR':
      return Object.assign({}, state, {
        isLoading: false,
        error
      });
    case 'CALL_SLOTS':
      return Object.assign({}, state, {
        isLoading: true,
      });
    case 'CALL_SLOTS_OK':
      return Object.assign({}, state, {
        slots: action.slots,
        isLoading: false,
      });
    case 'CALL_GET':
    case 'CALL_CREATE':
    case 'CALL_UPDATE':
      return Object.assign({}, state, {
        item: null,
        isLoading: true
      });
    case 'CALL_CREATE_OK':
    case 'CALL_UPDATE_OK':
    case 'CALL_GET_OK':
      return Object.assign({}, state, {
        item,
        isLoading: false
      });
    case 'CALLS_GET':
      return Object.assign({}, state, {
        items: [],
        count: 0,
        isLoading: true
      });
    case 'CALLS_OK':
      return Object.assign({}, state, {
        items: data.items,
        count: data.count,
        isLoading: false
      });

    default:
      return state;
  }
}
