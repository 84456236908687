import { Position, Toaster, Intent } from '@blueprintjs/core';

const toast = (message, intent, iconName) => {
  return Toaster.create({
    position: Position.TOP_RIGHT,
  }).show({
    message,
    intent,
    iconName
  });
};

export const error = message => {
  return toast(message, Intent.DANGER, 'error');
};

export const success = message => {
  return toast(message, Intent.SUCCESS, 'endorsed');
};

export const warn = message => {
  return toast(message, Intent.WARNING, 'error');
};

export const info = message => {
  return toast(message, Intent.PRIMARY, 'info');
};


export default {
  error,
  success,
  warn,
  info
};