import React, { Component } from 'react';
import { inflect } from 'inflection';
import Icon from './widgets/Icon';
import { browserHistory } from 'react-router';
export default class PageComponent extends Component {
  constructor(props) {
    super(props);
  }

  content(types) {
    const { showSigninModal} = this.props;
    return <div className="text-center p-a-4 page-content m-t-4" style={{ maxWidth: 700 }}>
      <Icon name="" className="m-a-4" style={{ fontSize: 50 }} />
      <Icon name="" className="m-a-4" style={{ fontSize: 50 }} />
      <Icon name="" className="m-a-4" style={{ fontSize: 50 }} />
      <Icon name="" className="m-a-4" style={{ fontSize: 50 }} />
      <Icon name="" className="m-a-4" style={{ fontSize: 50 }} />
      <br/>
      <h1>Kindly Login/Signup to Continue</h1>
      {showSigninModal && <button className="pt-button" onClick={() => showSigninModal()}>Login</button>}
    </div>
  }

  renderPageLayout() {
    const { props } = this;
    const params = {

    };
    return (
      <div>
        {this.renderPage()}
      </div>
    );
  }

  setTitle(title) {
    window.document.title = `${title} | ${window.APP_TITLE}`;
  }


  render() {
    const { state, showSigninModal, user } = this.props;
    const account = state && state.user ? state.user.account : user.account;
    const types = Array.isArray(this.getAuthType()) ? this.getAuthType() : [];
    const lawyerTypes = this.getLawyerType();
    if (
      !types.length ||
      (account &&
        (types.indexOf(account.type) > -1 || account.type === 'admin')
      )
    ) {
      if (
        account.type !== 'admin' &&
        types.indexOf('lawyer') > -1 &&
        types.length === 1 &&
        lawyerTypes.indexOf(account.lawyer) === -1
      ) {
        return this.content(types);
      }

      return this.renderPage();
    }


    if (types.length > 2) {
      types.splice(2, 1);
    }

    return this.content(types);
  }

  renderPage() { }
  getAuthType() { return ['guest']; }
  getLawyerType() { return ['plf']; }
}