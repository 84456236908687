import React, { Component } from 'react';
import Icon from './Icon';

export default class NoItem extends Component {
  render() {
    const { children, className } = this.props;
    return <div className="NoItem">
      <h5 className={`text-muted m-t-2 m-b-2 text-center ${className}`}>
        <div className="m-a-2"><Icon name="warning" size="large" /></div> <div className="m-a-2">{children}</div>
      </h5>
    </div>;
  }
}