
import { connect } from 'react-redux';
import React from 'react';
import Table from '../../../components/widgets/etable/custom';
import PageComponent from '../../../components/PageComponent';
import RenderLawyers from "../../../components/widgets/RenderLawyers";
import Props from '../../../components/widgets/etable/props';
import { Link } from 'react-router';
import { injectReducer } from '../../../store/reducers';
import axios from 'axios';
import Toaster from '../../../components/widgets/Toaster';

class Leru extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {
      user:[],
      lawyer:[],
      law: '',
      ticket:[],
      isVisible: false,
      isVerified: "Unverify"
    }
  }
  componentWillMount() {
        
    fetch('https://immense-tor-56766.herokuapp.com/api/user/read.php')
    .then(res => {
      console.log(res)
      return res.json();
    }).then(data=>{
      console.log(JSON.stringify(data))
      this.setState({user:  this.state.user.concat((data.records))})
      console.log(this.state.user);
    })
    
    this.fetchLawyers();
    //<RenderLawyers lawyer={law} id={law.id} onVerify={this.verify} verifyText={this.state.isVerified} onExpand = {this.expand} onUnverify={this.unverify} key={index}/>
  }

  fetchLawyers = () => {
    fetch('https://immense-tor-56766.herokuapp.com/api/lawyer/read.php')
    .then(res => {
      console.log(res)
      return res.json();
    }).then(data=>{
      console.log(JSON.stringify(data))
      this.setState({lawyer:  this.state.lawyer.concat((data.records))});
      console.log(this.state.lawyer);
    })
  }

  fetchLawyer = (id) => {
    fetch('https://immense-tor-56766.herokuapp.com/api/lawyer/read_one.php?id='+id)
    .then(res => {
      console.log(res)
      return res.json();
    }).then(data=>{
      this.setState({law:  data});
      console.log(this.state.law);
    })
  }

  expand = (e) => {
    const id = e.target.id;
    this.fetchLawyer(id);
    this.setState({isVisible: true});
  }

  unexpand = () => {
    this.setState({isVisible: false})
    this.setState({law: ""})
  }

  verify = (e) => {
    const ID = e.target.id;
    Toaster.info('waiting...');
    axios.get('https://cors-anywhere.herokuapp.com/https://immense-tor-56766.herokuapp.com/api/lawyer/verify_lawyer.php?id='+ID)
      .then(res => {
        Toaster.success('verified');
        this.setState({lawyer: []});
        this.fetchLawyers();
      });
    
  }

  unverify = (e) => {
    console.log(e.target.id);
  }

 



  getAuthType = () => ['admin'];

  render() {
    return (
      <section className="landing-page-section">
      <div className="landing-header">

      </div>
     



          <div className="w3-bar w3-top w3-black w3-large" style={{zIndex:'4'}}>
            <button className="w3-bar-item w3-button w3-hide-large w3-hover-none w3-hover-text-light-grey" onClick={this.handleSubmit}><i className="fa fa-bars"></i>Menu</button>
            <span className="w3-bar-item w3-right">LERU</span>
          </div>

          <nav className="w3-sidebar w3-collapse w3-white w3-animate-left" style={{zIndex:'3',width:"150px"}} id="mySidebar"><hr />
            <div className="w3-container w3-row">
              <div className="w3-col s4">
                <img src="/w3images/avatar2.png" className="w3-circle w3-margin-right" style={{width:"46px"}} />
              </div>
              <div className="w3-col s8 w3-bar">
                <span>Welcome</span><hr />
                <a href="#" className="w3-bar-item w3-button"><i className="fa fa-envelope"></i></a>
                <a href="#" className="w3-bar-item w3-button"><i className="fa fa-user"></i></a>
                <a href="#" className="w3-bar-item w3-button"><i className="fa fa-cog"></i></a>
              </div>
            </div>
            <hr />
            <div className="w3-container">
              <h5>Admin Area</h5>
            </div>
            <div className="w3-bar-block">
              <a href="#" className="w3-bar-item w3-button w3-padding-16 w3-hide-large w3-dark-grey w3-hover-black" onClick={this.handleSubmit} title="close menu"><i className="fa fa-remove fa-fw"></i>  Close Menu</a>
              <a href="#" className="w3-bar-item w3-button w3-padding w3-blue"><i className="fa fa-users fa-fw"></i>  Overview</a>
              <a href="#" className="w3-bar-item w3-button w3-padding"><i className="fa fa-eye fa-fw"></i>  Views</a>
              <a href="#" className="w3-bar-item w3-button w3-padding"><i className="fa fa-users fa-fw"></i>  Traffic</a>
              <a href="#" className="w3-bar-item w3-button w3-padding"><i className="fa fa-bank fa-fw"></i>  General</a>
              <a href="#" className="w3-bar-item w3-button w3-padding"><i className="fa fa-history fa-fw"></i>  History</a>
              <a href="#" className="w3-bar-item w3-button w3-padding"><i className="fa fa-cog fa-fw"></i>  Settings</a><hr /><hr />
            </div>
          </nav>


          <div className="w3-overlay w3-hide-large w3-animate-opacity" onClick={this.handleSubmit} style={{cursor:"pointer"}} title="close side menu" id="myOverlay"></div>

          <div className="w3-main" style={{marginLeft:'150px', marginTop:'43px'}}>

            <header className="w3-container" style={{paddingTop:'22px'}}>
              <h5><b><i className="fa fa-dashboard"></i>LERU Dashboard</b></h5>
            </header>

            <div className="w3-row-padding w3-margin-bottom">
              <div className="w3-quarter">
                <div className="w3-container w3-red w3-padding-16">
                  <div className="w3-left"><i className="fa fa-comment w3-xxxlarge"></i></div>
                  <div className="w3-right">
                    <h3>{this.state.ticket.length}</h3>
                  </div>
                  <div className="w3-clear"></div>
                  <h4>Tickets</h4>
                </div>
              </div>
              <div className="w3-quarter">
                <div className="w3-container w3-blue w3-padding-16">
                  <div className="w3-left"><i className="fa fa-eye w3-xxxlarge"></i></div>
                  <div className="w3-right">
                    <h3>0</h3>
                  </div>
                  <div className="w3-clear"></div>
                  <h4>Views</h4>
                </div>
              </div>
              <div className="w3-quarter">
                <div className="w3-container w3-teal w3-padding-16">
                  <div className="w3-left"><i className="fa fa-gavel w3-xxxlarge"></i></div>
                  <div className="w3-right">
                    <h3>{this.state.lawyer.length}</h3>
                  </div>
                  <div className="w3-clear"></div>
                  <h4>Lawyers</h4>
                </div>
              </div>
              <div className="w3-quarter">
                <div className="w3-container w3-orange w3-text-white w3-padding-16">
                  <div className="w3-left"><i className="fa fa-users w3-xxxlarge"></i></div>
                  <div className="w3-right">
                    <h3>{this.state.user.length}</h3>
                  </div>
                  <div className="w3-clear"></div>
                  <h4>Users</h4>
                </div>
              </div>
            </div>

            <div className="w3-container">
              <h5>General Stats</h5>
              <p>New Visitors</p>
              <div className="w3-grey">
                <div className="w3-container w3-center w3-padding w3-green" style={{width:"25%"}}>+25%</div>
              </div>

              <p>New Users</p>
              <div className="w3-grey">
                <div className="w3-container w3-center w3-padding w3-orange" style={{width:"50%"}}>50%</div>
              </div>

              <p>Bounce Rate</p>
              <div className="w3-grey">
                <div className="w3-container w3-center w3-padding w3-red" style={{width:"75%"}}>75%</div>
              </div>
            </div>
            <hr />
            <div className="w3-container" style={{textAlign: "center"}}> 
            {
              <div>
              <h5>Tickets</h5>
              <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Lawyer</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  this.state.user.map((u=> <tr key={u.id}>
                      <td>{u.fullname}</td>
                      <td>{u.email_addr}</td>
                      <td>{u.location}</td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
              <hr />
              </div>
              }
            </div>
            <div className="w3-container" style={{textAlign: "center"}}>
              <h5>User</h5>
              <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                <thead>
                  <tr>
                    <th>Fullname</th>
                    <th>Email</th>
                    <th>Location</th>
                    <th>Phone number</th>
                    <th>Subscribed</th>
                    <th>Subscription Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  this.state.user.map((u=> <tr key={u.id}>
                      <td>{u.fullname}</td>
                      <td>{u.email_addr}</td>
                      <td>{u.location}</td>
                      <td>{u.phone_number}</td>
                      <td>{u.subscribed == 0? "No": "Yes"}</td>
                      <td>{u.subscription_date}</td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
              <hr />
            </div>
            <hr />
            

            <div>
            <div className="w3-container" style={{textAlign: "center"}}>
              <h5>Lawyer</h5>
              <table className="w3-table w3-striped w3-bordered w3-border w3-hoverable w3-white">
                <thead>
                <tr>
                    <th>Picture</th>
                    <th>Name as on roll</th>
                    <th>Enrolment number</th>
                    <th>Legex ID</th>
                    <th>Expand profile</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.lawyer.map(((law, index)=><tr key={index}>
                    <td><img src={law.profile_picture} width={70} height={70} /></td>
                    <td>{law.fullname}</td>
                    <td>{law.enrolment_no}</td>
                    <td>{law.legex_id}</td>
                    <td><button id={law.id} className="w3-btn w3-green" onClick={this.expand}>Expand</button></td>
                    <td>{law.status == "null"? "Not Verified": "verified"}</td>
                    <td>{law.status == "null"? <button id={law.id} className="w3-btn w3-red" onClick={this.verify}>Verify</button> : <button id={law.id} className="w3-btn w3-teal" onClick={this.unverify}>{this.state.isVerified}</button>}</td>
                  </tr> 
                    ))
                  }
                </tbody>
                  
              </table>
              <hr />
              
            </div>
            <hr />
            </div>
            <hr />
            {
                    this.state.isVisible? 
                    <div className="w3-modal" style={{display: this.state.isVisible?"block":"none"}}>
                      <div className="w3-modal-content w3-card-4">
                        <header className="w3-container w3-green"> 
                          <span onClick={this.unexpand}
                          className="w3-button w3-display-topright">&times;</span>
                          <h2>Lawyer - {this.state.law.fullname}</h2>
                        </header>
                        <div className="w3-container">
                          <img src={this.state.law.profile_picture} width={150} height={150} />
                          <b><p>Name as on roll: {this.state.law.fullname}</p>
                          <p>Phone Number: {this.state.law.phone_number}</p>
                          <p>Whatsapp Number: {this.state.law.whatsapp_number}</p>
                          <p>Email Address: {this.state.law.email_addr}</p>
                          <p>Enrolment Number: {this.state.law.enrolment_no}</p>
                          <p>Year of Call: {this.state.law.year_of_call}</p>
                          <p>Residential Address: {this.state.law.residential_addr}</p>
                          <p>Office Address: {this.state.law.office_addr}</p>
                          <p>Preferred location: {this.state.law.pref_location}</p>
                          <p>Legex ID: {this.state.law.legex_id}</p>
                          <p>Status: {this.state.law.status == "null"? "Not Verified": "verified"}</p>
                          <p>Call to Bar Certificate: <a href={this.state.law.call_cert} target="_blank" className="w3-bar-item w3-button w3-green" >View</a></p>
                          <p>LLB Certificate: <a href={this.state.law.qualify_cert} target="_blank" className="w3-bar-item w3-button w3-green" >View</a></p></b>
                        </div>
                        <footer className="w3-container w3-green">
                          <p>profile viewer - {this.state.law.fullname}</p>
                        </footer>
                      </div>
                    </div>:
                    ""
                  }
            </div>
      </section>
    )
  }
  
};

export default store =>
  ({
    path: `/admin/leru`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ ...state }))(Leru)
      );
    }
  });