import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, browserHistory } from 'react-router';
import { connect } from 'react-redux'
import ConfirmModal from '../components/modals/ConfirmModal';
import LoginModal from '../components/modals/LoginModal';
import SignupModal from '../components/modals/SignupModal';
import CallModal from '../components/modals/CallModal';
import PasswordResetModal from '../components/modals/PasswordResetModal';
import UserSelectorModal from '../components/modals/UserSelectorModal';

class Modals extends React.Component {
  static contextTypes = {
    state: PropTypes.object
  };

  render() {
    const { match, state } = this.props;
    const { name, params } = state.modal;
    const props = { match };

    switch (name) {
      case 'confirmModal':
        return <ConfirmModal
          params={params}
          {...props}
        />;

      case 'signinModal':
        return <LoginModal
          params={params}
          {...props}
        />;

      case 'signupModal':
        return <SignupModal
          params={params}
          {...props}
        />;

      case 'passwordResetModal':
        return <PasswordResetModal
          params={params}
          {...props}
        />;

      case 'callModal':
        return <CallModal
          params={params}
          {...props}
        />;

      case 'userSelectorModal':
        return <UserSelectorModal
          params={params}
          {...props}
        />;

      default:
        return null;
    }
  }
}

export default connect(
  state => ({ state: state }),
  dispatch => ({
  })
)(withRouter(Modals));
