import React from 'react';
import Toaster from '../../components/widgets/Toaster';
import CreditCard from '../../components/widgets/CreditCard';
import Icon from '../../components/widgets/Icon';
import { currency } from '../../lib/utils';
import axios from 'axios';
import uuid from 'uuid/v1';

export default class LeruPay extends React.Component {

  
  componentWillMount() {

    const { data: { amount } } = this.props;

    // console.log(this.user)
    // if(this.props.user.plan == 2720){
    //   console.log("month")
    //   this.setState({amount: 10000 }, () => {})
    // }
    // else if(this.props.user.plan == 2198){
    //   console.log("year")
    //   this.setState({amount: 1000 }, () => {})
    // }
    
    // console.log(this.props)

    this.state = {
      amount: 1000,
      subscription: ""
    };

  }




  payWithRave = () => {
    const { user, data: { amount }, callback, uData } = this.props;
    const txref = uuid();
    const payment = window.getpaidSetup({
      PBFPubKey: 'FLWPUBK-6b5daf7cfe3cd8a28c515593661336c5-X',
      customer_email: user.email,
      amount,
      payment_plan: user.plan,
      customer_phone: user.phone,
      currency: window.SW_CURRENCY,
      country: user.country,
      txref,
      meta: [{
        metaname: 'type',
        metavalue: 'API123',
      }],
      onclose: () => { },
      callback: response => {
        // var txref = response.tx.txRef;
        // collect flwRef returned and pass to a server page to complete status check.
        if (
          response.tx.chargeResponseCode == '00' ||
          response.tx.chargeResponseCode == '0'
        ) {
          console.clear();
          console.log('This is the response returned after a charge', response);
          console.log(response, 'response')
          uData.txref = response.tx.txRef;
          console.log(uData);
          axios({
            url: 'https://cors-anywhere.herokuapp.com/https://immense-tor-56766.herokuapp.com/leru/api/payment/payment_verify.php',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            data: uData
          }).then(function(res){
            console.log(res.status);
            if((res.status == 200) || (res.status== 201)) {
              console.log(res.status);
              Toaster.success("Payment successful");
              console.log(res);
              console.log("%cUSER SUBSCRIBED","font-size:30px; color: white; background: black");
              browserHistory.push('/leruLogin');
            } else {
              console.log(res.status, res);
          }
          }).catch(function(err){
            console.error(err)
          });
        } else {
          callback({ message: 'Error with payment' })
        }

        payment.close();
      }
    });
  }

  

  render() {
    const { title, data: { amount } } = this.props;
    return <div className="Payment">
      <div className="page-title">{title}</div>
      <div className="p-a-2">
        <p className="text-center">
          <button type="button" className="pt-button pt-intent-success" onClick={this.payWithRave}>Make Payment</button>
        </p>
        <div className="pages m-t-2 text-center">
          <h3><strong className="text-muted">Total</strong> {currency(this.state.amount)}</h3>
        </div>
      </div>
    </div>;
  }
}