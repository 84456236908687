import React from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import { Button, Dialog, Intent } from "@blueprintjs/core";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { closeModal, showSigninModal } from '../../actions/modalActions';
import { Link } from 'react-router';
import Toaster from '../widgets/Toaster';
import { countries } from '../../data';
import { all } from '../../actions/userActions';
import Table from '../widgets/Table.js';

const headers = [
  {
    label: 'Name',
    key: 'name'
  }, {
    label: 'Location',
    key: 'location',
    func: (i) => (countries.filter(j => j.code === i)[0] || '').name,
  }, {
    label: 'Categories',
    key: 'categories',
    func: (i) => (i || []).join(', ').trim(),
  }, {
    label: 'Joined',
    key: 'stamp',
    func: i => moment(i).format('LL LT'),
  }
];

class UserSelectorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  handleChange(e) {
    const { name, value } = e.target || e.srcElement;
    const form = this.state.form;
    form[name] = value;
    this.setState({ form });
  }

  componentWillMount() {
    this.handleSearch(null, 1);
  }

  // renderMenuItemChildren = (user, props, index) => {
  //   return (<div key={user._id}>
  //     {/* <img
  //       src={userImage(user)}
  //       alt={user.name || noName}
  //       style={{
  //         marginRight: 10,
  //         width: 24,
  //       }}
  //     /> */}
  //     <span>{user.name}</span>
  //   </div>);
  // };

  // shouldComponentUpdate({ state: { user: { isLoading } } }) {
  //   return !isLoading;
  // }

  handleAssign = () => {
    const {
      props: { params: { cb }, closeModal },
      state: { user }
    } = this;

    cb(user);
    console.log(user);
    closeModal();
  };

  onChange = user => {
    this.setState({ user });
  };

  handleSearch = (q, page) => {
    const { params: { lawyer = 'vlf' }, all } = this.props;
    all(q, { lawyer, page });
  };

  componentDidMount() {
    // this.refs.search.focus();
    const input = window.document.getElementsByClassName('rbt-input-main')[0];
    if (input) {
      input.focus();
    }
  }

  handleSubmit = e => {
    e.preventDefault();
  };

  render() {
    const {
      closeModal,
      state: {
        user: users,
        session,
      },
      params: { lawyer = 'vlf' },
    } = this.props;
    const { isLoading: isLoading2 } = session;
    const { isLoading } = users;
    const {
      user,
    } = this.state;

    return <Dialog
      isOpen={true}
      onClose={closeModal}
      className="UserSelectorModal"
    >
      <div className="pt-dialog-body">
        <h2 className="slim">Select {lawyer.toUpperCase()} Lawyer</h2>
        <div>
          {/* <AsyncTypeahead
            allowNew={false}
            ref="search"
            useCache={false}
            minLength={3}
            onSearch={this.handleSearch}
            labelKey="name"
            onChange={this.onChange}
            options={users}
            renderMenuItemChildren={this.renderMenuItemChildren}
            placeholder={`Find a ${lawyer} lawyer`}
          /> */}
        </div>

        <Table onSelect={this.onChange} session={session} headers={headers} data={users} fetchMore={(this.handleSearch)} />

        <div className="m-t-2">
          <button onClick={() => closeModal()} className="pt-button pt-intent-danger">Cancel</button>
          <button disabled={!user} onClick={() => this.handleAssign()} className="pt-button pt-intent-primary pull-right">Assign</button>
        </div>
      </div>
    </Dialog>;
  }
}

export default connect(
  state => ({ state: state }),
  dispatch => ({
    closeModal: (replace) => dispatch(closeModal(replace)),
    all: (q, kwargs, callback) => dispatch(all(q, kwargs, callback)),
    signup: (json, callback) => dispatch(signup(json, callback)),
  })
)(UserSelectorModal);
