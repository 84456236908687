const initialState = {
  items: [],
  count: 0,
  item: null,
  isLoading: false
};

export function cardReducer(state = initialState, action) {
  const { data, card: item, type } = action;
  switch (type) {
    case 'CARD_ERROR':
      return Object.assign({}, state, {
        isLoading: false
      });
    case 'CARD_CREATE':
      return Object.assign({}, state, {
        item: null,
        isLoading: true
      });
    case 'CARD_CREATE_OK':
      return Object.assign({}, state, {
        item,
        isLoading: false
      });

    case 'CARD_GET':
      return Object.assign({}, state, {
        item: null,
        isLoading: true
      });
    case 'CARD_GET_OK':
      return Object.assign({}, state, {
        item,
        isLoading: false
      });

    case 'CARDS_GET':
      return Object.assign({}, state, {
        items: [],
        count: 0,
        isLoading: true
      });
    case 'CARDS_GET_OK':
      return Object.assign({}, state, {
        items: data.items,
        count: data.count,
        isLoading: false
      });

    default:
      return state;
  }
}
