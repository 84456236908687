import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { questionTypes } from '../../data';
import { cleanObject } from '../../lib/utils';
import Toaster from '../../components/widgets/Toaster';
import { showConfirmModal } from '../../actions/modalActions';
import { create, update, find } from '../../actions/plfCategoryActions';
import Spinner from '../../components/widgets/Spinner';
import NoItem from '../../components/widgets/NoItem';
import PageComponent from '../../components/PageComponent';
const NEW = 'new';

class PLFCategory extends PageComponent {
  getAuthType = () => ['admin'];
  componentWillMount() {
    this.state = {};
    this.fieldKey = 1;
    const _id = this.props.params.id;
    if (_id !== NEW) {
      this.get(_id);
    } else {
      this.state.plfCategory = {
        active: true
      };
    }
  }

  get = (_id) => {
    this.props.find(_id, (err, plfCategory) => {
      if (!err) {
        this.setState({ plfCategory });
      } else {
        Toaster.error(err.message);
      }
    });
  };

  handleChange = e => {
    const { name, value } = e.target || e.srcElement;
    const { plfCategory } = this.state;
    plfCategory[name] = value;
    this.setState({ plfCategory });
  };


  handleSubmit = e => {
    e.preventDefault();
    const {
      state: { plfCategory },
      props: { create, update }
    } = this;

    plfCategory.price = Number(plfCategory.price);
    if (plfCategory._id) {
      update(plfCategory._id, cleanObject(plfCategory), (err, res) => {
        if (!err) {
          Toaster.success('PLF Category updated');
        } else {
          Toaster.error(err.message);
        }
      });
    } else {
      create(cleanObject(plfCategory), (err, res) => {
        if (!err) {
          Toaster.success('PLF Category created');
          browserHistory.push(`/admin/plf-categories`);
        } else {
          Toaster.error(err.message);
        }
      });
    }
  };

  renderBreadCrumbs = (item, id) => {
    const name = item && item.name || id;
    return (<div>
      <ul className="pt-breadcrumbs">
        <li><Link className="pt-breadcrumbs-collapsed" to="/admin"></Link></li>
        <li><Link className="pt-breadcrumb" to="/admin">Dashboard</Link></li>
        <li><Link className="pt-breadcrumb" to="/admin/plf-categories">PLF Categories</Link></li>
        <li><span className="pt-breadcrumb pt-breadcrumb-current">{name.ucFirst()}</span></li>
      </ul>
      <br />
      <br />
    </div>);
  };

  renderPage() {
    const { plfCategory } = this.state;
    const { state: { plfCategory: { isLoading } }, params: { id } } = this.props;

    if (!plfCategory) {
      return <Spinner className="m-t-4 text-center" />;
    }

    return <div className="PLFCategory">
      <form onSubmit={this.handleSubmit} className="content-width">
        {this.renderBreadCrumbs(plfCategory, id)}
        <h3>{plfCategory._id ? 'Edit' : 'Create'} PLF Category</h3>
        <div className="row m-t-4">
          <label className="pt-label col_45">
            Name
            <br />
            <input type="text" maxLength={100} onChange={this.handleChange} className="pt-input pt-fill" name="name" placeholder="Enter name" defaultValue={plfCategory.name} required />
          </label>
          <div className="col_10"></div>
          <label className="pt-label col_45">
            Price
          <br />
            <input type="number" min={100} maxLength={100} onChange={this.handleChange} className="pt-input pt-fill" name="price" placeholder="Enter price" defaultValue={plfCategory.price} required />
          </label>
        </div>

        <div className="row">
          <label className="pt-label col_100">
            Description
          <br />
            <textarea type="text" maxLength={140} rows={5} onChange={this.handleChange} className="pt-input pt-fill" name="description" placeholder="(Optional)" defaultValue={plfCategory.description} />
          </label>
        </div>
        <div>
          <br />
          <button type="submit" disabled={isLoading} className="pt-button pt-intent-primary"><span>Save</span></button>
          <span>&nbsp;</span>
          <Link to={isLoading ? null : '/admin/plf-categories'}><button type="button" className="pt-button" disabled={isLoading}><span>Cancel</span></button></Link>
        </div>
      </form>
    </div>;
  }
}

export default store =>
  ({
    path: `/admin/plf-categories/:id`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({
            showConfirmModal: (params) => dispatch(showConfirmModal(params)),
            create: (plfCategory, callback) => dispatch(create(plfCategory, callback)),
            update: (_id, plfCategory, callback) => dispatch(update(_id, plfCategory, callback)),
            find: (_id, callback) => dispatch(find(_id, callback))
          }))(PLFCategory)
      );
    }
  });
