let PropTypes = {

  session:
    {
      _id: "string",
      stamp: "string",
      expires: "bool",
      user: "string",
      type:
        [
          "buyer",
          "seller",
          "admin"
        ],
      ip: "string"
    },

  user:
    {
      _id:
        {
          active: true,
          label: "ID",
          disabled: true,
          required: true,
          type: "string"
        },
      stamp:
        {
          active: true,
          label: "Last Modified",
          disabled: true,
          required: true,
          type: "date"
        },
      active:
        {
          active: true,
          label: "Active",
          disabled: false,
          required: false,
          type: "boolean"
        },
      type:
        {
          active: true,
          label: "Type",
          disabled: false,
          required: false,
          type: "enum",
          options:
            [
              {
                value: "buyer",
                label: "Customer"
              },
              {
                value: "seller",
                label: "Partner"
              },
              {
                value: "admin",
                label: "Administrator"
              }
            ]
        },
      name:
        {
          active: true,
          label: "Name",
          disabled: false,
          required: true,
          type: "string"
        },
      email:
        {
          active: true,
          label: "E-Mail",
          disabled: false,
          required: false,
          type: "string",
          pattern: "\\S+@\\S+\\.\\S+"
        },
      password:
        {
          active: true,
          label: "Password",
          disabled: false,
          required: false,
          type: "string",
          pattern: ".{6,}"
        },
      image:
        {
          active: true,
          label: "Avatar",
          disabled: true,
          required: false,
          type: "image"
        },
      phone:
        {
          active: true,
          label: "Phone",
          disabled: false,
          required: false,
          type: "string",
          placeholder: "XXX-XXX-XXXX",
          pattern: "\\d{3}-\\d{3}-\\d{4}"
        },
      timezone:
        {
          active: true,
          label: "Timezone",
          disabled: true,
          required: false,
          type: "string"
        }
    },

  event:
    {
      _id:
        {
          active: true,
          label: "ID",
          disabled: true,
          required: true,
          type: "string"
        },
      stamp:
        {
          active: true,
          label: "Last Modified",
          disabled: true,
          required: true,
          type: "date"
        },
      active:
        {
          active: true,
          label: "Active",
          disabled: false,
          required: false,
          type: "boolean"
        },
      title:
        {
          active: true,
          label: "Title",
          disabled: false,
          required: true,
          type: "string"
        }
    },

  "document-template":
    {
      _id:
        {
          active: true,
          label: "ID",
          disabled: true,
          required: true,
          type: "string"
        },
      stamp:
        {
          active: true,
          label: "Last Modified",
          disabled: true,
          required: true,
          type: "date"
        },
      active:
        {
          active: true,
          label: "Active",
          disabled: false,
          required: false,
          type: "boolean"
        },
      name:
        {
          active: true,
          label: "Name",
          disabled: false,
          required: true,
          type: "string"
        }
    },

  "category":
    {
      _id:
        {
          active: true,
          label: "ID",
          disabled: true,
          required: true,
          type: "string"
        },
      stamp:
        {
          active: true,
          label: "Last Modified",
          disabled: true,
          required: true,
          type: "date"
        },
      active:
        {
          active: true,
          label: "Active",
          disabled: false,
          required: false,
          type: "boolean"
        },
      name:
        {
          active: true,
          label: "Name",
          disabled: false,
          required: true,
          type: "string"
        }
    },

  "vlf_category":
    {
      _id:
        {
          active: true,
          label: "ID",
          disabled: true,
          required: true,
          type: "string"
        },
      stamp:
        {
          active: true,
          label: "Last Modified",
          disabled: true,
          required: true,
          type: "date"
        },
      active:
        {
          active: true,
          label: "Active",
          disabled: false,
          required: false,
          type: "boolean"
        },
      name:
        {
          active: true,
          label: "Name",
          disabled: false,
          required: true,
          type: "string"
        }
    },

  "plf_category":
    {
      _id:
        {
          active: true,
          label: "ID",
          disabled: true,
          required: true,
          type: "string"
        },
      stamp:
        {
          active: true,
          label: "Last Modified",
          disabled: true,
          required: true,
          type: "date"
        },
      active:
        {
          active: true,
          label: "Active",
          disabled: false,
          required: false,
          type: "boolean"
        },
      name:
        {
          active: true,
          label: "Name",
          disabled: false,
          required: true,
          type: "string"
        }
    },


  "subcategory":
    {
      _id:
        {
          active: true,
          label: "ID",
          disabled: true,
          required: true,
          type: "string"
        },
      stamp:
        {
          active: true,
          label: "Last Modified",
          disabled: true,
          required: true,
          type: "date"
        },
      active:
        {
          active: true,
          label: "Active",
          disabled: false,
          required: false,
          type: "boolean"
        },
      name:
        {
          active: true,
          label: "Name",
          disabled: false,
          required: true,
          type: "string"
        }
    },

  stream:
    {
      _id:
        {
          tab: "Details",
          active: true,
          label: "ID",
          disabled: true,
          required: true,
          type: "string"
        },
      stamp:
        {
          tab: "Details",
          active: true,
          label: "Last Modified",
          disabled: true,
          required: true,
          type: "date"
        },
      active:
        {
          tab: "Details",
          active: true,
          label: "Active",
          disabled: false,
          required: false,
          type: "boolean"
        },
      brand:
        {
          tab: "Details",
          active: true,
          label: "Brand",
          disabled: true,
          required: true,
          type: "string"
        },
      name:
        {
          tab: "Details",
          active: true,
          label: "Name",
          disabled: false,
          required: true,
          type: "string"
        },
      description:
        {
          tab: "Details",
          active: true,
          label: "Description",
          disabled: false,
          required: false,
          type: "string"
        },
      bios:
        {
          tab: "Details",
          active: true,
          label: "Biographies",
          disabled: false,
          required: false,
          type: "string"
        },
      info:
        {
          tab: "Details",
          active: true,
          label: "Information",
          disabled: false,
          required: false,
          type: "string"
        },
      agenda:
        {
          tab: "Details",
          active: true,
          label: "Agenda",
          disabled: false,
          required: false,
          type: "string"
        },
      external_dash:
        {
          tab: "Page Configuration",
          active: true,
          label: "External MPD Stream",
          disabled: false,
          required: false,
          type: "string"
        },
      external_hls:
        {
          tab: "Page Configuration",
          active: true,
          label: "External HLS Stream",
          disabled: false,
          required: false,
          type: "string"
        },
      external_progressive:
        {
          tab: "Page Configuration",
          active: true,
          label: "External MP4 Stream",
          disabled: false,
          required: false,
          type: "string"
        },
      external_poster:
        {
          tab: "Page Configuration",
          active: true,
          label: "External Cover Image",
          disabled: false,
          required: false,
          type: "string"
        },
      external_primary:
        {
          tab: "Page Configuration",
          active: true,
          label: "External Default Stream",
          disabled: false,
          required: true,
          type: "enum",
          options:
            [
              {
                value: "dash",
                label: "MPD"
              },
              {
                value: "hls",
                label: "HLS"
              },
              {
                value: "progressive",
                label: "MP4"
              }
            ]
        },
      internal_dash:
        {
          tab: "Page Configuration",
          active: true,
          label: "Internal MPD Stream",
          disabled: false,
          required: false,
          type: "string"
        },
      internal_hls:
        {
          tab: "Page Configuration",
          active: true,
          label: "Internal HLS Stream",
          disabled: false,
          required: false,
          type: "string"
        },
      internal_progressive:
        {
          tab: "Page Configuration",
          active: true,
          label: "Internal MP4 Stream",
          disabled: false,
          required: false,
          type: "string"
        },
      internal_poster:
        {
          tab: "Page Configuration",
          active: true,
          label: "Internal Cover Image",
          disabled: false,
          required: false,
          type: "string"
        },
      internal_primary:
        {
          tab: "Page Configuration",
          active: true,
          label: "Internal Default Stream",
          disabled: false,
          required: true,
          type: "enum",
          options:
            [
              {
                value: "dash",
                label: "MPD"
              },
              {
                value: "hls",
                label: "HLS"
              },
              {
                value: "progressive",
                label: "MP4"
              }
            ]
        },
      stream:
        {
          tab: "Details",
          active: true,
          label: "Recorded MP4",
          disabled: false,
          required: false,
          type: "string"
        },
      trailer:
        {
          tab: "Details",
          active: true,
          label: "Intro Image/MP4",
          disabled: false,
          required: true,
          type: "string"
        },
      start:
        {
          tab: "Details",
          active: false,
          label: "Start",
          disabled: false,
          required: true,
          type: "date"
        },
      end:
        {
          tab: "Details",
          active: false,
          label: "Start",
          disabled: false,
          required: true,
          type: "date"
        },
      background_color:
        {
          tab: "Details",
          active: false,
          label: "Background Color",
          disabled: false,
          required: false,
          type: "string"
        },
      embed_external:
        {
          tab: "Embedding",
          active: true,
          fn: "embed_external_textarea"
        },
      embed_internal:
        {
          tab: "Embedding",
          active: true,
          fn: "embed_internal_textarea"
        }
    }
};

export default PropTypes;
