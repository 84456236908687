import React from 'react';
import { connect } from 'react-redux';

class LeruTerms extends React.Component {
  render() {
    return (
    <div className="w3-container w3-cell w3-mobile" style={{width: '90%'}}>
    <header className="w3-container w3-center w3-green">
        <h1>Terms and Conditions</h1>
    </header>
    <div tyle={{textAlign:'justify', userSelect: 'none'}} className="user-select">
        <br />
        <h5>Terms of Service </h5><br />
        <p>
            This page states the Terms and Conditions (Terms) under which you may use the product LERU on www.mylaw.ng
            or
            mobile apps available for download (referred to as the service). Please read these Terms carefully as it
            governs your access to and use of the Site and apply to all services and sub-sites available under the
            domain
            name mylaw.ng, as well as all text, audio, video, images, graphics, materials, online communications and
            other
            information and content that are or become available on the Site (collectively, the "Information"). By
            using
            this service, you ("you" or "User") signify your acceptance of these Terms and your acknowledgement that
            the
            information that you provide, directly or indirectly, through the service will be managed in accordance
            with
            the mylaw Privacy Policy. As such, if you do not accept the Terms stated here, do not use this service.
            LERU contains certain services and related features that may require registration or subscription. If you
            open
            and operate an account on LERU, you shall ensure that you are lawfully authorized to provide all
            information
            submitted by you and all such information is true and accurate. You are responsible for updating all
            information supplied once there is any change. LERU reserves the right to suspend or terminate your access
            to
            any account created or any restricted portion of the service should it discover that any information
            provided
            by you is no longer true or accurate.
            You are entirely responsible for all activities that occur under such account and you are solely
            responsible
            for keeping details of such password and account safe and secure at all times. If you detect or become
            aware of
            any unauthorized activity or use of your account or password on LERU, you shall immediately notify
            info&#64;legexpartners.com of same so as to immediately disable access to such account.
            LERU shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any
            kind
            incurred as a result of, or in connection with, use of your account on the Site (www.mylaw.ng) or failure
            to
            comply with these Terms and Conditions of use.</p> <br />


        <h5> General</h5><br />
        <p>
            LERU facilitates access to competent, fit for purpose and affordable legal representation on a
            www.mylaw.ng.
            Legal
            services (the Services) which can be accessed via mylaw are broadly categorized as follows:</p><br />
        <p>
            • Customised Document Generation/ Document Repository App – enables users generate documents through an
            interactive
            process. This application allows users input specific information to be used to create an executable
            document
            with
            little or no support from a lawyer.</p>
        <p>
            • Virtual Law Firm (LEGEX Partners & Co) – allows users consult lawyers via chat or telephone.</p>
        <p>
            • Panel of Law Firms/Lawyers – Allows users access lawyers in cases where physical representation is
            required.
            Payment for the Services can either be by way of subscription or on a Pay As You Go (PAYG) basis.</p><br />
        <h5> 1. Interpretation and Definitions</h5><br />
        For the purposes of this Terms, the following words shall have the meaning provided below:<br />
        <p>
            <b>'LERU'</b> means Legex Emergency Response Unit</p>
        <p>
            <b>'Affiliate'</b> means the Virtual Platform Limited and any other entity that, directly or indirectly
            through
            one or
            more intermediaries, controls, is controlled by, or is under common control with, Virtual Platform Limited.</p>
        <p>
            <b>'mylaw website'</b> or <b>'Site'</b> shall mean all that proprietary information contained, prepared,
            presented and hosted by
            mylaw at [http//:www.mylaw.ng] and/or any other website so owned by mylaw for the purposes of facilitating
            access
            to the Services.</p>
        <p>
            <b>'Officers'</b> shall where the context so admits mean officers, employees, directors and agents of the
            organization,
            company or firm being referred to.</p>
        <b>'Partners'</b> means any and all registered lawyers and/or law firms on the Panel of Law Firms<br />
        <b>'Suppliers'</b> means any individual, firm designated by LERU or with whom LERU contracts as a partner,
        agent
        and such
        other relationships for the purpose of providing services to the User.<br />
        <b>'Terms'</b> means the Terms and Conditions of use contained herein.<br />
        'Virtual Platform Limited means the body corporate with rights and ownership to mylaw and all such services as
        are
        provided on the mylaw website, which expression shall include its successors in title and assigns.<br />
        <b>'User'</b> shall mean any and all visitors, and all registered accountholders on LERU.<br />
        <b>'Standard</b> Period of Representation (SRP)' shall mean 24hrs from the period of lawyer's arrival at
        location
        of
        representation<br /><br />

        <h5> 2. Contractual Relationship</h5><br />
        2.1. These Terms govern your access to, and use of LERU operated by Mylaw.ng.<br />
        2.2. By continuing usage of LERU, you consent to be bound by these Terms of use and access to mylaw.<br />
        2.3. The User is required to keep these terms and conditions of use as they constitute a binding agreement
        between
        User and LERU.<br /><br />

        <h5>3. User's Capacity and Related Matters</h5><br />
        By accepting these Terms and/or the continued use of LERU, the User represents and warrants:<br />
        3.1. To have attained the legal age of majority in the User's country of residence or otherwise able to form a
        binding contract with LERU in order to use the Services. Notwithstanding the foregoing, in no event is use of
        the
        Services permitted by those under the age of 18 except where the account will be operated by the parent or
        guardian.<br />
        3.2. All information provided by the User on and for the purposes of LERU are at all times true, complete,
        accurate, neither fraudulent or misleading in any respect.<br />
        3.3. User further represents and warrants to have the legal authority to accept the Terms herein set out and
        such
        acceptance will be binding on the User.<br />

        3.4. LERU reserves the right in its sole discretion, to refuse to provide the Services to the User in any event
        that the User is in breach of any provisions of these Terms.<br /><br />

        <h5>4. Acceptance of Terms of Use</h5><br />
        4.1. For the avoidance of doubt, use of LERU Services constitutes acceptance of these Terms, and any subsequent
        amendments which may be made to the LERU terms and conditions from time to time.<br /><br />

        <h5>5. Trial Offers, Auto-Renewals and Paid Accounts</h5><br />
        5.1. LERU may, at its sole discretion, make available trial offers for the limited use of her services to new
        or
        existing Users who provide a valid payment instrument, such as a debit card or other electronic payment method.<br />
        5.2. Any use of additional Services beyond those included in the trial offer, will require a separate purchase
        of
        those Services or an upgrade to a paid membership prior to the end of the trial offer period.<br />
        5.3. LERU reserves the right to automatically charge the User at the end of the trial or for a membership
        renewal
        unless the User gives notice of intention to cancel or convert such membership.<br />
        5.4. To enable User conveniently purchase additional products and services, LERU will store and update (e.g.
        upon
        expiration) User payment information.<br />
        5.5. In the event that LERU is unable to charge the Users designated payment method for 0any reason, LERU
        reserves
        the right to automatically convert User's paid plan to PAYG account or to suspend the paid membership until the
        designated payment method can be charged again.<br />
        5.6. Where subscriber's account cannot be charged based on insufficient funds or any other reason, subscriber
        shall
        have seven (7) days grace period, after which account will be suspended.<br />
        5.6.1 Where an account has been suspended, N10,000 naira will be paid by inactive user who want to draw on the
        service immediately and N20,000 for visitors who desire to draw on the service immediately.<br />
        5.7. For the avoidance of doubt, it is User's responsibility to maintain current billing information with LERU.<br /><br />

        <h5>6. Payment Obligations</h5>
        6.1. Upon initiation and confirmation of a transaction on LERU, the User agrees to be bound by and pay for any
        such
        transaction. User is enjoined not to commit to a transaction unless able and ready to pay for (through direct
        debit) and have confirmation of successful payment.<br />
        6.2. User warrants that all payment information provided is accurate, not misleading and not fraudulent in any
        respect; and all payment transactions for LERU are final; and non-refundable.<br />
        6.3. To prevent financial loss or possible violations of the law, LERU reserves the right to in its discretion
        disclose details of any payment associated with or made by the User, with law enforcement agencies, or impacted
        third parties (including other users). Such disclosures may originate from an order of any court of competent
        jurisdiction to compel such disclosure.<br />
        6.4. User agrees and confirms that User will not use LERU in violation of any law in force in the Federal
        Republic
        of Nigeria or such other law which may occasion liability of any sort on mylaw.<br />
        6.5. SMS charges, administrative fees for payment switching platforms or any such other charges (where
        applicable)
        by communication network operators may be applied on user's account for each payment transaction conducted on
        the
        Site.<br /><br />

        <h5>7. Refund Policy</h5><br />
        7.1. LERU desires the optimum satisfaction of the User regarding services provided on the Site.<br />
        7.2. In the event that the User believes there has been an error in billing, User should contact us by email at
        <i>info&#64;mylaw.ng</i> or by telephone at <i>08140000829</i> immediately so that such issue may be resolved, by
        providing a refund
        or offering credit that can be used for future Services.<br />
        7.3. The relevant mode of refund for complaints made will be determined at the sole discretion of LERU, and the
        User shall under no circumstances be entitled to any claim arising from or in connection with the refund method
        chosen by LERU.<br />
        7.4. When communicating such complaint, User should include any and all details relating to the exact LERU
        service
        paid for. Refund requests must be made within 20 (twenty) days of purchase, provided that, in the event that
        specific services require different refund request periods, the User shall comply with such refund period.<br /><br />


        <h5>8. Confidentiality</h5><br />
        8.1. In compliance with the professional rules and restrictions of the Rules of Professional Conduct, the Legal
        Practitioners' Act and the Nigeria Bar Association, and for reasons of personal integrity, LERU is bound by
        stringent professional standards of confidentiality. Any information received by us from Users is held in
        strict
        confidence and is not released to anyone outside of this practice, unless agreed with by you, or as required
        under
        applicable law.<br />
        8.2. All our records are securely retained in electronic files, along with secure backups.<br /><br />

        <h5> 9. Use of LERU APP</h5><br />
        9.1. Information/service provided by lawyers assigned to users on LERU does not constitute Legal Advice (as
        defined
        below) from LERU. As such, your use of LERU information/service does not create a perpetual lawyer-client
        relationship beyond the standard period for representation.<br />
        9.2. LERU takes no responsibility for the outcome of any act based on an information (legal or otherwise)
        provided
        by participating attorneys/panel of lawyers and law firms during representation.<br />
        9.3. You agree that all decisions and information you make while creating your profile and requesting a service
        on
        LERU are your full responsibility and you agree to accept and work with registered lawyers assigned from LERU
        qualified to practice in Nigeria.<br />
        9.4. Subscription to the LERU plan entitles user to one representation a month and will be required to pay more
        to
        access representation where:<br />
        i. Subscriber is not on active plan (has not paid up since last usage).<br />
        ii. Has used up the one more entitled representation and requires more.<br />
        iii. A new subscriber requires legal representation before the one month activation period.<br /><br />
        <h5>9.5. LERU does not:</h5><br />
        i. provide services beyond the standard representation period (SRP) to user except user negotiates for further
        representation after expiration of SRP.<br />
        ii. draw legal conclusions or provide opinions about your matter; or<br />
        iii. apply the law to the facts of the presented situation.<br />
        9.6. When you purchase or subscribe to a service on LERU, you are purchasing a perpetual non-transferable,
        non-assignable license to use the service for your use only. You may not sell, transfer, assign or otherwise
        give
        the service you have purchased or subscribed-to from us to another person for that person's separate use. These
        restrictions does NOT preclude you from having another person make payment on your behalf/bank account details
        so
        long as you own the request. You may not allow another person to have or use services that you have purchased
        from
        us through this website in any manner inconsistent with LERU.<br />
        FOR THE AVOIDANCE OF ANY DOUBT IF YOU ARE UNSURE ABOUT THE LEGAL REPRESENTATION SERVICES YOU ARE
        PURCHASING/SUBSCRIBING TO FROM LERU, YOU ARE STRONGLY ADVISED TO EITHER REQUEST TO SPEAK WITH A LAWYER VIA THE
        VIRTUAL LAW FIRM ( INFO@LEGEXPARTNERS.COM OR CALL08140000829), SEEK OTHER LEGAL COUNSEL OR REFRAIN FROM
        COMPLETING
        YOUR PURCHASE.<br /><br />

        <h5>10. Interface with the Virtual Law Firm (LEGEX Partners & Co)</h5><br />
        10.1. An attorney-client relationship is created or exists between a User and the Virtual Law Firm (VLF) where
        the
        User makes payment or draws from the User's subscription concerning a legal representation requested for.<br />
        10.2. Specific legal advice can only be given with full knowledge by the firm of the facts and issues relevant
        to
        your situation that you are aware of.<br />
        10.3. By providing you with legal representation on LERU, LERU has not agreed to provide legal representation
        extending beyond services which you have purchased/subscribed to.<br />
        10.4. In the event that you may require more traditional full-legal services not offered by LERU, you are
        encouraged to resort to Legex Partners and or the lawyers listed on the Panel of Lawyers/Law Firms.<br /><br />

        <h5>11. Panel of Lawyers/Law Firms Registered on LERU</h5><br />
        11.1. Where a User provides information on a particular legal matter that requires the physical intervention of
        a
        lawyer (e.g. negotiation or dispute resolution, SARS, POLICE, KAI, LASTMA or any law enforcement agency
        arrest),
        LERU will assign such matter to a lawyer registered on the LERU based on the lawyer's expertise, location and
        availability once the User makes payment for the service or draws on his or her existing subscription.<br />
        11.2. LERU does not make any warranty as to the degree of competency of any lawyer.<br />
        11.3. The lawyers registered LERU are third-party independent contractors solely responsible for the advice
        they
        give, the services they provide and the representations they make about themselves. As such, Users are solely
        responsible for assessing the quality, integrity, suitability and trustworthiness of all persons with whom they
        communicate legal needs on the LERU.<br /><br />

        <h5>12. Termination of subscription, membership and access restriction</h5><br />
        12.1. LERU reserves the right, in its sole discretion, to downgrade or terminate your access to the service,
        for
        any reason and at any time without prior notice.<br />
        12.2. If LERU elects to terminate your account, a notice will be sent to your registered email address.<br />
        12.3. LERU reserves the right to modify or discontinue, either temporarily or permanently, any part of the
        Services
        with or without notice to the User.<br />
        12.4. The User agrees that LERU will not be liable to the User or any third party claiming through the User for
        any
        modification, suspension, or discontinuance of the User's subscription or membership or access on the service.<br /><br />

        <h5>13. Ownership and preservation of documents</h5><br />
        13.1. mylaw does not claim ownership of any information, files or media you either create, upload or store on
        the
        LERU.<br />
        13.2. You hereby grant LERU permission to use your data in connection with providing services to you.<br />
        13.3. The User acknowledges and agrees that LERU may preserve these information as well as disclose them if
        required to do so by law or in the good faith belief that such preservation or disclosure is reasonably
        necessary
        to accomplish any of the following:<br />
        13.3.1. To comply with legal process, any applicable law or government requests;<br />
        13.3.2. To enforce the provisions of these Terms;<br />
        13.3.3. To respond to claims that any content violates the rights of third parties; or<br />
        13.3.4. To protect the rights, property, or personal safety of LERU, its users and the public.<br />
        13.4. The User understands that the technical processing and transmission of LERU, including User's content,
        may
        involve transmissions over various networks and changes to conform and adapt to technical requirements of
        connecting networks or devices. As such, User agrees that LERU has no responsibility or liability for deleting
        or
        failing to store any content maintained or uploaded for use on LERU.<br /><br />

        <h5>14. Consent to receive emails</h5><br />
        14.1. By creating an account, the User agrees to receive email communications from Legex partners, such as
        newsletters, special offers, account reminders and updates.<br />
        14.2. The User understands that the User can discontinue receipt of these communications by clicking the
        'Unsubscribe' link in the footer of the actual email.<br /><br />

        <h5>15. Acceptable use of Communication Services</h5><br />
        15.1. The services provided by mylaw include a large number of what are referred to in this section as
        Communication Services which include but are not limited to live chats, comment threads, question and answer
        products, customer service communication forums, and other message services.<br />
        15.2. The User agrees to use the Communication Services only to post, send and receive messages or materials
        proper
        to and related to the particular Communication Services.<br />
        15.3. The User agrees that when using the Communication Services, the User will not:<br />
        15.3.1. Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of any others.<br />
        15.3.2. Publish, post, upload, distribute or disseminate any names, materials, or information that is
        considered
        inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful.<br />
        15.3.3. Create a false identity, represent self as someone else or otherwise falsify or delete in an uploaded
        file
        any significant attributions or notices.<br />
        15.3.4. Upload files that contain software or other material protected either by intellectual property laws or
        by
        the rights of privacy or publicity except when the necessary rights or consent to such material or software is
        owned or controlled, or obtained (as applicable).<br />
        15.3.5. Upload corrupted files, files that contain viruses, or any other files that may damage the operation of
        another's computer.<br />
        15.3.6. Advertise, offer to sell, or offer to buy anything for business purposes except to the limited extent
        any
        particular Communication Service specifically allows such activity.<br />
        15.3.7. Restrict or inhibit any other user from using and enjoying the Communication Services specifically
        allows
        such activity.<br />
        15.3.8. Harvest or otherwise collect personally identifiable information about others, without their consent.<br />
        15.3.9. Violate any code of conduct or other guidelines, which may be applicable for any particular
        Communication
        Services.<br />
        15.3.10. Violate any applicable laws or regulations.<br />
        15.4. Although LERU has no obligation to monitor the Communication Services, LERU reserves the right, in its
        own
        discretion, to review and remove materials posted to a Communication Service, in whole or in part.<br />
        15.5. LERU reserves the right to disclose any material posted, information or activity as necessary to satisfy
        any
        applicable law, regulation, legal process, or governmental request.<br /><br />

        <h5>16. No Unlawful or Prohibited Use</h5><br />
        16.1. User can only make use of the Services if the Services do not conflict with or violate the laws of User's
        jurisdiction.<br />
        16.2. The availability of the Services in User's jurisdiction is not an invitation or offer by LERU to access
        or
        use the Site and the Services.<br />
        16.3. By using the Service, the User accepts sole responsibility that such use does not violate any applicable
        law
        in User's jurisdiction<br />
        16.4. The following are specifically excluded and/or prohibited:<br />
        16.4.1. Use in connection with any legal matter that is frivolous, immaterial or illegal in nature, as
        determined
        by LERU;<br />
        16.4.2. Use in connection with any legal matter involving the laws of jurisdictions outside of Nigeria;<br />
        16.4.3. Use in connection with any legal matter for which the User is currently or prospectively represented by
        legal counsel;<br />
        16.4.4. Use in any legal matter that directly involves LERU, Virtual Platform Limited or any of its affiliates,
        directors, agents, employees, or other service providers; or<br />
        16.4.5. Hack, 'scrape' or 'crawl' the Site whether directly or through intermediaries including but not limited
        to
        spiders<br />
        16.4.6. Use for legal representation in criminal matters, non-bailable offences, matters already filed in
        court.<br />
        16.5. In enforcement of this section, LERU reserves the right to refuse membership, or suspend or terminate
        User's
        account immediately and without prior notice.<br /><br />

        <h5> 17. License Grant</h5><br />
        17.1. Subject to the User's compliance with these Terms, the User is hereby granted a non-exclusive, limited,
        non-transferable, revocable license to subscribe/purchase a LERU plan as in accordance with the intention of
        LERU.<br />
        17.2. Any and all rights not expressly granted in these Terms are reserved by LERU.<br />
        17.3. Upon transmission of content on LERU, User hereby grants LERU and its affiliates a non-exclusive,
        royalty-free, perpetual, revocable and fully sub-licensable right to use, reproduce, modify, adapt, publish,
        translate, create derivative works from, distribute, perform and display any such content.<br />
        17.4. Any feedback and/or suggestions submitted by the User, may be used by LERU without obligation to the
        User.<br />
        17.5. Any resale or unauthorized distribution or duplication of materials downloaded from LERU is strictly
        prohibited. Use of any such materials is for personal use only. Any resale or redistribution of Mylaw and or
        LERU
        resources without express written consent by Virtual Platform Limited is strictly prohibited.<br /><br />

        <h5>18. Intellectual Property Rights</h5><br />
        18.1. Virtual Platform Limited retains all right, title and interest in and to the products and services
        provided
        by it on the Site including without limitation: software, images, text, graphics, illustrations, logos, service
        marks, copyrights, photographs, videos, music, and all related intellectual property rights. Except as
        otherwise
        provided in this Terms, the User shall not and shall not permit others to:<br />
        18.1.1. Reproduce, modify, translate, enhance, decompile, disassemble, reverse engineer or create derivative
        works
        of any of the Services;<br />
        18.1.2. Sell, license, sublicense, rent, lease, distribute, copy, publicly display, publish, adapt or edit any
        of
        the Services; or<br />
        18.1.3. Circumvent, or disable any security or technological features of the Services.<br />
        18.2. The design, text, graphics and selection and arrangement thereof, and services and the legal documents,
        guidance and all other content found on LERU and all other content found on the website are copyright of mylaw©
        .
        All rights reserved.<br />
        18.3. mylaw® is a registered trademark in the Nigerian Registry of Trade Marks, Patents, Designs and Copyright.<br />
        18.3.1. User shall not copy, imitate or use them, in whole or in part, without prior consent of mylaw.<br />
        18.3.2. In addition, the look and feel of mylaw is the service mark, trademark and/or trade identity of mylaw
        and
        the User may not copy, imitate or use same without prior written consent of mylaw.<br />
        18.3.3. The names of actual companies and products which the User may encounter through mylaw may be the
        trademarks
        of its respective owners.<br />
        18.3.4. Nothing in this Terms shall be understood as in any way granting any license or right to use any of
        mylaw's
        trademarks displayed on the mylaw website.<br />
        18.3.5. All goodwill generated from the use of mylaw's trademarks is reserved for the use of mylaw,
        exclusively.<br /><br />

        <h5>19. Copyright Infringement</h5><br />
        19.1. If the User believes that any material on the Site infringes upon any copyright owned and/or controlled
        by
        the User, the User may send a notice of alleged infringement to the contact information provided below:
        Address: 1b Utomi Airie Avenue, Off Fola Osibo,Lekki Phase 1, Lagos state.<br />
        Email: info@mylaw.ng<br /><br />

        <h5>20. Disclaimer of Responsibility and Liability</h5><br />
        20.1. TO THE FULLEST EXTENT PERMITTED BY LAW, LERU MAKES NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE
        SERVICES AS THEY ARE PROVIDED 'AS IS'.<br />
        20.2. LERU ALSO DISCLAIMS ANY WARRANTIES OR MERCHANTIBILITY, FITNESS FOR A PARTICULAR PURPOSE AND
        NON-INFRINGEMENT.
        INFORMATION AND OPINIONS RECEIVED VIA THE SERVICE SHOULD BE RELIED UPON FOR PERSONAL OR LEGAL DECISIONS ONLY
        UPON
        CONSULTATION WITH EITHER A LAWYER FROM THE VIRTUAL LAW FIRM, PANEL OF LAW FIRMS/LAWYERS OR ANY OTHER LAWYER
        QUALIFIED TO PRACTICE LAW IN NIGERIA FOR SPECIFIC ADVICE TAILORED TO THE USER'S SITUATION.<br />
        20.3. IN NO EVENT WILL LERU AND/OR ITS AFFILIATES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE,
        EXEMPLARY OR CONSEQUENTIAL DAMAGES OR ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, REGARDLESS OF LEGAL THEORY,
        WHETHER OR NOT LERU HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS
        ESSENTIAL
        PURPOSE.<br /><br />

        <h5>21. Release and Indemnity</h5><br />
        21.1. The User personally and on behalf of the User's heirs, executors, agents, representatives, and assigns,
        fully
        releases, forever discharges, and holds LERU, and/or its affiliates, its respective harmless form any and all
        losses, damages, expenses, including reasonable attorney's fees, rights, clams, and actions of any kind and
        injury
        (including death) arising out of or relating to the use of the Services.<br />
        21.2. User agrees that this release has been freely and voluntarily consented to and confirm that the User
        fully
        understand the purpose, intent, form and import of this Terms.<br />
        21.3. The User agrees to indemnify and hold LERU and/or its affiliates and their respective officers from any
        and
        all losses, damages, expenses, including reasonable attorneys' fees, rights, claims, actions of any kind and
        injury
        (including death) arising out of any third party claims relating to use of the LERU services, User violation of
        the
        Terms, and/or User violation of the rights of another in relation to the Terms.<br /><br />

        <h5>22. Dispute Resolution</h5><br />
        22.1. User concerns can be resolved quickly and to the Users satisfaction by calling 08140000829 and/or sending
        an
        email to info@legexpartners.com.<br />
        22.2. However, in the event that LERU is not able to resolve a dispute with the User after attempts to do so
        informally, then as a condition to use of the services, the User agrees with LERU that such disputes, claims,
        rights arising thereof shall be resolved through binding mediator.<br />
        22.3. The mediation proceedings shall be held in Lagos, Nigeria or any other location as to which the LERU and
        the
        User may then agree.<br />
        22.4. Any party seeking arbitration must first send to the other, by certified or registered mail a written
        notice
        of dispute.<br />
        22.5. Any notice to LERU should be addressed to:
        LEGEX PARTNERS & CO.
        1b Utomi Airie Avenue, Off Fola Osibo, Lekki Phase 1, Lagos State.
        Attention: LERU. <br />
        22.6. Any notice to be sent by Legex partners to the User shall be sent to the User address set forth in LERU
        records of account or such other legal address as LERU is able to identify.<br />
        22.7. The User may only resolve disputes with LERU, the Virtual Law Firm or the Panel of Law Firms/Lawyers on
        an
        individual basis, and may not bring a claim as claimant or plaintiff or a class member in a class, consolidated
        or
        representative action. Class arbitrations, class actions, private attorney general actions, and consolidations
        with
        other arbitrations are not allowed.<br />
        22.8. Notwithstanding this agreement to arbitrate disputes (as provided above), the following exceptions will
        apply
        to the resolution of disputes between Legex partners and users:<br />
        22.8.1. Legex Partners may bring a lawsuit against the User in any court of competent jurisdiction for
        injunctive
        relief to stop any unauthorized use or abuse of the LERU services without first engaging in arbitration or the
        informal dispute resolution process described above.<br />
        22.8.2. Legex Partners may bring a lawsuit against the User in any court of competent jurisdiction solely for
        injunctive relief to stop any intellectual property infringement without first engaging in arbitration or the
        informal dispute resolution process described above.<br />
        22.8.3. In the event that the agreement to mediate; as provided herein, is found to be inapplicable or
        unenforceable for any reason, then as a condition to the use of the LERU services; Legex Partners and the User
        expressly agree that any resulting judicial proceedings will be brought in any court of competent jurisdiction,
        and
        by use, the User expressly consents to the venue and jurisdiction of the courts therein.<br /><br />

        <h5>23. Applicable Law</h5><br />
        This Terms shall and will be governed by the Laws of the Federal Republic of Nigeria which shall for all intent
        and
        purposes be the governing law of this Terms.<br /><br />

        <h5>24. Entire Agreement</h5><br />
        24.1. This Terms constitute the entire agreement between the User and LEGEX PARTNERS with respect to its
        subject
        matter, and supersedes and replaces any other prior or contemporaneous agreements, or terms and conditions
        applicable to the subject matter of this Terms.<br />
        24.2. This Terms shall in no way be construed as creating third party beneficiary rights.<br /><br />

        <h5>25. Waiver, Severability and Assignment</h5><br />
        25.1. The failure of LEGEX PARTNERS to enforce any provision in this Terms is not and shall not be construed as
        a
        waiver of its right to do so at a later time.<br />
        25.2. In the event that any provision of this Terms is found unenforceable, the remaining provisions of the
        Terms
        will remain in full effect and be enforceable, and such term which reflects the intent of LEGEX PARTNERS as
        nearly
        as possible shall be reflected in the agreement instead of any severed term.<br />
        25.3. The User may not assign any rights under this Terms, and any such attempt to do so shall be void and of
        not
        effect. Provided that LEGEX PARTNERS shall reserve the right to assign its rights to any of its affiliates or
        subsidiaries, or to any successor in interest of any business associated with the Services.<br />
    </div>
</div>
    )
  }
}

export default store =>
  ({
    path: `/leruTerms`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({

          }))(LeruTerms)
      );
    }
  });