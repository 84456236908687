const key = 'vlfCategories';

import { connect } from 'react-redux';
import React from 'react';
import PageComponent from '../../../components/PageComponent';
import Props from '../../../components/widgets/etable/props';
import NoItem from '../../../components/widgets/NoItem';
import { getTemplates } from '../../../actions/documentActions';
import Toaster from '../../../components/widgets/Toaster';
import axios from 'axios';
import { Link, browserHistory } from 'react-router';
import { get, post, put, delete_ } from '../../../actions/request';

class SubscriptionPackages extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      partners: [],
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  componentWillMount() {
    this.getPackages();
  }

  navigate(e, route) {
    e.preventDefault();
    browserHistory.push(route);
  }
  getPackages() {
    get(`admin/subscription-packages`, { json: true }).then((result) => {
      this.setState({ packages: result.json }, () => {});
    });
  }

  deletePackage(e, id) {
    e.preventDefault();
    delete_(`subscription-package/${id}`, { json: {} })
      .then((result) => {
        Toaster.success('Subscription Deleted Successfully');
        this.getPackages();
      })
      .catch((error) => {
        Toaster.error('An error occured');
      });
  }
  renderPackages() {
    const tableHeaders = [
      'Name',
      'Type',
      'Partner',
      'Price',
      'Duration',
      'Consultation',
      'Ticket',
      'Documents',
    ];
    let { packages } = this.state;
    const count = packages.length;

    return (
      <div className="m-t-2">
        <div className="table-container">
          <table className="pt-table">
            <thead>
              <tr>
                {tableHeaders.map((name, idx) => {
                  return <th key={idx}>{name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {packages &&
                packages.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{item.name}</td>
                      <td>
                        {item.isServicePartner === true
                          ? 'Service Partner'
                          : 'General'}
                      </td>
                      <td>{item.isServicePartner ? item.partner : 'N/A'}</td>
                      <td>{item.price}</td>
                      <td>{item.duration}</td>
                      <td>{item.consultation.maxCount}</td>
                      <td>{item.ticket.maxCount}</td>
                      <td>{item.document.maxCount}</td>
                      <td>
                        {' '}
                        <button
                          className="pt-button pt-intent-dark"
                          type="submit"
                          onClick={(e) =>
                            this.navigate(
                              e,
                              `/admin/subscriptions/edit/${item._id}`
                            )
                          }
                        >
                          Edit
                        </button>
                        <button
                          type="submit"
                          onClick={(e) => this.deletePackage(e, item._id)}
                          className="pt-button pt-intent-danger"
                        >
                          <i className /> Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {!count ? <NoItem>No Packages created yet</NoItem> : null}
        </div>
      </div>
    );
  }

  getAuthType = () => ['admin'];
  static propTypes = Props;
  renderPage() {
    const {
      state: {
        document: {
          template: { items: templates, isLoading },
        },
      },
      params: { id },
    } = this.props;

    return (
      <section className="pad_2 content-width">
        <div className="d-flex justify-content-between">
          <h2 className="m-b-2">Subscription Packages</h2>
          <button
            className="pt-button pt-intent-primary"
            onClick={(e) => this.navigate(e, '/admin/subscriptions/create')}
            type="button"
          >
            Create New
          </button>
        </div>
        {this.renderPackages()}
      </section>
    );
  }
}

export default (store) => ({
  path: `/admin/subscriptions`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          getTemplates: (kwargs, callback) =>
            dispatch(getTemplates(kwargs, callback)),
        })
      )(SubscriptionPackages)
    );
  },
});
