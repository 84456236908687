
import React from 'react';
import NumberFormat from 'react-number-format';
import NoItem from '../../components/widgets/NoItem';
import Toaster from '../../components/widgets/Toaster';
import FileUploader from '../../components/widgets/FileUploader';

export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      errors: {},
      user: {
        name: props.name,
        image: props.image,
        email: props.email
      }
    };
  }

  handleSubmitPassowrdChange(e) {
    e.preventDefault();
    const form = e.target || e.srcElement;
    const { user, update } = this.props;
    const { elements } = e.target || e.srcElement;
    const { old_password, password, password_again } = elements;
    const payload = {
      old_password: old_password.value,
      password: password.value,
      password_again: password_again.value,
    };

    if (payload.password !== payload.password_again) {
      password.value = '';
      password_again.value = '';
      password.focus();
      return Toaster.error('Passwords do not match');
    }

    delete payload.password_again;
    this.setState({ isLoading: true });
    update(user._id, payload, (err, res) => {
      this.setState({ isLoading: false });
      if (!err) {
        password.value = '';
        password_again.value = '';
        old_password.value = '';
        return Toaster.success('Password updated');
      } else {
        return Toaster.error('Error changing password');
      }
    });
  }

  handleSubmitProfileUpdate(e) {
    e.preventDefault();
    const { elements } = e.target || e.srcElement;
    const { image, errors } = this.state;
    const {
      name: { value: name },
      email: { value: email },
      phone: { value: phone },
      location,
    } = elements;

    const { user, update } = this.props;
    if (phone.length < 10) {
      errors.phone = true;
      this.setState({ errors });
      return Toaster.error('Your phone number should be at least 10 digits');
    } else {
      delete errors.phone;
    }

    const payload = {
      name,
      email,
      phone
    };

    if (image) {
      payload.image = image;
    }

    if (location) {
      payload.location = location.value;
    }

    this.setState({ errors });
    update(user._id, payload, (err, res) => {
      // this.setState({ isLoading: false });
      if (!err) {
        return Toaster.success('Profile updated');
      } else {
        return Toaster.error(err && err.message || 'Error updating profile');
      }
    });
  }

  render() {
    const { user, createFile } = this.props;
    const { image, isLoading, errors = {} } = this.state;
    if (!user || !user._id) {
      return <NoItem>User not loaded</NoItem>;
    }

    return <div className="Profile">
      <div className="page-title">Profile</div>
      <div className="page-body">
        <form onSubmit={this.handleSubmitProfileUpdate.bind(this)}>
          <div className="row">
            <div className="col_30">
              <label className="pt-label">Profile Picture</label>
              <FileUploader create={createFile} file={image || user.image} onUpload={(err, res) => {
                if (!err) {
                  this.setState({ image: res.url });
                }
              }} />
            </div>
          </div>
          <div className="row">
            <div className="col_45">
              <p>
                <label className="pt-label">Full Name</label>
                <input className="pt-input pt-fill" defaultValue={user.name} name="name" type="text" ref="name" placeholder="Enter Full name" required />
              </p>
            </div>
            <div className="col_10" />
            <div className="col_45">
              <p>
                <label className="pt-label">Email</label>
                <input className="pt-input pt-fill" defaultValue={user.email} name="email" type="email" ref="email" placeholder="Enter Email Address" required />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col_45">
              <p>
                <label className="pt-label">Phone Number <em className="text-muted"></em></label>
                <input className="pt-input pt-fill" defaultValue={user.phone} name="phone" type="telp" ref="phone" placeholder="Enter Phone Number" required />
                {/* <NumberFormat className={`pt-input pt-fill ${errors.phone ? 'has-error' : ''}`} format="###-###-###-####" value={user.phone} name="phone" ref="phone" type="text" placeholder="Enter Phone Number" required /> */}
              </p>
            </div>
            <div className="col_10" />
            <div className="col_45">
              {user.lawyer === 'plf' && <p>
                <label className="pt-label">City, State<em className="text-muted"></em></label>
                <input className="pt-input pt-fill" defaultValue={user.location} name="location" type="text" ref="lication" placeholder="Enter your City and State" required />
                {/* <NumberFormat className={`pt-input pt-fill ${errors.phone ? 'has-error' : ''}`} format="###-###-###-####" value={user.phone} name="phone" ref="phone" type="text" placeholder="Enter Phone Number" required /> */}
              </p>}
            </div>
          </div>
          <p className="pull-right">
            <button disabled={isLoading} className="pt-button pt-button-primary">Update</button>
          </p>
          <div className="clearfix"></div>
        </form>
      </div>
      <div className="page-title">Change Password</div>
      <div className="page-body">
        <form onSubmit={this.handleSubmitPassowrdChange.bind(this)}>
          <div className="row">
            <div className="col_45">
              <p>
                <label className="pt-label">Old Password</label>
                <input className="pt-input pt-fill" name="old_password" type="password" ref="old_password" placeholder="Enter Old Password" required />
              </p>
            </div>
            <div className="col_10" />
            <div className="col_45">
              <p>
                <label className="pt-label">New Password</label>
                <input className="pt-input pt-fill" name="password" type="password" ref="password" placeholder="Enter New Password" required />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col_55" />
            <div className="col_45">
              <p>
                <label className="pt-label">New Password Again</label>
                <input className="pt-input pt-fill" name="password_again" type="password" ref="password_again" placeholder="Enter New Password Again" required />
              </p>
            </div>
          </div>
          <p className="pull-right">
            <button disabled={isLoading} className="pt-button pt-button-primary">Change Password</button>
          </p>
          <div className="clearfix"></div>
        </form>
      </div>
    </div>;
  }
}