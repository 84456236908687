import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/widgets/Spinner';
import Heading from '../../components/widgets/Heading';
import { Link } from 'react-router';
import axios from 'axios';

class BlogPost extends React.Component {
  constructor(props) {
    super(props);
    this.state =  {
      post: []
    }
  }
  componentDidMount() {
    axios.get('https://public-api.wordpress.com/rest/v1.1/sites/152560735/posts/' + this.props.routeParams.id)
      .then(res =>{
        console.log(res.data);
        this.setState({post: this.state.post.concat(res.data)})
      })
      .catch(err => {
        console.log(err);
      })
  }
  render() {
    return (
        !(this.state.post.length === 0) ? (<div style={{display: "flex", justifyContent: "center"}}>
            {this.state.post.map(p => <div className="col_md_85 col_sm_100" style={{marginBottom: '150px'}}>
                <h6><a href={"/blog"}>Blog&nbsp;> &nbsp;</a>{p.title}</h6>
                <div style={{margin: "75px auto"}}><Heading title={<div >{p.title}</div>} /></div>
                <div className="pt-card pt-elevation-3"> 
                    <div style={{textAlign: "left", marginBottom: '150px', marginTop: '150px'}} dangerouslySetInnerHTML={{__html:p.content}}></div>
                    </div>
                </div>
              )}
        </div>): (
          <div style={{display: "flex", justifyContent: "center"}}>
            <div className="col_md_85 col_sm_100">
             <div style={{margin: "75px auto"}}><Heading title={<div >Post</div>} /></div>
              <Spinner className="text-center" />
            </div>
          </div>
        )
    );
  }
}

export default store =>
  ({
    path: `/blog/post/:id`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({

          }))(BlogPost)
      );
    }
  });