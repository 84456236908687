import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link, browserHistory } from 'react-router';
import { showConfirmModal } from '../../actions/modalActions';
import {
  all as allDocuments,
  getTemplates,
} from '../../actions/documentActions';
import { all as allLawyers } from '../../actions/plfActions';
import { all as allCalls } from '../../actions/callActions';
import { all as allTickets } from '../../actions/ticketActions';
import Spinner from '../../components/widgets/Spinner';
import Icon from '../../components/widgets/Icon';
import Heading from '../../components/widgets/Heading';
import NoItem from '../../components/widgets/NoItem';
import PageComponent from '../../components/PageComponent';
const sections = [
  {
    key: 'document',
    label: 'Agreements',
    icon: 'note',
    notFound: "You haven't created any agreements yet",
    href: '/documents',
  },
  {
    key: 'call',
    label: 'Calls',
    icon: 'phone',
    notFound: "You haven't booked any calls yet",
    href: '/documents',
  },
  {
    key: 'ticket',
    label: 'Tickets',
    icon: 'label',
    notFound: "You haven't opened any tickets yet",
    href: '/tickets',
  },
  {
    key: 'plf',
    label: 'Lawyers',
    notFound: "You haven't requested for any lawyers yet",
    icon: 'people',
    noun: 'lawyer',
    href: '/plf-requests',
  },
];

class Home extends PageComponent {
  getAuthType = () => ['guest', 'admin', 'lawyer', 'servicepartner'];
  state = {
    subItems: [],
  };

  componentDidUpdate(prevProps) {
    const {
      state: {
        subscription: { item },
      },
    } = this.props;

    if (prevProps.state !== this.props.state) {
      this.setState({ subItems: item });
    }
  }

  componentWillMount() {
    this.setTitle('Home');
    this.limit = 1;
    this.state = {
      templates: { items: [] },
    };
    this.getData();
    const { getTemplates } = this.props;
    getTemplates({ limit: 8 }, (err, templates) => {
      if (!err) {
        templates.items = templates.items.slice(4, 8);
        this.setState({ templates });
      }
    });
    const ls = (val) => {
      localStorage.getItem(val);
    };
    if (localStorage.getItem('call')) {
      localStorage.removeItem('call');
      browserHistory.push('/calls');
    } else if (localStorage.getItem('chat')) {
      localStorage.removeItem('chat');
      browserHistory.push(
        '/tickets?message=%20&category=5ad7a18014e05a0014e3c5d8'
      );
    } else if (localStorage.getItem('legalad')) {
      localStorage.removeItem('legalad');
      browserHistory.push(
        'tickets?message=%20&category=5a395b7fa788660014a74e55'
      );
    } else if (localStorage.getItem('lease')) {
      localStorage.removeItem('lease');
      browserHistory.push(
        '/tickets?message=%20&category=5a3171dfeded770014f78f16'
      );
    } else if (localStorage.getItem('c-agree')) {
      localStorage.removeItem('c-agree');
      browserHistory.push('/documents');
    } else if (localStorage.getItem('r-agree')) {
      localStorage.removeItem('r-agree');
      browserHistory.push('/document-categries');
    } else if (localStorage.getItem('cust-agree')) {
      localStorage.removeItem('cust-agree');
      browserHistory.push('/document-categries');
    } else if (localStorage.getItem('regbiz')) {
      localStorage.removeItem('regbiz');
      browserHistory.push(
        '/tickets?message=%20&category=5a3171cdeded770014f78f14'
      );
    } else if (localStorage.getItem('incorp')) {
      localStorage.removeItem('incorp');
      browserHistory.push(
        '/tickets?message=%20&category=5a317214eded770014f78f18'
      );
    }
  }

  getData = (page) => {
    const {
      allDocuments,
      allTickets,
      allCalls,
      allLawyers,
      state: {
        session: { user: owner },
      },
    } = this.props;
    allDocuments({ limit: 1, owner });
    allTickets({ limit: 1, owner });
    allLawyers({ limit: 1, owner });
    allCalls({ limit: 1, owner });
  };

  renderWhatLeft = (key) => {
    const { subItems } = this.state;
    const mapper = {
      call: 'consultation',
      document: 'document',
      plf: 'plfRequest',
      ticket: 'ticket',
    };
    const id = subItems ? mapper[key] : null;
    let expression;
     if (id && subItems.active) {
      if ( subItems[id] ) {
        const amount =  subItems[id].maxCount - subItems[id].count; 
        expression = amount > 100 ? 'unlimited' : amount + ' unused';
      } else expression =  '0 unused';
     } else expression = null;
        
        
    return id ? (
      <h5>
        <em>{expression} </em>
      </h5>
    ) : null;
  };

  displayNotifier = () => {
    const { subItems } = this.state;
    return subItems && subItems.active ? null : (
      <button className="pop">
        <Link to="/account/subscription">Activate your free subscription</Link>
      </button>
    );
  };

  displaySubscription = () => {
    const { subItems } = this.state;
    return subItems && subItems.active ? (
      <>
        <div>You are currently subscribed to {subItems.name}</div>
        <div>Here is the statistics of your usage</div>
      </>
    ) : null;
  };

  renderSection = ({ notFound, icon, label, key }, idx) => {
    const { items, count } = this.props.state[key];
    return (
      <div className="section-pane col_md_25" key={idx}>
        <div className="section-content">
          <div className="row">
            <div className="col_60 p-a-1">
              <Icon name={icon} />
            </div>
            <div className="col_40 section-text">
              {count > 0 && <h2>{count}</h2>}
              {count < 1 && (
                <small>
                  <em>{notFound}</em>
                </small>
              )}
            </div>
          </div>
          <h1>{label}</h1>
          {this.renderWhatLeft(key)}
        </div>
      </div>
    );
  };

  renderPage() {
    const {
      state: {
        user: { account },
        document: {
          items: documents,
          count: documentCount,
          isLoading: isDocumentLoading,
        },
        call: { items: calls, count: callCount, isLoading: isCallLoading },
        ticket: {
          items: tickets,
          count: ticketCount,
          isLoading: isTicketLoading,
        },
      },
    } = this.props;

    const {
      templates: { items: templates, count: templatesCount },
    } = this.state;
    return (
      <div className="Home">
        <div className="content-width text-center">
          <div>
            <div className="m-t-4">
              {this.displayNotifier()}
              <Heading title={<div>Welcome</div>} />
              {this.displaySubscription()}
            </div>

            {account.type === 'lawyer' ? (
              <h5 className="text-center m-t-4">
                <Icon name="info" />
                You&rsquo;ll get notified when you receive requests.
              </h5>
            ) : (
              <div className="row text-left m-t-4">
                {sections.map(this.renderSection)}
              </div>
            )}

            <div className="pt-card m-t-4 m-b-4">
              <div className="section-inverse landing-page-section">
                <h3>Popular Documents</h3>
                <div className="row template-section">
                  {templatesCount > 0 ? (
                    templates.map((template, idx) => (
                      <div key={idx} className="col_md_25 col_sm_50 col_xs_50">
                        <div className="template-preview">
                          <div className="document-icon" />
                          <div className="document-name">
                            {template.title.ellipsis(30)}
                          </div>
                          <Link
                            to={`/document-category/templates/${template._id}`}
                            className="pt-button pt-intent-accent"
                          >
                            Try it out
                          </Link>
                        </div>
                      </div>
                    ))
                  ) : (
                    <Spinner className="col_100 text-center" />
                  )}
                </div>
                <div className="action-link m-t-4">
                  <Link to="/document-categories">
                    View all agreements <Icon name="arrow_forward" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (store) => ({
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          showConfirmModal: (params) => dispatch(showConfirmModal(params)),
          allDocuments: (kwargs, callback) =>
            dispatch(allDocuments(kwargs, callback)),
          allTickets: (kwargs, callback) =>
            dispatch(allTickets(kwargs, callback)),
          allLawyers: (kwargs, callback) =>
            dispatch(allLawyers(kwargs, callback)),
          allCalls: (kwargs, callback) => dispatch(allCalls(kwargs, callback)),
          getTemplates: (kwargs, callback) =>
            dispatch(getTemplates(kwargs, callback)),
        })
      )(Home)
    );
  },
});
