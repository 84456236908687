const key = 'dashboard';

import {connect} from 'react-redux';
import {injectReducer} from '../../store/reducers';
import reducer, * as actions from './modules';
import Component from './components';

export default store =>
({
  path: `/${key}`,
  getComponent(next, cb)
  {
    injectReducer(store, {key, reducer});
    cb(
      null,
      connect(state => state[key], actions)(Component)
    );
  }
});
