import React from 'react';
import Dropzone from 'react-dropzone'
import { Spinner } from '@blueprintjs/core';
import Toaster from './Toaster';
import Icon from './Icon';
import placeholder from '../../assets/images/document.png';

const isImage = i => /.\.(jpg|jpeg|png|gif|bmp)$/i.test(i);
export default class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      file: props.file
    };
  }

  onDrop(files) {
    const { onUpload, isVideo, create, path } = this.props;
    const file = files[0];

    // if (!/(application\/pdf)|(image\/.*)|(.*\/doc.*)/.test(file.type)) {
    //   return Toaster.warn(`Only documents and images can be uploaded`);
    // }

    this.setState({ isUploading: true });
    create({ file: files[0], path }, (err, res) => {
      this.setState({ isUploading: false });
      onUpload(err, res);
    });
  }

  preview(file) {
    return file ? `url(${isImage(file) ? file : placeholder})` : 'none';
  }

  render() {
    const { isUploading } = this.state;
    const { children, className = '', file, isVideo = false, multiple = false } = this.props;
    return (
      <Dropzone className={`FileUploader ${className}`} onDrop={this.onDrop.bind(this)} multiple={multiple} name="file">
        <div className="add-block sm" style={{ backgroundImage: this.preview(file) }}>
          <div className="description-text">
            {isUploading ? <Spinner /> : children || <div title={file ? 'Update' : 'Add'}><Icon name={isVideo ? 'videocam' : 'attach_file'} size={2} /></div>}
          </div>
        </div>
      </Dropzone>);
  }
}