import { get, post, put, delete_ } from './request';

export function create(json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'TICKET_CREATE',
    });

    json.files = json.files.filter((i) => i);
    post(`ticket`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'TICKET_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all({ owner: result.json.owner }));
          dispatch({ type: 'TICKET_CREATE_OK', ticket: result.json });
          if (window.ga) {
            window.ga('send', 'event', 'Ticket', 'create');
          }
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'TICKET_ERROR', error });
      });
  };
}

export function createMessage(_id, json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'TICKET_CREATE_MESSAGE',
    });

    json.files = (json.files || []).filter((i) => i);
    post(`ticket/${_id}/message`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'TICKET_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all({ owner: result.json.owner }));
          dispatch({ type: 'TICKET_CREATE_MESSAGE_OK', ticket: result.json });
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'TICKET_ERROR', error });
      });
  };
}

export function unmarkUnread(_id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'TICKET_MAKE_UNREAD',
    });

    put(`ticket/${_id}/message`, { json: {} })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'TICKET_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all({ owner: result.json.owner }));
          dispatch({ type: 'TICKET_MAKE_UNREAD_OK', ticket: result.json });
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'TICKET_ERROR', error });
      });
  };
}

export function update(_id, json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'TICKET_UPDATE',
    });

    put(`ticket/${_id}`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'TICKET_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all({ owner: json.owner }));
          dispatch({ type: 'TICKET_UPDATE_OK', ticket: result.json });
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'TICKET_ERROR', error });
      });
  };
}

export function remove(_id, json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'TICKET_DELETE',
    });

    delete_(`ticket/${_id}`, { json })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'TICKET_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch(all({ owner: json.owner }));
          dispatch({ type: 'TICKET_DELETE_OK', ticket: result.json });
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'TICKET_ERROR', error });
      });
  };
}

export function find(_id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'TICKET_GET',
    });

    get(`ticket/${_id}`, { json: true })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'TICKET_ERROR', error: result.response.statusText });
        } else {
          callback(null, result.json);
          dispatch({ type: 'TICKET_GET_OK', ticket: result.json });
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: 'TICKET_ERROR', error });
      });
  };
}

export function all(kwargs = {}, callback = () => {}) {
  return (dispatch, getState) => {
    if (!kwargs.useCallback) {
      dispatch({
        type: 'TICKETS_GET',
      });
    }

    const q = kwargs.q ? `&q=${kwargs.q}` : '';
    const owner = kwargs.owner ? `&owner=${kwargs.owner}` : '';
    const page = kwargs.page ? `&page=${kwargs.page}` : '';
    const status = kwargs.status ? `&status=${kwargs.status}` : '';
    const is_open = kwargs.is_open ? `&is_open=${kwargs.is_open}` : '';
    const unread = kwargs.unread ? `&unread=${kwargs.unread}` : '';
    const limit = `?limit=${
      typeof kwargs.limit === 'number' ? kwargs.limit : 10
    }`;
    get(`tickets${limit}${q}${owner}${page}${status}${is_open}${unread}`, {
      json: true,
    })
      .then((result) => {
        if (!result.response.ok) {
          callback(result.json);
          if (!kwargs.useCallback) {
            dispatch({
              type: 'TICKET_ERROR',
              error: result.response.statusText,
            });
          }
        } else {
          callback(null, result.json);
          if (!kwargs.useCallback) {
            dispatch({ type: 'TICKETS_OK', data: result.json });
          }
        }
      })
      .catch((error) => {
        callback(error);
        if (!kwargs.useCallback) {
          dispatch({ type: 'TICKETS_ERROR', error });
        }
      });
  };
}
