import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/widgets/Spinner';
import { Link, browserHistory } from 'react-router';
import Select from 'react-select';
import Modal from 'react-modal';
import axios from 'axios';


class LeruPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        fname:'',
        surn:'',
        full_name:'',
        password: '',
        e_addr:'',
        y_o_call:'',
        enrol_num:'null',
        pref_loc:'null',
        off_addr:'null',
        res_addr:'null',
        d_o_b:'',
        p_number:'',
        w_number:'',
        call_cert:'',
        q_cert:'',
        picture: '',
        file1: null,
        file2: null,
        file3: null,
        selectedOption: [{ value: 'TAX LAW', label: 'TAX LAW' }, { value: 'CRIMINAL LAW', label: 'CRIMINAL LAW' }],
        error: false,
        modalIsOpen: false,
        isChecked: false,
        year: false,
        validation: '',
        show: true
    };
  }
  componentWillMount() {
    Modal.setAppElement('body')
  }

  handleChange() {

  }
  filehandler = (e) => {
    
  }
  openModal = () => {
    this.setState({modalIsOpen: true});
    setTimeout(()=>{browserHistory.push('/leruLogin');}, 10000);
  }
 
  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#f00';
  }
 
  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  checked = (e) => {
    this.setState({isChecked: e.target.value})
  }


  uploadwidget = (e) => {
    e.preventDefault();
    var that = this;
    var file = e.target.files[0];
    var formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', "roogoybk");

    axios({
      url: 'https://cors-anywhere.herokuapp.com/https://api.cloudinary.com/v1_1/mylaw-ng/image/upload',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData
    }).then(function(res){
      that.setState({file1 : "file"})
      that.setState({picture: res.data.secure_url})
      console.log(res);
    }).catch(function(err){
      console.log(err);
    });
  }

  uploadBarwidget = (e) => {
    e.preventDefault();
    var that = this;
    var file = e.target.files[0];
    var formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', "roogoybk");

    axios({
      url: 'https://cors-anywhere.herokuapp.com/https://api.cloudinary.com/v1_1/mylaw-ng/upload',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData
    }).then(function(res){
      that.setState({file2 : "file"})
      that.setState({call_cert: res.data.secure_url})
      console.log(res);
    }).catch(function(err){
      console.log(err);
    });
  }
  uploadQuawidget = (e) => {
    var that = this;
    var file = e.target.files[0];
    var formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', "roogoybk");

    axios({
      url: 'https://cors-anywhere.herokuapp.com/https://api.cloudinary.com/v1_1/mylaw-ng/upload',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData
    }).then(function(res){
      that.setState({file3 : "file"})
      that.setState({q_cert: res.data.secure_url})
      console.log(res);
    }).catch(function(err){
      console.log(err);
    });
  }
  handleChangeS = (selectedOption) => {
    this.setState({selectedOption})
    console.log(selectedOption)
  }
  handleValidation = (e) => {
    const {name, value} = e.target || e.srcElement;
    if(name === 'password2') {
      if (value === this.state.password){
        console.log(`%c If you are seeing this, Stop!!`,'font-size:40px; color: red; background: white')
        this.setState({validation: ""})
      } else {
        this.setState({validation: "PASSWORDS DOESN'T MATCH"})
        this.pass.focus();
      }
    }
  }
  handleChange = (e) => {
    const { name, value, checked } = e.target || e.srcElement;


    switch(name) {
        case 'fname':
            this.setState({fname: value});
            break;
        case 'surn':
            this.setState({surn: value});
            break;
        case 'e_addr':
            this.setState({e_addr: value});
            break;
        case 'full_name':
            this.setState({full_name: value});
            break;
        case 'password':
            this.setState({password: value});
            break;
        case 'y_o_call':
            this.setState({y_o_call: value});
            var parts = value.split('-');
            parts = parts[0];
            var d = new Date();
            d = d.getFullYear() - 1;
            (d == parts || d < parts) ? this.setState({year: true}): this.setState({year: false})
            break;
        case 'enrol_num':
            this.setState({enrol_num: value});
            break;
        case 'pref_loc':
            this.setState({pref_loc: value});
            break;
        case 'off_addr':
            this.setState({off_addr: value});
            break;
        case 'res_addr':
            this.setState({res_addr: value});
            break;
        case 'd_o_b':
            this.setState({d_o_b: value});
            break;
        case 'res_addr':
            this.setState({res_addr: value});
            break;
        case 'p_number':
            this.setState({p_number: value});
            break;
        case 'w_number':
            this.setState({w_number: value});
            break;
        default:
            break;
    }
  }
  handleSubmit = (e) => {
      e.preventDefault();
      window.scrollTo(0,0);
      const { fname, surn,password, full_name, e_addr, y_o_call, enrol_num, pref_loc, off_addr, res_addr, d_o_b, p_number, w_number, picture, call_cert, q_cert, selectedOption} = this.state;



      this.setState({show: false})
      const c1 = selectedOption[0].value;
      const c2 = selectedOption[1].value;



      if((fname == null) || (surn == null) || (selectedOption[0] == undefined) || (selectedOption[0] == null) || (selectedOption[1] == null) || (selectedOption[1] == undefined) || (password == null) || (e_addr == null) || (y_o_call == null) || (enrol_num == null) || (pref_loc == null) || (off_addr == null) || (res_addr == null) || (d_o_b == null) || (p_number == null)) {
        this.setState({error: true});
        return false;
      }

      const data = {
       name : fname,
       surname : surn,
       fullname :  full_name,
       password: password,
       year_of_call : y_o_call,
       enrolment_no : enrol_num,
       email_addr : e_addr,
       residential_addr : res_addr,
       office_addr : off_addr,
       pref_location : pref_loc,
       date_of_birth : d_o_b,
       profile_picture : picture,
       phone_number : p_number,
       whatsapp_number : w_number,
       call_cert: call_cert,
       qualify_cert: q_cert,
       status: "null",
       cell_one: c1,
       cell_two: c2,
       cell_three: "null",
       cell_four: "null",
       cell_five: "null",
    };
    const that = this;
    axios({
      url: 'https://cors-anywhere.herokuapp.com/https://immense-tor-56766.herokuapp.com/api/lawyer/create.php',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data
    }).then(function(res){
      that.setState({show: true})
      that.openModal();
      console.log(res.status);
      if((res.status == 200) || (res.status== 201)) {
        console.log(res.status);
        that.setState({error: false});
        that.setState({file1: null});
        that.setState({file2: null});
        
        that.setState({file3: null});
        that.setState({selectedOption: null});
        console.log("%cLawyer created","font-size:30px; color: white; background: black")
      } else {
      that.setState({error: true});
    }
    }).catch(function(err){
      console.error(err)
    that.setState({show: true})
    that.openModal();
    that.setState({error: true});
    });
    //https://cors-anywhere.herokuapp.com/${window.SW_DOMAIN}/api/v1/leru_lawyer
  }
  
  render() {
    const options = [
      { value: 'ADMIRALTY (MARITIME) LAW', label: 'ADMIRALTY (MARITIME) LAW' },
      { value: 'INTERNATIONAL LAW', label: 'INTERNATIONAL LAW' },
      { value: 'CIVIL RIGHTS LAW', label: 'CIVIL RIGHTS LAW' },
      { value: 'TAX LAW', label: 'TAX LAW' },
      { value: 'CRIMINAL LAW', label: 'CRIMINAL LAW' },
      { value: 'ENTERTAINMENT LAW', label: 'ENTERTAINMENT LAW' },
      { value: 'IMMIGRATION LAW', label: 'IMMIGRATION LAW' },
      { value: 'INTELLECTUAL PROPERTY LAW', label: 'INTELLECTUAL PROPERTY LAW' },
      { value: 'LABOUR & EMPLOYMENT LAW', label: 'LABOUR & EMPLOYMENT LAW' },
      { value: 'FAMILY LAW', label: 'FAMILY LAW' },
      { value: 'ENVIRONMENT LAW', label: 'ENVIRONMENT LAW' },
      { value: 'DEBT RECOVERY', label: 'DEBT RECOVERY' },
      { value: 'PROPERTY LAW', label: 'PROPERTY LAW' },
      { value: 'DISPUTE RESOLUTION', label: 'DISPUTE RESOLUTION' },
      { value: 'ELECTION PETITION', label: 'ELECTION PETITION' },
      { value: 'ENERGY & NATURAL RESOURCES', label: 'ENERGY & NATURAL RESOURCES' },
      { value: 'HUMAN RIGHTS', label: 'HUMAN RIGHTS' },
        ];
      const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)'
        }
      };
    return (
      <section  >
      <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {
            this.state.error? <h2 ref={subtitle => this.subtitle = subtitle}>Network Error - Incomplete field</h2> : <h2 ref={subtitle => this.subtitle = subtitle}>Account awaiting Verification</h2>
          }
          
          <button onClick={this.closeModal}>close</button>
          <div>
            
            {
            this.state.error? <p> Reload the page and try again</p> : <p> We will get back to you after verifying your submitted data</p>
          }
          </div>
        </Modal>
        <div>
        {
          this.state.isChecked?
        <div>
          {
          this.state.show?
          <section className="landing-page-section">
          <div className="landing-header m-t-2" style={{background: '#3e8e41'}}>
            <Link to={'/home'}>
              <div className="landing-logo-text" style={{fontWeight: "bold", fontSize: "30px", color: "white", marginLeft: '45px' }}>Leru</div>
            </Link>
          </div>
        <div className="w3-cell-row" >
        <div className="w3-container w3-cell w3-mobile m-t-3" style={{textAlign: 'center'}}>
                <h1>FREQUENTLY ASKED QUESTIONS (LERU)</h1>
                <div className="m-t-4">
                  <h4>What is LERU?</h4>
                  <p>LERU is the acronym for Legex Emergency Response Unit. It is a<br />
                   legal service platform that allows you access to a lawyer in times of <br />
                   emergencies involving law enforcement and/or regulatory agencies such as the police,<br />
                    LASMA Local government etc.  LERU is also a referral platform for lawyers to get clients.
                    </p>
                  <h4>How can a Lawyer become a LERU Partner?</h4>
                  <p>Simply log on to www.mylaw.ng and click on the "LERU" icon, select "Become A Legex Partner".<br />
                   You may also register with the link- https://www.mylaw.ng/legex_partner.  Read the Service Level Agreement and agree.<br />
                     Fill in your details correctly. Ensure you upload necessary documents to help in your verification.
                      </p>
                  <h4>When can I start getting referrals?</h4>
                  <p>After you register and upload your documents, your credentials will be verified<br />
                   and a mail sent to you which will contain a link to make payment for your identity card.<br />
                    Upon payment, registration is complete. You will become eligible to start representing clients<br />
                     on the LERU platform.</p>
                      
                  <h4>Within what timeframe do I have to respond to a client?</h4>
                  <p>Once a client is assigned to you, you are to contact the client<br />
                   and respond to the emergency within three hours of the assignment.</p>
                  <h4>What is the Lawyer’s commitment under LERU?</h4>
                  <p>You are required to provide appropriate legal representation to the <br /> 
                  client until the matter is resolved or to continue to provide representation for up to a maximum of 24 hours.</p>
                  <h4>How do I get paid?</h4>
                  <p>LEGEX will disburse your agreed fee, upon closing out the service request on the mylaw.ng portal.</p>
                  <h4>What if the matter is not resolved in 24 hours?</h4>
                   <p>The Lawyer’s emergency response obligation is deemed fulfilled. <br /> However, the client may elect to retain the services of the lawyer on terms.</p>
                </div>
          </div>
          <div className="w3-container w3-cell w3-mobile">
            <h1 className="smart-text">
              Become a Legex Partner
            </h1>
            <div className="cta-container">
              <form  method="post" onSubmit={this.handleSubmit} ref={form => this.formRef = form} autoComplete="off">
                <input type="email" style={{display: "none"}} />
                <input type="password" style={{display: "none"}} />
                <p>
                <label className="pt-label tooltiip">Enter firstname <span className="tooltiiptext">Your Firstname e.g John</span> <span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                <textarea rows={1} className="pt-input pt-fill" min={5} onChange={this.handleChange} name="fname"  required />
                </p>
                <p>
                  <label className="pt-label tooltiip">Enter surname<span className="tooltiiptext">Your Lastname e.g Doe</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <textarea  rows={1} className="pt-input pt-fill" min={5} onBlur={this.handleChange} name="surn"  required />
                </p>
                <p>
                  <label className="pt-label tooltiip">Name as on roll <span className="tooltiiptext">Your name as on roll e.g Doe John</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <textarea  rows={1} className="pt-input pt-fill" min={5} onBlur={this.handleChange} name="full_name"  required />
                </p>
                <p>
                  <label className="pt-label tooltiip">Enter email address<span className="tooltiiptext">Format - example@gmail.com</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <input type="email"  autoComplete="off" min={7} className="pt-input pt-fill" onChange={this.handleChange} name="e_addr" required />
                </p>
                <p>
                  <label className="pt-label tooltiip">Enter password<span className="tooltiiptext">Make sure you type your password</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <input type="password" min={5} autoComplete="off" min={5} className="pt-input pt-fill" onChange={this.handleChange} name="password" required />
                </p>
                <p>
                  <p style={{fontWeight: "bold", color: "red"}}>{this.state.validation}</p>
                  <label className="pt-label tooltiip">Re-type password<span className="tooltiiptext">Re-type your password</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <input type="password" min={5} autocomplete="off" ref={(pass) => {this.pass = pass}}  className="pt-input pt-fill" onChange={this.handleValidation} name="password2" required />
                </p>
                <p>
                  <label className="pt-label tooltiip">Enter year of call<span className="tooltiiptext">The year you were called to the bar</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <input type="date" className="pt-input pt-fill" onChange={this.handleChange} name="y_o_call" required />
                </p>
              
                  {
                    (this.state.year)? 
                
                    <p>
                    <label className="pt-label tooltiip">Enter enrolment number<span className="tooltiiptext">Ignore if you were called this year</span></label>
                    <input type="text" className="pt-input pt-fill"  onBlur={this.handleChange} name="enrol_num"  />
                    </p>
                :
              
                    <p>
                    <label className="pt-label tooltiip">Enter enrolment number<span className="tooltiiptext">Your enrolment number</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                    <input type="text" className="pt-input pt-fill" pattern="[A-Za-z0-9]{3,15}" onBlur={this.handleChange} name="enrol_num" required />
                    </p>
                  }
                <div>
                  <label className="pt-label tooltiip">Practice areas (2)<span className="tooltiiptext">Your areas of expertise</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <div className="pt-select pt-fill">
                    <Select
                      value ={this.state.selectedOption}
                      onChange={this.handleChangeS}
                      options={options}
                      isMulti = {true}
                      required = {true}
                    />
                  </div>
                </div>
                <p>
                  <label className="pt-label tooltiip">Enter residential address<span className="tooltiiptext">required</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <textarea rows={1} className="pt-input pt-fill" pattern="[A-Za-z0-9]{5,50}" onBlur={this.handleChange} name="res_addr" required />
                </p>
                <p>
                  <label className="pt-label tooltiip">Enter office address<span className="tooltiiptext">required</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <textarea rows={1} className="pt-input pt-fill" pattern="[A-Za-z0-9]{5,50}" onBlur={this.handleChange} name="off_addr" required />
                </p>
                <p>
                  <label className="pt-label tooltiip">Enter preferred location<span className="tooltiiptext">required</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <textarea rows={1} className="pt-input pt-fill" pattern="[A-Za-z0-9]{5,50}" onBlur={this.handleChange} name="pref_loc" required />
                </p>
                <p>
                  <label className="pt-label tooltiip">Enter date of birth<span className="tooltiiptext">required</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <input type="date" className="pt-input pt-fill"  onBlur={this.handleChange} name="d_o_b" required />
                </p>
                <p>
                  <label className="pt-label tooltiip">Enter mobile number<span className="tooltiiptext">required</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <input type="tel" className="pt-input pt-fill" min={8}  onBlur={this.handleChange} name="p_number" required />
                </p>
                <p>
                  <label className="pt-label ">Enter your whatsapp number (if different from mobile number)</label>
                  <input type="tel" className="pt-input pt-fill" min={8} onBlur={this.handleChange} name="w_number" />
                </p>
                <p>
                  <label className="pt-label tooltiip">Upload your profile picture (.PNG/.JPG)<span className="tooltiiptext">required</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <input type="file" className="pt-input pt-fill" disabled={this.state.file1} onChange={this.uploadwidget} accept="image/png, image/jpeg" required/>
                {this.state.file1?
                <i className="fas fa-check" style={{color: "green", marginLeft: "10px"}}></i>
                : ""
                }
                </p>
                <p>
                  <label className="pt-label">Upload your Call to bar certificate (.PDF/.DOC/.DOCX)</label>
                  <input type="file" className="pt-input pt-fill" accept=" application/pdf, .doc, .docx, application/msword" disabled={this.state.file2} onChange={this.uploadBarwidget} required/>
                  {this.state.file2?
                <i className="fas fa-check" style={{color: "green", marginLeft: "10px"}}></i>
                : ""
                }
                </p>
                <p>
                  <label className="pt-label">Upload your LLB certificate (.PDF/.DOC/.DOCX)</label>
                  <input type="file" className="pt-input pt-fill" accept=" application/pdf, .doc, .docx, application/msword" disabled={this.state.file3} onChange={this.uploadQuawidget} required/>
                  {this.state.file3?
                <i className="fas fa-check" style={{color: "green", marginLeft: "10px"}}></i>
                : ""
                }
                </p>
                <p>
                  <input type="submit" value="Partner with us" disabled={(!this.state.file3)}  className="pt-button pt-intent-primary pull-left" />
                </p>
              </form> 
            </div>
          </div>
        </div>
        </section>
        :
            <Spinner className="text-center" />
          }
        </div>:
        <div className="w3-card-4 w3-center m-t-4" style={{width: '80%', margin: '0 auto'}}>
          <header className="w3-container w3-center w3-green">
            <h1>SERVICE LEVEL AGREEMENT BETWEEN LEGEX PARTNERS AND LAWYER</h1>
          </header>
          <div className="w3-container" style={{height: '250px', width: '100%', overflowY: 'auto'}}>
            
              <div  style={{textAlign:'justify', userSelect: 'none'}} className="user-select">
              <br />
              <h5>Service Level Agreement (SLA)</h5>
              <br />
              This page provides for the service level terms as a registered partner/third party independent contractor of Legex Partners. Please read these SLA carefully as it governs your service delivery with Legex partners which applies to all services and sub-sites available, as well as all text, audio, video, images, graphics, materials, online communications and other information and content that are or become available to Legex Partners (collectively, the "Information"). 
              By using this service, lawyer signifies acceptance and acknowledgement of these SLA and the information provided by lawyer, directly or indirectly, through the service will be managed in accordance with the Legex Partners Privacy Policy.  As such, if you do not accept the Terms stated here, do not use this service.
              Legex partners has access to certain virtual services platform and related features that may require registration of a Lawyer as independent contractor. Where you wish to sign-up as a Legex partner, you shall ensure that you are lawfully authorized to provide all information submitted by you and all such information is true and accurate. You are responsible for updating all information supplied once there is any change. 
              Legex Partners reserves the right to suspend or terminate your access to any account created or any restricted portion of the service should it discover that any information provided by you is no longer true or accurate.
              You are entirely responsible for all activities that occur under such account and you are solely responsible for keeping details of such password and account safe and secure at all times. If you detect or become aware of any unauthorized activity or use of your account or password on with Legex Partners, you shall immediately notify info@legexpartners.com of same so as to immediately disable access to such account.
              Legex Partners shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with, use of your account on the signup site or failure to comply with these SLA. <br />
              <br />
              <h5>General </h5>
              Legex Partners (LP) is the foremost virtual law firm that prides in delivering accessible and affordable legal services from the comfort of any device. This vision is being achieved through the use of multiple virtual channels to deliver legal services on time.  Fit for purpose solutions provided by LP are broadly categorized as follows: <br />
              • Regulatory Compliance Services – enables Lawyer have access to pool of clientele through a tech enabled platform from the comfort of their location thereby bringing legal solutions to clients in areas such as Tax, legal compliance and related matters… <br />
              • Legal Representation – Lawyer can physically represent a client as a result of a schedule initiated via technology. <br />
              <br />
              <h5>1.  Interpretation and Definitions</h5>
              <br/>
              For the purposes of this SLA, the following words shall have the meaning provided below:<br />
              'Legex Partners': The foremost virtual law firm as described and hosted on www.legexpartners.com and which expression shall include its successors in title and assigns.<br />
              'Mylaw website' or 'Site' shall mean all that proprietary information contained, prepared, presented and hosted by mylaw at [http//:www.mylaw.ng] and/or any other website so owned by mylaw for the purposes of facilitating access to the Services.  <br />
              'Officers' shall where the context so admits mean officers, employees, directors and agents of the firm, company or firm being referred to. <br />
              'Partner/Lawyer/independent contractor' shall means any and all registered lawyers and/or law firms on the Panel of Law Firms<br />
              'Supplier' means any individual, firm designated by LEGEX or with whom LEGEX contracts as agent and such other relationships for the purpose of providing services.<br />
              'Agreement' means the Service Level Agreement as contained herein. <br />
              'Virtual Platform Limited means the body corporate with rights and ownership to mylaw and all such services as are provided on the mylaw website, which expression shall include its successors in title and assigns.<br />
              'User' shall mean any and all visitors, and all registered accountholders for legal services.<br />
              'Standard Period of Representation' shall mean 24hrs from the period of lawyer's arrival at location of representation.<br />
              <br />
              <h5>2.  Contractual Relationship</h5>
              <br />
              2.1.    This SLA govern interactions and use of LEGEX virtual space to offer legal services as a Partner.<br />
              2.2.    By continuing use of the virtual platform, you consent to be bound by these agreement and access to Legex virtual space. <br />
              2.3.    Lawyer is required to keep these SLA as they constitute a binding agreement between Lawyer and LEGESX PARTNERS.<br />
              <br />
              <h5>1.  Lawyer's Capacity and Related Matters </h5>
              <br />
              By accepting this Terms, the Lawyer represents and warrants:<br />
              1.1.    To have been called to the Nigerian Bar Association and of an unquestionable character.<br />
              1.2.    All information provided by the Lawyer on and for the purposes of partnering with Legex are at all times true, complete, accurate, neither fraudulent or misleading in any respect.<br />
              1.3.    Lawyer further represents and warrants to have the legal authority to accept the terms herein set out and such acceptance will be binding. <br />
              1.4.    Legex Partners reserves the right in its sole discretion, to refuse access to the referral platform to provide legal services to User in any event that the Lawyer is in breach of any provisions of these Terms. <br />
              <br />
              <h5>2.  Acceptance of Service Level Agreement</h5>
              <br />
              For the avoidance of doubt, Becoming a Legex Partner constitutes acceptance of these Agreement, and any subsequent amendments which may be made from time to time.<br />
              <br />
              <h5>3.  Services and Auto-Renewals</h5>
              <br />
              1.1.    Legex may, at its sole discretion, from time to time allot service requests by users to Lawyer.<br />
              1.2.    Legex has the sole discretion to decide standard professional fees to Lawyer depending on the matter, having taken into consideration several factors to the build-up of such request from user; fees are subject to review from time to time by Legex.<br />
              1.3.    Fees paid to Lawyer for legal representation per transaction is excluding all costs to be incurred during representation.<br />
              1.4.    After lawyer indicates availability for legal representation in a location: the following is expected:<br />
              a)  Lawyer shall arrive at location of representation within 3hrs of request notice.<br />
              b)  Lawyer shall give feedback upon arrival at location via call, text or email or through any medium to be determined by Legex partners from time to time.<br />
              c)  Lawyer gives comprehensive report within 3 working days after representation<br />
              1.5.    Legex partner shall review Lawyer's report based on reasonable endeavor in representation.<br />
              1.6.    Lawyer has the discretion to further represent user/client after the standard representation period.<br />
              <br />
              <h5>2.  Payment</h5>
              <br />
              Lawyer is paid representation fee by Legex partners to account of lawyer (as provided) after verification of report.<br />
              <br />
              <h5>3.  Confidentiality</h5>
              <br />
              3.1.    In compliance with the professional rules and restrictions of the Rules of Professional Conduct, the Legal Practitioners' Act and the Nigeria Bar Association, and for reasons of personal integrity, Legex Partners is bound by stringent professional standards of confidentiality.  Any and ALL information received by us is held in strict confidence and is not released to anyone outside of this practice, unless agreed with by you, or as required under applicable law.<br />
              3.2.    All our records are securely retained in electronic files, along with secure backups.<br />
              <br />
              <h5>4.  Use of LEGEX PLATFORM</h5>
              <br />
              4.1.    Lawyer shall take sole and full responsibility for information/service provided to user/client during representation, i.e, no advice from lawyer shall constitute a legal advice by and on behalf of Legex. As such, your use of LEGEX information/service does not create a perpetual employer-employee relationship.<br />
              4.2.    LEGEX takes no responsibility for the outcome of any act based on an information (legal or otherwise) provided by participating lawyers/panel of lawyers and law firms during representation.<br />
              4.3.    You agree that all decisions and information you make while creating your profile are your full responsibility and you agree to accept and work as an assigned lawyer.<br />
              4.4.    LAWYER may not provide services beyond the standard representation period (SRP) to user except user negotiates for further representation after expiration of SRP.<br />
              4.5.    When you subscribe to join LEGEX partners on LERU, you sign-up to a perpetual non-transferable, non-assignable license but for your use only.  <br />
              4.6.    FOR THE AVOIDANCE OF ANY DOUBT IF YOU ARE UNSURE ABOUT THE LEGAL REPRESENTATION SERVICES YOU ARE SIGNING-UP TO WITH LEGEX PARTNERS, YOU ARE STRONGLY ADVISED TO EITHER REQUEST TO SPEAK WITH A LAWYER VIA THE VIRTUAL LAW FIRM (INFO@LEGEPARTNERS.COM OR CALL08140000829), SEEK FIRM OF ENQUIRY OR REFRAIN FROM COMPLETING THIS SIGNUP.<br />
              <br />
              <h5>5.  Termination of membership and access restriction</h5>
              <br />
              5.1 LEGEX reserves the right, in its sole discretion, to downgrade or terminate your access to this platform, for any reason and at any time without prior notice. <br />
              5.2 If LEGEX elects to terminate your account, a notice will be sent to your registered email address.<br />
              5.3 LEGEX reserves the right to modify or discontinue, either temporarily or permanently, your access to the platform with or without notice to the lawyer.<br />
              5.4 The Lawyer agrees that LEGEX will not be liable to the lawyer or any third party claiming through the lawyer for any modification, suspension, or discontinuance of the lawyer's access to the service. <br />
              <br />
              <h5>6   Ownership and preservation of documents</h5>
              <br />
              6.1 Mylaw does not claim ownership of any information, files or media you either create, upload or store on the virtual platform.<br />
              6.2 You hereby grant LEGEX permission to use your data in connection with your access to the virtual platform. <br />
              6.3 The Lawyer acknowledges and agrees that LEGEX may preserve these information as well as disclose them if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to accomplish any of the following: <br />
              6.3.1   To comply with legal process, any applicable law or government requests;<br />
              6.3.2   To enforce the provisions of these terms; <br />
              6.3.3   To respond to claims that any content violates the rights of third parties; or <br />
              6.3.4   To protect the rights, property, or personal safety of LEGEX, its users and the public.<br />
              6.4 The lawyer understands that the technical processing and transmission of LEGEX, including lawyer's content, may involve transmissions over various networks and changes to conform and adapt to technical requirements of connecting networks or devices. As such, Lawyer agrees that LEGEX has no responsibility or liability for deleting or failing to store any content maintained or uploaded for use by Legex. <br />
              a.  Although LEGEX has no obligation to monitor the Communication Services, LEGEX reserves the right, in its own discretion, to review and remove materials posted in whole or in part including an update on the terms of rendering services on the virtual platform as stated in this agreement.<br />
              b.  LEGEX reserves the right to disclose any material posted, information or activity as necessary to satisfy any applicable law, regulation, legal process, or governmental request.      <br />
              <br />
              <h5>7.  Disclaimer of Responsibility and Liability</h5>
              <br />
              7.1 TO THE FULLEST EXTENT PERMITTED BY LAW, LEGEX PARTNERS MAKES NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE PLATFORM AS THEY ARE PROVIDED 'AS IS'. <br />
              7.2 LEGEX ALSO DISCLAIMS ANY WARRANTIES OR MERCHANTIBILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. INFORMATION AND OPINIONS RECEIVED VIA LEGEX SHOULD BE RELIED UPON FOR PERSONAL OR LEGAL DECISIONS ONLY UPON CONSULTATION WITH LEGEX.<br />
              7.3 IN NO EVENT WILL LEGEX AND/OR ITS AFFILIATES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, REGARDLESS OF LEGAL THEORY, WHETHER OR NOT LEGEX HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE. <br />
              <br />   
              <h5>8.  Release and Indemnity </h5>
              <br />
              8.1 The lawyer personally and on behalf of the lawyer's heirs, executors, agents, representatives, and assigns, fully releases, forever discharges, and holds LEGEX, and/or its affiliates harmless from any and all losses, damages, expenses, rights, clams, and actions of any kind and injury (including death) arising out of or relating to the use of the platform.<br />
              8.2 Lawyer agrees that this release has been freely and voluntarily consented to and confirm that the lawyer fully understand the purpose, intent, form and import of this agreement.<br />
              8.3 The lawyer agrees to indemnify and hold LEGEX and/or its affiliates and their respective officers from any and all losses, damages, expenses, including rights, claims, actions of any kind and injury (including death) arising out of any third party claims relating to use of the LEGEX platform.<br />
              <br />
              <h5>9.  Dispute Resolution </h5>
              <br />
              9.1 Lawyer concerns can be resolved quickly and to the lawyer's satisfaction by calling 08140000829 and/or sending an email to info@legexpartners.com.<br />
              9.2 However, in the event that LEGEX is not able to resolve a dispute with the lawyer after attempts to do so informally, then as a condition to use of the services, the lawyer agrees with LEGEX that such disputes, claims, rights arising thereof shall be resolved through binding arbitration before a sole arbitrator. <br />
              9.3 The Arbitration and Conciliation Act, Cap A18 Laws of the Federal Republic of Nigeria shall be the applicable law for such proceedings. <br />
              9.4 The arbitration proceedings shall be held in Lagos, Nigeria or any other location as to which the LEGEX and the lawyer may then agree. <br />
              9.5 Any party seeking arbitration must first send to the other, by certified or registered mail a written notice of dispute.<br />
              9.6  Any notice to LEGEX should be addressed to: <br />
              LEGEX PARTNERS & CO. <br />
              1b Utomi Airie Avenue, Off Fola Osibo, Lekki Phase 1, Lagos State.<br />
              9.7 Any notice to be sent by Legex partners to the lawyer shall be sent to the lawyer's address set forth in LEGEX records of account or such other legal address as LEGEX is able to identify.<br />
              9.8 The Lawyer may only resolve disputes with LEGEX, the Virtual Law Firm or the Panel of Law Firms/Lawyers on an individual basis, and may not bring a claim as claimant or plaintiff or a class member in a class, consolidated or representative action. Class arbitrations, class actions, private attorney general actions, and consolidations with other arbitrations are not allowed. <br />
              9.9 Notwithstanding this agreement to arbitrate disputes (as provided above), the following exceptions will apply to the resolution of disputes between Legex partners and lawyer:<br />
              9.9.1   Legex Partners may bring a lawsuit against the lawyer in any court of competent jurisdiction for injunctive relief to stop any unauthorized use or abuse of the LEGEX services without first engaging in arbitration or the informal dispute resolution process described above. Legex Partners may bring a lawsuit against the lawyer in any court of competent jurisdiction solely for injunctive relief to stop any intellectual property.<br />
              <br />
              <h5>10. Applicable Law</h5>
              This Terms shall and will be governed by the Laws of the Federal Republic of Nigeria which shall for all intent and purposes be the governing law of this Terms.<br />
              <br />
              <h5>11. Entire Agreement</h5>
              This Terms constitute the entire agreement between the lawyer and LEGEX PARTNERS with respect to its subject matter, and supersedes and replaces any other prior or contemporaneous agreements, or terms and conditions applicable to the subject matter of this Terms. <br />
              <br />
              <h5>12. Waiver, Severability and Assignment</h5>
              <br />
              12.1    The failure of LEGEX PARTNERS to enforce any provision in this Terms is not and shall not be construed as a waiver of its right to do so at a later time. <br />
              12.2    In the event that any provision of this agreement is found unenforceable, the remaining provisions of the agreements will remain in full effect and be enforceable, and such term which reflects the intent of LEGEX PARTNERS as nearly as possible shall be reflected in the agreement instead of any severed term.  <br />
              12.3    The lawyer may not assign any rights under this agreement, and any such attempt to do so shall be void and of not effect. Provided that LEGEX PARTNERS shall reserve the right to assign its rights to any of its affiliates or subsidiaries, or to any successor in interest of any business associated with the Services.  <br />
              </div> 
          </div>
          <footer className="w3-container m-t-3">
            <h5><input type="checkbox" style={{width: '2%'}} value="1" onClick={this.checked} />I agree</h5>
          </footer>
        </div>
        }
        </div>
        <div className="m-t-4">
        </div>
      </section>
    );
  }
}

export default store =>
  ({
    path: `/legex_partner`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({

          }))(LeruPartner)
      );
    }
  });