import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/widgets/Spinner';
import Toaster from '../../components/widgets/Toaster';
import { Link, browserHistory } from 'react-router';
import LeruPay from '../account/LeruPay';
import Modal from 'react-modal';
import axios from 'axios';

class JoinLeru extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fname:'',
      surn:'',
      password: '',
      e_addr:'',
      location:'',
      subscribed: '',
      d_o_b:'null',
      p_number:'',
      e_1_a: '',
      e_1_b: '',
      e_1_c: '',
      e_2_a: '',
      e_2_b: '',
      e_2_c: '',
      s_1_a: '',
      s_1_b: '',
      s_1_c: '',
      s_2_a: '',
      s_2_b: '',
      s_2_c: '',
      s_3_a: '',
      s_3_b: '',
      s_3_c: '',
      p_plan: '2198',
      picture: '',
      validation: '',
      selectedOption: null,
      error: false,
      modalIsOpen: false,
      file1: null,
      show: true,
      data: null,
      isChecked: false,
  };

  }

  checked = (e) => {
    this.setState({isChecked: e.target.value})
  }

  componentWillMount() {
    if(localStorage.getItem('fname')){
      this.setState({fname: localStorage.getItem('fname')});
    }
    if(localStorage.getItem('surn')){
      this.setState({surn: localStorage.getItem('surn')})
    }
    if(localStorage.getItem('password')){
      this.setState({password: localStorage.getItem('password')})
    }
    if(localStorage.getItem('e_addr')){
      this.setState({e_addr: localStorage.getItem('e_addr')})
    }
    if(localStorage.getItem('location')){
      this.setState({location: localStorage.getItem('location')})
    }
    if(localStorage.getItem('d_o_b')){
      this.setState({d_o_b: localStorage.getItem('d_o_b')})
    } 
    if(localStorage.getItem('p_number')) {
      this.setState({p_number: localStorage.getItem('p_number')});
    }
    if(localStorage.getItem('e_1_a')) {
      this.setState({e_1_a: localStorage.getItem('e_1_a')});
    }
    if(localStorage.getItem('e_1_b')) {
      this.setState({e_1_b: localStorage.getItem('e_1_b')});
    }
    if(localStorage.getItem('e_1_c')) {
      this.setState({e_1_c: localStorage.getItem('e_1_c')});
    }
    if(localStorage.getItem('e_2_a')) {
      this.setState({e_2_a: localStorage.getItem('e_2_a')});
    }
    if(localStorage.getItem('e_2_b')) {
      this.setState({e_2_b: localStorage.getItem('e_2_b')});
    }
    if(localStorage.getItem('e_2_c')) {
      this.setState({e_2_c: localStorage.getItem('e_2_c')});
    }
    if(localStorage.getItem('s_1_a')) {
      this.setState({s_1_a: localStorage.getItem('s_1_a')});
    }
    if(localStorage.getItem('s_1_b')) {
      this.setState({s_1_b: localStorage.getItem('s_1_b')});
    }
    if(localStorage.getItem('s_1_c')) {
      this.setState({s_1_c: localStorage.getItem('s_1_c')});
    }
    if(localStorage.getItem('s_2_a')) {
      this.setState({s_2_a: localStorage.getItem('s_2_a')});
    }
    if(localStorage.getItem('s_2_b')) {
      this.setState({s_2_b: localStorage.getItem('s_2_b')});
    }
    if(localStorage.getItem('s_2_c')) {
      this.setState({s_2_c: localStorage.getItem('s_2_c')});
    }
    if(localStorage.getItem('s_3_a')) {
      this.setState({s_3_a: localStorage.getItem('s_3_a')});
    }
    if(localStorage.getItem('s_3_b')) {
      this.setState({s_3_b: localStorage.getItem('s_3_b')});
    }
    if(localStorage.getItem('s_3_c')) {
      this.setState({s_3_c: localStorage.getItem('s_3_c')});
    }
  }

  save = () => {
    if(this.state.fname != "") {
      localStorage.setItem('fname',this.state.fname);
    } 
    
    if(this.state.surn != "") {
      localStorage.setItem('surn',this.state.surn);
    } 
    
    if(this.state.password != "") {
      localStorage.setItem('password',this.state.password);
    } 
    
    if(this.state.e_addr != "") {
      localStorage.setItem('e_addr',this.state.e_addr);
    } 
    
    if(this.state.location != "") {
      localStorage.setItem('location',this.state.location);
    } 
    
    if(this.state.d_o_b != "") {
      localStorage.setItem('d_o_b',this.state.d_o_b);
    } 

    if(this.state.p_number != "") {
      localStorage.setItem('p_number', this.state.p_number);
    }

    if(this.state.e_1_a != "") {
      localStorage.setItem('e_1_a', this.state.e_1_a);
    }

    if(this.state.e_1_b != "") {
      localStorage.setItem('e_1_b', this.state.e_1_b);
    }

    if(this.state.e_1_c != "") {
      localStorage.setItem('e_1_c', this.state.e_1_c);
    }

    if(this.state.e_2_a != "") {
      localStorage.setItem('e_2_a', this.state.e_2_a);
    }

    if(this.state.e_2_b != "") {
      localStorage.setItem('e_2_b', this.state.e_2_b);
    }

    if(this.state.e_2_c != "") {
      localStorage.setItem('e_2_c', this.state.e_2_c);
    }

    if(this.state.s_1_a != "") {
      localStorage.setItem('s_1_a', this.state.s_1_a);
    }

    if(this.state.s_1_b != "") {
      localStorage.setItem('s_1_b', this.state.s_1_b);
    }

    if(this.state.s_1_c != "") {
      localStorage.setItem('s_1_c', this.state.s_1_c);
    }

    if(this.state.s_2_a != "") {
      localStorage.setItem('s_2_a', this.state.s_2_a);
    }

    if(this.state.s_2_b != "") {
      localStorage.setItem('s_2_b', this.state.s_2_b);
    }

    if(this.state.s_2_c != "") {
      localStorage.setItem('s_2_c', this.state.s_2_c);
    }

    if(this.state.s_3_a != "") {
      localStorage.setItem('s_3_a', this.state.s_3_a);
    }

    if(this.state.s_3_b != "") {
      localStorage.setItem('s_3_b', this.state.s_3_b);
    }

    if(this.state.s_3_c != "") {
      localStorage.setItem('s_3_c', this.state.s_3_c);
    }

    window.scrollTo(0,0);
    Toaster.success("Your data has been saved");
  }

  openModal = () => {
    //this.setState({modalIsOpen: true});
    setTimeout(()=>{browserHistory.push('/leruLogin');}, 10000);
  }
 
  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#f00';
  }
 
  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  uploadwidget = (e) => {
    e.preventDefault();
    var that = this;
    var file = e.target.files[0];
    var formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', "roogoybk");

    axios({
      url: 'https://api.cloudinary.com/v1_1/mylaw-ng/image/upload',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData
    }).then(function(res){
      that.setState({file1 : "file"})
      that.setState({picture: res.data.secure_url})
      console.log(res);
    }).catch(function(err){
      console.log(err);
    });
  }



  handleChange = (e) => {
    const { name, value, checked } = e.target || e.srcElement;


    switch(name) {
        case 'fname':
            this.setState({fname: value});
            break;
        case 'surn':
            this.setState({surn: value});
            break;
        case 'e_addr':
            this.setState({e_addr: value});
            break;
        case 'password':
            this.setState({password: value});
            break;
        case 'location':
            this.setState({location: value});
            break;
        case 'p_number':
            this.setState({p_number: value});
            break;
        case 'd_o_b':
            this.setState({d_o_b: value});
            break;
        case 'e_contact_name1':
            this.setState({e_1_a: value});
            this.setState({s_1_a: value});
            break;
        case 'e_contact_email1':
            this.setState({e_1_b: value});
            this.setState({s_1_b: value});
            break;
        case 'e_contact_p_number1':
            this.setState({e_1_c: value});
            this.setState({s_1_c: value});
            break;
        case 'e_contact_name2':
            this.setState({e_2_a: value});
            this.setState({s_2_a: value});
            break;
        case 'e_contact_email2':
            this.setState({e_2_b: value});
            this.setState({s_2_b: value});
            break;
        case 'e_contact_p_number2':
            this.setState({e_2_c: value});
            this.setState({s_2_c: value});
            break;
        case 'surety_name1':
            this.setState({s_1_a: value});
            break;
        case 'surety_email1':
            this.setState({s_1_b: value});
            break;
        case 'surety_p_number1':
            this.setState({s_1_c: value});
            break;
        case 'surety_name2':
            this.setState({s_2_a: value});
            break;
        case 'surety_email2':
            this.setState({s_2_b: value});
            break;
        case 'surety_p_number2':
            this.setState({s_2_c: value});
            break;
        case 'surety_name3':
            this.setState({s_3_a: value});
            break;
        case 'surety_email3':
            this.setState({s_3_b: value});
            break;
        case 'surety_p_number3':
            this.setState({s_3_c: value});
            break;
        case 'p_plan':
            this.setState({p_plan: value});
            break;
        
        default:
            break;
    }
  }

  handleValidation = (e) => {
    const {name, value} = e.target || e.srcElement;
    if(name === 'password2') {
      if (value === this.state.password){
        console.log(`%c If you are seeing this, Stop!!`,'font-size:40px; color: red; background: white')
        this.setState({validation: ""})
      } else {
        this.setState({validation: "PASSWORDS DOESN'T MATCH"})
        this.pass.focus();
      }
    }
  }


  handleSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0,0);
    const { fname, surn, password, e_addr, location, d_o_b, p_number, picture,e_1_a, e_1_b, e_1_c, e_2_a, e_2_b, e_2_c,
      s_1_a, s_1_b, s_1_c, s_2_a, s_2_b, s_2_c, s_3_a, s_3_b, s_3_c } = this.state;
    this.setState({show: false});

    if(fname == "" && surn == "" && password == "" && e_addr == "" && location == "" && d_o_b == "" && p_number == "" && picture == "" && e_1_a == "" && e_1_b == "" && e_1_c == "" && e_2_a == "" && e_2_b == "" && e_2_c == ""&& s_1_a == "" && s_1_b == "" && s_1_c == "" && s_2_a == "" && s_2_b == "" && s_2_c == "" && s_3_a == "" && s_3_b == "" && s_3_c == "") {
      return false;
    }

    if(s_3_a == "") {
      this.setState({s_3_a: "null"});
    } else if(s_3_b == "") {
      this.setState({s_3_b: "null"});
    } else if(s_3_c == "") {
      this.setState({s_3_c: "null"});
    }

    if(s_2_a == "") {
      this.setState({s_2_a: "null"});
    } else if(s_2_b == "") {
      this.setState({s_2_b: "null"});
    } else if(s_2_c == "") {
      this.setState({s_2_c: "null"});
    }
    if(s_1_a == "") {
      this.setState({s_1_a: "null"});
    } else if(s_1_b == "") {
      this.setState({s_1_b: "null"});
    } else if(s_1_c == "") {
      this.setState({s_1_c: "null"});
    }

    if(e_2_a == "") {
      this.setState({e_2_a: "null"});
    } else if(e_2_b == "") {
      this.setState({e_2_b: "null"});
    } else if(e_2_c == "") {
      this.setState({e_2_c: "null"});
    }

    const data = {
     name : fname,
     surname : surn,
     fullname :  `${fname} ${surn}`,
     password,
     location,
     email_addr : e_addr,
     date_of_birth : d_o_b,
     subscribed: false,
     subscription_date: false,
     profile_picture : picture,
     phone_number : p_number,
     surety_1: `${e_1_a} ${e_1_b} ${e_1_c}`,
     surety_2: `${e_2_a} ${e_2_b} ${e_2_c}`,
     surety_3: `${s_1_a} ${s_1_b} ${s_1_c}`,
     surety_4: `${s_2_a} ${s_2_b} ${s_2_c}`,
     surety_5: `${s_3_a} ${s_3_b} ${s_3_c}`,
     status: false,
  };

  console.log(this.state.data)
  console.log(data);
  const that = this;
  axios({
    url: 'https://cors-anywhere.herokuapp.com/https://immense-tor-56766.herokuapp.com/api/user/create.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  }).then(function(res){
    that.setState({data: data});
    that.setState({show: true})
    that.openModal();
    console.log(res.status);
    if((res.status == 200) || (res.status== 201)) {
      console.log(res.status);
      that.setState({error: false});
      that.setState({file1: null});
      that.setState({completed: true})
      console.log("%cUser created","font-size:30px; color: white; background: black")
    } else {
    that.setState({error: true});
  }
  }).catch(function(err){
    console.error(err)
  that.setState({show: true})
  that.openModal();
  that.setState({error: true});
  });
}
  
  render() {
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
      }
    };
    return (
      <div>
        {
        !this.state.completed?
      <section >
        <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            {
              this.state.error? <h2 ref={subtitle => this.subtitle = subtitle}>Network Error - Incomplete field</h2> : <h2 ref={subtitle => this.subtitle = subtitle}>Account Created - Proceed to payment</h2>
            }
            
            <button onClick={this.closeModal}>close</button>
            <div>
              
              {
              this.state.error? <p> Reload the page and try again</p> : <p> </p>
            }
            </div>
        </Modal>
        <div >
          {
          this.state.show?
          <section className="landing-page-section">
          <div className="landing-header m-t-2" style={{background: '#3e8e41'}}>
            <Link to={'/home'}>
              <div className="landing-logo-text" style={{fontWeight: "bold", fontSize: "30px", color: "white", marginLeft: '45px' }}>Leru</div>
            </Link>
          </div>
        <div className="w3-cell-row" >
          <div className="w3-container w3-cell w3-mobile m-t-3" style={{textAlign: 'center'}}>
                <h1>FREQUENTLY ASKED QUESTIONS (LERU)</h1>
                <div className="m-t-4">
                <h4>What is LERU?</h4>
                <p>LERU is the acronym for Legex Emergency Response Unit.<br />
                 It is a legal service platform that allows you access to a lawyer<br />
                in times of emergencies involving law enforcement and/or regulatory<br />
                 agencies such as the police, LASMA Local government etc.</p>
                <h4>How do I subscribe?</h4>
                <p>Simply log on to www.mylaw.ng and click on the "LERU" icon,<br />
                 select "subscribe to LERU". You may also register with the<br />
                  link- https://www.mylaw.ng/join_leru. Fill in your details correctly.</p>
                <h4>How do I make payment?</h4>
                <p>You can make payment on our payment platform on the mylaw.ng portal<br />
                 which allows you to make multiple forms of payment which includes, card<br />
                  payments and internet banking.</p>
                <h4>How much will I pay?</h4>
                <p>You will pay a token of one thousand naira (N1,000.00) for the monthly package<br />
                 and ten thousand naira only (N10, 000. 00) for the annual package. </p>

                <h4>When do I start enjoying the service?</h4>
                <p>After subscription, for annual subscription you will be entitld to receive<br />
                 the service of a lawyer immediately  and for a monthly subscription, you will be entited<br />
                  to receive the service of a lawyer after one month has elapsed as long as your monthly<br />
                   payments are up to date.</p>
                <h4>How do I get a lawyer in an emergency?</h4>
                <p>If  you need a lawyer in emergency you may call or text  08181866586, 08181866579,08140000829<br />
                 or visit the portal to request for a lawyer and one will be made available to you as soon as possible.</p>
                <h4>How do I identify a LERU lawyer ?</h4>
                <p>A profile of the lawyer is made available to you once the lawyer is sent and<br />
                 the lawyer will arrive bearing his LERU identity card.</p>
                </div>
          </div>
          <div className="w3-container w3-cell w3-mobile">
            <h1 className="smart-text">
              Signup to LERU
            </h1>
          <div className="cta-container">
            <form onSubmit={this.handleSubmit} method="post" autoComplete="off">
              <input type="email" style={{display: "none"}} />
              <input type="password" style={{display: "none"}} />
              <p>
              <label className="pt-label tooltiip">Enter firstname <span className="tooltiiptext">Your Firstname e.g John</span> <span style={{color: "red",marginLeft: "10px"}}>*</span></label>
              <textarea value={this.state.fname}  rows={1} className="pt-input pt-fill" onChange={this.handleChange} name="fname"  required />
              </p>
              <p>
                <label className="pt-label tooltiip">Enter surname<span className="tooltiiptext">Your Lastname e.g Doe</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                <textarea value={this.state.surn} rows={1} className="pt-input pt-fill" onChange={this.handleChange} name="surn"  required />
              </p>
              <p>
                <label className="pt-label tooltiip">Enter email address<span className="tooltiiptext">Format - example@gmail.com</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                <input type="email" autoComplete="off" value={this.state.e_addr} autocomplete="off" className="pt-input pt-fill" onChange={this.handleChange} name="e_addr" required />
              </p>
              <p>
                <label className="pt-label tooltiip">Enter password<span className="tooltiiptext">Make sure you type your password</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                <input type="password" autoComplete="off" value={this.state.password} autocomplete="off"  className="pt-input pt-fill" onChange={this.handleChange} name="password" required />
              </p>
              <p>
                  <p style={{fontWeight: "bold", color: "red"}}>{this.state.validation}</p>
                  <label className="pt-label tooltiip">Re-type password<span className="tooltiiptext">Re-type your password</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                  <input type="password" min={5} autocomplete="off" ref={(pass) => {this.pass = pass}}  className="pt-input pt-fill" onChange={this.handleValidation} name="password2" required />
                </p>
              <p>
                <label className="pt-label tooltiip">Enter your address <span className="tooltiiptext">required</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                <input value={this.state.location} className="pt-input pt-fill" onChange={this.handleChange}  name="location" type="text" required />
              </p>
              <p>
                <label className="pt-label tooltiip">Enter mobile number<span className="tooltiiptext">required</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                <input value={this.state.p_number} type="tel" className="pt-input pt-fill" onChange={this.handleChange} name="p_number" required />
              </p>
              <p>
                <label className="pt-label tooltiip">Enter date of birth</label>
                <input value={this.state.d_o_b} type="date" className="pt-input pt-fill" onChange={this.handleChange} name="d_o_b" />
              </p>
              
              <p>
                <b><label className="pt-label">Enter emergency contacts (1)</label></b>
                      <p>
                        <p>
                        <label className="pt-label">Contact name</label>
                          <input value={this.state.e_1_a} type="text" className="pt-input pt-fill" onChange={this.handleChange} name="e_contact_name1" required />
                        </p>
                        <p>
                          <label className="pt-label">Contact email</label>
                          <input value={this.state.e_1_b} type="email" className="pt-input pt-fill" onChange={this.handleChange} name="e_contact_email1" required />
                        </p>
                        <p>
                          <label className="pt-label">Contact phone number</label>
                          <input value={this.state.e_1_c} type="number" className="pt-input pt-fill" onChange={this.handleChange} name="e_contact_p_number1" required />
                        </p>
                      </p>
                    <label className="pt-label">Enter emergency contacts (2)</label>
                      <p>
                        <p>
                        <label className="pt-label">Contact name</label>
                          <input value={this.state.e_2_a} type="text" className="pt-input pt-fill" onChange={this.handleChange} name="e_contact_name2" />
                        </p>
                        <p>
                          <label className="pt-label">Contact email</label>
                          <input value={this.state.e_2_b} type="email" className="pt-input pt-fill" onChange={this.handleChange} name="e_contact_email2" />
                        </p>
                        <p>
                          <label className="pt-label">Contact phone number</label>
                          <input value={this.state.e_2_c} type="number" className="pt-input pt-fill" onChange={this.handleChange} name="e_contact_p_number2" />
                        </p>
                      </p>
              </p>
              <p>
                <label className="pt-label">Enter Surety (1)</label>
                      <p>
                        <p>
                        <label className="pt-label">Surety name</label>
                          <input value={this.state.s_1_a} type="text" className="pt-input pt-fill" onChange={this.handleChange} name="surety_name1" />
                        </p>
                        <p>
                          <label className="pt-label">Surety email</label>
                          <input value={this.state.s_1_b} type="email" className="pt-input pt-fill" onChange={this.handleChange} name="surety_email1" />
                        </p>
                        <p>
                          <label className="pt-label">Surety phone number</label>
                          <input value={this.state.s_1_c} type="number" className="pt-input pt-fill" onChange={this.handleChange} name="surety_p_number1" />
                        </p>
                      </p>
                    <label className="pt-label">Enter Surety (2)</label>
                      <p>
                        <p>
                        <label className="pt-label">Surety name</label>
                          <input value={this.state.s_2_a} type="text" className="pt-input pt-fill" onChange={this.handleChange} name="surety_name2" />
                        </p>
                        <p>
                          <label className="pt-label">Surety email</label>
                          <input value={this.state.s_2_b} type="email" className="pt-input pt-fill" onChange={this.handleChange} name="surety_email2" />
                        </p>
                        <p>
                          <label className="pt-label">Surety phone number</label>
                          <input value={this.state.s_2_c} type="text" className="pt-input pt-fill" onChange={this.handleChange} name="surety_p_number2" />
                        </p>
                      </p>
                      <label className="pt-label">Enter Surety (3)</label>
                      <p>
                        <p>
                        <label className="pt-label">Surety name</label>
                          <input value={this.state.s_3_a} type="text" className="pt-input pt-fill" onChange={this.handleChange} name="surety_name3" />
                        </p>
                        <p>
                          <label className="pt-label">Surety email</label>
                          <input value={this.state.s_3_b} type="email" className="pt-input pt-fill" onChange={this.handleChange} name="surety_email3" />
                        </p>
                        <p>
                          <label className="pt-label">Surety phone number</label>
                          <input value={this.state.s_3_c} type="text" className="pt-input pt-fill" onChange={this.handleChange} name="surety_p_number3" />
                        </p>
                      </p>
                      <p>

                      <label className="pt-label">Payment plan</label>
                      <select onChange={this.handleChange} className="pt-fill" name="p_plan"> 
                        <option value="2198">Monthly</option>
                        {/* <option value="2720">Yearly</option> */}
                      </select> 
                      </p>
                      <p>
                        <label className="pt-label tooltiip">Upload your profile picture (.PNG/.JPG)<span className="tooltiiptext">required</span><span style={{color: "red",marginLeft: "10px"}}>*</span></label>
                        <input type="file" className="pt-input pt-fill" disabled={this.state.file1} onChange={this.uploadwidget} required/>
                          {this.state.file1?
                        <i className="fas fa-check" style={{color: "green", marginLeft: "10px"}}></i>
                        : ""
                        }
                      </p>
                      <p>
                      <span><input type="checkbox" style={{width: '10%'}} value="1" onClick={this.checked} />I agree to the<Link to={'/leruTerms'}> Terms of Use</Link></span>
                      </p>
              </p>

              <div onClick={this.save} style={{marginRight: '5px'}} className="pt-button pt-intent-primary pull-left">Save & Continue later</div>
        
              <span style={{pointerEvents: this.state.isChecked? "": 'none'}}>
                <button onClick={this.handleSubmit} disabled={!this.state.file1} className="pt-button pt-intent-primary pull-left">Subscribe</button>
              </span>
            </form>
          </div>
          </div>
        </div>
      </section>: 
      <Spinner />
        }
      </div>
      </section>
      :
      <div className="w3-container w3-mobile">
      <LeruPay
              callback={this.handleSubmit}
              title="Checkout"
              uData = {{
                name : this.state.fname,
                surname : this.state.surn,
                fullname :  `${this.state.fname} ${this.state.surn}`,
                password : this.state.password,
                location : this.state.location,
                email_addr : this.state.e_addr,
                date_of_birth : this.state.d_o_b,
                subscribed: true,
                subscription_date: false,
                profile_picture : this.state.picture,
                phone_number : this.state.p_number,
                surety_1: `${this.state.e_1_a} ${this.state.e_1_b} ${this.state.e_1_c}`,
                surety_2: `${this.state.e_2_a} ${this.state.e_2_b} ${this.state.e_2_c}`,
                surety_3: `${this.state.s_1_a} ${this.state.s_1_b} ${this.state.s_1_c}`,
                surety_4: `${this.state.s_2_a} ${this.state.s_2_b} ${this.state.s_2_c}`,
                surety_5: `${this.state.s_3_a} ${this.state.s_3_b} ${this.state.s_3_c}`,
                status: false,
             }}
              user={{
                country: 'NG',
                phone: this.state.p_number,
                email: this.state.e_addr,
                plan: this.state.p_plan
              }}
              data={{
                amount: 1000
              }} {...this.props}
        />
      </div>
      }
      </div>
    );
  }
}

export default store =>
  ({
    path: `/join_leru`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({

          }))(JoinLeru)
      );
    }
  });

