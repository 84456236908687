import React from 'react';
import { connect } from 'react-redux';
import MylawyerPay from '../account/MylawyerPay';
import Spinner from '../../components/widgets/Spinner';
import Toaster from '../../components/widgets/Toaster';
import { Link, browserHistory } from 'react-router';
import Select from 'react-select';
import Modal from 'react-modal';
import axios from 'axios';


class Mylawyer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  componentWillMount() {
    console.log(this.props)
   
  }
  
  render() {
    
    return (
      <div>

          <MylawyerPay
              callback={this.handleSubmit}
              title="Checkout"
              uData = {{
                name : localStorage.getItem('ml_n'),
                message : localStorage.getItem('ml_m'),
                email_addr : localStorage.getItem('ml_e'),
             }}
              user={{
                country: 'NG',
                phone: null,
                email: localStorage.getItem('ml_e'),
                plan: 2853
              }}
              data={{
                amount: 200
              }} {...this.props}
        />
      </div>
    );
  }
}

export default store =>
  ({
    path: `/mylawyer`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({

          }))(Mylawyer)
      );
    }
  });