import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { Document, Page } from 'react-pdf';
import { ProgressBar, Intent } from '@blueprintjs/core';
import {
  DateInput,
  TimePicker,
  TimePickerPrecision,
} from '@blueprintjs/datetime';
import Toaster from '../../components/widgets/Toaster';
import {
  showConfirmModal,
  showSigninModal,
  showSignupModal,
} from '../../actions/modalActions';
import { all as getCategories } from '../../actions/categoryActions';
import {
  getTemplate,
  create,
  update,
  find,
} from '../../actions/documentActions';
import { update as updateUser } from '../../actions/userActions';
import { create as createPayment } from '../../actions/paymentActions';
import Spinner from '../../components/widgets/Spinner';
import CreditCard from '../../components/widgets/CreditCard';
import Checkout from '../../components/widgets/Checkout';
import Payment from '../account/Payment';
import NoItem from '../../components/widgets/NoItem';
import Icon from '../../components/widgets/Icon';
import Heading from '../../components/widgets/Heading';
import {
  create as createCard,
  remove as removeCard,
  all as getAllCards,
} from '../../actions/cardActions';
import {
  create as createSubscription,
  me as mySubscription,
} from '../../actions/subscriptionActions';
import { cannotUseSubscription } from '../../lib/utils';

class DocumentCreate extends React.Component {
  componentWillMount() {
    const {
      params: { id, document_id },
      find,
      mySubscription,
      getAllCards,
      state: {
        session: { user },
        subscription: { item },
      },
    } = this.props;

    this.state = {
      page: 0,
      crudA: '',
      pdfURL: `${window.SW_DOMAIN}/api/v1/document-template/${id}/preview`,
    };

    if (!document_id) {
      this.getDocumentTemplate(id);
    } else {
      this.state.pdfURL = `${window.SW_DOMAIN}/api/v1/document/${document_id}/preview`;
      if (item && item.type) {
        this.state.pdfDownloadURL = `${window.SW_DOMAIN}/api/v1/document/${document_id}/download?subscription=${item.type}`;
      } else {
        this.state.pdfDownloadURL = `${window.SW_DOMAIN}/api/v1/document/${document_id}/download`;
      }
      find(document_id, (err, document) => {
        window.document.title = `${document.template_expanded.title} | ${window.APP_TITLE}`;
        document.template_expanded.fields =
          document.template_expanded.fields || [];
        document.fields = document.fields || [];
        this.setState({ document });
      });
    }

    if (user) {
      mySubscription(user);
      getAllCards(user);
    }
  }
  /**
   * Get a document template by ID
   */
  getDocumentTemplate = (_id) => {
    this.props.getTemplate(_id, (err, template) => {
      if (!err) {
        const document = {
          progress: 0,
          template: template._id,
          template_expanded: template,
          question_index: -1,
          fields: template.fields || [],
        };

        window.document.title = `${document.template_expanded.title} | ${window.APP_TITLE}`;
        this.setState({ document, template });
      } else {
        Toaster.error(err.message);
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      props: {
        create,
        update,
        params: { document_id },
        state: {
          subscription: {
            item: { type },
          },
        },
      },
      state: { document, answer: answer_ },
    } = this;
    const { elements } = e.target || e.srcElement;
    let field;
    if (elements) {
      field = elements.answer;
    }

    const answer = field ? field.value : answer_;
    const idx = document.question_index;
    const nextIndex = this.nextQuestionIndex();
    if (nextIndex === null) {
      return;
    }
    document.question_index = nextIndex;
    document.fields[idx].answer = answer;

    const cb = (document) => {
      const pdfURL = `${
        window.SW_DOMAIN
      }/api/v1/document/${document_id}/preview?time=${new Date().getTime()}&subscription=${type}`;
      this.setState({ document, pdfURL });
    };

    if (document._id) {
      delete document.template_expanded.body;
      delete document.template_expanded.description;
      update(document._id, document, (err, res) => {
        if (!err) {
          cb(document);
        } else {
          Toaster.error('Error saving document, please try again');
        }
      });
    } else {
      create(document, (err, document) => {
        if (!err) {
          browserHistory.push(
            `/document-category/templates/${document.template}/documents/${document._id}`
          );
          cb(document);
        } else {
          Toaster.error('Error saving document, please try again');
        }
      });
    }
  };

  handleChange = (e) => {
    const { value: answer } = e.target || e.srcElement;
    this.setState({ answer });
  };

  renderField = ({ type, required, answer, options }) => {
    switch (type) {
      case 'number':
        return (
          <input
            ref="answer"
            type="number"
            defaultValue={answer}
            required={required}
            onChange={this.handleChange}
            className="pt-input pt-fill"
            name="answer"
            placeholder=""
          />
        );
      case 'paragraph':
        return (
          <textarea
            ref="answer"
            defaultValue={answer}
            required={required}
            onChange={this.handleChange}
            className="pt-input pt-fill"
            name="answer"
            placeholder=""
          />
        );
      case 'date':
        return (
          <div className="date-time-popover">
            <DateInput
              ref="answer"
              className="pt-input pt-fill"
              onChange={(value) =>
                this.handleChange({ target: { name: answer, value } })
              }
              defaultValue={answer ? new Date(answer) : null}
            />
          </div>
        );
      case 'time':
        return (
          <div className="date-time-popover">
            <TimePicker
              ref="answer"
              className="pt-input pt-fill"
              showArrowButtons={false}
              onChange={(value) =>
                this.handleChange({ target: { name: answer, value } })
              }
              defaultValue={answer ? new Date(answer) : null}
            />
          </div>
        );
      case 'options':
        return (
          <label className="pt-label">
            <div className="pt-select">
              <select ref="answer" defaultValue={answer} name="answer">
                {options &&
                  options.map((option, idx) => (
                    <option key={idx} label={option} value={option}>
                      {option}
                    </option>
                  ))}
              </select>
            </div>
          </label>
        );
      default:
        return (
          <input
            ref="answer"
            type="text"
            defaultValue={answer}
            required={required}
            onChange={this.handleChange}
            className="pt-input pt-fill"
            name="answer"
            placeholder=""
          />
        );
    }
  };

  startOver = () => {
    const { document } = this.state;
    document.progress = 0;
    document.question_index = 0;
    this.setState({ document });
  };

  handleSetReadOnly = () => {
    localStorage.removeItem('Template');
    localStorage.removeItem('crudA');
    this.setState({ crudA: '' });
    const { document } = this.state;
    document.downloaded = true;
    this.setState({ document });
  };

  renderQuestion = (idx) => {
    let field = null;
    const {
      document: { fields },
    } = this.state;
    const { isLoading } = this.props.state.document;
    const question = fields[idx];

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <h3>{question.question}</h3>
          <p style={{ textAlign: 'justify' }}>{question.description}</p>
          <div className="m-t-1 m-b-1">{this.renderField(question)}</div>
          <p>
            <button
              type="button"
              disabled={isLoading || !idx}
              onClick={() => this.nextQuestion(false)}
              className="pt-button"
            >
              Back
            </button>
            <button
              disabled={isLoading}
              className="pt-button pt-intent-primary pull-right"
            >
              Next
            </button>
            <a className="btn-skip pull-right" onClick={this.handleSubmit}>
              Skip
            </a>
          </p>
        </form>
      </div>
    );
  };

  progressIntent = (progress) => {
    if (progress < 0.3) {
      return Intent.DANGER;
    } else if (progress < 0.5) {
      return Intent.WARNING;
    } else if (progress < 0.9) {
      return Intent.PRIMARY;
    } else {
      return Intent.SUCCESS;
    }
  };

  getProgress = () => {
    const {
      document: { fields, question_index },
    } = this.state;
    let answers = question_index;
    if (answers + 1 > 0 && fields[answers]) {
      answers += fields[answers].answer ? 1 : 0;
      return Number(answers / fields.length);
    }

    return 0;
  };

  onDocumentLoad = ({ total: pages }) => {
    this.onResize();
    this.setState({ pages });
  };

  onPageLoad = (data) => {
    console.log('loaded', data);
  };

  onResize = () => {
    const div = window.document.getElementById('pdf-div');
    if (div) {
      const contentWidth = div.offsetWidth - 40;
      this.setState({ contentWidth });
    }
  };

  nextQuestionIndex = (next = true) => {
    let {
      document: { question_index, fields },
    } = this.state;
    const {
      state: {
        user: { account },
      },
      showConfirmModal,
      showSignupModal,
      showSigninModal,
    } = this.props;
    const idx = question_index;
    if (next) {
      const limit = Math.floor(fields.length / 2);
      if (!account && question_index >= limit) {
        showConfirmModal({
          title: 'Complete your document',
          message:
            "To continue with your document creation, you'll need to create an account or sign in with an existing account",
          buttons: [
            {
              action: showSigninModal.bind(this, {}),
              label: 'Sign in',
              intent: 'primary',
              noClose: true,
            },
            {
              action: showSignupModal.bind(this, {}),
              label: 'Sign up',
              intent: 'primary',
              noClose: true,
            },
          ],
        });

        return null;
      }
      question_index++;
    } else {
      question_index--;
    }

    question_index = fields[question_index] ? question_index : idx;
    setTimeout(() => {
      if (this.refs.answer) {
        this.refs.answer.value = fields[question_index].answer || '';
      }
    }, 200);
    return question_index;
  };

  nextQuestion = (next = true) => {
    const { document } = this.state;
    document.question_index = this.nextQuestionIndex(next);
    if (document.question_index !== null) {
      this.setState({ document, checkoutType: null });
    }
  };

  handleClick = () => {};

  pageNav(dir) {
    let page = this.state.page;
    if (dir) {
      page++;
    } else {
      page--;
    }

    this.setState({ page });
  }

  onPayment = (document) => {
    const {
      props: {
        state: {
          session: { user },
        },
        mySubscription,
      },
    } = this;

    mySubscription(user);
    this.setState({ document });
  };

  renderBreadCrumbs = (item) => {
    const name = item.template_expanded.title;
    return (
      <div>
        <ul className="pt-breadcrumbs">
          <li>
            <Link className="pt-breadcrumbs-collapsed" to="/"></Link>
          </li>
          {/* <li>
            <Link className="pt-breadcrumb" to="/document-categories">
              Agreement Templates
            </Link>
          </li> */}
          <li>
            <Link className="pt-breadcrumb" to="/documents">
              Agreements
            </Link>
          </li>
          <li>
            <Link className="pt-breadcrumb pt-breadcrumb-current">
              {name && name.ucFirst()}
            </Link>
          </li>
        </ul>
        <br />
        <br />
      </div>
    );
  };

  renderQuestionSection(question_index) {
    if (question_index >= 0) {
      return this.renderQuestion(question_index);
    } else {
      return (
        <div className="text-center">
          <p
            className="text-muted"
            style={{ textAlign: 'justify' }}
            dangerouslySetInnerHTML={{
              __html: this.state.template.description.asHTML(),
            }}
          />
          <h3 className="slim">All Set! Click to begin completing document</h3>
          <div className="m-t-2">
            <button
              className="pt-button pt-intent-primary"
              onClick={() => {
                this.nextQuestion();
                this.handleClick();
              }}
            >
              Begin
            </button>
          </div>
        </div>
      );
    }
  }

  renderDocumentPreview(page, pages, contentWidth, pdfURL) {
    return (
      <div
        className="pt-card document-container pt-elevation-2 m-t-2"
        id="pdf-div"
      >
        <h4 className="m-t-2 m-b-2 text-center">Preview</h4>
        {/* {document._id && <iframe ref={previewFrame => this.previewFrame = previewFrame} src={`${window.SW_DOMAIN} /api/v1/document/${document._id}/preview`} className="iframe-preview"></iframe>}
          {!document._id && <iframe src={`${window.SW_DOMAIN}/api/v1/document-template/${document.template}/preview`} className="iframe-preview"></iframe>} */}

        <Document
          file={pdfURL}
          onLoadSuccess={this.onDocumentLoad}
          style={{ width: '100%', height: 'auto' }}
          loading={<Spinner className="m-t-4 text-center" />}
        >
          <Page
            pageNumber={page + 1}
            width={contentWidth}
            onLoadSuccess={this.onPageLoad}
          />
        </Document>

        {pages ? (
          <div className="pages">
            <button
              disabled={pages <= pages - pages}
              className="pt-button"
              onClick={this.pageNav.bind(this, false)}
            >
              &lt;
            </button>
            <span className="text-muted">
              Page {page + 1} of {pages}
            </span>
            <button
              disabled={page + 1 >= pages}
              className="pt-button"
              onClick={this.pageNav.bind(this, true)}
            >
              &gt;
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const {
      state: {
        user: { account },
        subscription: { item: subscription, packages },
        document: {
          template: { isLoading },
        },
      },
    } = this.props;

    const {
      document,
      pdfURL,
      pdfDownloadURL,
      page = 0,
      pages = 0,
      contentWidth,
    } = this.state;

    if (isLoading || !document) {
      return <Spinner className="m-t-4 text-center" />;
    }

    const {
      fields,
      _id,
      question_index,
      template_expanded: template,
    } = document;

    if (!template) {
      return (
        <NoItem className="m-t-4">
          The template you tried to access was not found.
        </NoItem>
      );
    }

    template.description = template.description || '';
    const { subscriptions } = template;
    const progress = this.getProgress();

    return (
      <div className="DocumentCreate">
        <div className="content-width" style={{ maxWidth: 1360 }}>
          {this.renderBreadCrumbs(document)}
          <Heading title={template.title} />
          {subscriptions && subscriptions.length ? (
            <div className="text-right m-b-2">
              <b className="text-muted">
                The following subscription packages do not cover this document
              </b>
              <div>
                {subscriptions.map((item) => (
                  <span
                    key={item}
                    className="pt-tag pt-intent-warning m-t-1 m-l-1"
                  >
                    {packages[item].name}
                  </span>
                ))}
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col_md_35 col_sm_100 m-b-3">
              <div className="p-a-2 pt-card pt-elevation-2 text-left">
                <div dangerouslySetInnerHTML={{ __html: template.faqs }} />
                {!template.faqs && <NoItem>No FAQs for this document</NoItem>}
              </div>
            </div>
            <div className="col_md_65 col_sm_100">
              <div>
                {progress < 1 ? (
                  <div className="pt-card pt-elevation-3">
                    <h1 className="text-center text-muted">
                      {Math.round(progress * 100)}%
                    </h1>
                    <div className="m-b-4">
                      <ProgressBar
                        value={progress}
                        intent={this.progressIntent(progress)}
                      />
                    </div>
                    {this.renderQuestionSection(question_index)}
                  </div>
                ) : (
                  <div className="pt-card pt-elevation-2">
                    {document.paid ||
                    (localStorage.getItem('Template') && this.state.crudA) ? (
                      <div className="text-center">
                        <h2 className="text-center text-muted m-b-4">
                          Preview and Download
                        </h2>
                        {
                          //setTimeout(()=>{localStorage.getItem('crudA')? (localStorage.removeItem('Template')):null}, 10000)
                        }
                        <div></div>
                        <p>
                          Your document has been successfully created, you can
                          preview the pages of the document below before
                          downloading.
                          {!document.downloaded && (
                            <span>
                              If you wish to make changes to the document,{' '}
                              <a onClick={this.startOver}>click here</a>.
                            </span>
                          )}
                        </p>
                        <p>
                          NB: After downloading this document, changes cannot be
                          made to the document again.
                        </p>
                        <a
                          className="pt-button pt-icon-cloud-download"
                          target="_blank"
                          onClick={this.handleSetReadOnly}
                          download
                          href={pdfDownloadURL}
                        >
                          Download
                        </a>
                      </div>
                    ) : (
                      <div>
                        <h2 className="text-center text-muted">Checkout</h2>
                        <div className="text-center">
                          {
                            //localStorage.getItem('crudAk')? null:setTimeout(()=>{ this.setState({crudA: localStorage.getItem('crudA')})},2000)
                          }
                          <em>Your document is ready</em>
                          {
                            <Checkout
                              checkoutType={
                                cannotUseSubscription(
                                  document.template_expanded,
                                  subscription
                                )
                                  ? 'pay-as-you-go'
                                  : null
                              }
                              price={document.template_expanded.price}
                              resource={document}
                              type="document"
                              cb={this.onPayment}
                            />
                          }
                        </div>
                        <div className="pull-right">
                          <button
                            className="pt-button pt-button-accent"
                            type="button"
                            onClick={this.nextQuestion.bind(this, false)}
                          >
                            Go back
                          </button>
                        </div>
                        <div className="clearfix" />
                      </div>
                    )}
                  </div>
                )}

                {this.renderDocumentPreview(page, pages, contentWidth, pdfURL)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const paths = [
  '/document-category/templates/:id',
  '/document-category/templates/:id/documents/:document_id',
];

let path;
const store = (store) => ({
  path,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          showConfirmModal: (params) => dispatch(showConfirmModal(params)),
          showSignupModal: (params) => dispatch(showSignupModal(params)),
          showSigninModal: (params) => dispatch(showSigninModal(params)),
          getCategories: (params) => dispatch(getCategories(params)),
          getTemplate: (id, callback) => dispatch(getTemplate(id, callback)),
          create: (json, callback) => dispatch(create(json, callback)),
          createPayment: (json, callback) =>
            dispatch(createPayment(json, callback)),
          find: (_id, callback) => dispatch(find(_id, callback)),
          update: (_id, json, callback) =>
            dispatch(update(_id, json, callback)),
          updateUser: (_id, json, callback) =>
            dispatch(updateUser(_id, json, callback)),
          createCard: (user_id, payload, callback) =>
            dispatch(createCard(user_id, payload, callback)),
          createSubscription: (user_id, payload, callback) =>
            dispatch(createSubscription(user_id, payload, callback)),
          mySubscription: (user_id, payload, callback) =>
            dispatch(mySubscription(user_id, payload, callback)),
          packages: (callback) => dispatch(packages(callback)),
          removeCard: (user_id, _id, callback) =>
            dispatch(removeCard(user_id, _id, callback)),
          getAllCards: (user_id, payload, callback) =>
            dispatch(getAllCards(user_id, payload, callback)),
        })
      )(DocumentCreate)
    );
  },
});

export default (idx = 0) => {
  path = paths[idx];
  return store;
};
