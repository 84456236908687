import React from 'react';
import { connect } from 'react-redux';
import BlogPosts from '../../components/BlogPosts';
import Spinner from '../../components/widgets/Spinner';
import Heading from '../../components/widgets/Heading';
import { Link } from 'react-router';
import axios from 'axios';

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state =  {
      posts: [],
      currentPage: 1,
      postsPerPage:8
    };
    this.handleClick = this.handleClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id)
    })
  }
  fetchData() {
    if(this.state.posts.length === 0) {
      axios.get('https://public-api.wordpress.com/rest/v1.1/sites/152560735/posts')
        .then(res =>{
          console.log(res.data.posts);
          this.setState({posts: this.state.posts.concat(res.data.posts)})
          console.log('my post ',this.state.posts);
        })
        .catch(err => {
          console.log(err);
        })
    }
  }

  componentDidMount() {
    this.fetchData();
  }
  
  render() {
    const { posts, currentPage, postsPerPage } = this.state;
    //Logic for display
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    const renderPosts = currentPosts.map((post, index)=> {
      return <div key={post.ID} style={{display: 'flex', flexDirection: 'column', marginBottom: '30px'}} className="pt-card pt-elevation-3">
      <a href={"/blog/post/"+ post.ID}>
                {post.featured_image ? (
                    <img 
                      alt="article header"
                      src={post.featured_image}
                      height="160px"
                      width="160px"
                      className="align_left"
                      style={{float: 'left',marginRight: '60px'}}
                      />
                ): ""}
                 <span style={{float: 'left', clear: 'both'}}>
                   <h1 className="align_left">{post.title}</h1>
                   <div  dangerouslySetInnerHTML={{__html:post.excerpt}}></div>
                </span> 
            </a>
                <Link to={"/blog/post/" + post.ID}>
                    <button className="btn pt-button"> Read More</button>
                </Link>
      </div>
    })

    //Logic for displaying page numbers
    const pageNumbers = [];
    for(let i = 1; i <= Math.ceil(posts.length / postsPerPage); i++) {
      pageNumbers.push(i);
    }
    const renderNumbers = pageNumbers.map(number => {
      return (
        <button
          key={number}
          id={number}
          onClick={this.handleClick}
          className="pt-button"
          style={{ marginRight: '5px'}}
        >
        {number}
        </button>
      )
    })
    return (
      
        !(this.state.posts.length === 0) ? (<div style={{display: "flex", justifyContent: "center"}}>
          <div className="col_md_85 col_sm_100">
              <div style={{margin: "75px auto"}}><Heading title={<div >Blog</div>} /></div>
              <div className="pt-card pt-elevation-3"> 
                <div style={{marginTop: "30px"}}>{renderPosts}</div>
                <div style={{margin: "30px 0 30px 0"}}>
                  {renderNumbers}
                </div>
              </div>
          </div>
    </div>): (
    <div style={{display: "flex", justifyContent: "center"}}>
      <div className="col_md_85 col_sm_100">
          <div style={{margin: "75px auto"}}><Heading title={<div >Blog</div>} /></div>
          <Spinner className="text-center" />
      </div>
    </div>
  )
        

  )
  }
}

export default store =>
  ({
    path: `/blog`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({

          }))(Blog)
      );
    }
  });