import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { inflect } from 'inflection';
import { showConfirmModal } from '../../actions/modalActions';
import { injectReducer } from '../../store/reducers';
import moment from 'moment';
import {
  Tab2,
  Tabs2,
  Popover,
  Tooltip,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import Spinner from '../../components/widgets/Spinner';
import Icon from '../../components/widgets/Icon';
import NoItem from '../../components/widgets/NoItem';
import { currency } from '../../lib/utils';
import Toaster from '../../components/widgets/Toaster';
import { all, update } from '../../actions/ticketActions';
import PageComponent from '../../components/PageComponent';

class LawyerTickets extends PageComponent {
  getAuthType = () => ['lawyer'];
  getLawyerType = () => ['vlf'];
  constructor(props) {
    super(props);
    this.limit = 20;
    this.q = null;
    this.type = 'accepted';
    this.types = {
      // pending: 'pending=true',
      accepted: 'accepted=true',
      // taken: 'taken=true'
    };

    this.state = {
      tickets: {
        pending: {},
        accepted: {},
        taken: {},
      },
      page: 1,
    };
  }

  componentWillMount() {
    this.fetchTickets();
  }

  fetchTickets = () => {
    const { getTickets, types } = this;
    for (let i in types) {
      if (types.hasOwnProperty(i)) {
        getTickets(types[i], i);
      }
    }
  };

  getTickets = (path, status, page = 1) => {
    const {
      props: { all },
      limit,
      q,
    } = this;
    all({ q, page, limit, status }, (err, res) => {
      if (!err) {
        const tickets = this.state.tickets;
        tickets[status] = res;
        this.setState({ tickets, page });
      } else {
        Toaster.error(err.message || err);
      }
    });
  };

  handleSearch = (e) => {
    const { type, types } = this;
    const { value } = e.target || e.srcElement;
    if (value && value.length > 1) {
      this.q = value;
      this.getTickets(types[type], type, 1);
    } else if (!value) {
      this.q = null;
      this.getTickets(types[type], type, 1);
    }
  };

  updateCall(_id, action) {
    const body = {};
    const {
      update,
      showConfirmModal,
      state: { session },
    } = this.props;
    switch (action) {
      case 'accept':
        body.accepted = true;
        body.lawyer = session.user;
        body.status = 'accepted';
        break;
      // case 'decline':
      //   body.accepted = false;
      //   body.admin_taken = true;
      //   body.status = 'taken';
      //   break;
      // case 'revert':
      //   body.accepted = false;
      //   body.admin_taken = false;
      //   body.status = 'pending';
      //   break;
      default:
        return;
    }

    showConfirmModal({
      title: `Accept Appointment`,
      message: `Are you sure you want accept this appointment?`,
      buttons: [
        {
          label: action.ucFirst(),
          intent: 'danger',
          action: () => {
            update(_id, body, (err, res) => {
              if (!err) {
                this.fetchTickets();
                Toaster.info('Your request is being processed');
              } else {
                Toaster.error(err.message || err);
              }
            });
          },
        },
      ],
    });
  }

  handleTabChange = (type) => {
    this.type = type;
    this.setState({ page: 1 });
  };

  renderPaginator = (count, type) => {
    if (!count) {
      return null;
    }

    const { page } = this.state;
    const {
      payment: { isLoading },
    } = this.props.state;
    const total = Math.ceil(count / this.limit);
    let pages = [];
    for (let i = 0; i < total; i++) {
      pages.push(
        <div
          key={i}
          onClick={this.getTickets.bind(this, this.types[type], type, i + 1)}
          className={`page ${isLoading ? 'loading' : ''} ${
            page === i + 1 ? 'selected' : ''
          }`}
        >
          {i + 1}
        </div>
      );
    }

    return pages;
  };

  renderMessage = ({ messages, stamp }) => {
    return (
      <div className="p-a-1 tooltip-text">
        <p style={{ maxWidth: 320 }}>{messages[0].message.ellipsis(100)}</p>
        <hr />
        <span className="text-muted pull-right">
          <Icon name="access_time" /> {moment(stamp).fromNow()}
        </span>
        <div className="clearfix" />
      </div>
    );
  };

  renderWithDescriptionHover = (event, content) => {
    const popover = (
      <div className="p-a-1 tooltip-text">
        <h6>{event.data.transactionreference}</h6>
        <Link className="pull-right" to={`/admin/tickets/${event._id}`}>
          <span className="pt-icon-size pt-icon-share pull-right"></span>
        </Link>
        <p>
          <b>{event.narration}</b>
        </p>
        <p>
          Transaction: <code>{event.data.responsemessage}</code>
        </p>
        <p>
          AVS Response: <code>{event.data.avsresponsemessage}</code>
        </p>
        <p>
          <span className="text-muted">
            {event.stamp && moment(event.stamp).fromNow()}
          </span>
        </p>
      </div>
    );

    return null;
    // return <Popover interactionKind={PopoverInteractionKind.HOVER} content={popover} position={Position.TOP}>
    //   {content}
    // </Popover>
  };

  renderTickets = (type) => {
    const tableHeaders = [
      'Subject',
      'Owner',
      'VLF Category',
      'Date',
      'Status',
      'Responder',
      'Revert',
      '',
      '',
    ];
    const tickets = this.state.tickets[type];
    const count = tickets.count || 0;
    const {
      session: { user },
    } = this.props.state;

    return (
      <div className="m-t-2">
        <div className="table-container">
          <table className="pt-table">
            <thead>
              <tr>
                {tableHeaders.map((name, idx) => {
                  if (
                    (type === 'pending' && /Admin|Revert/.test(name)) ||
                    (type !== 'pending' && /Decline|Approval/.test(name))
                  ) {
                    return null;
                  }
                  return <th key={idx}>{name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tickets.items &&
                tickets.items.map((item, idx) => {
                  const canOpen = !item.lawyer || item.lawyer === user;
                  item.vlf_category_expanded = item.vlf_category_expanded || {};
                  return (
                    <tr key={idx}>
                      <td>
                        {/* <Popover interactionKind={PopoverInteractionKind.HOVER} content={this.renderMessage(item)} position={Position.TOP}>
                      {item.subject}
                    </Popover> */}
                        {item.subject}
                      </td>
                      <td>
                        {(item.owner_expanded && item.owner_expanded.name) ||
                          'N/A'}
                      </td>
                      <td>
                        {item.vlf_category_expanded.name}{' '}
                        <small>
                          {currency(item.vlf_category_expanded.price)}
                        </small>
                      </td>
                      <td>{moment(item.start).format('LL LT')}</td>
                      <td>
                        <small
                          className={`ticket-status pt-tag pt-intent-${
                            item.status === 'open' ? 'success' : 'danger'
                          }`}
                        >
                          {item.status.toUpperCase()}
                        </small>
                      </td>
                      <td>
                        {(item.lawyer_expanded && item.lawyer_expanded.name) ||
                          'N/A'}
                      </td>
                      <td>
                        <Link to={`/tickets/${item._id}`}>
                          <button
                            type="button"
                            disabled={!canOpen}
                            className="pt-button pt-primary"
                          >
                            Open
                          </button>
                        </Link>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {!count ? <NoItem>No Tickets found</NoItem> : null}
        </div>
        <div className="paginator">{this.renderPaginator(count, type)}</div>
      </div>
    );
  };

  renderHeader = () => {
    const count = this.state.tickets[this.type].count || 0;

    return (
      <div className="row">
        <div className="col_20">
          <div className="pt-input-group">
            <span className="pt-icon pt-icon-search"></span>
            <input
              className="pt-input"
              type="search"
              placeholder="Search"
              dir="auto"
              onChange={this.handleSearch}
            />
          </div>
        </div>
        <div className="col_20">
          <span className="pt-navbar-divider"></span>
          <span className="pt-tag pt-minimal pt-large">
            {count} {inflect('entry', count)} found
          </span>
        </div>
      </div>
    );
  };

  renderPage() {
    const {
      user: { account },
    } = this.props.state;
    if (!account) {
      return <Spinner />;
    }

    return (
      <section className="content-width LawyerTickets">
        <h2 className="m-t-4 m-b-2">Tickets</h2>
        <div className="m-b-4">{this.renderHeader()}</div>
        <Tabs2
          id="tickets"
          defaultSelectedTabId="accepted"
          onChange={this.handleTabChange}
        >
          {/* <Tab2 id="pending" title={<h5>Pending <div className="pending-count">{this.state.tickets.pending.count || 0}</div></h5>} panel={this.renderTickets('pending')} /> */}
          <Tab2
            id="accepted"
            title={<h5>Assigned to you</h5>}
            panel={this.renderTickets('accepted')}
          />
          {/* <Tab2 id="taken" title={<h5>Taken</h5>} panel={this.renderTickets('taken')} /> */}
          <Tabs2.Expander />
        </Tabs2>
      </section>
    );
  }
}

export default (store) => ({
  path: `/lawyer/tickets`,
  getComponent(next, cb) {
    cb(
      null,
      connect(
        (state) => ({ state }),
        (dispatch) => ({
          showConfirmModal: (params) => dispatch(showConfirmModal(params)),
          update: (_id, category, callback) =>
            dispatch(update(_id, category, callback)),
          all: (kwargs, callback) => dispatch(all(kwargs, callback)),
        })
      )(LawyerTickets)
    );
  },
});
