import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistorya } from 'react-router';
import PageComponent from '../../components/PageComponent';


class DocSeo extends PageComponent {
  getAuthType = () => ['admin'];
  constructor(props) {
      super(props);
  }
  componentWillMount() {
    browserHistory.push("/document-categories");
  }



    render() {
    return (
        <div className="Documents">
            <div>
            DESCRIPTION
A promissory note is a written promise by one party (the borrower) to another (the lender); to pay a definite sum of money either on demand or at a specified future date. A promissory note usually specifies the principal amount, interest rate, maturity date, date of issuance, signature of the issuer. 
FREQUENTLY ASKED QUESTIONS (FAQs)
When should I use a Promissory Note?
Promissory notes can be used for soft loans. Soft loans refer to informal borrowings usually of lower sums between individuals, colleagues, friends and family. Since soft loans do not require complex legal documentation, the Promissory Note serves as a legal record of indebtedness.
What is the difference between a promissory note and a loan agreement?
A promissory note is a simple document that is not as complex as a loan agreement.. A promissory note is used for small amount of loans and is essentially a formal contract between parties that contains a promise to pay a certain amount of money on demand at a specified time or over a period of time in future. 
Unlike a promissory note, a loan agreement imposes obligations on both parties, making it essential for both parties to sign. A promissory note on the other hand may be executed only by the borrower, as it is in essence a note confirming indebtedness. 
Deciding on which document to use will be based on the circumstances of the loan and the relationship between the parties. 
What does a promissory note contain? 
A promissory note should contain: 
	The names and addresses of the borrower and the lender
	Date of issuance
	Maturity date of the loan
	The amount of money being borrowed 
	How often payments will be made and in what amount
	Signature of the borrower, in order for the note to be enforceable		


PROMISSORY NOTE
This PROMISSORY NOTE (hereinafter referred to as the Note) is made this ______ day of ______________, 20__ by _____1______ of ______2______ (hereinafter referred to as the Borrower) in favour of _____3______ of _____4______ (hereinafter referred to as the Lender).
The Borrower and the Lender are referred to individually as Party and collectively as Parties. 
A.	The Lender has advanced ____5_____ (hereinafter referred to as the Sum) to the Borrower (receipt of which the Borrower acknowledges).
B.	For value received, the Borrower promises to repay to the Lender, the Sum, at such address as may be provided in writing by the Lender to the Borrower, with interest payable on the unpaid balance of the Sum at the rate of _____6_____ percent per annum, calculated yearly, beginning on ________7________.
C.	This Note will be repaid in full on ________7a________ 
D.	At any time while not in default under this Note, the Borrower may pay the outstanding balance of the Sum owed under this Note to the Lender without further bonus or penalty. 
E.	All cost, expenses and expenditure including, but not limited to, the complete legal costs incurred by the Lender in enforcing this Note as a result of any default by the Borrower, will be added to the balance of the Sum then outstanding and will be paid immediately by the Borrower to the Lender. 
F.	If any term, covenant, condition or provision of this Note is held by a court of competent jurisdiction to be invalid, void or unenforceable, it is the Partie intent that such provision be reduced in scope by the court only to the extent deemed necessary by that court to render the provision reasonable and enforceable and the remainder of the provisions of this Note will in no way be affected, impaired or invalidated as a result.
G.	This Note will be construed in accordance with and governed by the Laws of the Federal Republic of Nigeria.
H.	This Note will enure to the benefit of and be binding upon the respective heirs, executors, successors and assigns of the Borrower and the Lender. 
I.	No debt obligations incumbent on the Borrower by virtue of this Note shall be deemed unenforceable by the Lender by virtue of the presence or absence thereof, of a demand notice, notice of non-payment or any such document as are deployed to serve the same or similar purpose. 
J.	In any event that there is a controversy or claim arising out of or relating to this Note, or the breach hereof, the Parties shall expend their best efforts to settle any such disputes arising between them amicably within 30 days.
In the event that the Parties are unable to reach an amicable resolution, such dispute, and controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Note shall be resolved by arbitration; by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap 18 Laws of the Federal Republic of Nigeria 2004. 


IN WITNESS OF WHICH THE BORROWER HAS EXECUTED THIS NOTE IN THE MANNER BELOW, ON THE DATE INDICATE ABOVE:

Signed by the within named Borrower




  (repeat reference 1)					_________________
NAME						SIGNATURE






REFERENCES
1.	Who is the name of the Borrower?
2.	What is the address of the Borrower?
3.	What is the name of the Lender?
4.	What is the address of the Lender?
5.	How much has the Borrower promised to pay?
6.	How much interest is to be charged on the principal?
7.	On what date should the payment commence? 
7a. on what date should the debt be fully paid?



DESCRIPTION
A promissory note is a written promise by one party (the borrower) to another (the lender); to pay a definite sum of money either on demand or at a specified future date. A promissory note usually specifies the principal amount, interest rate, maturity date, date of issuance, signature of the issuer. 
FREQUENTLY ASKED QUESTIONS (FAQs)
When should I use a Promissory Note?
Promissory notes can be used for soft loans. Soft loans refer to informal borrowings usually of lower sums between individuals, colleagues, friends and family. Since soft loans do not require complex legal documentation, the Promissory Note serves as a legal record of indebtedness.
What is the difference between a promissory note and a loan agreement?
A promissory note is a simple document that is not as complex as a loan agreement.. A promissory note is used for small amount of loans and is essentially a formal contract between parties that contains a promise to pay a certain amount of money on demand at a specified time or over a period of time in future. 
Unlike a promissory note, a loan agreement imposes obligations on both parties, making it essential for both parties to sign. A promissory note on the other hand may be executed only by the borrower, as it is in essence a note confirming indebtedness. 
Deciding on which document to use will be based on the circumstances of the loan and the relationship between the parties. 
What does a promissory note contain? 
A promissory note should contain: 
	The names and addresses of the borrower and the lender
	Date of issuance
	Maturity date of the loan
	The amount of money being borrowed 
	How often payments will be made and in what amount
	Signature of the borrower, in order for the note to be enforceable		


PROMISSORY NOTE
This PROMISSORY NOTE (hereinafter referred to as the Note) is made this ______ day of ______________, 20__ by _____1______ of ______2______ (hereinafter referred to as the Borrower) in favour of _____3______ of _____4______ (hereinafter referred to as the Lender).
The Borrower and the Lender are referred to individually as Party and collectively as Parties. 
A.	The Lender has advanced ____5_____ (hereinafter referred to as the Sum) to the Borrower (receipt of which the Borrower acknowledges).
B.	For value received, the Borrower promises to repay to the Lender, the Sum, at such address as may be provided in writing by the Lender to the Borrower, with interest payable on the unpaid balance of the Sum at the rate of _____6_____ percent per annum, calculated yearly, beginning on ________7________.
C.	This Note will be repaid in full on ________7a________ 
D.	At any time while not in default under this Note, the Borrower may pay the outstanding balance of the Sum owed under this Note to the Lender without further bonus or penalty. 
E.	All cost, expenses and expenditure including, but not limited to, the complete legal costs incurred by the Lender in enforcing this Note as a result of any default by the Borrower, will be added to the balance of the Sum then outstanding and will be paid immediately by the Borrower to the Lender. 
F.	If any term, covenant, condition or provision of this Note is held by a court of competent jurisdiction to be invalid, void or unenforceable, it is the Parties intent that such provision be reduced in scope by the court only to the extent deemed necessary by that court to render the provision reasonable and enforceable and the remainder of the provisions of this Note will in no way be affected, impaired or invalidated as a result.
G.	This Note will be construed in accordance with and governed by the Laws of the Federal Republic of Nigeria.
H.	This Note will enure to the benefit of and be binding upon the respective heirs, executors, successors and assigns of the Borrower and the Lender. 
I.	No debt obligations incumbent on the Borrower by virtue of this Note shall be deemed unenforceable by the Lender by virtue of the presence or absence thereof, of a demand notice, notice of non-payment or any such document as are deployed to serve the same or similar purpose. 
J.	In any event that there is a controversy or claim arising out of or relating to this Note, or the breach hereof, the Parties shall expend their best efforts to settle any such disputes arising between them amicably within 30 days.
In the event that the Parties are unable to reach an amicable resolution, such dispute, and controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Note shall be resolved by arbitration; by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap 18 Laws of the Federal Republic of Nigeria 2004. 


IN WITNESS OF WHICH THE BORROWER HAS EXECUTED THIS NOTE IN THE MANNER BELOW, ON THE DATE INDICATE ABOVE:

Signed by the within named Borrower




  (repeat reference 1)					_________________
NAME						SIGNATURE






REFERENCES
1.	Who is the name of the Borrower?
2.	What is the address of the Borrower?
3.	What is the name of the Lender?
4.	What is the address of the Lender?
5.	How much has the Borrower promised to pay?
6.	How much interest is to be charged on the principal?
7.	On what date should the payment commence? 
7a. on what date should the debt be fully paid?


DESCRIPTION 
A notice to quit is a notice given by a landlord or a representative authorized in writing (an agent) to a tenant to vacate the premises on or after a certain date. 
A notice to quit must contain: the name and address of the agent, date of letter of authorization, name and address of landlord, address of the property, name of the tenant, type of tenancy, date of commencement and expiry of tenancy, date of intended recovery of premises. 

FREQUENTLY ASKED QUESTIONS (FAQs)
Is it only the landlord that can prepare/sign a notice to quit? 
A Notice to Quit can be signed by the Landlord or his representative. However, for a representative to sign, he must have a written authorization from the landlord to do so on his behalf. 

Do I need to give notice to quit?
Notice to quit is mandatory in recovery of premises. Furthermore, violating the length of notice required by law invalidates the notice to quit. 

How do I determine the length of notice?
The length of notice depends on the type of tenancy. The law provides the minimum notice period required for a tenant. The notice periods are as follows:
Weekly Tenancy  1 (one) week notice
Monthly Tenancy  1 (one) month notice
Quarterly Tenancy  3 (three) months notice
Yearly Tenancy  6 (six) months notice 
However, the parties may agree to a different notice period in the tenancy agreement.

How do I know the type of tenancy? 
Generally, the type of tenancy is determined by the time when rent is to be paid (as stipulated in the tenancy agreement). For example; where the agreement provides that rent is to be paid weekly, then it is a weekly tenancy.

What is the next step after the notice to quit? 
After the notice to quit expires, the landlord will send a notice of intention to recover the premises; which usually has a 7 day notice period 

NOTICE TO QUIT (by Agent) DRAFT 
I ________________1a_______________ of ___________1b__________(Agent) having been duly authorized by ___________2a__________ of ________2b_____________Landlord owner of the ________3_____________ and all appurtenances (hereinafter referred to as the Premises) situate at ___________4_____________ which you ___________5____________(Tenant) presently occupy by virtue of a  _________6________tenancy which commenced on __________7a_____________ and expires on _________7b_____________; hereby give you notice to quit and deliver up possession of the Premises on or before ________8__________.

Dated this __ day of ____________ 20__
Signed by the within named Agent

_________________
NAME:

REFERENCES 
1a What is the name of the Landlords agent?
1b What is the address of the landlords agent?
2a What is the Landlords name?
2b What is the landlords address?
3 What is the description of the property? For example: 2 bedroom bungalow or 4 bedroom duplex  
4 What is the address of the property?
5 What is the Tenants name?
6 What is the type of tenancy? For example: Weekly tenancy or Monthly tenancy or Yearly tenancy
7a When did the tenancy start? For example: 2nd of September 2012 or 3rd of July 2005
7b When is the tenancy meant to expire? 
8 When do you want the tenant to move out? 


DESCRIPTION OF THE DOCUMENT
 An Artist Management Agreement is a contract between an Artist and a Manager which states the scope of the obligations of each party, representation, rights and compensation under the agreement as well as other important clauses. It is highly recommended that every artiste and their representatives enter into this agreement to clearly state services required of each party and their entitlements. This agreement sets forth a broad range of agreed upon parameters related to the structure and details of an artists career and the various sources of revenue generated.

FREQUENTLY ASKED QUESTIONS (FAQs)
Why execute an Artist Management Agreement?
To legally protect the interests of both parties and guarantee performance. Choosing a personal manager is a critical moment in an artists career and a document such as this indicates in detail many important guidelines that will govern the legal aspects of a relationship between an artist and manager. The Artist Management Agreement makes it clear the services a professional artist manager will or will not provide to an artist and how the manager will get paid. 
NB: This Agreement is in relation to an artists music career.
When do I use an Artist Management Agreement?	
If you are a song writer, performer or entertainer of any sort, you need an Artist Management Agreement when you sign on a new management to represent you with a mandate to help you attain progress in your career. No matter how informal the relationship is, there is need to expressly state the terms guiding the relationship as agreed by parties to avoid future conflicts.
What is the duration of an Artist Management Agreement?
Like any other Agreement, the duration of an Artist Management Agreement depends on what the parties agree. 

 
THIS ARTIST MANAGEMENT AGREEMENT made this _____day of ___________________, 20____

BETWEEN 

_____1a of ______1b (hereinafter referred to as the Artist which expression shall where the context so admits include her heirs, executors, administrators, successors-in-title, personal/legal representatives, and assigns) of the first part and 

_____2a of ______2b (hereinafter referred to as the Manager which expression shall where the context so admits include her heirs, executors, administrators, successors-in-title, personal/legal representatives, and assigns) of the other part.

The Artist and the Manager may hereinafter individually be referred to as Party and collectively as Parties

WHEREAS 
i.	--------------2a.
ii.	The Manager is familiar with the creative abilities of Artist and has the expertise, ability, industry contacts and resources to assist Artist in the furtherance of the Artists career.

iii.	The Artist wishes to obtain advice, guidance, counsel, and direction in the development and furtherance of the Artists career as a musician, recording, and performing artist and in such new and different areas as the Artists artistic talents can be developed and exploited; and 
iv.	The Manager by reason of Managers contacts, experience and background, is qualified to render such advice, guidance, counsel, and direction to Artist.
v.	The Manager and Artist wish to enter into this Agreement to govern their relationship. 

 
NOW THEREFORE, in consideration of the mutual promises herein contained, it is agreed and understood as follows:


1.	Managers Covenants and Obligations 
1.1	The Manager agrees to render such advice, guidance, counsel, and other services as the Artist may reasonably require to further his career as a musician, composer, actor, recording, and performing artist, and to develop new and different areas within which his artistic talents can be developed and exploited, including but not limited to the following services:
(a) 	To represent the Artist and act as negotiator, to fix the terms governing all manner of disposition, use, employment or exploitation of Artists talents and the products thereof; 
 (b) To supervise the Artists professional employment, and on the Artists behalf, to consult with employers and prospective employers so as to assure the proper use and continued demand for Artists services; 
(c) 	To be available at reasonable times and places to confer with the Artist in connection with all matters concerning Artists professional career, business interests, employment, and publicity; 
(d) 	To exploit the Artists personality in all media, and in connection therewith, to approve and permit for the purpose of trade, advertising and publicity, the use, dissemination, reproduction or publication of the Artists name, photographic likeness, facsimile signature, voice and artistic and musical materials; 
(e) 	To engage, discharge and/or direct such theatrical agents, booking agencies and employment agencies, as well as other firms, persons or corporations who may be retained for the purpose of securing contacts, engagements or employment for the Artist; and,
 (f) To represent the Artist in all dealings with any union; and,
 (g) To exercise all powers granted to the Manager pursuant to Clause 2.3 hereof.

1.2 	The Manager agrees to maintain accurate books and records of all transactions concerning Artist.

1.3	The Manager is not required to render exclusive services to the Artist or to devote its entire time or the entire time of any of the Managers employees to the Artists affairs. Nothing herein shall be construed as limiting the Managers right to represent other persons whose talents may be similar to or who may be in competition with the Artist or to have and pursue business interests which may be similar to or may compete with those of the Artist.

2	Artist Covenants and Obligations
2.1	The Artist hereby appoints the Manager as sole personal manager in all matters usually and normally within the jurisdiction and authority of personal manager, including but not limited to the advice, guidance, counsel, and direction specifically referred to in Clause 1 hereof. 

2.2	The Artist agrees to seek such advice, guidance, counsel, and direction from the Manager exclusively and agrees that it will not engage any other agent, representative, or manager to render similar services, and that he will not perform said services on his own behalf and he will not negotiate, accept, or execute any agreement, understanding, or undertaking concerning his career as an actor, musician, recording and performing artist without the Managers prior consent.

2.2	The Artist hereby irrevocably appoints the Manager for the term of this Agreement and any extensions hereof as the Artists true and lawful attorney-in-fact to sign, make, execute, accept, endorse, collect and deliver any and all bills of exchange, cheques, and notes as the said attorney; to demand, sue for, collect, recover, and receive all goods, claims, money, interest and other items that may be due; and to make, execute, and deliver receipts, releases, or other discharges therefore under sale or otherwise and to defend, settle, adjust, compound, submit to arbitration and compromise all actions, suits, accounts, reckonings, claims, and demands whatsoever that are or shall be pending in such manner and in all respects as in any way limiting the foregoing; generally to do, execute and perform any other act, deed, or thing whatsoever deemed reasonable that ought to be done, executed, and performed of any and every nature and kind as fully effectively as the Artist could do if personally present; and the Artist hereby ratifies and affirms all acts performed by the Manager by virtue of this Agreement.

2.3	Artist expressly agrees not to exert any of the powers herein granted to the Manager by the foregoing power of attorney without the express prior written consent of the Manager and that all sums and considerations paid to the Artist by reason of the artistic endeavours may be paid to the Manager on the Artists behalf.

2.4	It is expressly understood that the foregoing power of attorney is limited to matters reasonably related to Artists career as a musician, actor, recording and performing artist and such new and different areas within which artistic talents can be developed and exploited.

2.5	The Artist agrees and understands that the power of attorney granted to Manager is coupled with an interest which the Artist irrevocably grants to the Manager in the career of the Artist, in the artistic talents of Artist, in the products of said career and talents and in the earnings of Artist arising by reason of such career, talents, and products.


2.6	The Artist agrees to release -------------3 albums within the duration of this Agreement as contained in clause 4. Each album shall comprise no less than ______4a songs. 

3	Fee and Compensation
3.1	The profit sharing ratio between the Manager and the Artist in respect of any show performed by the Artist during the term of this Agreement:
		Artist:		------5%
		Manager:	------6%

3.2	For avoidance of doubt, the ratio applies to the income derived from the Artists performance only upon deduction of taxes, other statutory fees and all expense incurred in respect of any performance by the Artist, both locally and internationally.

3.3	As compensation for the services to be rendered hereunder, the Manager shall receive all earnings from royalties, endorsements, caller tunes, album sales, merchandise, shares in ventures, products, properties, or any other kind or type of income which is reasonably related to the Artists career in the entertainment, amusement, music, recording, motion picture, television, radio, literary, theatrical, and advertising fields.




4	Duration and Termination
4.1	The term of this Agreement shall be for a period of ------7 with an option to renew this Agreement by written notice to the Artist no less than thirty (30) days prior to the expiration of the said term.

4.2	If the Artist refuses, neglects or fails to perform any of the Artists obligations contained in clause 2 of this Agreement or does not comply with any of the terms in this Agreement, the Manager may, by notice in writing to the Artist, terminate the terms of this Agreement. 

4.3 	Upon termination of the Agreement in accordance with either clause 4.1 or 4.2, the Manager shall be entitled to receive a commission of ----8% on every opportunity, project or transaction initiated by the Manager. 

5	Miscellaneous Provisions
5.1	During the term of this Agreement, it is understood and agreed that there shall be no change or modification of this Agreement unless reduced to writing and signed by both Parties hereto. 

5.2	No failure by either Party to take any action with respect to a breach of this Agreement or a default by the other Party shall constitute a waiver of the Party's right to enforce any provision of this Agreement or to take action with respect to such breach or default or any subsequent breach or default. Waiver by any Party of any breach or failure to comply with any provision of this Agreement by a Party shall not be construed as, or constitute, a continuing waiver of such provision, or a waiver of any other breach of or failure to comply with any other provision of this Agreement. 

5.3	The construction, validity and performance of this Agreement shall be governed in all respects by the Laws of the Federal Republic of Nigeria, and shall be binding upon and inure to the benefit of the parties, respective heirs, executors, administrators, successors, and assigns.

5.4	It is agreed that as a condition precedent to any assertion by the Artist or the Manager that the other is in default in performing any obligation contained herein, the party alleging the default must advise the other in writing of the specific obligation which it claims has been breached and said other party shall be allowed a period of thirty (30) days from the receipt of such written notice within which to cure such default.

5.5	Where a dispute between the Parties is not resolved within thirty days of the, either Party may be referred to Arbitration before a single arbitrator in accordance with the Arbitration and Conciliation Act, Cap A18 of the Laws of the Federation of Nigeria, 2004. The arbitration proceedings shall be conducted in English Language.

5.6	This Agreement does not and shall not be construed to create a partnership or joint venture between the parties hereto.


IN WITNESS WHEREOF, the parties hereunder have subscribed their signatures in the day and year first above written.


Signed by the within named Artist         	                        _________________________
 								        Signature and Date

Name

Signed by the within named Manager    		            _________________________
         Signature and Date

IN THE PRESENCE OF 
Name: _____________________________________________________________
Address: ____________________________________________________________
Occupation: _________________________________________________________
Signature: ___________________________________________________________

References: 
1a. Who is the Artist?
1b. What is the address of the Artiste?
2a  Who is the Manager?
2b. What is the Managers Address?
2c. Provide details of what the Manager does. E.g. the Manager specializes in the management, recording, distribution and representation of artists
3a.How many albums will the artist be required to release within the duration of the Agreement? 
4a. What is the least number of songs the artiste is expected to have in one album?
5. What percentage of the profits will the Artist receive? 6What percentage of the profits will the Manager receive? 
7. What is the duration of the Artist Management Agreement? E.g. 3 years, 16 months etc. 
8 Upon termination of the agreement, what percentage of profits should the Manager receive as commission for any opportunity, project or transaction initiated by the Manager? 



DESCRIPTION OF DOCUMENT
A Chairmans Contract is an agreement between the Company and the Chairman of the Board of Directors stating his duties, compensation and benefits amongst other obligations. It differs from the Executives agreement because the Chairman appointed by the board could be from either the Executive or Non-Executive directors of the board. The position is however usually from the non-executive directors except the person is an Executive Chairman. 

FREQUENTLY ASKED QUESTION (FAQs)
Who is the Chairman of the Board?	
The Chairman presides over the meetings of the board and is the most senior officer amongst the board of directors. The Chairman is usually appointed from amongst the members of the Board and serves as a link between the board and general organisation overseeing the conduct of affairs of the Company.
Is the Chairman entitled to a remuneration?
Yes, all members of the board are entitled to remuneration for the period in which they have acted in such capacity. Some organisations pay sitting allowances to board members. The Chairman in his capacity is entitled to remuneration for his services all of which should be stated in the Chairmans Contracts.
Can a Person be both Chairman and CEO?
Yes, Sometimes subject to the Articles of Association of the company and directive of the Regulatory Agency regulating the business sector, a person can be both Chairman and CEO, this is usually the case with Private Ltd Companies. 

                                                  CHAIRMANS CONTRACT 
 THIS AGREEMENT is made this _____day of _______________________20____ (Effective Date)

BETWEEN 
____1a____ [hereinafter referred to as the (Company) a company duly incorporated and registered under law with RC Number ______1b_____ and its registered address at ____1c_____ 
AND 
	____2a______ of _______2b_______ (hereinafter referred to as the Chairman).

The Company and the Chairman are referred to individually as Party and collectively as Parties

NOW, THE PARTIES AGREE as follows: 

        1. TERM
    1.1 This Agreement shall subsist for a period of [insert reference] from the Effective Date and shall continue thereafter for as long as the Chairman is re-elected as Chairman of the Board of Directors ("Chairman") of Company.

  2. DUTIES
      2.1 The Chairman shall preside over all the meetings of the Board or of the Companys shareholders.
2.2	The Chairman shall be responsible for the following: 
i.	Provide overall leadership and direction to the Board and the Company;
ii.	Manage Board meetings to ensure sufficient time is allowed for the discussion of complex or contentious issues;
iii.	Provide independent advice and counsel to the Managing Director/Chief Executive Officer;
iv.	Keep abreast generally of the activities of the Company and its management.
v.	Oversee shareholder and other stakeholder communications.
	
      2.3 The Chairman shall comply with the statutes, rules, regulations and orders of any governmental or quasi-governmental authority, which are applicable to the performance of the duties, and Company's rules, regulations, and practices as they may from time-to-time be adopted or modified.
2.4	The Chairman shall perform such other duties and responsibilities as are normally related to such position in accordance with Company's bylaws and applicable law. The Chairman hereby agrees to use his best efforts to carry out the duties contained in this Clause 2.
Multiple Directorship
 2.3 The Chairman may be employed by another company, may serve on other boards of directors or advisory boards, and may engage in any other business activity (whether or not pursued for pecuniary advantage), as long as such outside activities do not violate Chairman's obligations under this Agreement or fiduciary obligations to the shareholders. 
2.4 PROVIDED THAT the Chairman shall not engage in any activity that is in direct competition with the Company or serve in any capacity (including, but not limited to, as an employee, consultant, advisor or director) in any company or entity that competes directly with the Company, as reasonably determined by a majority of Company's disinterested board members.

3. REPRESENTATIONS AND WARRANTY
 3.1 The Chairman represents that, to the best of his knowledge, they have no outstanding agreement or obligation that is in conflict with any of the provisions of this Agreement, and the chairman agrees to use best efforts to avoid or minimize any such conflict and agrees not to enter into any agreement or obligation that could create such a conflict, without the approval of the Chief Executive Officer or a majority of the Board of Directors. 
3.2 If, at any time, the Chairman is required to make any disclosure or take any action that may conflict with any of the provisions of this Agreement, the Chairman  will promptly notify the Chief Executive Officer or the Board of such obligation, prior to making such disclosure or taking such action.

4. CONFLICT OF INTEREST
During the term of this Agreement, the Chairman shall not engage in any activities which could or do constitute a conflict with the performance of the duties required under this Agreement. If a conflict may or does occur, the Chairman shall immediately notify the board. 


5. COMPENSATION AND BENEFITS

5.1 In consideration of the services to be rendered under this Agreement, Company shall pay the Chairman a fee at the rate of ______3a per year, which shall be paid in accordance with Company's regularly established practices regarding the payment of Chairmans fees, but in no event later than 12 months after the Effective Date of this Agreement and each of its subsequent anniversaries, if any. 
 5.2 During the term of this Agreement, the Chairman may be granted additional stock options or other equity rights, as determined by Company, in its sole discretion.
  5.3 The Company will provide the Chairman with health and life insurance benefits in accordance with the benefit plans established by Company for its senior executives (as may be amended from time to time in Company's sole discretion) to the extent allowed under the terms of such plans and will pay all premiums for the coverage of the Chairman and his family, including his domestic partner. 
5.4	The Chairman shall also be eligible to participate in any additional benefits made generally available by Company to its senior executives, to the extent allowed by the benefit plans established by Company, which may be amended or terminated at any time in Company's sole discretion.
5.5 The Company shall reimburse the Chairman for all reasonable business expenses incurred in the performance of his duties hereunder in accordance with Company's expense reimbursement guidelines.

6. TERMINATION
   6.1 At any time, the Chairman may be removed as Chairman or may resign as Chairman. Notwithstanding anything to the contrary contained in or arising from this Agreement or any statements, policies, or practices of Company. Either Party shall be required to provide an advance notice in writing communicating any reason or cause for termination or resignation of the Chairman.

  6.2 Termination as Chairman 
 6.2.1 Upon the termination of Chairmans Status, in which Chairman remains a Director, this Agreement will terminate, and the Company and Director will sign the Company's standard Director's Agreement, in effect at the time of the termination, subject to any modifications to which both parties mutually agree;
 PROVIDED, however, following such termination and for as long as Chairman continues to serve as a Director of the Company, the Company will continue to provide Chairman with the benefits he is entitled to as director of the company. The Company shall pay to the Chairman all compensation and benefits to which the Chairman is entitled up through the date of termination, and thereafter, all of the Company's obligations under this Agreement shall cease.


        6.3 Termination Obligations
        6.3.1 The Chairman agrees that all property, including, without limitation, all equipment, proprietary information, documents, records, notes, contracts, and computer-generated materials provided to or prepared by Chairman incident to his services belong to Company and shall be promptly returned at the request of Company.
        6.3.2 The Company and the Chairman agree that their obligations under this Clause, shall survive the termination of this Agreement.

 7.  NON-DISCLOSURE OBLIGATIONS
       The Chairman acknowledges and agrees that by virtue of his involvement with the business and affairs of the Company, he has developed and will continue to develop substantial expertise and knowledge with respect to all aspects of the business, affairs and operations of the Company and has had access to and will continue to have access to all significant aspects of the business and operations of the Company and to confidential and proprietary information of the Company.
As Such;    
7.1 During the term of this Agreement and for  after the termination of this Agreement for any reason, the Chairman shall not, directly or indirectly disclose or use any Proprietary Information, or trade secrets belonging to Company, whether or not it is in written or permanent form, except to the extent necessary to perform the Services, as required by a lawful government order or subpoena, or as authorized in writing by Company. 
7.2 These nondisclosure obligations also apply to Proprietary Information belonging to customers and suppliers of Company, and other third parties, learned by Director as a result of performing the Services.
"Proprietary Information" means all information pertaining in any manner to the business of Company, unless (i) the information is or becomes publicly known through lawful means; (ii) the information was part of Director's general knowledge prior to his relationship with Company; or (iii) the information is disclosed to Director without restriction by a third party who rightfully possesses the information and did not learn of it from Company.
 7.3 As such, the Chairman acknowledges and agrees that the Company will be damaged if he were to breach or threaten to breach any of the provisions of this Section or if he were to disclose or make unauthorized use of any confidential and proprietary information of the Company or otherwise engage in the activities prohibited by this Section. Accordingly, Chairman expressly acknowledges and agrees that he is knowingly and voluntarily entering into this Agreement, and that the terms, provisions and conditions of this Section are fair and reasonable and necessary to adequately protect the Company and its business.	


8.  NON-COMPETE/NON SOLICITATION	
8.1 During the term of this Agreement and for One (1) year after the termination thereof, the Chairman shall not, either directly or indirectly, whether on his own behalf or on behalf of any other individual or entity (other than the Company), solicit or attempt to solicit any client or actively sought prospective client of the Company with whom the Chairman had Material Contact during the term of this Agreement.
8.2     The Chairman will be deemed to have had Material Contact with a client or actively sought prospective client of the Company if the Chairman (i) dealt directly with the client on behalf of the Company; (ii) coordinated or supervised the Companys dealings with the client; (iii) obtained confidential information about the client as a result of this Agreement.
8.3  During the term of this Agreement and for one (1) year after the termination of this Agreement for any reason, the  Chairman shall not, directly or indirectly, solicit to employ, or employ for himself or others, any employee of the Company, or any subsidiary or affiliate of the Company, who was an officer, director or employee of, or consultant or advisor to, the Company, or any subsidiary or affiliate of the Company, as of the date of the termination of this Agreement or during the preceding six (6) month period, or solicit any such person to leave such persons position or join the employ of, or act in a similar capacity with, another, then or at a later time.

 9. DISPUTE RESOLUTION
9.1 Any controversy or claim arising out of or relating to this Agreement, or the breach hereof, the Parties shall expend their best efforts to settle any disputes arising between them amicably within 30 days.
9.2 In the event that the Parties are unable to reach an amicable resolution, such dispute, and controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration; by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap 19 Laws of the Federal Republic of Nigeria 2004.            

   10.  ENTIRE AGREEMENT AND COUNTER PARTS
10.1 This Agreement is intended to be the final, complete, and exclusive statement of the terms of Director's relationship with the Company solely with respect to his position as Chairman. This Agreement entirely supersedes and may not be contradicted by evidence of any prior or contemporaneous statements or agreements pertaining to Director's relationship as Chairman or Director. 
10.2 This Agreement may be executed in any number of counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument.

    11.  AMENDMENTS AND WAIVERS
 11.1 This Agreement may not be amended except by a written document signed by the Chairman and by a duly authorized representative of the Company other than the Chairman. Failure to exercise any right under this Agreement shall not constitute a waiver of such right.

      12. SEVERABILITY
 If any provision of this Agreement shall be held by a court or arbitrator to be invalid, unenforceable, or void, such provision shall be enforced to fullest extent permitted by law, and the remainder of this Agreement shall remain in full force and effect. In the event that the time period or scope of any provision is declared by a court or arbitrator of competent jurisdiction to exceed the maximum time period or scope that such court or arbitrator deems enforceable, then such court or arbitrator shall reduce the time period or scope to the maximum time period or scope permitted by law.

        13. GOVERNING LAW
The implementation and enforcement of this Agreement shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement

        14. INTERPRETATION
14.1	This Agreement shall be construed as a whole, according to its fair meaning, and not in favor of or against any party. 
14.2	Captions are used for reference purposes only and should be ignored in the interpretation of the Agreement.
14.3	Use of the masculine pronoun shall be deemed to include usage of the feminine pronoun where appropriate.

        15. CHAIRMANS ACKNOWLEDGMENT
Chairman acknowledges he has had the opportunity to consult legal counsel concerning this Agreement, has read and understands the Agreement, that Chairman is fully aware of its legal effect, and has entered into it freely based on his own judgment and not on any representations or promises other than those contained in this Agreement.


IN WITNESS OF WHICH the parties have executed this agreement in the manner below, on the date indicated above

THE COMMON SEAL of the within named Company is hereby affixed and the deed duly delivered in the presence of:

_________________						   _______________________
Signature						              Signature
Name:	____________________				  Name: ______________________
Title:	____________________		                          Title: ________________________

SIGNED by the within named Chairman:

_____________________________                                   _______________________________
Signature							Name of Chairman

IN THE PRESENCE OF:
Name: 
Address:
Occupation:
Signature:


References:
1a. What is the name of the Company?
1b. What is the Registration Number of the company? 
1c. What is the registered address of the company?
2a. What is the name of the director to be appointed Chairman?
2b. What is the address of the Chairman?
2c. What is the Chairmans annual salary?



DESCRIPTION OF THE DOCUMENT 
A Cleaning Service Contract is the document used by a cleaning company and their clients to determine the terms and conditions governing the cleaning arrangement. Cleaning Contracts ultimately regulate the services the cleaning company provides and the payment the client is required to make. There are several benefits to hiring a professional for contract cleaning, and it can be helpful to know some details when deciding whether to hire such a company. 

FREQUENTLY ASKED QUESTIONS (FAQs)
Why do I need a Cleaning Services Contract?
The Contract enables both parties agree upfront their expectations for what types of cleaning they want done, and what level of service and detail should be provided for each cleaning task. For a freelance maid or janitor, you can use a cleaning services contract to manage your clients and maintain your self-employment records. Offering a properly drafted legal document assures your prospective client of your professional credentials and ensures you are legally protected in the event of a dispute over payment.

What information is included in a Cleaning Services Contract?
A Cleaning Services Contract typically contains the following items:
�	If the cleaning service will be a regularly-scheduled or one-time job
�	The location and time of day for the cleaning service to arrive
�	Contact information for the cleaning service and the client
�	Payment details: hourly rate, if a deposit is required, and when the cleaning service will be paid
�	Who will provide the necessary cleaning equipment and consumables



DESCRIPTION OF DOCUMENT
A Codicil is a document that is executed by a person who had previously made his or her Will, to modify, delete, qualify, or revoke provisions contained in it. A Codicil effectuates a change in an existing Will without requiring that the Will be re-executed. The maker of the Codicil identifies the Will that is to be changed by the date of its execution. The Codicil should state that the Will is affirmed except for the changes contained therein. The same formalities that are necessary for the valid execution of a Will must be observed when a Codicil is executed (sound mind, execution in the presence of witnesses etc.) as failure to do so renders the Codicil void.

FREQUENTLY ASKED QUESTIONS (FAQs)
When do I need a Codicil?

Instances in which you can amend your Will using a Codicil includes where:
o	You want to remove, change or add beneficiaries 
o	Your assets and finances have significantly changed
o	A beneficiary under the previous will is dead
o	The executor dies before the testator
o	You have had a change of heart with respect to the earlier distribution outlined in the Will
Can I add to an existing Will using a Codicil without changing the previous content of the Will?
Adding an addendum to a Will requires a document called a Codicil. If drafted appropriately, the codicil will be considered a part of the Will and read alongside the original document when the estate is probated. The Codicil must be signed and executed in same way as the Will in other to be valid.
Can a Codicil revoke a Will?
You should consider making a new will if the changes you want to make are substantial or if you intend to revoke the entire Will. A Codicil  can only amend, add to or partly revoke an existing Will provided that it refers to the existing Will. In other words, a Codicil can be used to change specific provisions of a Will but leaves all the other provisions the same. 

 CODICIL TO THE LAST WILL AND TESTAMENT
OF
___________________________________(1a)

I ____(2a)___ (Testator) of  _____2b______ declare this to be the Codicil to my last Will and Testament executed by me on ______2c

My Last Will and Testament shall be amended and modified as set forth herein below: 
______3a

I hereby confirm and republish my Last Will dated _____2c in all respects other than those mentioned above.
IN WITNESS OF WHICH, I sign and execute this instrument at _____4a, as the Codicil to my last Will, willingly while of sound mind and under no constraint or undue influence, in the presence of each of the undersigned witnesses, on this ____ day of ________________, 20____.



________________________________   ___________________________________
Testator Signature			                     Testators Name
The foregoing instrument, was on this ____ day of ________________, 20____, signed to by the above-named Testator, and declared to be the Codicil to the Last Will and Testament, in the presence of us and each of us, who thereupon, at the request of the Testator and upon the presence of the Testator, and in the presence of each other, have hereunto subscribed our names as attesting witnesses thereto. We hereby declare that to the best of our knowledge, the Testator is of sound mind and under no constraint or undue influence


1st Witness:



_____________________________
Witness Signature



______________________________			             
 Name


_____________________________ 
 Address

2nd Witness:



_______________________________			             
Witness Signature


_______________________________			             
Name

_______________________________			             
Address

References
1a. Who is the Testator?
2a. Please insert the name of the Testator
2b. What is the address of the Testator?
2c. On what date was the Last Will sought to be amended by this Codicil executed?
3a. Outline in numbered paragraphs the amendments or additions you wish to effect in the Last Will using this Codicil (E.g Clause 4 of my Last Will on �Disposition of Property: a. My 5 bedroom duplex situate at 10 BD lane Lagos which was previously given to Mr Ebuka Thompson under my Last Will is now revoked and willed to my son Raphael Thompson born on 28 September 2017) 
4a. Kindly provide the location/address where you will sign the Codicil.



Description of the Agreement
A Confidentiality Agreement is a contract used by parties to protect information, ideas, transaction details, etc. from disclosure to a party not authorized to access the information. In this agreement the parties impose obligations to refrain from disclosing information and take necessary measures to protect the confidentiality of information and its use only specified purpose(s). 

Frequently Asked Questions (FAs)
When should I contract a Confidentiality Agreement?
It is highly recommended to execute a confidentiality agreement before disclosing confidential information as this will ensure both parties understand their duties and privileges. However, a confidentiality agreement can be drafted to include previous disclosures to cover instances where the parties had already disclosed confidential information before executing the agreement. 
Why do I need a Confidentiality Agreement? 
Circumstances in which a confidentiality agreement is important are:
a.	In pre-contract situations 
b.	To protect technical or commercial information which may be discovered in course of business relationships such as technology transfers, due diligence exercises, partnership arrangements etc. 
c.	To protect trade secrets 
How long does a confidentiality agreement last for? What is the standard period length?
A confidentiality agreement will last for as long as the parties agree in the contract. For example, where the contract provides for a period of 2 years, the parties are bound to keep such information secret for a period of 2 years from the signing of the document. 
Generally, there is no standard period for confidentiality agreements; the specified period will depend largely on the nature/duration of the transaction and the disclosure needs of the parties.
Is there a difference between a Confidentiality Agreement and a Non-Disclosure Agreement?
Although the terms (and the agreements) are used interchangeable, in practice they are used in slightly different circumstances. Confidentiality Agreement is used when a higher degree of secrecy is required because the parties are required to be more proactive in making sure information is kept secret. This could include putting restrictions on the use of confidential information, protection of electronic databases, preventing theft by employees, requiring sub-contractors to be bound by the same agreement, etc. 
Non-Disclosure Agreements typically require that the parties do not disclose personal or private information without the additional obligation to   

Who should sign the Confidentiality Agreement?
Recommendation is to have the agreement signed by directors or a director and company secretary as it would on face value be deemed to have authorization to enter into the agreement on behalf of the company. 


DRAFT OF THE CONFIDENTIALITY AGREEMENT
This CONFIDENTIALITY AGREEMENT (hereinafter referred to as the Agreement) is entered into this __ day of _________, 20__ (the Effective Date) by and between ________1a__________ a company duly incorporated and existing under the laws of _______1b_________ with RC Number ________1c_________ having its registered office at ______1d______ and  _______2a_________ , a company duly incorporated and existing under the laws of____2b____  with RC Number ____2c_____ having its registered office at _________2d__________ 
_____1a_______ and ______2b________ are collectively referred to as Parties, and individually as Party.

WHEREAS: 
a.	The Parties are engaged in discussions relating to a prospective business relationship(hereinafter referred to as the Project), and in the context of which either Party (Receiving Party) may disclose Confidential Information to the other Party (Disclosing Party)  (as defined in Clause 1.1 hereunder);

b.	Each Party acknowledges that it is of utmost importance to the Disclosing Party that the Confidential Information remains confidential and that its disclosure to persons other than as permitted under the terms of this agreement may prejudice the Disclosing Partys operations and business; and

c.	The Parties wish to set out their obligations regarding such Confidential Information.

NOW THEREFORE, THE PARTIES AGREE AS FOLLOWS:
1.	FOR THE PURPOSES OF THIS AGREEMENT:

1.1	"Confidential Information" shall mean without limiting the generality of the term:
(a)	all information (including technical, scientific, commercial, financial and/or marketing information, studies, reports, plans, maps, models, designs and/or drawings) and other materials in whatever form including information whether oral, written or contained in a document, electronic file or captured in any other way of representing or recording information which contains or is derived or copied from such information, relating to the Project or the activities of the disclosing party or any of the disclosing partys Affiliates, their interests and/or business, which is disclosed by the disclosing party or any of the disclosing partys representatives to the receiving party or any of the receiving partys representatives on or after the date indicated above, but excluding any information:
i.	which was known to or in the possession of the receiving party prior to disclosure;  
ii.	which is, or becomes public knowledge through no breach of the receiving party of the obligations as to confidentiality herein;
iii.	which is approved in writing for public disclosure by the disclosing party;
iv.	independently developed by a Party without reference to or use of the confidential information of the disclosing party;
v.	disclosed to a Party by some other persons or body who has no duty of confidentiality to the disclosing party.
1.2	"Parties" means the disclosing party and the receiving party and Party means either of          them, as the context requires
1.3	Disclosing Party means a Party that discloses Confidential Information under this Agreement.
1.4	 Receiving Party means a Party that receives Confidential Information under this Agreement. 
1.5	Representative means in relation to a Party, its officers, directors, members, partners, potential sources of capital, employees, agents, consultants, advisors (including without limitation, attorneys, accountants and financial advisors)

2.	The Parties hereby acknowledge and agree that in connection with the Project, the Disclosing Party will deliver and make available to the Receiving Party certain Confidential Information and accordingly the Receiving Party undertakes to:

2.1	 keep secret and confidential and cause its Representatives to keep secret and confidential and not, without the prior written consent of the Disclosing Party, disclose, sell, publish, utilize, employ, exploit or in any manner whatsoever use or allow the use by any person (other than persons permitted herein, or as required by law, or by any court, or pursuant to enquiry or investigation, by any governmental, or regulatory body which is lawfully entitled to require such disclosure, of the Confidential Information  for any other purpose than the evaluation and/or pursuit of the Project;

2.2	only disclose the Confidential Information to such of its Representatives who have a clear need to know such information for the purposes of assessing the Project and to ensure that its Representatives are informed of the confidential nature of such information and of the terms of this Agreement.

2.3	not disclose to any third party (other than as permitted herein) the fact that the Disclosing Party has approached the Receiving Party regarding the Project, or that discussions or negotiations are taking place between the Parties, save with the prior written consent of the Disclosing Party (consent of which shall not be unreasonably withheld); and

2.4	take all practical steps to ascertain any third party given access to the Confidential Information pursuant to 2.3 above is informed of and agree in writing to observe the confidential nature of the Confidential Information in the form or substantially in the form as provided for in this Agreement.

2.5	keep all Confidential Information secure and protected against leak, theft, damage, loss or unauthorized access

3.	The Receiving Party undertakes that if it and/or any of its Representatives become(s) legally compelled to disclose any of the Confidential Information or the existence of the Project by any requirement or order of any court of competent jurisdiction or other competent judicial, governmental or regulatory body or by the rules of, or notice issued by, any stock exchange or listing authority stock exchange rules, the Receiving Party shall, to the extent permitted by law and prior to such compelled disclosure to the extent possible, notify the Disclosing Party so that it may seek a protective order or other appropriate remedy.  If such protective order or remedy is not obtained, the Receiving Party undertakes that it shall disclose (in a manner reasonably designed to preserve its confidential nature) only that portion of the Confidential Information that is legally required.

4.	Nothing in this Agreement shall be construed as making one Party the agent of the other.  Neither Party shall be entitled to commit the other Party to any arrangement or agreement with regard to the Project until such time as and if a definitive agreement with regard to the Project has been duly executed nor to have any obligation pursuant to this Agreement or otherwise to disclose or continue to disclose or make available Confidential Information to the Receiving Party or any of the Receiving Partys Representatives.

5.	The Receiving Party acknowledges that the Confidential Information shall at all times remain the property of the Disclosing Party and nothing in this Agreement or in any disclosure made hereunder confers on the Receiving Party any rights or interest of whatever nature in the Confidential Information.

6.	In the event of a breach or a threatened breach by the Receiving Party of any of its obligations hereunder and notwithstanding any other provision of this Agreement, the Disclosing Party shall be entitled to apply to a court of competent jurisdiction for: an injunction, specific performance and/or an order restraining the Receiving Party from disclosing in whole or in part the Confidential Information. Nothing herein shall be construed as prohibiting the Disclosing Party from pursuing any other additional remedy or remedies or relief(s) available whether under this Agreement, in law or in equity for such a breach or threatened breach.

7.	The Receiving Party shall be liable for, and hereby indemnifies and holds harmless the Disclosing Party of any loss suffered or damage incurred of whatsoever nature (including but not restricted to all legal fees, costs and expenses) as a result of any unauthorised disclosure of the Confidential Information where such disclosure is in breach of its obligations under  this Agreement provided however, that the Receiving Party shall not be liable for indirect, consequential and reasonably unforeseen loss(es) or damage(s) resulting from or arising out of this Agreement including, without limitation, loss of profit or loss of business or business interruptions, however same may be caused.

8.	This Agreement shall remain in effect from the date indicated above (Effective Date) and shall terminate _____7_____ after the Effective Date except otherwise agreed by the Parties in writing.

9.	Nothing contained in this Agreement may be construed as binding or committing either of the Parties to enter into discussions, negotiate or conclude any other agreement in connection with the Project.  This Agreement shall not constitute or be construed to constitute an offer, an acceptance, or a contract to negotiate, or commitment pertaining to the subject matter of the Project, except as expressly stated herein. It is agreed that the discussions under this Agreement are not exclusive and both Parties are entitled to discuss or agree on any matter with any third party provided that the terms of this Agreement are not breached.

 
10.	No Party shall assign or transfer any of its rights or liabilities in whole or in part under this Agreement without the prior written consent of the other Party (such consent not to be unreasonably withheld). This Agreement shall inure in the benefit of, and be binding on all heirs, successors and assigns of the Parties.

11.	None of the requirements of this Agreement shall be considered waived unless waived in writing by the Party concerned or its Representatives. The waiver by any Party of one or more of its rights hereunder shall not be deemed or construed as a continuing waiver on the part of such Party as to prohibit such Party from demanding the performance of any of the other duties and obligations owed it in accordance with the provisions of this Agreement.  

12.	This Agreement constitutes the entire agreement between the Parties hereto relating to the use, disclosure and protection of Confidential Information, and no terms or provisions of this Agreement shall be varied or modified by any prior or subsequent statement, conduct or act of any of the Parties save and except that the Parties may amend this Agreement by written instruments specifically referring to and executed in the same manner as this Agreement. Any such instrument shall be signed by the duly authorised Representatives of the Parties. The Agreement shall supersede any prior agreement between the parties regarding the use, disclosure and protection of Confidential Information.

13.	The interpretation, implementation, enforcement and termination of this Agreement shall be governed by the laws of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement.

14.	Parties shall use their best efforts to settle any disputes arising between them amicably within 20 days. 

14.1	However, where the Parties are unable to reach an amicable resolution, such dispute, controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration conducted by a sole arbitrator in accordance with the Arbitration and Conciliation Act Cap A18 Laws of the Federal Republic of Nigeria 2004.

15.	This Agreement may be executed in two (2) or more counterparts, each of which shall be deemed an original, but all of which together shall constitute one (1) and the same Agreement.

IN WITNESS OF WHICH THE PARTIES HAVE EXECTUTED THIS DEED IN THE MANNER BELOW ON THE DATE INDICATED ABOVE:

THE COMMON SEAL OF the within named   __________1a____________ is hereby affixed and this deed duly delivered in the presence of: 


____________________						_______________________
NAME:								NAME:	
DESIGNATION							DESIGNATION: 
								

THE COMMON SEAL OF the within named __________2a____________ is hereby affixed and this deed duly delivered in the presence of: 


____________________						_______________________
NAME:								NAME:
DESIGNATION							DESIGNATION:								




References:
1a. What is the first partys full name 
1b. What is the first partys Country of registration/incorporation 
1c. Company 1s Registration Number 
1d. Company 1s Registered Office Address  
2a. Company 2s Name  
2b. Company 2s Country of registration/incorporation 
2c. Company 2s Registration Number 
2d. Company 2s Registered office
7. For how long should the confidentiality obligation remain in force? E.g. 6 months, 2 years, upon conclusion of the Project etc. 



DESCRIPTION OF THE DOCUMENT	
A Confidentiality and Exclusivity Agreement is used by parties to protect sensitive information from disclosure to external parties not authorized to access the information and also ensure the project or business contemplated will be carried out exclusively by the parties. This is used by individuals and businesses in the course of business transactions, projects, employment or other interactions with third parties. By entering into a confidentiality and exclusivity agreement, the recipient undertakes to protect the information or data made available to him by the disclosing party from other parties for a stated period of time or the Recipient undertakes not to use same for personal benefits.  The exclusivity angle in this agreement is the understanding that parties will not deal with or use a third party to fulfil either party�s obligations in respect of the business transaction for the period agreed by the parties in the Agreement.

CONFIDENTIALITY AND EXCLUSIVITY AGREEMENT
This Confidentiality and Exclusivity Agreement (the Agreement) is dated this______ day of __________, 20__ (Effective Date) 
BETWEEN
________ (1a) a company duly incorporated and existing under the laws of _______ (1b) with RC Number ________ (1c) having its registered office at ______1d
 AND_______ (2a), a company duly incorporated and existing under the laws of____ (2b) with RC Number ____ (2c) having its registered office at _________ (2d)

WHEREAS: 
a.	The Parties are engaged in discussions relating to a prospective business relationship (hereinafter referred to as the Project), and in the context of which either Party may disclose Confidential Information to the other Party. 
b.	Each Party acknowledges that it is of utmost importance to the Disclosing Party that the Confidential Information remains confidential and that its disclosure to persons other than as permitted under the terms of this agreement may prejudice the Disclosing Partys operations and business; and
c.	Each Party is also willing to commit to exclusivity on the terms set forth below in connection with the Project.

d.	The Parties wish to set out their obligations regarding such Confidential Information and exclusivity terms in accordance with the terms of this Agreement.

NOW THEREFORE, THE PARTIES AGREE AS FOLLOWS:
1.	FOR THE PURPOSES OF THIS AGREEMENT:

1.1	"Confidential Information" shall mean without limiting the generality of the term:
(a)	all information (including technical, scientific, commercial, financial and/or marketing information, studies, reports, plans, maps, models, designs and/or drawings) and other materials in whatever form including information whether oral, written or contained in a document, electronic file or captured in any other way of representing or recording information which contains or is derived or copied from such information, relating to the Project or the activities of the disclosing party or any of the disclosing partys Affiliates, their interests and/or business, which is disclosed by the disclosing party or any of the disclosing partys representatives to the Recipient or any of the Recipients representatives on or after the date indicated above, but excluding any information:
i.	which was known to or in the possession of the Recipient prior to disclosure;  
ii.	which is, or becomes public knowledge through no breach of the Recipient of the obligations as to confidentiality herein;
iii.	which is approved in writing for public disclosure by the disclosing party;
iv.	independently developed by a Party without reference to or use of the confidential information of the disclosing party;
v.	disclosed to a Party by some other persons or body who has no duty of confidentiality to the disclosing party.
1.2	"Parties" means the disclosing party and the recipient and Party means either of          them, as the context requires
1.3	Disclosing Party means a Party that discloses Confidential Information under or in anticipation of this Agreement.
1.4	 Recipient means the Party to this agreement that receives Information directly or indirectly from the Disclosing party
1.5	Representative means in relation to a Party, its officers, directors, members, partners, potential sources of capital, employees, agents, consultants, advisors (including without limitation, attorneys, accountants and financial advisors)

2.	PROVISION OF CONFIDENTIAL INFORMATION & USE

The Parties hereby acknowledge and agree that in connection with the Project, the Disclosing Party will deliver and make available to the Recipient certain Confidential Information and accordingly the Recipient undertakes to:

2.1	 keep secret and confidential and cause its Representatives to keep secret and confidential and not, without the prior written consent of the Disclosing Party, disclose, sell, publish, utilize, employ, exploit or in any manner whatsoever use or allow the use by any person for any other purpose than the evaluation and/or pursuit of the Project;

2.2	only disclose the Confidential Information to such of its Representatives who have a clear need to know such information for the purposes of assessing the Project and to ensure that its Representatives are informed of the confidential nature of such information and of the terms of this Agreement.

2.3	not disclose to any third party (other than as permitted herein) the fact that the Disclosing Party has approached the Recipient regarding the Project, or that discussions or negotiations are taking place between the Parties, save with the prior written consent of the Disclosing Party (consent of which shall not be unreasonably withheld); and

2.4	take all practical steps to ascertain any third party given access to the Confidential Information pursuant to 2.3 above is informed of and agree in writing to observe the confidential nature of the Confidential Information in the form or substantially in the form as provided for in this Agreement.

2.5	keep all Confidential Information secure and protected against leak, theft, damage, loss or unauthorized access

3.	AUTHORISED DISCLOSURES & EXCEPTIONS
The above obligations of the confidentiality in clause 2 shall not apply to, and the term Confidential Information shall be deemed to exclude any information which the Recipient can prove by written records:
i. was known to the Recipient prior to any such disclosure to the Recipient by the Discloser;
ii. was at the time of disclosure by the Discloser, or subsequently becomes, published, accessible to the public or otherwise in the public domain other than through any breach of this Agreement by the Recipient or any Authorised Person;
iii. may be required by law, regulation or order of a court of competent jurisdiction to be disclosed and the Recipient will immediately notify the Discloser in writing of the requirement for disclosure and of all relevant surrounding circumstances. If the Recipient is unable so to notify the Discloser before such disclosure is required it will notify the Discloser immediately after the disclosure has been made. The Recipient will use its best endeavours to resist disclosure (and to assist the Discloser in resisting the requirement for disclosure) and to maintain the confidentiality of any Confidential Information.

4.	NO IMPLIED RIGHTS & PARTNERSHIPS
4.1      This agreement shall not be construed:
i. To grant the Recipient any license, rights or interests of whatever nature other than  expressly set out in this agreement in respect of the Confidential Information nor
ii. To require the Disclosing Party to disclose any Confidential Information to the Recipients not related to the Project.

4	BREACH AND RELIEF
4.2	The Recipient undertakes that the rights which are sought to be protected by this  Agreement are unique and that any breach of it by the Recipients Representative would cause the Discloser irreparable and unquantifiable damage and that the Disclosing Party shall be entitled to apply for and obtain (but without prejudice to any such rights as the Disclosing Party may have to obtain damages in any such respect) interlocutory and/or final injunctive or equitable relief  against or in respect of any actual or threatened breach hereof by the Recipient or any of the Recipients Representative.

6.	TERM OF AGREEMENT
The term of this agreement shall be for _________(3), commencing from the Effective Date subject to renewal by mutual agreement of the Parties, except for the obligations of the Parties hereto with respect to Confidential Information received prior to such termination, which shall survive such termination.


7.	NO WAIVER/ PROVISION FOR SPECIFIC PERFORMANCE
None of the requirements of this Agreement shall be considered waived unless waived in writing by the Party concerned or its Representatives. The waiver by any Party of one or more of its rights hereunder shall not be deemed or construed as a continuing waiver on the part of such Party as to prohibit such Party from demanding the performance of any of the other duties and obligations owed it in accordance with the provisions of this Agreement.  

8.	GOVERNING LAW AND DISPUTE RESOLUTION
9.1 The interpretation, implementation, enforcement and termination of this Agreement shall be governed by the laws of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement.

       9.2 Parties shall use their best efforts to settle any disputes arising between them amicably. Any dispute, controversy, breach or claim arising out of this Agreement shall first be resolved through amicable negotiations in good faith between the parties, or else settled by arbitration conducted by a sole arbitrator in accordance with the current Arbitration and Conciliation Act, Laws of the Federation of Nigeria.

9.	MUTUALITY / NON-CIRCUMVENTION
The Parties agree that the spirit of this Agreement is one of mutual trust and confidence, and one of reliance on each other to do what is fair and equitable. In furtherance thereto, it is hereby agreed that neither party shall take any steps that will circumvent the business or commercial interest of the other Party as regards the underlying objective of this Agreement or in any manner relating to the information to be disclosed.
10.	EXCLUSIVITY 
During the period commencing from _________ (4) and continuing through _________ (5) (the Exclusivity Period), the Parties will not, and will not permit any of its Representatives to, directly or indirectly:
i.    solicit or encourage the initiation or submission of any expression of interest, inquiry,   proposal or offer from any person (other than Recipient or any subsidiary of Recipient) relating to or in connection with the Project; or
ii.  participate in any discussions or negotiations or enter into any agreement with, or provide any non-public information to, any person (other than Recipient and the Compans Representatives or any subsidiary of Recipient) relating to or in connection with the Project.
The provisions of this Clause 10  shall (unless extended in writing by the Parties) automatically terminate as of the end of the Exclusivity Period and shall thereafter be of no further force and effect, except with respect to any prior breach of this Clause 10.

11.	ENTIRE AGREEMENT & COUNTER PARTS

11.1	This Agreement constitutes the entire agreement between the Parties hereto relating to its subject matter, and no terms or provisions of this Agreement shall be varied or modified by any prior or subsequent statement, conduct or act of any of the Parties save and except that the Parties may amend this Agreement by written instruments specifically referring to and executed in the same manner as this Agreement. Any such instrument shall be signed by the duly authorised Representatives of the Parties. The Agreement shall supersede any prior agreement between the parties regarding its subject matter.
12.2   This Agreement may be executed in two (2) or more counterparts, each of which shall be deemed an original, but all of which together shall constitute one (1) and the same Agreement
 

IN WITNESS OF WHICH the parties have executed this Confidentiality and Exclusivity Agreement in the manner below on the date indicated above:
   FOR AND ON BEHALF OF __________(6):

				
Authorized Signature				


				
Name 

 				
 Title					






FOR AND ON BEHALF OF __________(7):


			_______
Authorized Signature					

			_______
Name 


				
 Title					

References:
1a. What is the first partys full name? 
1b. What is the first partys Country of registration/incorporation? 
1c. What is the first partys Registration Number?
1d. What is the first partys registered office address? 
2a. What is the second partys name?  
2b. What is the second partys Country of registration/incorporation?
2c. What is the second partys Registration Number?
2d What is the second partys registered office address?
3. What is the duration of the Confidentiality Agreement?
4. When does exclusivity period start?
5. When does exclusivity period end?
6. Insert first partys name
7. Insert second partys name 

Frequently Asked Questions (FAQs)
What type of information can be protected using a Confidentiality and Exclusivity agreement?
Any information made available to the recipient by the disclosing party that is not common knowledge or in public domain. Hence the following information can be protected using a confidentiality and exclusivity agreement; client information, intellectual property information, business operations and accounting information etc. 

Why do I need a Confidentiality and Exclusivity Agreement? 
Circumstances in which a confidentiality agreement is important are:
a.	In pre-contract situations 
b.	To protect technical or commercial information which may be discovered in course of business relationships such as technology transfers, due diligence exercises, partnership arrangements etc. 
c.	To protect trade secrets. 
d.	To ensure the business relationship between the parties in respect of the stated transactions are conducted to the exclusion of other entities.  

Is there a time frame for a confidentiality and exclusivity agreement?
A confidentiality agreement should last for a reasonable time long enough to protect the interest of the disclosing party. This time should not be unfair or unreasonable as that would make the courts unable to enforce the agreement. For example, where the contract provides for a period of 3 years, the parties are bound to honour their obligations in the agreement for that time duration.
Generally, there is no standard period for confidentiality agreements; the specified period will depend largely on the nature/duration of the transaction and the disclosure needs of the parties.
Are confidentiality and exclusivity agreements enforceable?
Yes. As long as the terms are not too broad and there are no unfair restrictions
Is there a difference between a Confidentiality Agreement and a Confidentiality and Exclusivity Agreement? 
A Confidentiality Agreement is a contract between two or more parties where the subject of the agreement is a promise that information conveyed will be maintained in secrecy. A Confidentiality and Exclusivity Agreement on the other hand in addition to the above ensure the parties only deal with each other in respect of the transaction leading to the disclosure of confidential information for the period as agreed by the parties.

Who should sign the Confidentiality an Exclusivity Agreement for corporate entities?
Like with any other agreement, recommendation is to have the confidentiality and exclusivity agreement signed by directors or a director and company secretary as these officials would on face value be deemed to have authorization to enter into the agreement on behalf of the company. 




DESCRIPTION
A contract of sale is a contract by which the seller transfers or agrees to transfer the property in goods to the buyer for a consideration, called the price.  It is a legal contract for an exchange of goods, services or property owned by a seller to a buyer for an agreed upon value paid or the promise to pay same. 


FREQUENTLY ASKED QUESTIONS
 
What is the difference between a contact of sale and a hire purchase? 
In a contract of sale, the seller transfers or agrees to transfer the property in goods to the buyer as soon as the goods are delivered and the price is paid; while. A contract of hire-purchase, on the other hand, is a hire of the goods coupled with an option to purchase them which may or may not be exercised.
What can I use a Contract of Sale?
You can use a Contract of Sale for purchasing/selling goods, services or real estate.


CONTRACT OF SALE DRAFT
This CONTRACT OF SALE (this Agreement) is made and entered into this __day of ____________20__ (the Effective Date) between ____1_____ (the Seller) a company duly incorporated and existing under the laws of ____2_____ with RC Number____3____ having its registered address at ___4____ and ____5_____ (the Buyer) a company duly incorporated and existing under the laws of ____6____ with RC Number ____7_____ having its registered address at ____8_____. 
The Seller and the Buyer are referred to individually as Party and collectively as Parties.
WHEREAS:
A.	The Buyer is in the business of _____9______
B.	The Seller is engaged in the business of _____10______  
C.	The Seller is in true and lawful possession of and wishes to sell  _____11______ (the Goods), 
D.	The Buyer has inspected the Goods, has ascertained that the Goods are fit for purpose and desires to purchase the Goods from the Seller. 
E.	In consideration of the Sum and the mutual covenants contained herein, the Parties wish to be bound by the provisions of this Agreement.

THE PARTIES AGREE AS FOLLOWS:
1.	DEFINITION AND INTERPRETATION
For the purposes of this Agreement:
A.	Agreement means this Contract of Sale 
B.	Goods means the property of the Seller, ownership of which is to be assigned by the Seller to the Buyer.
C.	Delivery means all activities and processes involved in making the Goods available to the Buyer.
D.	Delivery Terms includes but is not limited to the date and address of delivery of the Goods
E.	Sum means the consideration paid by the Buyer to the Seller for the purchase of the Goods as provided in clause 3
F.	Representatives shall include but is not limited to any person(s), officer(s), firm(s) designated by a Party to performs any obligation, duty or role in respect of this Agreement.

2.	DESCRIPTION AND DELIVERY OF GOODS
A.	The Seller shall transfer and deliver the Goods to the Buyer; and 
B.	The Buyer shall take delivery of and accept the Goods in satisfaction of the provisions of this Agreement. 
C.	The Seller shall deliver the Goods covered by this Agreement to the Buyer at ____12_____on ____13_____ (Delivery terms). 
D.	The Parties mutually agree that time is of the essence.
E.	The Seller shall make available upon delivery an itemization of the Goods delivered. 

3.	PAYMENT
A.	Upon delivery of the Goods, the Buyer shall pay the Seller ____15____ (___16___ only) [the Sum] being the sale price for the Goods.
B.	 The Buyer shall pay the Sum within _____17_____ of delivery of the Goods; failing which the Seller shall have the right to lawfully repossess the Goods.

4.	TAXES
The Buyer shall be responsible for the payment of any and all taxes, charges, duties and levies that may accrue in connection with the sale of the Goods.

5.	BUYERS COVENANTS
The Buyer covenants as follows:
A.	To promptly pay the Sum to the Seller 
B.	To promptly pay any and all taxes, charges, duties and levies that may accrue to and in connection with this Agreement 
C.	To obtain all necessary permits, licences and approvals of which the Goods may be subject in respect of this Agreement 
D.	To inspect and accept the Goods upon delivery. Provided that where the Goods are upon delivery discovered to be defective and/or not in conformity with this Agreement, the Buyer shall immediately inform the Seller of such defect or non-conformity.
E.	To ensure complete compliance with all laws, rules, regulations, codes which are applicable to and may in any way affect the Goods. 
   
6.	SELLER COVENANTS:
The Seller covenants as follows: 
A.	To transfer and deliver the Goods to the Buyer 
B.	To ensure that the Goods conform to this contract in respect of quantity, quality and time of delivery
C.	To cure the Goods of any defect or non-conformity communicated by the Buyer pursuant to Clause 5D above.
D.	To defend, protect, indemnify and hold harmless the Buyer of any claim(s), allegation(s), damages and  loss(es) occasioned  by any third party claiming through the seller; ownership of or title to the Goods.   

7.	RISK OF LOSS AND TITLE
A.	The risk of loss as pertaining to the Goods shall pass to the buyer upon delivery of the Goods in accordance with the Delivery Terms of this Agreement 
B.	The Title to and beneficial ownership of the Goods shall remain with the Seller until the Sum is fully paid; upon which beneficial ownership and title shall be transferred to the Buyer. 

8.	WAIVER
None of the requirements, rights or provisions of this Agreement shall be considered waived unless waived in writing by the Party concerned or its Representatives. The waiver by any Party of one or more of its rights hereunder shall not be deemed or construed as a continuing waiver on the part of such Party as to prohibit such Party from demanding the performance of any of the other duties and obligations owed it in accordance with the provisions of this Agreement.

9.	REPRESENTATIONS AND WARRANTIES
A.	Each Party represents and warrants as follows:
i.	It has all requisite resources, power and authority to execute and perform all duties, roles and obligations related hereto.
ii.	The execution, delivery, and performance of this Agreement is not in conflict with any law(s), regulation(s), code(s), agreement(s), judgment(s) to which it is bound.
B.	The Seller represents and warrants as follows:
i.	That without prejudice to Clause 5D, the Goods are of merchantable quality and fit for purpose.
ii.	That all information provided in relation to the Goods and in this Agreement  are true, accurate and complete in every respect, and are not misleading.
C.	The Buyer represents and warrants to have unencumbered cash sufficient to fully satisfy its obligations to pay the Sum to the Seller as specified in the Delivery Terms.
 
10.	SEVERABILITY 
In the event that any clause or provision in this Agreement is held invalid or unenforceable by a court of competent jurisdiction in Nigeria, such invalidity shall not affect the validity or operation of any other clause or provision; and such invalid clause or provision shall be deemed to be severed from this Agreement.

11.	ALTERATION AND MODIFICATION 
This Agreement constitutes the entire agreement between the Parties hereto as regarding the subject matter of this Agreement, and no terms or provisions of this Agreement shall be varied or modified by any prior or subsequent statement, conduct or act of any of the Parties save and except that the Parties may amend this Agreement by written instruments specifically referring to and executed in the same manner as this Agreement. Any such instrument shall be signed by the duly authorised Representatives of the Parties. The Agreement shall supersede any prior agreement between the parties regarding the subject matter of this agreement. 

12.	NOTICE
A.	All notices required hereunder to be given to any party under this Agreement shall be sent to the Party at its address first mentioned herein or such other address as each party may hereafter communicate in writing. 
B.	All notices shall be in writing and may be delivered physically or sent by registered mail or certified mail or electronic mail. The day of mailing of any such notice shall be deemed the date of the giving thereof.
C.	In respect of notices sent by electronic mail as mentioned in clause 11B above;
i.	 notices to the Seller shall be sent to ____19_____ 
ii.	 notices to the Buyer shall be sent to ____20_____ 
13.	GOVERNING LAW 
The implementation and enforcement of this Agreement shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement.

14.	DISPUTE RESOLUTION
A.	Any controversy or claim arising out of or relating to this Agreement, or the breach hereof, the Parties shall expend their best efforts to settle any disputes arising between them amicably within 30 days. 
B.	In the event that the Parties are unable to reach an amicable resolution, such dispute, and controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration; by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap 19 Laws of the Federal Republic of Nigeria 2004.



IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS AGREEMENT IN THE MANNER BELOW, ON THE DATE INDICATED ABOVE:

The Common Seal of the within named Buyer was hereunto affixed in the presence of:

_____________							_______________
NAME:								NAME:
DESIGNATION:							DESIGNATION:


The Common Seal of the within named Seller was hereunto affixed in the presence of:

_____________							_______________
NAME:								NAME:
DESIGNATION:							DESIGNATION:





REFERENCES
(1)	What is the company name of the Seller?
(2)	In what country was the Seller incorporated?
(3)	What is the Sellers RC Number?
(4)	What is the Sellers office address?
(5)	What is the company name of the Buyer?
(6)	In what country was the Buyer incorporated?
(7)	What is the Buyers RC Number?
(8)	What is the Buyers office address?
(9)	Give a brief description of the Buyers business. E.g. Retail of educational resources or retail of jewelry and fashion products
(10)	Give a brief description of the Sellers business.  E.g. Wholesale of educational resources or Wholesale of fashion products and jewelry 
(11)	Kindly provide a detailed description of the Goods/Property E.g. A black 2004 Toyota Camry with registration number C606 PXX, a 5,000 kva Mikano generator, a Suzuki Gixxer 2017 Motorcycle or An iphone 7 mobile phone etc.
(12)	To what address will the goods be delivered? 
(13)	On what date will the goods be delivered? E.g. 5th September 2010
(14)	Deleted the reference here 
(15)	What is the price of the Goods (amount in numbers)?E.g. N300,000 or $4,000
(16)	The price of the Goods in words. E.g. Three Hundred Thousand Naira or Four Thousand British Pounds 
(17)	How soon after delivery is the Buyer meant to make payment? E.g. 5 days or 2 weeks or 1 month
(18)	 
(19)	What is the email address of the Seller?
(20)	What is the email address of the Buyer?


DESCRIPTION 
A demand letter is a letter that requests the performance of an obligation which is in default. Such obligation may be statutory, contractual, or it may arise under the custom or trade of the parties. A demand letter sets out what payment or action is demanded, why you are entitled to the performance of the obligation, how the payment or action may be effected, and a timeline for payment.

NB: This demand letter relates specifically to payment of outstanding debt. 

FREQUENTLY ASKED QUESTIONS (FAQs)
Is a Demand Letter appropriate only for payment and money related issues?
Demand letters are most popularly used in payment related circumstances. However, it may also be adapted to request the performance of an agreement etc.
If my demand letter is not adhered to, what should be my next course of action?
The next course of action is dependent on the content of the demand letter. More often than not, demand letters indicate that noncompliance will lead to the filing of an action in court or resort to any other dispute resolution mechanism. 
Do I need to enclose documentation of the debt to the letter?
Generally, when demand letters are written, it is presumed that the other party is aware of/capable of ascertaining their default; to this extent attachments are not compulsory. However, attaching relevant documentation will no doubt be helpful. 
How much time should I give the receiver to comply?
There is no one-size-fits-all approach to this. However, in determining how much time, the following factors may be considered:
	Complexity/difficulty and speed of compliance: realistically, how soon can your request be complied with?
	Urgency of compliance: how soon do you need them to comply?
	Readiness to impose penalty: if they dont comply, are you ready to begin your next course of action?





________1_________

Dear Sir/Madam,

LETTER OF DEMAND 
I _____2a_______ of __________2b_________ hereby give you notice of demand for the repayment of _____3a_____ (____3b____ only); being the sum owed and due by virtue of _____4_____. Although the understanding was that payment will be made________5________, I am yet to receive full payment despite repeated attempts to recover same.
You are hereby directed to pay the outstanding sum within _________6___________ of receipt of this letter.
Take notice that failure to comply with this demand will leave me with no further option than to proceed against you with any legal means available to enforce recovery of same.
I have attached the relevant documents for your perusal.
N.B: This letter may be tendered as evidence in court in proof of matters related hereto. 

Yours faithfully,


____________________
 _________7___________







REFERENCES 
(1) Please provide the date of this letter. 
(2a) What is your name?
(2b) What is your address? E.g. 26, Ogun drive, Lekki, Lagos
(3a) How much are you demanding (in figures)? E.g. N5,000,000 or $ 3,000
(3b) How much are you demanding (in words)? E.g. five million Naira only or three thousand Dollars 
(4) Kindly provide details of the transaction leading to the debt. E.g. Loan contract or Contract of Sale or Lease Agreement entered into on the 25 November 2015 etc.
(5) When was payment due? 
(6) How long do they have to comply? E.g. 3 days or 3 weeks or 3 months
(7) Insert your name (note to print the demand letter on your letter headed paper. NB: You can easily create a customized letterhead if you do not have one by adding your name and address to the header of the word document before printing the letter. 




DESCRIPTION 
A deed of gift is a legal document that details a voluntary transfer of a gift from a Donor (owner of property) to the Donee (receiver of the gift) without any exchange of money.  It can be used to donate property to another person without demanding payment in return. This document is developed to cater for instances where a person is desirous of transferring immoveable property(ies).   

FREQUENTLY ASKED QUESTIONS (FAQs)
What can I give as a gift?
A property should have the following elements before it can be transferred to someone as a gift:
1.	It must be owned by you(i.e. you cannot give what you dont have)
2.	It must be properly defined, identifiable and/or ascertainable
3.	It must be transferrable 
4.	It should already be in existence( i.e. not property you will own in the future)
5.	It should be tangible

What happens if the Donee does not receive the gift before the Donor dies?
It is very vital that the Donee accept the gift before the Donor dies; this because in law, the deed of gift is only valid during the lifetime of the Donor.  As such, the gift is rendered invalid if the Donee fails to accept it while the Donor is alive. Upon death the property can only be given through a Will.   

What is the difference between a Deed of Gift and a Will? 
A Deed of Gift and a Will essentially serve the same purpose; to give gifts. However, a deed of gift is only effective while the giver (Donor) is alive; while a Will can only take effect at the death of the giver (Testator). 
Therefore, a Deed of Gift is preferable where you want the gift to be given while you are alive. 
Also, a Deed of Gift is tax free; while a Will attracts inheritance taxes. That said, a Deed of Gift should never be used to evade tax. 



Can the Recipient (Donee) be a witness in the deed?
No. It is important that the witnesses in the Deed are uninterested parties; i.e. people who have no share/stake in the property. This is aimed at reflecting the voluntary nature of the gift. 

Can the Donee be a minor?
No. As a general rule, a Deed of Gift can only be made in favour of people who the law recognizes as having capacity to contract. This means the gift cannot be in favour of a minor (i.e. people younger than the age of 21 years). 
A workable solution will be to make the Guardian of the minor the Donee.  

Who should sign the Deed of Gift?
The Gift must be signed by the Donor, the Donee and the Witnesses. 
N.B: Dont forget the witnesses to the Deed must be uninterested parties i.e. having no stake in the transfer of the property.  



DRAFT 
This DEED OF GIFT (hereinafter referred to as the Agreement) is made this ___day of ___________________20___ by ______1_______ of ________2_________ (hereinafter after called the Donor which expression shall where the context so admits include heirs, executors, administrators and assigns) in favour of  __________3__________ of _________4_________ (hereinafter referred to as the Donee which expression shall where the context so admits include his heirs, executors, administrators and assigns). 
The Donor and the Donee are collectively referred to in this Agreement as Parties.
WHEREAS
A.	The Donor is the rightful and beneficial owner of  _____5______ (hereinafter referred to as the Property) , more particularly contained in the Survey Plan with plan No: _____6____ and covered by Certificate of Occupancy Number ____7____having acquired same by virtue of _______8_______, and since then has enjoyed undisturbed ownership and possession of the Property.
B.	The Donor as beneficial owner is desirous of conveying the Property to the Donee as a gift by virtue of this Agreement. 
C.	The Donee has accepted the gift and is desirous of using same for lawful _____9_______ purpose(s). 
NOW THIS DEED WITNESSES AS FOLLOWS:
A.	In consideration of the affinity and/or appreciation of the Donee; the Donor hereby conveys unto the Donor all that Property (more particularly described in Recital A above) 
B.	The Property hereby conveyed shall be held and utilized in the sole discretion of the Donee. 
C.	The Donee herby covenants with the Donor henceforth to pay all rates, levies, charges and fees charged by the relevant government authorities in respect of the Property as may be prescribed from time to time. 
D.	The Donor covenants that the Property is conveyed as beneficial owner, free of any claims or encumbrances from any third party claiming through the Donor.
E.	The Donor covenants for vacant possession and hereby puts the Donee in full, peaceable and exclusive possession of the Property.  
F.	The Donor represents and warrants that all information given herein as to the true and absolute ownership of the Property are true, honest and complete in every respect and are not in any circumstance misleading.     

IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS DEED IN THE MANNER BELOW; ON THE DAY AND YEAR INDICATED ABOVE:




SIGNED, SEALED AND DELIVERED 
By the within named Donor:




______________________
NAME:


IN THE PRESENCE OF:
NAME:
ADDRESS:
OCCUPATION:
SIGNATURE:


SIGNED, SEALED AND DELIVERED 
By the within named Donee:



___________________
NAME: 


IN THE PRESENCE OF:
NAME:
ADDRESS:
OCCUPATION:
SIGNATURE: 






REFERENCES
1.	What is the name of the Donor (i.e. the person transferring the Property)?
2.	What is the Donos address?
3.	What is the name of the Donee (i.e. the person receiving the Property)?
4.	What is the Dones address?
5.	Kindly provide a detailed description of the landed property you are giving as a gift? E.g. 5 bedroom bungalow at 16, Lekki Drive, Apapa, Lagos or 21 hectares of land situate at 16, Lekki Drive Drive, Apapa, Lagos with certificate of Occupancy Number  etc.
6.	What is the Survey Plan Number?
7.	What is the Certificate of Ocuupancy Number?
8.	How did you become the owner of the property? E.g. a Deed of Assignment dated 14th September 1998  or an inheritance in a will dated 14th September 2015
9.	For what purpose will the Donee use the Property? E.g Commercial or Residential. 



DESCRIPTION 
A Deed of Gift is a legal document that details a voluntary transfer of a gift from a Donor (owner of property) to the Donee (receiver of the gift) without any exchange of money.  It can be used to donate property to another person without demanding payment in return. This document is developed to cater for instances where a person is desirous of transferring moveable property(ies). 

FREQUENTLY ASKED QUESTIONS (FAQs)
What can I give as a gift?
A property should have the following elements before it can be transferred to someone as a gift:
1.	It must be owned by you(i.e. you cannot give what you dont have)
2.	It must be properly defined, identifiable and/or ascertainable
3.	It must be transferrable 
4.	It should already be in existence( i.e. not property you will own in the future)
5.	It should be tangible

What happens if the Donee does not receive the gift before the Donor dies?
It is very vital that you accept the gift before the Donor dies; this because in law, the deed of gift is only valid during the lifetime of the Donor.  As such, the gift is rendered invalid if the Donee fails to accept it while the Donor is alive. Upon death the property can only be given through a Will.   

What is the difference between a Deed of Gift and a Will? 
A Deed of Gift and a Will essentially serve the same purpose; to give gifts. However, a deed of gift is only effective while the giver (Donor) is alive; while a Will can only take effect at the death of the giver (Testator). 
Therefore, a Deed of Gift is preferable where you want the gift to be given while you are alive. 
Also, a Deed of Gift is tax free; while a Will attracts inheritance taxes. That said, a Deed of Gift should never be used to evade tax.

Can the Recipient (Donee) be a witness in the deed?
No. It is important that the witnesses in the Deed are uninterested parties; i.e. people who have no share/stake in the property. This is aimed at reflecting the voluntary nature of the gift. 

Can the Donee be a minor?
No. As a general rule, a Deed of Gift can only be made in favour of people who the law recognizes as having capacity to contract. This means the gift cannot be in favour of a minor (i.e. people younger than the age of 21 years). 
A workable solution will be to make the guardian of the minor the Donee.  

Who should sign the Deed of Gift?
The Gift must be signed by the Donor, the Donee and the Witnesses. 
N.B: Dont forget the witnesses to the Deed must be uninterested parties i.e. having no stake in the transfer of the property.  



DRAFT 
This DEED OF GIFT (hereinafter referred to as the Agreement) is made this ___day of ___________________20___ by ______1_______ of ________2_________ (hereinafter after called the Donor which expression shall where the context so admits include heirs, executors, administrators and assigns) in favour of  __________3__________ of _________4_________ (hereinafter referred to as the Donee which expression shall where the context so admits include his heirs, executors, administrators and assigns). 
The Donor and the Donee are collectively referred to in this Agreement as Parties.
WHEREAS
A.	The Donor is the rightful, sole and absolute owner of _____5______ (hereinafter referred to as the Property) having acquired same by virtue of _______6_______, and since then has enjoyed undisturbed ownership and possession of the Property.
B.	The Donor is desirous of conveying the Property to the Donee as a gift by virtue of this Agreement. 
NOW THIS DEED WITNESSES AS FOLLOWS:
A.	In consideration of the affinity and/or appreciation of the Donee; the Donor hereby conveys unto the Donor all that Property (more particularly described in Recital A above) 
B.	The Property hereby conveyed shall be held and utilized in the sole discretion of the Donee. 
C.	The Donor covenants that the Property is conveyed free of any claims or encumbrances from any third party claiming through the Donor.
D.	The Donor represents and warrants that all information given herein as to the true and absolute ownership of the Property are true, honest and complete in every respect and are not in any circumstance misleading.     

IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS DEED IN THE MANNER BELOW; ON THE DAY AND YEAR INDICATED ABOVE:

SIGNED, SEALED AND DELIVERED 
By the within named Donor:




______________________
NAME:


IN THE PRESENCE OF:
NAME:
ADDRESS:
OCCUPATION:
SIGNATURE:


SIGNED, SEALED AND DELIVERED 
By the within named Donee:



___________________
NAME: 


IN THE PRESENCE OF:
NAME:
ADDRESS:
OCCUPATION:
SIGNATURE: 





REFERENCES
1.	What is the name of the Donor (i.e. the person transferring the Property)?
2.	What is the Donors address?
3.	What is the name of the Donee (i.e. the person receiving the Property)?
4.	What is the Donees address?
5.	Kindly provide a detailed description of the property you are giving as a gift? E.g. A black 2004 Toyota Camry with registration number C606 PXX, a 5,000 kva Mikano generator, a Suzuki Gixxer 2017 Motorcycle or An iphone 7 mobile phone etc.
6.	How did you become the owner of the property? E.g. a purchase in 1998 or an inheritance in a will dated 14th September 2015




DESCRIPTION OF DOCUMENT

A DJ is a person who plays popular music in a recorded format usually on radio, at a club, party or event. A DJ/Entertainment service contract is a document that establishes the terms of operations between a DJ and the client.

Price: N10,000

FREQUENTLY ASKED QUESTIONS

Why do I  need a service contract with a DJ?
It is important to clearly document the terms and the operations of the service provider(DJ) so as to be clear on the expectations and delivery between both parties.

Do I need to speak with a lawyer before signing the contract?
You may not need to speak with a Lawyer to sign a DJ service contract if both parties can clearly understand the terms binding them.

Do I need to sign a contract with my client as a DJ?
It is important to clearly document the terms of your operations as a DJ as well as the expectations of your clients i.e. special song request, extension of time, space to setup, lightening of the area etc.

Do the parties need to meet in person before signing a DJ/Service contract?
It is advised that both parties meet in person. Meeting face to face will help you evaluate as the client and to judge whether the DJ is a good match for you and your event: while it helps the DJ to properly interact with clients on their expectations.

Can I include special request(song list) and Do not play list in the service contract?
It is okay to do so if its going set the right tone for your event. 

How do i charge for overtime where my guests wants require my services beyond the stipulated time?
Being hopeful that the DJ will perform as to keeping the atmosphere fun-filled. However, ensure that the DJs Service Contract outlines a specific rate for additional time at the end of the night, whether it is a set price or a prorated amount based on the original price.



DJ/ENTERTAINMENT SERVICE CONTRACT

This DJ/Entertainment service contract is hereby entered into by the within named parties:

_____  whose address is ______  hereinafter called (the Client), which expression shall where the context so admits include its affiliates, successors-in-title and assigns).

and

_____  who address is _____  hereinafter called (DJ), which expression shall where the context so admits include its affiliates, successors-in-title and assigns).

Client and DJ may hereafter also be referred to individually as Party or collectively as Parties

WHEREAS 
1.	The Client is of the opinion that the DJ has experience and expertise to provide services at _____ scheduled to hold on ______ , from ______  to______ 
2.	The DJ has agreed to perform the services in accordance with the terms and conditions in this Agreement.  
NOW, THEREFORE, the Parties hereby agree as follows:
1.	SERVICES 
The Client hereby agrees to engage the Service Provider for the following services; 
1.1	_______ 
1.2	The Service will also include any other service agreed upon by the Parties. 
1.3	The DJ hereby agrees to perform the services stated above.
 1.4 In consideration of these services, the Client agrees to pay the sum of ______  as DJ Services which covers all equipment that will be used for this purpose.
2. TERM
2.1 The term of this Agreement (The Term) will begin on the Effective Date and will remain in force until the completion of the Services. 
2.2 The Parties may extend the agreement subject to agreement in writing. 

3. PRICE
3.1 Client agrees to pay a ______  non-refundable deposit within 24 hours of signing this DJ Services Contract. 
3.2 The remaining balance from the table above shall be due on the day of the event listed above. / The balance of __________  shall be due on the day of the event listed above. 
Payment of the non-refundable deposit reserves the DJ and any necessary event on the day and time listed above.
4. TERMS AND CONDITIONS
4.1 This contract may be cancelled at any time prior to the day of the event by either party. In the event that the Client chooses to cancel this contract, they agree to forfeit their initial deposit.
4.2This contract may only be cancelled by written notice or via  _______________ .
4.3 The Client shall be responsible for ensuring that all event attendees treat the DJ and any equipment on-site with respect. In the event that an attendee acts in a manner which the DJ judges to be dangerous, threatening, or inappropriate, the DJ agrees to provide the Client with one opportunity to correct the behavior. If the problem persists, the DJ may, without warning, cease providing DJ services and vacate the event. If this occurs, the Client will be responsible for full payment of the fees listed in this contract without exception.
4.4 The DJ recognizes that the event listed in this contract is a rain or shine event, and agrees that weather shall not prevent the performance of the services listed in this contract. However, lightning is considered an exception due to the metal framing necessary for light and speaker mounts, and as a result the DJ shall be able to cease operations should a lightning threat occur.
4.5 The Client is responsible for providing the DJ with reasonable working accommodations and power supply.
5. INDEPENDENT CONTRACTOR 
5.1The Parties agree that this agreement creates an independent contractor relationship and shall not be deemed to be an employee, legal representative, dealer, general agent, joint ventures or partner agreement for any purpose. 
	
6. INDEMNITY 
6.1 Except as stipulated by law the parties agree to indemnify the other party including but not limited to the employees, affiliates, customers of all claims, losses, penalties, fines that may arise out of the indemnifying partys actions in relation to this Agreement. 

6.2 The indemnity survives the termination of this Agreement. 

7. ASSIGNMENT 
7.1The Parties agree that the obligations of the Service Provider that arise out of this agreement will not be assigned or transferred without the written consent of the Client. 

8. ENTIRE AGREEMENT
8.1 This Agreement constitutes the entire agreement of the Parties on the subject hereof  and supersedes all prior understandings and instruments on such subject. This Agreement may not be modified other than by a written instrument executed by duly authorized representatives of the Parties

9. WAIVER 
9.1 No waiver of any provision of this Agreement shall constitute a waiver of any other provision(s) or of the same provision on another occasion. Failure of either Party to enforce any provision of this Agreement shall not constitute a waiver of such provision or any other provision(s) of this Agreement. 



10. SEVERANCE
10.1 If any clause or part of a clause in this Agreement is found by a court, arbitrator or other authority of competent jurisdiction to be void or unenforceable, that clause or part of a clause is to be deemed deleted from this Agreement and the remaining clauses and parts thereof shall continue in full force and effect.  If necessary the Parties shall in such event seek to agree upon a valid and enforceable clause or part of a clause to replace the clause or part of a clause found to be void and unenforceable.


11. FORCE MAJEURE
11.1 Either Party shall be excused from any delay or failure in performance required hereunder if caused by reason of any occurrence or contingency beyond its reasonable control, including, but not limited to, acts of God, acts of war, wild fire, insurrection, earthquakes, severe floods, explosions .  
	
11.2 The obligations and rights of the Party so excused shall be extended on a day-to-day basis for the time period equal to the period of such excusable interruption. When such events have abated, the Parties respective obligations hereunder shall resume.

12. TERMINATION 

12.1This Agreement may be terminated by either Party by giving ______  days written notice of such termination to the other Party where any of the following occurs:
(I) any other breach that a Party has failed to cure within a reasonable time after receipt of written notice by the other Party.
 (ii) The death or physical or mental incapacity of Consultant or any key person performing the Services on its behalf as a result of which the Consultant or such key person becomes unable to continue the proper performance of the Services,
 (iii) An act of gross negligence or wilful misconduct of a Party, and 
(iv)  The insolvency, liquidation or bankruptcy of a Party.


13. GOVERNING LAW AND DISPUTE RESOLUTION

13.1 This Agreement shall be construed and governed by the laws of the Federal Republic of Nigeria.
13.2 Where here is a dispute, controversy or claim between the Parties, the Parties shall try to reach agreement amicably by direct negotiations. If no agreement can be reached within thirty (30) days, the dispute, controversy or claim shall be referred to Conciliation in accordance with the Arbitration and Conciliation Act Cap A[18] Laws of the Federation of Nigeria 2004. 
13.3  A dispute shall be deemed to have arisen when either Parties notifies the other Party in writing to that effect. The Language of Conciliation shall be English.


IN WITNESS WHEREOF, the Parties have duly executed this Agreement as of the date first written above.

SIGNED by the within named Client 


____________________ 


SIGNED by the within named DJ


_______________________ 





DESCRIPTION
This a legal contract between an employer and employee which lays out binding terms and conditions that prohibit the employee from disclosing confidential and proprietary information of the company.
An Employee Non-Disclosure Agreement is in effect for the duration of an employee's employment and for a period of time following employment termination.

FREQUENTLY ASKED QUESTIONS (FAQs)
Why do I need an Employee Non-Disclosure Agreement?
A non-disclosure agreement is particularly important for an Employer because it ensures the Employee does not share proprietary knowledge, trade secrets, client or product information, strategic plans, and other information that is confidential and proprietary to the company with third parties, especially competitors.

For how long does the confidentiality obligation last?
Generally, non-disclosure agreements are drafted to ensure confidentiality during the duration of the employment, and for certain period of time (as determined by the employer) after the employment is ended. In determining this, it is advisable to take into consideration the nature of the confidential information and the impact of disclosure on the Employers business. 

What information will be classified as confidential?
A non-disclosure Agreement broadly covers proprietary information relating to the Company business, its strategy, trade secrets etc. It however, does not include:
	information publicly known about the companys operations 
	information discovered before the employment relation began 






This NON-DISCLOSURE AGREEMENT is made this __day of ____________20__ between _____1_____ a company incorporated and existing under the laws of ______2_______ with RC Number ______3______ and registered address at _____4_____ ("the Company") and _____5_____ ("the Employee").
The Company and the Employee shall be referred to individually as Party and collectively as Parties
WHEREAS
A.	The Company is engaged in the business of _______6_______
B.	The Company and the Employee have agreed to enter into an employment relationship pursuant to the contract of employment.
C.	The Employee will perform services for the Company which may require the Company to disclose Confidential Information to the Employee
D.	In consideration of the commencement of Employee's employment with the Company and the compensation that will be paid, Employee and Company agree to be bound by this Agreement.
It is hereby agreed as follows:
1.	DEFINITION AND INTERPRETATION
In this Agreement, the following terms shall have the following meanings: 
1.1	Agreement means this Confidentiality Agreement entered into by the Parties.
1.2	Confidential Information  means any information including proprietary information of any kind, nature, or description concerning any matters affecting or relating to Employee's services for the Company, the business or operations of  the Company, and/or the products, drawings, plans, processes, or other data that is commercially valuable to Company and not generally known or readily ascertainable in the industry. It includes but is not be limited to: 
1.2.1	Company's trade secrets, technical information concerning Company's products and services, including product know-how, formulas, designs, devices, diagrams, software code, test results, processes, inventions, research projects and product development, technical memoranda and correspondence;
1.2.2	information concerning Company's business, including cost information, profits, sales information, accounting and unpublished financial information, business plans, markets and marketing methods, customer lists and customer information, purchasing techniques, supplier lists and supplier information and advertising strategies;
1.2.3	information concerning Company's employees, including salaries, strengths, weaknesses and skills;
1.2.4	information submitted by Company's customers, suppliers, employees, consultants or co-venture partners with Company for study, evaluation or use; and
1.2.5	any other information not generally known to the public which, if misused or disclosed, could reasonably be expected to adversely affect Company's business.
1.3	Employment Relations means the contractual relationship arising out or as a result of the employment of the Employee with the Company
1.4	Representatives any person(s), firm(s) and company (ies) as may be designated by the Parties.
1.5	Third Party means any person(s), firm(s), company(ies), organisation(s) other than the Parties to this Agreement

2.	EMPLOYEE COVENANTS:
The Employee covenants as follows:
2.1	To hold the Confidential Information received from the Company, whether or not prepared or developed by the Employee in strict confidence and shall exercise a reasonable degree of care to prevent disclosure to others.
2.2	To not disclose or divulge either directly or indirectly the Confidential Information to any Third Party unless first authorized to do so in writing by the Company.
2.3	To not reproduce the Confidential Information nor use this Confidential Information commercially, for any purpose or benefit of any Third Party, other than for the performance of Employes duties for the Company.
2.4	To not assign or transfer any obligation(s), right(s) and benefit(s) hereunder save with the prior written consent of the Company. 



3.	EXCEPTIONS TO THE CONFIDENTIALITY OBLIGATIONS OWED TO THE COMPANY BY THE EMPLOYEE:
The Employee shall have no obligation to treat as confidential any information which:
3.1	is or becomes public knowledge through a source other than Employee and through no fault of Employee; or
3.2	is or becomes lawfully available to the Employee from a source other than Company.

4.	REPRESENTATIONS, WARRANTIES, REMEDIES AND WAIVERS
The Employee hereby represents and warrants as follows: 
4.1	that there is no preexisting obligation(s) inconsistent with the provisions of this Agreement
4.2	that any misappropriation of any of the Confidential Information in violation of this Agreement may cause Company irreparable harm, the amount of which may be difficult to ascertain, and therefore the Employee agrees that the Company will have the right to apply to a court of competent jurisdiction for an order enjoining any such further misappropriation and for such other relief as the Company deems appropriate. This right is to be in addition to the remedies otherwise available to Company.
4.3	to indemnify the Company against any and all losses, damages, claims or expenses incurred or suffered by the Company as a result of Employee's breach of this Agreement
5.	WAIVER 
None of the requirements, rights or provisions of this Agreement shall be considered waived unless waived in writing by the Party concerned. The waiver by any Party of one or more of its rights hereunder shall not be deemed or construed as a continuing waiver on the part of such Party as to prohibit such Party from demanding the performance of any of the other duties and obligations owed it in accordance with the provisions of this Agreement.

6.	SEVERABILITY
In the event that any clause or provision in this Agreement is held invalid or unenforceable by a court of competent jurisdiction in Nigeria, such invalidity shall not affect the validity or operation of any other clause or provision; and such invalid clause or provision shall be deemed to be severed from this Agreement.

7.	TERMINATION
On request by the Company made in writing and/or termination of the employment relations with Company for whatever reason, the Employee will promptly deliver to Company: 
i.	all originals and copies of all drawings, notes, documents, records, software programs, media and other materials containing any Confidential Information. 
ii.	all equipment, files, software programs and other personal property belonging to Company.

8.	MISCELLANEOUS
i.	Employee's obligation pursuant to this Agreement shall remain for _____7______ even after Employee's employment with Company ends provided that nothing in this Agreement shall preclude the Employee to maintain as confidential any and all Confidential Information for as long as it remains a trade secret. 
ii.	Nothing contained in this Agreement shall be deemed to make the Employee a partner or joint owner of the Company for any purpose.
iii.	This Agreement shall be binding upon the Employee�s heirs, personal representatives and successors in title and shall inure to the benefit of the Company and its successors in title and assigns.
iv.	The Company may assign its rights under this Agreement to any party at any time

9.	ENTIRE AGREEMENT
10.1	This Agreement expresses the complete understanding of the parties with respect to the subject matter and supersedes all prior proposals, agreements, representations and understandings.
10.2	This Agreement may not be amended except in writing signed by the Company through its representatives and the Employee.


10.	GOVERNING LAW 
The implementation and enforcement of this Agreement shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement.

11.	DISPUTE RESOLUTION
11.1.	Any controversy or claim arising out of or relating to this Agreement, or the breach hereof, the Parties shall expend their best efforts to settle any disputes arising between them amicably within 30 days.
11.2.	In the event that the Parties are unable to reach an amicable resolution, such dispute, and controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration; by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap 18 Laws of the Federal Republic of Nigeria 2004.

IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS DEED IN THE MANNER BELOW, ON THE DATE INDICATED ABOVE:

SIGNED, SEALED AND DELIVERED
The Common seal of the Company is hereby affixed, and this deed duly delivered in the presence of:


__________________						____________________
NAME:								NAME:
DESIGNATION:							DESIGNATION:


SIGNED, SEALED AND DELIVERED 
By the within named Employee

_________________
NAME:
IN THE PRESENCE OF: 
NAME:
ADDRESS:
OCCUPATION: 
SIGNATURE:



REFERENCES:
(1)	What is the name of the Company?
(2)	In what country was the company incorporated?
(3)	What is the RC Number of the company?
(4)	What is the address of the Company?
(5)	What is the name of the Employee?
(6)	Kindly give a brief description of the companys business. E.g sale and retail of electronic appliances
(7)	For how long after the employment relationship is terminated will the employee be required to keep the confidentiality obligation?





DESCRIPTION OF THE DOCUMENT
An Employee Handbook, sometimes also known as an employee manual, staff handbook, or company policy manual, is a book given to employees by an Employer. Usually, the Employee Handbook contains several key provisions which includes information about company culture, policies, and procedures.


FREQUENTLY ASKED QUESTIONS (FAQs)
When do I need an Employee Handbook?
If you are an employer, there is the need to establish the rules of conduct and policies in your organization. And secondly as an employee, to know the rules, rights and responsibilities expected of you in an organization.
Is an Employee Handbook a contract?
The answer depends on whether your handbook has a disclaimer telling employees that they are at-will and cannot rely on the handbook as a contract. 
Hence, an employer who wishes to cushion the effect of any liability arising therefrom may state expressly in the handbook that the Employee Handbook is not a contract, that the employer doesn't intend to be bound by the handbook and the handbook is subject to change at the employer's discretion. Note that this handbook provides such language 
Is an Employee handbook same as a Policy Manual?
Employee Handbooks are provided to new employees to help them better understand the company and its policies. It helps the employees understand the expectations the company has from them and the benefits and other perks the company offers them. An Employee Manual on the other hand is aimed at regulating the relationship between the employees and employer, it contains human resource provisions as well procedural provisions for certain benefits.






TABLE OF CONTENTS

Chapter 1: ____1a
Welcome & Introduction
1.1	Vision
1.2	Mission
1.3	Values
	
Chapter 2: Employment Policies 
2.1	Terms of Employment
2.2	Miscellaneous 
2.3	References 
2.4       On boarding
2.5	Identification cards
2.6	Probationary period
2.7	Working conditions
2.8	Confidentiality of Compans information
2.9	Personal Appearance & Dress code
2.10	Workplace Etiquette & Professional conduct/Personal Behaviour
2.11	Access to Personnel Files
2.12	Personnel Data changes
2.13	Business Ethics & Conduct
2.14	Personal Relationships in the Workplace




Chapter 3: Organisational Structure & Personnel Matters
3.1      Job Descriptions
3.2      Salary Administration

Chapter 4: People Development
4.1	Career Planning & Development
4.2	Training & Development
	
Chapter 5: Reward & Recognition 
5.1	Competitiveness
5.2	Salary Review
5.3	Payday

Chapter 6: Allowances and Fringe Benefits
6.1     Out of Station Allowance
6.2     Vacation Benefits

Chapter 7: Employee Misconduct and Grievance 
7.1	Employee Conduct and Work Rules
7.2	Sexual & Other Unlawful Harassment
7.3	Types of Disciplinary Action
7.4	Problem Resolution & Grievance Practices

Chapter 8: Use of Company Property and General Provisions
 8.1   Use of Company Property
8.2   Emergency Closing
8.3   Business Travel Expenses
8.4    Visitors in the Workplace
8.5    Workplace Monitoring
8.6    Absenteeism
8.7    Attendance & Punctuality
8.8    Security Inspections

Chapter 9: Separation
9.1    Termination
9.2    Voluntary Resignation
9.3    Redundancy
9.4    Accidents while on Official Duty
9.5    Death


















Chapter 1: ____1a

WELCOME 

On behalf of the entire staff, we welcome you to our company and wish you success here. 
 
We believe that each employee contributes directly to the growth and success of the company, and we hope you will take pride in being a member of our team. This handbook was developed to describe some of the expectations of our employees and to outline the policies, programs, and benefits available to eligible employees. Employees should become familiar with the contents of the employee handbook as soon as possible, as it will answer many questions about your employment. In order to for the organization to continue to stand above the rest in the industry, each member of staff is expected to manifest loyalty and devotion as well as maintain a high attention to detail and hard work. The organization in turn is concerned with the wellbeing of its entire staff. Both the organization and the staff have mutual obligation and rights under the employment contract. We believe that professional relationships are easier when all employees are aware of the culture and values of the organization. This guide will help you to better understand our vision for the future of our business and the challenges that are ahead. Please familiarize yourself with the terms of this handbook and in case of any problems with meaning or interpretation, kindly contact the management for assistance. We hope that your experience here will be challenging, enjoyable, and ultimately rewarding.

Again, welcome! 




____________________
Signature

____________________
Name & Designation



INTRODUCTION
This Employee Handbook (Handbook) is a compilation of personnel policies, practices and procedures currently in effect in our company.
The Handbook is designed to introduce you to our Company, familiarize you with Company policies, provide general guidelines on work rules, benefits and other issues related to your employment, and help answer many of the questions that may arise in connection with your employment. This Handbook does not create a contract, express or implied, guaranteeing you any specific term of employment, nor does it obligate you to continue your employment for a specific period of time. The purpose of the Handbook is simply to provide you with a convenient explanation of present policies and practices at the Company. This Handbook is an overview or a guideline. It cannot cover every matter that might arise in the workplace. For this reason, specific questions regarding the applicability of a particular policy or practice should be addressed to the management.

The Company reserves the right to modify any of our policies and procedures, including those covered in this Handbook, at any time. We will seek to notify you of such changes by email and other appropriate means. However, such a notice is not required for changes to be effective.

1.1	Vision: 
________1b

1.2	Mission: 
________1c
1.3	Values: 
_________1d










Chapter 2: EMPLOYMENT POLICIES

Objective: To be an Employer of choice, offering equal opportunities to all employees. The Employer is committed to filling available job positions with the best qualified candidates in terms of qualifications, ability and preparedness to work in line with our values and creative vision.
Employment with the company is voluntarily entered into but where the employee or the Employer wishes to terminate the relationship at any time either party would have to give one months notice in lieu or pay one monts salary. The provisions of the handbook have been developed at the discretion of management and, may be waived, amended or cancelled at any time, at the Employes sole discretion. These provisions supersede all existing policies and practices and may not be amended or added to without the express written approval of the Management.

Employment Applications

The Employer relies upon the accuracy of information contained in the employment application, as well as the accuracy of other data presented throughout the hiring process and employment. Any misrepresentations, falsifications, or material omissions in any of this information or data may result in the exclusion of the individual from further consideration for employment or, if the person has been hired, termination of employment.

Equal Employment Opportunity

In order to provide equal employment and advancement opportunities to all individuals, employment decisions will be based on merit, qualifications, and abilities. The Company does not discriminate in employment opportunities or practices based on color, religion, sex, national origin, age, or any other characteristic protected by law. This policy governs all aspects of employment, including selection, job assignment, compensation, discipline, termination, and access to benefits and training. Any employees with questions or concerns about any type of discrimination in the workplace are encouraged to bring these issues to the attention of their immediate supervisor or the management. Employees can raise concerns and make reports without fear of reprisal. Anyone found to be engaging in any type of unlawful discrimination will be subject to disciplinary action, up to and including termination of employment.

2.1	Terms of employment
Employment Letter  All new employees will be given a letter of employment setting out the following;

a.	Name and address of employee
b.	Job title and description
c.	Salary, Allowances and benefits applicable
d.	Type of employment, whether temporary or permanent
e.	Other particulars as may be deemed necessary
	
2.2	Miscellaneous
a. Employment could be permanent or temporary e.g. contract staff, internships etc.

2.3	References
On joining the Company, every employee is required to provide at least two (2) satisfactory references, one of which must be the employees previous employers. The reference from the immediate past will include a character reference as well as confirmation of previous status and salary.The satisfactory provision of these references is a prerequisite to confirmation.

2.4	On boarding

All new employees will undergo an on boarding program approved by the Employer. The focus will be on the history, Mission & Vision, Core Values, Structure, Policies and Procedures, Services, Job Details, Individual Expectations, Resources and Facilities Available.

2.5	Identification Cards

Every employee will be issued an identity card on joining the Company. On leaving the services of the Company, the identity card must be deposited with the management on the last day of employment.
	All employees are required to wear their identification cards at all times on the Company premises.

2.6	Probationary period

Every employee will be required to undergo a 6-month probationary period before confirmation of employment. During the probationary period, the employees job performance is appraised to determine whether it meets agreed standards and goals. References are also received during this period.

Employees whose performance fall below mutually agreed goals and standards will be allowed an additional 3 months probation. At the end of a cumulative 9-months probation period, employees whose performance still remains below agreed goals and standards will be disengaged from the Companys services.

An employees contract of employment may be terminated during probationary period by either party with notice or payment in lieu of notice. Confirmation of appointment will be given in writing after a probationary period, but conditional upon satisfactory references.

2.7	Working Conditions

Working hours  The Company observes a five-day working week (Monday through Friday) except where any of those days is a government public holiday. Regular working hours will be ______, with an hour break for lunch. The Company however, reserves the right to stagger work hours to suit operational requirements, and weekend hours may be incorporated accordingly, and during busy seasons.

Overtime  There may be circumstances that necessitate extra work in satisfying requirements of the job. The Company will not make overtime payments. The supervisor may however exercise their discretion to authorise reimbursement for transportation and meal expenses for junior employees closing from work after late hours on a normal work day. 

Transfers  In line with business needs and requirements, the Company reserves the right to transfer staff to and from any of its branches in or outside Nigeria. The cost of travel and other logistics will be borne by the Company.

2.8	Confidentiality Obligation

All employees have a responsibility to protect confidential information on the Company, its Partners and other Third Parties entrusted to them or to which they are otherwise exposed.  Therefore, all business information of the Company should be treated as confidential.  Employees must neither use (other than for the business of the Company) nor disclose any confidential information of the Companys business or about existing or previous customers of the Company during or after employment. 

Disclosure of business information about the Company and/or the Companys Partners is allowed only if it is:
a.	required to properly discharge the Companys responsibility under relevant law, or
b.	required as a professional responsibility and is not contrary to applicable laws or regulations in Nigeria, or
c.	properly authorised in writing by the Company and effected in accordance with management guidelines, or 
d.	in response to an enforceable subpoena or summons and effected in accordance with management guidelines.

Any intentional disclosure of confidential information will be regarded as gross misconduct that may attract disciplinary action, up to and including termination of employment.

2.9	Personal Appearance & Dress Code
During business hours, employees of the Company are expected to be neat, tidy and appropriately dressed, maintaining a business professional appearance. Dress, grooming, and personal cleanliness standards contribute to the morale of all employees and affect the professional image the Employer presents to clients and visitors. During business hours or when representing the Employer, you are expected to present a clean, neat, and tasteful appearance. You should dress and groom yourself according to the requirements of your position and accepted social standards. This is particularly true if your job involves dealing with the clients or visitors in person.

2.10	Workplace Etiquette & Professional Conduct/Personal Behaviour 
Employees of the Company represent the Company at all times and accordingly are expected to maintain high standards of professional conduct/personal behaviour. The Company strives to maintain a positive work environment where employees treat each other with respect and courtesy. Sometimes issues arise when employees are unaware that their behavior in the workplace may be disruptive or annoying to others. Many of these day-to-day issues can be addressed by politely talking with a co-worker to bring the perceived problem to their attention. In most cases, common sense will dictate an appropriate resolution. The Company encourages all employees to keep an open mind and graciously accept constructive feedback.

Employees must maintain a professional conduct, standard of good taste, good judgement and a courteous disposition to colleagues and visitors to the companys premises. In all situations, employees of the Company should conduct themselves in a manner that conforms to the best professional image prevailing in the business community.

a.)	Use of Alcohol, Drugs or Other Intoxicating Substances 

The use of alcohol and/or drugs and/or other behaviour altering substances on the Companys premises while on official duty or during the official working hours is forbidden. 
Employees will not present themselves for work under the influence of alcohol and/or drugs and/or other behaviour altering substances.  While it is expected that during social functions, employees may consume some alcohol, such should be limited to amounts that do not result in alteration of behaviour, impairment of the individuals sense of judgement and loss of reasonable conduct.
An employee taking prescribed drugs that might affect his/her performance should discuss the matter with their supervisor. Violation of this policy, if proven, can lead to disciplinary action, up to and including termination of employment.

b.)	Smoking

In keeping with the Companys intent to provide a safe and healthful work environment, smoking is prohibited throughout the workplace. This policy applies equally to all employees, clients, and visitors.

c.)	Illegal Activities 
The Company is committed to the highest level of ethical and legal conduct and expects the same from all employees. Engagement in illegal activities of any sort may be the basis for dismissal or other appropriate sanction(s).

d.)	 Harassment at Work
Harassment is defined as "engaging in a course of vexatious comment or conduct that is known or ought reasonably to be known to be unwelcome".  The Company is committed to keeping the work environment free of harassment and intimidation, supportive of productivity and the personal dignity and self-esteem of all employees.  Be it sexual, ethnic or of any other nature, harassment in the workplace by any person is prohibited. Such behaviour will attract appropriate sanction, if proven.
All employees will treat one another with mutual respect without prejudice to person or position. 

e.)	Other Employment/Income from Other Sources 
Employees are expected to avoid situations where personal interests may conflict with the interest of the Company. Employees may therefore, not enter into another occupation or employment, paid or unpaid, or conduct any trade or business without prior written approval of the employer. 
Employees may not accept employment or compensation or engage in any business that might require disclosure of confidential information of the Company, or that could reasonably be expected to impair the individuals independence of judgement in the performance of the Companys duties. No employee is allowed to accept outside employment, income, gifts or any other benefit from other sources that compromise an employees ability to perform his/her job duties effectively, or to accept overtime and/or accept travel assignments.
Violation of this policy will attract appropriate discipline, up to and including termination of employment with the Company.


2.11	Access to Personnel Files
The Company maintains a personnel file on each employee. The personnel file includes such information as the employee's job application, resume, records of training, documentation of performance appraisals and salary increases, and other employment records. Personnel files are the property of the employer and access to the information they contain is restricted. Generally, only supervisors and management personnel who have a legitimate reason to review information in a file are allowed to do so. Employees who wish to review their own file should contact the management. With reasonable advance notice, employees may review their own personnel files and in the presence of the company officer in charge.

2.12	Personnel Data Changes

It is the responsibility of each employee to promptly notify the employer of any changes in personnel data. Personal mailing addresses, telephone numbers, number and names of dependents, individuals to be contacted in the event of emergency, educational accomplishments, and other such status reports should be accurate and current at all times. 
2.13	Business Ethics and Conduct

The successful business operation and reputation of the company is built upon the principles of quality output and professional conduct by our employees. Our reputation for integrity and excellence requires careful observance of the spirit and letter of all applicable laws and regulations, as well as a scrupulous regard for the highest standards of conduct and personal integrity. The continued success of the company is dependent upon our client's trust and we are dedicated to preserving that trust. Employees owe a duty to the employer, its clients, and stakeholders to act in a way that will merit the continued trust and confidence of the public. The company will comply with all applicable laws and regulations and expects its employees to conduct business in accordance with the letter, spirit, and intent of all relevant laws and to refrain from any illegal, dishonest, or unethical conduct.
In general, the use of good judgment, based on high ethical principles, will guide you with respect to lines of acceptable conduct. If a situation arises where it is difficult to determine the proper course of action, the matter should be discussed openly with your immediate supervisor and, if necessary, with the management, for advice and consultation. Compliance with this policy of business ethics and conduct is the responsibility of every employee. Disregarding or failing to comply with this standard of business ethics and conduct could lead to disciplinary action, up to and including possible termination of employment.

2.14	Personal Relationships in the workplace

The employment of relatives or individuals involved in a dating relationship in the same area of an organization may cause serious conflicts and problems with favoritism and employee morale. In addition to claims of partiality in treatment at work, personal conflicts from outside the work environment can be carried over into day-to-day working relationships. For purposes of this policy, a relative is any person who is related by blood or marriage, or whose relationship with the employee is similar to that of persons who are related by blood or marriage. A dating relationship is defined as a relationship that may be reasonably expected to lead to the formation of a consensual "romantic" or sexual relationship. This policy applies to all employees without regard to the gender or sexual orientation of the individuals involved. Although the company has no prohibition against employing relatives of current employees or individuals involved in a dating relationship with current employees, we are committed to monitoring situations in which such relationships exist in the same area. In case of actual or potential problems, the company will take prompt action, and this can include reassignment. Employees in a close personal relationship should refrain from public workplace displays of affection or excessive personal conversation.








Chapter 3: ORGANISATIONAL STRUCTURE AND PERSONNEL MATTERS              
3.1	Job Descriptions

The Employer makes every effort to create and maintain accurate job descriptions for all positions within the organization. Each description includes a job information section, a job summary section (giving a general overview of the job's purpose), a responsibilities section, and a qualifications section (including education and/or experience, communication skills, and any certification required). The company maintains job descriptions to aid in orienting new employees to their jobs, identifying the requirements of each position, establishing hiring criteria, setting standards for employee performance evaluations. Existing job descriptions are also reviewed and revised in order to ensure that they are up to date. Job descriptions may also be rewritten periodically to reflect any changes in the position's duties and responsibilities. All employees will be expected to help ensure that their job descriptions are accurate and current, reflecting the work being done. Employees should remember that job descriptions do not necessarily cover every task or duty that might be assigned, and that additional responsibilities may be assigned as necessary. Contact the management if you have any questions or concerns about your job description.

3.2	Salary Administration

We believe recruiting and retaining talented employees is critical to our success. The company is committed to paying its employees equitable wages that reflect the requirements and responsibilities of their positions and are comparable to the pay received by similarly situated employees in other similarly situated organizations in the industry. Compensation for every position is determined by several factors, including job analysis and evaluation, the essential duties and responsibilities of the job, and salary survey data on pay practices of other organisations. The company periodically reviews its salary administration program and restructures it as necessary. Merit-based pay adjustments may be awarded in conjunction with superior employee performance documented by the performance evaluation process. Incentive bonuses may be awarded depending on the overall profitability of the company and based on each employee's individual contributions to the organization. 


The organizational structure is attached at the end of this manual for your attention as Appendix A





Chapter 4: People Development

Objective: To clearly define and establish Managemes commitment to equipping staff with requisite skills, knowledge and attitude to perform optimally on the job.
The Company is committed to providing and facilitating training and development opportunities for employees at all levels. The thrust of this commitment is to equip every employee with the requisite skills for consistent peak performance. We are also committed to ensuring that corporate development initiatives accommodate individual career objectives.

4.1	Career planning & development

The development of each employs individual career will be a shared responsibility between the Company and its employees. Each employee is therefore responsible for defining personal goals, skills and talents requirements, and putting forth the required effort necessary to fully develop current skills and learn new ones. Please note that it is important that organisational and personal goals meet for growth to take place.
The Company on its part is responsible for providing the enabling environment, resources and feedback on staff performance. The Company will define the competency requirements for each function, and ensure the deployment of skill sets available in the organization. 

4.2	Training and Development
The Company is a learning organisation committed to providing the necessary environment and support towards progressive self-development. Building employee capabilities and competencies is a major strategy adopted by the company in the achievement of its goals. The Organization is therefore committed to providing technical, professional training and managerial skills development opportunities for all employees. The training needs of individual employee will be identified at the beginning of the year based on the outcome of the annual performance appraisal exercise. 







Chapter 5: REWARD AND RECOGNITION
Objective: The company is determined to be an employer of choice. Therefore, upon the commencement of production by the company, a key strategic objective will be to implement and sustain a competitive pay structure that makes the Company one of the leaders in staff compensation within the industry.

5.1	Competitiveness	
We will operate a competitive remuneration system to attract and retain high performers. The Human Resource department will on a proactive and continual basis, collect and analyse compensation data from benchmark Companies within our industry to ensure that we remain competitive. 
 
5.2	Salary Review	
Subject to overall corporate performance, salaries will be reviewed. The timing and frequency of such reviews will be determined by the Management.

5.3	Payday 
Employee salaries are payable monthly in arrears on a calendar month basis.  Employee salaries will be paid directly into their Staff salary accounts every month. 













Chapter 6: ALLOWANCES AND FRINGE BENEFITS

Objective: As part of the compensation package made available to our employees, we also offer a number of allowances. Qualification for, and the amount of each individuas entitlement vary in relation to employes category /level.

6.1	Out of Station Allowance
When employees are required to work at locations away from their primary base of operations, the Company will provide accommodation, meals and incidentals or pay cash in lieu. 

6.2	Vacation benefits
The company will grant holiday time off to all employees on the holidays listed below:

a.)	Annual Leave
To qualify for their full leave period entitlements, employees will be required to have been in the Companys employment for at least one year. Where an employee has not worked for the full year, they will be entitled to only a pro-rated leave period. In this regard, all staff will be required to fill the Companys Leave form.
Annual Leave Entitlement for all employees will be 21 working days. If business conditions prevent an employee from taking their full leave entitlement in the year, the unused leave may be converted to cash with the agreement of the management. Any leave not used or converted to cash within a given year will be forfeited. 

b.)	National and Religious Holidays
The Company will observe national and religious holidays as declared by the Federal Government of Nigeria in accordance with the industry practice within Nigeria.  An employee may however be required to work on such days at the discretion of the management if the needs of the business so dictate.  

c.)	Maternity Leave
The Company will grant its female employees maternity leave in accordance with the Nigerian employment laws.  To qualify, however, a female employee must have been confirmed in the employment of the Company. Affected staff must produce a medical certificate duly signed by a registered medical practitioner stating their expected date of delivery at least four (4) weeks before submitting application for maternity leave.  Such employees will be paid their full salary for the duration of the maternity leave.  The duration will be a maximum of twelve (12) weeks, to be taken as the employee so desires, PROVIDED that the employee must commence the leave at least two weeks before her expected due date. It should be noted that the leave AFTER delivery may not be extended, except with the approval of the Management. 
Nursing mothers will be allowed to resume one hour late and close one hour earlier than the normal time for a period of three (3) months on resumption of duty after maternity leave.

d.)	Casual Leave
Members of staff may be granted not more than five days casual leave within a year. Granting of casual leave is at the sole discretion of Management. Any application for casual leave must be recommended by the employees supervisor and approved by management. Where the cause of the leave is the event of serious illness/ death of an immediate family member or other approved circumstances, the casual leave will be non-deductible from the annual leave entitlement. It will however be deductible for reasons other than those stated above e.g. the wedding or death of a non-immediate family member. 

e.)	Leave Of Absence
Employees are entitled to a non-deductible leave in the instance of special circumstances demanding absence from work. The special circumstances applicable will be reviewed and approval granted at the discretion of management in the interest of both the employee and the Company.

f.)	Sick Leave Benefits
In the event of an employes inability to come to work due to ill health, sick leave may be granted on the written recommendation of a medical practitioner. During absence, all confirmed employees would receive their salaries up to a maximum duration as agreed between the employee and company, for as long as the illness is not due to self-inflicted injuries, venereal disease, alcoholism or drug addiction.







Chapter 7: EMPLOYEE MISCONDUCT & GRIEVANCE 

7.1	Employee Conduct & Work Rules
To ensure orderly operations and provide the best possible work environment, the company expects employees to follow rules of conduct that will protect the interests and safety of all employees and the organization. It is not possible to list all the forms of behavior that are considered unacceptable in the workplace. The following are examples of infractions of rules of conduct that may result in disciplinary action, up to and including termination of employment:
 Theft or inappropriate removal or possession of property
 Falsification of timekeeping records
 Working under the influence of alcohol or illegal drugs
 Possession, distribution, sale, transfer, or use of alcohol or illegal drugs in the workplace, while on duty
 Fighting or threatening violence in the workplace
 Boisterous or disruptive activity in the workplace
 Negligence or improper conduct leading to damage of property
 Insubordination or other disrespectful conduct
 Violation of safety or health rules
 Sexual or other unlawful or unwelcome harassment
 Possession of dangerous or unauthorized materials, such as explosives or firearms, in the workplace
 Excessive absenteeism or any absence without notice
 Unauthorized use of telephones, mail system, or equipment
 Unauthorized disclosure of business "secrets" or confidential information
 Violation of personnel policies
 Unsatisfactory performance or conduct

7.2	Sexual & Other Unlawful Harassment
The company is committed to providing a work environment that is free from all forms of discrimination and conduct that can be considered harassing, coercive, or disruptive, including sexual harassment. Actions, words, jokes, or comments based on an individual's sex, race, color, national origin, age, religion, disability, or any other legally protected characteristic will not be tolerated.

Sexual harassment is defined as unwanted sexual advances, or visual, verbal, or physical conduct of a sexual nature. This definition includes many forms of offensive behavior and includes gender-based harassment of a person of the same sex as the harasser. 
Unwelcome sexual advances (either verbal or physical), requests for sexual favors, and other verbal or physical conduct of a sexual nature constitute sexual harassment when:
(1) Submission to such conduct is made either explicitly or implicitly a term or condition of employment;
(2) Submission or rejection of the conduct is used as a basis for making employment decisions; or,
(3) the conduct has the purpose or effect of interfering with work performance or creating an intimidating, hostile, or offensive work environment.
If you experience or witness sexual or other unlawful harassment in the workplace, report it immediately to your supervisor. If the supervisor is unavailable or you believe it would be inappropriate to contact that person, you should immediately contact any other member of management. You can raise concerns and make reports without fear of reprisal or retaliation. All allegations of sexual harassment will be quickly and discreetly investigated. To the extent possible, your confidentiality and that of any witnesses and the alleged harasser will be protected against unnecessary disclosure. When the investigation is completed, you will be informed of the outcome of the investigation.
 Anyone engaging in sexual or other unlawful harassment will be subject to disciplinary action, up to and including termination of employment.The Company is committed to ensuring due process for all staff in resolving all matters of misconduct.

7.3	Types of Disciplinary Action
Although employment with the company is based on mutual consent and both the employee and the company have the right to terminate employment at will, with or without cause or advance notice, the company may use progressive discipline at its discretion.
However, devotion to duty by all employees will be total while in employment. Every employee will obey and observe the instructions of the Company. There will be no conflict of interests.
Improper conduct by employees will be sanctioned. The Company reserves the right to invoke the following disciplinary measures in cases of staff misconduct:

	Verbal query/caution 
	Written query/caution
	Surcharge in lieu of losses
	Suspension with or without pay
	Withholding of Annual Increment
	Dismissal/Termination of Employment

Disciplinary action may call for any of the above mentioned measures depending on the severity of the problem and the number of occurrences. There may be circumstances when one or more steps are bypassed.
Progressive discipline means that, with respect to most disciplinary problems, these steps will normally be followed: a first offense may call for a verbal warning; a next offense may be followed by a written warning; another offense may lead to a suspension; and, still another offense may then lead to termination of employment.
The Company recognizes that there are certain types of employee problems that are serious enough to justify either a suspension, or, in extreme situations, termination of employment, without going through the usual progressive discipline steps. By using progressive discipline, we hope that most employee problems can be corrected at an early stage, benefiting both the employee and the company.

7.4  Problem Resolution & Grievance Practices
Ensure utmost transparency in the resolution of grievance issues, while maintaining a total respect for the individual. The Company will support processes and practices to promote timely and amicable settlement of employ grievances within their respective functional units.

Grievance Practices
The Company is committed to providing the best possible working conditions for its employees. Part of this commitment is encouraging an open and frank atmosphere in which any problem, complaint, suggestion, or question receives a timely response from the management. The company strives to ensure fair and honest treatment of all employees. Supervisors, managers, and employees are expected to treat each other with mutual respect. Employees are encouraged to offer positive and constructive criticism. If employees disagree with established rules of conduct, policies, or practices, they can express their concern through the problem resolution procedure. No employee will be penalized, formally or informally, for voicing a complaint in a reasonable manner, or for using the problem resolution procedure.


Chapter 8: USE OF COMPANY PROPERTY AND GENERAL PROVISIONS

8.1	Use of Company Property
a)	Use of Phone 
To ensure effective telephone communications, employees should always use an approved greeting and speak in a courteous and professional manner. Please confirm information received from the caller, and hang up only after the caller has done so.

b)	Use of Equipment
Equipment essential in accomplishing job duties is often expensive and may be difficult to replace. When using property, employees are expected to exercise care, perform required maintenance, and follow all operating instructions, safety standards, and guidelines. Please notify the supervisor if any equipment, machines, or tools appear to be damaged, defective, or in need of repair. Prompt reporting of damages, defects, and the need for repairs could prevent deterioration of equipment and possible injury to employees or others. The supervisor can answer any questions about an employee's responsibility for maintenance and care of equipment used on the job.
The improper, careless, negligent, destructive, or unsafe use or operation of equipment can result in disciplinary action, up to and including termination of employment.

c)	Computer and E-mail Usage
Computers, computer files, the e-mail system, and software furnished to employees are company property intended for business use. Employees should not use a password, access a file, or retrieve any stored communication without authorization. To ensure compliance with this policy, computer and e-mail usage may be monitored. 

d)	Internet Usage
Internet access to global electronic information resources on the World Wide Web is provided by the company to assist employees in obtaining work-related data and technology. The following guidelines have been established to help ensure responsible and productive Internet usage. 
Employees should always ensure that the business information contained in Internet e-mail messages and other transmissions is accurate, appropriate, ethical, and lawful. The equipment, services, and technology provided to access the Internet remain at all times the property of the company. As such, the employer reserves the right to monitor Internet traffic, and retrieve and read any data composed, sent, or received through our online connections and stored in our computer systems. Data that is composed, transmitted, accessed, or received via the Internet must not contain content that could be considered discriminatory, offensive, obscene, threatening, harassing, intimidating, or disruptive to any employee or other person. Examples of unacceptable content may include, but are not limited to, sexual comments or images, racial slurs, gender-specific comments, or any other comments or images that could reasonably offend someone on the basis of race, age, sex, religious or political beliefs, national origin, disability, sexual orientation, or any other characteristic protected by law.

The unauthorized use, installation, copying, or distribution of copyrighted, trademarked, or patented material on the Internet is expressly prohibited. As a rule, if an employee did not create the material, does not own the rights to it, or has not gotten authorization for its use, it should not be put on the Internet. Employees are also responsible for ensuring that the person sending any material over the Internet has the appropriate distribution rights. Internet users should take the necessary anti-virus precautions before downloading or copying any file from the Internet. All downloaded files are to be checked for viruses; all compressed files are to be checked before and after decompression.

Abuse of the Internet access provided by the company in violation of law or company policies will result in disciplinary action, up to and including termination of employment. Employees may also be held personally liable for any violations of this policy. The following behaviors are examples of previously stated or additional actions and activities that are prohibited and can result in disciplinary action: Sending or posting discriminatory, harassing, or threatening messages or image Using the organization's time and resources for personal gai Stealing, using, or disclosing someone else's code or password without authorizatio Copying, pirating, or downloading software and electronic files without permissio Sending or posting confidential material, trade secrets, or proprietary information outside of the organizatio Violating copyright la Failing to observe licensing agreement Engaging in unauthorized transactions that may incur a cost to the organization or initiate unwanted Internet services and transmission Sending or posting messages or material that could damage the organization's image or reputatio Participating in the viewing or exchange of pornography or obscene material Sending or posting messages that defame or slander other individual Attempting to break into the computer system of another organization or perso Refusing to cooperate with a security investigatio Sending or posting chain letters, solicitations, or advertisements not related to business purposes or activitie Using the Internet for political causes or activities, religious activities, or any sort of gamblin Jeopardizing the security of the organization's electronic communications system Sending or posting messages that disparage another organization's products or service Passing off personal views as representing those of the organizatio Sending anonymous e-mail message Engaging in any other illegal activities

e)	Return of Property
Employees are responsible for all the property, materials, or written information issued to them or in their possession or control. Employees must return all company property immediately upon request or upon termination of employment. Where permitted by applicable laws, the company may withhold from the employee's check or final paycheck the cost of any items that are not returned when required. The company may also take all action deemed appropriate to recover or protect its property.

8.2	Emergency Closing
At times, emergencies such as severe weather, fires or power failures, can disrupt company operations. In extreme cases, these circumstances may require the closing of a work facility. When operations are officially closed due to emergency conditions, the time off from scheduled work will be unpaid. However, with supervisory approval, employees may use available paid leave time, such as unused vacation benefits.
In cases where an emergency closing is not authorized, employees who fail to report for work will not be paid for the time off. Employees in essential operations may be asked to work on a day when operations are officially closed.
In these circumstances, employees who work will receive regular pay.

8.3	Business Travel Expenses
The company will reimburse employees for reasonable business travel expenses incurred while on assignments away from the normal work location. The immediate supervisor must approve all business travel in advance. Employees whose travel plans have been approved should make all travel arrangements through company accounts department. When approved, the actual costs of travel, meals, lodging, will be paid and the company will reimburse other expenses directly related to accomplishing business travel objectives. Employees are expected to limit expenses to reasonable amounts. Expenses that generally will be reimbursed include the following: Airfare economy class or the lowest available fare. Car rental fees, only for compact or mid-sized cars. Fares for shuttle or airport bus service, where available; costs of public transportation for other ground travel. Taxi fares, only when there is no less expensive alternative.. Cost of standard accommodations in low to mid-priced hotels, motels, or similar lodgings. Cost of meals, no more lavish than would be eaten at the employee's own expense. A fixed amount is stipulated by accounts for phone calls daily
(Personal entertainment and personal care items are not reimbursed.)

Employees are encouraged to use their cellular telephone or calling cards when traveling, as hotel charges are excessive.
Employees who are involved in an accident while traveling on business must promptly report the incident to their immediate supervisor. Vehicles owned, leased, or rented by the company may not be used for personal use without prior approval.
When travel is completed, employees should submit completed travel expense reports. Receipts for all individual expenses should accompany reports.
Employees should contact their supervisor for guidance and assistance on procedures related to travel arrangements, travel advances, expense reports, reimbursement for specific expenses, or any other business travel issues.
Abuse of this business travel expenses policy, including falsifying expense reports to reflect costs not incurred by the employee, can be grounds for disciplinary action, up to and including termination of employment.

8.4	Visitors in the Work Place
To provide for the safety and security of employees and the facilities, only authorized visitors are allowed in the workplace.
Restricting unauthorized visitors helps maintain safety standards, protects against theft, ensures security of equipment, protects confidential information, safeguards employee welfare, and avoids potential distractions and disturbances.



8.5	Workplace Monitoring
Workplace monitoring may be conducted by the company to ensure quality control, employee safety, security, and customer satisfaction.
Employees who regularly communicate with customers may have their telephone conversations monitored or recorded. Telephone monitoring is used to identify and correct performance problems through targeted training.
Computers furnished to employees are the property of the company. As such, computer usage and files may be monitored or accessed. Employees can request access to information gathered through workplace monitoring that may impact employment decisions. Access will be granted unless there is a legitimate business reason to protect confidentiality or an ongoing investigation.
Because the company is sensitive to the legitimate privacy rights of employees, every effort will be made to guarantee that workplace monitoring is done in an ethical and respectful manner.

8.6	Absenteeism
A. Unauthorized absence on work days
Employees are required to obtain advance approval for leave from their supervisor. Violations of established policy and procedure concerning leave approval (except in emergency situations which are reviewed on a case-by-case basis) are considered unauthorized absence.

B. Abandonment of Job
Employees are expected to be at their designated work station during regular work hours (except for lunch breaks as defined by supervisor).

8.7	Attendance & Punctuality
To maintain a safe and productive work environment, employees are expected to be reliable and to be punctual in reporting for scheduled work. Absenteeism and tardiness place a burden on other employees and company. In the rare instances when employees cannot avoid being late to work or are unable to work as scheduled, they should notify their supervisor as soon as possible in advance of the anticipated tardiness or absence. Poor attendance and excessive tardiness are disruptive. Either may lead to disciplinary action, up to and including termination of employment.




Chapter 9: SEPARATION
Objective: The Company�s human resources policy is geared towards retaining and motivating employees that are committed, disciplined and loyal, to have a fulfilling and rewarding career in the company.
However, it is recognised that separation is inevitable in certain circumstances as provided in the policy. This can be initiated by the company or the employee or by natural circumstances beyond human control.
Below are examples of some of the most common circumstances under which employment is terminated:

 Resignation - voluntary employment termination initiated by an employee.
 Discharge - involuntary employment termination initiated by the organization.
 Layoff/Redundancy - involuntary employment termination initiated by the organization for non-disciplinary reasons.
 Retirement - voluntary employment termination initiated by the employee meeting age, length of service, and any other criteria for retirement from the organization.

9.1	Termination
Either of the parties to the employment contract is entitled to terminate this relationship at any time with notice or by payment of salary in lieu of such notice as applicable to the individual employee. An officer whose employment is terminated under this section shall be entitled to his entire terminal benefits as provided in his employment letter or Contract. 

9.2	Voluntary Resignation        
In the event of resignation, the immediate supervisor of the resigning employee will discuss with the employee, before noting acceptance by appending initials on the letter of resignation, and then submitting to the relevant member of management for review and comments.
The staff member is entitled to his/her salary and all allowances due as of the time of resignation. 
	Any outstanding leave and therefore pay in lieu of such leave.
	Other Entitlements as may be approved from time to time.




9.3	Redundancy
Redundancy means involuntary and permanent loss of employment as a result of excess manpower, lack of work, elimination of a job function or as a result of company restructuring or technological changes that is not due to the fault of the staff.
The Company does not anticipate redundancy, but should it be necessary, it will determine those to be declared redundant by applying the principle offirst in, last ou to those who, in the opinion of the Company are of comparable skills and efficiency. 
Entitlements:
In event of redundancy however, the staff is entitled to:
o	One monts notice or salary in lieu of notice.
o	Salary and all allowances due as of the date of redundancy.

9.4	Accidents while on Official Duty
In the event that an accident occurs while an employee is on Official duty resulting in bodily injury and damage, the Company shall make reasonable payments to the employee or on behalf of the employee to cover the whole or part of the medical expenses. The limit of payments in respect of medical expenses over are at the sole discretion of the Company.

9.5	Death
If an employee dies while in the service of the Company, the Company will pay reasonable burial expense to assist the deceaseds family. Entitlements are as outlined under Voluntary Resignation above.
IF YOU HAVE ANY COMMENTS OR SUGGESTIONS REGARDING THE CONTENT OF THE EMPLOYEE HANDBOOK, PLEASE DIRECT THEM TO THE MANAGEMENT
WISHING YOU A LONG AND REWARDING CAREER!	





APPENDIX A
ORGANISATIONAL STRUCTURE





References
1a. What is the name of the organization?
1b. what are the visions of the organization?
1c. what is the mission of the organization?
1d. What are the values of the organization?





DESCRIPTION
An employment termination letter is a letter which notifies the employee of your intention to terminate the employment, and outline other details about the termination. While a termination letter may be provided to an employee for reasons unrelated to his or her job performance (e.g. if business conditions necessitate layoffs or downsizing), it may also be given to an employee for poor job performance or misconduct.
The letter contains the date on which the termination is effective; sometimes the reason for the termination is also included in the letter. 

FREQUENTLY ASKED QUESTIONS (FAQs) 
Must I give a reason for the termination? 
An employer is not obligated to provide any reason for terminating the contract provided that the provisions of the employment contract in relation to its termination are complied with. 
Is Termination of employment different from dismissal?
Termination is different from dismissal. An employment contract may be terminated by the employer or the employee following the procedure laid down in the employment contract. 
A dismissal is done by the employer, where the employee has committed a serious misconduct. Examples of serious misconduct include: stealing, fraud, bribery, corruption, falsification of records, gross insubordination, dereliction of duty, verbal or physical violence, fighting, assault and battery, working under the influence of illegal drugs, conflict of interest, competition with the employers business, conversion of companys property for private use without the employers permission or consent etc. 

What does it mean to terminate in lieu of notice? 
Termination in lieu of notice refers to a situation in which the Employer does not observe the notice period specified in the employment contract. Employment contracts are either terminated with notice or with pay in lieu of notice. Hence, where an employer does not wish to follow the requisite notice period, the employment may still be terminated by paying salary in lieu of notice. 
DRAFT 
______1_______
________2a________

Dear ________2b________,

TERMINATION OF EMPLOYMENT
In reference to your Letter of Offer for Employment dated _____3_____,  ____4_____ (hereinafter referred to as the Company) hereby writes to inform you that your employment is terminated effective on ____5____.



Kindly submit all documents and property of the Company in your possession to the appropriate department. Also note that all trade secrets, business plans and procedures, client contact list and the Companys other confidential information are proprietary and as such, you are not permitted to use them in any way.

You can contact the undersigned in the event that you have any questions or concerns. 

Thank you.

For: the Company


________________
Name:
Designation: 






REFERENCES 
(1)	On what date is the letter written?
2a.What is the employee�s address?
2b.What is the name of the employee?
3.	On what date was the employee offered employment?
4.	What is the name of the Employer?
5.	On what date will the termination become effective? (N.B: Since the termination is given with notice, ensure the date stated compliance with the notice provision in the employment contract) 





DESCRIPTION
An employment termination letter is a letter which notifies the employee of your intention to terminate the employment, and outline other details about the termination. While a termination letter may be provided to an employee for reasons unrelated to his or her job performance (e.g. if business conditions necessitate layoffs or downsizing), it may also be given to an employee for poor job performance or misconduct.

The letter contains the date on which the termination is effective, sometimes the reason for the termination is included in the letter. 

FREQUENTLY ASKED QUESTIONS (FAs) 
Must I give a reason for the termination? 
An employer is not obligated to provide any reason for terminating the contract provided that the provisions of the employment contract in relation to its termination are complied with. 
Is Termination of employment different from dismissal?
Termination is different from dismissal. An employment contract may be terminated by the employer or the employee following the procedure laid down in the employment contract. 
A dismissal is done by the employer, where the employee committed has committed a serious misconduct. Examples of serious misconduct include: stealing, fraud, bribery, corruption, falsification of records, gross insubordination, dereliction of duty, verbal or physical violence, fighting, assault and battery, working under the influence of illegal drugs, conflict of interest, competition with the employes business, conversion of compans property for private use without the employes permission or consent etc. 

What does it mean to terminate in lieu of notice? 
Please provide respons



DRAFT 
______1_______
________2a________

Dear ________2b________,

TERMINATION OF EMPLOYMENT
In reference to your Letter of Offer for Employment dated _____3_____ , ____4_____ (hereinafter referred to as the Company) hereby writes to inform you that your employment is terminated.

In lieu of notice of termination of your employment, the Company has decided to pay you ____5____ compensation for the termination of your employment contract.

We appreciate the cordial working relationship that existed between us since your assumption of office.

Kindly submit all official documents and property of the Company in your possession to the appropriate department.

Thank you.

For: the Company


________________
Name:
Designation: 



REFERENCES 
(1)	On what date is the letter written?
2a.	What is the employees address?
2b. What is the name of the employee?
3.	On what date was the employee offered employment?
4.	What is the name of the Employer?
5.	How much will the employer pay for termination of the contract? NB: Please print this letter on the Compans letterhead



Description 
An Equipment Rental Agreement is an agreement where the owner of the equipment (Lessor), permits the person renting the equipment (Lessee) to use the equipment in exchange for periodic or one off payments. The equipment for rent can be machinery, vehicles or any other equipment. Although actual ownership of the equipment remains with the Lessor, the Lessee is conferred with right to possession and use of equipment for a certain period agreed by the parties. 

FREQUENTLY ASKED QUESTIONS 
When do I need an Equipment Rental Agreement?
An Equipment Rental Agreement will be required anytime you want to rent equipment you own to someone else, with certain conditions in place to limit your liability and preserve the value of your equipment.

Is it more beneficial to rent or buy an Equipment?
Equipment rental provides the opportunity to conserve working capital and immediate access to up-to-date business tools. On the other hand, long-term leasing may be more expensive than buying the equipment outright. There are many factors that contribute to a decision about whether leasing or buying is right for a given company, including the nature of its industry and the types of equipment the company requires.

As the entity renting the property (the Lessee), can I assign the rental agreement to someone else?
Usually the Lessee cannot further assign the rental agreement unless the Agreement clearly states that the Lessor can authorise the Lessee to assign the rental agreement.

EQUIPMENT RENTAL AGREEMENT

THIS EQUIPMENT RENTAL AGREEMENT (the Agreement) is made this __day of _______________20_____
BETWEEN 
_______1a_________ a company incorporated and existing under the laws of ________1b___________ with RC Number ____1c_____ having its office address at ____1d____  (hereinafter referred to as the Lessor, which expression shall include officers, representatives, subsidiaries and affiliates) and 
____2a_____ a company incorporated and existing under the laws of ____2b_____ with RC Number _____2c_____ having its office address at ______2d_______ (hereinafter referred to as the Lessee, which expression shall include its officers, representatives, subsidiaries and affiliates)

The Lessor and the Lessee are referred to individually as Party and collectively as Parties.

WHEREAS 
i.	The Lessor is the rightful owner of the Equipment described in Clause 1 below.
ii.	The Lessee is desirous of renting the Equipment and the Lessor has agreed to rent the Equipment to the Lessee in accordance with the terms and conditions provided below. 
 
NOW, THEREFORE, IT IS HEREBY AGREED AS FOLLOWS:

1.	EQUIPMENT 
Lessor hereby leases to Lessee the ______3_______ (the Equipment)

2.	TERM
The term of this Agreement is for _________4 commencing on the _________5.

3.	PAYMENT TERMS  
3.1.	Lessee agrees to pay to Lessor as rent for the Equipment the amount of _________6 (Rent) each _________7 in advance on the _________8 day of each _________7. If the Lease Term does not start on the first day of the month or end on the last day of a month, the rent will be prorated accordingly.
3.2.	Lessee agrees to pay to Lessor as rent for the Equipment the amount of _________9 for the first _________7 of the Lease and _________10 each subsequent month in advance on the first day of each month. 

4.	LATE CHARGES AND DEFAULTS 
4.1.	If any amount under this Agreement is more than seven (7) days late, the Lessee agrees to pay a default fee of _________12 for each _________7 payment is delayed in addition to the overdue balance. Any overdue balance not paid within 30 days of due date shall be considered in default and a fundamental breach of this Agreement. 
4.2.	If Lessee fails to perform or fulfill any obligation under this Agreement, Lessee shall be in default of this Agreement. Subject to any statute, ordinance or law to the contrary, Lessee shall have seven (7) days from the date of notice of default by Lessor to cure the default. In the event Lessee does not cure a default, Lessor may at Lessors option cure such default and the cost of such action may be added to Lessees financial obligations under this Agreement. If Lessee shall become insolvent, cease to do business as a going concern or if a petition has been filed by or against Lessee, Lessor may immediately declare Lessee in default of this Agreement. In the event of default, Lessor may, as permitted by law, re-take possession of the Equipment. Lessor may, at its option, hold Lessee liable for any difference between the Rent that would have been payable under this Agreement during the balance of the unexpired term and any rent paid by any successive Lessee if the Equipment is re-let minus the cost and expenses of such re-letting. In the event Lessor is unable to re-let the Equipment during any remaining term of this Agreement, after default by Lessee, Lessor may at its option hold Lessee liable for the balance of the unpaid rent under this Agreement if this Agreement had continued in force.

5.	USE OF EQUIPMENT 
5.1.	The Lessee will use the Equipment in a good and careful manner, in accordance with the highest standard of care and will comply with all of the manufacturers requirements and recommendations in respect of the Equipment and with any applicable law that applies to the use of the Equipment. 
5.2.	If the Equipment is not in good repair, appearance and condition when it is returned to the Lessor, the Lessor may make such repairs or may cause such repairs to be made as are necessary to put the Equipment in a state of good repair, appearance and condition, normal and reasonable wear and tear excepted. The Lessee shall pay the Lessor full compensation for replacement and/or repair of any equipment which is not returned because it is lost or stolen or any equipment which is damaged and in need of repair to put it into the same condition it was in at the time of rental, normal wear and tear excepted. The Lessors invoice for replacement or repair is conclusive as to the amount Lessee shall pay under this paragraph for repair or replacement.
5.3.	The Lessee will use the Equipment for the purpose for which it was designed and not for any other purpose. 
5.4.	Unless the Lessee obtains the prior written consent of the Lessor, the Lessee will not alter, modify or attach anything to the Equipment.

6.	RENTAL TERMS AND CONDITIONS
6.1.	The Lessee shall allow Lessor to enter Lessees premises where the Equipment is stored or used at all reasonable times to locate and inspect the state and condition of the Equipment. If the Lessee is in default of any of the terms and conditions of this Agreement, the Lessor may at any time enter the Lessees premises where the Equipment is stored or used and recover the Equipment.

6.2.	The Lessee shall not pledge or encumber the rented equipment in any way. The Lessor may terminate this Agreement immediately upon the failure of Lessee to make rental payments when due, or upon Lesses filling for protection from creditors in any court of competent jurisdiction.

6.3.	The Lessee indemnifies and holds the Lessor harmless for all injuries or damage of any kind for repossession and for all consequential and special damages for any claimed breach of warranty. Except for damages, claims or losses due to Lessors acts or negligence, Lessee will indemnify and hold Lessor free and harmless from any liability for losses, claims, injury to or death of any person, including Lessee, or for damage to property arising from Lessee using and possessing the Equipment or from the acts or omissions of any person or persons, including Lessee, using or possessing the Equipment with Lessees express or implied consent.

6.4	Lessee shall keep the Equipment free and clear of any liens or other encumbrances, and shall not permit any act where Lessors title or rights may be negatively affected. Lessee shall be responsible for complying with and conforming to all laws and regulations relating to the possession, use or maintenance of the Equipment.

7.	POSSESSION AND SURRENDER OF EQUIPMENT
Lessee shall be entitled to possession of the Equipment during the Term in accordance with this Agreement. At the expiration of the Term, Lessee shall surrender the Equipment to Lessor by delivering the Equipment to the Lessor agent in good condition and working order, ordinary wear and tear excepted, as it was at the commencement of the Agreement. 

8.	USE OF EQUIPMENT
Lessee shall only use the Equipment as specified by the Lessor in a careful and proper manner and will comply with all laws, rules, ordinances, statutes and manufacturers requirement and recommendation regarding the use, maintenance and storage of the Equipment. 

9.	CONDITION OF EQUIPMENT AND REPAIR 
Lessee or Lessees agent has inspected the Equipment and acknowledges that the Equipment is in good and acceptable condition. 

10.	MAINTENANCE, DAMAGE AND LOSS
Lessee will, at Lessee's sole expense, keep and maintain the Equipment clean and in good working order and repair during the Term. In the event the Equipment is lost or damaged beyond repair, Lessee shall pay to Lessor the replacement cost of the Equipment; in addition, the obligations of this Agreement shall continue in full force and effect through the Term. 
 
11.	LESSORS REPRESENTATIONS 
Lessor represents and warrants that it has the right to lease the Equipment as provided in this Agreement and that Lessee shall be entitled to quietly hold and possess the Equipment, and Lessor will not interfere with that right as long as the Lessee pays the Rent and performs all other obligations under this Agreement in a timely manner. 

12.	OWNERSHIP
The Equipment is and shall remain the exclusive property of Lessor. 

13.	SEVERABILITY 
If any part or parts of this Agreement shall be held unenforceable for any reason, the remainder of this Agreement shall continue in full force and effect. If any provision of this Agreement is deemed invalid or unenforceable by any court of competent jurisdiction, and if limiting such provision would make the provision valid, then such provision shall be deemed to be construed as so limited. 

14.	ASSIGNMENT
Neither this Agreement nor Lessees rights hereunder are assignable except with Lessors prior, written consent. 

15.	BINDING EFFECT 
The covenants and conditions contained in the Agreement shall apply to and bind the Parties and the heirs, legal representatives, successors and permitted assigns of the Parties. 

16.	GOVERNING LAW 
This Agreement shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria.  

17.	DISPUTE RESOLUTION  
17.1.	Parties shall use their best efforts to settle any disputes arising between them amicably within 30 days. 
17.2.	However, where the Parties are unable to reach an amicable resolution, such dispute, controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration conducted by a sole arbitrator in accordance with the Arbitration and Conciliation Act Cap A18 Laws of the Federal Republic of Nigeria 2004.

IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS AGREEMENT IN THE MANNER BELOW, ON THE DATE INDICATED ABOVE

The Common Seal of the LESSOR was hereunto affixed in the presence of 


_____________							____________
NAME:								NAME:
DESIGNATION:							DESIGNATION:

The Common Seal of the LESSEE was hereunto affixed in the presence of:


______________							________________
NAME:								NAME:
DESIGNATION:							DESIGNATION:




DESCRIPTION OF DOCUMENT
An Eviction Notice is a document issued by the landlord to tenant evidencing an intention to recover possession of the property. It is usually used in a situation where the tenancy has been terminated or there has been a fundamental breach of the lease agreement. It is issued after a due Notice to Quit has been served on the tenant and the period for which it is to run has expired, the Landlord can now issue an eviction notice to evict the tenant from the property or resort to court depending on the jurisdiction to exercise same.

FREQUENTLY ASKED QUESTIONS (FAQs)
When can I use an eviction notice?
You can issue an eviction notice in the following instances; 
o	When a tenant is in arrears of rent
o	When there has been a violation of a term of the tenancy
o	When the term of the tenancy has expired and tenant is still in possession
o	When tenant has caused damage to the property
o	When tenant uses premises for immoral or illegal acts
o	When tenant abandons premises or fails to maintain the premises
Is there any special requirement of law with respect to service of an eviction notice?
Essentially, before serving an eviction notice, you must have served a notice to quit on the tenant. It is only when the time duration prescribed in the notice to quit is expired that an eviction notice can be served. To serve a notice to quit, you must take into consideration the type of tenancy that was created to avoid breaching the law.
NB: You can check the FAQs for notice to quit and also contact one of our lawyers  for further clarification on this.
How do I serve an eviction notice?
When the notice to quit has run its course, and the tenant is still in default. You can contact the police or commence an action in court to that effect (depending on the jurisdiction). The law generally requires you to commence an action in court to recover possession upon the expiration of your eviction notice.

Must I issue an Eviction Notice?
You are required to where the tenant refuses to voluntarily vacate the property. It is immaterial whether the tenancy is for a fixed term or not. The law expects a notice to recover the property to be served on the tenant before you can access your remedy.







EVICTION NOTICE
TO: ___(1a)___  (Tenant in Possession) and all other persons
ADDRESS: ____1b____ 
DATE: 

NOTICE IS HEREBY GIVEN THAT pursuant to the tenancy agreement dated ___2a___ with respect to the property situate at ___2b___which you hold as a ___2c____ tenant. 
You have been in default of the terms of the tenancy by ___3b___ and accordingly, you are notified that except you fulfill your obligations under the tenancy agreement, you will be required to vacate the premises.
TAKE NOTICE that if at the end of ___4a____ from the issuance of this notice you are still in default of your obligations to the landlord or agents under the lease agreement, recourse will be had to all necessary steps to evict you and any other persons found on the property.  
								                                                                  


___________________________
Name & Signature 



References
1a. What is the Name of the Tenant you wish to evict? 
1b. What is the Address of the Tenant?
2a. What is the date of the lease agreement made with respect to this tenancy?
2b. What is the address of the property over which the lease was created
2c. What is the duration of the tenancy created in the lease? (Monthly, yearly etc.)

3b. What is the default by the Tenant? e.g. default in payment of rent, breach of tenancy agreement (state specific term breached), expiration of tenancy etc.  
4a. What is the notice period for this eviction? (Note that the notice should be a minimum of 7 days)





DESCRIPTION OF THE DOCUMENT

An Executive Director�s Contract is an Agreement between an Employer and its top level executives. This Agreement is used for only high level executives and states their compensation, appointment procedure, term of years, termination, confidentiality obligations etc. 


FREQUENTLY ASKED QUESTIONS (FAQs)

Who is an Executive Director?
An Executive Director is a director appointed by the board to take charge of the daily activities of the company and often are in charge of a specific area or department like finance, business development, sales etc..


What is the difference between an Executive Director and a Non-Executive Director?
Although an executive and non-executive director have the same fiduciary obligations to the company, the difference between an executive and a non-executive director lies in the role that they perform. Non-executive directors do not engage in the day-to-day running of the business, but rather assist the executive directors in their strategic decision-making process.

Why do I need an Executive Directors Contract?
An Executive Directors Contract, ensures there exists a reference document that clearly captures the terms of employment and job description for the company's top level officers. Having such a written agreement in place helps the company protect its business, and makes sure the company and its management staff are on the same page. 

EXECUTIVE DIRECTOR CONTRACT

This Employment Agreement (Agreement) is entered into this ____ day of _______________20 ____

 BETWEEN 
 
____1a____ (hereinafter referred  to as the Employer) a company duly incorporated and registered under the existing laws of the Federal Republic of Nigeria with RC Number ______1b_____ and its primary place of business at ____1c_____ 
AND 
______2a______ of _______2b_______ (hereinafter referred to as the Employee).

The Employer and the Employee are referred to individually as Party and collectively as Parties

WHEREAS
A.	The Employer wishes to hire an Executive Director to administer and manage its affairs.
B.	In view of the above, the Employer desires to obtain and retain the services of the Employee and the Employee is willing to render such services.
C.	The Parties are now entering into this Agreement to establish the terms of their employment arrangement.


NOW, THE PARTIES AGREE as follows: 

1.	 APPOINTMENT

The Employer hereby appoints ____3a_____as Executive Director effective from ______4a (Effective Date)


2.	TERM OF EMPLOYMENT 

The Employment shall be deemed to have commenced on the Effective Date and shall coinue for a term of _____4b 

3.	   EMPLOYEE WARRANTIES 

3.1. The Employee represents and warrants to the Employer that, by entering into this Agreement or performing any of the obligations under it, there will not be a breach of any court order or any express or implied terms of any contract or other obligation binding on the employee.
 
3.2 The Employee warrants that the resume, references and other information provided  which qualified the employee for the position of a director are true and correct and will immediately notify the Employer  if it ceases to be so.

3.3 The Employee warrants that there are no restrictions which prevents the employee from holding office as a director.


4.	   DUTIES OF THE EMPLOYEE

 

The duties to be performed by the Employee under this Agreement are set forth in Appendix A, which is made a part of this Agreement. The duties outlined in Appendix A provide a general description of the functions of the Employee as Executive Director and may be modified as necessary to meet the requirements of the position.

5.	CONFIDENTIALITY

5.1	Employee acknowledges and agrees that Confidential Information, whether or not in written form, is the exclusive property of Employer, that it has been and will continue to be of critical importance to the business of Employer, and that the disclosure of it to, or use of it by, competitors and others will cause Employer substantial and irreparable harm. Accordingly, the employee will not, either during the term of employment or at any time after the termination (whether voluntary or involuntary, and regardless of reason) of such employment with Employer, use, or disclose any Confidential Information relating to the business of Employer which is not generally available to the public. 

  5.2 Notwithstanding the foregoing provisions of this clause, Employee may disclose or use any such information:
                  (i) when such disclosure or use may be required or appropriate in the good faith judgment of Employee in the course of performing his/her duties to Employer and in accordance with Employer policies and procedures, or

 (ii) when required by a court of law, by any governmental agency having supervisory authority over Employee or the business of Employer, or by any administrative or legislative body (including a committee thereof) with apparent jurisdiction.


5.3 The Employee understands and agrees that the  obligations under this Agreement shall be in addition to, rather than in lieu of, any obligations Employee may have under any applicable statute or at common law. In the event of a breach or threatened breach by the Employee of the provisions of this clause, the Employer shall be entitled to an injunction restraining the Employee from disclosing, in whole or in part, such confidential information.  


6	   COMPENSATION 

        FEES AND EXPENSES

6.1	The Employee shall be entitled to a salary of ___5a____ per year, payable in ___5b__installments of ____5c____ on or before ___5d____. 

6.2	The Employer shall reimburse the Employee for reasonable and necessary expenses, incurred in the performance of the duties and responsibilities attached to the position. Expenses will be reimbursed within a month upon submission of documentation and receipts.

6.3	The Employer will provide health and life insurance benefits and a pension plan at a rate to be discussed by the Parties.


6.4	The Employee understands and agrees that any further consideration and/or additional remuneration paid to the Employee in the form of bonuses, benefits and other similar incentive remuneration will rest in the sole discretion of the Employer.



7	INTELLECTUAL PROPERTY RIGHTS
All work products or work in progress created by the Employee under this contract during the term of this Agreement shall constitute work for hire and shall be the property of the Employer. The Employee agrees to give all necessary assistance to the Employer to enable it to enforce its Intellectual Property Rights against third parties, to defend claims for infringement of third party Intellectual Property Rights and to apply for registration of Intellectual Property Rights, where appropriate throughout the world, and for the full term of those rights.

8	PLACE AND HOURS OF WORK

8.1 The normal place of work of the Employee is ____8a ___or such other place which the Employer may reasonably require for the proper performance and exercise of the duties of the employee. 

8.2. The Employee agrees to travel on any business of the Employer as may be required in the course of carrying out the duties under this Agreement.

8.3 The regular work hours of the Employee shall be ____9a__ or such hours as are necessary  for the proper performance of his/her duties, and the Employee acknowledges that no further remuneration shall be made in respect of such additional hours

9	TERMINATION 
       Either party to this agreement may terminate same by giving the other not less than ___10a  prior notice in writing.

9.1 In the event that the Agreement is terminated by the employee, there shall be no termination compensation other than accrued compensation earned prior to the effective date of termination less any payments due and owing to the Employer. 

Termination without Notice

9.2 In the event that the Agreement is terminated by the Employer for cause defined as willful, negligent, or repeated failure to perform the essential requirements of the position or for unethical, illegal, or fraudulent conduct, there shall be no notice required and no severance pay or termination compensation made to the Employer.
9.3  If the decision is made by the Employer to terminate the Agreement for reasons other than those specified above, severance arrangements (less any amounts due and owing to the Employer) shall apply in accordance to Employes policies.


10	GOVERNING LAW 
The implementation and enforcement of this Agreement shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement.

11	DISPUTE RESOLUTION

11.1	Any controversy or claim arising out of or relating to this Agreement, or the breach hereof, the Parties shall expend their best efforts to settle any disputes arising between them amicably within 30 days.
11.2	In the event that the Parties are unable to reach an amicable resolution, such dispute, and controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration; by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap 19 Laws of the Federal Republic of Nigeria 2004.

12	SEVERABILITY
In the event that any clause or provision in this Agreement is held invalid or unenforceable by a court of competent jurisdiction in Nigeria, such invalidity shall not affect the validity or operation of any other clause or provision; and such invalid clause or provision shall be deemed to be severed from this Agreement.


13	WAIVER 
None of the requirements, rights or provisions of this Agreement shall be considered waived unless waived in writing by the Party concerned. The waiver by any Party of one or more of its rights hereunder shall not be deemed or construed as a continuing waiver on the part of such Party as to prohibit such Party from demanding the performance of any of the other duties and obligations owed it in accordance with the provisions of this Agreement.


14.	ENTIRE AGREEMENT AND COUNTERPARTS 

14.1 This agreement and any document referred to in it constitutes the entire agreement between the parties and supersedes and extinguishes all previous discussions, correspondence, negotiations, drafts, agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.

14.2 This deed may be executed in any number of counterparts, each of which, when executed and delivered, shall be an original, and all the counterparts together shall constitute one and the same deed.

15. MISCELLANEOUS 

15.1.	This Agreement and its provisions shall be subject to the Employers Articles of Association as may be amended from time to time. 
15.2.	In the event of any conflict between this Agreement and the Employers Articles of Association, such provision(s) which conflicts with the Employers Articles of Association shall be invalid and unenforceable to the extent to which same conflicts with the Articles of Association. 

IN WITNESS OF WHICH the parties have executed this agreement in the manner below, on the date indicated above
THE COMMON SEAL of the within named Company is hereby affixed and the deed duly delivered in the presence of:

_________________						   _______________________
Signature						              Signature
Name:	____________________				 Name: ______________________
Title:	____________________		                         Title: ________________________

SIGNED by the within named Employee:

_____________________________                                   _______________________________
Signature							Name of Employee


IN THE PRESENCE OF:
Name:
Address:
Occupation:
Signature:

References:
1a. What is the Employers name?
1b. What is the Employers RC Number?
1c. What is the Employers office address?
2a. What is the Employee/Executive Directors name?
2b.What is the employees address?
3a. Repeat reference 2a
4a. On what date will the employment as Executive Director commence?
4b. What is the duration of the Employees tenure as Executive Director?
5a. What is the annual salary of the Employee as Executive director?
5b. In how many installments is the annual salary payable?
5c. What is the monthly salary of the employee as Executive Director?
5d. The employees salary is payable on or before what date every month? (27th of every month)
8a. Where is the normal place of work of the employee? (e.g. Lagos, Nigeria)
9a. What is the regular work hour expected of the employee as Executive Director? (8am to 5pm, Monday to Friday; 37hours per week)
10a. What is the notice period for termination of employment as Executive Director by either party?
11a. Kindly provide details of specific duties of the Executive Director in numbered paragraphs.


APPENDIX A

 DETAILS OF JOB DESCRIPTION FOR MANAGING DIRECTOR 




DESCRIPTION 
A general power of attorney is a document issued by a party (Donor) to another party (Donee) which gives the Donee wide-ranging powers and authority to act on behalf of the Donor in almost everything. This document is very useful for a person who is absent from a particular place, but has business which is ongoing. In that circumstance, the Donor can give a general power of attorney to another who shall act on his/her behalf in his/her absence.  
	
FREQUENTLY ASKED QUESTIONS (FAQs)
Who is a Donor?
A Donor is the party who authorises another party to act on his behalf.

Who is a Donee?
A Donee is the party authorised to act on behalf of the Donor in the Power of Attorney.

What is the difference between a general power of attorney and a specific power of attorney?
Simply put, the difference between the two is the scope of powers. The General Power of Attorney has a more extensive range (allowing the donee make financial and business decisions on behalf of the Donor); while the specific Power of Attorney limits the circumstances in which the Donee has power to act. Although generally the power of attorney should be given to someone trustworthy and competent, the threshold is a lot higher for general power of attorney because of the level of power granted the donee. 

When will a general power of attorney be suitable?
A general power of attorney is suited to circumstances where:
a.	You intend to give your donee a free hand/ unfettered authority. 
b.	Youll be unavoidably absent from a place, and you need someone to carry on your responsibilities while you�re away
  

STANDARD POWER OF ATTORNEY 
THIS POWER OF ATTORNEY (the Power) is made this __ day of __________ 20__ (the Effective Date) by me ______1a_______ of _______1b________ (the Donor).
I hereby appoint_______2a_______ of ______2b_________ to be my true and lawful Attorney with full power and authority to do all that I may lawfully do in ____3____; including but not limited to the powers:
A.	To demand, sue for and recover, and give discharges for all moneys, securities for all sums of money, debts, stocks, shares and property now or hereafter belonging to me, whether solely or jointly with any other person or persons. 
B.	To manage and let my immovable property in such manner as the Donee shall deem fit and to make any outlay in connection with the said property and the upkeep thereof or otherwise in relation to the said property or any part thereof. 
C.	To manage my business affairs, investments, securities and movable property for the time being in such manner as the Donee shall think fit and to make any payments in connection with my business affairs, investments, securities and movable property. 
D.	To commence, carry on or defend all actions and other legal proceedings in respect of my property or affairs or any part thereof or in respect of anything in which I or my property or affairs may be in any way concerned. 
E.	To accept the transfer of any stock(s), fund(s), share(s), annuities and other securities, which shall or may at any time hereafter be transferable to me, whether solely or jointly with any other person(s). 
F.	To invest any of my money in such manner, at such rate of interest, and upon such securities as the Donee shall in his absolute discretion think fit and from time to time to vary the said investments or any of them and in the meantime, and pending any such investment as aforesaid, to deposit the said money or any part thereof with any bank, building society or other institution authorised by law to accept money on deposit to whom or to which the Donee shall think fit to entrust the same. 
G.	To receive dividend(s), interest and income arising from any stock(s), share(s) or other property now or hereafter belonging to me, whether solely or jointly with any other person(s).
H.	To carry into effect and perform all agreements entered into by me with any other person or persons. 
I.	For the purposes aforesaid or any of them, to operate on any banking account already open in my name alone or in the joint names of myself and any other person(s), and to open or operate any new banking account in such name(s), to draw, sign, endorse and negotiate cheques, bills of exchange, dividend and interest warrants and negotiable instruments
J.	To sign my name and execute on my behalf all contract(s), transfer(s), deed(s) and instrument(s) whatsoever. 
K.	To concur in doing any of the act(s) and thing(s) herein mentioned in conjunction with any other person(s) having an interest therein. 
L.	To act in relation to my property and affairs and in relation to this deed as fully and effectually in all respects as I myself could do. 
 
I undertake to ratify and confirm all acts lawfully performed by the Donee, in good faith and in strict accordance with the provisions of this Power.
This Power shall come into force from the Effective Date and remain in force until it is revoked by a _____4_____ written notice from the Donor to the Donee.

The implementation and enforcement of this Power shall be governed by the laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Power.

IN WITNESS OF WHICH THE DONOR HAS EXECUTED THIS DEED IN THE MANNER BELOW, ON THE DATE INDICATED ABOVE:
SIGNED, SEALED AND DELIVERED
By the within named Donor

___________________
NAME:
SIGN:

IN THE PRESENCE OF: 
NAME: 
ADDRESS:
OCCUPATION: 
SIGNATURE:   









REFERNCES 
(1a) What is your name?
(1b)What is your Address?
(2a) What is your Donee�s name? 
(2b) What is your Donee�s address?
(3) Where is the Donee supposed to act on your behalf? E.g. Osun state, Lagos State, Federal Republic of Nigeria  
(4) If you want to end this arrangement, what should the length of notice be? E.g. 2 days or 2 weeks or 2 months 





DESCRIPTION OF THE DOCUMENT
A Hire Purchase Agreements is an agreement where the owner of a property (�the Owner�) allows a person (�the Hirer�) to hire the property from him for a period of time by paying installments. Upon payment of the last instalment, ownership of the property transfers to the Hirer. In essence, although the Hirer has the right to use the goods, he is not the legal owner during the term of the agreement. The Hirer may also at any time before the end of the contract, exercise an option to buy the goods. 

FREQUENTLY ASKED QUESTIONS (FAQ�s)
What is a Hire Purchase?
A Hire Purchase is a method of buying property through installment payments over a period time.

Is a Hire Purchase and different from a Lease?
Yes a Hire Purchase is different from a Lease. A Hire Purchase culminates in the transfer of ownership of the property upon payment of the last instalment; unlike a Lease in which ownership of the property does not transfer to the Lessee. 

Does the hirer need a guarantor? 
Most Hire Purchase Agreements may involve two or three parties. In transactions where the owner perceives the hirer as capable of fulfilling all his obligations, the parties need not include a guarantor (two party hire purchase agreement).  However, where the owner requires an additional backing for the hirer, a guarantor will be involved in the transaction (three party hire purchase agreement). NB: This particular document does not include a guarantor. 

Who is responsible for servicing and repair?
This depends on the content of the Hire Purchase Agreement. Most contracts however, provide for the service and repair of the property to be done by the Hirer.

Who should sign the document?
The agreement is to be signed for the Owner and the Hirer. 

This HIRE PURCHASE AGREEMENT (the �Agreement�) is made this __day of _______________20__ (the �Effective Date�) between _______1a_________ a company incorporated and existing under the laws of ________1b___________ with RC Number ____1c_____ having its office address at ____1d____  ( hereinafter referred to as the �Owner�, which expression shall include officers, representatives, subsidiaries, affiliates and designates) and ____2a_____ a company incorporated and existing under the laws of ____2b_____ with RC Number _____2c_____ having its office address at ______2b_______ (hereinafter referred to as the �Hirer�, which expression shall include its officers, representatives, subsidiaries, affiliates and designates)
The Hirer and the Owner are referred to individually as Party and collectively as Parties. 
WHEREAS:
A.	The Owner is in true and lawful possession of ________3________ (the �Property�)  and;
B.	The Hirer has approached the Owner and the Owner has agreed to let the Property on hire to the Hirer, for consideration of_________4a_________ (the �Sum�) paid _______4b_______ for a term of _______4c________ (the �Term�) at the end of which the Hirer may exercise an Option to Buy the said Property free from any charge or encumbrances in favour of any third party.
C.	The Hirer has inspected the Property, is satisfied with same and finds the Property to be fit for the purpose and so contracts.  
THE PARTIES AGREE AS FOLLOWS
1.	INTERPRETATION
For the purposes of this Agreement, 
A.	�Option to Buy� shall mean the right of the Hirer to pay a lump sum balance of the full value of the Goods including any cost or payment direct and incidental to the terms of this Agreement. 
B.	�Representative� means in relation to a Party, its officers, agents, and assigns.

2.	HIRE PURCHASE CLAUSE
A.	The Owner covenants to let the Property to the Hirer and the Hirer covenants to hire same for the Term at the Sum indicated above.
B.	the Hirer covenants to pay the Sum in ____5a____ installments, the first payment of ____5b____ which is to be made on the _____5c_____ with each subsequent payment of _____6a_____ to be made on the ____6b____ into the account of the Owner at:
Bank ____7a_____
Account Name ____7b_____
Account number _____7c____
C.	The Hirer shall have an Option to Buy the Property; on the occurrence of which this Agreement shall be deemed terminated.
D.	Upon payment of the Sum to the Owner, including payment of any other sums incidental to the Hirer�s obligation under this Agreement, the hire shall come to an end and ownership of the Property shall transfer to the Hirer. The Owner shall, thereupon, assign all rights and interest in the Property to the Hirer.

3.	HIRER�S COVENANTS
The Hirer covenants:
A.	To not sell, assign, pledge, mortgage, underlet, lend or part with the possession of the Property during the Term of this Agreement.
B.	To pay all duties, fees, registration, charges, taxes, (if any) payable in respect of the Property during the Term of this Agreement.
C.	To keep and maintain the Property in good and merchantable condition.
D.	To permit the Owner and persons authorized by the Owner in writing to have access to the Property for the purpose of inspecting the condition thereof.
E.	To reimburse the Owner in the event of any damage to the Property (fair wear and tear excepted) 
F.	To pay the Owner the full value of the Property in the event of its total loss during the Term.
G.	Not use or permit or suffer the Property to be used in contravention of any law for the time being in force. 
H.	To conscientiously perform the terms and conditions of this Agreement. 

4.	OWNERS� RIGHTS AND OBLIGATION
     The Owner covenants that:
A.	Upon payment of the final instalment by the Hirer, the Owner shall assign all rights and interest in the Property to the Hirer.
B.	In the event that the Hirer exercises the Option to Buy the Property under the terms of this Agreement before the payment of the final instalment, the Owner will transfer to the Hirer the benefit of any insurance policy which may exist in respect of the Property.

5.	TERMINATION
Save for termination as contemplated under clause 2d of this Agreement:
A.	The Hirer may terminate the Agreement by returning the Property at the Hirer�s own cost and risk to the Owner PROVIDED THAT the Property is in a good and merchantable condition. All payments made for the hiring of the Goods prior to termination under this clause shall be forfeited by the Hirer. 
B.	The Owner may terminate this Agreement by giving ____8____ notice to the Hirer of intention to terminate same. Termination under this clause shall entitle the Hirer to a refund of all instalments of the Sum earlier paid unless such termination was as a result of willful misconduct/ negligent handling of the Property or breach of any of the covenants which the Hirer fails to remedy within 15 days.
C.	Upon default in payment of any instalment of the Sum payable hereunder or failure to observe or perform any of the terms and conditions of this Agreement by the Hirer, the Owner may, without prejudice to claim for arrears of hire or damages (if any) for breach of this Agreement terminate the hiring without notice and retake physical possession of the Property.
D.	In the event of the hiring being determined by the Hirer or by the Owner under this clause 5, the Hirer shall forthwith return the Property to the Owner in good state of repair and working order at the Hirer�s expense. 
E.	The determination of the hiring shall not affect or prejudice any claim the Owner may have against the Hirer or the right to enforce such claim by action in court or otherwise.



6.	SEVERABILITY
If any clause or provision in this Agreement is held invalid or unenforceable by a court of competent jurisdiction in Nigeria, such invalidity shall not affect the validity or operation of any other clause or provision and such invalid term, clause or provision shall be deemed to be severed from the Agreement.

7.	WAIVER
None of the requirements of this Agreement shall be considered waived unless waived in writing by the Party concerned or its Representatives. The waiver by any Party of one or more of its rights hereunder shall not be deemed or construed as a continuing waiver on the part of such Party as to prohibit such Party from demanding the performance of any of the other duties and obligations owed it in accordance with the provisions of this Agreement.

8.	GOVERNING LAW 
The implementation and enforcement of this Agreement shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement.

9.	ALTERATION AND MODERATION OF AGREEMENT
This Agreement constitutes the entire agreement between the Parties hereto as regarding the subject matter of this Agreement, and no terms or provisions of this Agreement shall be varied or modified by any prior or subsequent statement, conduct or act of any of the Parties save and except that the Parties may amend this Agreement by written instruments specifically referring to and executed in the same manner as this Agreement. Any such instrument shall be signed by the duly authorised Representatives of the Parties. The Agreement shall supersede any prior agreement between the Parties regarding the subject matter of this Agreement. 

10.	DISPUTE RESOLUTION  
Parties shall use their best efforts to settle any disputes arising between them amicably within 30 days. 
9.1	However, where the Parties are unable to reach an amicable resolution, such dispute, controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration conducted by a sole arbitrator in accordance with the Arbitration and Conciliation Act Cap A18 Laws of the Federal Republic of Nigeria 2004.

IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS AGREEMENT IN THE MANNER BELOW, ON THE DATE INDICATED ABOVE


The Common Seal of the Owner was hereunto affixed in the presence of 


_____________							____________
NAME:								NAME:
DESIGNATION:							DESIGNATION:

The Common Seal of the Hirer was hereunto affixed in the presence of:


______________							________________
NAME:								NAME:
DESIGNATION:							DESIGNATION:



REFERENCES
1a.What is the name of the Owner?
1b.In what country was the Owner incorporated?
1c.What is the RC Number of the Owner?
1d.What is the office address of the Owner?
2a.What is the name of the Hirer?
2b.In what country was the Hirer incorporated?
2c.What is the RC Number of the Hirer?
2d. What is the office address of the Hirer? 
3.  Kindly provide a detailed description of the Property. E.g. A black 2004 Toyota Camry with registration number C606 PXX, a 5,000 kva Mikano generator, a Suzuki Gixxer 2017 Motorcycle or An iphone 7 mobile phone etc.
4a.How much will be paid to hire the Property? 
4b.How often will the payment be made? For example; daily or weekly or monthly or quarterly or yearly
4c.What is the duration of the hire? 
5a � The payments will be made in how many instalments?
5b- How much is the first instalment?
5c � When is the first instalment due? e.g. 14th November 2016
6 When will subsequent instalments be made? E.g. first Monday of every month; 25th of every month, last day of every quarter etc. 
7a. What bank should the payment be made to? 
7b: What is the Account Name?
7c: What is the Account Number?
 8: If the Owner wants to terminate the contract, what should the length of the notice be?  For example: 2 weeks or 5-days or 1 month 





HIRE PURCHASE AGREEMENT
This Hire Purchase Agreement is made this _________ day of __________________ 20_____ (the �Agreement�)

BETWEEN
Mrs. Catherine Johnmacars of XXX hereafter referred to as the �Owner� of the first part;
Mr. xxx of (the �Hirer�) of the second part; and
Mr. xxx of (the �Guarantor�) of the third part.

Individually referred to as �Party� and collectively as �Parties�.

WHEREAS the Owner owns the motor Vehicle, more particularly described in the Schedule to this Agreement (the �Vehicle�). The Hirer has approached the Owner and the Owner has agreed to let the Vehicle for consideration of the payment of weekly rent which may, over the course of fifty-two (52) weeks, buy out the Owners interest in the Vehicle.
AND WHEREAS the Hirer has inspected the Vehicle and is satisfied about the same and considers it fit for the purposes for which he requires it.
The Guarantor understands the obligations of the Hirer under this Agreement and undertakes to guarantee performance of same. 
Therefore, the Parties have agreed terms as encapsulated in this Agreement. 

WHEREBY IT IS AGREED AS FOLLOWS:
1.	Hire Purchase clause:
1.1	The Owner will let and the Hirer will take on hire the Vehicle for a period of fifty-two (52) weeks, paying in equal weekly instalments the sum of one million, five hundred thousand Naira (NGN 1,500,000.00) being the total purchase value of the Vehicle.
1.2	In consideration of the delivery of the  Vehicle to the Hirer, the Hirer has paid in advance the sum of twenty-eight thousand, eight hundred and forty-seven Naira (NGN 28,847.00) as first installment for hiring the Vehicle (the receipt of which the Owner hereby acknowledges) and will punctually pay to the Owner the sum of twenty-eight thousand, eight hundred and forty-seven Naira (NGN 28,847.00) every calendar week by way of rent for the hiring the  Vehicle, the first payment to be made on the ����. day of ����. and each subsequent payment on the Sunday of every succeeding week into the account of the Owner:
Name:
Banker:
Account number:
Account type:
1.3	The Hirer shall have the option of paying in one lump sum the balance of all the full value of the Vehicle as set out in paragraph 1.1 above, including any cost or payment direct and incidental to the terms of this Agreement.
1.4	Upon payment of the full value of the Vehicle to the Owner, including payment of any other sums incidental to the Hirers obligation under this Agreement, the hiring shall come to an end and the Vehicle shall become the property of the Hirer. The Owner shall, thereupon, assign all her rights and interest in the Vehicle to the Hirer. 

2.	Hirer�s covenants
The Hirer covenants:
2.1	Not to sell, assign, pledge, mortgage, underlet, lend or part with the possession of the Vehicle.
2.2	Not to take the Vehicle out of Lagos State, Nigeria without first obtaining written permission from the Owner.
2.3	To pay all licence duties, fees, registration and other charges taxes, payable in respect of the Vehicle.
2.4	To keep the Vehicle in good repair condition and working order. 
2.5	To permit the Owner and persons authorised by him to have access to the Vehicle for the purpose of inspecting the condition thereof.
2.6	To Keep the Vehicle insured against all risks, at the Hirer�s expense, during the term of this Agreement in the joint names of the Owner and the Hirer. The Hirer shall pay all the premiums payable to the insurance company. 
2.7	Make good to the Owner all damages to the Vehicle (fair wear and tear excepted) and pay the Owner the full value of the Vehicle in the event of its total loss during the period of the hire.
2.8	Indemnify the Owner against claims by third parties arising by accident caused by the Vehicle until the Vehicle is returned to the Owner or purchased by the Hirer in terms of this Agreement.
2.9	Not use or permit or suffer the Vehicle to be used in contravention of any law for the time being in force.

3.	Owners� rights and obligation:
3.1	In case the Hirer fails or neglects to cause the Vehicle to be repaired or kept in a proper state of repair, the Owner shall be entitled to seize or cause the Vehicle to be seized and to get the same repaired and kept in the Owners custody until the Hirer pays all cost arising from the seizure and repair of the Vehicle.
3.2	On the Hirer becoming the Owner of the Vehicle under the terms of this agreement, the owner will transfer to the hirer the benefit of any insurance policy then current relating to the Vehicle.

4.	Termination 
Save for termination as contemplated under clause 1.4 of this Agreement:
4.1	The Hirer may terminate the hiring by returning the Vehicle at his own cost and risk to the Owner at her place of address for the time being PROVIDED THAT the Vehicle is in a good state of repair and the Hirer shall remain liable to pay-up any outstanding rental instalment as at the date of return of the Vehicle. All payments made for the hiring of the Vehicle prior to termination under this clause shall be forfeited by the Hirer. 
4.2	The Owner may terminate this Agreement by giving one-week notice to the Hirer of her intention to terminate same. Where the Agreement is terminated under this paragraph, the Hirer shall be entitled to a refund of all weekly instalments made, net of any other costs or fees incidental to his obligation under this agreement.
4.3	If the Hirer shall default in payment of any weekly sum payable hereunder for three (3) days after the same has become due or fails to observe or perform any of the terms and conditions of this Agreement, the Owner may, without prejudice to her claim for arrears of hire or damages (if any) for breach of this Agreement forthwith terminate the hiring without notice and retake physical possession of the Vehicle herself or through her agents.
4.4	In the event of the hiring being determined by the Hirer or by the Owner under paragraphs 4.1 and 4.2, the Hirer shall forthwith return the Vehicle to the Owner in good state of repair and working order at the Hirer�s expense. 
4.5	The determination of the hiring shall not affect or prejudice any claim the Owner may have against the Hirer for arrears of hire payments or for damages for breach of this Agreement or her right to enforce such claim by action or otherwise.

5.	Guarantor�s acknowledgment 
The Guarantor acknowledges having knowledge and understanding of the clauses and conditions of this Agreement. The Guarantor hereby irrevocably undertakes to the Owner absolute and unconditional due payment on demand of all monies which shall at any time be due to the Owner; such sum to be paid in the manner prescribed in clause 1 above.

6.	Dispute resolution 
The Parties shall endeavor to settle any dispute arising between them amicably. Where amicable settlement fails, the dispute shall be finally determined by arbitration by a sole arbitrator under the Arbitration and Conciliation Act at the Lagos Multi-Door Court House. 

7.	Severability
If any term, clause or provision hereof is held invalid or unenforceable by a court of competent jurisdiction, such invalidity shall not affect the validity or operation of any other term, clause or provision and such invalid term, clause or provision shall be deemed to be severed from the Agreement.

8.	No Inference Against Author 
No provision of this Agreement shall be interpreted against any Party because such Party or its legal representative drafted such provision.

9.	Non-waiver
No neglect, delay or indulgence on the part of the owner in enforcing any terms or conditions of this agreement shall prejudice the rights of the owner hereunder.

10.	Acknowledgment of understanding:
Each Party acknowledges that it has read and understands this Agreement and agrees to be bound by its terms and conditions.

IN WITNESS OF WHICH the Parties have executed this Agreement in the manner below, day and year first above written.
SIGNED
By the within named �OWNER�

���������������
Mrs. Catherine Johnmacars

In the presence of:

Name:��������������.
Address��������������
Occupation:������������
Signature:�������������

SIGNED
By the within named �HIRER�

������������������
Mr. xxx

In the presence of:

Name:���������������
Address���������������
Occupation:�������������
Signature:��������������

SIGNED
By the within named �GUARANTOR�

������������������
Mr. xxx

In the presence of:

Name:���������������
Address���������������
Occupation:�������������
Signature:��������������





SCHEDULE:
Brand:
Model:
Date of manufacture:
Chasis no:
Color: 
Documentation:
1.	




POWER OF ATTORNEY (LAND)
Description of Power of Attorney
A power of attorney is a document issued by a party (Donor) to another party (Donee) which authorizes the Donee to act on behalf of the Donor and manage the affairs of the Donor. It contains the powers, limitations and authority bestowed on the Donor and lays out the duration of the exercise of same, it also states whether or not it is given for a consideration and the financial compensation for the Donee if any. It is advisable to use a trusted person as your Donor, and where there are any restrictions, to state same expressly.

FREQUENTLY ASKED QUESTIONS (FAQS)
Who is a Donor?
A Donor is the party who authorises another party to act on his behalf.

Who is a Donee?
A Donee is the party authorised to act on behalf of the Donor in the Power of Attorney.

Which laws should govern my Power of Attorney?
Generally, the laws of the jurisdiction in which the subject of the Power of Attorney is situate will govern it. It is advisable to appoint a donor within same jurisdiction where the subject of the power is situate, otherwise there may be need to use several donors or prepare separate powers of attorney for each separate subject that is out of jurisdiction.

Must the Donee be a lawyer?
No, your Donee need not be a Lawyer. Anyone who is an adult is qualified to be a Donee. The Donor cannot however be an undischarged bankrupt or mentally incapacitated.

Should I grant general or specific powers?
If there are particular acts you may wish to undertake yourself, it is advisable to grant specific powers to your Donee. Granting general powers will give your Donee the authority to oversee all your affairs and do anything you would lawfully do if you were present. 


Can I revoke /end my Power of Attorney?
Yes you can unless it is an irrevocable Power of Attorney. A power of attorney continues indefinitely until ended in writing except the document granting the power states a given date.


THIS IRREVOCABLE POWER OF ATTORNEY is made this ___ day of ___________ 20___ by _______ (1a) _____ whose address is _____ (1b) ______ (referred to as the �Donor�)

The Donor being of sound mind and legal capacity, do hereby irrevocably nominate, constitute and appoint ________ (2a) ________whose address is ______________ (2b) _____________ as the Donor�s true and lawful attorney with full authority to do and execute the following acts in the Donor�s name and on the Donor�s behalf in respect of all that piece of land situate at _____ (3) ______ more particularly described in Survey Plan No. _____ (4) ______ dated _____ (5) ______ prepared by _____ (6) ______:
i.	To manage and superintend over the management of the Land to develop same for building purposes or otherwise to mortgage, sell, charge, lease, let and otherwise dispose of same.
ii.	To sign and give notices to tenants or occupiers of the said property (if any), to quit or to abate a nuisance or to remedy a breach of covenant or for any other purpose thereof and to enter upon all such property whether for the purpose of viewing the state there of or in exercise of any right of re-entry vested in the Donor. 
iii.	To make and sign applications to the appropriate Government departments, local authorities or other competent authorities for all and any licenses, permissions or consents required by any Act, statutory instrument by law, or otherwise in connection with the management or improvement of the premises including the recovery of compensation where such is recoverable with full power to pay development or other charges and to raise the same by mortgage upon premises.
iv.	To warn off or prohibit and if necessary proceed against in due form of law all trespassers on the premises and to take appropriate steps whether by action or otherwise to abate all nuisances.
v.	To sign and execute any contracts, assignments, transfers, leases, mortgages, charges, deeds and instruments whatsoever in connection with the Land.
vi.	To do all other things and exercise any other powers as are necessary and directly incidental for the purpose of carrying out of the powers created above as the Donor may lawfully do.

AND the Donor declares:
1.	The powers and authorities given under this Power of Attorney will remain in full force and effect until the Donor completes the acts relating to the powers created above.
2.	That this Power of Attorney is given for a valuable consideration of _____ (7) ______.  
3.	The Donee shall be liable to the Donor for willful negligence and gross misconduct.
4.	The Donor will pay to the Donee all reasonable costs associated with stamping and registering this Power of Attorney and all reasonable and approved expenses incurred in carrying out the powers above.
5.	The document shall be governed by the Laws of Nigeria.  
6.	The Attorney shall not delegate any authority granted under this agreement.

IN WITNESS OF WHICH the Donor, has executed this Power of Attorney in the manner below the day and year first above written.


____________________				
Donor�s Name: 

IN THE PRESENCE OF 

Name: ___________________________________________________________
Address: __________________________________________________________
Signature: _________________________________________________________
 
References
1a � Donor�s name 
1b � Donor�s address
2a � Donee�s name
2b � Donee�s address 
3 � Please provide the address/location of the land 
4 - What is the Survey Plan No for the land? 
5 � What date was the survey plan prepared?
6 � What is the name of the surveyor that prepared the survey plan?
7 � How much will the Donee be paid for the carrying out the powers? NB: Payment can either be in cash or kind 




  IRREVOCABLE POWER OF ATTORNEY
A Power of Attorney is a document used to give someone you trust the legal authority to manage your financial, property, and/or matters. The very purpose of a power of attorney is to ensure that the best interests of the person for whom they operate are served. A power of attorney can be made irrevocable if it is given with due consideration and if it specifically mentions that it is irrevocable. Like any power of attorney, an irrevocable power of attorney gives an agent/donee the authority to make decisions, enter into contracts and generally act on the behalf of the person granting the power (the principal/donor). Irrevocable powers of attorney are relatively rare, though, because they essentially operate like any other power of attorney, but are not unilaterally revocable by the principal. As a practical matter, an irrevocable power of attorney is rarely used and is typically limited to a specific purpose. Often, a Power of Attorney is used to ensure that your affairs will be administered according to your wishes in the event that you become mentally or physically incapable. Alternately, it may be convenient for persons who will be away for an extended period, and unable to personally sign documents or make financial and property decisions.

But a power of attorney can be made irrevocable if the document includes a provision that specifically states that the principal gives up the right of revocation or otherwise indicates that the power is irrevocable. 

The purpose of granting a power of attorney is to ensure that when you are unavailable or unable to make decisions; your interest will continue to be protected by the person(s) whom you have chosen. Also, if you became ill and unable to manage your day to day finances, your bank and other organisations will not accept instruction from anyone who has not been legally authorised to act on your behalf.  

1.	A Power of Attorney is a formal instrument by which one person grants authority to another to act for him or her, or act in his or her stead for certain purposes. The person giving the authority or power is called the donor, while the person to whom the authority or power is given is called the donee.
2.	Only a person in law (i.e. someone who can sue and be sued) can be an Attorney.
3.	The authority to the Attorney must be under seal when the Attorney is authorised to execute a deed.
4.	The court construes a Power of Attorney strictly.
5.	A Power of Attorney will be irrevocable not by terming it irrevocable but, If it is given for consideration and it remains irrevocable until the consideration is realized. If it is given for a period of time (usually not more than 12 months); it is irrevocable until the period expires.
6.	The power exercised by the donee of an irrevocable Power of Attorney is not vitiated irrespective of the death, lunacy, insanity, bankruptcy of the donor until the consideration is realized, or the time expires. 
7.	The Attorney should execute the deed in the name of the donor, but may execute it in his own name, except where any statute directs that the deed is to be executed in the name of the estate owner.
8.	A Power of Attorney may be revoked either: (a)   Expressly; or (b) Impliedly; or (c) By operation of law. 
9.	Although attestation is not a strict requirement of a Power of Attorney, it is necessary that a Power of Attorney be attested to by a notary public, judge or magistrate for the presumption of due execution.
10.	The Power of Attorney, if executed outside Nigeria, must be witnessed by either a Notary Public or a Judge or Magistrate.Note: A Notary Public is a legal practitioner who attests or certifies Deeds and other documents. A Notary Public by the Law of Nations has credit everywhere.
11.	A Power of Attorney attracts a fixed stamp duty under the Stamp Duties Laws of the various states.
12.	Power of Attorney is also a registrable instrument under the relevant Land Instruments Registration Laws. Non-registration renders it inadmissible in evidence in court like any other Deed.
13.	Stamping precedes registration and both are required when the Power of Attorney confers interest in land or landed property.
14.	The various state Land Laws provide for the acquisition of the Governor's consent to transactions involving alienation of interest in land. A Power of Attorney relating to sub-lease of State lands or certificate of occupancy must have the consent of the Governor.
15.	The Purchaser of a property pursuant to a Power of Attorney is entitled to have the instrument creating the power delivered to him, if it related to one transaction or an acknowledgment for production and an undertaking for safe custody.
16.	Where the power relates to other transactions and, as such cannot be released, in such a case, the purchaser should request that a memorandum of the execution of the power in his favour be endorsed on the Deed creating the Power of Attorney.
17.	The donee of a Power of Attorney cannot sue in his own name but must sue in the name of the principal.
18.	Where a donee is given power to execute a conveyance such as a Deed of Assignment, the commencement will read:"This DEED OF ASSIGNMENT made the .... day of �..2014, between ABC, by his Attorney Akintunde Esan".
19.	 A Power of Attorney granted by Deed must be revoked by Deed.
20.	 If the donor grants another Power of Attorney in respect of the same subject-matter, it cannot be taken to be an implied revocation of the original one. The subsequent grant of a Power of Attorney is invalid.
21.	A Power of Attorney can be invalidated if fraud, duress, undue influence or an illegality is established.

THIS IRREVOCABLE POWER OF ATTORNEY is made this ������.. Day of������� by �(1)� of �(2)� [Hereinafter referred to as the �DONORS� which expression, shall, where the context admits, include heirs, personal representatives and assigns].

WHEREAS 
The Donors are the Directors and Shareholders holding the fully allotted shares of FELIXTOWE LIMITED (the �Company�), a private limited company registered under the laws of the Federal Republic of Nigeria and having its registered address at 17a Wumego Crescent, Off Christ Avenue, Lekki Phase 1, Lagos. 

NOW THIS IRREVOCABLE POWER OF ATTORNEY WITNESSES as follows:

The within named �DONORS� hereby jointly and severally appoint Mr. Richard Pusey and Mrs. Omone Pusey of 10 Aberdeen Court, Maida Vale, London W91AF [Herein referred to as the �DONEES�, which expression, shall, where the context admits, include heirs, personal representatives and assigns] as their lawful ATTORNEYS and in their name and on their behalf, to do and execute all or any of the following acts and things:

1.	To manage and carry on the business and registered objects of FELIXTOWE LIMITED including but not limited to:

a.	carrying on the business of general contracts, general merchants, general supplies and also to carry on business as traders, commission agents, or carriers or in any capacity within the Federal Republic of Nigeria;
b.	carrying on business as importers, exporters, distributors, suppliers, wholesalers, retailers, buyers and sellers of any products or commodities and to supply and deal in goods, articles and things of all kinds;
c.	carrying on business as contractors, suppliers, importers, exporters and dealers in all kinds of building materials;
d.	borrowing or raising money in such a manner as the company shall think fit, and particularly by debenture stock (perpetual or otherwise), and to secure the repayment of any money borrowed, raised or owing by mortgage, charge or lien upon the whole or any part of the company�s property or assets (whether present or future) including its uncalled capital;
e.	doing all such other things as may be considered to be incidental or conducive to the attainment of the above registered objects or any of them.

2.	To supervise, execute all documents and represent the DONORS and on behalf of the Company act in all transactions including but not limited to opening and operating bank accounts, acquisition of stocks and bonds, banking and other financial transactions, insurance transactions, business and personal operations connected to or in furtherance of the business of the Company.

3.	To sign and give notices to tenants, occupiers or holders of any property or assets of the Company, to quit or to abate a nuisance or to remedy a breach of covenant or for any other purpose thereof to enter upon or obtain control over all such property, whether for the purpose of viewing the state thereof or in exercise of any right of re-entry or repossession vested in the DONORS. 

4.	To make and sign applications made to the appropriate Government departments, local authorities or other competent authorities for all and any licenses, permissions or consents required by any Act, Statutory Instrument, by law, or otherwise in connection with the management or improvement of any property or other asset of the Company including the recovery of compensation where such is recoverable,  with full power to pay development or other charges and to raise the same by mortgage upon the proceeds of the property or other asset.

5.	To warn off or prohibit and if necessary proceed in due form of law against all trespassers on the property or other asset of the Company and to take appropriate steps whether by legal action in court or otherwise to abate all nuisances.

6.	To sign and execute any contracts, assignments, transfers, leases, mortgages, charges, deeds and instruments whatsoever in connection with the Company and/or its business.

7.	To exercise full power and authority to perform every act and thing whatsoever necessary to accomplish the purposes for which this Irrevocable Power of Attorney is granted, as fully and effectually as the DONORS can exercise themselves. 


WE FURTHER DECLARE that any act or thing lawfully done under the grant of this power by our named attorneys shall be binding on ourselves both jointly and severally, and our heirs, legal and personal representatives and assigns and we ratify all such lawful acts. 

THIS IRREVOCABLE POWER OF ATTORNEY shall not be affected by subsequent incapacity of any or all of the Donors.

IN WITNESS WHEREOF the DONORS have executed this document the day and year first above written. 

SIGNED. SEALED AND DELIVERED by the within named DONORS,



______________________________
DR STEPHEN OMOKOJIE IRUNE

In the presence of:  
Name: ��������    	_____________________________________              
Occupation:	_____________________________________
Address: 	_____________________________________
Signature:	_____________________________________

AND

___________________________________
MRS JOSEPHINE OLUFUNMILAYO IRUNE

In the presence of:  
Name: ��������    	_____________________________________              
Occupation:	_____________________________________
Address: 	_____________________________________
Signature:	_____________________________________
 
In _______________, on the _____________ day of ____________, 20 __, before me, a Notary Public for Nigeria, _________________________ and ________________________personally appeared and are proved to be the persons named in and who executed the foregoing instrument, and being first duly sworn, acknowledged that they executed said instrument for the purposes therein contained as their free and voluntary act and deed. 
 



_______________________________
NOTARY PUBLIC
 
References 
(1)	Who is granting the Power of Attorney? 
(2)	What is the address of the person granting the Power of Attorney? 





DESCRIPTION OF THE DOCUMENT
A Joint Venture Agreement (�JVA�) is a business arrangement where two parties develop a new entity to their mutual benefit. It is essentially two or more businesses coming together to achieve a specific task. It usually involves sharing of resources like capital, facility, personnel etc. All joint ventures are initiated by the parties entering a contract or an agreement that specifies their mutual responsibilities and goals. The contract is crucial for avoiding trouble later; the parties must be specific about the intent of their joint venture as well as aware of its limitations. The objectives of the JV, the initial contributions of the partners, the day-to-day operations and the right to the profits (and responsibility for losses) of the JV are all set out in this document.

FREQUENTLY ASKED QUESTIONS (FAQs)	
Why execute a Joint Venture?
A joint venture involves two or more businesses pooling their expertise and resources together to achieve a set goal. It aids in business expansion and development and is good for small or medium enterprises seeking to expand their capital base or to go into production. It creates an avenue for collaborations to achieve project that an entity may otherwise not be able to solely undertake.
What is the difference between a partnership and Joint Venture?
A Joint Venture is a contractual agreement between two parties with the aim of achieving a specific task. Whereas a Partnership is an agreement between two parties on an understanding that profits and loss will be shared by parties.
Is the Joint venture a separate Legal entity?	
It depends on whether the Joint Venture is Incorporated or unincorporated. In an incorporated Joint Venture, the partners arrange for the incorporation of a separate legal entity to undertake the project on their behalf. An unincorporated joint venture on the other hand is a type of business arrangement in which partners come together using a contract as the basis for governing the collective relationship without incorporating an entity to pursue the project. This type of approach is commonly used where the venture in question is for short-term purposes only. 
.


JOINT VENTURE AGREEMENT (JVA)
This JOINT VENTURE AGREEMENT (�JVA�) is made this ___________ day of ___________ 20___.
BETWEEN
___1a, a company incorporated under the laws of _____1b whose address is situate at ___1c (hereinafter called �Partner 1�), which expression shall where the context so admits include its affiliates, successors-in-title and assigns) of the first part.
AND
__2a, a company incorporated under the laws of _____2b whose address is situate at ___2c (hereinafter called �Partner 2�) which expression shall where the context so admits include its affiliates, successors-in-title and assigns) of the other part.
Partner 1 and Partner 2 may hereafter also be referred to individually as �Party� or collectively as �Parties�.

WHEREAS
1.	___3a
2.	___3b
3.	The aim of entering into this JVA is to set out the working arrangements the Parties believe are necessary to undertake ___3ba (the Project) jointly and provide a framework for future contracts between them.
4.	The Parties agree to work together to implement this JVA in full, in a timely, efficient and effective manner. 
It is hereby agreed as follows:
1.	UNDERSTANDING OF THE PARTIES
1.1.	No Party shall have any right or authority to create or incur any liability or any obligation of any kind, express or implied, against or in the name of or on behalf of the other Party to this JVA except to the extent expressly permitted under this JVA        or subsequently authorized by a Party in writing in furtherance of the Project.
1.2.	Nothing contained in this JVA shall prevent any Party from carrying on its business for its sole interest and profit outside of this JVA, unless to the extent that this would have an unfavorable influence or effect on the terms of this JVA. 
1.3.	The approved business plan under this Joint Venture for the purposes of the Project shall be the joint property and for the benefit of both parties.
1.4.	The Joint Venture shall be incorporated as a limited liability company under the laws of the Federal Republic of Nigeria (the Company). The business and affairs of the Joint Venture shall be conducted solely and at all times under the name of " _____3c". In the event that the Joint Venture has not been registered at the point of executing the agreement and the name provided in this clause 1.4 is not available at the Corporate Affairs Commission, the Joint Venture shall be registered in a name unanimously agreed by the Parties. 
1.5.	Each party has contributed capital to the Joint Venture, in cash or property in agreed upon value as detailed in Appendix A (Capital Contribution)  
1.6.	The Parties agree that they shall be responsible for ensuring that the	Joint Venture is funded from one or more of the following sources:
a.	Funding provided by each Party to the Joint Venture, whether by way of additional capital contributions and/or shareholder loan; and/or
b.	Funding from revenues generated by the activities of the Joint Venture.
1.7.	Profits in the Joint Venture will be distributed between the Parties in proportion to their respective shareholding in the Joint Venture viz:
a.	___3d 
b.	___3e

2.	OBLIGATIONS OF THE PARTIES
The Parties shall be jointly responsible for the following: 
2.1.1.	The Parties shall upon the approval of a business plan developed for the Project, oversee the implementation of the business plan to the full extent required for the plan to succeed;
2.1.2.	The Parties, shall be responsible for nominating and screening prospective employees and manpower prior to formal interview and recruitment which will be carried out by the Company
2.1.3.	The parties shall maintain a detailed record of all information including but not limited to production rate, utilization of resources, etc. and prepare financial and operational reports for the Company�s use;
2.1.4.	The Parties shall be responsible to sourcing for the initial investment capital required for the Project. 
2.1.5.	The Parties shall keep each other informed on the progress of the performance of their obligations in relation to this Agreement. 
2.1.6.	The Parties will make all relevant information and advice available to each other to enable them carry out their respective obligations under this JVA in a timely and efficient manner.
The individual obligations of each Party are provided in Appendix B. 

3.	CONFIDENTIALITY AND NON-CIRCUMVENTION

3.1.	This JVA, the Project, all information provided by any of the Parties in respect of themselves or their business operations for the purpose of the Project and all negotiations and discussions relating to the Project are confidential between the Parties and shall not be disclosed to any third-party, except as is necessary to facilitate the Project, and upon the Party obtaining same undertaking from the third-party to whom such information shall be disclosed.
3.2.	The Parties irrevocably agree, and guarantee each other that no Party shall, directly or indirectly interfere with, circumvent or attempt to circumvent, avoid, by-pass, or obviate the interests of the other Parties, or relationship with any third party, or initiate any business or transactional relationships that by-passes any Party or with any third party revealed or introduced by the Parties to each other in connection with any past, on-going or future transaction. 
3.3.	The Parties acknowledge that damages may be inadequate compensation for any breach of this JVA and each Party agrees that a Party shall be entitled to seek an injunction, specific performance, or similar remedy against any conduct or threatened conduct which is or would be a breach of this JVA.

4	 DURATION  
4.1. 	This JVA will become effective once signed by the Parties. It will continue in force from the date of execution for an initial period of ____5a subject to renewal for such further terms as may be mutually agreed by the Parties in writing. 
       5.    TERMINATION
       5.1 Either party shall be entitled to terminate this JVA by notice to the other party at any time in the event that the other party commits a material breach of any of the obligations contained herein; and in the case where such a breach is capable of rectification, the breaching party fails to rectify the same within sixty (60) days after written notice is given demanding such rectification by the other party;
             5.2 The Joint Venture also can come to an end upon the happening of all or any of the provisions of law with respect to a business/company getting dissolved. In that case, the Joint Venture shall terminate and be wound up. The Joint Venture property shall thereupon be sold (and any of the parties may be a purchaser of all or any portion thereof), its liabilities paid or provided for, and the remaining assets distributed to and among the parties pro rata in accordance with their capital accounts, without undue delay. Any remaining profits shall be distributed pro rata in accordance with the Parties' capital accounts as they stood prior to the making of the distribution required under terms of the JVA. 

6.  GOVERNING LAW/RESOLUTION OF DISPUTES
6.1. 	This Agreement shall be construed and governed by the laws of the Federal Republic of Nigeria.
6.2. 	If there is a dispute, controversy or claim between the Parties, the Parties shall try to reach agreement amicably by direct negotiations. If no agreement can be reached within thirty (30) days, the dispute, controversy or claim shall be referred to Arbitration in accordance with the Arbitration and Conciliation Act Cap A[xx] Laws of the Federation of Nigeria 2004.  A dispute shall be deemed to have arisen when either Parties notifies the other Party in writing to that effect.  The Language of Arbitration shall be English.

7. 	NOTICES	
Any notices or demands permitted or required hereunder shall be in writing sent by certified or registered mail, postage prepaid, addressed to each party at the address set forth below 
      Notices to Partner 1shall be addressed to:
       _______6a

      Notices to Partner 2 shall be addressed to:
       ________6b

   8.    ASSIGNMENT AND SUBCONTRACTING
The Parties shall not assign or transfer all or any part of their respective rights under this 	JVA or delegate their performance under this JVA without the prior written approval of the other Party, and any assignment, transfer or delegation that is made without such prior written approval shall constitute a breach of this JVA.

     9.   SEVERANCE
If any clause or part of a clause in this JVA is found by a court, arbitrator or other authority of competent jurisdiction to be void or unenforceable, that clause or part of a clause is to be deemed deleted from this JVA and the remaining clauses and parts thereof shall continue in full force and effect.  If necessary, the Parties shall in such event seek to agree upon a valid and enforceable clause or part of a clause to replace the clause or part of a clause found to be void and unenforceable.   

       10. COUNTERPARTS
This JVA may be executed in any number of counterparts, but shall not take effect until each Party has executed at least one counterpart.  Each counterpart shall constitute an original but all the counterparts together shall constitute a single agreement.	

    11.  MISCELLANEOUS MATTERS
11.1.    This JVA shall be identified as the parent document of any subsequent agreement executed between the Parties and between the Parties and any other third party wishing to participate in the Project or to render any service to either of the Parties in fulfilment of any of its obligations under this JVA. Such further agreements concerning the Project shall be for the purpose of providing specific details and commitments of the Parties with respect to the Project.
11.2. 	This JVA can only be modified by a written agreement between the Parties.
11.3. 	Neither Party has the authority to make decisions for the other, or to enter into obligations on behalf of the other.
11.4. 	In executing this JVA, each Party warrants that they have the necessary financial and technical resources to fulfill their obligations under this JVA, and each Party individually represents that it has the necessary corporate approvals (where applicable) to execute this JVA, and perform the obligations described herein.

IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the day and year first above written.

THE COMMON SEAL of the within named (repeat reference 1a) is hereby affixed in the presence of 



____________________________		                   ____________________________		
Director					                     Secretary



THE COMMON SEAL of the within named (repeat reference 2a) is hereby affixed in the presence of 
	


____________________________		                   ____________________________		
Director					                     Secretary



References:
1a. What is the name of the first Joint Venture Partner?
1b. Under the laws of what Country is the first Joint Venture Partner registered?
1c. What is the address of the first Joint Venture Partner?
2a. What is the name of the second Joint Venture Partner?
2b. Under the laws of what Country is this first Joint Venture Partner registered?
2c. What is the address of the second Joint Venture Partner?
3a. Please provide a brief description of the first Joint Venture Partner 
3b. Please provide a brief description of the second Joint Venture Partner 
3ba. What transaction or business are the parties desirous of undertaking jointly? 
3c What is the name of the entity through which the parties will run the joint venture?
3d. What is the percentage of shares held by the first Joint Venture Partner?
3e. What is the percentage of shares held by the second Joint Venture Partner?

5a. What is the duration of this Joint Venture Agreement?
6a. Please provide details (in the format below) of the person to receive notices for the first Joint Venture Partner 
Name: John Peter 
Designation: Director 
Address:13 Awolowo Way, Lekki, Lagos 
Email: jpeter@gmail.com
6b. Please provide  details (in the format below) of the person to receive notices for the second Joint Venture Partner 
Name: John Peter 
Designation: Director 
Address:13 Awolowo Way, Lekki, Lagos 
Email: jpeter@gmail.com




DESCRIPTION OF THE DOCUMENT
A Joint Venture Agreement (�JVA�) is a business arrangement where two parties develop a new entity to their mutual benefit. It is essentially two or more businesses coming together to achieve a specific task. It usually involves sharing of resources like capital, facility, personnel etc. All joint ventures are initiated by the parties entering a contract or an agreement that specifies their mutual responsibilities and goals. The contract is crucial for avoiding trouble later; the parties must be specific about the intent of their joint venture as well as aware of its limitations. The objectives of the JV, the initial contributions of the partners, the day-to-day operations and the right to the profits (and responsibility for losses) of the JV are all set out in this document.

FREQUENTLY ASKED QUESTIONS (FAQs)	
Why execute a Joint Venture?
A joint venture involves two or more businesses pooling their expertise and resources together to achieve a set goal. It aids in business expansion and development and is good for small or medium enterprises seeking to expand their capital base or to go into production. It creates an avenue for collaborations to achieve project that an entity may otherwise not be able to solely undertake.
What is the difference between a partnership and Joint Venture?
A Joint Venture is a contractual agreement between two parties with the aim of achieving a specific task. Whereas a Partnership is an agreement between two parties on an understanding that profits and loss will be shared by parties.
Is the Joint venture a separate Legal entity?	  
It depends on whether the Joint Venture is Incorporated or unincorporated. In an incorporated Joint Venture, the partners arrange for the incorporation of a separate legal entity to undertake the project on their behalf. An unincorporated joint venture on the other hand is a type of business arrangement in which partners come together using a contract as the basis for governing the collective relationship without incorporating an entity to pursue the project. This type of approach is commonly used where the venture in question is for short-term purposes only. No, it is not. The Joint venture would carry out activities through the parties who have formed it as agreed in the JVA. Hence, from entering into contracts, to hiring and tax payment, all will be handled by the parties who formed the Joint venture as stated in their JVA.
Is there a difference between a Joint Venture and a Strategic Alliance?	
While a Joint Venture creates a new company jointly owned by the Parent Companies, a Strategic Alliance does not create a new entity. A strategic alliance merely is a legal arrangement where parties decide to share resources.


JOINT VENTURE AGREEMENT (JVA)
This JOINT VENTURE AGREEMENT (�JVA�) is made this ___________ day of ___________ 20___.
BETWEEN
___1a, a company incorporated under the laws of _____1b whose address is situate at ___1c (hereinafter called �Partner 1the Company�), which expression shall where the context so admits include its affiliates, successors-in-title and assigns) of the first part.
AND
__2a, a company incorporated under the laws of _____2b whose address is situate at ___2cb (hereinafter called �Partner 2the Co-venturer�) which expression shall where the context so admits include its affiliates, successors-in-title and assigns) of the other part.
The CompanyPartner 1 and Partner 2 the Co-venturer may hereafter also be referred to individually as �Party� or collectively as �Parties�.

WHEREAS
1.	___3a
2.	___3b
3.	The aim of entering into this JVA is to set out the working arrangements the Parties believe are necessary to undertake ___3ba (the pProject)s jointly and provide a framework for future contracts between them.
4.	The Parties agree to work together to implement this JVA in full, in a timely, efficient and effective manner. 
It is hereby agreed as follows:
1.	UNDERSTANDING OF THE PARTIES
1.1.	No Party shall have any right or authority to create or incur any liability or any obligation of any kind, express or implied, against or in the name of or on behalf of the other Party to this JVA except to the extent expressly permitted under this JVA        or subsequently authorized by a Party in writing in furtherance of the Project.
1.2.	Nothing contained in this JVA shall prevent any Party from carrying on its business for its sole interest and profit outside of this JVA, unless to the extent that this would have an unfavorable influence or effect on the terms of this JVA. 
1.3.	The approved business plan under this Joint Venture for the purposes of the Project shall be the joint property and for the benefit of both parties.
1.4.	The Joint Venture shall be incorporated as a limited liability company under the laws of the Federal Republic of Nigeria (the Company). The business and affairs of the Joint Venture shall be conducted solely and at all times under the name of " _____3c". In the event that the Joint Venture has not been registered at the point of executing the agreement and the name provided in this clause 1.4 is not available at the Corporate Affairs Commission, the Joint Venture shall be registered in a name unanimously agreed by the Parties. 
1.5.	Each party has contributed capital to the Joint Venture, in cash or property in agreed upon value as detailed in Appendix A (Capital Contribution)  
1.6.	The Parties agree that they shall be responsible for ensuring that the 	Joint Venture is funded from one or more of the following sources:
a.	Funding provided by each Party to the Joint Venture, whether by way of additional capital contributions and/or shareholder loan; and/or
b.	Funding from revenues generated by the activities of the Joint Venture.
1.7.	Profits in the Joint Venture will be distributed between the Parties in proportion to their respective shareholding in the Joint Venture viz:
a.	___3d 
b.	___3e

2.	OBLIGATIONS OF THE PARTIES
The Parties shall be jointly responsible for the following: 
2.1.1.	The Parties shall upon the approval of a business plan developed for the Project, oversee the implementation of the business plan to the full extent required for the plan to succeed;
2.1.2.	The Parties, shall be responsible for nominating and screening prospective employees and manpower prior to formal interview and recruitment which will be carried out by the Company
2.1.3.	The parties shall maintain a detailed record of all information including but not limited to production rate, utilization of resources, etc. and prepare financial and operational reports for the Company�s use;
2.1.4.	The Parties shall be responsible to sourcing for the initial investment capital required for the Project. 
2.1.5.	The Parties shall keep each other informed on the progress of the performance of their obligations in relation to this Agreement. 
2.1.6.	The Parties will make all relevant information and advice available to each other to enable them carry out their respective obligations under this JVA in a timely and efficient manner.
The individual obligations of each Party are provided in Appendix B. 

3.	CONFIDENTIALITY AND NON-CIRCUMVENTION

3.1.	This JVA, the Project, all information provided by any of the Parties in respect of themselves or their business operations for the purpose of the Project and all negotiations and discussions relating to the Project are confidential between the Parties and shall not be disclosed to any third-party, except as is necessary to facilitate the Project, and upon the Party obtaining same undertaking from the third-party to whom such information shall be disclosed.
3.2.	The Parties irrevocably agree, and guarantee each other that no Party shall, directly or indirectly interfere with, circumvent or attempt to circumvent, avoid, by-pass, or obviate the interests of the other Parties, or relationship with any third party, or initiate any business or transactional relationships that by-passes any Party or with any third party revealed or introduced by the Parties to each other in connection with any past, on-going or future transaction. 
3.3.	The Parties acknowledge that damages may be inadequate compensation for any breach of this JVA and each Party agrees that a Party shall be entitled to seek an injunction, specific performance, or similar remedy against any conduct or threatened conduct which is or would be a breach of this JVA.

4	 DURATION  
4.1. 	This JVA will become effective once both signed by the Parties sign it. It will continue in force from the date of execution for an initial period of ____5a subject to renewal for such further terms as may be mutually agreed by the Parties in writing. 
       5.    TERMINATION
       5.1 Either party shall be entitled to terminate this JVA by notice to the other party at any time in the event that the other party commits a material breach of any of the obligations contained herein; and in the case where such a breach is capable of rectification, the breaching party fails to rectify the same within sixty (60) days after written notice is given demanding such rectification by the other party;
             5.2 The Joint Venture also can come to an end upon the happening of all or any of the provisions of law with respect to a business/company getting dissolved. In that case, the Joint Venture shall terminate and be wound up. The Joint Venture property shall thereupon be sold (and any of the parties may be a purchaser of all or any portion thereof), its liabilities paid or provided for, and the remaining assets distributed to and among the parties pro rata in accordance with their capital accounts, without undue delay. Any remaining profits shall be distributed pro rata in accordance with the Parties' capital accounts as they stood prior to the making of the distribution required under terms of the JVA. 

6.  GOVERNING LAW/RESOLUTION OF DISPUTES
6.1. 	This Agreement shall be construed and governed by the laws of the Federal Republic of Nigeria.
6.2. 	If there is a dispute, controversy or claim between the Parties, the Parties shall try to reach agreement amicably by direct negotiations. If no agreement can be reached within thirty (30) days, the dispute, controversy or claim shall be referred to Arbitration in accordance with the Arbitration and Conciliation Act Cap A[xx] Laws of the Federation of Nigeria 2004.  A dispute shall be deemed to have arisen when either Parties notifies the other Party in writing to that effect.  The Language of Arbitration shall be English.

7. 	NOTICES	
Any notices or demands permitted or required hereunder shall be in writing sent by certified or registered mail, postage prepaid, addressed to each party at the address set forth below 
      Notices to Partner 1the company shall be addressed to:
       _______6a

      Notices to Partner 2 the Co-venturer shall be addressed to:
       ________6b

   8.    ASSIGNMENT AND SUBCONTRACTING
The Parties shall not assign or transfer all or any part of their respective rights under this 	JVA or delegate their performance under this JVA without the prior written approval of the other Party, and any assignment, transfer or delegation that is made without such prior written approval shall constitute a breach of this JVA.

     9.   SEVERANCE
If any clause or part of a clause in this JVA is found by a court, arbitrator or other authority of competent jurisdiction to be void or unenforceable, that clause or part of a clause is to be deemed deleted from this JVA and the remaining clauses and parts thereof shall continue in full force and effect.  If necessary, the Parties shall in such event seek to agree upon a valid and enforceable clause or part of a clause to replace the clause or part of a clause found to be void and unenforceable.   

       10. COUNTERPARTS
This JVA may be executed in any number of counterparts, but shall not take effect until each Party has executed at least one counterpart.  Each counterpart shall constitute an original but all the counterparts together shall constitute a single agreement.	

    11.  MISCELLANEOUS MATTERS
11.1.    This JVA shall be identified as the parent document of any subsequent agreement executed between the Parties and between the Parties and any other third party wishing to participate in the Project or to render any service to either of the Parties in fulfilment of any of its obligations under this JVA. Such further agreements concerning the Project shall be for the purpose of providing specific details and commitments of the Parties with respect to the Project.
11.2. 	This JVA can only be modified by a written agreement between the Parties.
11.3. 	Neither Party has the authority to make decisions for the other, or to enter into obligations on behalf of the other.
11.4. 	In executing this JVA, the Company and the Co-venturereach Party warrants that they have the necessary financial and technical resources to fulfill their obligations under this JVA, and each Party of the Company and Co-venturer individually represents that it has the necessary corporate approvals (where applicable) to execute this JVA, and perform the obligations described herein.

IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the day and year first above written.

THE COMMON SEAL of the within named (repeat reference 1a) Company is hereby affixed in the presence of 



____________________________		                   ____________________________		
Director					                     Secretary



THE COMMON SEAL of the within named (repeat reference 2a) Co-venturer is hereby affixed in the presence of 
	 


____________________________		                   ____________________________		
Director					                     Secretary



References:
1a. What is the name of the first Joint Venture PartnerCompany?
1b. Under the laws of what Country is theis first Joint Venture Partner Company registered?
1c. What is the registered address of the first Joint Venture PartnerCompany?
2a. What is the name of the Co-venturer Companysecond Joint Venture Partner?
2b. Under the laws of what Country is this first Joint Venture Partner registered?
2c. What is the address of the Co-Venturersecond Joint Venture Partner?
3a. Please Input provide a brief description of the Companyfirst Joint Venture Partner 
3b. Please input provide a brief description of the Co-venturersecond Joint Venture Partner 
3ba. What transaction or business are the parties desirous of undertaking jointly? 
3c What is the name of the entity through which the parties will run the joint ventureUnder what name shall the business of the Joint Venture be run?
3d. What is the Ppercentage of shares held by the first Company in the Joint Venture Partner?
3e. What is the percentage of shares held by the second co-venturer in the Joint Venture Partner?
4a. What is the business for which the Joint venture is being formed?
5a. What is the duration of this Joint Venture Agreement?
6a. Please input in letter formatprovide  the details (in the format below) of the person to receive notices for the company first Joint Venture Partner will be sent to (Name, designation, address, email)
Name: John Peter 
Designation: Director 
Address:13 Awolowo Way, Lekki, Lagos 
Email: jpeter@gmail.com
6b. Please provide input in letter format the details (in the format below) of the person to receive notices for the co-venturersecond Joint Venture Partner will be sent to(Name, designation, address, email)
Name: John Peter 
Designation: Director 
Address:13 Awolowo Way, Lekki, Lagos 
Email: jpeter@gmail.com






DESCRIPTION
A shareholder may issue a letter of relinquishment of shares where the shareholder decides to return the shares held back to the company. 
A letter of relinquishment is written by the shareholder of a company expressing the desire to surrender the shares held in the company. 

A letter of relinquishment is often referred to interchangeably as a letter of surrender.

FREQUENTLY ASKED QUESTIONS (FAQs)
Why would I relinquish my shares?
A shareholder may voluntarily surrender shares at any time the shareholder so desires. However, relinquishment of such shares does not absolve the shareholder of any existing and unpaid liabilities in respect of the shares. 
Is �relinquishment� different from �forfeiture�?
Yes, a relinquishment of shares is different from a forfeiture of shares. The difference is that a relinquishment is voluntary; while a forfeiture is compulsory. 
Forfeitures is most often compelled by the company when shareholder has failed to honour calls for payment on the shares. 

DRAFT 
______1______
______2______

			LETTER OF RELINQUISHMENT OF SHARES
I _______3_______ of _____4______ by this letter relinquish ______5_______ of the _____6_____ shares which I currently hold in (repeat ref 1) [hereinafter referred to as the Company], and all incidental rights and benefits. 
Upon request, I will execute and deliver to the Corporate Affairs Commission any and all documents so deemed by the Company as necessary and desirable to effect the relinquishment of the shares. 
I warrant that I am the true owner of the shares, and am entitled to the rights and benefits accruing thereto and acknowledge that to the best of my knowledge; no adverse rights, charges, or obligations exist in connection with the shares which shall prejudice the title which I hereto relinquish; and undertake to indemnify the Company in the event that any information herein supplied is untrue, inaccurate or misleading. 
I fully understand that the relinquishment of shares in the Company is irrevocable and may not be withdrawn under any circumstances.


Thank you.
Yours faithfully,

______________
[repeat ref 3]

REFERENCES
1.	What is the name of the company?
2.	What is the address of the company? 
3.	What is the name of the shareholder?
4.	What is the address of the shareholder?
5.	How many shares are you giving up? E.g. 3,000 or 200 or all
6.	What the type of shares? E.g. Ordinary shares or Preference shares or Founders shares. 





DESCRIPTION
A letter of resignation serves as a written notification of a person�s intent to leave a position currently held, such as an office, employment or commission. The letter is usually sent by an employee to the employer signifying intention to leave the employment. 

FREQUENTLY ASKED QUESTION�s (FAQ�s)
How do I determine the length of the notice period? 
The notice period for your resignation is usually contained in the employment contract; the contract stipulates how long the notice should be for. 
However, where your contract does not provide a notice period for resignation, you should consider giving a period long enough to allow you and your employer finalise a seamless transition from the organization. In which case minimum of two to four weeks will suffice depending on your role. 
Do I need to reveal my future plans?
No you do not. It is desirable that the letter of resignation strikes a balance between its formal nature and the level of cordiality that exists between you and your employer. 
When can I stop going to work? 
Your last day on the job will be that very last day of the notice period. It is usually indicated as the effective date in your letter; after that day you may not need to go in to the workplace. 

 





RESIGNATION LETTER DRAFT
_____1a_____
_____1b_____


_____1_____
_____2_____ (the Company)
Dear Sir/Ma,

 			LETTER OF RESIGNATION
Please accept this letter as notice of my resignation as _____4_____ at the Company, effective ____5____ from this date (effective date); which shall be my last day of work.
Thank you for the support and the opportunities that you have provided me during the last ____6____. I have truly enjoyed my tenure with the Company, and am more than grateful for the encouragement you have given me in pursuing my professional and personal growth objectives.
If I can be of any assistance during this transition in order to facilitate the seamless passing of my responsibilities to my successor, please let me know. I would be glad to help however I can.

Yours faithfully,


_____________
NAME:






REFERENCES 
1a. What is the Employee�s name?
1b. What is the Employee�s address? 
(1)	On what is the date of the letter written?
(2)	What is the name of the employer (company)?
(3)	What is your name?
(4)	What is your job position?  
(5)	How many weeks� notice are you giving? E.g. 14 days or 2 weeks or 3 months
(6)	How long have you worked for the organization? E.g. 30 days or 2 months or 3 years






DESCRIPTION
This Agreement contemplates the use of collateral as security for the loan. 
A Loan Agreement is a written contract between two parties: a lender and a borrower, for the lender to advance a money sum which will be repaid by the Borrower (sometimes with interest) at an agreed period. 
While the existence of the Loan Agreement itself does not necessarily guarantee that the borrower will repay the money back in a timely manner, the presence of such a document can help to support the lender's claim if legal action has to be taken to reclaim any money.
FREQUENTLY ASKED QUESTIONS (FAQs)
When should I use a loan agreement?
In any of the following circumstances, you should consider using a Loan Agreement to protect your interests:
�	If you borrow money from an individual lender or a business and you want to document in detail the terms and conditions of the loan in writing.
�	If you plan to loan any amount of money to another individual or entity and you want to create a robust formal record of the transaction and specify the terms of repayment.
�	If you are either the borrower or lender in a loan transaction which involves interest on the principal amount of the loan. 
What is the difference between a promissory note and a loan agreement?
A promissory note is a simple document that is not as complex as a loan agreement. A promissory note is used for small amount of loans and is essentially a formal contract between parties that contains a promise to pay a certain amount of money on demand at a specified time or over a period of time in future. 
Unlike a promissory note, a loan agreement imposes obligations on both parties, making it essential for both parties to sign. A promissory note on the other hand may be executed only by the borrower, as it is in essence a �note� confirming indebtedness. 
Deciding on which document to use will be based on the circumstances of the loan and the relationship between the parties. 


What does a loan agreement contain?
When creating a Loan Agreement, it is important to include certain information. To make sure the document is complete, you should include the following: 
�	Full details of the lender including the name and address 
�	Full details of the borrower including the name and address 
�	Principal amount of the loan 
�	Any interest accrued on the loan and the rate of interest 
�	Repayment date/schedule; so that there is no question as to how and when the borrower must repay the principal amount of the loan and interest (if applicable) to the lender.
�	Any additional stipulations relating to the loan such as prepayment or default of the loan
Must there be a collateral?
No. Loans can be validly granted without a collateral security. However, it is advisable that Lenders demand collateral to serve as a guarantee for the borrower�s repayment; and to aid the lender in recovery of the loaned sum
Who should sign the Agreement?
The Agreement should be executed by the Lender and the Borrower. Since the parties are companies; the Agreement must be signed for each company by: either two directors or a director and a secretary.  

DRAFT
This LOAN AGREEMENT (hereinafter referred to as the Agreement) is made this ______day of ____________20___ between ___1____ a company incorporated and existing under the laws of _____2_____ with RC Number _____3_____ and its office address at _____4_____ (hereinafter referred to as the Lender, which expression shall include officers, representatives, subsidiaries, affiliates and designates) and _____5_____ a company incorporated and existing under the laws of ______6_____ with RC Number ______7______ having its office address at ______8_____ (hereinafter referred to as the Lender, which expression shall include officers, representatives, subsidiaries, affiliates and designates).
The Lender and the Borrower are referred to individually as Party and collectively as Parties. 
WHEREAS
A.	The Borrower has requested to borrow and the Lender has consented to lend the sum of ____9_____(___10___ only),(hereinafter referred to as the Principal Amount) 
B.	In consideration of the Principal Amount (the receipt and sufficiency of which the Borrower acknowledges) and the mutual covenants contained herein, the Parties agree to be bound by the provisions of this Agreement

THE PARTIES AGREE AS FOLLOWS:
1.	DEFINITION AND INTERPRETATION
For the purposes of this Agreement, unless the context otherwise requires, the following words and phrases shall have the meanings set out below:

Agreement means this Loan Agreement 
Guaranteed Debt means all sums as described in Clause 3 of this Agreement
Principal Amount means all that sum of money advanced by the Lender to the Borrower; more particularly stated in Recital A above, giving rise to this Agreement.
Total Amount means all such amount as is and/or becomes due and payable under this Agreement 

2.	PAYMENT TERMS 
Principal 
A.	Subject to the provisions of this Agreement, the Lender has agreed to advance the Principal Amount to the Borrower, on _______11______ (hereinafter referred to as the Advance Date) 
Term
B.	This Agreement shall commence on the Advance Date and remain in force for a period of _____13______ (hereinafter referred to as the Term) during which the Borrower shall be unequivocally obligated to complete full repayment of the Guaranteed Debt under this Agreement. 
C.	Provided that:
i.	the Lender shall reserve the right to extend the Term of this Agreement, and any such extension shall be construed as within the Term of this Agreement and not be or be so construed as a breach or variation of the terms of this Agreement;
ii.	neither the Term of this Agreement nor the obligations of the Borrower herein shall be deemed expired until the Borrower completes repayment of all the Guaranteed Debt as aggregated under this Agreement or the Lender so admits in a written document duly executed by the Parties to this Agreement.
Repayment
D.	Repayment as due in respect of this Agreement shall be made on a _____14_____ basis, in the amount of ____15____ paid on ____16____ during the Term. 
E.	Provided that upon written consent of the Lender, Parties may agree in writing to vary the repayment dates and schedule under this Agreement. 
Interest 
F.	The Principal Amount shall bear Interest at the rate of_____17_____ percent per annum; 
Delivery
G.	Payments(hereinafter referred to as Delivery Terms) under this Agreement shall be made into the bank account of the Lender as follows:
Bank Name: _____18_____
Account Number: ____19____
Account Name: _____20______ 
Provided that the Lender may change the Delivery Terms as under this Clause 2G by giving notice of same to the Borrower. 
Security/collateral
H.	The Lender shall collect ____21____ as Collateral for the loaned sum.
I.	Provided that ownership of the Collateral shall not be deemed as vested in the Lender until so decided in any Event of Default by a court of competent jurisdiction.
Prepayments
J.	In the event that the Borrower prepays the Loan in part, the Borrower agrees to continue to make the regularly scheduled payments until all amounts due under this Agreement are paid.

Acceleration 
K.	At any time the Borrower is in default as under Clause 4 below, the Lender may require the Borrower to pay the full amount due under this Agreement, provided that in the event that the Lender does not, upon the Borrower�s default, require the Borrower to pay immediately, the Lender shall still have the right to do so at a later time.
Fees and charges for default
L.	In the event that the Lender has not received the full amount of any payments due at such period as specified in Clause 2 above; the Borrower shall owe a fee to the Lender in the sum of ______22_____ for each default (hereinafter referred to as Default Fee), due immediately in addition to the previously owed balance; and interest of ____23____ (hereinafter referred to as Default Interest) per annum shall be charged on such Default Fee.  
M.	If the owed balance and the Default fee is not paid promptly and immediately, the Lender may without prejudice to Clause 4 declare same as an Event of Default.

3.	GUARANTEED DEBT 
The Borrower agrees that the Guaranteed Debt shall consist of all amounts owed by the Borrower under this Agreement, including, but not limited to:
i.	The Principal amount; as specified in Recital A 
ii.	Interest on Principal Amount; as specified in Clause 2F 
iii.	Default  Fees; as specified in Clause 2L
iv.	Default Interest per annum; as specified in Clause 2L
v.	Amounts owed as a consequence of a declared Event of Default and acceleration by the Lender; and
All such sum(s) as are Guaranteed Debt shall be payable to the Lender in the manner as specified in 2G

4.	EVENT OF DEFAULT 
A.	�Event of Default� means the occurrence of any of the following events:

Failure to pay: 
The Borrower does not pay, at or before the due time, on the due date and in the specified manner, the Guaranteed Debt payable under this Agreement. 

Breach of agreement:
The Borrower is in breach of or fails to comply with any terms contained in this Agreement. 

Untrue warranty: 
Any representation, warranty or statement made, or deemed to be made by the Borrower in this Agreement or any accounts or opinion furnished in connection thereof is proved to be untrue in any material respect when made, or deemed to be made.


B.	Provided that any of the above listed circumstances/events shall not amount to an Event of Default if same is satisfactorily remedied (with the consent, and in the opinion of the Lender) within 7 days of its occurrence; or such other period as the Parties may so decide.  

C.	The Lender shall be authorized to procure the services of one or more collection agencies to recoup a whole or part of the Guaranteed Debt; provided that the Borrower is afforded the remedy period and adequate notice as described in Clause 2B above.

Notice
D.	In the event that the Borrower is in default, the Lender may send the Borrower a notice stating: the default of the Borrower and if the due amount is not paid by a certain date, the Lender may require the Borrower to pay immediately the whole or part of the Guaranteed Debt which is still owed. 
Provided that such notice and the time period for sending same shall remain in the sole discretion of the Lender.  

Payment of lenders cost and expenses
E.	In the event that the Lender has required the Borrower to pay (as described above) the whole or part of the Guaranteed Debt, the Lender shall have the right to be reimbursed for all its costs and expenses to the extent not prohibited by the governing law, including but not limited to, cost incurred in the procurement of collection agencies and reasonable attorney�s fees.
  
5.	REPRESENTATIONS AND WARRANTIES 
A.	The Borrower hereto represents and warrants that:
i.	It has full power to enter into, exercise its rights and perform its obligations contemplated under this Agreement.
ii.	No Event of Default has occurred and/or is continuing under any loan agreement to which it is a party. 
iii.	This Agreement constitutes its valid and legally binding obligations, enforceable against it in accordance with their respective terms; 
iv.	It is the true, rightful and beneficial owner of the Collateral pledged in respect of this Agreement (as in clause 2H above) and has unfettered right to charge same in fulfilment of its obligations under this Agreement, free from all encumbrances. 
v.	No encumbrance(s) exists over or in respect of the whole or any part of the Collateral
vi.	It acknowledges that the Lender has entered into this Agreement and agreed to provide the Principal Amount in reliance on the representations and warranties made in this clause 5A. 

B.	The Lender represents and warrants as follows: 
i.	It is duly authorized to execute and deliver this Agreement and any other documentation relating to or arising out of this Agreement to which it is a Party; and executing; and delivering same is not in contravention with any law, any judgment, any rule or regulation imposed by any authority under the Governing Law.
ii.	It is capable of performing all such actions, duties and obligations imposed hereunder 

C.	Each Party to this Agreement warrants and represents as follows: 
i.	It has all requisite resources, power and authority to execute and perform all duties, roles and obligations related hereto.
ii.	The execution, delivery and performance of this Agreement is not in conflict with any law(s), regulation(s), code(s), agreement(s), judgment(s) to which it is bound.  
iii.	All information provided in this Agreement are true, accurate and complete in every respect, and are not misleading. 
iv.	The representations and warranties herein will be taken to be repeated on each day of the Term of this Agreement, by reference to the facts and circumstances then existing. 

6.	UNDERTAKINGS
Until all Guaranteed Debt actually and contingently owed under this Agreement have been repaid in full, the Borrower shall: 
A.	Not create or permit to exist any security interest over all or any of its property or assets unless same has previously been notified to and approved by the Lender in writing;
B.	Give the Lender prompt notice of the occurrence of any Event of Default or potential Event of Default.
C.	Not transfer, sell or otherwise alienate its assets to any person otherwise than in the ordinary course of business and for proper market value in money or money�s worth;
D.	Not otherwise than pursuant to this Agreement, or other arrangements with the Lender borrow money without the consent of the Lender. 

7.	NOTICES 
A.	All notices (including the notices as required under clauses 4B and 4D) related or required hereunder to be given to any Party under this Agreement shall be sent to the Party at its address first mentioned herein or such other address as the Party may hereafter communicate in writing. 
B.	All notices shall be in writing and may be delivered physically or sent by registered mail or certified mail or electronic mail. The day of mailing of any such notice shall be deemed the date of the giving thereof. 
C.	In respect of electronic mail;
The Borrower shall be communicated with via _____24_____
The Lender shall be communicated with via _____25______
Provided that either Party may change its address as above stated by giving notice of same to the other Party.

8.	WAIVER 
None of the requirements of this Agreement shall be considered waived unless waived in writing by the Party concerned. The waiver by any Party of one or more of its rights hereunder shall not be deemed or construed as a continuing waiver on the part of such Party as to prohibit such Party from demanding the performance of any of the other duties and obligations owed it in accordance with the provisions of this Agreement. 

9.	SEVERABILITY 
In the event that any clause or provision in this Agreement is held invalid or unenforceable by a court of competent jurisdiction in Nigeria, such invalidity shall not affect the validity or operation of any other clause or provision; and such invalid clause or provision shall be deemed to be severed from this Agreement. 

10.	ALTERATION AND MODIFICATION 
A.	This Agreement constitutes the entire agreement between the Parties hereto as regarding the subject matter of this Agreement, no terms or provision of this Agreement shall be varied or modified by any prior or subsequent statement, conduct or act of the Parties save and except that the Parties may amend this Agreement by written instruments specifically referring to and executed in the same manner as this Agreement. Any such instrument shall be signed by the Parties through its duly appointed representatives. 
B.	This Agreement shall supersede any prior agreement between the Parties regarding the subject matter of this Agreement.

11.	GOVERNING LAW 
The implementation and enforcement of this Agreement shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement. 

12.	DISPUTE RESOLUTION
Parties shall use their best efforts to settle any disputes arising between them amicable within 30 days. 
A.	However, where the Parties are unable to reach an amicable resolution, such dispute, controversy or claim arising out of, or relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration conducted by a sole arbitrator in accordance with the Arbitration and Conciliation Act Cap A18 Laws of the Federal Republic of Nigeria 2004.  

IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS AGREEMENT IN THE MANNER BELOW, ON THE DATE INDICATED ABOVE: 
The Common seal of (repeat ref 1) is hereunto affixed in the presence of:




__________________						____________________
NAME:								NAME:			
DESIGNATION:							DESIGNATION:




					
The Common Seal of (repeat ref 5) was hereunto affixed in the presence of:





_____________________					____________________
NAME:								NAME:			
DESIGNATION:							DESIGNATION:

REFERENCES 
1.	What is the Lender�s name?
2.	In what country was the Lender incorporated?
3.	What is the RC Number of Lender?
4.	What is the office address of the Lender? 
5.	What is the Borrower�s name? 
6.	In what country was the Borrower incorporated?
7.	What is the RC Number of the Borrower?
8.	What is the office address of the Borrower? 
9.	How much is the loan amount (in figures)?
10.	How much is the loan amount (in words)?
11.	On what date will the Lender give the money to the Borrower?
12.	
13.	What is the duration of this Loan Agreement? E.g. 30 days or 6 months or 3 years
14.	At what intervals will the borrower repay the Loan? E.g. Daily or Monthly or Yearly  
15.	How much is each repayment?
16.	On what date will the repayments be made? E.g. 27th of Every month or in the 9th Month of each year or before 3pm every day
17.	How much interest is charged on the loan? N.B: This is the per annum rate. 
18.	In what bank should repayments be made? E.g. GTBank or Zenith Bank or Access Bank etc. 
19.	To what Account Number?
20.	What is the Account Name? 
21.	Give a full description of the Borrower�s collateral. A black 2004 Toyota Camry with registration number C606 PXX , a 5,000 kva Mikano generator, a Suzuki Gixxer 2017 Motorcycle or An iphone 7 mobile phone etc.
22.	How much will be charged for late repayment (Default Fee)? N.B If there are not late payment fees, please input 0. 
23.	How much interest will be charged on the Default Fee?
24.	What is the email address of the Borrower? 
25.	What is the email address of the Lender?






DESCRIPTION
A Loan Agreement is a written contract between two parties: a lender and a borrower, for the lender to advance a money sum which will be repaid by the Borrower (sometimes with interest) at an agreed period. 
While the existence of the Loan Agreement itself does not necessarily guarantee that the borrower will repay the money back in a timely manner, the presence of such a document can help to support the lender's claim if legal action has to be taken to reclaim any money.
FREQUENTLY ASKED QUESTIONS (FAQs)
When should I use a loan agreement?
In any of the following circumstances, you should consider using a Loan Agreement to protect your interests:
�	If you borrow money from an individual lender or a business and you want to document in detail the terms and conditions of the loan in writing.
�	If you plan to loan any amount of money to another individual or entity and you want to create a robust formal record of the transaction and specify the terms of repayment.
�	If you are either the borrower or lender in a loan transaction which involves interest on the principal amount of the loan. 
What is the difference between a promissory note and a loan agreement?
A promissory note contains standard provisions which revolve around the money lent and the process of repayment; a loan agreement is a largely more complex document. 
Also, while a loan agreement requires both parties to sign, a promissory note may be executed only by the maker, as it is in essence a �note� confirming indebtedness. 
Deciding on which document to use will be based on the circumstances of the loan and the relationship between the parties. 
What does a loan agreement contain?
When creating a Loan Agreement, it is important to include certain information. To make sure the document is complete, you should include the following: 
�	Full details of the lender including the name and address 
�	Full details of the borrower including the name and address 
�	Principal amount of the loan 
�	Any interest accrued on the loan and the rate of interest 
�	Repayment date/schedule; so that there is no question as to how and when the borrower must repay the principal amount of the loan and interest (if applicable) to the lender.
�	Any additional stipulations relating to the loan such as prepayment or default of the loan
Must there be a collateral?
No. Loans can be validly granted without a collateral security. However, it is advisable that Lenders demand collateral to serve as a guarantee for the borrower�s repayment; and to aid the lender in recovery of the loaned sum
Who should sign the Agreement?
The Agreement should be executed by the Lender and the Borrower. 

DRAFT
This Loan Agreement (hereinafter referred to as the Agreement) is made this ______day of ____________20___ between ___1____ of _____2_____ (hereinafter referred to as the Lender, which expression shall include heirs, assigns, successors in title, personal representatives, administrators or executors) and _____3_____ of ______4_____ (hereinafter referred to as the Lender, which expression shall include heirs, assigns, successors in title, personal representatives, administrators or executors).
The Lender and the Borrower are referred to individually as Party and collectively as Parties. 

WHEREAS
A.	The Borrower has requested to borrow and the Lender has consented to lend the sum of ____5_____(___6___ only),(hereinafter referred to as the Principal Amount) 
B.	In consideration of the Principal Amount (the receipt and sufficiency of which the Borrower acknowledges) and the mutual covenants contained herein, the Parties agree to be bound by the provisions of this Agreement;

THE PARTIES AGREE AS FOLLOWS:
1.	DEFINITION AND INTERPRTATION
For the purposes of this Agreement, unless the context otherwise requires, the following words and phrases shall have the meanings set out below:

Agreement means this Loan Agreement 
Guaranteed Debt means all sums as described in Clause 3 of this Agreement
Loan Agreement means this Agreement. 
Principal Amount means all that sum of money advanced by the Lender to the Borrower; more particularly stated in Recital A above, giving rise to this Agreement.
Total Amount means all such amount as is and/or becomes due and payable under this Agreement 

2.	PAYMENT TERMS 
Principal 
A.	Subject to the provisions of this Agreement, the Lender has agreed to advance the Principal Amount to the Borrower, on _______7______ (hereinafter referred to as the Advance Date), 
Term
B.	This Agreement shall commence on the Advance Date and remain in force for a period of _____9______ (hereinafter referred to as the Term) during which the Borrower shall be unequivocally obligated to complete full repayment of the Guaranteed Debt under this Agreement. 
C.	Provided that:
i.	the Lender shall reserve the right to extend the Term of this Agreement, and any such extension shall be construed as within the Term of this Agreement and not be or be so construed as a breach or variation of the terms of this Agreement;
ii.	neither the Term of this Agreement nor the obligations of the Borrower herein shall be deemed expired until the Borrower completes repayment of all the Guaranteed Debt as aggregated under this Agreement or the Lender so admits in a written document duly executed by the Parties to this Agreement.
Repayment
D.	Repayment as due in respect of this Agreement shall be made on a _____10_____ basis, in the amount of ____11____ paid on ____12____ during the Term. 
E.	Provided that upon written consent of the Lender, Parties may agree in writing to vary the repayment dates and schedule under this Agreement. 
Interest 
F.	The Principal Amount shall bear Interest at the rate of_____13_____ percent per annum; 
Delivery
G.	Payments(hereinafter referred to as Delivery Terms) under this Agreement shall be made into the bank account of the Lender as follows:
Bank Name: _____14_____
Account Number: ____15____
Account Name: _____16______ 
Provided that the Lender may change the Delivery Terms as under this Clause 2H by giving notice of same to the Borrower. 
Security/collateral
H.	The Lender shall collect ____17____ as collateral for the loaned sum (hereinafter referred to as the Collateral Security).
I.	Provided that ownership of the Collateral Security shall not be deemed as vested in the Lender until so decided in any Event of Default by a court of competent jurisdiction.
Prepayments
J.	In the event that the Borrower prepays the Loan in part, the Borrower agrees to continue to make the regularly scheduled payments until all amounts due under this Agreement are paid.

Acceleration 
K.	At any time the Borrower is in default as under Clause 4 below, the Lender may require the Borrower to pay the full amount due under this Agreement, provided that in the event that the Lender does not, upon the Borrower�s default, require the Borrower to pay immediately, the Lender shall still have the right to do so at a later time.
Fees and charges for default
L.	In the event that the Lender has not received the full amount of any payments due at such period as specified in Clause 2 above; the Borrower shall owe a fee to the Lender in the sum of ______18_____ for each default (hereinafter referred to as Default Fee), due immediately in addition to the previously owed balance; and interest of ____19____ (hereinafter referred to as Default Interest) per annum shall be charged on such Default Fee.  
M.	If the owed balance and the Default fee is not paid promptly and immediately, the Lender may without prejudice to Clause 4 declare same as an Event of Default.

3.	GUARANTEED DEBT 
The Borrower agrees that the Guaranteed Debt shall consist of all amounts owed by the Borrower under this Agreement, including, but not limited to:
i.	The Principal amount; as specified in Recital A 
ii.	Interest on Principal Amount; as specified in Clause 2G 
iii.	Default  Fees; as specified in Clause 2M
iv.	Default Interest per annum; as specified in Clause 2M
v.	Amounts owed as a consequence of a declared event of default and acceleration by the Lender; and
All such sum(s) as are Guaranteed Debt shall be payable to the Lender in the manner as specified in 2H

4.	EVENT OF DEFAULT 
A.	�Event of Default� means the occurrence of any of the following events:


Failure to pay: 
The Borrower does not pay, at or before the due time, on the due date and in the specified manner, the Guaranteed Debt payable under this Agreement. 
Appointment of liquidator or administrator: 
A liquidator, provisional liquidator, receiver, manager, receiver and manager, trustee, administrator, or similar officer is appointed in respect of the Borrower or any of its Assets; by reason of the Borrower�s inability to pay its debts (including but not limited to the Guaranteed Debt as incurred under this Agreement) as and when they fall due or the Borrower�s initiation or consent to the initiation of proceedings under any applicable bankruptcy, or making an assignment for the benefit of, or entering into any compromise with its creditors;

Breach of agreement:
The Borrower is in breach of or fails to comply with any terms contained in this Agreement. 

 

Untrue warranty: 
Any representation, warranty or statement made, repeated or deemed to be made or repeated in this Agreement or any accounts or opinion furnished in connection thereof is proved to be untrue in any material respect when made, repeated or deemed to be made.


B.	Provided that any of the above listed circumstances/events shall not amount to an Event of Default if same is satisfactorily remedied (with the consent, and in the opinion of the Lender) within 7 days of its occurrence; or such other period as the Parties may so decide.  

C.	The Lender shall be authorized to procure the services of one or more collection agencies to recoup a whole or part of the Guaranteed Debt; provided that the Borrower is afforded the remedy period and adequate notice as described in Clause 2B above.

Notice
D.	In the event that the Borrower is in default, the Lender may send the Borrower a notice stating: the default of the Borrower and if the due amount is not paid by a certain date, the Lender may require the Borrower to pay immediately the whole or part of the Guaranteed Debt which is still owed. 
Provided that such notice and the time period for sending same shall remain in the sole discretion of the Lender.  

Payment of lenders cost and expenses
E.	In the event that the Lender has required the Borrower to pay (as described above) the whole or part of the Guaranteed Debt, the Lender shall have the right to be reimbursed for all its costs and expenses to the extent not prohibited by the governing law, including but not limited to, cost incurred in the procurement of collection agencies and reasonable attorney�s fees.
  
5.	REPRESENTATIONS AND WARRANTIES 
A.	The Borrower hereto represents and warrants that:
i.	It has full power to enter into, exercise its rights and perform its obligations contemplated under this Agreement.
ii.	No Event of Default has occurred and/or is continuing under any Loan Agreement to which it is a party. 
iii.	This Agreement constitutes its valid and legally binding obligations, enforceable against it in accordance with their respective terms; 
iv.	It is the true, rightful and beneficial owner of the Collateral Security pledged(if any) in respect of this Agreement (as in clause 2F above) and has unfettered right to charge same in fulfilment of its obligations under this Agreement, free from all encumbrances. 
v.	No encumbrance(s) exists over or in respect of the whole or any part of the Collateral Security
vi.	It acknowledges that the Lender has entered into this Agreement and agreed to provide the Principal Amount in reliance on the representations and warranties made in this clause 5A. 

B.	The Lender represents and warrants as follows: 
i.	It is duly authorized to execute and deliver this Agreement and any other documentation relating to or arising out of this Agreement to which it is a Party; and executing; and delivering same is not in contravention with any law, any judgment, any rule or regulation imposed by any authority under the Governing Law.
ii.	It is capable of performing all such actions, duties and obligations imposed hereunder 

C.	The Parties to this Agreement warrant and represent as follows: 
i.	All information provided in this Agreement are true, accurate and complete in every respect, and are not misleading. 
ii.	The representations and warranties herein will be taken to be repeated on each day of the Term of this Agreement, by reference to the facts and circumstances then existing. 

6.	UNDERTAKINGS
Until all Guaranteed Debt actually and contingently owed under this Agreement have been repaid in full, the Borrower shall: 
A.	Not create or permit to exist any security interest over all or any of its property or assets unless same has previously been notified to and approved by the Lender in writing;
B.	Give the Lender prompt notice of the occurrence of any Event of Default or potential Event of Default.
C.	Not transfer, sell or otherwise alienate its assets to any person otherwise than in the ordinary course of business and for proper market value in money or money�s worth;
D.	Not otherwise than pursuant to this Agreement, or other arrangements with the Lender borrow money without the consent of the Lender. 

7.	NOTICES 
A.	All notices (including the notices as required under clauses 4B and 4D) related or required hereunder to be given to any Party under this Agreement shall be sent to the Party at its address first mentioned herein or such other address as the Party may hereafter communicate in writing. 
B.	All notices shall be in writing and may be delivered physically or sent by registered mail or certified mail or electronic mail. The day of mailing of any such notice shall be deemed the date of the giving thereof. 
C.	In respect of electronic mail;
The Borrower shall be communicated with via _____20_____
The Lender shall be communicated with via _____21______
Provided that either Party may change its address as above stated by giving notice of same to the other Party.

8.	WAIVER 
None of the requirements of this Agreement shall be considered waived unless waived in writing by the Party concerned. The waiver by any Party of one or more of its rights hereunder shall not be deemed or construed as a continuing waiver on the part of such Party as to prohibit such Party from demanding the performance of any of the other duties and obligations owed it in accordance with the provisions of this Agreement. 

9.	SEVERABILITY 
In the event that any clause or provision in this Agreement is held invalid or unenforceable by a court of competent jurisdiction in Nigeria, such invalidity shall not affect the validity or operation of any other clause or provision; and such invalid clause or provision shall be deemed to be severed from this Agreement. 



10.	ALTERATION AND MODIFICATION 
A.	This Agreement constitutes the entire agreement between the Parties hereto as regarding the subject matter of this Agreement, no terms or provision of this Agreement shall be varied or modified by any prior or subsequent statement, conduct or act of the Parties save and except that the Parties may amend this Agreement by written instruments specifically referring to and executed in the same manner as this Agreement. Any such instrument shall be signed by the Parties through its duly appointed representatives. 
B.	This Agreement shall supersede any prior agreement between the Parties regarding the subject matter of this Agreement.

11.	GOVERNING LAW 
The implementation and enforcement of this Agreement shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement. 

12.	DISPUTE RESOLUTION
Parties shall use their best efforts to settle any disputes arising between them amicable within 30 days. 
A.	However, where the Parties are unable to reach an amicable resolution, such dispute, controversy or claim arising out of, or relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration conducted by a sole arbitrator in accordance with the Arbitration and Conciliation Act Cap A18 Laws of the Federal Republic of Nigeria 2004.  

IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS AGREEMENT IN THE MANNER BELOW, ON THE DATE INDICATED ABOVE: 
Signed by the within named Lender:




____________________						(repeat ref 1)
SIGNATURE							    NAME:
					
Signed by the within named Borrower:





__________________						(Repeat ref 3)
SIGNATURE							    NAME
REFERENCES 
1.	What is the name of the Lender?
2.	What is the address of the Lender? 
3.	What is the name of the Borrower? 
4.	What is the address of the Borrower? 
5.	How much is the loan amount (in figures)?
6.	How much is the loan amount (in words)?
7.	On what date will the Lender give the money to the Borrower?
8.	
9.	What is the duration of this Loan Agreement? E.g. 30 days or 6 months or 3 years
10.	At what intervals will the borrower repay the Loan? E.g. Daily or Monthly or Yearly  
11.	How much is each repayment?
12.	On what date will the repayments be made? E.g. 27th of Every month or in the 9th Month of each year or before 3pm every day
13.	How much interest is charged in the loan? N.B: This is the per annum rate. 
14.	In what bank should repayments be made? E.g. GTBank or Zenith Bank or Access Bank etc. 
15.	To what Account Number?
16.	What is the Account Name? 
17.	Give a full description of the Borrower�s collateral. A black 2004 Toyota Camry with registration number C606 PXX , a 5,000 kva Mikano generator, a Suzuki Gixxer 2017 Motorcycle or An iphone 7 mobile phone etc.
18.	How much will be charged for late repayment? N.B If there are not late payment fees, please input 0. 
19.	How much interest will be charged on the Default Fee?
20.	What is the email address of the Borrower? 
21.	What is the email address of the Lender?






DESCRIPTION OF THE DOCUMENT

A Managing Director�s Contract is an Agreement between an Employer and its managing director which sets out the rights and obligations, compensation, appointment procedure, term of years, termination, and confidentiality obligations of the parties. 


FREQUENTLY ASKED QUESTIONS (FAQs)

Who is a Managing Director?
A Managing Director is the most senior officer, executive or administrator in charge of managing an organization. The Managing Director/Chief Executive Officer is responsible for the performance of the company, as dictated by the board's overall strategy. The MD/CEO reports to the Chairman or Board of directors. The responsibilities of the MD/CEO include: Formulating and successfully implementing company policy etc.

Is an Executive Director different from the Managing Director?
The Managing Director, is both the alter ego and employee of the company. The position of the Managing Director is a creation of law, hence the board appoints one of the directors to the position of Managing Director. In any case, the position of the Managing Director is the most senior role in an organization and it is in the absence of an MD/CEO that an Executive Director can be the highest officer in an organization (mostly for non-profit organizations).

Who can be a Managing Director?
One of the executive directors may be managing director. A person may only be managing director as long as they are and remain a director - if they resign as a director, they automatically cease to hold office as managing director.
How can a Managing Director be removed?
The removal of a managing director will depend on the terms of any contract of employment for the managing director. If there is no contract of employment, this will depend on the company's Constitutional documents, or any other terms pursuant to which the managing director was appointed.



MANAGING DIRECTOR�S CONTRACT

This Agreement is entered into this ____ day of _______________20 ____

 BETWEEN 
 
____1a____ (hereinafter referred  to as the �Employer�) a company duly incorporated and registered under the existing laws of the Federal Republic of Nigeria with RC Number ______1b_____ and its primary place of business at ____1c_____ 
AND 
______2a______ of _______2b_______ (hereinafter referred to as the �Employee�).

The Employer and the Employee are referred to individually as �Party� and collectively as �Parties�

WHEREAS
A.	The Employer wishes to appoint a Managing Director to administer and manage its affairs.
B.	In view of the above, the Employer desires to obtain and retain the services of the Employee and the Employee is willing to render such services.
C.	The Parties are now entering into this Agreement to establish the terms of their employment arrangement.


NOW, THE PARTIES AGREE as follows: 

1.	 APPOINTMENT

The Employer hereby appoints ____3a_____as Managing Director effective from ______4a


2.	TERM OF EMPLOYMENT 

The Employment shall be deemed to have commenced on the effective date in clause 1 above and shall continue for a term of _____4b 

3.	   EMPLOYEE WARRANTIES 

3.1. The Employee represents and warrants to the Employer that, by entering into this Agreement or performing any of the obligations under it, there will not be a breach of any court order or any express or implied terms of any contract or other obligation binding on the employee.
 
3.2 The Employee warrants that the resume, references and other information provided  which qualified the employee for the position of a Managing Director are  true and correct and will immediately notify the Employer  if it ceases to be so.

3.3 The Employee warrants that there are no restrictions which prevents the employee from holding office as the Managing Director.


4.	   DUTIES OF THE EMPLOYEE

 During the Employment the Employee shall: 

4.1 acts as Managing Director/Chief Executive Officer and be accountable to the board of directors through the chairman of the board; 

         4.2 comply with the Employer�s articles of association (as amended from time to time); 

4.3	 abide by any statutory, fiduciary or common-law duties to the Employer; 

4.4	 comply with the anti-corruption and bribery policy and related procedures of the Employer; 

4.5	 unless prevented by incapacity, devote the time, attention and abilities required to effectively and efficiently manage the  Employer�s business;

4.6	recommend and participate in formulation of policies and makes decisions within existing   policies as approved by the Board of Directors;
 
4.7	plan, organize, direct, and coordinate staff, programs, and activities to ensure that objectives are attained, plans are fulfilled, and member needs are met; 

4.8	maintain effective internal and external relationships through management, leadership, and communication to achieve economical, productive performance, and constructive growth of the Employer. 

Administration and Management 
4.9	Attend all meetings of the Board of Directors and Executive Committee (where established) and ensure the management is kept fully informed of the Employer�s conditions and operations.

4.10	Recommend, for the approval of the Board of Directors, basic policies and programs that will further the Employer�s objectives. 

4.11	Develop and administer, for purposes of day-to-day operations, specific policies, procedures, and programs to implement the general policies established by the Board of Directors. 

  Financial Management 
4.12	Plan and manage the Employer�s budget, coordinating with the finance department. 

4.13	Ensure the Employer�s funds are administered in accordance with applicable fiscal policies. 
   

Human Resources Management 
4.14.	Establish and maintain a sound organizational structure for the Employer. 

4.15.	Supervise the recruitment and training of the Employer�s staff as authorized by the Board of Directors. 

Additional Managing Director�s responsibilities under this agreement are further set forth in Appendix A, and made a part of this Agreement. The content of Appendix A is however subject to further modifications as the position will require from time to time.


5.	CONFIDENTIALITY
5.1 	The Employee hereby acknowledges and agrees that either during the employment or at any time after its termination (however arising), the Employee shall possess information that has been created, discovered, developed, or otherwise known and owned by the Employer, which information has commercial value in the business in which the Employer is or may become engaged. 
5.2 	The Employee agrees that during the term of this Agreement and after the termination or expiration hereof, the Employee will keep in confidence and trust all such information, and will not use or disclose any such information or anything directly relating to it without the written consent of the Employer. 
           5.3  	In the event of the expiration or termination of this Agreement for any reason, the Employee shall promptly, at the direction of the Employer cease to use, destroy or return to the Employer all documents and data of any nature pertaining to the any information of the Employer, and will not keep or deliver to anyone else any documents or data of any description or any reproduction of any description containing or pertaining to any Proprietary Information. 
5.4 	This Clause shall not, however, apply to information which: 
a.	 is or becomes publicly available without the fault of the Employee; 
b.	 is disclosed in order to comply with the requirements of Law 
c.	 is disclosed to any of the consultants (legal, financial, technical or otherwise) of the Employer, provided that the recipient executes a confidentiality undertaking to use the information solely for the purpose disclosed. 
5.5 	The Employee also agrees to use its best efforts to assure that all information disclosed in connection with the business of the Company and not otherwise generally available shall be kept confidential and shall not be revealed.
	
6.	   COMPENSATION 
        FEES AND EXPENSES

6.1	The Employee shall be entitled to a salary of ___5a____ per year, payable in ___5b__installments of ____5c____ on or before ___6a____. 

6.2	The Employer shall reimburse the Employee for reasonable and necessary expenses, incurred in the performance of the duties and responsibilities attached to the position. Expenses will be reimbursed within a month upon submission of documentation and receipts.

6.3	The Employer will provide health and life insurance benefits and a pension plan at a rate to be discussed by the Parties.

6.4	The Employee understands and agrees that any further consideration and/or additional remuneration paid to the Employee in the form of bonuses, benefits and other similar incentive remuneration will rest in the sole discretion of the Employer.



7.	INTELLECTUAL PROPERTY RIGHTS
All work products or work in progress created by the Employee under this contract during the term of this Agreement shall constitute work for hire and shall be the property of the Employer. The Employee agrees to give all necessary assistance to the Employer to enable it to enforce its intellectual property rights against third parties, to defend claims for infringement of third party intellectual property rights and to apply for registration of intellectual property rights, where appropriate throughout the world, and for the full term of those rights.

8.	PLACE AND HOURS OF WORK

8.1 The normal place of work of the Employee is ____8a ___or such other place which the Employer may reasonably require for the proper performance and exercise of the duties of the Employee. 

8.2. The Employee agrees to travel on any business of the Employer as may be required in the course of carrying out the duties under this Agreement.

8.3 The regular work hours of the Employee shall be ____9a__ or such hours as are necessary for the proper performance of the duties, and the Employee acknowledges that no further remuneration shall be made in respect of such additional hours


9.	TERMINATION 
       Either party to this agreement may terminate same by giving the other not less than ___10a  prior notice in writing.

9.1 In the event that the Agreement is terminated by the Employee, there shall be no termination compensation other than accrued compensation earned prior to the effective date of termination less any payments due and owing to the Employer. 

Termination without Notice

9.2 In the event that the Agreement is terminated by the Employer for cause defined as willful, negligent, or repeated failure to perform the essential requirements of the position or for unethical, illegal, or fraudulent conduct, there shall be no notice required and no severance pay or termination compensation made to the Employer.

9.3  If the decision is made by the Employer to terminate the Agreement for reasons other than those specified above, severance arrangements (less any amounts due and owing to the Employer) shall apply in accordance to Employer�s policies.


 
10.	GOVERNING LAW 
The implementation and enforcement of this Agreement shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement.

11.	DISPUTE RESOLUTION

11.1	Any controversy or claim arising out of or relating to this Agreement, or the breach hereof, the Parties shall expend their best efforts to settle any disputes arising between them amicably within 30 days.
11.2	In the event that the Parties are unable to reach an amicable resolution, such dispute, and controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration; by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap 19 Laws of the Federal Republic of Nigeria 2004.

12	SEVERABILITY
In the event that any clause or provision in this Agreement is held invalid or unenforceable by a court of competent jurisdiction in Nigeria, such invalidity shall not affect the validity or operation of any other clause or provision; and such invalid clause or provision shall be deemed to be severed from this Agreement.


13	WAIVER 
None of the requirements, rights or provisions of this Agreement shall be considered waived unless waived in writing by the Party concerned. The waiver by any Party of one or more of its rights hereunder shall not be deemed or construed as a continuing waiver on the part of such Party as to prohibit such Party from demanding the performance of any of the other duties and obligations owed it in accordance with the provisions of this Agreement.



14	ENTIRE AGREEMENT AND COUNTERPARTS 

14.1 This agreement and any document referred to in it constitutes the entire agreement between the parties and supersedes and extinguishes all previous discussions, correspondence, negotiations, drafts, agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.

14.2 This deed may be executed in any number of counterparts, each of which, when executed and delivered, shall be an original, and all the counterparts together shall constitute one and the same deed.

15. MISCELLANEOUS 

15.1.	This Agreement and its provisions shall be subject to the Employer�s Articles of Association as may be amended from time to time. 
15.2.	In the event of any conflict between this Agreement and the Employer�s Articles of Association such provision(s) which conflicts with the Employer�s Articles of Association shall be invalid and unenforceable to the extent to which same conflicts with the Articles of Association.  

IN WITNESS OF WHICH the parties have executed this agreement in the manner below, on the date indicated above
THE COMMON SEAL of the within named Company is hereby affixed and the deed duly delivered in the presence of:



_________________						   _______________________
Signature						              Signature
Name:	____________________				 Name: ______________________
Title:	____________________		                         Title: ________________________

SIGNED by the within named Managing Director:



_____________________________                                   _______________________________
Signature							Name of Employee


IN THE PRESENCE OF:
Name:
Address:
Occupation:
Signature:

References:
1a. What is the Employer�s name?
1b. What is the Employer�s RC Number?
1c. What is the Employer�s office address?
2a. What is the Employee/Managing Director�s name?
2b.What is the Employee�s address?
3a. Repeat reference 2a
4a. On what date will the Employee�s appointment as Managing Director commence?
4b. What is the duration of the Employee�s tenure as Managing Director?
5a. What is the annual salary of the Employee as Managing Director?  
5b. In how many installments is the annual salary payable?
5c. What is the monthly salary of the employee as Managing Director?
6a. The Employee�s salary is payable on or before what date every month? (27th of Every Month)
8a. Where is the normal place of work of the employee? (e.g. Lagos, Nigeria)
9a. What is the regular work hour expected of the employee as Managing Director? (37hours per week)
10a. What is the notice period for termination of employment as Managing Director by either party?
11a. Kindly provide details of specific duties of the Managing Director in numbered paragraphs.





DESCRIPTION OF THE DOCUMENT
A Memorandum of Understanding (MOU) is a nonbinding agreement between two or more parties outlining the terms and details of an understanding, including each parties' requirements and responsibilities. An MOU is often the first stage in the formation of a formal contract. MOUs specify mutually accepted expectations between two or more people or organizations in respect of a particular transaction/project. 


MEMORANDUM OF UNDERSTANDING
THIS MEMORANDUM OF UNDERSTANDING (�MOU�) is made this _________ day of___________________20____

BETWEEN

________________________ (1a) of _______________________________ (1b) (hereafter referred to as �Party A� which expression shall where the context admits include its successors-in-title and permitted assigns) 
AND

________________________ (2a) of ________________________________ (2b) (hereafter referred to as �Party B� which expression shall where the context admits include its successors-in-title and permitted assigns) 
Hereafter collectively referred to as �Parties� and individually as �Party�

WHEREAS
1.	The aim of entering into this MOU is to set out the working arrangements the Parties believe are necessary to undertake projects jointly and provide a framework for future contracts between them.
2.	The Parties agree to work together in good faith in carrying out their obligations in accordance with the terms and conditions set out in this MOU.

 

NOW THEREFORE, the Parties agree as follows:
1.	RELATIONSHIP OF THE PARTIES
The Parties understand that no contractual relationship is created between them by virtue of this Memorandum but have decided to mutually work together in good faith and demonstrate their commitment to effectively and efficiently carry out �.(2c) (�the Project�). 

2.	PURPOSE
The purpose of this MOU is to facilitate more detailed discussions regarding the basis on which the Joint Venture would be undertaken and to broadly set out the respective rights and obligations of the Parties under the Joint venture.

3.	MUTUAL UNDERSTANDING
3.1	The Parties each acknowledge and agree that:
i.	They will deploy all the resources (both human and material) for the purpose of realizing the objectives of this MOU and the Project.
ii.	They will provide the support that is necessary and desirable to successfully carry out the terms of this MOU and the Project.
iii.	They shall perform their duties to the best of their professional ability. 
iv.	The Parties will work collaboratively and synergistically towards achieving the aims and objectives of this MOU.
v.	The Parties shall exhibit good faith in their interactions pursuant to and under this MOU.
vi.	That any variation to this MOU shall not be effective unless made in writing and signed by the Parties hereto.
vii.	That the relationship between them in respect of the Project shall be �(2d) 
viii.	That no member of staff or Affiliate of the Parties shall engage in any activity in connection with the Project except on behalf of its respective employer who is a Party to this MOU.
ix.	That nothing contained in this MOU shall prevent any Party from carrying on its business for its sole interest and profit outside of the Project, unless this would have an unfavourable influence or effect on the Project.

 

4.	DURATION OF THE MOU
This MOU shall come into force on the date of signature of the last Party hereto and shall terminate on the first to occur of: (i) execution of a definitive agreement(s) by the Parties which will address issues such as the Project structure, rights and responsibilities of the parties and profit sharing; or (ii) �..(2e) after the execution.


5.	BINDING AND NON-BINDING PROVISIONS

5.1	This MOU is not intended to be legally binding except as specifically set out below.
5.2	The provisions of clauses 4 -12 of this MOU shall be binding on the Parties notwithstanding anything contained elsewhere in this MOU and the fact that this MOU is a pre-contract agreement.

6.	CONFIDENTIALITY
6.1	The Parties agree that the existence of and the terms of this MOU and all information disclosed and all correspondences exchanged during the negotiation of this MOU and the Agreement is confidential (�Confidential Information�).
6.2	Neither Party shall directly or indirectly disclose to any third party any Confidential Information except as permitted in accordance with clause 6.3. 
6.3	 Party may disclose confidential information:
(i)	to its shareholders, affiliates, professional advisers, directors, officers or employees but only to the extent required to consider the transaction proposed by the Agreement;
(ii)	to persons to the extent required by a court of competent jurisdiction, applicable law or relevant governmental or regulatory authorities
but in any event the Party making the disclosure (�Disclosing Party�) shall, to the greatest extent possible, ensure that any Confidential Information is treated confidentially by any person receiving the Confidential Information (�Third Party Recipient�) including, where appropriate, the Disclosing Party and the Third Party Recipient first executing an agreement which contains similar confidentiality obligation as this clause 5 for the protection of the Confidential Information from disclosure by the Third Party Recipient.
6.4	The obligation of confidentiality contained in this clause 6 shall continue to bind the 	parties for a period of 3 years after the termination of this MOU.

7.	GOVERNING LAW AND JURISDICTION

7.1	This MOU shall in all respects be governed by and be construed and interpreted and take effect in accordance with the laws of the Federal Republic of Nigeria.

7.2	Any dispute arising out of or in connection with this MOU, including any question regarding its existence, validity or termination, shall be resolved by arbitration by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap 19 Laws of the Federal Republic of Nigeria 2004.
8.	NOTICES
8.1	All notices or demands and any other communication under this MOU (especially in case of a dispute) shall be in writing and delivered personally or by registered mail or courier at the below mentioned address.

If to PARTY A: 
______________________________________ (5a)
Attention: _________________________________ (5b)
Email: ____________________________________ (5c)


If to PARTY B: 
________________________________________ (6a)
Attention: ___________________________________ (6b)
Email: ______________________________________ (6c)

8.2	Changes, if any, to the above addresses must be given by either Party to the other, in writing, and delivered using any of the above mentioned modes of delivery.

9.	ASSIGNMENT
The Parties shall not assign or transfer all or any part of their respective rights under this MOU or delegate their performance under this MOU without the prior written approval of the other Party, and any assignment, transfer or delegation that is made without such prior written approval shall constitute a breach of this MOU.

10.	COSTS AND LIABILITIES
Each Party shall be solely responsible for its own costs and expenses including without limitation, its legal, accountancy and other professional costs in connection with the preparation and negotiation of this MOU, any definitive agreement and the Project.
11.	ENTIRE AGREEMENT
This MOU constitutes the entire agreement between the Parties and supersedes all prior understandings, oral or written between the Parties. 

12.	OTHER PROVISIONS
12.1	Each Party warrants and represents that:
12.1.1	it is authorised to enter into this MOU and that the respective signatories hereto have been duly authorised to act on behalf of each of the Parties; and
12.1.2	all participation in further negotiations between the Parties in respect of the details of the Agreement will be in good-faith and on a best effort basis. Any documentation and meeting preparation shall be done promptly, properly and efficiently and provided with all reasonable due care and skill.
12.1.3	The Parties may not issue any public notice or announcement, or any press release (or similar) in relation to this MOU and the Agreement without the prior written consent of the other Party.

IN WITNESS OF WHICH the Parties have executed this MOU in the manner below on the day and year first above written:
Signed for and on behalf of PARTY A:

................................................................
Name: _______________________________
Position: _____________________________
Date: ________________________________

Signed for and on behalf of PARTY B:

...............................................................
Name: _______________________________
Position: _____________________________
Date: ________________________________








References:
1a what is the name of the Party A entering into this MOU?
1b. what is the address of Party A?
2a. what is the name of party B in this MOU?
2b. what is the address of party B in this MOU?
2c. Please provide details of the project/transactions the parties wish to undertake. E.g. Automobile repairs and fleet management services, car hiring services etc. 
2d. Is the relationship between the Parties in respect of the project exclusive or non-exclusive?
2e. What is the duration of the MOU?  
5a. please provide an address for the receipt of notices by party A?
5b. What is the name of the person that should receive the notices for Party A?
5c. What is the email address of the person that should receive the notice for Party A?  
6a. please provide an address for the receipt of notices by party B?
6b. What is the name of the person that should receive the notices for Party B?
6c. What is the email address of the person that should receive the notice for Party B?
FREQUENTLY ASKED QUESTIONS (FAQS)
Why should I enter into an MOU?
An MOU outlines the terms of the agreement between the parties. Though a less formal document than an actual contract, it ensures clarity of terms behind the signatures and handshakes. Each party knows the extent and limit of what to expect of the other.
When is an MOU needed?
You will need an MOU if for instance:
�	You own a business and wish to partner with another business on a project
�	you have a small business and operate by constantly partnering with others
�	when another business wants to partner with you 
Is an MOU enforceable?
Generally, an MOU does not bind the parties and is usually not enforceable in courts because it does not satisfy the elements of a valid contract viz; offer, acceptance, consideration etc. Essentially parties enter into an MOU based on good faith understanding with the other party.






mylaw Terms and Conditions 
General 
1.	These Terms and Conditions (Terms) set out the basis on which you can access the Services offered by mylaw. Please read them carefully as they contain important information. Your subscription, one off payment for services and general use of the website signifies your acceptance of these Terms and your acknowledgement that the information that you provide, directly or indirectly, through the websites will be managed in accordance with the mylaw Privacy Policy. IF YOU DO NOT ACCEPT THESE TERMS OF USE, YOU ARE NOT AUTHORIZED TO ACCESS OR USE THE WEBSITES.
2.	It is your responsibility to review these Terms periodically. If at any time you find these Terms unacceptable or if you do not agree to these Terms, please do not use this Site or any Applications. We may revise these Terms at any time without notice to you. If you have any questions about these Terms, please contact our customer care center.
3.	If one or more provisions in these Terms appear to be void, voidable or non-binding, the remaining part of these terms and conditions will remain in full force. mylaw is entitled to replace the void, voidable or non-binding provisions with provisions that are not void or voidable and which are binding and which, with a view to the object and purposes of these Terms, reflect the void, voidable or non-binding provision(s) as closely as possible. 

Introducing mylaw 
The �mylaw� web service is a self-help platform which aids the provision of legal services for users of the website. 
�mylaw� is an internet hosted service medium which provides simple, affordable and accessible services to the public; such as: 
�	Document Repository App: An online source for standard legal agreement templates allowing Users reduce contractual intentions in form of legal agreements and contracts.
�	Virtual Law Firm: Is a web-based advisory platform through which �mylaw� provides auxiliary assistance to Users for sharing and resolving legal and regulatory issues and/or queries.
�	Panel of Law Firms: is an online registration medium through which existing lawyers and law firms can purvey legal services to willing Users; and/or respond to User queries as channeled on the Platform.  
These Terms stated below, govern the use of �mylaw�, and are binding between you and �mylaw� and/or its affiliates, being providers of the �mylaw� services. 
1.	Interpretation and Definitions
For the purposes of this Terms, the following expressions shall have the following meaning:
Affiliates shall mean and/or include Virtual Platform Limited and any such other company as is designated by �mylaw� as its affiliate
�mylaw� shall mean all that in these Terms and Conditions, except where the context so admits mean all services provided by Virtual Platform Limited on the mylaw website including the �Document Repository App�, �Virtual Law Firm� and �Panel of Law Firms�, and any and all such services as may be developed by Virtual Platform Limited and hosted on the mylaw website. 
Services shall where the context so admits mean all services provided by �mylaw� on the �mylaw� website including the �Document Repository App� , �Virtual Law Firm� and �Panel of Law Firms�, any and all such services as may be developed by and/or for �mylaw� and hosted on the mylaw website
�mylaw� website shall mean all that proprietary information contained, prepared, presented and hosted by �mylaw� at [http/: www.] and/or any other website so owned by �mylaw� for the purposes of providing �mylaw� services.  
Officers shall where the context so admits mean officers, employees, directors and agents of the organization, company or firm being referred to. 
Participating Lawyers means any and all �mylaw� registered lawyers and/or law firms on the Panel of Law Firms
�Suppliers� means any individual, firm or organization designated by �mylaw� or with whom �mylaw� contracts as a partner, agent and such other relationships for the purpose of providing or distributing �mylaw� services to the User.  
Terms means the Terms and Conditions contained herein. 
Virtual Platform Limited means the body corporate with rights and ownership to the mylaw trademark, and all such services as are provided on the mylaw website, which expression shall include its successors in title and assigns.
User shall mean any and all visitors, and all registered accountholders on the mylaw website.
2.	Contractual Relationship
2.1.	These Terms govern your access to, and use of �mylaw� operated by Virtual Platform Limited. 
2.2.	By continuing usage of mylaw, you consent to be bound by these Terms and Conditions for use of and access to �mylaw�. 
2.3.	The User is required to keep these terms and conditions as they constitute a binding agreement between User and �mylaw�.

3.	The Agreement
3.1.	I (�User�) confirm and agree that the following terms and conditions shall govern my use of the mylaw platform and services. 
3.2.	By clicking on the �I AGREE� button or a similar affirmation, or by acknowledging acceptance of these Terms and Conditions by another method allowed by �mylaw�, or by using or accessing �mylaw� through any means permissible including, without limitation via a computer or a mobile application; User acknowledges and agrees that: 
3.2.1.	It has reviewed and understands these Terms and Conditions;
3.2.2.	It agrees to be legally bound by these Terms and Conditions; and 
3.2.3.	Its use of mylaw and any related products or services will be governed by these Terms and Conditions 
3.3.	If the User does not agree or is not willing to be bound by these terms and conditions, User should not click on �I AGREE� button and/or should immediately discontinue use of mylaw. 

4.	User�s Capacity and Related matters 
By accepting these Terms and Conditions and/or the continued use of the mylaw, the User represents and warrants that:
4.1.	The User agreeing to these Terms and Conditions has attained the legal age of majority in your country of residence or otherwise able to form a binding contract with mylaw in order to use the Services. In no event is use of the Services permitted by those under the age of 18.
4.2.	All information provided by the User on and for the purposes of �mylaw� are at all times true, complete, accurate, neither fraudulent or misleading in any respect.
4.3.	User further represents and warrants that User has the legal authority to accept the Terms and Conditions herein set out and such acceptance will be binding on the User. 
4.4.	�mylaw� reserves the right to in its sole discretion, refuse to provide �mylaw� service to the User in any event that the User is in breach of any provisions of these Terms and Conditions. 

5.	Acceptance of Terms and Conditions of use
5.1.	For the avoidance of doubt, use of mylaw and/or any of its services constitutes acceptance of these Terms and Conditions, and any subsequent amendments which may be made to the �mylaw� terms and conditions from time to time.

6.	Trial offers, Auto-renewals and Paid accounts
6.1.	�mylaw� may at its sole discretion make available trial offers for the limited use of mylaw to new or existing Users who provide a valid payment instrument, such as a credit card or other payment method. 
6.2.	Any User that chooses to start a Trial Offer, will automatically join the selected paid membership at the end of the Trial Offer. 
6.3.	User unequivocally authorizes �mylaw� to charge the provided payment method a recurring monthly subscription fee or annual subscription fee or Pay-As-You-Go (PAYG) cost, as applicable. 
6.4.	Any use of additional Services beyond those included in the Trial Offer, will require a separate purchase of those Services or an upgrade to a paid membership prior to the end of the Trial Offer period.
6.5.	�mylaw� may automatically charge the User at the end of the trial or for a membership renewal unless the User gives notice of intention to cancel or convert your membership. 
6.6.	User can downgrade a subscription membership to a PAYG account at any time.
6.7.	To ensure uninterrupted service and to enable User conveniently purchases additional products and services, �mylaw� will store and update (e.g. upon expiration) User payment method on file. 
6.8.	In the event that mylaw is unable to charge the Users designated payment method for any reason, mylaw reserves the right to automatically convert User�s paid plan to PAYG account or to suspend the paid membership until the designated payment method can be charged again. 
6.9.	For the avoidance of doubt, it is User�s responsibility to maintain current billing information on file with �mylaw�.

7.	Payment Obligations
7.1.	Upon initiation and confirmation of a transaction on �mylaw�, the User agrees to be bound by and pay for any such transaction. User is enjoined to not commit to a transaction unless able and ready to pay for and have confirmation of successful payment. 
7.2.	User warrants that all payment information provided is accurate, not misleading and not fraudulent in any respect; and all payment transactions for mylaw are final; and non-refundable except in the sole discretion of �mylaw�.
7.3.	To prevent financial loss or possible violations of the Law, �mylaw� reserves the right to in its discretion disclose details of any payment associated with or made by the User, with law enforcement agencies, or impacted third parties (including other users). Such disclosures may originate from an order of any court of competent jurisdiction to compel such disclosure. 
7.4.	User agrees and confirms that User will not use �mylaw� in violation of any law in force in the Federal Republic of Nigeria or such other law which may occasion liability of any sort on �mylaw�. 
7.5.	SMS charges or any such other charges (where applicable) by communication network operators may be applied for each payment transaction conducted on the �mylaw� website.

8.	Refund Policy 
8.1.	�mylaw� desires the optimum satisfaction of the User as regarding services provided on the �mylaw� website. 
8.2.	In the event that the User is less than satisfied or believes there has been an error in billing, User should contact our Customer Service Department by email at [customer service email address] or by telephone at [customer service phone number] immediately so that such issue may be resolved, by providing a refund or offering credit that can be used for future Services. 
8.3.	The relevant mode of refund for complaints made will be determined in the sole discretion of �mylaw�, and the User shall under no circumstances be entitled to any claim arising from or in connection with the refund method chosen by �mylaw�. 
8.4.	When communicating such complaint, User should include any and all details relating to the exact mylaw service or product purchased so as to ensure complete User satisfaction with the mylaw experience. 
8.5.	Refund requests must be made within [30 days] of purchase. Provided that any User seeking to cancel a subscription, may apply to receive a prorated refund for the remaining time which has not been used. In order to apply for a refund relating to a �mylaw� services, this is to be communicated by sending an email to [email address for refund requests] or call mylaw at [phone number for refund requests].
8.6.	Provided that, in the event that specific services require different refund request periods same shall be abided with by the User.
8.7.	 �mylaw� cannot and shall not be required to refund any money paid by User directly to third parties, such as payments made by Users directly to Participating Attorneys.

9.	�mylaw� is not a Law Firm 
9.1.	For the purposes of this Section, �mylaw� shall mean  as defined in Section 1 above, excluding the services provided in respect of the Virtual Law Firm
9.2.	�mylaw� provides a platform for legal information and self-help. Any and all such information provided by �mylaw� along with the content on �mylaw� website related to legal matters ("Legal Information") is provided for User�s private use and does not constitute legal advice.
9.3.	�mylaw� does not review any information provided on the Document Repository App for legal accuracy or sufficiency, draw legal conclusions, provide opinions about your selection of documents, or apply the law to the facts of the presented situation.
9.4.	Any User in need of legal advice for a specific problem, should consult with licensed attorney or communicate such queries on the Virtual Law Firm. No Legal Information provided by �mylaw� is a substitute for legal advice from a qualified attorney licensed to practice in an appropriate jurisdiction.
9.5.	As �mylaw� is not a law firm, please note that any communication between the User and a �mylaw� may not be protected as confidential information under the attorney-client privilege doctrine.
9.6.	Use of the �mylaw� does not create an attorney-client relationship between the User and �mylaw�, or between the User and any employee or representative of �mylaw� and/or its affiliates. Unless User is otherwise represented by an attorney, including a Participating Attorney, User represents itself in any legal matter you undertaken on �mylaw� Services.
9.7.	�mylaw� is not a "Lawyer Referral Service". The Directory of Lawyers and Law firms published on our Panel of Law Firms is provided to the public for informational purposes only. �mylaw� does not endorse or recommend any attorney nor does it make any warranty as to the qualifications or competency of any attorney.

10.	Interaction on �mylaw�
10.1.	Users will have the opportunity to initiate contact with Participating Attorneys.
10.2.	Participating Attorneys are neither employees nor agents of mylaw and are third-party independent contractors who have agreed to provide online answers, limited consultations or other basic legal services to �mylaw� users. 

11.	Profiles of Participating Attorneys on mylaw 
11.1.	Attorney profiles on the �mylaw� website are advertisements and should be interpreted as such. 
11.2.	�mylaw� does not endorse to any User the proficiency or competence of any Participating attorney on the �mylaw� website. 
11.3.	Participating Attorneys are third-party independent contractors solely responsible for the advice they give, the services they provide and the representations they make about themselves. As such, Users are solely responsible for assessing the quality, integrity, suitability and trustworthiness of all persons with whom they communicate legal needs on the �mylaw� website.

12.	Termination of subscription, membership and access restriction
12.1.	�mylaw� reserves the right, in its sole discretion, to downgrade or terminate your access to �mylaw�, for any reason and at any time without prior notice. 
12.2.	If �mylaw� elects to terminate User�s account, �mylaw� will provide User with notice of same sent to the registered email address of the User.
12.3.	�mylaw� reserves the right to modify or discontinue, either temporarily or permanently, any part of the �mylaw� services with or without notice to the User.
12.4.	The User agrees that �mylaw� will not be liable to the User or any third party claiming through the User for any modification, suspension, or discontinuance of the User�s subscription or membership or access on �mylaw�. 

13.	Ownership and preservation of documents
13.1.	�mylaw� does not claim ownership of any documents, files or media (collectively referred to in this section as Documents) you either create, upload or store using �mylaw�. 
13.2.	The User hereby grants permission to �mylaw� and/or its Participating Attorney�s to use your Documents in connection with providing services to you. 
13.3.	The User acknowledges and agrees that �mylaw� may preserve these Documents as well as disclose them if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to accomplish any of the following: 
13.3.1.	To comply with legal process, any applicable law or government requests;
13.3.2.	To enforce the provisions of these Terms and Conditions
13.3.3.	To respond to claims that any content violates the rights of third parties; or 
13.3.4.	To protect the rights, property, or personal safety or �mylaw�, its users and the public.
13.4.	The User understands that the technical processing and transmission of �mylaw�, including User�s content, may involve transmissions over various networks and changes to conform and adapt to technical requirements of connecting networks or devices. As such User agrees that �mylaw� has no responsibility or liability for deleting or failing to store any content maintained or uploaded for use on �mylaw�. 

14.	Consent to receive emails
14.1.	By creating an account, the User agrees that the User may receive email communications from �mylaw�, such as newsletters, special offers, account reminders and updates. 
14.2.	The User understands that the User can discontinue receipt of these communications by clicking the �Unsubscribe� link in the footer of the actual email. 

15.	Acceptable use of Communication Services
15.1.	The �mylaw� services include a large number of what are referred to in this Section as Communication Services which include but are not limited to live chats, comment threads, question and answer products, customer service communication forums, and other message services. 
15.2.	The User agrees to use the Communication services only to post, send and receive messages or materials proper to and related to the particular Communication Services. 
15.3.	The User agrees that when using the Communication Services, the User will not : 
15.3.1.	Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of any others.
15.3.2.	Publish, post, upload, distribute or disseminate any names, materials, or information that is considered inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful. 
15.3.3.	Create a false identity, represent self as someone else or otherwise falsify or delete in an uploaded file any significant attributions or notices. 
15.3.4.	Upload files that contain software or other material protected either by intellectual property laws or by the rights of privacy or publicity except when the necessary rights or consent to such material or software is owned or controlled, or obtained(as applicable).
15.3.5.	Upload corrupted files, files that contain viruses, or any other files that may damage the operation of another�s computer. 
15.3.6.	Advertise, offer to sell, or offer to buy anything for business purposes except to the limited extent any particular Communication service specifically allows such activity. 
15.3.7.	Restrict or inhibit any other user from using and enjoying the Communication Services specifically allows such activity. 
15.3.8.	Harvest or otherwise collect personally identifiable information about others, without their consent. 
15.3.9.	Violate any code of conduct or other guidelines, which may be applicable for any particular Communication Services.
15.3.10.	Violate any applicable laws or regulations. 
15.4.	Although �mylaw� has no obligation to monitor the Communication Services, �mylaw� reserves the right, in its own discretion, to review and remove materials posted to a Communication service, in whole or in part. 
15.5.	�mylaw� reserves the right to disclose any material posted, information or activity as necessary to satisfy any applicable law, regulation, legal process, or governmental request.      

16.	No Unlawful or Prohibited Use
16.1.	User can only make use of �mylaw services if �mylaw� services do not conflict with or violate the laws of User�s jurisdiction.
16.2.	The availability of �mylaw� services in User�s jurisdiction is not an invitation or offer by �mylaw� to access or use �mylaw� website and its services.
16.3.	By using �mylaw� services , the User accepts sole responsibility that such use does not violate any applicable law in User�s jurisdiction 
16.4.	The following are specifically excluded and/or prohibited:
16.4.1.	Use in connection with any legal matter that is frivolous, immaterial or illegal in nature, as determined by �mylaw� or the Participating Attorney in their sole discretion;
16.4.2.	Use in connection with any legal matter involving the laws of jurisdictions outside of Nigeria;
16.4.3.	Use in connection with any legal matter for which the User is currently or prospectively represented by legal counsel;
16.4.4.	Use in any legal matter that directly involves �mylaw�, Virtual Platform Limited or any of its affiliates, directors, agents, employees, or other �mylaw� service providers; or 
16.4.5.	Hack, �scrape� or �crawl� the �mylaw� website whether directly or through intermediaries including but not limited to spiders
16.5.	In enforcement of this section, �mylaw� reserves the right to refuse membership, or suspend or terminate User�s account immediately and without prior notice.

17.	Licence Grant
17.1.	Subject to the User�s compliance with these Terms and Conditions. The User is hereby granted a non-exclusive, limited, non-transferable, revocable licence to use �mylaw� services as �mylaw� intends them to be used. 
17.2.	User is licensed to keep, for personal records, electronic or physical copies of documents created on �mylaw�.
17.3.	User shall not copy the content of �mylaw� documents for use or sale outside of �mylaw�. 
17.4.	Any and all rights not expressly granted in these Terms and conditions are reserved by �mylaw�.
17.5.	Upon transmission of content on �mylaw�, User hereby grants �mylaw� and its affiliates a nonexclusive, royalty-free, perpetual, revocable and fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display any such content, including throughout the world in any media. 
17.6.	Any feedback and/or suggestions submitted by the User, may be used by �mylaw� without obligation to the User.
17.7.	Any resale or unauthorized distribution or duplication of materials downloaded from the �mylaw� website is strictly prohibited. Use of any such materials is for personal, business or educational use only.   Any resale or redistribution of �mylaw� resources without express written consent by �mylaw� is strictly prohibited.  

18.	Intellectual Property Rights
18.1.	�mylaw� retains all right, title and interest in and to the products and services provided by it on the �mylaw� website including without limitation: software, images, text, graphics, illustrations, logos, service marks, copyrights, photographs, videos, music, and all related intellectual property rights. Except as otherwise provided in this Terms and Conditions, the User shall not and shall not  permit others to:
18.1.1.	Reproduce, modify, translate, enhance, decompile, disassemble, reverse engineer or create derivative works of any of the �mylaw� products and services;
18.1.2.	Sell, license, sublicense, rent, lease, distribute, copy, publicly display, publish, adapt or edit any of the �mylaw� products and services; or
18.1.3.	Circumvent, or disable any security or technological features of the �mylaw� products and/or services. 
18.2.	The design, text, graphics and selection and arrangement thereof, and services and the legal documents, guidance and all other content found on the �mylaw� website and all other content found on the website are copyright of mylaw� . All rights reserved. 
18.3.	mylaw� is a registered trademark in the Nigerian Registry of Trade Marks, Patents, Designs and Copyright. This and any other �mylaw� product or service names or slogans displayed on �mylaw� products are trademarks of �mylaw�.
18.3.1.	User shall not copy imitate or use them, in whole or in part, without prior consent of �mylaw�. 
18.3.2.	In addition, the look and feel of �mylaw� is the service mark, trademark and/or trade identity of �mylaw� and the User may not copy, imitate or use same without prior written consent of �mylaw�. 
18.3.3.	The names of actual companies and products which the User may encounter through �mylaw� may be the trademarks of its respective owners. 
18.3.4.	Nothing in these Terms and Conditions shall be understood as in any way granting any license or right to use any of mylaw�s trademarks displayed on the �mylaw� website.
18.3.5.	All goodwill generated from the use of mylaw�s trademarks is reserved for the use of �mylaw�, exclusively.
19.	Copyright Infringement
19.1.	   If the User believes that any material on the �mylaw� website infringes upon any copyright owned and/or controlled by the User, the User may file a Notice of alleged Infringement with mylaw�s Designated Copyright Agent:

Virtual Platform Limited
Attn: Legal Department
[address]
[email]
20.	Links to Third Party Sites
20.1.	�mylaw� websites may contain links to third party resources and businesses on the Internet(referred to in this section as �Links�). 
20.2.	These Links are provided for User convenience to help the User identify and locate other internet resources that may be of interest to the User. �mylaw� does not sponsor and is not legally associated with any third party Links. 
20.3.	�mylaw� is not legally authorised to use any trade name, registered trademark, logo, official seal or copyrighted material that may appear in the Links.
20.4.	�mylaw� does not control, endorse or monitor the content(s) of any Links, and any changes or updates to any such Link.
20.5.	�mylaw� is not responsible for webcasting or for any other form of transmission received from any Link. 
20.6.	These Terms and Conditions do not cover User interaction with Links. 
20.7.	User has the sole responsibility to carefully review the Terms and Conditions and Privacy Policy of any Links and Linked sites, and any third party sites connected thereto.
20.8.	In respect of the use of any service provided by any link or on any Linked site:
20.8.1.	�mylaw� is not and will not be responsible for any act or omission of the third party; including any third party access to or use of User data.
20.8.2.	�mylaw� does not warrant or support any service provided by the Third Party. 
21.	Disclaimer of Responsibility and Liability
21.1.	The information, Software, product and services made available through �mylaw� may include inaccuracies or typographical errors.
21.2.	�mylaw� and/or its suppliers may at any time make improvements or changes to the �mylaw� services.
21.3.	Any and all information received via �mylaw� should not be relied upon for any personal, legal, or financial decisions. 
21.4.	User should consult an appropriate professional for specific advice tailored to the unique circumstances of the User. 
21.5.	TO THE FULLEST EXTENT PERMITTED BY LAW, �mylaw� AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE SERVICES ARE PROVIDED �AS IS�. 
�mylaw� ALSO DISCLAIMS ANY WARRANTIES OR MERCHANTIBILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. INFORMATION AND OPINIONS RECEIVED VIA THE WEBSITE SHOULD BE RELIED UPON FOR PERSONAL, LEGAL OR FINANCIAL DECISIONS ONLY UPON CONSULTATION WITH AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO THE USER�S SITUATION.
21.6.	TO THE FULLEST EXTEN PERMITTED BY LAW, IN NO EVENT WILL �mylaw� AND/OR ITS AFFILIATES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, REGARDLESS OF LEGAL THEORY, WHETHER OR NOT �mylaw� HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE. 

22.	Release and Indemnity 
22.1.	The User personally and on behalf of the User�s heirs, executors, agents, representatives, and assigns, fully releases, forever discharges, and holds �mylaw�, and/or its affiliates, its respective harmless form any and all losses, damages, expenses, including reasonable attorney�s fees, rights, clams, and actions of any kind and injury (including death) arising out of or relating to the use of the �mylaw� Services.
22.2.	User agrees that this release has been freely and voluntarily consented to and confirm that the User fully understand the purpose, intent, form and import of these Terms and Conditions.
22.3.	The User agrees to indemnify and hold �mylaw� and/or its affiliates and their respective officers from any and all losses, damages, expenses, including reasonable attorneys� fees, rights, claims, actions of any kind and injury (including death) arising out of any third party claims relating to use of the �mylaw� services, User violation of the Terms and Conditions, and/or User violation ot the rights of another in relation to these Terms and Conditions.   
 
23.	Dispute Resolution 
23.1.	User concerns can be resolved quickly and to the Users satisfaction by calling the �mylaw� Customer Care Lines at [phone number] and/or sending an email to [email address]
23.2.	However, in the event that �mylaw� is not able to resolve a dispute with the User after attempts to do so informally, then as a condtion to use of the services, the User agrees with �mylaw� that such disputes, claims, rights arising thereof shall be resolved through binding arbitration. 
23.3.	The Arbitration and Conciliation Act, Cap A18 Laws of the Federal Republic of Nigeria shall be the applicable law for such proceedings. 
23.4.	The arbitration proceedings shall be held in Lagos, Nigeria or any other location as to which the �mylaw� and the User may then agree. 
23.5.	Any party seeking arbitration must first Send to the other, by certified or registered mail a written notice of dispute.
23.6.	 Any notice to �mylaw� should be addressed to:
 Virtual Platform Limited, 
[Address], 
Attention: Legal Department. 
23.7.	Any notice to be sent by �mylaw� to the User shall be sent to the User address set for in �mylaw� records of account or such other legal address as �mylaw� is able to identify.
23.8.	The User may only resolve disputes with �mylaw� or Participating Attorneys on an individual basis, and may not bring a claim as claimant or plaintiff or a class member in a class, consolidated or representative action. Class arbitrations, class actions, private attorney general actions, and consolidations with other arbitrations are not allowed. 
23.9.	Notwithstanding this agreement to arbitrate disputes (as provided above), the following exceptions will apply to the resolution of disputes between �mylaw� and users:
23.9.1.	�mylaw� may bring a lawsuit against the User in any court of competent jurisdiction for injunctive relief to stop any unauthorized use or abuse of the �mylaw� services without first engaging in arbitration or the informal dispute resolution process described above. 
23.9.2.	�mylaw� may bring a lawsuit against the User in any court of competent jurisdiction solely for injunctive relief to stop any intellectual property infringement without first engaging in arbitration or the informal dispute resolution process described above.
23.9.3.	In the event that the agreement to arbitrate; as provided herein, is found to be inapplicable or unenforceable for any reason, then as a condition to the use of the �mylaw services; �mylaw� and the User expressly agree that any resulting judicial proceedings will be brought in the Federal High Court, Lagos Division, and by use, the User expressly consents to the venue and jurisdiction of the courts therein. 
23.9.4.	�mylaw� may in the future, make changes to the provisions of these Terms and Conditions regarding Dispute resolution and arbitration by providing notice in accordance with the section of these Terms and Conditions as described in �Modifications� below. 
23.9.5.	The User may reject any changes made during the User�s use by sending �mylaw� written notice. Any such notice shall be given within thirty[30] calendar days of the notice of modification to the following address:
Virtual Platform Limited 
[address]
Attention: Legal Department.

24.	Modification
24.1.	�mylaw� reserves the right to unilaterally revise these Terms and Conditions from time to time, and the revised version of same shall be available on the �mylaw� website.   
24.2.	In the event that, in the opinion of �mylaw�, any revision meaningfully reduces User�s rights, �mylaw� shall notify the User by any means deemed by �mylaw� as sufficient: such as replacing the existing terms with the revised version. 
24.3.	By the User�s continued use or access of �mylaw� Services at any moment after which such  revisions come into effect, the User agrees to be bound by the revised Terms and Conditions.  

25.	Applicable Law
25.1.	These Terms and Conditions shall and will be governed by the Laws of the Federal Republic of Nigeria which shall for all intent and purposes and be the governing law of these Terms and Conditions.

26.	Entire Agreement
26.1.	These Terms and Conditions constitute the entire agreement between the User and �mylaw� with respect to its subject matter, and supersedes and replaces any other prior or contemporaneous agreements, or terms and conditions applicable to the subject matter of these Terms and Conditions. 
26.2.	These Terms and Conditions shall in no way be construed as creating third party beneficiary rights.

27.	Waiver, Severability and Assignment
27.1.	The failure of �mylaw� to enforce any provision in these Terms and Conditions is not and shall not be construed as a waiver of its right to do so at a later time. 
27.2.	In the event that any provision of these Terms and Conditions is found unenforceable, the remaining provisions of the Terms and Conditions will remain in full effect and be enforceable, and such term which reflects the intent of �mylaw� as nearly as possible shall be reflected in the agreement instead of any severed term.  
27.3.	The User may not assign any rights under these Terms and Conditions, and any such attempt to do so shall be void and of not effect. Provided that �mylaw� shall reserve the right to assign its rights to any of its affiliates or subsidiaries, or to any successor in interest of any business associated with the �mylaw� services.  






Terms and Conditions
Terms of Service  
This page states the Terms and Conditions under which you may use the website www.xxxx.com or mobile apps available for download (referred to as the Site). Please read these Terms and Conditions carefully as it governs your access to and use of the Site and apply to all services and sub-sites available under the domain name xxx.com, as well as all text, audio, video, images, graphics, materials, online communications and other information and content that are or become available on the Sites (collectively, the �Information�). By using the Web Sites, you (�you� or �User�) signify your acceptance of these Terms and your acknowledgement that the information that you provide, directly or indirectly, through the Sites will be managed in accordance with the mylaw Privacy Notice. As such, if you do not accept the Terms and Conditions stated here, do not use the Site.
The Site contains certain services and related features that may require registration or subscription. If you open and operate an account on the Site, you shall ensure that you are lawfully authorised to provide all information submitted by you and all such information is true and accurate. You are responsible for updating all information supplied once there is any change. mylaw reserves the right to suspend or terminate your access to any account created or any restricted portion of the Site should it discover that any information provided by you is no longer true or accurate.
You are entirely responsible for all activities that occur under such account and you are solely responsible for keeping details of such password and account safe and secure at all times. If you detect or become aware of any unauthorised activity or use of your account or password on the Site, you shall immediately notify mylaw of same to enable mylaw immediately disable access to such account.
mylaw shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with, use of your account on the Site or failure to comply with these Terms and Conditions.
  
General 
mylaw leverages technology to provide individuals and Micro, Small and Medium Enterprises (MSMEs) access to affordable legal services through multiple platforms. Services provided by mylaw can be broadly categorized as follows: 
�	Document Repository App: An online source for standard legal documents that enable Users develop customised legal documents by responding to questions.
�	Virtual Law Firm: Is a web-based advisory platform through which �mylaw� provides auxiliary assistance to Users for sharing and resolving legal and regulatory issues and/or queries.
�	Panel of Law Firms: is an online registration medium through which existing lawyers and law firms can purvey legal services to willing Users; and/or respond to User queries as channeled on the Platform.  
These Terms and Conditions stated below, govern the use of �mylaw�, and are binding between you and �mylaw� and/or its affiliates, being providers of the �mylaw� services. 
1.	Interpretation and Definitions
For the purposes of this Terms and Conditions, the following expressions shall mean:
Affiliates shall mean and/or include Virtual Platform Limited and any such other company as is designated by �mylaw� as its affiliate
�mylaw� shall mean all that in these Terms and Conditions, except where the context so admits mean all services provided by Virtual Platform Limited on the mylaw website including the �Document Repository App�, �Virtual Law Firm� and �Panel of Law Firms�, and any and all such services as may be developed by Virtual Platform Limited and hosted on the mylaw website. 
�mylaw� services shall where the context so admits mean all services provided by �mylaw� on the �mylaw� website including the �Document Repository App� , �Virtual Law Firm� and �Panel of Law Firms�, any and all such services as may be developed by and/or for �mylaw� and hosted on the mylaw website
�mylaw� website shall mean all that proprietary information contained, prepared, presented and hosted by �mylaw� at [http/: www. ] and/or any other website so owned by �mylaw� for the purposes of providing �mylaw� services.  
Officers shall where the context so admits mean officers, employees, directors and agents of the organization, company or firm being referred to. 
Participating Attorneys means any and all �mylaw� registered lawyers and/or law firms on the Panel of Law Firms
�Suppliers� means any individual, firm or organization designated by �mylaw� or with whom �mylaw� contracts as a partner, agent and such other relationships for the purpose of providing or distributing �mylaw� services to the User.  
Terms and Conditions means the Terms and Conditions contained herein. 
Virtual Platform Limited means the body corporate with rights and ownership to the mylaw trademark, and all such services as are provided on the mylaw website, which expression shall include its successors in title and assigns.
User shall mean any and all visitors, and all registered accountholders on the mylaw website.
2.	Contractual Relationship
2.1.	These Terms and Conditions of Use govern your access to, and use of �mylaw� operated by Virtual Platform Limited. 
2.2.	By continuing usage of mylaw, you consent to be bound by these Terms and Conditions for use of and access to �mylaw�. 
2.3.	The User is required to keep these terms and conditions as they constitute a binding agreement between User and �mylaw�.
3.	The Agreement
3.1.	I (�User�) confirm and agree that the following terms and conditions shall govern my use of the mylaw platform and services. 
3.2.	By clicking on the �I AGREE� button or a similar affirmation, or by acknowledging acceptance of these Terms and Conditions by another by another method allowed by �mylaw�, or by using or accessing �mylaw� through any means permissible including, without limitation via a computer or a mobile application; User acknowledges and agrees that: 
3.2.1.	It has reviewed and understands these Terms and Conditions;
3.2.2.	It agrees to be legally bound by these Terms and Conditions; and 
3.2.3.	Its use of mylaw and any related products or services will be governed by these Terms and Conditions 
3.3.	If the User does not agree or is not willing to be bound by these terms and conditions, User should not click on �I AGREE� button and/or should immediately discontinue use of mylaw. 

4.	User�s Capacity and Related matters 
By accepting these Terms and Conditions and/or the continued use of the mylaw, the User represents and warrants that:
4.1.	The User agreeing to these Terms and Conditions has attained the legal age of majority in your country of residence or otherwise able to form a binding contract with mylaw in order to use the Services. In no event is use of the Services permitted by those under the age of 18.
4.2.	All information provided by the User on and for the purposes of �mylaw� are at all times true, complete, accurate, neither fraudulent or misleading in any respect.
4.3.	User further represents and warrants that User has the legal authority to accept the Terms and Conditions herein set out and such acceptance will be binding on the User. 
4.4.	�mylaw� reserves the right to in its sole discretion, refuse to provide �mylaw� service to the User in any event that the User is in breach of any provisions of these Terms and Conditions. 
5.	Acceptance of Terms and Conditions of use
5.1.	For the avoidance of doubt, use of mylaw and/or any of its services constitutes acceptance of these Terms and Conditions, and any subsequent amendments which may be made to the �mylaw� terms and conditions from time to time.
6.	Trial offers, Auto-renewals and Paid accounts
6.1.	�mylaw� may make available trial offers for the limited use of mylaw to new or existing Users who provide a valid payment instrument, such as a credit card or other payment method. 
6.2.	Any User that chooses to start a Trial Offer, will automatically join the selected paid membership at the end of the Trial Offer. 
6.3.	User unequivocally authorizes �mylaw� to charge the provided payment method a recurring monthly subscription fee or annual subscription fee or Pay-As-You-Go (PAYG) cost, as applicable. 
6.4.	Any use of additional Services beyond those included in the Trial Offer, will require a separate purchase of those Services or an upgrade to a paid membership prior to the end of the Trial Offer period.
6.5.	�mylaw� may automatically charge the User at the end of the trial or for a membership renewal unless the User gives notice of intention to cancel or convert your membership. 
6.6.	User can downgrade a subscription membership to a PAYG account at any time.
6.7.	To ensure uninterrupted service and to enable User conveniently purchases additional products and services, �mylaw� will store and update (e.g. upon expiration) User payment method on file. 
6.8.	In the event that mylaw is unable to charge the Users designated payment method for any reason, mylaw reserves the right to automatically convert User�s paid plan to PAYG account or to suspend the paid membership until the designated payment method can be charged again. 
6.9.	For the avoidance of doubt, it is User�s responsibility to maintain current billing information on file with �mylaw�.

7.	Payment Obligations
7.1.	Upon initiation and confirmation of a transaction on �mylaw�, the User agrees to be bound by and pay for any such transaction. User is enjoined to not commit to a transaction unless able and ready to pay for and have confirmation of successful payment. 
7.2.	User warrants that all payment information provided is accurate, not misleading and not fraudulent in any respect; and all payment transactions for mylaw are final; and non-refundable except in the sole discretion of �mylaw�.
7.3.	To prevent financial loss or possible violations of the Law, �mylaw� reserves the right to in its discretion disclose details of any payment associated with or made by the User, with law enforcement agencies, or impacted third parties (including other users). Such disclosures may originate from an order of any court of competent jurisdiction to compel such disclosure. 
7.4.	User agrees and confirms that User will not use �mylaw� in violation of any law in force in the Federal Republic of Nigeria or such other law which may occasion liability of any sort on �mylaw�. 
7.5.	SMS charges or any such other charges (where applicable) by communication network operators may be applied for each payment transaction conducted on the �mylaw� website.
8.	Refund Policy 
8.1.	�mylaw� desires the optimum satisfaction of the User as regarding services provided on the �mylaw� website. 
8.2.	In the event that the User is less than satisfied or believes there has been an error in billing, User should contact our Customer Service Department by email at [customer service email address] or by telephone at [customer service phone number] immediately so that such issue may be resolved, by providing a refund or offering credit that can be used for future Services. 
8.3.	The relevant mode of refund for complaints made will be determined in the sole discretion of �mylaw�, and the User shall under no circumstances be entitled to any claim arising from or in connection with the refund method chosen by �mylaw�. 
8.4.	When communicating such complaint, User should include any and all details relating to the exact mylaw service or product purchased so as to ensure complete User satisfaction with the mylaw experience. 
8.5.	Refund requests must be made within [30 days] of purchase. Provided that any User seeking to cancel a subscription, may apply to receive a prorated refund for the remaining time which has not been used. In order to apply for a refund relating to a �mylaw� services, this is to be communicated by sending an email to [email address for refund requests] or call mylaw at [phone number for refund requests].
8.6.	Provided that, in the event that specific services require different refund request periods same shall be abided with by the User.
8.7.	 �mylaw� cannot and shall not be required to refund any money paid by User directly to third parties, such as payments made by Users directly to Participating Attorneys.

9.	�mylaw� is not a Law Firm 
9.1.	For the purposes of this Section, �mylaw� shall mean  as defined in Section 1 above, excluding the services provided in respect of the Virtual Law Firm
9.2.	�mylaw� provides a platform for legal information and self-help. Any and all such information provided by �mylaw� along with the content on �mylaw� website related to legal matters ("Legal Information") is provided for User�s private use and does not constitute legal advice.
9.3.	�mylaw� does not review any information provided on the Document Repository App for legal accuracy or sufficiency, draw legal conclusions, provide opinions about your selection of documents, or apply the law to the facts of the presented situation.
9.4.	Any User in need of legal advice for a specific problem, should consult with licensed attorney or communicate such queries on the Virtual Law Firm. No Legal Information provided by �mylaw� is a substitute for legal advice from a qualified attorney licensed to practice in an appropriate jurisdiction.
9.5.	As �mylaw� is not a law firm, please note that any communication between the User and a �mylaw� may not be protected as confidential information under the attorney-client privilege doctrine.
9.6.	Use of the �mylaw� does not create an attorney-client relationship between the User and �mylaw�, or between the User and any employee or representative of �mylaw� and/or its affiliates. Unless User is otherwise represented by an attorney, including a Participating Attorney, User represents itself in any legal matter you undertaken on �mylaw� Services.
9.7.	�mylaw� is not a "Lawyer Referral Service". The Directory of Lawyers and Law firms published on our Panel of Law Firms is provided to the public for informational purposes only. �mylaw� does not endorse or recommend any attorney nor does it make any warranty as to the qualifications or competency of any attorney.
10.	Interaction on �mylaw�
10.1.	Users will have the opportunity to initiate contact with Participating Attorneys.
10.2.	Participating Attorneys are neither employees nor agents of mylaw and are third-party independent contractors who have agreed to provide online answers, limited consultations or other basic legal services to �mylaw� users. 

11.	Profiles of Participating Attorneys on mylaw 
11.1.	Attorney profiles on the �mylaw� website are advertisements and should be interpreted as such. 
11.2.	�mylaw� does not endorse to any User the proficiency or competence of any Participating attorney on the �mylaw� website. 
11.3.	Participating Attorneys are third-party independent contractors solely responsible for the advice they give, the services they provide and the representations they make about themselves. As such, Users are solely responsible for assessing the quality, integrity, suitability and trustworthiness of all persons with whom they communicate legal needs on the �mylaw� website.
12.	Termination of subscription, membership and access restriction
12.1.	�mylaw� reserves the right, in its sole discretion, to downgrade or terminate your access to �mylaw�, for any reason and at any time without prior notice. 
12.2.	If �mylaw� elects to terminate User�s account, �mylaw� will provide User with notice of same sent to the registered email address of the User.
12.3.	�mylaw� reserves the right to modify or discontinue, either temporarily or permanently, any part of the �mylaw� services with or without notice to the User.
12.4.	The User agrees that �mylaw� will not be liable to the User or any third party claiming through the User for any modification, suspension, or discontinuance of the User�s subscription or membership or access on �mylaw�. 
13.	Ownership and preservation of documents
13.1.	�mylaw� does not claim ownership of any documents, files or media (collectively referred to in this section as Documents) you either create, upload or store using �mylaw� . 
13.2.	The User hereby grants permission to �mylaw� and/or its Participating Attorney�s to use your Documents in connection with providing services to you. 
13.3.	The User acknowledges and agrees that �mylaw� may preserve these Documents as well as disclose them if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to accomplish any of the following: 
13.3.1.	To comply with legal process, any applicable law or government requests;
13.3.2.	To enforce the provisions of these Terms and Conditions
13.3.3.	To respond to claims that any content violates the rights of third parties; or 
13.3.4.	To protect the rights, property, or personal safety or �mylaw�, its users and the public.
13.4.	The User understands that the technical processing and transmission of �mylaw�, including User�s content, may involve transmissions over various networks and changes to conform and adapt to technical requirements of connecting networks or devices. As such User agrees that �mylaw� has no responsibility or liability for deleting or failing to store any content maintained or uploaded for use on �mylaw�. 
14.	Consent to receive emails
14.1.	By creating an account, the User agrees that the User may receive email communications from �mylaw�, such as newsletters, special offers, account reminders and updates. 
14.2.	The User understands that the User can discontinue receipt of these communications by clicking the �Unsubscribe� link in the footer of the actual email. 
15.	Acceptable use of Communication Services
15.1.	The �mylaw� services include a large number of what are referred to in this Section as Communication Services which include but are not limited to live chats, comment threads, question and answer products, customer service communication forums, and other message services. 
15.2.	The User agrees to use the Communication services only to post, send and receive messages or materials proper to and related to the particular Communication Services. 
15.3.	The User agrees that when using the Communication Services, the User will not : 
15.3.1.	Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of any others.
15.3.2.	Publish, post, upload, distribute or disseminate any names, materials, or information that is considered inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful. 
15.3.3.	Create a false identity, represent self as someone else or otherwise falsify or delete in an uploaded file any significant attributions or notices. 
15.3.4.	Upload files that contain software or other material protected either by intellectual property laws or by the rights of privacy or publicity except when the necessary rights or consent to such material or software is owned or controlled, or obtained(as applicable).
15.3.5.	Upload corrupted files, files that contain viruses, or any other files that may damage the operation of another�s computer. 
15.3.6.	Advertise, offer to sell, or offer to buy anything for business purposes except to the limited extent any particular Communication service specifically allows such activity. 
15.3.7.	Restrict or inhibit any other user from using and enjoying the Communication Services specifically allows such activity. 
15.3.8.	Harvest or otherwise collect personally identifiable information about others, without their consent. 
15.3.9.	Violate any code of conduct or other guidelines, which may be applicable for any particular Communication Services.
15.3.10.	Violate any applicable laws or regulations. 
15.4.	Although �mylaw� has no obligation to monitor the Communication Services, �mylaw� reserves the right, in its own discretion, to review and remove materials posted to a Communication service, in whole or in part. 
15.5.	�mylaw� reserves the right to disclose any material posted, information or activity as necessary to satisfy any applicable law, regulation, legal process, or governmental request.      

16.	No Unlawful or Prohibited Use
16.1.	User can only make use of �mylaw services if �mylaw� services do not conflict with or violate the laws of User�s jurisdiction.
16.2.	The availability of �mylaw� services in User�s jurisdiction is not an invitation or offer by �mylaw� to access or use �mylaw� website and its services.
16.3.	By using �mylaw� services , the User accepts sole responsibility that such use does not violate any applicable law in User�s jurisdiction 
16.4.	The following are specifically excluded and/or prohibited:
16.4.1.	Use in connection with any legal matter that is frivolous, immaterial or illegal in nature, as determined by �mylaw� or the Participating Attorney in their sole discretion;
16.4.2.	Use in connection with any legal matter involving the laws of jurisdictions outside of Nigeria;
16.4.3.	Use in connection with any legal matter for which the User is currently or prospectively represented by legal counsel;
16.4.4.	Use in any legal matter that directly involves �mylaw�, Virtual Platform Limited or any of its affiliates, directors, agents, employees, or other �mylaw� service providers; or 
16.4.5.	Hack, �scrape� or �crawl� the �mylaw� website whether directly or through intermediaries including but not limited to spiders
16.5.	In enforcement of this section, �mylaw� reserves the right to refuse membership, or suspend or terminate User�s account immediately and without prior notice.

17.	Licence Grant
17.1.	Subject to the User�s compliance with these Terms and Conditions. The User is hereby granted a non-exclusive, limited, non-transferable, revocable licence to use �mylaw� services as �mylaw� intends them to be used. 
17.2.	User is licensed to keep, for personal records, electronic or physical copies of documents created on �mylaw�.
17.3.	User shall not copy the content of �mylaw� documents for use or sale outside of �mylaw�. 
17.4.	Any and all rights not expressly granted in these Terms and conditions are reserved by �mylaw�.
17.5.	Upon transmission of content on �mylaw�, User hereby grants �mylaw� and its affiliates a nonexclusive, royalty-free, perpetual, revocable and fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display any such content, including throughout the world in any media. 
17.6.	Any feedback and/or suggestions submitted by the User, may be used by �mylaw� without obligation to the User.
17.7.	Any resale or unauthorized distribution or duplication of materials downloaded from the �mylaw� website is strictly prohibited. Use of any such materials is for personal, business or educational use only.   Any resale or redistribution of �mylaw� resources without express written consent by �mylaw� is strictly prohibited.  
18.	Intellectual Property Rights
18.1.	�mylaw� retains all right, title and interest in and to the products and services provided by it on the �mylaw� website including without limitation: software, images, text, graphics, illustrations, logos, service marks, copyrights, photographs, videos, music, and all related intellectual property rights. Except as otherwise provided in this Terms and Conditions, the User shall not and shall not  permit others to:
18.1.1.	Reproduce, modify, translate, enhance, decompile, disassemble, reverse engineer or create derivative works of any of the �mylaw� products and services;
18.1.2.	Sell, license, sublicense, rent, lease, distribute, copy, publicly display, publish, adapt or edit any of the �mylaw� products and services; or
18.1.3.	Circumvent, or disable any security or technological features of the �mylaw� products and/or services. 
18.2.	The design, text, graphics and selection and arrangement thereof, and services and the legal documents, guidance and all other content found on the �mylaw� website and all other content found on the website are copyright of mylaw� . All rights reserved. 
18.3.	mylaw� is a registered trademark in the Nigerian Registry of Trade Marks, Patents, Designs and Copyright. This and any other �mylaw� product or service names or slogans displayed on �mylaw� products are trademarks of �mylaw�.
18.3.1.	User shall not copy imitate or use them, in whole or in part, without prior consent of �mylaw�. 
18.3.2.	In addition, the look and feel of �mylaw� is th service mark, trademark and/or trade identity of �mylaw� and the User may not copy, imitate or use same without prior written consent of �mylaw�. 
18.3.3.	The names of actual companies and products which the User may encounter through �mylaw� may be the trademarks of its respective owners. 
18.3.4.	Nothing in these Terms and Conditions shall be understood as in any way granting any license or right to use any of mylaw�s trademarks displayed on the �mylaw� website.
18.3.5.	All goodwill generated from the use of mylaw�s trademarks is reserved for the use of �mylaw�, exclusively.
19.	Copyright Infringement
19.1.	   If the User believes that any material on the �mylaw� website infringes upon any copyright owned and/or controlled by the User, the User may file a Notice of alleged Infringement with mylaw�s Designated Copyright Agent:

Virtual Platform Limited
Attn: Legal Department
[address]
[email]
20.	Links to Third Party Sites
20.1.	�mylaw� websites may contain links to third party resources and businesses on the Internet(referred to in this section as �Links�). 
20.2.	These Links are provided for User convenience to help the User identify and locate other internet resources that may be of interest to the User. �mylaw� does not sponsor and is not legally associated with any third party Links. 
20.3.	�mylaw� is not legally authorised to use any trade name, registered trademark, logo, official seal or copyrighted material that may appear in the Links.
20.4.	�mylaw� does not control, endorse or monitor the content(s) of any Links, and any changes or updates to any such Link.
20.5.	�mylaw� is not responsible for webcasting or for any other form of transmission received from any Link. 
20.6.	These Terms and Conditions do not cover User interaction with Links. 
20.7.	User has the sole responsibility to carefully review the Terms and Conditions and Privacy Policy of any Links and Linked sites, and any third party sites connected thereto.
20.8.	In respect of the use of any service provided by any link or on any Linked site:
20.8.1.	�mylaw� is not and will not be responsible for any act or omission of the third party; including any third party access to or use of User data.
20.8.2.	�mylaw� does not warrant or support any service provided by the Third Party. 
21.	Disclaimer of Responsibility and Liability
21.1.	The information, Software, product and services made available through �mylaw� may include inaccuracies or typographical errors.
21.2.	�mylaw� and/or its suppliers may at any time make improvements or changes to the �mylaw� services.
21.3.	Any and all information received via �mylaw� should not be relied upon for any personal, legal, or financial decisions. 
21.4.	User should consult an appropriate professional for specific advice tailored to the unique circumstances of the User. 
21.5.	TO THE FULLEST EXTENT PERMITTED BY LAW, �mylaw� AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE SERVICES ARE PROVIDED �AS IS�. 
�mylaw� ALSO DISCLAIMS ANY WARRANTIES OR MERCHANTIBILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. INFORMATION AND OPINIONS RECEIVED VIA THE WEBSITE SHOULD BE RELIED UPON FOR PERSONAL, LEGAL OR FINANCIAL DECISIONS ONLY UPON CONSULTATION WITH AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO THE USER�S SITUATION.
21.6.	TO THE FULLEST EXTEN PERMITTED BY LAW, IN NO EVENT WILL �mylaw� AND/OR ITS AFFILIATES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, REGARDLESS OF LEGAL THEORY, WHETHER OR NOT �mylaw� HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE. 
22.	Release and Indemnity 
22.1.	The User personally and on behalf of the User�s heirs, executors, agents, representatives, and assigns, fully releases, forever discharges, and holds �mylaw�, and/or its affiliates, its respective harmless form any and all losses, damages, expenses, including reasonable attorney�s fees, rights, clams, and actions of any kind and injury (including death) arising out of or relating to the use of the �mylaw� Services.
22.2.	User agrees that this release has been freely and voluntarily consented to and confirm that the User fully understand the purpose, intent, form and import of these Terms and Conditions.
22.3.	The User agrees to indemnify and hold �mylaw� and/or its affiliates and their respective officers from any and all losses, damages, expenses, including reasonable attorneys� fees, rights, claims, actions of any kind and injury (including death) arising out of any third party claims relating to use of the �mylaw� services, User violation of the Terms and Conditions, and/or User violation ot the rights of another in relation to these Terms and Conditions.    
23.	Dispute Resolution 
23.1.	User concerns can be resolved quickly and to the Users satisfaction by calling the �mylaw� Customer Care Lines at [phone number] and/or sending an email to [email address]
23.2.	However, in the event that �mylaw� is not able to resolve a dispute with the User after attempts to do so informally, then as a condtion to use of the services, the User agrees with �mylaw� that such disputes, claims, rights arising thereof shall be resolved through binding arbitration. 
23.3.	The Arbitration and Conciliation Act, Cap A18 Laws of the Federal Republic of Nigeria shall be the applicable law for such proceedings. 
23.4.	The arbitration proceedings shall be held in Lagos, Nigeria or any other location as to which the �mylaw� and the User may then agree. 
23.5.	Any party seeking arbitration must first Send to the other, by certified or registered mail a written notice of dispute.
23.6.	 Any notice to �mylaw� should be addressed to:
 Virtual Platform Limited, 
[Address], 
Attention: Legal Department. 
23.7.	Any notice to be sent by �mylaw� to the User shall be sent to the User address set for in �mylaw� records of account or such other legal address as �mylaw� is able to identify.
23.8.	The User may only resolve disputes with �mylaw� or Participating Attorneys on an individual basis, and may not bring a claim as claimant or plaintiff or a class member in a class, consolidated or representative action. Class arbitrations, class actions, private attorney general actions, and consolidations with other arbitrations are not allowed. 
23.9.	Notwithstanding this agreement to arbitrate disputes (as provided above), the following exceptions will apply to the resolution of disputes between �mylaw� and users:
23.9.1.	�mylaw� may bring a lawsuit against the User in any court of competent jurisdiction for injunctive relief to stop any unauthorized use or abuse of the �mylaw� services without first engaging in arbitration or the informal dispute resolution process described above. 
23.9.2.	�mylaw� may bring a lawsuit against the User in any court of competent jurisdiction solely for injunctive relief to stop any intellectual property infringement without first engaging in arbitration or the informal dispute resolution process described above.
23.9.3.	In the event that the agreement to arbitrate; as provided herein, is found to be inapplicable or unenforceable for any reason, then as a condition to the use of the �mylaw services; �mylaw� and the User expressly agree that any resulting judicial proceedings will be brought in the Federal High Court, Lagos Division, and by use, the User expressly consents to the venue and jurisdiction of the courts therein. 
23.9.4.	�mylaw� may in the future, make changes to the provisions of these Terms and Conditions regarding Dispute resolution and arbitration by providing notice in accordance with the section of these Terms and Conditions as described in �Modifications� below. 
23.9.5.	The User may reject any changes made during the User�s use by sending �mylaw� written notice. Any such notice shall be given within thirty[30] calendar days of the notice of modification to the following address:
Virtual Platform Limited 
[address]
Attention: Legal Department.
24.	Modification
24.1.	�mylaw� reserves the right to unilaterally revise these Terms and Conditions from time to time, and the revised version of same shall be available on the �mylaw� website.   
24.2.	In the event that, in the opinion of �mylaw�, any revision meaningfully reduces User�s rights, �mylaw� shall notify the User by any means deemed by �mylaw� as sufficient: such as replacing the existing terms with the revised version. 
24.3.	By the User�s continued use or access of �mylaw� Services at any moment after which such  revisions come into effect, the User agrees to be bound by the revised Terms and Conditions.  
25.	Applicable Law
25.1.	These Terms and Conditions shall and will be governed by the Laws of the Federal Republic of Nigeria which shall for all intent and purposes and be the governing law of these Terms and Conditions.

26.	Entire Agreement
26.1.	These Terms and Conditions constitute the entire agreement between the User and �mylaw� with respect to its subject matter, and supersedes and replaces any other prior or contemporaneous agreements, or terms and conditions applicable to the subject matter of these Terms and Conditions. 
26.2.	These Terms and Conditions shall in no way be construed as creating third party beneficiary rights.
27.	Waiver, Severability and Assignment
27.1.	The failure of �mylaw� to enforce any provision in these Terms and Conditions is not and shall not be construed as a waiver of its right to do so at a later time. 
27.2.	In the event that any provision of these Terms and Conditions is found unenforceable, the remaining provisions of the Terms and Conditions will remain in full effect and be enforceable, and such term which reflects the intent of �mylaw� as nearly as possible shall be reflected in the agreement instead of any severed term.  
27.3.	The User may not assign any rights under these Terms and Conditions, and any such attempt to do so shall be void and of not effect. Provided that �mylaw� shall reserve the right to assign its rights to any of its affiliates or subsidiaries, or to any successor in interest of any business associated with the �mylaw� services.  





DESCRIPTION OF DOCUMENT
A partnership agreement is a contract between two or more parties which sets out the terms and conditions of the relationship between them as partners. Among other things, the Partnership Agreement sets out the nature of the business, capital contributed by each partner, and their rights and responsibilities.

FREQUENTLY ASKED QUESTIONS (FAQ�s)
What is a Partnership? 
A partnership is a business arrangement of two to twenty people with the primary aim of making profit. 
 Do I need a Partnership Agreement? 
Although a Partnership can be formed orally, in writing or even by conduct, it is advisable to have a
Partnership Agreement. This is because a Partnership Agreement helps to:
a.	Outline the roles and responsibilities of the partners 
b.	Describe the decision making process of the partnership 
c.	Deal with issues that may come up like: death of a partner, dissolution of the partnership, exit of a partner etc. 
How will the Partnership pay tax?
The tax obligation of a partnership business is borne by the individual partner. To this extent, tax is levied on the separate incomes of the partners in the business. 

PARTNERSHIP AGREEMENT 
This PARTNERSHIP AGREEMENT is made this __day of ____________ 20__ between___________1a____________ of _____________1b____________________ (Hereinafter referred to as �___________1c____________�) and ______________2a___________________ of _________________2b__________________ (Hereinafter referred to as �___________2c____________). 
The parties shall hereinafter be referred to as Partners jointly and Partner individually 

WHEREAS: 
1.	The Parties intend to carry on the Business described in Clause 1.1 as a partnership. 
2.	 The Parties by means of this Agreement, define their participation, rights and obligations in carrying on business and intend to establish the principles of co-operation with respect to carrying out the business of the partnership.  

In consideration of the mutual promises contained in this agreement, Partners agree as follows:
1.	NAME AND BUSINESS.
1.1	The parties hereby form a partnership under the name of ________3a________ to conduct the business of ____________________3b_____________________________.
1.2	The principal place of business shall be at ______________3c__________________.

2.	TERM
2.1	The partnership shall begin on the __ day of ____________, 20__ and shall continue until terminated in accordance with Clause 14

3.	CAPITAL
3.1	The capital of the Partnership shall be contributed in cash or property by the partners in agreed upon value as provided in Appendix A (the �Capital Contribution�)
3.2	Any withdrawal from the Capital Contribution shall be mutually agreed by the Partners.
3.3	Capital Contributions may be amended form time to time, according to the requirements of the Partnership provided that the interests of the Partners are not affected, except with the unanimous consent of the Partners. Whenever additional capital is determined to be required and either Partner is unwilling or unable to meet the additional contribution requirement within a reasonable period, the other Partner may contribute in proportion to their existing Capital Contributions to resolve the amount in default. In such a case, the allocation of profits or losses among all the Partners will be adjusted to reflect the aggregate change in Capital Contributions by the Partners. 
3.4	An individual capital account (the �Capital Account�) will be maintained for each Partner and their initial Capital Contribution will be credited to this account. Any additional Capital Contributions made by any Partner will be credited to that Partner�s individual Capital Account. 
3.5	No interest shall be paid on the initial contributions to the capital of the partnership or on any subsequent contributions of capital.

4.	PROFIT AND LOSS.
4.1.	The net profits of the partnership shall be divided equally between the partners and the net losses shall be borne equally by them. 
4.2.	A separate income account shall be maintained for each partner. 
4.3.	Partnership profits and losses shall be charged or credited to the separate income account of each partner as per Clause 4.2  
4.4.	If a partner has no credit balance in his income account, losses shall be charged to his Capital Account.
4.5.	Decisions regarding the distribution of profits, allocation of losses and the requirement for additional Capital Contributions as well as other financial matters will be decided by a unanimous vote of the Partners. 

5.	SALARIES AND DRAWINGS.
5.1.	Unless otherwise unanimously agreed by the Parties, neither partner shall receive any salary for services rendered to the Partnership. 
5.2.	Each Partner may, from time to time, withdraw the credit balance in his income account.

6.	TITLE TO THE PARTNERSHIP PROPERTY
Title to all property acquired by the Partnership will remain in the name of the Partnership (Partnership Property). No partner will have any ownership rights in such Partnership Property.

7.	MANAGEMENT DUTIES AND MEETINGS.
7.1.	The partners shall have equal rights in the management of the partnership business, and each partner shall devote his entire time to the conduct of the business. 
7.2.	No partner shall without the consent of the other partner on behalf of the partnership borrow or lend money, or make, deliver, or accept any commercial paper, or execute any mortgage, security agreement, bond, or lease, or purchase or contract to purchase, or sell or contract to sell any property for or of the partnership on behalf of the partnership other than the type of property bought and sold in the regular course of its business. For the avoidance of any doubt, all actions and decisions in respect of the management, operation and control of the Partnership and its business will be decided by a unanimous vote of the Partners except all Partners agree otherwise in writing. 
7.3.	Regular meetings of the Partners will be held ________4________
7.4.	Any Partner can call a special meeting to resolve issues that require urgent attention by providing reasonable notice to the other Partner. Discussions at the meeting will be restricted to the specific purpose for which the meeting was called.
7.5.	Any Partner is permitted to participate in a meeting by means of telephone, audio and/or video conferencing or other communication facilities. 

8.	BANKING.
8.1.	All funds of the partnership shall be deposited in its name in such checking account or accounts as shall be designated by the Partners. 
8.2.	All withdrawals therefrom are to be made upon cheques signed by either partner.

9.	BOOKS, ACCOUNTS AND AUDIT.
9.1.	The partnership books shall be maintained at the principal office of the partnership, and each partner shall at all times have access thereto. 
9.2.	The partnership books shall be kept on a fiscal year basis and shall be closed and balanced at the end of each fiscal year. The Partners shall agree when a fiscal year is deemed to start and when it ends. 
9.3.	 As soon as practicable after the close of each fiscal year, the Partnership will furnish to each Partner an annual report showing a full and complete account of the condition of the Partnership. 
9.4.	Any of the Partners will have the right to request an audit of the Partnership books. The cost of the audit will be borne by the Partnership. The audit will be performed by an accounting firm acceptable to all Partners. Not more than one (1) audit will be required by any or all of the Partners for any fiscal year.

10.	NON-COMPETITION 
10.1.	No Partner will engage in any business, venture or transaction, whether directly or indirectly, that might be competitive with the business of the Partnership or that would be in direct conflict of interest to the Partnership without the unanimous written consent of the remaining Partners. Any and all businesses, ventures or transactions with any appearance of conflict of interest must be fully disclosed to all other Partners. Failure to comply with any of the terms of this clause will be deemed an involuntary withdrawal of the Partner (as described in clause 12.2) and may be treated accordingly by the remaining Partner.
10.2.	In the event that a Partner exits the Partnership whether voluntarily or involuntarily, such partner shall not directly or indirectly solicit existing clients of the Partnership or engage in a business which is or which would be competitive with the existing business of the partnership in the territory where the Partnership is currently doing business. The restriction contained in this Clause 10.2 shall apply for a period of __________5_____________ unless the remaining Partner(s) agree otherwise in writing. 

11.	ADMISSION OF  NEW PARTNERS
10.1.	A new Partner may only be admitted to the Partnership with a majority vote of the existing Partners. 
10.2.	Any new Partner agrees to be bound by all the covenants, terms and conditions of this Agreement, inclusive of all current and future amendments. A new Partner will also be required to execute such documents as are needed to effect the admission of the new Partner. Any new Partner will receive such business interest in the Partnership as determined by a unanimous decision of the other Partners. 

12.	WITHDRAWAL OF A PARTNER 
12.1.	Any Partner will have the right to voluntarily withdraw from the Partnership at any time upon service of written notice of intention to withdraw from the Partnership at least three (3) months before the withdrawal date. 
12.2.	Events which may occasion an involuntary withdrawal of a Partner from the Partnership include: death; mental incapacity; disability preventing reasonable participation in the Partnership; incompetence; breach of fiduciary duties; criminal conviction; or act/ omission of a Partner that can reasonably be expected to bring the business or reputation of the Partnership into disrepute. 
12.3.	Where the dissociation of a Partner for any reason results in the dissolution of the Partnership, the Partners will proceed in a reasonable and timely manner to dissolve the Partnership, with all debts being paid first, prior to any distribution of the remaining funds. 
12.4.	The remaining Partner(s) reserves the right to seek damages from a dissociation Partner where the dissociation resulted from either a malicious or criminal act by the dissociated Partner, breach of fiduciary duty or act or omission committed by the dissociated Partner that could reasonably be foreseen to bring harm or damage to the Partnership or to the reputation of the Partnership.  

13.	DEATH.
13.1.	Upon the death of either partner, the surviving partner shall have the right to purchase the interest of the deceased partner in the partnership or to terminate and liquidate the partnership business. 
13.2.	In the event that the surviving partner elects to purchase the deceased's interest pursuant to Clause 11.1, he shall serve notice in writing of such election, within three months after the death of the deceased, upon the executor or administrator of the deceased, or, if at the time of such election no legal representative has been appointed, upon the next-of-kin or any known legal heir, successor or assign of the deceased.
13.3.	The surviving partner shall be entitled to use the trade name of the partnership.

14.	DISSOLUTION AND DISTRIBUTION OF THE PARTNERSHIP PROPERTY.
The partnership may be dissolved and terminated at any time by the unanimous agreement of the partners, in which event the partners shall proceed with reasonable promptness to liquidate the business of the partnership. The partnership name shall be sold with the other assets of the business. The assets of the partnership business shall be used and distributed in the following order:
a.	to pay or provide for the payment of all partnership liabilities and liquidating expenses and obligations;
b.	to balance the income accounts of the partners;
c.	to discharge the balance of the income accounts of the partners;
d.	to balance the capital accounts of the partners; and
e.	to discharge the balance of the capital accounts of the partners.

15.	INDEMNIFICATION.
If at any time either of the Partners is required to pay or become liable for more than its proportion of the Partnership debts as provided for in this Agreement, that Partner shall have as against the other Partner a right of recovery of the appropriate proportion of the payment or indemnification against such liability, and the Partner shall have, on becoming liable for such debt, the first lien or charge on the capital and all other interest or interests of the offending Partner in the Partnership business.

16.	DISPUTE RESOLUTION.
Any controversy or claim arising out of or relating to this Agreement, or the breach hereof, the Partners shall expend their best efforts to settle any disputes arising between them amicably within 30 days. 
However, where the Parties are unable to reach an amicable resolution, such dispute, and controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap 19 Laws of the Federal Republic of Nigeria 2004. 

17.	ADDITIONS, ALTERATIONS, OR MODIFICATIONS.
17.1.	In the event that this agreement, or any terms and conditions contained in this agreement, are in any way ineffective or deficient, or not expressed as originally intended, the partners shall enter into, execute, and perform all further deeds and instruments as their counsel shall advise.
17.2.	 Any addition, alteration, or modification shall be in writing, and no oral agreement shall be effective. 




18.	GOVERNING LAW.
This agreement shall be governed by and construed in accordance with the Laws of the Federal Republic of Nigeria.

IN WITNESS OF WHICH the Parties have executed this Partnership Agreement in the manner below on the date indicated above.

SIGNED by the within named Partner 


_________________________
NAME:

IN THE PRESENCE OF: 
NAME:
ADDRESS
OCCUPATION:
SIGNATURE:

SIGNED by the within Partner


_________________________
NAME:

IN THE PRESENCE OF:
NAME:
ADDRESS:
OCCUPATION:
SIGNATURE:

REFERENCES/CONSIDERATIONS 
1a. Your name 
1b. Your address
2a. Your Partner�s name 
2b. Your Partner�s Address
3a. What is the name of the partnership business?
3b. What business will the partnership do? For example: �procurement and sale of landed property in Lagos State�
3c. What is the office address of the partnership?
4	How often should Partners have their meetings (e.g. weekly, monthly, quarterly) 
5	In the event that a Partner exits the partnership, for how long will the partner be prevented from carrying on a business similar to the partnership and from soliciting business from the Partnership�s existing clients (e.g. 6 months, 2 years)





DESCRIPTION OF DOCUMENT
A partnership agreement is a contract between two or more parties which sets out the terms and conditions of the relationship between them as partners. Among other things, the Partnership Agreement sets out the nature of the business, capital contributed by each partner, and their rights and responsibilities.

FREQUENTLY ASKED QUESTIONS (FAQ�s)
What is a Partnership? 
A partnership is a business arrangement of two to twenty people with the primary aim of making profit. 
 Do I need a Partnership Agreement? 
Although a Partnership can be formed orally, in writing or even by conduct, it is advisable to have a
Partnership Agreement. This is because a Partnership Agreement helps to:
a.	Outline the roles and responsibilities of the partners 
b.	Describe the decision making process of the partnership 
c.	Deal with issues that may come up like: death of a partner, dissolution of the partnership, exit of a partner etc. 
How will the Partnership pay tax?
The tax obligation of a partnership business is borne by the individual partner. To this extent, tax is levied on the separate incomes of the partners in the business. 

PARTNERSHIP AGREEMENT 
This PARTNERSHIP AGREEMENT is made this __day of ____________ 20__ between___________1a____________ of _____________1b____________________ (Hereinafter referred to as �___________1c____________�) and ______________2a___________________ of _________________2b__________________ (Hereinafter referred to as �___________2c____________). 
The parties shall hereinafter be referred to as Partners jointly and Partner individually 

WHEREAS: 
1.	The Parties intend to carry on the Business described in Clause 1.1 as a partnership. 
2.	 The Parties by means of this Agreement, define their participation, rights and obligations in carrying on business and intend to establish the principles of co-operation with respect to carrying out the business of the partnership.  

In consideration of the mutual promises contained in this agreement, Partners agree as follows:
1.	NAME AND BUSINESS.
1.1	The parties hereby form a partnership under the name of ________3a________ to conduct the business of ____________________3b_____________________________.
1.2	The principal place of business shall be at ______________3c__________________.

2.	TERM
2.1	The partnership shall begin on the __ day of ____________, 20__ and shall continue until terminated in accordance with Clause 14

3.	CAPITAL
3.1	The capital of the Partnership shall be contributed in cash or property by the partners in agreed upon value as provided in Appendix A (the �Capital Contribution�)
3.2	Any withdrawal from the Capital Contribution shall be mutually agreed by the Partners.
3.3	Capital Contributions may be amended form time to time, according to the requirements of the Partnership provided that the interests of the Partners are not affected, except with the unanimous consent of the Partners. Whenever additional capital is determined to be required and either Partner is unwilling or unable to meet the additional contribution requirement within a reasonable period, the other Partner may contribute in proportion to their existing Capital Contributions to resolve the amount in default. In such a case, the allocation of profits or losses among all the Partners will be adjusted to reflect the aggregate change in Capital Contributions by the Partners. 
3.4	An individual capital account (the �Capital Account�) will be maintained for each Partner and their initial Capital Contribution will be credited to this account. Any additional Capital Contributions made by any Partner will be credited to that Partner�s individual Capital Account. 
3.5	No interest shall be paid on the initial contributions to the capital of the partnership or on any subsequent contributions of capital.

4.	PROFIT AND LOSS.
4.1.	The net profits of the partnership shall be divided equally between the partners and the net losses shall be borne equally by them. 
4.2.	A separate income account shall be maintained for each partner. 
4.3.	Partnership profits and losses shall be charged or credited to the separate income account of each partner as per Clause 4.2  
4.4.	If a partner has no credit balance in his income account, losses shall be charged to his Capital Account.
4.5.	Decisions regarding the distribution of profits, allocation of losses and the requirement for additional Capital Contributions as well as other financial matters will be decided by a unanimous vote of the Partners. 

5.	SALARIES AND DRAWINGS.
5.1.	Unless otherwise unanimously agreed by the Parties, neither partner shall receive any salary for services rendered to the Partnership. 
5.2.	Each Partner may, from time to time, withdraw the credit balance in his income account.

6.	TITLE TO THE PARTNERSHIP PROPERTY
Title to all property acquired by the Partnership will remain in the name of the Partnership (Partnership Property). No partner will have any ownership rights in such Partnership Property.

7.	MANAGEMENT DUTIES AND MEETINGS.
7.1.	The partners shall have equal rights in the management of the partnership business, and each partner shall devote his entire time to the conduct of the business. 
7.2.	No partner shall without the consent of the other partner on behalf of the partnership borrow or lend money, or make, deliver, or accept any commercial paper, or execute any mortgage, security agreement, bond, or lease, or purchase or contract to purchase, or sell or contract to sell any property for or of the partnership on behalf of the partnership other than the type of property bought and sold in the regular course of its business. For the avoidance of any doubt, all actions and decisions in respect of the management, operation and control of the Partnership and its business will be decided by a unanimous vote of the Partners except all Partners agree otherwise in writing. 
7.3.	Regular meetings of the Partners will be held ________4________
7.4.	Any Partner can call a special meeting to resolve issues that require urgent attention by providing reasonable notice to the other Partner. Discussions at the meeting will be restricted to the specific purpose for which the meeting was called.
7.5.	Any Partner is permitted to participate in a meeting by means of telephone, audio and/or video conferencing or other communication facilities. 

8.	BANKING.
8.1.	All funds of the partnership shall be deposited in its name in such checking account or accounts as shall be designated by the Partners. 
8.2.	All withdrawals therefrom are to be made upon cheques signed by either partner.

9.	BOOKS, ACCOUNTS AND AUDIT.
9.1.	The partnership books shall be maintained at the principal office of the partnership, and each partner shall at all times have access thereto. 
9.2.	The partnership books shall be kept on a fiscal year basis and shall be closed and balanced at the end of each fiscal year. The Partners shall agree when a fiscal year is deemed to start and when it ends. 
9.3.	 As soon as practicable after the close of each fiscal year, the Partnership will furnish to each Partner an annual report showing a full and complete account of the condition of the Partnership. 
9.4.	Any of the Partners will have the right to request an audit of the Partnership books. The cost of the audit will be borne by the Partnership. The audit will be performed by an accounting firm acceptable to all Partners. Not more than one (1) audit will be required by any or all of the Partners for any fiscal year.

10.	NON-COMPETITION 
10.1.	No Partner will engage in any business, venture or transaction, whether directly or indirectly, that might be competitive with the business of the Partnership or that would be in direct conflict of interest to the Partnership without the unanimous written consent of the remaining Partners. Any and all businesses, ventures or transactions with any appearance of conflict of interest must be fully disclosed to all other Partners. Failure to comply with any of the terms of this clause will be deemed an involuntary withdrawal of the Partner (as described in clause 12.2) and may be treated accordingly by the remaining Partner.
10.2.	In the event that a Partner exits the Partnership whether voluntarily or involuntarily, such partner shall not directly or indirectly solicit existing clients of the Partnership or engage in a business which is or which would be competitive with the existing business of the partnership in the territory where the Partnership is currently doing business. The restriction contained in this Clause 10.2 shall apply for a period of __________5_____________ unless the remaining Partner(s) agree otherwise in writing. 

11.	ADMISSION OF  NEW PARTNERS
10.1.	A new Partner may only be admitted to the Partnership with a majority vote of the existing Partners. 
10.2.	Any new Partner agrees to be bound by all the covenants, terms and conditions of this Agreement, inclusive of all current and future amendments. A new Partner will also be required to execute such documents as are needed to effect the admission of the new Partner. Any new Partner will receive such business interest in the Partnership as determined by a unanimous decision of the other Partners.  

12.	WITHDRAWAL OF A PARTNER 
12.1.	Any Partner will have the right to voluntarily withdraw from the Partnership at any time upon service of written notice of intention to withdraw from the Partnership at least three (3) months before the withdrawal date. 
12.2.	Events which may occasion an involuntary withdrawal of a Partner from the Partnership include: death; mental incapacity; disability preventing reasonable participation in the Partnership; incompetence; breach of fiduciary duties; criminal conviction; or act/ omission of a Partner that can reasonably be expected to bring the business or reputation of the Partnership into disrepute. 
12.3.	Where the dissociation of a Partner for any reason results in the dissolution of the Partnership, the Partners will proceed in a reasonable and timely manner to dissolve the Partnership, with all debts being paid first, prior to any distribution of the remaining funds. 
12.4.	The remaining Partner(s) reserves the right to seek damages from a dissociation Partner where the dissociation resulted from either a malicious or criminal act by the dissociated Partner, breach of fiduciary duty or act or omission committed by the dissociated Partner that could reasonably be foreseen to bring harm or damage to the Partnership or to the reputation of the Partnership.  

13.	DEATH.
13.1.	Upon the death of either partner, the surviving partner shall have the right to purchase the interest of the deceased partner in the partnership or to terminate and liquidate the partnership business. 
13.2.	In the event that the surviving partner elects to purchase the deceased's interest pursuant to Clause 11.1, he shall serve notice in writing of such election, within three months after the death of the deceased, upon the executor or administrator of the deceased, or, if at the time of such election no legal representative has been appointed, upon the next-of-kin or any known legal heir, successor or assign of the deceased.
13.3.	The surviving partner shall be entitled to use the trade name of the partnership.

14.	DISSOLUTION AND DISTRIBUTION OF THE PARTNERSHIP PROPERTY.
The partnership may be dissolved and terminated at any time by the unanimous agreement of the partners , in which event the partners shall proceed with reasonable promptness to liquidate the business of the partnership. The partnership name shall be sold with the other assets of the business. The assets of the partnership business shall be used and distributed in the following order:
a.	to pay or provide for the payment of all partnership liabilities and liquidating expenses and obligations;
b.	to balance the income accounts of the partners;
c.	to discharge the balance of the income accounts of the partners;
d.	to balance the capital accounts of the partners; and
e.	to discharge the balance of the capital accounts of the partners.

15.	INDEMNIFICATION.
If at any time either of the Partners is required to pay or become liable for more than its proportion of the Partnership debts as provided for in this Agreement, that Partner shall have as against the other Partner a right of recovery of the appropriate proportion of the payment or indemnification against such liability, and the Partner shall have, on becoming liable for such debt, the first lien or charge on the capital and all other interest or interests of the offending Partner in the Partnership business.

16.	DISPUTE RESOLUTION.
Any controversy or claim arising out of or relating to this Agreement, or the breach hereof, the Partners shall expend their best efforts to settle any disputes arising between them amicably within 30 days. 
However, where the Parties are unable to reach an amicable resolution, such dispute, and controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap 19 Laws of the Federal Republic of Nigeria 2004. 

17.	ADDITIONS, ALTERATIONS, OR MODIFICATIONS.
17.1.	In the event that this agreement, or any terms and conditions contained in this agreement, are in any way ineffective or deficient, or not expressed as originally intended, the partners shall enter into, execute, and perform all further deeds and instruments as their counsel shall advise.
17.2.	 Any addition, alteration, or modification shall be in writing, and no oral agreement shall be effective. 
 



18.	GOVERNING LAW.
This agreement shall be governed by and construed in accordance with the Laws of the Federal Republic of Nigeria.

IN WITNESS OF WHICH the Parties have executed this Partnership Agreement in the manner below on the date indicated above.

SIGNED by the within named Partner 


_________________________
NAME:

IN THE PRESENCE OF: 
NAME:
ADDRESS
OCCUPATION:
SIGNATURE:

SIGNED by the within Partner


_________________________
NAME:

IN THE PRESENCE OF:
NAME:
ADDRESS:
OCCUPATION:
SIGNATURE:

REFERENCES/CONSIDERATIONS 
1a. Your name 
1b. Your address
2a. Your Partner�s name 
2b. Your Partner�s Address
3a. What is the name of the partnership business?
3b. What business will the partnership do? For example: �procurement and sale of landed property in Lagos State�
3c. What is the office address of the partnership?
4   How often should Partners have their meetings (e.g. weekly, monthly, quarterly) 
5   In the event that a Partner exits the partnership, for how long will the partner be prevented from carrying on a business similar to the partnership and from soliciting business from the Partnership�s existing clients (e.g. 6 months, 2 years)






DESCRIPTION
A non-compete agreement is a legal contract between two parties in which, party A agrees not to enter into or start a similar profession or trade which will be in competition with the existing business of party B. Non-compete agreements are most common with employments, partnerships and business collaborations where the business owner wants to prevent its staff or business associate from leaving the business to establish or join a rival company/business. 
This document features a non-compete agreement between an employee and the employer. 

FREQUENTLY ASKED QUESTIONS (FAQ�s)
Is a Non-Compete Agreement different from a Non-Disclosure Agreement?
Yes. The two contracts are distinct and serve different purposes. A non-compete agreement is made to prevent staff from joining or starting a business which will interfere or compete with the existing business of the company; while a non-disclosure agreement prevents the employee from revealing confidential information of the company to an unauthorized third party. 

Why would I need a Non-Compete Agreement?
A non-compete agreement is useful to:
A.	Prevent your employees from going to join or start a rival/competing business. 
B.	Prevent a departing employee from recruiting current employees of your business.
C.	Prevent your business from losing key customers when an employee leaves 
D.	Protect company investment in employee development 

What does a non-compete agreement contain?
Typically a non-compete agreement defines:
a.	What constitutes competing with the employer�s business
b.	How long the non-compete restriction will last
c.	The geographical area in which the non-compete will apply
d.	Compensation for the employee for the non-compete restriction(this is usually the salary) 
Should all my employees sign a non-compete?
It won�t be necessary for all the employees to sign a non-compete. The agreement is designed to prevent high level/management staff from polarizing company business when they leave the company. 


DRAFT 
This Non-Compete Agreement (hereinafter referred to as the �Agreement�) is entered into this ___day of _________________20__ (hereinafter referred to as the Effective Date) between _____1_____ a company incorporated and existing under the laws of _____2_____ with RC Number: _____3____ having its office address at _______4______ (hereinafter referred to as the Company) and ______5______ of _____6_____ (hereinafter referred to as the Employee, which expression shall herein, include its officers, affiliates, representatives, subsidiaries, partners and designates ).
The Company and the Employee are referred to individually as Party and collectively as Parties. 
WHEREAS:
A.	The Company is engaged in the business of ____7_____
B.	The Company and the Employee have agreed to enter into an employment relationship pursuant to the employment contract dated ____8____ (hereinafter referred to as the Contract of Employment). 
C.	In consideration of the Employee�s employment and the mutual covenants contained in the Contract of Employment and this Agreement, the Parties agree to be bound by the provisions of this Agreement. 

THE PARTIES AGREE AS FOLLOWS: 
1.	DEFINITIONS AND INTERPRETATION 
In this Agreement, the following expressions shall have the following meanings: 
�Agreement� shall mean this Non-Compete Agreement
�Company Business� shall mean the business activities of the Company as mentioned in Recital A above
�Competitive Business� shall include ownership of and/or employment, agency or participation(of any kind) in any firm, partnership, joint venture, corporation, consultancy, agency, advisory and/or any other entity, business combination, alliance which develops, manufactures, market, distributes, sell, advises, consults or engages in the same business as the Company Business.
Contract of Employment shall mean the employment contract existing between the Company and the Employee as indicated in Recital B above. 
Term shall mean the duration of this Agreement a provided in clause 2 below.   

2.	TERM 
This Agreement shall come into effect on the Effective Date, shall remain during the Contract of Employment and for ____9____ after the Employee�s employment with the company ends (hereinafter referred to as the Term). 


3.	NON-COMPETE AND NON-SOLICITATION 
A.	For the Term of this Agreement and any subsequent agreement for the same or similar purpose, the Employee shall not:
i.	Engage in any Competitive Business within ____10____
ii.	Directly or indirectly solicit or attempt to do same in respect of any existing or prospective clients, customers, partners, venture partners, investors or agents of the Company.  
iii.	Directly or indirectly, personally, on behalf of or in conjunction with any person or legal entity, recruit, solicit, induce or attempt to induce, recruit and solicit any present or prospective employee of the Company.   

4.	WARRANTIES AND REPRESENTATIONS
A.	The Employee warrants and represents as follows:
i.	This Agreement, its provisions and the enforcement of same is fair, just, appropriate and does not place any undue burden upon the livelihood of the Employee. 

B.	The Parties warrants and represent as follows: 
i.	There are no pre-existing obligation(s) and/or agreement(s) inconsistent with the provisions of this Agreement. 
ii.	All information provided in relation to this Agreement are true, accurate and complete in every respect, and not misleading. 

5.	SEVERABILITY 
In the event that any clause or provision in this Agreement is held invalid or unenforceable by a court of competent jurisdiction in Nigeria, such invalidity shall not affect the validity or operation of any other clause or provision; and such invalid clause or provision shall be deemed to be severed from this Agreement.

6.	AMENDMENT, ALTERATION AND MODIFICATIONS
i.	This Agreement contains the entire agreement and understanding by and between the Parties with respect to its provisions. 
ii.	Further, any representations, promises, agreements, or understandings, written or oral, not contained in this Agreement shall be subject to the provisions herein.
iii.	This Agreement may not be amended except in writing signed by the Parties. 

7.	TERMINATION
Without prejudice to its provisions, this Agreement shall be terminated: 
i.	At the expiration of the Term as indicated in Clause 2 above
ii.	With mutual consent of the parties; provided that same is evidenced in writing. 

8.	GOVERNING LAW
The implementation and enforcement of this Agreement shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement. 

9.	DISPUTE RESOLUTION
i.	Any controversy or claim arising out of or relating to this Agreement, or the breach hereof, the Parties shall expend their best efforts to settle any disputes arising between them amicably within 30 days.
ii.	In the event that the Parties are unable to reach an amicable resolution, such dispute, and controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration; by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap A18 Laws of the Federal Republic of Nigeria 2004.

10.	REMEDIES
Without prejudice to the provision(s) of this Agreement:
i.	The Company shall reserve the right to pursue any and all remedies available to it, the pursuit of which will not be deemed to exclude any other remedies, including, but not limited to recovery of damages. 
ii.	The Company shall have and reserve the right to any injunctive relief to prevent, restrain and/or enjoin any actual or threatened breach of the provisions contained in this Agreement

IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS AGREEMENT IN THE MANNER BELOW, ON THE DATE INDICATED ABOVE:

The Common Seal of the Company was hereunto affixed in the presence of:




________________						________________
NAME:								NAME:
DESIGNATION:							DESIGNATION:


Signed by the within named Employee in the presence of:    


___________________
NAME:

REFERENCES  
1.	What is the name of the company?
2.	In what country was the company incorporated?
3.	What is the Company RC Number?
4.	What I the office address of the Company?  
5.	What is the name of the Employee?
6.	What is the address of the Employee? 
7.	Give a brief description of the Company business. E.g. Provision of legal and financial advisory services to medium and small scale enterprises or production, manufacture and development of information and technology software or sale and retail of company financial securities
8.	 What is the date of the Contract of Employment?
9.	How long (excluding the employment period) should the restriction last?
10.	Where (city or state or country) should the non-compete apply? E.g.  Calabar or Lagos State or Nigeria. 






DESCRIPTION 
A Non- Executive Director Contract is a legal agreement between a Company and a non-executive director detailing the appointment, tenure, role, duties, functions and responsibilities of the non-executive director in the company. 

FREQUENTLY ASKED QUESTIONS (FAQ�s)
Who is a non-executive Director? Are they different from executive directors?
A non-executive director is a member of a company's board of directors who is not part of the executive team. A non-executive director typically does not engage in the day-to-day management of the organization, but is involved in policy making and planning exercises. In addition, non-executive directors' responsibilities include the monitoring of the executive directors and acting in the interest of the company stakeholders.

Is it compulsory for me to have a non-executive director?
The answer to this depends on whether your company is a private company or a public company. It is not compulsory for Private companies to have non-executive directors. The Corporate Governance Code requires that public companies, on the other hand, must have non-executive directors. 
However, it is good practice for every company (public or private); to have non-executive directors, since they contribute: 
�	independent or impartial outlook to company performance
�	wide experience which the company can benefit from
�	special knowledge and unique personal qualities
Why should their contract be different? 
Non-executive directors are not exactly employees of the company. To this extent, they are not entitled to salary, except reimbursement for out of pocket expenses incurred while carrying out the company affairs. However, most companies make compensation arrangements for their non-executive directors. 


DRAFT 
This Non-Executive Director Service Contract is made this __day of ______________20__ between _____1____ (hereinafter referred to as the Company) a company incorporated and existing under the laws of ____2____ with RC Number ______3_____ and its registered address at ____4_____ and ______5______ of _______6_______ (hereinafter referred to as the �Director�).
The Company and the Director are referred to individually as �Party� and collectively as �Parties�
WHEREAS:
A.	The Company is a company engaged in the business of ______7_______
B.	The Company desires to obtain and retain the services of the Director.
C.	The Director desires to render such services on the terms and conditions of this Agreement.
THE PARTIES AGREE AS FOLLOWS:
1.	DEFINITION AND INTERPRETATION: 
In this Agreement the following terms shall have the following meanings;
1.1	Affiliates means in relation to the Company, its officers, directors, members, partners, potential sources of capital, employees, agents, consultants, advisors (including without limitation, attorneys, accountants and financial advisors)
1.2	Agreement means this Director Service Contract entered into by the parties.
1.3	Articles means Articles of Association of the Company as amended from time to time or any document created to have the same effect.
1.4	Board means the Board of Directors of The Company 
1.5	Board Meetings means the meeting of the Board of Directors of the Company
1.6	Company Law means the all rules, regulations and laws applicable to the regulation of a company in the Federal Republic of Nigeria 
1.7	Confidential Information means any and all detail regarding the business of the Company, including, but not limited to: the names and/or other information of any of its customers, the prices it obtains, the prices at which it sells products, its manner of operation, its plans, projects, its strategies, any of the Company�s trade secrets or any other information pertaining to the business of the Company.
1.8	Proprietary Information means but is not limited to any such information relating to any customer, customer list or requirement, price list or pricing structure, sales and marketing, information, business plan or dealing, employee or officer, source codes and computer systems, software, financial information and plans, designs, formulae, prototypes, product lines, services, research activities, any document marked �Confidential� (or with a similar expression)
1.9	Term means all such time as will elapse between the Start Date (as contained in Clause 2.2) till the end of this Agreement either by termination or its determination thereof.
1.10	Third Party means any person(s), firm(s), company(ies), Organisation(s) other than the Parties to this Agreement

2	APPOINTMENT 
2.1	Subject to the Articles the Company and provision of this Agreement, the Company hereby appoints the Director as Non-Executive Director of the Company; to render all such necessary and incidental services as are required and requested pursuant to this Agreement.
2.2	The appointment will commence on __day of _____________20__ for a period of _____8______ (the Term).


3	DIRECTOR�S DUTIES AND RESPONSIBILITIES 
The Director covenants as follows:
3.1	To take decisions in the best interest of the Company 
3.2	To constructively challenge and contribute to the development of strategy for the Company
3.3	To scrutinize the performance of management in meeting agreed goals and objectives, and monitor the objective reporting of performance
3.4	To satisfy the Director that financial information is accurate and that financial controls and systems of risk management are robust and defensible 
3.5	To be responsible for and/or contribute to the determination of appropriate levels of remuneration for executive directors, appointment and removal (where necessary) of senior management and succession planning
3.6	To serve on any committee(s) of the Board as required by the Company from time to time 
3.7	To attend meetings of any such committees pursuant to clause 3.7 above.
3.8	To declare any and all actual and/or potential conflicts of interest to the Board as soon as they are apparent.
3.9	To declare any and all actual and/or potential conflicts of interest to the Board as soon as they are apparent.
3.10	To expend best endeavours to attend all Board meetings, the Company�s annual general meeting (AGM) and other shareholder meetings from time to time, and all meetings of any Board committees; as may be communicated from time to time by the Company. 
3.11	In the event that any such meeting as mentioned in clause 3.10 above cannot be attended, to notify the Company within a reasonable period  of such inability to attend. 
3.12	To devote such time and attention as is necessary for the proper discharge of all duties and responsibilities as director of the Company and the effective performance of any such duties. (Computation of which shall include time spent in accordance with Clause 3.10)
3.13	To provide all necessary information to enable the Company to comply with all statutory and/or regulatory requirements including but not limited to its register of directors and its register of the interests of directors.  This obligation shall continue after end of the term and/or the termination of this Agreement.


4	FEES AND EXPENSES
4.1	The Company shall pay ____10____ per annum for services rendered by the Director to the Company in consonance with the provisions of this Agreement.
4.2	The Company shall reimburse the Director of all necessary and incidental expenses reasonably and lawfully incurred by the Director in furtherance with the provisions of this Agreement.
4.3	The Director understands and agrees that any further consideration and/or additional remuneration paid to the Employee in the form of bonuses, benefits and other similar incentive remuneration will rest in the sole discretion of the Company. 

5	CONFIDENTIALITY
The Director shall not at any time during or after ______11______ of the Term of this Agreement:
5.1	Use or permit to be used for personal benefit or benefit of any Third Party 
5.2	Disclose or permit to be disclosed to any Third Party
5.3	Divulge, publish, copy, reproduce or permit to be divulged, published, reproduced or copied in any form (without authorization in writing from the Company);
any Confidential information or Proprietary information relating or belonging to the Company or its affiliates 

6	TERMINATION
6.1	This Agreement shall be terminated (without prejudice to the applicable company law principles) as follows: 
6.1.1	Either Party shall be entitled to terminate this Agreement by giving ____12_____ prior written notice; at occurrence of which the Term of this Agreement shall be deemed as having come to an end. 
6.1.2	Upon vacation or removal of the Director from office in accordance with the Articles of the Company and/or  applicable Company Law; 
6.1.3	This Agreement shall be terminated on the occurrence of any or all of the above listed provisions.
6.2	Upon termination of this Agreement,
6.2.1	 The Company reserves the right to appoint any person (as it deems fit) as attorney to execute any documents or do any and all requisite things to give effect to the termination of this Agreement.
6.2.2	The Director will return within 7 days any and all property and documents of the Company(without making or permitting the making of copies of whatever form or reproduction by whatever means) and deliver same to the office address of the Company  
6.3	Nothing in this Agreement shall be construed as to entitle the Director to any fee(s), damage(s) or otherwise for loss of office and/or termination of this Agreement; and as such no fee(s) or damage(s) will be deemed payable in that respect by virtue of this Agreement. 

7	GOVERNING LAW 
The implementation and enforcement of this Agreement shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement.

8	DISPUTE RESOLUTION
8.1	Any controversy or claim arising out of or relating to this Agreement, or the breach hereof, the Parties shall expend their best efforts to settle any disputes arising between them amicably within 30 days.
8.2	In the event that the Parties are unable to reach an amicable resolution, such dispute, and controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration; by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap 19 Laws of the Federal Republic of Nigeria 2004.

9	SEVERABILITY
In the event that any clause or provision in this Agreement is held invalid or unenforceable by a court of competent jurisdiction in Nigeria, such invalidity shall not affect the validity or operation of any other clause or provision; and such invalid clause or provision shall be deemed to be severed from this Agreement.


10	WAIVER 
None of the requirements, rights or provisions of this Agreement shall be considered waived unless waived in writing by the Party concerned. The waiver by any Party of one or more of its rights hereunder shall not be deemed or construed as a continuing waiver on the part of such Party as to prohibit such Party from demanding the performance of any of the other duties and obligations owed it in accordance with the provisions of this Agreement.

11	ADDITION, ALTERATION AND MODIFICATION
11.1	This Agreement supersedes any agreements made or existing between the parties before or simultaneously with this Agreement (all of which shall be deemed to have been terminated by mutual consent with effect from the date of this Agreement) and
11.2	Any addition, alteration, or modification of this Agreement shall be in writing, and no oral agreement shall be effective.

12	MISCELLANEOUS
12.1	This Agreement and its provisions shall be subject to the Articles of the Company as may be amended from time to time. 
12.2	In the event of any conflict between this Agreement and the Articles of the company, such provision(s) which conflicts with the Articles of the Company shall be invalid and unenforceable to the extent to which same conflicts with the Articles 
12.3	This Agreement may be executed in two (2) or more counterparts, each of which shall be deemed an original, but all of which together shall constitute one (1) and the same Agreement

IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS DEED IN THE MANNER BELOW, ON THE DATE INDICATED ABOVE

SIGNED, SEALED AND DELIVERED
THE COMMON SEAL of the within named Company is hereby affixed and the deed duly delivered in the presence of:

______________						          _______________
NAME:								NAME:
DESIGNATION:							DESIGNATION:


SIGNED, SEALED AND DELIVERED
By the within named Director:

______________
NAME:



IN THE PRESENCE OF:
NAME:
ADDRESS:
OCCUPATION:
SIGNATURE:











REFERENCES
(1) What is the name of the company?
(2) In what country was the company incorporated? 
(3) What is the RC Number of the Company?
(4) What is the office address of the company? 
(5) What is the name of the Director? 
(6) What is the address of the Director?
(7) Give a brief description of the company business- E.g. purchase and retail of phone accessories
(8) How long is the Director�s tenure? E.g. 30 days or 24months or 2 years

(10) How much will the Director be paid per-annum?
(11)When the Director leaves your company, how much longer do you want the confidentiality to continue? E.g. 6 months or 30 days or 2 years 
(12) How long should the length of notice be, if the appointment is to be terminated?





DESCRIPTION 
A guarantee previously given can be withdrawn by a document called a Revocation of Guarantee. This notice is used to cancel the guarantee earlier issued in favour of another (the obligor/borrower). NB: Please note that a guarantee given for an existing debt cannot be revoked. It is however possible to revoke a guarantee for a future debt or continuing guarantee. In this instance, the guarantor shall only be liable for those transactions which have already taken place once the guarantee is revoked.

FREQUENTLY ASKED QUESTIONS (FAQs)
Who is the Guarantor?
The Guarantor is the person or party which pledges to be accountable for the repayment of another�s debt or performance of another�s obligation(s) under a contract. 
Who is the Obligee/Creditor?
The Obligee/Creditor is the party in a contract to whom money or the performance of an obligation is owed.
Who is the Obligor/Borrower?
The Obligor/Borrower is the party that owes an obligation to perform a contract or repay a debt.
What is a Continuing Guarantee?
A Continuing guarantee is a type of guarantee that extends to a series of successive transactions that either continue the guarantor�s liability, or from time to time renews it. Generally, indefinite numbers of transactions are dealt in continuing guarantee. Such guarantee can be in respect of future transactions, which is to be conducted during a fixed period of time. For continuing guarantees, the guarantor can set limit on his or her liability as to time or amount of guarantee.
An example of a continuing guarantee is where A guarantees payment to B, a car dealer to the amount of N10, 000, 000 for cars A may from time to time supply to C. B supplies C with cars to the extent of the agreed value i.e., N8, 500, 000 and C pays B for it. Afterwards B supplies C with cars to the value of N13, 000, 000 but C fails to pay. Since the guarantee given by A was a continuing guarantee, C is liable to B to the extent of N 10, 000, 000.


_____1_____
______2______
_____3_______

Dear �.3b�.,

			REVOCATION OF GUARANTEE 
Kindly refer to the guarantee dated ______4______ (the Guarantee), issued to you by ______5______ (the Company) wherein the Company guaranteed the obligation(s) of _______6______ (the Obligor).
Please be advised that effective from _____7_____, the Company shall not be obligated to guarantee any future or further obligation extended by you to the Obligor. 
The Company understands that it remains liable for the present obligations upon notification that same is unsatisfied and to the extent agreed in the above referenced Guarantee. 


Thank you for your cooperation. 


For: (repeat ref 5)



__________________
NAME:
DESIGNATION:
REFERENCES 
1.	What is the date of the letter?
2.	Who is the name of the Obligee/Creditor?
3.	What is the address of the Obligee/Creditor?
3b. In the event the letter is addressed to a company, kindly provide the name of the company�s contact person. Where it is addressed to a person, please insert the name of the person.
4.	On what date was the guarantee given?
5.	What is the name of the Guarantor? 
6.	On whose behalf was the guarantee issued?
7.	On what date is the termination effective? E.g. 14th December, 2017 Please note: the chosen date must comply with the notice period provided in the Guarantee.





DESCRIPTION 
A guarantee previously given can be withdrawn by a legal document called a Revocation of Guarantee. This notice is used to cancel the guarantee earlier issued in favour of another (the obligor/borrower). NB: Please note that a guarantee given for an existing debt cannot be revoked. It is however possible to revoke a guarantee for a future debt or continuing guarantee. In this instance, the guarantor shall only be liable for those transactions which have already taken place once the guarantee is revoked.

FREQUENTLY ASKED QUESTIONS (FAQs)
Who is the Guarantor?
The Guarantor is the person or party which pledges to be accountable for the repayment of another�s debt or performance of another�s obligation(s) under a contract. 
Who is the Obligee/Creditor?
The Obligee/Creditor is the party in a contract to whom money or the performance of an obligation is owed.
Who is the Obligor/Borrower?
The Obligor/Borrower is the party that owes an obligation to perform a contract or repay a debt. 

What is a Continuing Guarantee? 
A Continuing guarantee is a type of guarantee that extends to a series of successive transactions that either continue the guarantor�s liability, or from time to time renews it. Generally, indefinite numbers of transactions are dealt in continuing guarantee. Such guarantee can be in respect of future transactions, which is to be conducted during a fixed period of time. For continuing guarantees, the guarantor can set limit on his or her liability as to time or amount of guarantee.
An example of a continuing guarantee is where A guarantees payment to B, a car dealer to the amount of N10, 000, 000 for cars A may from time to time supply to C. B supplies C with cars to the extent of the agreed value i.e., N8, 500, 000 and C pays B for it. Afterwards B supplies C with cars to the value of N13, 000, 000 but C fails to pay. Since the guarantee given by A was a continuing guarantee, C is liable to B to the extent of N 10, 000, 000.

DRAFT 

_____1_____
______2______
_____3_______

Dear �.3b�.,

			REVOCATION OF GUARANTEE 
Kindly refer to the guarantee dated ______4______ (the Guarantee), issued to you by ______5______ wherein I guaranteed the obligation(s) of _______6______ (the Obligor).
Please be advised that effective from _____7_____, I shall not be obligated to guarantee any future or further obligation extended by you to the Obligor. 
I understand that I remain liable for the present obligations upon notification that same is unsatisfied and to the extent agreed in the above referenced Guarantee.. 

Thank you for your cooperation. 



__________________
(Repeat reference 5)  

REFERENCES 
1.	What is the date of the letter?
2.	What is the name of the Obligee/Creditor?
3.	What is the address of the Obligee/Creditor?
3b. In the event the letter is addressed to a company, kindly provide the name of the company�s contact person. Where it is addressed to a person, please insert the name of the person.
4.	On what date was the guarantee given?
5.	What is the name of the Guarantor? 
6.	On whose behalf was the guarantee issued?
7.	On what date is the termination effective? E.g. 14th December, 2017 Please note: the chosen date must comply with the notice period provided in the Guarantee.




DESCRIPTION 
A notice of termination of contract is a document sent by a party that desires to bring an existing contract to an end. The notice contains the date the termination becomes effective and the reason for terminating the contract. Where the agreement executed by the parties stipulates the mode and process for termination, the party desirous of terminating the contract should endeavor to strictly follow the provisions of the agreement (especially in respect of notice period and service of notice).    

FREQUENTLY ASKED QUESTION (FAQs)
What are the reasons for terminating a contract?
Some of the reasons for which a contract may be terminated include: 
�	Breach of contract: where a party has contravened the provisions of the contract and fails to remedy the breach within the timeline stipulated in the agreement. 
�	Prior agreement of the parties: where the parties agree that the contract may be terminated for a specific reason or occurrence of an event
�	Mutual termination: the parties may jointly agree that the contract should be terminated 
However, it is important to note that some contracts are drafted to contain the grounds upon which it may be terminated. As such, it is advisable to verify from the provisions of the contract. 
How do I know the effective date of the termination? 
The effective date of the termination is dependent on the notice period specified in the contract. For example; where the contract specifies a 3 week notice of termination, the effective date of termination will be any chosen date; after the three week notice is completed. 
However, where the contract does not specify a notice period for termination, any future date can be chosen. 

N.B: This letter should be printed on the company Letterhead. 


DRAFT
______1_______
______2________
______3_________


Dear ����...3b����.
		


NOTICE OF TERMINATION OF CONTRACT 
In reference to the ______4______dated ______5_______ (hereinafter referred to as the Contract) entered into with ______6______, kindly accept this letter as our notice of termination of the Contract. This termination is effective ______7______.
The contract is terminated due to ___________8__________



Thank you. 

For: (Repeat ref 6)

_______________
NAME 
DESIGNATION: 




REFERENCES
1.	What is the date of the letter?
2.	What is the name of the person the notice will be sent to?
3.	What is the address of the person the notice will be sent to?
3b. In the event the letter is addressed to a company, kindly provide the name of the company�s contact person. Where it is addressed to a person, please insert the name of the person.  
4.	What contract are you terminating? E.g. Contract of Sale or Hire Purchase Agreement or Service Agreement etc. 
5.	What is the date of the contract?
6.	What is the name of your company? 
7.	What is the effective date of the termination? 
8.	Why are you terminating the contract?  E.g. Company A�s failure to supply the agreed quantity of goods; or our inability to reach an amicable settlement of disputes; or Company A�s continued breach of Clauses 4, 5 and 10 of the Contract 





DESCRIPTION
A notice of withdrawal from Partnership is a legal document used by a Partner who wishes to leave a Partnership (voluntarily). The effect of a partner's withdrawal on the partnership depends on the type of partnership and the terms of the partnership agreement.
FREQUENTLY ASKED QUESTIONS (FAQ�s)
What is the difference between a Notice of Withdrawal from Partnership and a Partnership Dissolution Agreement? 
The two documents are often confused; however, they are very distinct.
 A Partnership Dissolution Agreement is used when the Partners desire to bring the Partnership to an end; i.e. the partnership will cease to exist.
 A Notice of Withdrawal from Partnership is used when a partner wishes to leave the Partnership; this decision does not affect the continued existence of the Partnership. 

How Do I use a Notice of Withdrawal from Partnership? When does the notice take effect?
Information about the requirements, notice and mode of withdrawal from a partnership is usually contained in the Partnership Agreement between the Partners. 
The effective period of the Withdrawal will be dependent on the terms of the Partnership Agreement. 
N.B: If there is a written Partnership Agreement, it will be helpful to have a copy available while completing this document.

What does a Notice of Withdrawal contain?
A notice of withdrawal should contain:
�	The name and address of the withdrawing partner 
�	Name the partnership ,
�	Date of formation of the partnership and 
�	Business object(s) of the partnership 

Who should the notice be sent to? 
The answer to this depends on the provisions of the Partnership Agreement. However, the best practice will be to provide a copy of the notice to all partners of the business and also to the business address of the Partnership.  


DRAFT
Please insert date address and any other information required to be here 
To: The Partners of _____ (repeat reference 3)______ 

	NOTICE OF WITHDRAWAL FROM PARTNERSHIP
I, ________1_______ of ______2_______ (, a partner in ___3____ desires to withdraw from this Partnership.
By this document, I give due  notice of my withdrawal to the other partners of ___ (repeat reference 3) ____.. My exit from the partnership shall become effective on ___4____.
I appreciate the time spent and relationships built over the years. 

Kindly accept my best wishes for the success of ___ (repeat reference 3) ____. 



_________________
(repeat reference 1)





REFERENCES
1.	What is the name of the withdrawing partner?
2.	What is the address of the withdrawing partner?
3.	What is the name of the Partnership=? 
4.	Please provide the date in which you want your withdrawal to take effect





DESCRIPTION 
A notice to quit is a notice given by a landlord to a tenant to vacate the premises on or after a certain date. A notice to quit must contain: the name of the landlord, address of the property, name of the tenant, type of tenancy, date of commencement and expiry of tenancy, date of intended recovery of premises. 

FREQUENTLY ASKED QUESTIONS (FAQ�s)
Is it only the landlord that can prepare/sign a notice to quit? 
A Notice to Quit can be signed by the Landlord or his representative. However, for a representative to sign, he must have a written authorization from the landlord to do so on his behalf. 

Do I need to give notice to quit?
Notice to quit is mandatory in recovery of premises. Furthermore, violating the length of notice required by law invalidates the notice to quit. 

How do I determine the length of notice?
The length of notice depends on the type of tenancy. The law provides the minimum notice period required for a tenant. The notice periods are as follows:
Weekly Tenancy � 1 (one) week notice
Monthly Tenancy � 1 (one) month notice
Quarterly Tenancy � 3 (three) months� notice
Yearly Tenancy � 6 (six) months� notice 
However, the parties may agree to a different notice period in the tenancy agreement. 

How do I know the type of tenancy? 
Generally, the type of tenancy is determined by the time when rent is to be paid (as stipulated in the tenancy agreement). For example; where the agreement provides that rent is to be paid weekly, then it is a weekly tenancy.

What is the next step after the notice to quit? 
After the notice to quit expires, the landlord will send a notice of intention to recover the premises; which usually has a 7 day notice period.

NOTICE TO QUIT (by Landlord) DRAFT 
I ________________1a_______________ of ___________1b_______________ (Landlord) owner of the ________2_____________ and all appurtenances (hereinafter referred to as the Premises) situate at ___________3_____________ which you ___________4____________(Tenant) presently occupy by virtue of a  _________5________tenancy which commenced on __________6a_____________ and expires on _________6b_____________; hereby give you notice to quit and deliver up possession of the Premises on or before ______________7__________________.

Dated this __ day of ____________ 20__
Signed by the within named Landlord

_________________
NAME:

REFERENCES 
1a What is the Landlord�s name?
1b What is the landlord�s address?
2 What is the description of the property? For example: 2 bedroom bungalow or 4 bedroom duplex  
3 What is the address of the property?
4 What is the Tenant�s name?
5 What is the type of tenancy? For example: Weekly tenancy or Monthly tenancy or Yearly tenancy
6a When did the tenancy start? For example: 2nd of September 2012 or 3rd of July 2005
6b When is the tenancy meant to expire? 
7 When do you want the tenant to move out? 




DESCRIPTION OF DOCUMENT
A partnership agreement is a contract between two or more parties which sets out the terms and conditions of the relationship between them as partners. Among other things, the Partnership Agreement sets out the nature of the business, capital contributed by each partner, and their rights and responsibilities.

FREQUENTLY ASKED QUESTIONS (FAQ�s)
What is a partnership? 
A partnership is a business combination of two to twenty people with the primary aim of making profit. 
 Do I need a partnership agreement? 
Although a Partnership can be formed orally, in writing or even by conduct, it is advisable to have a
Partnership Agreement. This is because a Partnership agreement helps to:
a.	Outline the roles and responsibilities of the partners 
b.	Describe the decision making process of the partnership 
c.	Deal with issues that may come up like: death of a partner, dissolution of the partnership, exit of a partner etc. 
How will the Partnership pay tax?
The tax obligation of a partnership business is borne by the individual partner. To this extent, tax is levied on the separate incomes of the partners in the business. 

PARTNERSHIP AGREEMENT 
This PARTNERSHIP AGREEMENT is made this __day of ____________ 20__ between___________1a____________ of _____________1b____________________ (Hereinafter referred to as �___________1c____________�) and ______________2a___________________ of _________________2b__________________ (Hereinafter referred to as �___________2c____________). 
The parties shall hereinafter be referred to as Partners jointly and Partner individually 

WHEREAS: 
1.	The Parties intend to carry on the Business described in Clause 1.1 as a partnership. 
2.	 The Parties by means of this Agreement, define their participation, rights and obligations in carrying on business and intend to establish the principles of co-operation with respect to carrying out the business of the partnership.  

In consideration of the mutual promises contained in this agreement, Partners agree as follows:
1.	NAME AND BUSINESS.
1.1	The parties hereby form a partnership under the name of ________3a________ to conduct the business of ____________________3b_____________________________.
1.2	The principal place of business shall be at ______________3c__________________.

2.	TERM
2.1	The partnership shall begin on the __ day of ____________, 20__ and shall continue until terminated in accordance with Clause 10.

3.	CAPITAL
3.1	The capital of the Partnership shall be contributed in cash or property by the partners in agreed upon value as provided in Appendix A (the �Capital Contribution�)
3.2	Any withdrawal from the Capital Contribution shall be mutually agreed by the Partners.
3.3	Capital Contributions may be amended form time to time, according to the requirements of the Partnership provided that the interests of the Partners are not affected, except with the unanimous consent of the Partners. Whenever additional capital is determined to be required and either Partner is unwilling or unable to meet the additional contribution requirement within a reasonable period, the other Partner may contribute in proportion to their existing Capital Contributions to resolve the amount in default. In such a case, the allocation of profits or losses among all the Partners will be adjusted to reflect the aggregate change in Capital Contributions by the Partners. 
3.4	An individual capital account (the �Capital Account�) will be maintained for each Partner and their initial Capital Contribution will be credited to this account. Any additional Capital Contributions made by any Partner will be credited to that Partner�s individual Capital Account. 
3.5	No interest shall be paid on the initial contributions to the capital of the partnership or on any subsequent contributions of capital.

4.	PROFIT AND LOSS.
4.1.	The net profits of the partnership shall be divided equally between the partners and the net losses shall be borne equally by them. 
4.2.	A separate income account shall be maintained for each partner. 
4.3.	Partnership profits and losses shall be charged or credited to the separate income account of each partner as per Clause 4.2  
4.4.	If a partner has no credit balance in his income account, losses shall be charged to his Capital Account.
4.5.	Decisions regarding the distribution of profits, allocation of losses and the requirement for additional Capital Contributions as well as other financial matters will be decided by a unanimous vote of the Partners. 

5.	SALARIES AND DRAWINGS.
5.1.	Unless otherwise unanimously agreed by the Parties, neither partner shall receive any salary for services rendered to the Partnership. 
5.2.	Each Partner may, from time to time, withdraw the credit balance in his income account.

6.	TITLE TO THE PARTNERSHIP PROPERTY
Title to all property acquired by the Partnership will remain in the name of the Partnership (Partnership Property). No partner will have any ownership rights in such Partnership Property.

7.	MANAGEMENT DUTIES AND MEETINGS.
7.1.	The partners shall have equal rights in the management of the partnership business, and each partner shall devote his entire time to the conduct of the business. 
7.2.	No partner shall without the consent of the other partner on behalf of the partnership borrow or lend money, or make, deliver, or accept any commercial paper, or execute any mortgage, security agreement, bond, or lease, or purchase or contract to purchase, or sell or contract to sell any property for or of the partnership on behalf of the partnership other than the type of property bought and sold in the regular course of its business. For the avoidance of any doubt, all actions and decisions in respect of the management, operation and control of the Partnership and its business will be decided by a unanimous vote of the Partners except all Partners agree otherwise in writing. 
7.3.	Regular meetings of the Partners will be held ________4________
7.4.	Any Partner can call a special meeting to resolve issues that require urgent attention by providing reasonable notice to the other Partner. Discussions at the meeting will be restricted to the specific purpose for which the meeting was called.
7.5.	Any Partner is permitted to participate in a meeting by means of telephone, audio and/or video conferencing or other communication facilities. 

8.	BANKING.
8.1.	All funds of the partnership shall be deposited in its name in such checking account or accounts as shall be designated by the Partners. 
8.2.	All withdrawals therefrom are to be made upon cheques signed by either partner.

9.	BOOKS, ACCOUNTS AND AUDIT.
9.1.	The partnership books shall be maintained at the principal office of the partnership, and each partner shall at all times have access thereto. 
9.2.	The partnership books shall be kept on a fiscal year basis and shall be closed and balanced at the end of each fiscal year. The Partners shall agree when a fiscal year is deemed to start and when it ends. 
9.3.	 As soon as practicable after the close of each fiscal year, the Partnership will furnish to each Partner an annual report showing a full and complete account of the condition of the Partnership. 
9.4.	Any of the Partners will have the right to request an audit of the Partnership books. The cost of the audit will be borne by the Partnership. The audit will be performed by an accounting firm acceptable to all Partners. Not more than one (1) audit will be required by any or all of the Partners for any fiscal year.

10.	NON-COMPETITION 
10.1.	No Partner will engage in any business, venture or transaction, whether directly or indirectly, that might be competitive with the business of the Partnership or that would be in direct conflict of interest to the Partnership without the unanimous written consent of the remaining Partners. Any and all businesses, ventures or transactions with any appearance of conflict of interest must be fully disclosed to all other Partners. Failure to comply with any of the terms of this clause will be deemed an involuntary withdrawal of the Partner (as described in clause 12.2) and may be treated accordingly by the remaining Partner.
10.2.	In the event that a Partner exits the Partnership whether voluntarily or involuntarily, such partner shall not directly or indirectly solicit existing clients of the Partnership or engage in a business which is or which would be competitive with the existing business of the partnership in the territory where the Partnership is currently doing business. The restriction contained in this Clause 10.2 shall apply for a period of __________5_____________ unless the remaining Partner(s) agree otherwise in writing. 

11.	ADMISSION OF  NEW PARTNERS
10.1.	A new Partner may only be admitted to the Partnership with a majority vote of the existing Partners. 
10.2.	Any new Partner agrees to be bound by all the covenants, terms and conditions of this Agreement, inclusive of all current and future amendments. A new Partner will also be required to execute such documents as are needed to effect the admission of the new Partner. Any new Partner will receive such business interest in the Partnership as determined by a unanimous decision of the other Partners. 

12.	WITHDRAWAL OF A PARTNER 
12.1.	Any Partner will have the right to voluntarily withdraw from the Partnership at any time upon service of written notice of intention to withdraw from the Partnership at least three (3) months before the withdrawal date. 
12.2.	Events which may occasion an involuntary withdrawal of a Partner from the Partnership include: death; mental incapacity; disability preventing reasonable participation in the Partnership; incompetence; breach of fiduciary duties; criminal conviction; or act/ omission of a Partner that can reasonably be expected to bring the business or reputation of the Partnership into disrepute. 
12.3.	Where the dissociation of a Partner for any reason results in the dissolution of the Partnership, the Partners will proceed in a reasonable and timely manner to dissolve the Partnership, with all debts being paid first, prior to any distribution of the remaining funds. 
12.4.	The remaining Partner(s) reserves the right to seek damages from a dissociation Partner where the dissociation resulted from either a malicious or criminal act by the dissociated Partner, breach of fiduciary duty or act or omission committed by the dissociated Partner that could reasonably be foreseen to bring harm or damage to the Partnership or to the reputation of the Partnership.  

13.	DEATH.
13.1.	Upon the death of either partner, the surviving partner shall have the right to purchase the interest of the decedent partner in the partnership or to terminate and liquidate the partnership business. 
13.2.	In the event that the surviving partner elects to purchase the decedent's interest pursuant to Clause 11.1, he shall serve notice in writing of such election, within three months after the death of the decedent, upon the executor or administrator of the decedent, or, if at the time of such election no legal representative has been appointed, upon the next-of-kin or any known legal heir, successor or assign of the decedent.
13.3.	The surviving partner shall be entitled to use the trade name of the partnership.

14.	DISSOLUTION AND DISTRIBUTION OF THE PARTNERSHIP PROPERTY.
The partnership may be dissolved and terminated at any time:
By agreement of the partners, in which event the partners shall proceed with reasonable promptness to liquidate the business of the partnership. The partnership name shall be sold with the other assets of the business. The assets of the partnership business shall be used and distributed in the following order:
a.	to pay or provide for the payment of all partnership liabilities and liquidating expenses and obligations;
b.	to balance the income accounts of the partners;
c.	to discharge the balance of the income accounts of the partners;
d.	to balance the capital accounts of the partners; and
e.	to discharge the balance of the capital accounts of the partners.

15.	INDEMNIFICATION.
If at any time either of the Partners is required to pay or become liable for more than its proportion of the Partnership debts as provided for in this Agreement, that Partner shall have as against the other Partner a right of recovery of the appropriate proportion of the payment or indemnification against such liability, and the Partner shall have, on becoming liable for such debt, the first lien or charge on the capital and all other interest or interests of the offending Partner in the Partnership business.

16.	DISPUTE RESOLUTION.
Any controversy or claim arising out of or relating to this Agreement, or the breach hereof, the Partners shall expend their best efforts to settle any disputes arising between them amicably within 30 days. 
However, where the Parties are unable to reach an amicable resolution, such dispute, and controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration by a sole arbitrator and in accordance with the Arbitration and Conciliation Act Cap 19 Laws of the Federal Republic of Nigeria 1990. 

17.	ADDITIONS, ALTERATIONS, OR MODIFICATIONS.
17.1.	In the event that this agreement, or any terms and conditions contained in this agreement, are in any way ineffective or deficient, or not expressed as originally intended, the partners shall enter into, execute, and perform all further deeds and instruments as their counsel shall advise.
17.2.	 Any addition, alteration, or modification shall be in writing, and no oral agreement shall be effective. 
17.3.	Any such addition(s), alteration(s) or modification(s) as made pursuant to Clause 13 shall be binding upon the parties as if same were herein set out. 



18.	GOVERNING LAW.
This agreement shall be governed by and construed in accordance with the Laws of the Federal Republic of Nigeria.

IN WITNESS OF WHICH the Parties have executed this Partnership Agreement in the manner below on the date indicated above.

SIGNED by the within named Partner 


_________________________
NAME:

IN THE PRESENCE OF: 
NAME:
ADDRESS
OCCUPATION:
SIGNATURE:

SIGNED by the within Partner


_________________________
NAME:

IN THE PRESENCE OF:
NAME:
ADDRESS:
OCCUPATION:
SIGNATURE:

REFERENCES 
1a. Your name 
1b. Your address
2a. Your Partner�s name 
2b. Your Partner�s Address
3a. What is the name of the partnership business?
3b. What business will the partnership do? For example: �procurement and sale of landed property in Lagos State�
3c. What is the office address of the partnership?
4	How often should Partners have their meetings (e.g. weekly, monthly, quarterly) 
5	In the event that a Partner exists the partnership, for how long will the partner be prevented from carrying on a business similar to the partnership and from soliciting business from the Partnerships existing clients (e.g. 6 months, 2 years)




DESCRIPTION OF THE DOCUMENT
A Performance Contract is an agreement that outlines the terms and conditions of a performance by an individual or group either privately or at commercial venues. The essence of entering into this agreement is to state clearly the rights and duties of each party, compensation due, duration etc. 


FREQUENTLY ASKED QUESTIONS (FAQs)

Why enter into a Performance Contract?
It is vital to enter into a Performance Contract to clearly state the terms as agreed by parties and make provisions for a means to resolve subsequent disputes. As an independent contractor or Client, you need to execute this agreement to protect your interests and guarantee performance by the other party of their obligations herein.

Who needs a Performance Contract?
An independent Contractor who renders services and a Client in need of services from an independent contractor. Hence, either party could be an individual, group or an organization as the case may be.

Who is an Independent contractor?
An Independent Contractor is one who renders services for a fee and for a specified time period. An Independent contractor is an employee, hence the need to specially outline the terms of the engagement of one where necessary.

Is a performance Contract enforceable?
Yes, it is a valid contract and is enforceable under law once terms are clearly stated and reasonable in the circumstance.





PERFORMANCE CONTRACT 

THIS AGREEMENT made this ______Day of __________________20____ is for the service described below between ______1a of ____1b [hereinafter referred to in this agreement as �the Client� (which expression shall where the context so admits include its successors-in-title and assigns) of the one part;

AND 

_____2a of _____2b a performing Performer carrying on the business of entertainment under the name and style of __________2c [hereinafter referred to as �the Performer� (which expression shall where the context so admits include its successors-in-title and assigns) of the other part.

WHEREAS 

1.	_______3a
2.	_______3b

3.	The Client desires to invite and hire the Performer to _____3c


NOW THIS AGREEMENT WITNESSETH AS FOLLOWS:

In consideration of the performance fees hereinafter agreed and reserved and of the covenants and conditions herein contained, the Performer hereby agrees to perform at _____4a




PERFORMANCE FEES & COSTS

4.	The Client agrees to pay the Performer or his agent an aggregate of _____4b as gross performance fees at a time to be agreed by the parties. A cheque shall be made payable to the Performer or Manager appointed by the Performer. 
5.	The Client agrees to solely reimburse and/or  pay the Performer or Performer�s agent for the  costs incidental to making the Performer available to perform at the said event and throughout the duration of the performance.


DATE AND TIMES OF PERFORMANCE

6.	The date of the Performance is  ____5a . This Performance shall have duration of at least _____5b
7.	Upon default by the Performer, the Client has the right to seek a replacement Performer and is entitled to be compensated for the cost and inconvenience of securing the replacement Performer. 
8.	The parties agree that time is of essence in this agreement and each undertake to act in good faith and in all honesty towards one another to give effect to this agreement.

INDEMNITY
9.	The Client indemnifies and holds the Performer harmless for any loss or injury that is caused by reasons other than any direct fault of the Performer or agents. The Performer also in turn indemnifies the Client for any loss, damage or injury caused by reasons not amounting to a direct fault of the Client.

 EQUIPMENT
10.	The Venue will be available for any preliminary checks the Performer or agents may need to ensure it is well set up for their use on a date agreed by parties. 
11.	The Client agrees to make available to the Performer all equipment needed to guarantee a seamless performance by the Performer and to allow the Performer also use personal equipments in conjuction with what is made available, to ensure a smooth performance where needed. The client also agrees to provide suitable power supply to the client for the duration of the performance.



ADVERTS AND PROMOTIONS

12.	The Client shall be entitled to advertise and promote the appearance of the Performer at the event. The Performer acknowledges that the Client will rely on the terms of this contract in all such promotions and adverts. The Performer hereby acknowledges and agrees that the Client may use the Performer�s name, photograph, likeness, facsimile signature and any other promotional materials in all such promotions, advertising or other activities used to procure increased attendance at the event.
13.	The Performer agrees and gives the Client the concession to revamp/modify/improve on their original work where deemed necessary to do so for the purposes of the event and for any subsequent Recording, Reproduction or Transmission of the Performance.


PROPRIETARY RIGHTS	
     11. The Performer agrees to grant to the Client the right of Recording, Reproduction or Transmission of Performance:  The Client will be permitted to use means available for recording, reproduction or transmission of the Performance by the Performer for commercial purposes to the extent that it does not interfere with the Performer�s Intellectual Property with respect to the subject of the performance
      12. The Performer agrees that all proprietary rights to the recordings of the Performer�s performance, in any form made, shall inhere in the Client and the Performer shall lay no claims to any interests in the recordings, howsoever transmitted. 

EXCLUSIVITY
14.	The Performer is an Independent contractor and not an employee of the client. To this effect, the exclusivity of this contract is only limited to the duration of the performance contracted with the Performer and does not hinder the Performer from entering into other similar agreements with other clients outside the performance time.


FORCE MAJEURE
15.	 Either Party shall be excused from any delay or failure in performance required hereunder if caused by reason of any occurrence or contingency beyond its reasonable control, including, but not limited to, acts of God, acts of war, fire, insurrection, strikes, lock-outs or other serious labor disputes, riots, earthquakes, floods, explosions or other acts of nature.  


DISPUTE RESOLUTION
14.	Any dispute arising as a result of the terms of this agreement shall be resolved first by amicable resolution between the parties failing which the dispute shall be referred to Mediation in accordance with the statutory rules relating to mediation and where parties are still unable to completely resolve the issues between them, it shall be referred to arbitration for a final determination of the matter in accordance with the Arbitration and Conciliation Act (CapA18) Laws of the Federation of Nigeria 2004 or any statutory modification or re-enactment thereof for the time being in force.

ENTIRE AGREEMENT
15.	This Agreement constitutes the entire agreement of the Parties on the subject hereof    and supersedes all prior understandings and instruments on such subject. This Agreement may not be modified other than by a written instrument executed by duly authorized representatives of the Parties. 

  WAIVER 
16.	 No waiver of any provision of this Agreement shall constitute a waiver of any other provision(s) or of the same provision on another occasion. Failure of either Party to enforce any provision of this Agreement shall not constitute a waiver of such provision or any other provision(s) of this Agreement. 



SEVERANCE
17.	If any clause or part of a clause in this Agreement is found by a court, arbitrator or other authority of competent jurisdiction to be void or unenforceable, that clause or part of a clause is to be deemed deleted from this Agreement and the remaining clauses and parts thereof shall continue in full force and effect.  If necessary the Parties shall in such event seek to agree upon a valid and enforceable clause or part of a clause to replace the clause or part of a clause found to be void and unenforceable

IN WITNESS WHEREOF the Parties have hereunto executed this agreement on the day and year first above written.

THE COMMON SEAL of the within named Client ______6a Was hereunto affixed In the presence of:

 _____________________                                             ______________________
Director                                                                          Secretary

SIGNED,SEALED AND DELIVERED by the within named Performer _____6b
 
______________________
Signature

In the presence of: 
Name:___________________________________
Address: _________________________________
Signature: ________________________________
Occupation: ______________________________

References:
1a. What is the Client�s name?
1b. What is the address and particulars of the Client? (for organisations please state the lawsof the country under which they are registered and their registration number where applicable)
2a. What is the name of the Performer?
2b. What is the address of the Performer?
2c. What is the name and style under which the performer performs? (stage name)
3a. Please briefly describe the client and the services they are engaged in
3b. Please briefly describe the Performer and the service they render
3c. What does the Client want to hire the performer for?
4a. Where is the performer carrying out this performance? ( The event, Place, date and time of the performance. E.g At my birthday in my residence at 10 Glover Road Maitama, Abuja on 27 July 2017 at 10pm)
4b. How much will the Performer be paid for the performance?
5a. On what date is the performer required to perform?
5b. what is the minimum time duration of artist�s performance? 





DESCRIPTION OF THE DOCUMENT
A Personal guarantee is an agreement to make one�s self liable (Guarantor) for the debts or obligations (Obligor/Borrower) of another person to a third party (Obligee/Creditor). It envisages a situation whereby a Guarantor undertakes to be held responsible for the Obligor/Borrower�s obligations in the event of the Obligor/Borrower�s default.  

FREQUENTLY ASKED QUESTIONS (FAQs)
What does it mean to sign a personal guarantee?
It means that if the debtor defaults in fulfilling their obligation to a third party, the individual guarantor will be personally held liable.
What do you mean by the premise upon which a guarantee is given? 
It means the facts and circumstances that gave rise to the guarantee
Can a company give a personal guarantee?
Where a company acts as a guarantor, the document is referred to as a corporate guarantee as opposed to a personal guarantee. 
Is a personal guarantee enforceable?
Yes, a personal guarantee is enforceable against the guarantor by the creditor. This is triggered upon default by the borrower.

PERSONAL GUARANTEE
I, _ 1a residing at _1b hereby guarantee the performance of ��2a owed to �.2b (the Obligee) by �..3 (Obligor) in respect of �..4. 
In the unlikely event that the Obligor is unable to perform the obligations in part or full upon due demand and same is brought to my notice by the Obligee, I undertake to pay all established outstanding payments to the Obligee. I hereby state that at the time of executing this guarantee, nothing exists to adversely affect the said guarantee to the detriment of the Obligee.

I acknowledge that this guarantee is a continuing guarantee and shall remain in full force irrespective of any interruptions in the business relations between the Obligor  and the Obligee; until all obligations provided for by this guarantee are discharged. PROVIDED THAT, my liability as guarantor with respect to this guarantee shall not at any time exceed __6a__.  

SIGNED BY THE GUARANTOR 


Signature: ________________
Name: ________________
Date: ________________

References:
1a. what is the name of the guarantor?
1b. What is the guarantor�s address?
2a. What obligation does the Obligor/Borrower owe the Obligee/creditor? e.g. payment obligation; obligation to provide cleaning service etc.
2b. What is the name of the Obligee/Creditor?
3a. What is the Obligor/Borrower�s name? 
4. Please provide details of the transaction between the Obligee/Creditor and Obligor/borrower? e.g. credit facility to the tune of $50, 000 provided by the Creditor to the Borrower; employment relationship between the Obligor and Obligee; transportation service provided by the Obligee to the Obligor
6a. What is the limitation for the sum recoverable against the guarantor? (Please state sum in figures. e.g. $30,000, N9, 000,000)







Real Estate
To enter into any contract or contracts for the sale, transfer, conveyance, management, lease, assignment, mortgage or encumbrance of my property located at �.. (the Property) 
To develop and manage my property located at �; to ward of trespassers on the Property; to accept and take tenants in the property; to collect rent from tenants on the Property
To commerce, carry on or defend all actions and other legal proceedings in respect of my Property or affairs or any part thereof or in respect of anything in which I or my Property or affairs may be in any way concerned.

Business 
To manage my business affairs, investments, securities and movable properties detailed below �
To carry into effect and perform all agreements entered into by me with any other person or persons.
In addition to the general powers to enter into a contract or contracts to sell, transfer, convey, manage, lease, option, assign, mortgage or encumber real estate, this Irrevocable Power of Attorney to Convey

Administration of Estate
To sign all documents regarding the Donor�s interest be it shares, properties, monies, etc. in the Estate of the Donor�s late father Engr. Bayo Ahmed Eze.
To sign and give all documents, notices and the like in my name and to sign all applications to all authorities in my name and my behalf over same.
To abate or remedy any breach of covenants or conditions whatsoever regarding the said interest in the Estate of the Donor�s late father Engr. Bayo Ahmed Eze.
To ward off and prohibit all trespasses regarding the said interest and if necessary proceed against them through due process of law.
To demand and receive all payments belonging to the Donor and in accordance with the stipulated mutual agreement if necessary sue to recover such payments over our interest in the Estate of the Donor�s late father Engr. Bayo Ahmed Eze.
To settle and compromise all accounts, claims and/or dispute between the administrators and other person(s) over our interest in the Estate of the Donor�s late father Engr. Bayo Ahmed Eze. 
To lease, mortgage, sell, assign or part with possession of the said interest in our name and on our behalf and to sign all applications for consents on our behalf.

Real Estate also includes, but is not limited to:
1.	The power to make gifts of my property.
2.	The power to create a trust and convey my property into that trust or any trust.
3.	The power to disclaim any interest in my property.
4.	The power to renounce fiduciary positions regarding my property.
5.	The power to withdraw and receive the income or corpus of a trust in which my property in an asset.





DESCRIPTION 
A power of attorney is a document issued by a party (Donor) to another party (Donee) which authorizes the Donee to act on behalf of the Donor and manage the affairs of the Donor. It contains the powers, authority and limitations the Donee and lays out the duration for which complete your thoughts�.
FREQUENTLY ASKED QUESTIONS (FAQ�s)
Who is a Donor?
A Donor is the party who authorises another party to act on his behalf.

Who is a Donee?
A Donee is the party authorised to act on behalf of the Donor in the Power of Attorney.

Must the Donee be a lawyer?
No, your Donee need not be a Lawyer. Anyone who is an adult is qualified to be a Donee. The Donor cannot however be an undischarged bankrupt or mentally incapacitated.

Should I grant general or specific powers?
If there are particular acts you may wish to undertake yourself, it is advisable to grant specific powers to your Donee. Granting general powers will give your Donee the authority to oversee all your affairs and do anything you would lawfully do if you were present. 

Can I revoke /end my Power of Attorney?
Yes you can unless it is an irrevocable Power of Attorney. A power of attorney continues indefinitely until ended in writing except the document granting the power states a given date.

STANDARD POWER OF ATTORNEY 
THIS POWER OF ATTORNEY (the �Power�) is made this __ day of __________ 20__ by me ______1a_______ of _______1b________ (the �Donor�).

1.0 APPOINTEMENT 
The Donor by this Power hereby appoints _______2a_______ of ______2b_________ as Donee in relation to the matter(s) contained in Clause 2 of this Power.

2.0 SCOPE OF AUTHORITY 
The Donee shall in the name and on behalf of the Donor do all or any of the following acts or things namely:
a.	To ________3________

b.	AND to do all such things as are necessary and/or incidental to the accomplishment to the above; as the Donor may lawfully do.

 3.0 RATIFICATION
The Donor undertakes to ratify and confirm all acts lawfully performed by the Donee, in good faith and in strict accordance with the provisions of this Power.

4.0 REVOCATION, EXPIRY AND TERMINATION
3.1 This Power shall remain in force if and until the occurrence of any of the following:
3.1.1 completion of the acts provided in Clause 2; or
3.1.2 _______5_________ written notice from the Donor to the      Donee.

5.0 GOVERNING LAW 
The implementation and enforcement of this Power shall be governed by the laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Power.

IN WITNESS OF WHICH THE DONOR HAS EXECUTED THIS DEED IN THE MANNER BELOW, ON THE DATE INDICATED ABOVE:
SIGNED, SEALED AND DELIVERED
By the within named Donor

___________________
NAME:
SIGN:

IN THE PRESENCE OF: 
NAME: 
ADDRESS:
OCCUPATION: 
SIGNATURE:   











REFERNCES 
(1a) What is the name of the person giving the power (Donor)?
(1b)What is the Donor�s Address?
(2a) What is your Donee�s name? 
(2b) What is your Donee�s address?
(3) What do you want your Donee to do (Kindly list)? E.g. manage my property at Km 6, Bwari, Abuja or manage my shop at Balogun market 

(5) If you want to end this arrangement, what should the length of notice be? E.g. 2 days or 2 weeks or 2 months 





Description of Power of Attorney
A power of attorney is a document issued by a party (Principal) to another party (agent) which authorizes that party (agent) to act on behalf of the principal and manage the affairs of the principal. It contains the powers, limitations and authority bestowed on the said agent and lays out the duration of the exercise of same, it also states whether or not it is given for a consideration and the financial compensation for the agent if any. It is advisable to use a trusted person as your agent, and where there are any restrictions, to state same expressly.


STANDARD POWER OF ATTORNEY
This Power of Attorney is given by me, ____________________________________ (�the principal�)of ______________________________________________________ (address) this ___________ day of ____________20____

1.	EXISTING POWER OF ATTORNEY 
The principal revokes all prior existing Power of attorney given in respect of the matters contained in this power of attorney.

2.	APPOINTMENT
2.1 	The Principal hereby appoints__________________________________________________( "the Agent")of________________________________________________________________ (address) to be  my true and lawful agent and in my name and on my behalf act in relation to all such matters as I have set out in this power of attorney.

3.	REVOCABILITY
This power of attorney shall be irrevocable, except by the consent of the attorney 
 



4.	GOVERNING LAW
4.1	This power of attorney and any claims or dispute arising with respect to the matters contained therein shall be governed by the laws in force in ____________________________ (City, State) and my agent is expected to at all times act in accordance to the said laws in any acts done on my behalf or in my name further to this power of attorney.
4.2	The Courts in the above State shall have exclusive jurisdiction to adjudicate on or settle any matters arising with respect to this power (including any claims or dispute with respect to its existence, validity, termination and any obligation arising by virtue of the said power) and shall grant any reliefs in connection with the settlement of such dispute or any other matters arising by virtue of this power of attorney.

5.	EFFECTIVE DATE
This power of attorney is effective from the moment this document is executed and continues to be in force until otherwise stated.

6.	LIABILITY
The agent shall not be held liable to the principal, his successors in title, heirs or assignees for any acts, omissions or arrangement done in furtherance of the powers conferred except where for such acts are as a result of wilful misconduct or gross negligence.

7.	SCOPE OF POWER
By this power of attorney, the principal authorises the agent to do all such acts as the principal may lawfully do.
7.1     Specific powers 
The agent may also act on behalf of the principal with respect to the following matters as specified below; (list specific acts which principal may want agent to do on his behalf)


8.	RESTRICTIONS
This power of attorney is not subject to any limitations or restrictions except as    already mentioned.

9.	ACCOUNTABILITY
9.1	The agent is to give account of all sums collected, monies spent, interests accrued and all other benefits for actions carried out by the agent by virtue of this power of attorney. 
9.2	The agent shall not gain personally from any action carried out by virtue of this power and is expected to keep separate accounts of all funds made available to him by virtue of this power without mixing same with his personal funds.

10.	DELEGATION
The agent shall not delegate to third parties any of the powers conferred by virtue of this power of attorney without the consent of the principal.


11.	FINANCIAL COMPENSATION FOR AGENT
The principal shall reimburse the agent�s account for all out of pocket costs and expenses incurred by the agent by virtue of this power of attorney provided that the agent is not entitled to make any benefits or reserve any benefits for himself arising out of this power of attorney.

12.	NOTICE TO THIRD PARTIES
Any third party who relies on representations lawfully made with respect to the powers contained in this power of attorney shall not be liable to the principal, heirs or assigns. A revocation of this power of attorney shall be effective against a third party only upon receipt of notice of such revocation.



13.	RATIFICATION
The principal hereby agrees to ratify and does ratifies everything, which the Agent under this power of attorney shall lawfully do or purport to do by virtue of this power of attorney. 

14.	TERMINATION
This power of attorney shall be valid until the incapacitation of the principal or the death of the principal or until an express revocation

15.	ACKNOWLEGEMENT
The principal _____________________________________ does hereby acknowledge this document having read same and with a clear understanding of the document and states that it is voluntary and that he/she has the capacity to give same.

IN WITNESS OF WHICH, I _________________________________________ (the principal) of _______________________________________________________ (address) have executed this document this ________________ day of _________________ 20 ____


__________________________
(Principal�s signature)

IN THE PRESENCE OF the undersigned witnesses. 

Name: ___________________________________________________________
Address: __________________________________________________________
Signature: _________________________________________________________

Name: ___________________________________________________________
Address: __________________________________________________________
Signature:__________________________________________________________



 Reference:
1.	Who is granting the power of attorney? The person who gives the authority (power to act) is referred to as a donor, grantor, or principal. 
2.	Please provide the address of the grantor of this power
3.	Date of the grant of the power of attorney
4.	Who is the grantor of this power appointing as agent?
5.	Please provide the address of the agent?
6.	Is this power of attorney revocable or irrevocable?
7.	What law will govern the provision of this power of attorney
8.	In which city will this power of attorney be used
9.	What is the commencement date of this power of attorney
10.	Is the agent liable for acts done in furtherance of the powers conferred?
11.	Are there exceptions as to when agent may be liable
12.	Is the grantor of this power granting general powers to the agent? 
13.	Are there specific powers you may wish for the agent to exercise on your behalf? If yes , then list same in details
14.	Are there acts you want to expressly forbid the agent from doing on your behalf
15.	Is agent required to give accounts? 
16.	Is the agent allowed to delegate any powers conferred by virtue of this power of attorney
17.	Is the agent entitled to any financial compensations?
18.	Are strangers or third parties who act pursuant to the power of attorney liable to the principal
19.	When will the power of attorney be deemed to stop existing?





DESCRIPTION
A promissory note is a written promise by one party (the borrower) to another (the lender); to pay a definite sum of money either on demand or at a specified future date. A promissory note usually specifies the principal amount, interest rate, maturity date, date of issuance, signature of the issuer. 
FREQUENTLY ASKED QUESTIONS (FAQs)
When should I use a Promissory Note?
Promissory notes can be used for �soft� loans. Soft loans refer to informal borrowings usually of lower sums between individuals, colleagues, friends and family. Since soft loans do not require complex legal documentation,  the Promissory Note serves as a legal record of indebtedness.

What is the difference between a promissory note and a loan agreement?
A promissory note is a simple document that is not as complex as a loan agreement.. A promissory note is used for small amount of loans and is essentially a formal contract between parties that contains a promise to pay a certain amount of money on demand at a specified time or over a period of time in future. 
Unlike a promissory note, a loan agreement imposes obligations on both parties, making it essential for both parties to sign. A promissory note on the other hand may be executed only by the borrower, as it is in essence a �note� confirming indebtedness. 
Deciding on which document to use will be based on the circumstances of the loan and the relationship between the parties. 

What does a promissory note contain? 
A promissory note should contain: 
�	The names and addresses of the borrower and the lender
�	Date of issuance
�	Maturity date of the loan
�	The amount of money being borrowed 
�	How often payments will be made and in what amount
�	Signatures of both parties, in order for the note to be enforceable
				


PROMISSORY NOTE
This PROMISSORY NOTE (hereinafter referred to as the Note) is dated this ______ day of ______________, 20__ between [_____1______] of [______2______] (hereinafter referred to as the Maker) and [_____3______] of [_____4______] (hereinafter referred to as the Holder).
The Maker and the Holder are referred to individually as Party and collectively as Parties. 

WHEREAS 
A.	The Holder has advanced [____5_____] (hereinafter referred to as the Sum) to the Maker (receipt of which the Maker acknowledges).
B.	For the value received, the Maker promises to pay to the Holder the Sum from the date hereof until the Sum owed is paid in full, as hereinafter provided and upon the terms and conditions provided in this agreement.

THE PARTIES AGREE AS FOLLOWS:
1.	MAKERS PROMISE TO PAY 
A.	In return for value received, the Maker promises to pay to the Holder the Guaranteed Debt as described below in Clause 4, including the Sum with interest or holding, handling or processing fee, if any, late charges, returned payment charges, and all other amounts that may become due under this Note. 
B.	The Maker acknowledges and agrees that it is the Maker�s responsibility to repay amounts due under this Note and to uphold the obligations under this Note.  

2.	PAYMENT TERMS 
A.	TERM
Payments shall be made on a [_____6_____] basis, in the amount of [______7______] to be paid on [_____8_____].  

B.	DELIVERY:  
Payments shall be made into the bank account of the Holder at 
BANK NAME: [___9____]
ACCOUNT NUMBER: [____10____]
ACCOUNT NAME: [____11_____];

Provided that any payments due under this Note may be paid into such other account as may later be agreed upon by the Parties. 

C.	PREPAYMENTS. 
In event that the Maker prepays the Note in part, the Maker agrees to continue to make the regularly scheduled payments until all amounts due under this Note are paid.

3.	FAILURE TO PAY 
A.	LATE CHARGES. 
In the event that the Holder has not received the full amount of any monthly payments at such period as specified in Clause 2A above, the Maker shall owe a late payment fee to the Holder in the sum of [_____12_____], due immediately in addition to the previously owed balance. If the owed balance and the late fee is not paid promptly and immediately, the Holder may, at his/her option, declare the Note in default. 

B.	DEFAULT. 
The Maker will be in default for any of the following reasons: 
(i)	The full amount of each monthly payment remains unpaid after the date stated in Clause 2A above; 
(ii)	The Maker defaults on any promise made in this Note; 
(iii)	The Maker has made any untrue statement or misrepresentation in the Maker's application or any other certificate or document given or made for this Note; 
(iv)	The death of the Maker; 
(v)	The Maker files for bankruptcy or is the subject of a bankruptcy claim filed by another; 
(vi)	The Maker becomes insolvent and/or cannot pay the Maker's debts as they become due.
C.	ACCELERATION.  
At any time the Maker is in default as under Clause 3B above, the Holder may require the Maker to pay the full amount due under the Note, provided that in the event that the Holder does not, upon the Maker�s default, require the Maker to pay immediately, the Holder shall still have the right to do so at a later time.

D.	NOTICE.
In the event that the Maker is in default for a period exceeding [____13____], the Holder may send Maker a notice stating that Maker is in default and if the overdue amount is not paid by a certain date, the Holder may require Maker to pay immediately the full amount of principal which has not been paid and all interested owed on the amount. 
E.	PAYMENT OF NOTE HOLDER'S COSTS AND EXPENSES. 
In the event that the Holder has required the Maker to pay immediately in full as described above, the Holder shall have the right to be reimbursed for all of its costs and expenses to the extent not prohibited by the governing law, including but not limited to, cost incurred in the procurement of collection agencies and reasonable attorney's fees.

4.	GUARANTEED DEBT
The Maker agrees that the Guaranteed Debt shall consist of all amounts owed by Maker under this Note, including, but not limited to: 
A.	the Principal amount; 
B.	Interest at the rate of [____14____]; 
C.	Late Fees;
D.	Default Interest at the rate of [_____15_____]; 
E.	amounts owed as a consequence of a declared Event of Default and acceleration by Holder; and
F.	Holder's other costs and expenses of enforcing the Note including, but not limited to, attorneys' fees, and the costs of any arbitration proceeding to enforce the Note against Maker.

5.	NOTICES
A.	All notices (including the notices as required by Clause 3D) related or required hereunder to be given to any Party under this Note shall be sent to the Party at its address first mentioned herein or such other address as each party may hereafter communicate in writing. 
B.	All notices shall be in writing and may be delivered physically or sent by registered mail or certified mail or electronic mail. The day of mailing of any such notice shall be deemed the date of the giving thereof.
C.	In respect of electronic mail;
The Maker shall be communicated with via [____16_____] 
The Holder shall be communicated with via [_____17_____]


6.	WAIVER AND MODIFICATION
A.	No waiver of any breach or default hereunder shall be deemed a waiver of any subsequent breach or default of the same or similar nature 
B.	The terms and conditions of this Note shall not be altered, modified or revised unless by a written agreement signed by both parties specifically referencing this Note. 


7.	GOVERNING LAW  
The implementation and enforcement of this Note shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Note.

8.	SEVERABILITY 
In the event that any clause or provision in this Note is held invalid or unenforceable by a court of competent jurisdiction in Nigeria, such invalidity shall not affect the validity or operation of any other clause or provision; and such invalid clause or provision shall be deemed to be severed from this Note.



IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS NOTE IN THE MANNER BELOW, ON THE DATE INDICATE ABOVE:

Signed by the within named Maker




  (repeat reference 1)					____________________
NAME						SIGNATURE

IN THE PRESENCE OF:
NAME:
ADDRESS: 
OCCUPATION:
SIGNATURE:


Signed by the within named Holder





(repeat reference 3)					____________________
NAME						SIGNATURE

IN THE PRESENCE OF:
NAME:
ADDRESS: 
OCCUPATION:
SIGNATURE:






REFERENCES

1.	Who is the name of the maker?
2.	What is the address of the maker?
3.	What is the name of the holder?
4.	What is the address of the holder?
5.	How much are you promising to pay?
6.	How often will the payments be made? E.g. Weekly or Monthly or Yearly
7.	How much is each installment? 
8.	On what date will the payments be made? E.g. on the 25th of every month 
9.	In what bank will the payments be made?
10.	What is the account number?
11.	What is the account name?
12.	 How much is the late payment fee? Late payment fee is the fine the borrower will pay for not making the payment on time. N.B: if you do not want to charge a late payment fee enter 0
13.	Insert the grace period for default before a demand notice is sent. E.g. 7 days or 2 weeks or 1 month  
14.	How much interest is to be charged on the principal?
15.	How much interest is charged on the late fee? N.B if you do not want to charge interest on the late payment fee you can enter 0
16.	What is the email address of the Maker?
17.	What is the email address of the Holder?





DESCRIPTION OF DOCUMENT 
Powers transferred by you (donor) to your attorney/donee may be withdrawn by a legal document called Revocation of Power of Attorney. This is used to cancel, terminate or annul powers earlier granted in a power of attorney. The document is a written confirmation that the powers earlier granted by a donor in a power of attorney to his attorney/donee are withdrawn. The document when executed and sent to the donee becomes a notice that you no longer require those services. 

NB: Notice of revocation of a power of attorney may not be able to revoke a power of attorney that is deemed irrevocable  


REVOCATION OF POWER OF ATTORNEY 
I _____________1a_________________ of  ___________1b____________, hereby revoke the power of attorney granted by me on the ___day of _________20__  (2) which appointed __________3a_____________ of ______________3b________________ as my donee/attorney. 
Notice is hereby given that _______________4_______________ no longer has the authority to act on my behalf, any and all authority previously conferred by the said power of attorney is hereby revoked, cancelled and terminated as of this __day of __________20__.
IN WITNESS OF WHICH the Donor has executed this deed in the manner below on the date indicated above.

SIGNED, SEALED AND DELIVERED 
 By the within named DONOR 

_______________________________
		    5

In the presence of:
NAME: _____________________
ADDRESS: __________________
OCCUPATION: ___________________
SIGNATURE: _____________________




References:
1a- Donor�s name
1b- Donor�s address  
2- The Date of execution of the power of attorney agreement which is to be revoked
3a- Donee�s name
3b- Donee�s address
4- Donee�s name
5- Donor�s name

FREQUENTLY ASKED QUESTIONS (FAQ�s)
Why would I want to revoke a power of attorney?
A power of attorney may be revoked for any of the following reasons:
a.	You have found a more appropriate donee/attorney
b.	You are now willing to act on your own behalf and no longer need the attorney/donee
c.	You no longer trust the donee/attorney earlier appointed 
d.	The attorney is no longer able to perform the functions you appointed him for 

Who is a donor? 
The donor is the party/person who transferred powers to another in the power of attorney which is now to be revoked.

Who is a donee?
The donee is the party or person to whom powers were transferred by the donor in a power of attorney agreement.
The donee is the person selected by the donor as his representative under the power of attorney. 
Do I need to give the reason for the revocation?
You can revoke the power of attorney without giving any reasons. 

Do I need to give notice of the revocation to my Attorney?
It is necessary that the attorney receives a notice of the revocation of the power of attorney.  This is because, the power of attorney will be deemed to be in force until the notice of revocation is sent to the donee/attorney. It is also advisable to notify persons dealing with the donee of the revocation.






DESCRIPTION
A secondment agreement is an agreement between an Employer and another organisation (the host) for the temporary assignment of an employee to work with the Host. In a secondment arrangement, the secondee will remain employed by the original employer during the secondment and will following the termination of the secondment return to the seconder. 

FREQUENTLY ASKED QUESTIONS (FAQs)
What does a Secondment Agreement contain?
A secondment agreement usually contains:
�	the duration of the secondment; 
�	payment of the Employee�s remuneration during the secondment; 
�	indemnities; 
�	termination of the secondment agreement; and 
�	the ownership of intellectual property developed during the secondment.  

Can a secondment be internal?
Yes, an employee who works in a large organisation may be seconded to another section of that organisation. However, an internal secondment can be done much more informally and a fully detailed Secondment Agreement is unlikely to be required.

Must the Employee sign the Secondment Agreement? 
No. The secondment Agreement can be validly executed by the Employer and the Host Company. 
Although, some Secondment Agreement drafts require the Employee�s signature, it is not compulsory. As such this document is drafted to not require the signature of the Employee. 

DRAFT 
This SECONDMENT AGREEMENT (hereinafter referred to as the �Agreement�) is made this ____ day of __________________ 20__ 
BETWEEN 
_______1______ of a company incorporated and existing under the laws of _______2_______ with RC Number: ______3_______ having its office address at ________4_______ (hereinafter referred to as the Employer; which shall include its lawful successors and assigns) and 
_____5_____ a company incorporated and existing under the laws of _____6______ with RC Number: _____7_____ having its office address at _____8______ (hereinafter referred to as the Company, which shall include its lawful successors and assigns). 
The Employer and the Company are referred to individually as Party and collectively as Parties. 

WHEREAS
A.	________9_______(hereinafter referred to as the Employee) is in the employ of the Employer by virtue of a Contract of Employment dated _______10______ (hereinafter referred to as the Contract of Employment) 
B.	The Company requires the Employee to act in the capacity of ____11____ and the Employer has agreed to second the Employee on the terms and conditions set forth in this Agreement.
 
THE PARTIES AGREE AS FOLLOWS:
1.	RELATIONSHIP BETWEEN THE PARTIES 
A.	The Employer and the Company agree that the Employee will at all times remain an employee of the Employer notwithstanding this secondment to the Company.
B.	Nothing in this Agreement shall constitute or be deemed to constitute a partnership or agency relationship as existing between the Employer and the Company. To this extent, neither party shall have authority or power to bind the other or act as agent of the other, or to contract in the name of or create liability against the other in any way or for any purpose except as expressly authorized in writing by the other from time to time.

2.	TERM 
This Agreement shall commence on _____12_____ and subject to the remaining terms of this Agreement, remain for a period of ______13_______ (Secondment Period).

3.	SECONDMENT OF THE EMPLOYEE
A.	The Employer will second the Employee to the Company in order to perform the Services set out in schedule 1. 
B.	The Employee will devote such time, attention and skill as may be required by the Company from time to time for the proper performance of the Services. 
C.	During the Secondment Period, the Employee will be governed by the same workplace rules, policies and practices as established by the Company for its own employees, as amended from time to time including business expenses policies.

4.	OBLIGATIONS OF THE COMPANY 
The Company agrees that: 
A.	The scope of services provided by the Employee shall be under the sole discretion and control of the Company. 
B.	The Employee shall be considered an agent of the Company and not of the Employer in all matters related to or in connection with the performance of the Services.
C.	It shall ensure the Employee observes its health and safety policies and procedures and maintains a safe method of working. The Employee will observe all reasonable safety instructions given from time to time by the Company.
D.	It shall be responsible for providing the Employer with all relevant information to enable the Employee perform the Services optimally. 
E.	It shall be responsible for the submission of any such reports, reviews and appraisals of Employee performance during this secondment arrangement as and when reasonably requested by the Employer. 

5.	EMPLOYEE'S REMUNERATION AND EXPENSES 
A.	The Company will be responsible for the payment of the Employee�s remuneration and worker�s compensation, where appropriate. The Company will pay all appropriate tax to the relevant authorities in relation to the Employee�s remuneration during the Secondment Period.
B.	During the Secondment Period the Employee shall be paid by the Company either at the rate determined in the Employee�s Employment Contract with the Employer or as agreed and set out in Schedule 2 of this Agreement.  
C.	The Company will reimburse directly to the Employee(s) any out-of-pocket expenses properly incurred by the Employee in relation to the performance of Services for the Company during the Secondment Period. All claims for expenses must be accompanied by proper documentary proof of the expenses, such as receipts.

6.	WORK HOURS
For the purposes of this Secondment, the work hours of the Employee shall be _____15_______ on ________16_______

7.	PERIOD OF LEAVE 
A.	The Employee shall during the Term of this Agreement be entitled to all such holidays, vacations and leave as are stipulated in the Contract of Employment.  During the Secondment Period, the Employee shall book all leave to which the Employee is entitled with the Company in accordance with the procedures of the Company and at times that are reasonably convenient to the Company.

B.	In the event of sickness, the Employee shall notify both the Employer and the Company in accordance with the agreed procedures of each party. 

8.	GRIEVANCE AND DISCIPLINE 
All matters of grievance and discipline which occur during the Secondment Period shall be dealt with by the Company in conjunction with the Employer according to the Company�s established grievance and disciplinary procedure.


9.	CONFIDENTIALITY 
A.	It is acknowledged that to enable the Employee provide the Services during the Secondment Period, the Company will provide the Employee with information of a highly confidential nature which is or may be private, confidential or secret, being information or material which is the property of the Company or which the Company is obliged to hold confidential include, without limitation, all trade secrets, lists or details of customers, information relating to the working of any process or invention carried on or used by the Company or any subsidiary or associate, research projects, prices, discounts, mark-ups, future business strategy, marketing, tenders, any price sensitive information, and any proprietary information. (Any and all of the foregoing being ("Confidential Information")).

B.	The Employer will use reasonable efforts to procure that the Employee adopts all such procedures as the Company may reasonably require and to keep confidential all Confidential Information and that the Employee shall not, (save as required by law) disclose the Confidential Information in whole or in part to anyone and agrees not to disclose the Confidential Information other than in connection with the provision of the Services. The Employer will not, however, be liable for any failure on the part of the Employee to observe this requirement.

10.	INTELLECTUAL PROPERTY 
A.	The Employee may make inventions or create other industrial or intellectual property during the Term of this Agreement.

B.	Any discovery, development, invention, improvement, design, process, formula, information, computer program, semi-conductor or other topography, copyright work, trade mark or trade name or get-up made, created devised, developed or discovered by the Employee during the Secondment (whether capable of being patented or registered or not) either alone or with any other person in connection with or in any way affecting or relating to the business of the Company or capable of being used or adapted for use therein or in connection therewith  shall forthwith be disclosed to the Company and shall belong to and be the absolute property of the Company.

11.	TERMINATION
This Agreement may terminate where any of the following event occur:
i.	The expiration of _____14______ following delivery of a written termination notice by the Company to the Employer;  
ii.	The termination of the Employee�s Contract of Employment; 
iii.	The Employee commits any act or makes any omission (whether or not in connection with the secondment) which would entitle the Company to dismiss him summarily if he were employed by the Company on the terms and conditions under which he is employed by the Employee;
iv.	Either Party commits any material breach of its obligations under this Agreement;
v.	The termination of this Agreement shall not affect any rights or obligations of the parties which accrued prior to such termination.

12.	OBLIGATION FOLLOWING TERMINATION
A.	Upon termination of this Agreement, however occasioned, the Employer shall use all reasonable endeavours to procure that the Employee shall (if the Company so requests):
i.	Deliver to the Company all documents (including, but not limited to, correspondence, lists of clients or customers, plans, drawings, accounts and other documents of whatsoever nature and all copies thereof, whether on paper, computer disc or otherwise) made, compiled or acquired by the Employee during this Agreement and relating to the affairs of the Company and any other property of the Company which is in his possession, custody, care or control outside the premises or property of the Company; and 
ii.	Confirm in writing and produce such evidence as necessary and/or reasonable to prove compliance with the Employee�s obligations under this clause. 
B.	The Parties shall cooperate on all issues relating to the termination of the Employee�s secondment. 


13.	INDEMNITY
Each Party agrees to fully indemnify and keep fully indemnified the other Party on demand against all actions, claims, costs, liabilities and losses that are made, suffered or incurred as a consequence of or which relate to or arise directly from the failure of the other Party to perform its obligations hereunder. 

14.	WARRANTIES AND REPRESENTATION
Each Party represents and warrants as follows: 
A.	It is duly authorized to execute and deliver this Agreement and any other documentation relating to or arising out of this Agreement to which it is a Party; and executing; and delivering same is not in contravention with any law, its constitutional documents, any judgment, any rule or regulation imposed by any authority under the Governing Law.
B.	It is capable of performing all such actions, duties and obligations imposed hereunder.

15.	ENTIRE AGREEMENT 
A.	This Agreement contains the entire Agreement and understanding of the Parties and supersedes all prior agreements, understandings or arrangements (both oral and written) relating to the subject matter of this Agreement.
B.	No variation, supplement, deletion or replacement of or from this Agreement or any of its terms will be effective unless made in writing and signed by or on behalf of each Party. 

16.	ASSIGNMENT 
A.	No Party may assign, transfer or subcontract all or any of its rights or obligations under this Agreement without prior written consent of the other. 
B.	This Agreement will enure in the benefit of and bind the respective successors and permitted assigns of the Parties.

17.	SEVERABILITY 
In the event that any clause or provision in this Agreement is held invalid or unenforceable by a court of competent jurisdiction, such invalidity shall not affect the validity or operation of any other clause or provision; and such invalid clause or provision shall be deemed to be severed from this Agreement 

18.	NOTICES 
A.	All notices required hereunder to be given to any party under this Agreement shall be sent to the Party at its address first mentioned herein or such other address as each party may hereafter communicate in writing. 
B.	All notices shall be in writing and may be delivered physically or sent by registered mail or certified mail or electronic mail. The day of mailing of any such notice shall be deemed the date of the giving thereof.
C.	In respect of notices sent by electronic mail as mentioned above:
i.	notices to the Employer shall be sent to ____17_____ 
ii.	notices to the Company shall be sent to ____18_____

19.	GOVERNING LAW 
The implementation and enforcement of this Agreement shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement.

20.	MISCELLANEOUS 
This Agreement may be executed in two (2) or more counterparts, each of which shall be deemed an original, but all of which together shall constitute one (1) and the same Agreement.

IN WITNESS OF WHICH the Parties have executed this Agreement in the manner below on the date indicated above: 
The common seal of (repeat ref 1) was hereunto affixed in the presence of: 




____________________					______________________	
Name: 							Name: 
Designation: 						Designation:


The common seal of (repeat ref 5) was hereunto affixed in the presence of: 



____________________					______________________	
Name: 							Name: 
Designation: 						Designation: 







Description 
A Shareholder Loan Agreement is used when a company intends to borrow money from its shareholder(s) or a company owes money to a shareholder (for salary, etc.). This agreement serves as documentary evidence of the company�s indebtedness to the shareholder(s).

Frequently Asked Questions 
As a shareholder, should I use a Loan Agreement or Shareholder Loan Agreement?
A Shareholder Loan Agreement applies where a shareholder lends money to the company, while a Loan Agreement applies if the shareholder is borrowing money from company.

What is the difference between a Promissory Note and a Shareholder Loan Agreement?
Both contracts evidence a debt owed from a borrower to a lender, but a Promissory Note may be between any two parties. The Shareholder Loan Agreement is used when a Company borrows money from one (or more) of its shareholders.

As the Shareholder, should I demand that the company provide security/collateral for the loan?
Although it is not compulsory to request that the company provide a collateral, in the event the Company goes bankrupt, your debt will have priority over debts owed by the company to other lenders if you have secured your loan through collateral. It is therefore advisable to request a collateral (especially where the company does not have enough assets to fully repay all of its debts).

Must the collateral be equivalent in value to the loan amount?
No, if collateral is given for the loan, it can be for any amount. However, if the collateral is significantly less valuable than the debt, there is a chance that the shareholder's loan will only be partially secured. If the company goes bankrupt and does not have enough assets to pay its debts, the Shareholder may not receive full repayment of the loan.

SHAREHOLDER LOAN AGREEMENT

THIS SHAREHOLDER LOAN AGREEMENT is made this _____day of ___________________, 20____

BETWEEN 

_____1a of ______1b (hereinafter referred to as �the Shareholder� which expression shall where the context so admits include her heirs, executors, administrators, successors-in-title, personal/legal representatives, and assigns) and 

___2a, a company incorporated under the laws of _____2b whose registered address is situate at ___2c (hereinafter called �Company�, which expression shall where the context so admits include its affiliates, successors-in-title and assigns).

The Shareholder and the Company may be referred to individually as �Party� and collectively as �Parties�

WHEREAS 
i.	The Company desires to borrow up to an aggregate principal amount of _____3 from the Shareholder for _____3a in accordance with the terms and conditions of this Agreement.
ii.	The Shareholder, which is holding __4 of the total issued share capital of the Company, is willing to make the loan available to the Company in accordance with the terms and conditions of this Loan Agreement.

IT IS AGREED as follows:

1.	Facility Amount & Interest  
The Shareholder promises to loan ___5 to the Company and the Company promises to repay this principal amount to the Shareholder with interest at the rate of ___6 per annum, calculated yearly (the Loan). 

2.	Payment Terms 
2.1.	This Loan will be repaid in ____8 instalments of ____9 each on a ____10 basis commencing on ___11 with final payment made on ___11.
2.2.	Payments under this Agreement from the Company to the Shareholder shall be made into the Shareholder�s bank account as provided below:
Bank Name: _____18_____
Account Number: ____19____
Account Name: _____20______ 

2.3.	Provided that the Shareholder is at liberty to change the account details in clause 2.2 above by giving notice of same to the Company.

2.4.	The Company shall pay all costs and expenses in connection with the Loan.

3.	Security 
Unless otherwise agreed by the Parties, the Company shall grant ___12a to the Shareholder as security for the Loan.

4.	Default 
4.1.	Notwithstanding anything to the contrary in this Agreement, if the Company defaults in the performance of any obligations under this Agreement, then the Shareholder may declare the principal amount owing under this Agreement at that time to be immediately due and payable.
 
4.2.	Further, if the Shareholder declares the principal amount owing under this Agreement to be immediately due and payable, and the Company fails to provide full payment, interest in the amount of __13%, calculated yearly will be charged on the outstanding amount, commencing the day the principal amount is declared due and payable, until full payment is received by the Shareholder. 

5.	Governing Law and Dispute Resolution 
5.1.	The construction, validity and performance of this Agreement shall be governed in all respects by the Laws of the Federal Republic of Nigeria.

5.2.	If there is a dispute, controversy or claim between the Parties, the Parties shall try to reach agreement amicably by direct negotiations. If no agreement can be reached within thirty (30) days, the dispute, controversy or claim shall be referred to Arbitration in accordance with the Arbitration and Conciliation Act Cap A18 Laws of the Federation of Nigeria 2004.  A dispute shall be deemed to have arisen when either Parties notifies the other Party in writing to that effect. The Language of Arbitration shall be English Language.

6.	Amendment 
This Agreement may only be amended or modified by a written instrument executed by both the Company and the Shareholder.

7.	Severability 
7.1.	The clauses and paragraphs contained in this Agreement are intended to be read and construed independently of each other. If any part of this Agreement is held to be invalid, this invalidity will not affect the operation of any other part of this Agreement. 
 
7.2.	If at any time any provision in whole or in part of this Agreement is illegal, invalid or unenforceable under or inconsistent with the provisions of any applicable law or would by reason of the provisions of any such law render the Shareholder unable to collect the amount of any loss sustained by it as a result of making the Loan which it would otherwise be able to collect, then such provision shall not apply and shall be construed so as not to apply to the extent that it is so illegal, invalid, unenforceable or inconsistent or would so render the Shareholder unable to collect the amount of any such loss, and this Loan will continue in full force and effect and be construed as if it had been executed without such illegal, invalid, unenforceable or inconsistent provision.

8.	Entire Agreement 
This Agreement and any documents or instruments delivered pursuant to, this Agreement constitute the whole and entire agreement between the Parties with respect to the Loan.

IN WITNESS WHEREOF, the parties have executed this Shareholder Loan Agreement on the date first above written 
 
THE COMMON SEAL of the within named Company is hereby affixed and the deed duly delivered in the presence of:


_________________					  _______________________
Director						  Director/Secretary

Name:	______________________			  Name: ______________________

Designation: ________________		              Designation: ___________________
 
References:
1a. What is the Shareholder�s name?
1b. What is the Shareholder�s address?
2a. What is the Company�s name?
2b. In what Country is the Company registered?
2c. What is the Company�s registered address?
3. How much is the Shareholder willing to lend the Company?
3a. What will the loan be used for?  
4. How many shares does the shareholder have in the Company?
5. Repeat ref 3
6. What percentage of interest is payable on the loan per annum? (where there is no interest, please put 0%)
8. How many instalments will the loan be made? 
9. How much will the company be required to pay on each instalment? 
10. At what intervals will the borrower repay the Loan? E.g. Daily or Monthly or Yearly
11. When will the company be required to start making repayments for the loan? 
11a. Please provide the date in which the company is required to make final payment for the loan 
12a. What will the Company provide as security/collateral for the loan? 
18.	 In what bank should the shareholder�s repayments be made? E.g. GTBank or Zenith Bank or Access Bank etc. 
19. Please provide the account number 
20. What is the account name?
13. What percentage of interest will be charged on the loan amount in the event that the Company defaults in making payments when due?





DESCRIPTION OF THE DOCUMENT
A Shareholders� Agreement is a contract between the shareholders of a company which sets out the shareholders� right, their obligations to the company and the relationship between them. The Shareholders' Agreement is intended to make sure that all shareholders (no matter how small their shareholding) are treated fairly and that their rights are protected. Though the Companies and Allied Matters Act (CAMA) and a company�s Articles of Association cover some of the issues, a Shareholders� Agreement details the specific duties and rights of shareholders which to a larger extent are not covered in the articles of association. It is advisable to set out in clear details all the terms guiding the shareholders in relationship with the company to avoid future conflicts. 

Frequently Asked Questions (FAQs)
Who is a shareholder of a company?
A shareholder is a person who owns shares in a company and takes part in the profit sharing and loss of the company.

When is a Shareholders� Agreement required?
�	When you are about to incorporate a company and require investors through the sale of shares.
�	When you want to invest in a company and want to protect your interests as a shareholder (if the company does not already have a Shareholders� Agreement) Where the company already has a Shareholders� Agreement, recommendation is to seek legal advice on the implication, rights and limitations attributable to shareholders by virtue of the Agreement.
�	To avoid or quickly address potential disputes between shareholders.

In what instance will a Shareholders� Agreement be required and not a Partnership Agreement? 
In many respects, shareholder agreements and partnership agreements do the same things. Both types of agreements deal with governance issues: making decisions, veto powers, introducing new members, and so on. Where however the business is incorporated (i.e. it operates as a company), the agreement should be a shareholders agreement between the shareholders and the company. If the business is not a company, then the agreement would more likely be a partnership agreement.

Can a company have only one Shareholder?
A company incorporated in Nigeria cannot have only one shareholder. A minimum of two shareholders is required. 

Can an infant be a shareholder of a company?
An infant can be a shareholder where there are at least two adult shareholders. 

SHAREHOLDERS� AGREEMENT

This Shareholders� Agreement (�the Agreement�) is made this _____Day of ____________ 20___ 

BETWEEN 

____________________ (1a) of_________________________ (1b) [hereinafter referred to as �the Company�], which expression shall where the context admits include its successors and assigns.       


The parties listed in Appendix A, as further described in the said Appendix (hereinafter referred to as the Shareholders which expression shall where the context so admits include their successors and assigns)		            

The Company and Shareholders are hereafter referred to as Parties collectively and Party individually. 

WHEREAS:
i.	The Shareholders wish to enter into this Agreement in order to regulate the operation and management of the Company and the relationship between themselves as the Company�s Shareholders.
ii.	The Company has executed this Agreement for the purpose of acknowledging notice of this Agreement and for the purpose of making certain representations 
  
1.	DEFINITIONS AND INTERPRETATIONS
	1.1 In this Agreement:
�Agreement� 	means this Shareholders� Agreement. 


�Applicable Law�	means any law, statute, constitution, judgment, treaty, regulation, rule, bye-law, order, decree, code of practice, circular, directive, other legislative measure, guidance note, requirement, request, guideline or injunction (whether or not having the force of law and, to the extent not having the force of law, is generally complied with by persons to whom it is addressed or applied) of or made by any authority, which is binding and enforceable on any of the Shareholders.

            �Board� 	                          means the board of directors of the Company as the same may be constituted from time to time in accordance with this Agreement. 

           �Business� 		means the business of the Company as described in clause 3 of this Agreement and such other business as the Board may agree from time to time, should be carried on by the Company.

�Business Day�	means a day (other than a Saturday or Sunday) on which banks are generally open in Lagos for normal business

�Corporate Account(s)�    	means the bank account(s) to be maintained by and for the Company for the purpose of paying the initial capital commitments and other payments as may be agreed by the Board from time to time.

�Director� 			means any director appointed by the Shareholders including, where applicable, any alternate director.

�Person�		means an individual or company or other entity as the case may be.

"Effective Date" 	means the date of commencement of this Agreement as stated in clause 2. 
�Shareholder(s)� 		means the parties listed in Appendix A and or any new or future allottee or transferee of shares, who accedes to this Agreement and whose name is entered in the register of members of the Company. 

.

�Shares� 			means the units of ownership of the Company.

�Third Party� 		means any Person that is not a Party to this Agreement or Shareholder of the company.

2.	 EFFECTIVE DATE
This Agreement shall come into force and effect and be binding upon the Shareholders from the date of execution of this Agreement by all the Parties.

3.	OBJECTS OF THE COMPANY
	The primary object of the Company shall be as outlined in its Memorandum of Association and such other businesses as the Board may agree, from time to time, should be carried on by the Company.

4.	CLASSIFICATION OF SHARES AND CAPITAL STRUCTURE 
	4.1	The Shareholders agree that there shall be no classification of the shares of the Company.
	4.2	The Shares of the Company shall, unless otherwise provided for under this Agreement or subsequently agreed by the Shareholders, have identical rights and privileges with respect to dividend and voting right.
4.3.	The corporate details of the Company and the initial capital commitment of each Shareholder is as set out beside their respective names in Appendix B of this Agreement.

5.	CAPITAL STRUCTURE	
		5.1	Cash Calls and Future Capitalisation
	        5.1.1 	The Shareholders in order to meet the Company�s financial requirements may, from time to time, increase its authorized and/or paid up share capital. 

  5.1.3	If the Company proposes to issue further Shares, such Shares shall be offered to the Shareholders to take up and purchase a pro-rata number of shares equivalent to its shareholding in the Company and upon terms determined based on fair market value.
5.1.4	The Shareholders hereby agree to subscribe to such number of issued shares as they may be called upon to subscribe to proportionately in accordance with their respective shareholding in the Company.
5.1.5	Where a Shareholder declines the offer or does not subscribe for all the Shares offered within a timeline agreed by the Shareholders, any of the unsubscribed Shares may be offered by the Company to other Shareholders pro rata their Shareholding and thereafter, any outstanding Shares that remains unsubscribed by the other Shareholders shall be offered to a Third Party.

6.	SHARE TRANSFERS 	
6.1	Transfer restrictions 
6.1.1 	Any Shareholder may, subject to the provisions of this Agreement and in compliance with the Applicable Law, Transfer, whether directly or indirectly, all or any of its/their  Shares to a Third Party provided that:
i.	The Shareholder is not in default of this Agreement; 
ii.	Such shares are first offered to the other Shareholders; 
iii.	The Third Party purchaser agrees and undertakes to be bound by the terms and conditions of this Agreement;
iv.	Such Third Party has the legal capacity to purchase such shares.
6.2.	Permitted Transfers  	
6.2.1	A Shareholder may transfer all or any of its Shares to any of the following Persons:
i.	an individual (a family member) being the spouse, child, step-child or other direct descendant or direct ancestor of the Shareholder;
ii.	an Affiliate where the Shareholder is a body corporate.
6.3 	Rights of First Offer
6.3.1 	In addition to the requirements set out in Clause 6.1, if at any time, a Shareholder desires to Transfer, whether directly or indirectly, any or all of its Shares or voting interests therein owned by it then, it shall:
(i)  	make an offer for the sale of the Shares to the other Shareholders by a notice (Offer Notice) mentioning therein: - (a) the total number of Shares to be offered for sale (b) the price at which the Shares are being offered for sale (Offer Price), which must not exceed the prevailing market price and (c) any other terms and conditions in connection therewith.
(ii)  	Subject to receiving the Offer Notice, and in accordance with its terms and conditions, the remaining Shareholders shall have the option to purchase between them, all of the Parties shares or such other Shareholder.  
(iii)	If the remaining Shareholders opt not to purchase the Shares from the Shareholder, following an approval in writing by the Board of Directors of the prospective buyer and of the sale of the shares, the Shareholder may dispose of the Shares to a Third Party.
	6.4	Share Transfer to a Third Party
  6.4.1	Any Shareholder may, subject to the provisions of this Agreement and in compliance with the Applicable Law, transfer, whether directly or indirectly, all or any of its/their Shares to a Third Party provided that:
i.	The Shareholder is not in default of this Agreement.
ii.	Such shares are first offered to the other Shareholders.
iii.	The Third Party purchaser agrees and undertakes to be bound by the terms and conditions of this Agreement.

6.5.	 Remedies for Wrongful Transfer
6.5.1			In the event that the shares of any Shareholder are transferred or disposed of in any manner that does not comply with the provisions of this Agreement, or if such shares are taken without complying with the provisions of this Agreement, the Company shall refuse to acknowledge or register any shares transferred in violation of this Agreement. 

6.5.2			The Shareholders shall, upon actual notice thereof, in addition to their rights and remedies under this Agreement, be entitled to purchase such shares from the transferee, under the same terms and conditions set forth in this Agreement as if the transferee had offered to sell such Shares to the Shareholders in accordance with clause 6.3.

6.5.3	The Shareholders agree that in no event shall the purchase price exceed the amount paid for the said shares by the transferee if such shares were acquired by the transferee for consideration.

6.5.4	For the avoidance of doubt and without prejudice to continued responsibility of the transferee to defray any and all liabilities attaching to the Shares, all benefits accruing in respect of the wrongly transferred Shares shall be suspended for the period of the said wrongful transfer until same have been surrendered to the Company and re-transferred to the Shareholders or a Third Party in accordance with Clause 6.3 or 6.4 

6.6	Death of Shareholder
6.6.1	In the event of the death of a Shareholder who dies intestate, the legal representative of his Estate shall be required to sell all of the deceased Shareholders shares in the Company to the Shareholders and shall be deemed to have offered all of the said shares to the other Shareholders.
6.6.2	The Shareholders shall be deemed to have accepted the offer to purchase as many shares as it may legally purchase.

7.	GOVERNANCE
7.1	The relationship between the Shareholders and the Company shall be at arm�s length.
7.2	The Shareholders agree to use their best endeavours to ensure that in their capacities as Shareholders:
7.2.1	The Company performs and complies with all obligations on its part under this Agreement and complies with the restrictions imposed upon it under the Articles of Association.  

7.2.2	The Business is conducted in the best interests of the Company on sound commercial principles, in accordance with the highest standards of corporate governance, Applicable Laws and the Company�s management policy and business plan (or any amendment or modification of the same).

7.3	The Shareholders also agrees that they shall not exercise their rights in any manner which could reasonably be expected to result in:
7.3.1	A breach by the Company of any of its obligations under this Agreement or any restrictions imposed upon it under the Articles (whether or not enforceable against the Company itself); or
7.3.2	The affairs of any member of the Company being carried on in a manner inconsistent with the terms of this Agreement. 

7.4	It is hereby agreed that the Shareholders shall have the right to examine the books, records and accounts to be kept by the company secretary and shall be entitled to receive all information, including monthly management accounts and operating statistics and other trading and financial information. Provided that sufficient notice is given to the Company for production of such information.

8.	SHAREHOLDER MEETINGS
8.1	All Shareholders entitled to attend and vote at general meetings shall receive not less than 21 (twenty one) days written notice of every general meeting unless Shareholders (entitled to attend and vote) together holding not less than ninety per cent. (90%) of the Company�s share capital agree to a shorter notice period.

8.2	Every notice convening a meeting of the Shareholders shall set out the date time, place agenda and details of the business to be transacted at the meeting. A copy of documents to be reviewed or discussed at such meeting shall accompany such notice where possible. 

8.3	The quorum for all general meetings of the Company shall be two-thirds of the Shareholders representing 75% of the Company�s issued share capital in each case present personally or by corporate representative or by proxy.

8.4	If within an hour of the time appointed for the meeting, a quorum is not present, the meeting shall be adjourned to the same day 1 (one) week later at the same time and place. If at any such adjourned meeting, a valid quorum is not present within an hour of the time appointed for such adjourned meeting, the members present shall constitute a quorum.

8.5	The Chairman, of the Board shall preside as a chairman at every Shareholder meeting of the Company. Where the Chairman is not present within one hour after the time appointed for the holding of the meeting or is unwilling to act, the Directors present shall elect one of their number to be chairman of the meeting. If at any meeting no Director is willing to act as Chairman or if no Director is present within one hour after the time appointed for holding the meeting, the Shareholders present shall choose one of their number to be Chairman. 

8.6	Where all or any of the Shareholders participate in a Shareholders� meeting by means of telephone, audio and/or video conferencing or other communication facilities, such Shareholders shall be deemed for the purposes of this Agreement, to be present at that meeting, shall be counted in a quorum and shall be entitled to vote. A resolution passed at such a meeting shall, notwithstanding that the Shareholders are not present together in one place at the time of the meeting, be deemed to have been passed at a meeting of the Shareholders duly called and constituted on the day and at the time at which the meeting was held.

9.	VETO MATTERS  
9.1	The Parties agree that the matters listed in Appendix C shall not be carried out without the prior written consent of Shareholders holding in aggregate more than �2b�% of the Company�s share capital.

9.2	Each of the Shareholders shall use their respective rights and powers to procure, so far as they are each able, that no matter listed in Appendix C is carried out unless the required threshold approval is reached.

9.3	In the event that 75% of the Directors entitled to vote at a meeting of the Board declare that the matters enumerated in Appendix C are (i) overly onerous, (ii) disruptive to Company operations or (iii) deleterious to effective decision-making, the aforementioned matters listed in Appendix C shall be revisited by the Parties and the Parties shall act in good faith to agree a list of matters and an effective percentage approval threshold that addresses the aforementioned concerns.

9.4	Deadlock arising from Decision Making 
9.4.1	If a deadlock arises or occurs, each shareholder must use its reasonable endeavours to resolve the deadlock through good faith negotiations and informal dispute resolution techniques within 10 Business Days after the date on which the deadlock arose (the Negotiation Period).

9.4.2	Where the Parties are unable to resolve the deadlock following the Negotiation Period, any of the Shareholders shall be entitled to either resort to the dispute resolution mechanism in Clause 18 or sell its Shares in the Company to the other Shareholders pro-rata their shareholding in accordance with Clause 6.

10	PROFIT SHARING POLICY
10.1	The Shareholders agree it is in the best interest of the Company to hand over the responsibility of deciding the mechanism for dividend/profit sharing to the Directors. 
10.2	The Directors shall therefore be responsible for preparing and implementing the dividend/profit sharing policy, taking into consideration the Company�s financial standing and the amount that should reasonably be retained in order to meet foreseeable commitments and contingencies.

11	MANAGEMENT AND THE BOARD OF DIRECTORS
11.1 	The Company shall be managed and governed under the overall direction and control of the Board. The Board shall have overall authority with respect to development and management of the Company. The officers of the Company shall have the authority and responsibilities specifically delegated by the Board of Directors, consistent with this Agreement.
11.2 	Composition of the Board
11.2.1 	The Board shall comprise of -----2b------ directors split into -------3---- executive directors and ------4------- non-executive directors.
11.2.2	Each Director shall be entitled to cast one vote on all proposals made to the Board for consideration.
11.2.3	The terms of appointment of the executive Directors shall be governed by a detailed employment contract which shall address remuneration, tenure and all other issues regulating their employment.
11.2.4	Despite the specification of the duties of the executive directors in this Agreement, all directors are at the minimum required to participate in the operations of the Company. As such, the Board is required to meet at least quarterly to examine the position and operations of the Company.
11.2.5	Each Director shall be entitled to cast one vote on all proposals made to the Board for consideration.
11.2.6	All resolutions before the Board (inclusive of those in respect of the company�s account) shall require approval of at least two-thirds of the Board.
11.3 	Chairman
11.3.1	The Shareholders hereby undertake and agree that the Board shall have the right to nominate the Chairman of the Company.  
11.3.2 	The Chairman shall preside over all the meetings of the Board or of the Shareholders of the Company. 
11.3.3	Where the Chairman is not available for a meeting, the Directors who are present may appoint an acting Chairman from amongst themselves for the sole purpose of that meeting. 
		11.3.4 	In the event of any dead lock at any meeting of the Board or of members of the Company, the Chairman or acting Chairman shall have the casting vote. 
11.4 	Managing Director
11.4.1 	The Directors shall appoint the Managing Director of the Company.  
11.4.2	The Managing Director shall not be liable to retire by rotation. The term of each appointment for the Managing Director shall be for such period as specified by the Board and subject to a detailed employment agreement (if considered necessary by the Board) with the appointee.
11.4.3	 The Managing Director shall be responsible for the day-to-day management of the Company. The Managing Director will exercise his powers subject to the overall, direction and control of the Board.
11.5.	Alternate Directors
11.5.1	A Director, other than the Executive Directors, shall be entitled at any time and from time to time, to appoint any person to act as the Director�s alternate (�Alternate Director�) 
11.5.2.	Such Alternate Director shall be entitled, while holding office as such, to receive notices of meetings of the Board or any committee thereof to which the Director has been appointed, and to attend and vote as a Director at any such meetings at which the Director is not present and generally to exercise all the powers, rights (other than the right to appoint an Alternate Director), duties and authorities and to perform all the functions of the Director. 
11.5.3	Further, such Alternate Director shall be entitled to constitute quorum, exercise the vote and sign a written resolution on behalf of the Director at any meeting of the Board or any committee thereof and to the extent permitted by Applicable Law his signature, vote, presence and consent shall be deemed to be that of himself (as if he is a Director in his own right) and the Director for whom he is an Alternate Director.
11.5.4	An appointment of an alternate shall be in writing signed by the appointing Director and, to be valid for any particular Board meeting or meeting of any committee of the Board, must have been delivered at the Company�s registered office, marked for the attention of the Secretary, not less than one (1) Business Day before the time appointed for the Board or committee meeting, as the case may be.
11.5.5	The appointment of an alternate shall be valid for the period, if any, specified in the appointment or until revoked in writing signed by the appointing Director, such revocation having been delivered to the Company�s registered office marked for the attention of the Secretary. The Secretary shall promptly notify all other Directors of such revocation.
11.6 	Mode of conduct of Board Meeting
11.6.1 	Board meetings shall be held at least once every quarter at such places as the Board may determine and failing any such determination at the Company�s office. 
11.6.2	Where all or any of the Directors participate in a Board meeting by means of telephone, audio and/or video conferencing or other communication facilities, such Director shall be deemed for the purposes of this Agreement, to be present at that meeting, shall be counted in a quorum and shall be entitled to vote. 
11.7 	Notice and Agenda for Meeting
Unless the requirement of notice is waived by all Directors, a minimum of fourteen (14) days written notice (or such shorter period as all the Directors may agree) of the Board meetings shall be given to all Directors and their Alternate Directors. Each notice of a meeting of the Board shall contain, inter alia, an agenda specifying in reasonable detail, the matters to be discussed at the relevant meeting and shall be accompanied by all necessary written information. 
11.8 	Quorum
11.8.1 The quorum for the meetings of the Board or any adjournment thereof shall be all Board members in attendance. 
11.8.2	Where a quorum is not present, the said Board meeting shall stand adjourned to the same day in the next week, to be held at the same time (except in the case of an emergency) and place (�Adjourned Meeting�). The same quorum shall be required at any such reconvened meeting. 

11.8.3	All items of business transacted or decisions taken at meetings where the quorum is not so constituted shall be null and void. 
11.9	Director�s Expense
		The Company shall be responsible for providing reasonable allowance to cover travel, hotel and other expenses incurred by a Director on official duties for the Company.


12	TERMINATION 
12.1. 	The Shareholders agree that in the event that any of the Shareholders ceases to hold, directly or indirectly, any Shares of the Company, this Agreement shall stand terminated automatically vis-�-vis such Shareholder. 
12.2	This Agreement shall continue in full force and effect until the terminated in accordance with this Agreement, upon the written agreement of all of the Shareholders or the winding up, receivership, insolvency or administration of the Company
12.3	Provided however, the obligations of such Shareholder under this Agreement relating to confidentiality and dispute resolution and such other provisions of this Agreement that by their nature are intended to survive, shall survive any termination of this Agreement.
13	CONFIDENTIALITY 
13.1 	The Shareholders hereby acknowledge and agree that each of them possesses and will continue to possess information that has been created, discovered, developed, or otherwise known and owned by them, which information has commercial value in the business in which they are or may become engaged (the aforementioned information is hereinafter called "Proprietary Information"). 
13.2 	The Shareholders agree that during the term of this Agreement and after the termination or expiration hereof, each of them will keep in confidence and trust all such Proprietary Information, and they will not use or disclose any such Proprietary Information or anything directly relating to it without the written consent of the other Shareholders. 
	13.3  	In the event of the expiration or termination of this Agreement for any reason, the Shareholders shall promptly, at the direction of the owner of such Proprietary Information, cease to use, destroy or return to the owner all documents and data of any nature pertaining to the Proprietary Information owned by such Shareholder, and will not keep or deliver to anyone else any documents or data of any description or any reproduction of any description containing or pertaining to any Proprietary Information. 
13.4 	This Clause shall not, however, apply to information which: 
a.	 is or becomes publicly available without fault of any Shareholder; 
b.	 was known to any Shareholder on a non-confidential basis prior to disclosure; 
c.	 is independently developed by any Shareholder without use of the Proprietary Information; 
d.	is disclosed in order to comply with the requirements of Applicable Law including any requirements for the stock exchange listing of the Company or any Entity, which directly or indirectly, holds Shares; 

e.	 is disclosed to any of the consultants (legal, financial, technical or otherwise) of the Shareholders, provided that the recipient executes a confidentiality undertaking to use the information solely for the purpose disclosed. 
13.5 	The Shareholders also agree with each other and the Company to use theirs, and to cause the Company to use its best efforts to assure that all information disclosed in connection with the business of the Company and not otherwise generally available shall be kept confidential and shall not be revealed.

14.    NOTICES
14.1 	Any notice to be given under this Agreement shall either be given personally or by courier. 

14.2	The address for service of each Shareholder to this Agreement (in the case of a company) shall be its registered office for the time being and (in the case of an individual) shall be his address stated in Appendix A of this Agreement or any other address for service previously notified to the other Shareholders.  

14.3	 A notice shall be deemed to have been served if personally delivered: at the time of delivery; (Personal delivery shall include delivery by courier service)
13.4	In proving service it shall be sufficient to prove that personal delivery was made.


15.	GENERAL PROVISIONS
15.1	In the event of any ambiguity or conflict arising between the terms of this Agreement and those of the Company�s memorandum and articles of association, the terms of this Agreement shall prevail.
15.2	This Agreement supersedes any previous agreement between the Shareholders in relation to the matters dealt with in this Agreement and represents the entire understanding between the Shareholders in that respect. This Agreement may be amended only by a written Agreement executed and delivered by each Shareholder.
15.3	The Shareholders may provide for the Business such services as may from time to time be agreed between them in writing subject to agreed terms.
15.4	Except as provided in this Agreement, none of the Shareholders shall create or permit to subsist any pledge, lien or charge over, or grant any option or other rights over or dispose of any interest in, all or any of the Shares held or owned by such Shareholder.
15.5		None of the provisions of this Agreement shall be deemed to constitute a partnership between the Shareholders and none of them shall have any authority to bind the other in any way.

 

15.6		All costs, legal fees, registration fees and other expenses incurred on behalf of the Company shall be borne and paid by the Company.

15.7		None of the rights of any Shareholder shall be prejudiced or restricted by any indulgence or forbearance extended to any other Shareholder and no waiver by any Shareholder in respect of any breach shall operate as a waiver in respect of any subsequent breach.

15.8	If any of the provisions of this Agreement is found by a Court or other competent authority to be void or unenforceable, the provision shall be deemed to be deleted from this Agreement and the remaining provisions shall continue in full force and effect. The Shareholders shall nevertheless negotiate in good faith in order to agree the terms of a mutually satisfactory provision to be substituted for the provision which is void or unenforceable.

15.9	This Agreement and all amendments may be executed in two or more counterparts, and by each Shareholder on the same or different counterparts, but all of such counterparts shall together constitute one and the same instrument.


16.	REPRESENTATIONS AND WARRANTIES 
16.1	Each Shareholder warrants that:
16.1.1	It has the legal capacity and has taken all necessary corporate action required to empower and authorise it to enter into and implement this Agreement on the terms and conditions herein set out;  
16.1.2	It is not subject nor has it been subject in the past to financial or criminal investigation by any government authority;
16.1.3	It is not nor will at any time be, a party to any contract or other arrangement of any nature that will materially interfere with the full, due and complete performance of this Agreement.
16.1.4	Subject to this Agreement, it has not made or has any foreseeable plans to assign any of its shareholdings for beneficiaries or creditors;
16.1.5	It is not a Party to a bankruptcy or other insolvency proceedings; 
16.1.6	It is not legally insolvent under the laws of any jurisdiction;
16.1.7	It has taken or caused to be taken all steps, actions and corporate procedures necessary to cause this Agreement to be binding upon it. Each Shareholder shall, if requested by the other Party, furnish sufficient evidence of the authority of the person or persons who will, on behalf of the Party so requested, take any action or execute any documents required or permitted to be taken or executed by such Party under this Agreement.
16.2	The warranties contained in this clause 16.1 are continuing warranties and will survive the issue/transfer of shares should any Party suffer loss as a result of breach of any of the warranties contained in this clause 16.1 by a Shareholder and the other Parties shall be entitled to recover such loss or damages suffered from the Shareholder in breach.
16.3	This Agreement is concluded on the basis of the above representations and warranties that have been given by each Shareholder to the other Parties.

17.		GOVERNING LAW 
The construction, validity and performance of this agreement shall be governed in all respects by the Laws of the Federal Republic of Nigeria. 

18. 	DISPUTE RESOLUTION
18.1	The Shareholders shall in good faith do all within their power to promptly (by discussions and negotiations between the aggrieved Shareholders and Senior Executives with the authority to settle the dispute or difference) settle amicably any difference of disputes concerning the interpretation or performance of this Agreement.

18.2	Any dispute between the Parties in respect of this Agreement shall be resolved by arbitration by a sole arbitrator in accordance with the Arbitration and Conciliation Act Cap A19 Laws of the Federal Republic of Nigeria 2004.


IN WITNESS WHEREOF, the Parties have caused this Agreement to be executed on the date indicated above.
	
The Common Seal of _________________(5a) was herein affixed in the presence of

 


________________________		__________________________
Director						Secretary


SIGNED by the Shareholders listed in Appendix 1

1.      SIGNED by
Name: ____________________________


Signature______________________	

Date_________________________

	
2.      SIGNED by: 
Name: ____________________________________


Signature______________________	

Date_________________________


3. 	SIGNED by: 

Name: ____________________________________


Signature______________________	

Date_________________________



4.	SIGNED by: 

Name: ____________________________________


Signature______________________	

Date_________________________		


5. 	SIGNED by: 

Name: ____________________________________


Signature______________________	

Date_________________________

 
APPENDIX A
SHAREHOLDER DETAILS
No.	Name 	Address
1.		
2.		
3.		
4.		
5.		





 
APPENDIX B
CORPORATE DETAILS AND SHAREHOLDING STRUCTURE
1.	Corporate Details 
Name of the Company: 		
RC Number: 				
Date of Incorporation:			
Registered Address:	
Name and address of Secretary: 
Authorised Share Capital: 
Names of Directors: 	


2.	Shareholding Structure
On and with effect from the Effective Date, the following Shareholders shall hold the equity of the Company and shall be liable for all costs and liabilities and receive profit/dividends in the proportion of their shareholding as represented below, or as may be modified from time to time in accordance with the provisions of this Agreement:
S/N	Name 	No of Shares Allotted 
1.			
2.			
3.			
4.			
5.			

 
APPENDIX C
VETO MATTERS
Whilst this Agreement remains in force the Shareholders shall procure that the following decisions shall not be carried out without the prior written consent of Shareholders holding in aggregate the percentage of share capital referred to in clause 9:
i.	A sale, merger, acquisition, consolidation, recapitalization, liquidation or initial public offering of the Company;
ii.	Amendments to the Company�s Memorandum and Articles of Association;
iii.	Material changes in the nature of the business of the Company;
iv.	Investment decisions which are not provided for in the business plan;
v.	Changes in the compensation of the key managers of the Company;
vi.	Increases or reductions in the Company�s share capital;
vii.	Sale, transfer, lease, licence or disposal of all or a material part of the Company�s business, undertaking or assets, whether by a single transaction or series of transactions related or not (otherwise than in the ordinary course of the Company�s business).
viii.	Borrowing or raising of any sums. 
ix.	Making any loan or advance or otherwise giving credit;
x.	Creating or issuing any debenture, mortgage, charge or other security over any assets of the Company;
xi.	Acquiring any share or loan capital of any corporate (including that of the Company or any Subsidiary) or entering into any partnership or profit sharing arrangement with any person;
xii.	Entering into or making any material change to any contract of employment or for the provision of services by any director or senior manager of the Company (whatever his title or job description) including any increase in salary other than any increase budgeted for in any approved business plan;
xiii.	Entering into or materially varying any agreement for the provision of consultancy, management or other services by any person which will, or is likely to result in, the Company being managed otherwise than by its directors or;
xiv.	Changing the auditors of the Company or appointing any auditors in place of any who resign or make any significant change in the accounting policies and practices for the time being adopted by the Company or making any change in the accounting reference date of the Company.
 
References:
1a. What is the name of the company?
1b. What is the address of the company?
2a. What percentage of the company�s share capital held by the shareholders is required to consent to veto matters?  Veto matters are contractually-agreed matters that need consent of a majority of the shareholders before being approved and implemented. These matters generally protect the rights of the minority shareholders and as such the threshold of shareholding essentially ranges from 75% to 90%. Suggested veto matters are contained in Appendix C. Recommendation is to review the Appendix C and decide what you (in consultation with the other shareholders) will need to take out, add or amend. 
2b. How many directors will the company have? Advisable to have odd numbers i.e. 3 directors, 7 directors etc. to reduce the occurrence of deadlocks while voting
3. How many of the directors will be executive directors? Executive directors are members of the board of directors of a company employed full time by the company and often have a specified area of responsibility, such as finance or production
4. How many of the directors will be non-executive directors? Non-executive directors are board members that are not responsible for daily management or operations of the company. 
5a.Please input the name of the company








Description of the Agreement
The Tenancy Agreement is a legal contract between the tenant and landlord in respect of the Landlord�s property. The agreement sets out the terms and conditions of the tenancy, such as the rent, the length of the tenancy, repair responsibilities, when the landlord can enter the property and any conditions and restrictions on the use of the property.

TENANCY AGREEMENT
This Tenancy Agreement is made this __ day of ________ 20______

BETWEEN
 ______ (2a) ______of ___ (2b) _______ (herein after referred to as �Landlord� which expression shall include his administrators, executors, heirs, and/or legal representatives and assigns)
And 
___ (3a) ______of ____ (3b) ___________ (hereinafter referred to as �Tenant� which expression shall where the context so admits include his successors in title, assigns, heirs and personal and/or legal representatives)
Hereinafter jointly referred to as �Parties� or individually as �Party� shall be construed accordingly.

NOW THEREFORE THIS AGREEMENT WITHNESSES AS FOLLOWS:
In consideration of the payment of the rent hereinafter reserved and the due observation and performance of the covenants and conditions here in contained by the Tenant, the Landlord hereby grants/demise unto the tenant all that �(4)� situate at �(5)� (Hereinafter referred to as �Premises�) for a term of �..(6)�. (the Term) commencing from the ... (7)� to the � (8)� at � (9)� (�10� only) representing the rent for the Term hereby granted and agreement having been paid for by the Tenant before the execution of this Agreement (The receipt whereof the Landlord acknowledges). 
 
1.	THE TENANT COVENANTS WITH THE LANDLORD AS FOLLOWS:
1.1	To pay, all such taxes, tenement, water, electricity and any other applicable charges, rates, and assessments which during the Term shall be charged, assessed or imposed upon the Premises by the relevant governmental authorities. 
1.2	To keep the interior of the Premises in good and tenable repair and condition reasonable wear and tear excepted and to paint the interior of the Premises at the expiration of the term hereby granted.
1.3	To keep the fixtures and fittings of the property of the Landlord within or on the Premises in good and serviceable condition, reasonable wear and tear excepted and not to remove any fixture/fitting from the Premises and in the event of any fixture/fitting becoming damaged or lost to replace same with similar and not less in quality and value.
1.4	Not to make or permit to be made any structural alteration or addition to the interior and exterior of the Premises or any part thereof without the prior written consent of the Landlord.
1.5	Not to sublet or otherwise part with possession of the Premises or any part thereof, for the term created without the prior written consent of the Landlord.
1.6	Not to use or permit or suffer the Premises or any part of the Premises thereof to be used for any illegal, immoral or unauthorised purpose; or constitute nuisance or be injurious to the Landlord or neighbours of adjoining premises in the area.
1.7	To pay and make good to the Landlord all and every loss and damage whatsoever incurred or sustained by the Landlord as a consequence of every breach or non�-observance of the Tenant�s covenants herein contained and to indemnify the Landlord against all actions, claims, liability, costs and expenses thereby arising
1.5	At the expiration or sooner determination of the term hereby granted, to yield up peaceable possession and surrender to the Landlord the Premises including the said fittings and fixtures in such state of repair, condition, order and preservation as shall be in accordance with this agreement.
1.6 	Not to do or permit to be done anything which may become a nuisance, inconvenience or annoyance to the Landlord or the occupiers of any adjoining or neighbouring townhouses/apartment in general.
1.7 	To permit the Landlord and the Landlord�s agents, to enter upon the Premises for the purpose of inspection at all reasonable times during the Term hereby granted on the condition that the Landlord notifies the Tenant of the inspection.
1.8	To ensure and maintain the highest standards of hygiene and environmental sanitation by keeping the Premises clean including the drains free of dirt at all times.

2.	  LANDLORD�S COVENANTS: 
Subject to the payment of the Rent and provided that the Tenant has complied with all the covenants and obligations on the part of the Tenant to be performed and observed, the Landlord hereby covenants:
2.1.	That the Tenant shall peaceably hold and enjoy the let Premises throughout the Term without any interruptions by the Landlord or any person rightfully claiming under or in trust for the Landlord.
2.2.	To deliver the Premises to the Tenant in a reasonable state of repair. 

3.	PROVIDED ALWAYS and it is hereby agreed as follows: 
3.1.	If and whenever, during the said Term, there shall be any breach or non-performance of any of the covenants on the part of the Tenant herein contained, and if the Tenant shall fail to cure any such breach or non-performance, or, if the default is such that it cannot reasonably be cured within 21 (twenty one) days period, then the Tenant shall be deemed to have acted in a manner detrimental to the interest of the Landlord. In that case it shall be lawful for the Landlord at any time thereafter to re-enter upon the Premises or any part thereof and thereupon, this Tenancy Agreement shall be determined absolutely but without prejudice to any right of action or remedy of the Landlord for any antecedent breach of covenant by the Tenant.
3.2.	The Landlord shall not be responsible to the Tenant or to the Tenant�s licensees, servants, agents or other persons in the Premises or within the Premises for any accidental happening or injury or damages to or loss of any chattel or property sustained on the Premises or within the Premises unless the same shall be due to any breach of the Landlord�s covenants. 
3.3.	 If the Landlord shall be in breach of any of the covenants above stated and the Tenant has given the Landlord notice in writing to remedy the breach and the Landlord fails, refuses or neglects to remedy or commence the remedying of the breach within a reasonably time or fails to expeditiously or diligently carry on the remedying of the breach, the Tenant shall be at liberty to remedy the breach itself and any reasonable amount expended in the course of providing such remedy by the Tenant shall be a debt due from the Landlord to the Tenant. 

4.	TENANTS OPTION TO RENEW
The Tenancy is for a term of ______ (11) _____ and the tenant has the option to renew the Tenancy agreement for any further period as agreed by the parties and upon 30 days written notice to the Landlord of the intention to renew. 

IN WITNESS OF WHICH, the parties hereto have caused this agreement to be executed by their authorised representatives on the date indicated above. 

SIGNED by the within named LANDLORD


__________________________                                  
        � (12)�

In the presence of:
NAME: _____________________________
ADDRESS: __________________________
OCCUPATION:_______________________
SIGNATURE:_________________________

SIGNED by the within named Tenant

_____________________________
� (13)�.

In the presence of:

NAME: _____________________________
ADDRESS: __________________________
OCCUPATION: _______________________
SIGNATURE: _________________________

References:
(1)Commencement date
(2a) Landlord�s name?
(2b) Landlord�s address?
(3a) Tenant�s name? 
(3b) Tenant�s address?
(4) Provide a description of the property - Side Note e.g. 4 bedroom flat/duplex/bungalow 
(5) Address of the Property 
(6) What is the duration of the tenancy? Side Note e.g. 1 year, 2 years 6 months etc.  
(7) Provide the date the tenancy will commence (e.g. 1st day of September 2017) 
(8) Provide the date the tenancy will end (e.g. 31st day of August 2018) Side Note: Tenancy start date is typically the first day of a particular month while the tenancy end date is the last day of a month. For instance, a one year tenancy that commences on the 1st day of September 2017 will expire on the 31st day of August 2018 not the 1st day of September 2018) 
(9) How much will the tenant pay as rent? Side Note: The rent should be written in figures e.g. N1, 500, 000
(10) Provide details of the rent in words e.g. one million five hundred thousand Naira
(11) How many years/months is the tenancy arrangement for?
(12) Landlord�s name 
(13) Tenant�s name






DESCRIPTION OF THE DOCUMENT
A �Tenant�s notice to terminate tenancy� is issued by the tenant to the landlord, usually in tenancy of an intention to terminate the tenancy in accordance with the terms of the tenancy agreement. This is usually used when the tenant does not desire to renew the tenancy agreement. 


FREQUENTLY ASKED QUESTIONS (FAQs)
Must I issue a notice of intention to terminate tenancy?
Essentially, if the tenancy agreement provides for one, then you have to. Otherwise nothing stops you from ending the tenancy as provided for in the agreement. Where the agreement does not provide for a notice, it is assumed that the tenancy can be terminated by effluxion of time. 
Can I terminate a fixed term tenancy earlier than the term agreed?
Ideally a tenant should not be able to but where for instance the Landlord has breached a fundamental term of the agreement (e.g. failure to repair structural defects which make the premises inhabitable), then the tenant can issue a notice to terminate the tenancy earlier than the agreed term.  
.

Is there any special requirement of law with respect to this notice?
Generally, you are expected to serve the notice on the landlord or the landlord�s agent. It is also advisable that you have the landlord or agent acknowledge receipt of the notice. 


TENANT�S NOTICE TO TERMINATE TENANCY
_____ (1a) _____
_____ (1b) _____
_____ (1c) _____ (�Landlord�)
_____ (1d) ______


NOTICE IS HEREBY GIVEN THAT by virtue of a tenancy agreement dated ____ (2a) _____with respect to the premise situate at____ (2b) _____. I, ____(3a)_____(�Tenant�) hereby notify the landlord of my intention to vacate the premises located in the above mentioned address on or before ____________(4a) in accordance to the terms and conditions of the tenancy agreement.
This is in fulfillment of the _____(5a)____notice of intention to terminate tenancy required to be sent to the landlord in accordance with the  tenancy agreement.



SIGNED by the within named Tenant

________________________
NAME: 


References:
1a Please provide the tenant�s address in a letter format 
i.e. No17 Bode Street
Off Thomas Avenue
Lekki Phase 1
Lagos 
1b- Please provide the date the Notice will be prepared
1c What is the Landlord�s Name? 
1d. Please provide Landlord�s address in a letter format 
i.e. No17 Bode Street
Off Thomas Avenue
Lekki Phase 1
Lagos

2a. What is the commencement date of the tenancy agreement executed in respect of the premises?
2b. What is the address of the premise?
3a. What is the name of the tenant who wishes to vacate the premises?
4a. When does the tenant intend to vacate the premises? 
5a. Please indicate the period of notice required under the tenancy agreement? E.g. 2 month, 3 week 





DESCRIPTION 
A Trust Deed is a legal document through which the owner (settlor) of a property creates a trust in which the property (Trust Asset) is managed by another (Trustee) for the benefit of a beneficiary. It prescribes how management of the Trust Asset should be carried out. Trust Asset can include any type of property, such as cash, financial securities, landed property etc.
FREQUENTLY ASKED QUESTIONS (FAQ�s)
Who are the Parties in a Trust Deed? Does the beneficiary have to sign?
A trust deed involves two Parties:
a.	Settlor: This is the owner of the property.
b.	Trustee: This is the person who will manage the property
Although, the trust is created for the beneficiary and the beneficiary is identified in the trust deed, the beneficiary is not a party to the Deed, and does not have to sign the trust deed unless the trust arrangement is made to benefit of the Settlor. In that instance, the Settlor is also the beneficiary and can execute the Trust Deed. 
A classic example of this is where parents (settlor) create a trust fund (trust asset) for their child (beneficiary), and pending when the child becomes an adult, they appoint people (trustees) to manage the trust fund.   
What is the difference between a Trust Deed and a Power of Attorney?
A trust deed effectively divests the beneficiary of control over the goods; leaving the trustee in charge of the management and control of the trust asset. However, a Power of attorney appoints another (the attorney) who may act for you in situations where you are unable to act. 
When should I use a trust deed?
It is desirable to use a trust deed in respect of asset which require or will benefit management expertise and reduced levels of owner participation. Typical examples of which include: trading financial instruments such as shares, bonds, debentures, management of real or landed property etc. 




TRUST DEED DRAFT 
This TRUST DEED (hereinafter referred to as the �Agreement�) is made this __day of ___________ 20__ (hereinafter referred to as the �Effective Date�) by ____1____ of ____2____ (hereinafter referred to as the �Settlor�) and _____3_____ of ____4____ (hereinafter referred to as the �Trustee�). 
The Settlor and the Trustee are referred to individually as Party and collectively as Parties. 
WHEREAS:
A.	The Settlor has acquired the property detailed in Appendix A (Trust Asset).
B.	The Settlor wishes to settle on the Trustee the Trust Asset for the benefit of  ___5___ of ___6___ (Beneficiary)
C.	The Trustee is willing to accept management and control of the Trust Asset for the Beneficiary.
D.	The Trust Asset is be held in the name of the Trustee for the benefit of the Beneficiary on the terms of this Agreement. 


THE PARTIES AGREE AS FOLLOWS:
1.	DEFINITION AND INTERPRETATION:
In this Agreement the following terms shall be defined:
A.	�Agreement� shall mean this Trust Deed.
B.	�Third Party� shall mean in respect of a Party; any person(s), firm(s), organisation(s) other than the party itself.
C.	�Trust� shall mean the trust created by this Agreement
D.	�Trust Asset� shall mean the property described in Appendix A 
E.	�Representative(s)� means in relation to a Party, its officer(s), agent(s), and assign(s).

2.	TRANSFER OF PROPERTY:
A.	The Settlor hereby conveys, transfers and assigns the Trust Asset to the Trustee to be held in trust on such terms as are set out in this Agreement. The Trustee hereby acknowledges receipt of the Trust Asset, consents to the terms of the Agreement; and acknowledges that the Trust Asset is held in trust on the terms as set out in this Agreement. 
B.	The whole of the beneficial interests in the Trust Asset belongs to the Beneficiary and the Trustee shall hold the Trust Asset in trust for the Beneficiary absolutely.

3.	TRUSTEE COVENANTS
The Trustee:
A.	undertakes and agrees not to sell, part with possession or otherwise deal with the Trust Asset, any part thereof, any changes thereto or any related rights or interests save and except with the consent of the Settlor. 
B.	if and when required by the Settlor to do and as soon as it is possible for the Beneficiary to become a registered holder of the Trust Asset, the Trustee will sign and execute all deeds, documents, instruments or other writings and do all acts and things which may be necessary to enable the Beneficiary to be the registered holder of the Trust Asset
C.	to as often as possible forward and/or convey to the Settlor all information, statements, accounts and documents that the Trustee may receive as or by virtue of being the registered holder of the Trust Asset.
D.	shall disclose to the Settlor all agreement(s) and arrangement(s) made and/or  binding in respect of the Trust Asset.
E.	shall act in good faith and with due diligence for the benefit of the Beneficiary in respect of all matters relating to the Trust Asset; and
F.	shall take all steps within his power to protect the Beneficiary�s interests in the Trust Asset, all changes thereto and all related rights and interests
G.	shall not without written consent of the Settlor (consent not unreasonably withheld) delegate the administration and management of the Trust Asset to any third party. 

4.	BENEFICIARY COVENANTS
The Settlor shall:
A.	indemnify the Trustee for all payments made and costs and expenses properly and reasonably incurred in relation to the Trust Asset as a result of carrying out the instructions of the Settlor in accordance with this Agreement
B.	in the event that the Trustee becomes unable or unwilling to act as Trustee, reserve the power to change the Trustee provided that written notice is given to the Trustee of this change.
C.	Not unreasonably withhold consent where same is sought by the Trustee in furtherance of the terms of this Agreement.

5.	WARRANTIES: 
It is agreed as follows:
A.	The Settlor warrants to be absolutely entitled to all of the legal interest in the Trust Asset; until same is transferred to the Beneficiary.
B.	The Parties warrant that beneficial interest in the Trust Asset at all times during the pendency of this Agreement shall reside in the Beneficiary.
C.	The Parties warrant that they have the necessary power and approval to enter into this Agreement.
D.	The Parties warrant that they are not aware of anything in their reasonable control which will or could have an adverse effect upon their ability to perform their respective obligations hereunder.


6.	WAIVER
None of the right(s) or obligation(s) of a party in accordance with this Agreement shall be considered waived unless waived in writing by the Party concerned or its Representative(s). The waiver by any Party of one or more of its rights hereunder shall not be deemed or construed as a continuing waiver on the part of such Party as to prohibit such Party from demanding the performance of any of the other duties and obligations owed it in accordance with the provisions of this Agreement.

7.	REMUNERATION OF TRUSTEE(S)
The Trustee(s) shall be paid ___7____for work done, services rendered in the management and administration of the Trust.

8.	TERMINATION AND DETERMINATION
A.	Without prejudice to the terms contained herein, this Trust shall be terminated either:
I.	by the Settlor upon written notice to the Trustee; 
II.	by the Trustee upon written notice to the Settlor; or 
III.	by mutual consent of the Parties evidenced in writing
B.	Written notice shall in this Agreement be a notice given for not less than a period of ________8__________
  
9.	SEVERABILITY
If any clause or provision in this Agreement is held invalid or unenforceable by a court of competent jurisdiction in Nigeria, such invalidity shall not affect the validity or operation of any other clause or provision and such invalid term, clause or provision shall be deemed to be severed from the Agreement.

10.	GOVERNING LAW 
The implementation and enforcement of this Agreement shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Agreement.

11.	ALTERATION AND MODERATION OF AGREEMENT
This Agreement constitutes the entire agreement between the Parties hereto as regarding the Trust, and no terms or provisions of this Agreement shall be varied or modified by any prior or subsequent statement, conduct or act of any of the Parties save and except that the Parties may amend this Agreement by written instruments specifically referring to and executed in the same manner as this Agreement. Any such instrument shall be signed by the duly authorised Representatives of the Parties. The Agreement shall supersede any prior agreement between the Parties regarding the Trust. 

12.	DISPUTE RESOLUTION  
Parties shall use their best efforts to settle any disputes arising between them amicably within 20 days. 
A.	However, where the Parties are unable to reach an amicable resolution, such dispute, controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration conducted by a sole arbitrator in accordance with the Arbitration and Conciliation Act Cap A18 Laws of the Federal Republic of Nigeria 2004.


IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS DEED IN THE MANNER BELOW, ON THE DATE INDICATED ABOVE: 

SIGNED, SEALED AND DELIVERED 
By the within named Settlor

_____________________
NAME:

IN THE PRESENCE OF: 
NAME
ADDRESS
OCCUPATION
SIGNATURE


SIGNED, SEALED AND DELIVERED 
By the within named Trustee

___________________
NAME:

IN THE PRESENCE OF:
NAME:
ADDRESS:
OCCUPATION:
SIGNATURE


REFERENCES 
1. What is the name of the Settlor?
2. What is the address of the Settlor? 
3. Who will manage the property?
4. What is the address of the person that will manage the property?
5. What is the name of the beneficiary? 
6. What is the address of the Beneficiary? 
7. How much will the Trustee receive for managing the Trust Asset?
8 If the agreement is to be terminated, what should the length of notice be?






DESCRIPTION 
A Trust Deed is a legal document that creates a trust, giving a person or organization (Trustee) the right to manage trust asset for someone else (Beneficiary), and prescribes how management of the trust asset should be carried out. Trust asset may include any type of property, such as cash, securities etc. Although a trust deed is usually involves three different parties: a trustor (or grantor), a beneficiary, and a trustee, there are instances in which the trustor is also the beneficiary of the trust asset. The document prepared in this instance contemplates a situation in which the trustor is also the beneficiary of the trust asset. This document has an Appendix which requires that you provide details of the trust assets to be managed by the trustee. 


FREQUENTLY ASKED QUESTIONS (FAQ�s)
What is the difference between a Trust Deed and a Power of Attorney?
A trust deed effectively divests the beneficiary of control over the goods; leaving the trustee in charge of the management and control of the trust asset. However, a Power of attorney appoints another (the attorney) who may act for you in situations where you are unable to act. 

When should I use a trust deed?
It is desirable to use a trust deed in respect of asset which require or will benefit from high levels of expertise and reduced levels of active management participation. Typical examples of which include: financial instruments such as shares, bonds, debentures and realty such as houses etc. 

TRUST DEED DRAFT 
THIS TRUST DEED (hereinafter referred to as the �Agreement�) is made this __day of ___________ 20__ (hereinafter referred to as the �Effective Date�) by ___________1a____________ of ___________1b___________ (hereinafter referred to as the �Beneficiary�) and ___________2a___________ of ___________2b____________ (hereinafter referred to as the �Trustee�). 
The Trustee and the Beneficiary are referred to individually as Party and collectively as Parties 
WHEREAS:
A.	The Beneficiary owns the Trust Asset detailed in Appendix A.
B.	The Beneficiary wishes to settle on the Trustee the Trust Asset for the benefit of the Beneficiary
C.	The Trustee is willing to accept management and control the Trust Asset for the Beneficiary.
D.	The Trust Asset is be held in the name of the Trustee for the benefit of the Beneficiary on the terms of this Agreement. 

THE PARTIES AGREE AS FOLLOWS:
1.	DEFINITION AND INTERPRETATION:
In this Trust Deed the following terms shall be defined:
1.1	�Trust� shall mean the trust created by this Trust Deed
1.2	�Trust Asset� shall mean that property detailed in Appendix A
1.3	 �Representative� means in relation to a Party, its officers, agents, and assigns.

2.	TRANSFER OF PROPERTY
The Beneficiary hereby conveys, transfers and assigns the Trust Asset to the Trustee to be held in trust on such terms as are set out in this Trust Deed. The Trustee hereby acknowledges receipt of the Property and consents to the terms of the Trust Deed; admits and acknowledges the Property is held in trust on the terms as set out in this Trust Deed. 

The whole of the beneficial interests in the Trust Asset belongs to the Beneficiary and the Trustee shall hold the Trust Asset in trust for the Beneficiary absolutely.

3.	TRUSTEE COVENANTS
The Trustee:
2.1	undertakes and agrees not to sell, part with possession or otherwise deal with the Trust Asset, any part thereof, any changes thereto or any related rights or interests save and except with the consent of the Beneficiary;
2.2	if and when required by the Beneficiary, to do and as soon as it is possible for the Beneficiary to become a registered holder of the Trust Asset, the Trustee will sign and execute all deeds, documents, instruments or other writings and do all acts and things which may be necessary to enable the Beneficiary to be the registered holder of the Trust Asset;
2.3	shall use best efforts to ensure that as often as possible forward and/or convey to the Beneficiary all information, statements, accounts and documents that the Trustee may receive as or by virtue of being the registered holder of the Trust Asset;
2.4	shall disclose to the Beneficiary all agreements  and arrangements made and/or  binding in respect of the Trust Asset;
2.5	shall act in good faith and with due diligence for the benefit of the Beneficiary in respect of all matters relating to the Trust Asset; and
2.6	shall take all steps within his power to protect the Beneficiary�s interests in the Trust Asset, all changes thereto and all related rights and interests
2.7	shall not without written consent (consent not to be unreasonably withheld) delegate the administration and management of the Trust Asset to any third party  

3	BENEFICIARY COVENANTS
The Beneficiary shall:
3.1	indemnify the Trustee for all payments made and costs and expenses properly and reasonably incurred in relation to the Trust Asset as a result of carrying out the instructions of the Beneficiary in accordance with this deed
3.2	 in the event that the Trustee becomes unable or unwilling to act as Trustee of the Trust, reserve the power to change the trustee of the Trust Asset provided that written notice is given to the Trustee of this desired change

4	WARRANTIES: 
It is agreed as follows:
4.1	The Beneficiary warrants  to be absolutely entitled to all of the legal and beneficial interest in the Trust Asset at all times during the pendency of this agreement.
4.2	The Trustee warrants to have the requisite capacity and expertise to manage the Trust Asset.
4.3	Both Parties warrant that they have the necessary power and approval to enter into this Trust Deed
4.4	Both Parties warrant that they are not aware of anything in their reasonable control which will or could have an adverse effect upon their ability to perform their respective obligations hereunder.

5	WAIVER
None of the right to or obligation of a party in accordance with this Agreement shall be considered waived unless waived in writing by the Party concerned or its Representatives. The waiver by any Party of one or more of its rights hereunder shall not be deemed or construed as a continuing waiver on the part of such Party as to prohibit such Party from demanding the performance of any of the other duties and obligations owed it in accordance with the provisions of this Agreement.

6	REMUNERATION OF TRUSTEE(S)
The Trustee(s) shall be paid --------3-------for work done and services rendered in the management and administration of the Trust Asset.

7.	TERMINATION AND DETERMINATION
7.1	Without prejudice to the terms contained herein, this Trust shall be terminated:
7.1.1	by the Beneficiary upon written notice to the Trustee; 
7.1.2	by the Trustee upon written notice to the Beneficiary; 
7.1.3	by mutual consent of the Parties evidenced in writing
7.2	Written notice shall in this Agreement be a notice given for not less than a period of ________4__________
  
8	SEVERABILITY
If any clause or provision in this Agreement is held invalid or unenforceable by a court of competent jurisdiction in Nigeria, such invalidity shall not affect the validity or operation of any other clause or provision and such invalid term, clause or provision shall be deemed to be severed from the Agreement.

9	GOVERNING LAW 
The implementation and enforcement of this Trust shall be governed by the Laws of the Federal Republic of Nigeria, which shall, for all intent and purposes be the governing law of this Trust.

10	ALTERATION AND MODERATION OF AGREEMENT
This Agreement constitutes the entire agreement between the Parties hereto as regarding the Trust, and no terms or provisions of this Trust shall be varied or modified by any prior or subsequent statement, conduct or act of any of the Parties save and except that the Parties may amend this Agreement by written instruments specifically referring to and executed in the same manner as this Agreement. Any such instrument shall be signed by the duly authorised Representatives of the Parties. The Agreement shall supersede any prior agreement between the Parties regarding the Trust. 

11	DISPUTE RESOLUTION  
Parties shall use their best efforts to settle any disputes arising between them amicably within 20 days. 
11.1	However, where the Parties are unable to reach an amicable resolution, such dispute, controversy or claim arising out of, relating to or in connection with the breach, termination or validity of this Agreement shall be resolved by arbitration conducted by a sole arbitrator in accordance with the Arbitration and Conciliation Act Cap A18 Laws of the Federal Republic of Nigeria 2004.


IN WITNESS OF WHICH THE PARTIES HAVE EXECUTED THIS DEED IN THE MANNER BELOW, ON THE DATE INDICATED ABOVE 
SIGNED, SEALED AND DELIVERED 
By the within named Beneficiary

_____________________
NAME:

IN THE PRESENCE OF: 
NAME
ADDRESS
OCCUPATION
SIGNATURE

SIGNED, SEALED AND DELIVERED 
By the within named Trustee

___________________
NAME:

IN THE PRESENCE OF:
NAME:
ADDRESS:
OCCUPATION:
SIGNATURE


APPENDIX A

The Trust Asset (subject of the Trust) shall be the following:


REFERENCES 
1a. Who will manage the property?
1b What is the address of the person that will manage the property?
2a Who is the asset being managed for (beneficiary)? 
2b What is the address of the beneficiary?
3  How much will the Trustee receive for managing the Trust Asset?
4 if the agreement is to be terminated, what should the length of notice be?








THIS 	TRUST DEED IS MADE on the                   day of                                  
            BETWEEN
(1)       BASIC START LIMITED  of 17a Wumego Crescent, Off Christ Avenue, Off Admiralty Way, Lekki Phase 1  (the �Trustee�); 
           AND
(2)        [ ] of [ ] ) of [ ] (�Beneficiary�).
            WHEREAS :-
A.        The Trustee has subscribed for or otherwise acquired 9999 shares of N1.00 each (�Shares�) in the capital of  Norse Energy Limited RC 1177503, a company incorporated in Nigeria and having its registered office at 17a Wumego Crescent, Off Christ Avenue, Off Admiralty Way, Off Christ Avenue, Lekki Phase 1 (the �Company�).
B.         The total consideration for the said subscription for and/or acquisition of the Shares is N9, 999.00 Nine Thousand Nine Hundred and Ninety-nine Naira Only.
C.        The total consideration paid for the Shares was provided by the Beneficiary and it has been agreed that the Shares shall be held in name of the Trustee for the benefit of the Beneficiary absolutely on the terms of this Deed.
NOW THIS DEED made in pursuance of the said agreement and for the consideration herein witnesses as follows:-
1.         The parties declare that the whole of the beneficial interests in the Shares belongs to the Beneficiary and the Trustee shall hold the Shares in trust for the Beneficiary absolutely.
2.         without prejudice to the generality of the above:-
2.1       the Trustee undertakes and agrees not to sell, part with possession or otherwise deal with the Shares, any part thereof, any accretions thereto or any related rights or interests save and except with the consent of the Beneficiary; 
2.2       if and when required by the Beneficiary to do and as soon as it is possible for the Beneficiary to become a registered holder of the Shares, the Trustee will sign and execute all deeds/documents or other writings and do all acts and things which may be necessary to enable the Beneficiary to be the registered holder of the Shares; 
2.3       the Trustee shall as soon as possible forward and/or convey to the Beneficiary all information, statements, accounts and documents that the Trustee may receive as or by virtue of him being the registered holder of the Shares;
2.4       the Trustee shall disclose to the Beneficiary all agreements and/or arrangements binding on all shareholders of the Company;
2.5       the Trustee shall act in good faith and with due diligence for the benefit of the Beneficiary in respect of all matters relating to the Shares and the Company; and
2.6       the Trustee shall take all steps within his power to protect the Beneficiary�s interests in the Shares, all accretions thereto and all related rights and interests. 
3.         The Beneficiary shall indemnify the Trustee for all payments properly made by the Trustee in accordance with this Deed in relation to the Shares or the Company and for all payments made and costs and expenses properly and reasonably incurred as a result of carrying out the instructions of the Beneficiary.
 4.         Where holders of Shares are obliged to meet cash-call by the Company, the Beneficiary shall put the Trustee in funds to meet the cash-call.

5. The TRUSTEE do hereby agree that he shall hold and stand possessed of the said trust assets, properties and funds (which expression shall include all investments in cash or kind or in any nature whatsoever into and for which, the said property or a part or parts thereof may from time to time be converted, varied or exchanged) and/ or such investments as may be held by the TRUSTEES from time to time in relation to these presents together with all income, profits, additions and accretions thereof, upon trust for the object set out herein with and subject to the provisions and conditions hereinafter contained in these presents.
 6.         In the event that the Company becomes a public listed company, the Trustee shall procure for the Beneficiary the right to have the Shares exchanged for shares in the capital of the public listed company.
7. The information which may be made available as a result of this Trust Deed (�Confidential Information�) by either Party (the �Disclosing Party�) to the other Party (the �Receiving Party�) is provided by the Disclosing Party on the basis that the Receiving Party agrees to keep it in strict confidence in accordance with the terms of this Trust Deed. 

7.1	Confidential Information, subject to clause 7.2 below, includes this Trust Deed, all negotiations leading up the conclusion of this Trust Deed, and all negotiations and agreements relating to the affairs of the Parties in relation to the Company and all other information of a confidential nature which is disclosed by one Party to any other Party in relation to this Trust Deed and the subject matter covered herein. Each Party undertakes to keep the Confidential Information confidential and not to disclose it to any person (other than its lawyers, accountants and other professional advisors to the extent necessary), except with the prior written consent of the Disclosing Party and subject to the circumstances contemplated under clause 7.3 below.

7.2	Confidential Information, for the purposes of this clause 7 shall not include information which:

7.2.1	is presently in the public domain or enters the public domain by any means other than breach of this Trust Deed; or

7.2.2	can be adequately evidenced by the Receiving Party to have been known by it prior to the date that information is disclosed to them by the Disclosing Party; or

7.2.3	is lawfully obtained by the Receiving Party, after the date that information is disclosed to it by the Disclosing Party, other than from a source which is connected with the Disclosing Party and which, in either case, as far as the Receiving Party is aware, has not been obtained in violation of and is not otherwise subject to any obligation of confidentiality or fiduciary duty.

7.3	Despite the provisions of clause 7.1 above, a Receiving Party may disclose Confidential Information where the Receiving Party is required by any law, court of competent jurisdiction or any judicial, governmental, supervisory or regulatory body, provided that such Receiving Party notifies the Disclosing Party, as soon as possible upon becoming aware of any such requirement and gives the Disclosing Party reasonable assistance in connection with the Disclosing Party taking any steps or proceedings to resist or narrow such requirements.

7.4	The Parties agree that the provisions contained in this clause with regard to confidentiality of the Confidential Information, shall survive the termination this Trust and the obligations of confidentiality imposed by this clause 7 shall continue indefinitely.

7.5	On termination of this Trust, the Parties must, to the extent reasonably possible, immediately return all Confidential Information of the other Party in its possession or under its control, and within 10 days of termination of the Trust each Party must certify in writing that it has complied with the obligations set out in this paragraph (if required by the other Party). 
GOVERNING LAW AND JURISDICTION
8.1 This Agreement shall be governed by and construed in accordance with the
     laws of Nigeria.

8.2 Any dispute, controversy or claim arising out of or in connection with this Agreement, including any question regarding its existence, validity or termination, or the breach, termination or invalidity thereof, shall be settled by arbitration in accordance with the  Arbitration and Conciliation Act as at present in force and as may be amended from time to time. The appointing authority shall be the Lagos Multi-Door Court House, failing which by application of any of the parties to the Chief or Presiding Justice of the Lagos Division of the Federal High Court, appointing powers shall then vest in the said Chief/Presiding Justice.
8.3 The place of arbitration shall be in Lagos Nigeria and there shall be only one arbitrator. If the parties fail to agree on the designation of an arbitrator within twenty (20) day period, the above Multi-Door Court House or failing, the Presiding Justice of the Lagos Division of the Federal High Court shall be requested to designate the single arbitrator.
8.4 Any arbitration shall be administered by the Lagos Multi-Door Court House, in accordance with its rules, failing which any of the parties may seek the directives of the Presiding Justice of the Federal High Court.

IN WITNESS WHEREOF, the parties have hereunto set their hands and seals on the date above.
 
Signed Sealed & Delivered by the within named Trustee:
Sign---------------------
Name-----------------------------------------------
Address---------------------------------------------------

In the presence of:-

Sign---------------------
Name-----------------------------------------------
Address---------------------------------------------------

Signed Sealed & Delivered by the within named Beneficiary:
Sign---------------------
Name-----------------------------------------------
Address---------------------------------------------------

In the presence of :-

Sign---------------------
Name-----------------------------------------------
Address---------------------------------------------------









DESCRIPTION OF THE DOCUMENT
An Employee Handbook, sometimes also known as an employee manual, staff handbook, or company policy manual, is a book given to employees by an Employer. Usually, the Employee Handbook contains several key provisions which includes information about company culture, policies, and procedures.


FREQUENTLY ASKED QUESTIONS (FAQs)
When do I need an Employee Handbook?
If you are an employer, there is the need to establish the rules of conduct and policies in your organization. And secondly as an employee, to know the rules, rights and responsibilities expected of you in an organization.
Is an Employee Handbook a contract?
The answer depends on whether your handbook has a disclaimer telling employees that they are at-will and cannot rely on the handbook as a contract. 
Hence, an employer who wishes to cushion the effect of any liability arising therefrom may state expressly in the handbook that the Employee Handbook is not a contract, that the employer doesn't intend to be bound by the handbook and the handbook is subject to change at the employer's discretion. Note that this handbook provides such language 
Is an Employee handbook same as a Policy Manual?
Employee Handbooks are provided to new employees to help them better understand the company and its policies. It helps the employees understand the expectations the company has from them and the benefits and other perks the company offers them. A Policy Manual on the other hand is aimed at regulating the relationship between the employees and employer, it contains human resource provisions as well procedural provisions for certain benefits.






TABLE OF CONTENTS

Chapter 1: �����������____1a
Welcome & Introduction
1.1	Vision
1.2	Mission
1.3	Values
	
Chapter 2: Employment Policies 
2.1	Terms of Employment
2.2	Miscellaneous 
2.3	References 
2.4       On boarding
2.5	Identification cards
2.6	Probationary period
2.7	Working conditions
2.8	Confidentiality of Company�s information
2.9	Personal Appearance & Dress code
2.10	Workplace Etiquette & Professional conduct/Personal Behaviour
2.11	Access to Personnel Files
2.12	Personnel Data changes
2.13	Business Ethics & Conduct
2.14	Personal Relationships in the Workplace

Chapter 3: Organisational Structure & Personnel Matters
3.1      Job Descriptions
3.2      Salary Administration

Chapter 4: People Development
4.1	Career Planning & Development
4.2	Training & Development
	
Chapter 5: Reward & Recognition 
5.1	Competitiveness
5.2	Salary Review
5.3	Payday

Chapter 6: Allowances and Fringe Benefits
6.1     Out of Station Allowance
6.2     Vacation Benefits

Chapter 7: Employee Misconduct and Grievance 
7.1	Employee Conduct and Work Rules
7.2	Sexual & Other Unlawful Harassment
7.3	Types of Disciplinary Action
7.4	Problem Resolution & Grievance Practices

Chapter 8: Use of Company Property and General Provisions
 8.1   Use of Company Property
8.2   Emergency Closing
8.3   Business Travel Expenses
8.4    Visitors in the Workplace
8.5    Workplace Monitoring
8.6    Absenteeism
8.7    Attendance & Punctuality
8.8    Security Inspections

Chapter 9: Separation
9.1    Termination
9.2    Voluntary Resignation
9.3    Redundancy
9.4    Accidents while on Official Duty
9.5    Death

















 

Chapter 1: ____1a

WELCOME 

On behalf of the entire staff, we welcome you to our company and wish you success here. 
 
We believe that each employee contributes directly to the growth and success of the company, and we hope you will take pride in being a member of our team. This handbook was developed to describe some of the expectations of our employees and to outline the policies, programs, and benefits available to eligible employees. Employees should become familiar with the contents of the employee handbook as soon as possible, as it will answer many questions about your employment. We believe that professional relationships are easier when all employees are aware of the culture and values of the organization. This guide will help you to better understand our vision for the future of our business and the challenges that are ahead. Please familiarize yourself with the terms of this handbook and in case of any problems with meaning or interpretation, kindly contact the management for assistance. We hope that your experience here will be challenging, enjoyable, and ultimately rewarding.

Again, welcome! 




____________________
Signature

____________________
Name & Designation



INTRODUCTION
This Employee Handbook (�Handbook�) is a compilation of personnel policies, practices and procedures currently in effect in our Company.
The Handbook is designed to introduce you to our Company, familiarize you with Company policies, provide general guidelines on work rules, benefits and other issues related to your employment, and help answer many of the questions that may arise in connection with your employment. This Handbook does not create a contract, express or implied, guaranteeing you any specific term of employment, nor does it obligate you to continue your employment for a specific period of time. The purpose of the Handbook is simply to provide information on the present policies and practices at the Company. This Handbook is essentially an overview or a guideline and as such cannot cover every matter that might arise in the workplace. For this reason, specific questions regarding the applicability of a particular policy or practice should be addressed to the management.

The Company reserves the right to modify any of our policies and procedures, including those covered in this Handbook, at any time. We will seek to notify you of such changes by email and other appropriate means. However, such a notice is not required for changes to be effective.

1.1	Vision: 
________1b

1.2	Mission: 
________1c
1.3	Values: 
_________1d










Chapter 2: EMPLOYMENT POLICIES

Objective: To be an Employer of choice, offering equal opportunities to all employees. The Employer is committed to filling available job positions with the best qualified candidates in terms of qualifications, ability and preparedness to work in line with our values and creative vision.
Employment with the company is voluntarily entered into but where the employee or the Employer wishes to terminate the relationship at any time either party would have to give one month�s notice in lieu or pay one month�s salary. The provisions of the handbook have been developed at the discretion of management and, may be waived, amended or cancelled at any time, at the Employer�s sole discretion. These provisions supersede all existing policies and practices and may not be amended or added to without the express written approval of the Management.

Employment Applications

The Employer relies upon the accuracy of information contained in the employment application, as well as the accuracy of other data presented throughout the hiring process and employment. Any misrepresentations, falsifications, or material omissions in any of this information or data may result in the exclusion of the individual from further consideration for employment or, if the person has been hired, termination of employment.

Equal Employment Opportunity

In order to provide equal employment and advancement opportunities to all individuals, employment decisions will be based on merit, qualifications, and abilities. The Company does not discriminate in employment opportunities or practices based on color, religion, sex, national origin, age, or any other characteristic protected by law. This policy governs all aspects of employment, including selection, job assignment, compensation, discipline, termination, and access to benefits and training. Any employees with questions or concerns about any type of discrimination in the workplace are encouraged to bring these issues to the attention of their immediate supervisor or the management. Employees can raise concerns and make reports without fear of reprisal. Anyone found to be engaging in any type of unlawful discrimination will be subject to disciplinary action, up to and including termination of employment.

2.1	Terms of employment
Employment Letter � All new employees will be given a letter of employment setting out the following;

a.	Name and address of employee
b.	Job title and description
c.	Salary, Allowances and benefits applicable
d.	Type of employment, whether temporary or permanent
e.	Other particulars as may be deemed necessary
	
2.2	Miscellaneous
a. Employment could be permanent or temporary e.g. contract staff, internships etc.

2.3	References
On joining the Company, every employee is required to provide at least two (2) satisfactory references, one of which must be the employee�s previous employers. The reference from the immediate past will include a character reference as well as confirmation of previous status and salary.The satisfactory provision of these references is a prerequisite to confirmation.

2.4	On boarding

All new employees will undergo an on boarding program approved by the Employer. The focus will be on the history, Mission & Vision, Core Values, Structure, Policies and Procedures, Services, Job Details, Individual Expectations, Resources and Facilities Available.

2.5	Identification Cards

Every employee will be issued an identity card on joining the Company. On leaving the services of the Company, the identity card must be deposited with the management on the last day of employment.
�	All employees are required to wear their identification cards at all times on the Company premises.

2.6	Probationary period

Every employee will be required to undergo a 6-month probationary period before confirmation of employment. During the probationary period, the employee�s job performance is appraised to determine whether it meets agreed standards and goals. References are also received during this period.

Employees whose performance fall below mutually agreed goals and standards will be allowed an additional 3 months� probation. At the end of a cumulative 9-months� probation period, employees whose performance still remains below agreed goals and standards will be disengaged from the Company�s services.

An employee�s contract of employment may be terminated during probationary period by either party with notice or payment in lieu of notice. Confirmation of appointment will be given in writing after a probationary period, but conditional upon satisfactory references.

2.7	Working Conditions

Working hours � The Company observes a five-day working week (Monday through Friday) except where any of those days is a government public holiday. Regular working hours will be ______, with an hour break for lunch. The Company however, reserves the right to stagger work hours to suit operational requirements, and weekend hours may be incorporated accordingly, and during busy seasons.

Overtime � There may be circumstances that necessitate extra work in satisfying requirements of the job. The Company will not make overtime payments. The supervisor may however exercise their discretion to authorise reimbursement for transportation and meal expenses for junior employees closing from work after late hours on a normal work day. 

Transfers � In line with business needs and requirements, the Company reserves the right to transfer staff to and from any of its branches in or outside Nigeria. The cost of travel and other logistics will be borne by the Company.

2.8	Confidentiality Obligation

All employees have a responsibility to protect confidential information on the Company, its Partners and other Third Parties entrusted to them or to which they are otherwise exposed.  Therefore, all business information of the Company should be treated as confidential.  Employees must neither use (other than for the business of the Company) nor disclose any confidential information of the Company�s business or about existing or previous customers of the Company during or after employment. 

Disclosure of business information about the Company and/or the Company�s Partners is allowed only if it is:
a.	required to properly discharge the Company�s responsibility under relevant law, or
b.	required as a professional responsibility and is not contrary to applicable laws or regulations in Nigeria, or
c.	properly authorised in writing by the Company and effected in accordance with management guidelines, or 
d.	in response to an enforceable subpoena or summons and effected in accordance with management guidelines.

Any intentional disclosure of confidential information will be regarded as gross misconduct that may attract disciplinary action, up to and including termination of employment.

2.9	Personal Appearance & Dress Code
During business hours, employees of the Company are expected to be neat, tidy and appropriately dressed, maintaining a business professional appearance. Dress, grooming, and personal cleanliness standards contribute to the morale of all employees and affect the professional image the Employer presents to clients and visitors. During business hours or when representing the Employer, you are expected to present a clean, neat, and tasteful appearance. You should dress and groom yourself according to the requirements of your position and accepted social standards. This is particularly true if your job involves dealing with the clients or visitors in person.

2.10	Workplace Etiquette & Professional Conduct/Personal Behaviour 
Employees of the Company represent the Company at all times and accordingly are expected to maintain high standards of professional conduct/personal behaviour. The Company strives to maintain a positive work environment where employees treat each other with respect and courtesy. Sometimes issues arise when employees are unaware that their behavior in the workplace may be disruptive or annoying to others. Many of these day-to-day issues can be addressed by politely talking with a co-worker to bring the perceived problem to their attention. In most cases, common sense will dictate an appropriate resolution. The Company encourages all employees to keep an open mind and graciously accept constructive feedback.

Employees must maintain a professional conduct, standard of good taste, good judgement and a courteous disposition to colleagues and visitors to the company�s premises. In all situations, employees of the Company should conduct themselves in a manner that conforms to the best professional image prevailing in the business community.

a.)	Use of Alcohol, Drugs or Other Intoxicating Substances 

The use of alcohol and/or drugs and/or other behaviour altering substances on the Company�s premises while on official duty or during the official working hours is forbidden. 
Employees will not present themselves for work under the influence of alcohol and/or drugs and/or other behaviour altering substances.  While it is expected that during social functions, employees may consume some alcohol, such should be limited to amounts that do not result in alteration of behaviour, impairment of the individual�s sense of judgement and loss of reasonable conduct.
An employee taking prescribed drugs that might affect his/her performance should discuss the matter with their supervisor. Violation of this policy, if proven, can lead to disciplinary action, up to and including termination of employment.

b.)	Smoking

In keeping with the Company�s intent to provide a safe and healthful work environment, smoking is prohibited throughout the workplace. This policy applies equally to all employees, clients, and visitors.

c.)	Illegal Activities 
The Company is committed to the highest level of ethical and legal conduct and expects the same from all employees. Engagement in illegal activities of any sort may be the basis for dismissal or other appropriate sanction(s).

d.)	 Harassment at Work
Harassment is defined as "engaging in a course of vexatious comment or conduct that is known or ought reasonably to be known to be unwelcome".  The Company is committed to keeping the work environment free of harassment and intimidation, supportive of productivity and the personal dignity and self-esteem of all employees.  Be it sexual, ethnic or of any other nature, harassment in the workplace by any person is prohibited. Such behaviour will attract appropriate sanction, if proven.
All employees will treat one another with mutual respect without prejudice to person or position. 

e.)	Other Employment/Income from Other Sources 
Employees are expected to avoid situations where personal interests may conflict with the interest of the Company. Employees may therefore, not enter into another occupation or employment, paid or unpaid, or conduct any trade or business without prior written approval of the employer. 
Employees may not accept employment or compensation or engage in any business that might require disclosure of confidential information of the Company, or that could reasonably be expected to impair the individual�s independence of judgement in the performance of the Company�s duties. No employee is allowed to accept outside employment, income, gifts or any other benefit from other sources that compromise an employee�s ability to perform his/her job duties effectively, or to accept overtime and/or accept travel assignments.
Violation of this policy will attract appropriate discipline, up to and including termination of employment with the Company.


2.11	Access to Personnel Files
The Company maintains a personnel file on each employee. The personnel file includes such information as the employee's job application, resume, records of training, documentation of performance appraisals and salary increases, and other employment records. Personnel files are the property of the employer and access to the information they contain is restricted. Generally, only supervisors and management personnel who have a legitimate reason to review information in a file are allowed to do so. Employees who wish to review their own file should contact the management. With reasonable advance notice, employees may review their own personnel files and in the presence of the company officer in charge.

2.12	Personnel Data Changes

It is the responsibility of each employee to promptly notify the employer of any changes in personnel data. Personal mailing addresses, telephone numbers, number and names of dependents, individuals to be contacted in the event of emergency, educational accomplishments, and other such status reports should be accurate and current at all times. 
2.13	Business Ethics and Conduct

The successful business operation and reputation of the company is built upon the principles of quality output and professional conduct by our employees. Our reputation for integrity and excellence requires careful observance of the spirit and letter of all applicable laws and regulations, as well as a scrupulous regard for the highest standards of conduct and personal integrity. The continued success of the company is dependent upon our client's trust and we are dedicated to preserving that trust. Employees owe a duty to the employer, its clients, and stakeholders to act in a way that will merit the continued trust and confidence of the public. The company will comply with all applicable laws and regulations and expects its employees to conduct business in accordance with the letter, spirit, and intent of all relevant laws and to refrain from any illegal, dishonest, or unethical conduct.
In general, the use of good judgment, based on high ethical principles, will guide you with respect to lines of acceptable conduct. If a situation arises where it is difficult to determine the proper course of action, the matter should be discussed openly with your immediate supervisor and, if necessary, with the management, for advice and consultation. Compliance with this policy of business ethics and conduct is the responsibility of every employee. Disregarding or failing to comply with this standard of business ethics and conduct could lead to disciplinary action, up to and including possible termination of employment.

2.14	Personal Relationships in the workplace

The employment of relatives or individuals involved in a dating relationship in the same area of an organization may cause serious conflicts and problems with favoritism and employee morale. In addition to claims of partiality in treatment at work, personal conflicts from outside the work environment can be carried over into day-to-day working relationships. For purposes of this policy, a relative is any person who is related by blood or marriage, or whose relationship with the employee is similar to that of persons who are related by blood or marriage. A dating relationship is defined as a relationship that may be reasonably expected to lead to the formation of a consensual "romantic" or sexual relationship. This policy applies to all employees without regard to the gender or sexual orientation of the individuals involved. Although the company has no prohibition against employing relatives of current employees or individuals involved in a dating relationship with current employees, we are committed to monitoring situations in which such relationships exist in the same area. In case of actual or potential problems, the company will take prompt action, and this can include reassignment. Employees in a close personal relationship should refrain from public workplace displays of affection or excessive personal conversation.








Chapter 3: ORGANISATIONAL STRUCTURE AND PERSONNEL MATTERS              
3.1	Job Descriptions

The Employer makes every effort to create and maintain accurate job descriptions for all positions within the organization. Each description includes a job information section, a job summary section (giving a general overview of the job's purpose), a responsibilities section, and a qualifications section (including education and/or experience, communication skills, and any certification required). The company maintains job descriptions to aid in orienting new employees to their jobs, identifying the requirements of each position, establishing hiring criteria, setting standards for employee performance evaluations. Existing job descriptions are also reviewed and revised in order to ensure that they are up to date. Job descriptions may also be rewritten periodically to reflect any changes in the position's duties and responsibilities. All employees will be expected to help ensure that their job descriptions are accurate and current, reflecting the work being done. Employees should remember that job descriptions do not necessarily cover every task or duty that might be assigned, and that additional responsibilities may be assigned as necessary. Contact the management if you have any questions or concerns about your job description.

3.2	Salary Administration

We believe recruiting and retaining talented employees is critical to our success. The company is committed to paying its employees equitable wages that reflect the requirements and responsibilities of their positions and are comparable to the pay received by similarly situated employees in other similarly situated organizations in the industry. Compensation for every position is determined by several factors, including job analysis and evaluation, the essential duties and responsibilities of the job, and salary survey data on pay practices of other organisations. The company periodically reviews its salary administration program and restructures it as necessary. Merit-based pay adjustments may be awarded in conjunction with superior employee performance documented by the performance evaluation process. Incentive bonuses may be awarded depending on the overall profitability of the company and based on each employee's individual contributions to the organization. 


The organizational structure is attached at the end of this manual for your attention as Appendix A





Chapter 4: People Development

Objective: To clearly define and establish Management�s commitment to equipping staff with requisite skills, knowledge and attitude to perform optimally on the job.
The Company is committed to providing and facilitating training and development opportunities for employees at all levels. The thrust of this commitment is to equip every employee with the requisite skills for consistent peak performance. We are also committed to ensuring that corporate development initiatives accommodate individual career objectives.

4.1	Career planning & development

The development of each employee�s individual career will be a shared responsibility between the Company and its employees. Each employee is therefore responsible for defining personal goals, skills and talents requirements, and putting forth the required effort necessary to fully develop current skills and learn new ones. Please note that it is important that organisational and personal goals meet for growth to take place.
The Company on its part is responsible for providing the enabling environment, resources and feedback on staff performance. The Company will define the competency requirements for each function, and ensure the deployment of skill sets available in the organization. 

4.2	Training and Development
The Company is a learning organisation committed to providing the necessary environment and support towards progressive self-development. Building employee capabilities and competencies is a major strategy adopted by the company in the achievement of its goals. The Organization is therefore committed to providing technical, professional training and managerial skills development opportunities for all employees. The training needs of individual employee will be identified at the beginning of the year based on the outcome of the annual performance appraisal exercise. 







Chapter 5: REWARD AND RECOGNITION
Objective: The company is determined to be an employer of choice. Therefore, upon the commencement of production by the company, a key strategic objective will be to implement and sustain a competitive pay structure that makes the Company one of the leaders in staff compensation within the industry.

5.1	Competitiveness	
We will operate a competitive remuneration system to attract and retain high performers. The Human Resource department will on a proactive and continual basis, collect and analyse compensation data from benchmark Companies within our industry to ensure that we remain competitive. 
 
5.2	Salary Review	
Subject to overall corporate performance, salaries will be reviewed. The timing and frequency of such reviews will be determined by the Management.

5.3	Payday 
Employee salaries are payable monthly in arrears on a calendar month basis.  Employee salaries will be paid directly into their Staff salary accounts every month. 













Chapter 6: ALLOWANCES AND FRINGE BENEFITS

Objective: As part of the compensation package made available to our employees, we also offer a number of allowances. Qualification for, and the amount of each individual�s entitlement vary in relation to employee�s category /level.

6.1	Out of Station Allowance
When employees are required to work at locations away from their primary base of operations, the Company will provide accommodation, meals and incidentals or pay cash in lieu. 

6.2	Vacation benefits
The company will grant holiday time off to all employees on the holidays listed below:

a.)	Annual Leave
To qualify for their full leave period entitlements, employees will be required to have been in the Company�s employment for at least one year. Where an employee has not worked for the full year, they will be entitled to only a pro-rated leave period. In this regard, all staff will be required to fill the Company�s Leave form.
Annual Leave Entitlement for all employees will be 21 working days. If business conditions prevent an employee from taking their full leave entitlement in the year, the unused leave may be converted to cash with the agreement of the management. Any leave not used or converted to cash within a given year will be forfeited. 

b.)	National and Religious Holidays
The Company will observe national and religious holidays as declared by the Federal Government of Nigeria in accordance with the industry practice within Nigeria.  An employee may however be required to work on such days at the discretion of the management if the needs of the business so dictate.  

c.)	Maternity Leave
The Company will grant its female employees maternity leave in accordance with the Nigerian employment laws.  To qualify, however, a female employee must have been confirmed in the employment of the Company. Affected staff must produce a medical certificate duly signed by a registered medical practitioner stating their expected date of delivery at least four (4) weeks before submitting application for maternity leave.  Such employees will be paid their full salary for the duration of the maternity leave.  The duration will be a maximum of twelve (12) weeks, to be taken as the employee so desires, PROVIDED that the employee must commence the leave at least two weeks before her expected due date. It should be noted that the leave AFTER delivery may not be extended, except with the approval of the Management. 
Nursing mothers will be allowed to resume one hour late and close one hour earlier than the normal time for a period of three (3) months on resumption of duty after maternity leave.

d.)	Casual Leave
Members of staff may be granted not more than five days casual leave within a year. Granting of casual leave is at the sole discretion of Management. Any application for casual leave must be recommended by the employee�s supervisor and approved by management. Where the cause of the leave is the event of serious illness/ death of an immediate family member or other approved circumstances, the casual leave will be non-deductible from the annual leave entitlement. It will however be deductible for reasons other than those stated above e.g. the wedding or death of a non-immediate family member. 

e.)	Leave Of Absence
Employees are entitled to a non-deductible leave in the instance of special circumstances demanding absence from work. The special circumstances applicable will be reviewed and approval granted at the discretion of management in the interest of both the employee and the Company.

f.)	Sick Leave Benefits
In the event of an employee�s inability to come to work due to ill health, sick leave may be granted on the written recommendation of a medical practitioner. During absence, all confirmed employees would receive their salaries up to a maximum duration as agreed between the employee and company, for as long as the illness is not due to self-inflicted injuries, venereal disease, alcoholism or drug addiction.







Chapter 7: EMPLOYEE MISCONDUCT & GRIEVANCE 

7.1	Employee Conduct & Work Rules
To ensure orderly operations and provide the best possible work environment, the company expects employees to follow rules of conduct that will protect the interests and safety of all employees and the organization. It is not possible to list all the forms of behavior that are considered unacceptable in the workplace. The following are examples of infractions of rules of conduct that may result in disciplinary action, up to and including termination of employment:
� Theft or inappropriate removal or possession of property
� Falsification of timekeeping records
� Working under the influence of alcohol or illegal drugs
� Possession, distribution, sale, transfer, or use of alcohol or illegal drugs in the workplace, while on duty
� Fighting or threatening violence in the workplace
� Boisterous or disruptive activity in the workplace
� Negligence or improper conduct leading to damage of property
� Insubordination or other disrespectful conduct
� Violation of safety or health rules
� Sexual or other unlawful or unwelcome harassment
� Possession of dangerous or unauthorized materials, such as explosives or firearms, in the workplace
� Excessive absenteeism or any absence without notice
� Unauthorized use of telephones, mail system, or equipment
� Unauthorized disclosure of business "secrets" or confidential information
� Violation of personnel policies
� Unsatisfactory performance or conduct

7.2	Sexual & Other Unlawful Harassment
The company is committed to providing a work environment that is free from all forms of discrimination and conduct that can be considered harassing, coercive, or disruptive, including sexual harassment. Actions, words, jokes, or comments based on an individual's sex, race, color, national origin, age, religion, disability, or any other legally protected characteristic will not be tolerated.

Sexual harassment is defined as unwanted sexual advances, or visual, verbal, or physical conduct of a sexual nature. This definition includes many forms of offensive behavior and includes gender-based harassment of a person of the same sex as the harasser. 
Unwelcome sexual advances (either verbal or physical), requests for sexual favors, and other verbal or physical conduct of a sexual nature constitute sexual harassment when:
(1) Submission to such conduct is made either explicitly or implicitly a term or condition of employment;
(2) Submission or rejection of the conduct is used as a basis for making employment decisions; or,
(3) the conduct has the purpose or effect of interfering with work performance or creating an intimidating, hostile, or offensive work environment.
If you experience or witness sexual or other unlawful harassment in the workplace, report it immediately to your supervisor. If the supervisor is unavailable or you believe it would be inappropriate to contact that person, you should immediately contact any other member of management. You can raise concerns and make reports without fear of reprisal or retaliation. All allegations of sexual harassment will be quickly and discreetly investigated. To the extent possible, your confidentiality and that of any witnesses and the alleged harasser will be protected against unnecessary disclosure. When the investigation is completed, you will be informed of the outcome of the investigation.
 Anyone engaging in sexual or other unlawful harassment will be subject to disciplinary action, up to and including termination of employment.The Company is committed to ensuring due process for all staff in resolving all matters of misconduct.

7.3	Types of Disciplinary Action
Although employment with the company is based on mutual consent and both the employee and the company have the right to terminate employment at will, with or without cause or advance notice, the company may use progressive discipline at its discretion.
However, devotion to duty by all employees will be total while in employment. Every employee will obey and observe the instructions of the Company. There will be no conflict of interests.
Improper conduct by employees will be sanctioned. The Company reserves the right to invoke the following disciplinary measures in cases of staff misconduct:

�	Verbal query/caution 
�	Written query/caution
�	Surcharge in lieu of losses
�	Suspension with or without pay
�	Withholding of Annual Increment
�	Dismissal/Termination of Employment

Disciplinary action may call for any of the above mentioned measures depending on the severity of the problem and the number of occurrences. There may be circumstances when one or more steps are bypassed.
Progressive discipline means that, with respect to most disciplinary problems, these steps will normally be followed: a first offense may call for a verbal warning; a next offense may be followed by a written warning; another offense may lead to a suspension; and, still another offense may then lead to termination of employment.
The Company recognizes that there are certain types of employee problems that are serious enough to justify either a suspension, or, in extreme situations, termination of employment, without going through the usual progressive discipline steps. By using progressive discipline, we hope that most employee problems can be corrected at an early stage, benefiting both the employee and the company.

7.4  Problem Resolution & Grievance Practices
Ensure utmost transparency in the resolution of grievance issues, while maintaining a total respect for the individual. The Company will support processes and practices to promote timely and amicable settlement of employees� grievances within their respective functional units.

Grievance Practices
The Company is committed to providing the best possible working conditions for its employees. Part of this commitment is encouraging an open and frank atmosphere in which any problem, complaint, suggestion, or question receives a timely response from the management. The company strives to ensure fair and honest treatment of all employees. Supervisors, managers, and employees are expected to treat each other with mutual respect. Employees are encouraged to offer positive and constructive criticism. If employees disagree with established rules of conduct, policies, or practices, they can express their concern through the problem resolution procedure. No employee will be penalized, formally or informally, for voicing a complaint in a reasonable manner, or for using the problem resolution procedure.


Chapter 8: USE OF COMPANY PROPERTY AND GENERAL PROVISIONS

8.1	Use of Company Property
a)	Use of Phone 
To ensure effective telephone communications, employees should always use an approved greeting and speak in a courteous and professional manner. Please confirm information received from the caller, and hang up only after the caller has done so.

b)	Use of Equipment
Equipment essential in accomplishing job duties is often expensive and may be difficult to replace. When using property, employees are expected to exercise care, perform required maintenance, and follow all operating instructions, safety standards, and guidelines. Please notify the supervisor if any equipment, machines, or tools appear to be damaged, defective, or in need of repair. Prompt reporting of damages, defects, and the need for repairs could prevent deterioration of equipment and possible injury to employees or others. The supervisor can answer any questions about an employee's responsibility for maintenance and care of equipment used on the job.
The improper, careless, negligent, destructive, or unsafe use or operation of equipment can result in disciplinary action, up to and including termination of employment.

c)	Computer and E-mail Usage
Computers, computer files, the e-mail system, and software furnished to employees are company property intended for business use. Employees should not use a password, access a file, or retrieve any stored communication without authorization. To ensure compliance with this policy, computer and e-mail usage may be monitored. 

d)	Internet Usage
Internet access to global electronic information resources on the World Wide Web is provided by the company to assist employees in obtaining work-related data and technology. The following guidelines have been established to help ensure responsible and productive Internet usage. 
Employees should always ensure that the business information contained in Internet e-mail messages and other transmissions is accurate, appropriate, ethical, and lawful. The equipment, services, and technology provided to access the Internet remain at all times the property of the company. As such, the employer reserves the right to monitor Internet traffic, and retrieve and read any data composed, sent, or received through our online connections and stored in our computer systems. Data that is composed, transmitted, accessed, or received via the Internet must not contain content that could be considered discriminatory, offensive, obscene, threatening, harassing, intimidating, or disruptive to any employee or other person. Examples of unacceptable content may include, but are not limited to, sexual comments or images, racial slurs, gender-specific comments, or any other comments or images that could reasonably offend someone on the basis of race, age, sex, religious or political beliefs, national origin, disability, sexual orientation, or any other characteristic protected by law.

The unauthorized use, installation, copying, or distribution of copyrighted, trademarked, or patented material on the Internet is expressly prohibited. As a rule, if an employee did not create the material, does not own the rights to it, or has not gotten authorization for its use, it should not be put on the Internet. Employees are also responsible for ensuring that the person sending any material over the Internet has the appropriate distribution rights. Internet users should take the necessary anti-virus precautions before downloading or copying any file from the Internet. All downloaded files are to be checked for viruses; all compressed files are to be checked before and after decompression.

Abuse of the Internet access provided by the company in violation of law or company policies will result in disciplinary action, up to and including termination of employment. Employees may also be held personally liable for any violations of this policy. The following behaviors are examples of previously stated or additional actions and activities that are prohibited and can result in disciplinary action:

� Sending or posting discriminatory, harassing, or threatening messages or images
� Using the organization's time and resources for personal gain
� Stealing, using, or disclosing someone else's code or password without authorization
� Copying, pirating, or downloading software and electronic files without permission
� Sending or posting confidential material, trade secrets, or proprietary information outside of the organization
� Violating copyright law
� Failing to observe licensing agreements
� Engaging in unauthorized transactions that may incur a cost to the organization or initiate unwanted Internet services and transmissions
� Sending or posting messages or material that could damage the organization's image or reputation
� Participating in the viewing or exchange of pornography or obscene materials
� Sending or posting messages that defame or slander other individuals
� Attempting to break into the computer system of another organization or person
� Refusing to cooperate with a security investigation
� Sending or posting chain letters, solicitations, or advertisements not related to business purposes or activities
� Using the Internet for political causes or activities, religious activities, or any sort of gambling
� Jeopardizing the security of the organization's electronic communications systems
� Sending or posting messages that disparage another organization's products or services
� Passing off personal views as representing those of the organization
� Sending anonymous e-mail messages
� Engaging in any other illegal activities

e)	Return of Property
Employees are responsible for all the property, materials, or written information issued to them or in their possession or control. Employees must return all company property immediately upon request or upon termination of employment. Where permitted by applicable laws, the company may withhold from the employee's check or final paycheck the cost of any items that are not returned when required. The company may also take all action deemed appropriate to recover or protect its property.

8.2	Emergency Closing
At times, emergencies such as severe weather, fires or power failures, can disrupt company operations. In extreme cases, these circumstances may require the closing of a work facility. When operations are officially closed due to emergency conditions, the time off from scheduled work will be unpaid. However, with supervisory approval, employees may use available paid leave time, such as unused vacation benefits.
In cases where an emergency closing is not authorized, employees who fail to report for work will not be paid for the time off. Employees in essential operations may be asked to work on a day when operations are officially closed.
In these circumstances, employees who work will receive regular pay.

8.3	Business Travel Expenses
The company will reimburse employees for reasonable business travel expenses incurred while on assignments away from the normal work location. The immediate supervisor must approve all business travel in advance. Employees whose travel plans have been approved should make all travel arrangements through company accounts department. When approved, the actual costs of travel, meals, lodging, will be paid and the company will reimburse other expenses directly related to accomplishing business travel objectives. Employees are expected to limit expenses to reasonable amounts. Expenses that generally will be reimbursed include the following:
� Airfare economy class or the lowest available fare.
� Car rental fees, only for compact or mid-sized cars.
� Fares for shuttle or airport bus service, where available; costs of public transportation for other ground travel.
� Taxi fares, only when there is no less expensive alternative..
� Cost of standard accommodations in low to mid-priced hotels, motels, or similar lodgings.
� Cost of meals, no more lavish than would be eaten at the employee's own expense.
� A fixed amount is stipulated by accounts for phone calls daily
(Personal entertainment and personal care items are not reimbursed.)

Employees are encouraged to use their cellular telephone or calling cards when traveling, as hotel charges are excessive.
Employees who are involved in an accident while traveling on business must promptly report the incident to their immediate supervisor. Vehicles owned, leased, or rented by the company may not be used for personal use without prior approval.
When travel is completed, employees should submit completed travel expense reports. Receipts for all individual expenses should accompany reports.
Employees should contact their supervisor for guidance and assistance on procedures related to travel arrangements, travel advances, expense reports, reimbursement for specific expenses, or any other business travel issues.
Abuse of this business travel expenses policy, including falsifying expense reports to reflect costs not incurred by the employee, can be grounds for disciplinary action, up to and including termination of employment.

8.4	Visitors in the Work Place
To provide for the safety and security of employees and the facilities, only authorized visitors are allowed in the workplace.
Restricting unauthorized visitors helps maintain safety standards, protects against theft, ensures security of equipment, protects confidential information, safeguards employee welfare, and avoids potential distractions and disturbances.



8.5	Workplace Monitoring
Workplace monitoring may be conducted by the company to ensure quality control, employee safety, security, and customer satisfaction.
Employees who regularly communicate with customers may have their telephone conversations monitored or recorded. Telephone monitoring is used to identify and correct performance problems through targeted training.
Computers furnished to employees are the property of the company. As such, computer usage and files may be monitored or accessed. Employees can request access to information gathered through workplace monitoring that may impact employment decisions. Access will be granted unless there is a legitimate business reason to protect confidentiality or an ongoing investigation.
Because the company is sensitive to the legitimate privacy rights of employees, every effort will be made to guarantee that workplace monitoring is done in an ethical and respectful manner.

8.6	Absenteeism
A. Unauthorized absence on work days
Employees are required to obtain advance approval for leave from their supervisor. Violations of established policy and procedure concerning leave approval (except in emergency situations which are reviewed on a case-by-case basis) are considered unauthorized absence.

B. Abandonment of Job
Employees are expected to be at their designated work station during regular work hours (except for lunch breaks as defined by supervisor).

8.7	Attendance & Punctuality
To maintain a safe and productive work environment, employees are expected to be reliable and to be punctual in reporting for scheduled work. Absenteeism and tardiness place a burden on other employees and company. In the rare instances when employees cannot avoid being late to work or are unable to work as scheduled, they should notify their supervisor as soon as possible in advance of the anticipated tardiness or absence. Poor attendance and excessive tardiness are disruptive. Either may lead to disciplinary action, up to and including termination of employment.




Chapter 9: SEPARATION
Objective: The Company�s human resources policy is geared towards retaining and motivating employees that are committed, disciplined and loyal, to have a fulfilling and rewarding career in the company.
However, it is recognised that separation is inevitable in certain circumstances as provided in the policy. This can be initiated by the company or the employee or by natural circumstances beyond human control.
Below are examples of some of the most common circumstances under which employment is terminated:

� Resignation - voluntary employment termination initiated by an employee.
� Discharge - involuntary employment termination initiated by the organization.
� Layoff/Redundancy - involuntary employment termination initiated by the organization for non-disciplinary reasons.
� Retirement - voluntary employment termination initiated by the employee meeting age, length of service, and any other criteria for retirement from the organization.

9.1	Termination
Either of the parties to the employment contract is entitled to terminate this relationship at any time with notice or by payment of salary in lieu of such notice as applicable to the individual employee. An officer whose employment is terminated under this section shall be entitled to his entire terminal benefits as provided in his employment letter or Contract. 

9.2	Voluntary Resignation        
In the event of resignation, the immediate supervisor of the resigning employee will discuss with the employee, before noting acceptance by appending initials on the letter of resignation, and then submitting to the relevant member of management for review and comments.
The staff member is entitled to his/her salary and all allowances due as of the time of resignation. 
�	Any outstanding leave and therefore pay in lieu of such leave.
�	Other Entitlements as may be approved from time to time.




9.3	Redundancy
Redundancy means involuntary and permanent loss of employment as a result of excess manpower, lack of work, elimination of a job function or as a result of company restructuring or technological changes that is not due to the fault of the staff.
The Company does not anticipate redundancy, but should it be necessary, it will determine those to be declared redundant by applying the principle of �first in, last out� to those who, in the opinion of the Company are of comparable skills and efficiency. 
Entitlements:
In event of redundancy however, the staff is entitled to:
o	One month�s notice or salary in lieu of notice.
o	Salary and all allowances due as of the date of redundancy.

9.4	Accidents while on Official Duty
In the event that an accident occurs while an employee is on Official duty resulting in bodily injury and damage, the Company shall make reasonable payments to the employee or on behalf of the employee to cover the whole or part of the medical expenses. The limit of payments in respect of medical expenses over are at the sole discretion of the Company.

9.5	Death
If an employee dies while in the service of the Company, the Company will pay reasonable burial expense to assist the deceased�s family. Entitlements are as outlined under Voluntary Resignation above.
IF YOU HAVE ANY COMMENTS OR SUGGESTIONS REGARDING THE CONTENT OF THE EMPLOYEE HANDBOOK, PLEASE DIRECT THEM TO THE MANAGEMENT
WISHING YOU A LONG AND REWARDING CAREER!	





APPENDIX A
ORGANISATIONAL STRUCTURE








References
1a. What is the name of the organization?
1b. what are the visions of the organization?
1c. what is the mission of the organization?
1d. What are the values of the organization?
            </div>
        </div>
        );
  }  
}

export default store =>
  ({
    path: `/docseo`,
    getComponent(next, cb) {
      cb(
        null,
        connect(state => ({ state }),
          dispatch => ({
            showConfirmModal: (params) => dispatch(showConfirmModal(params)),
            all: (kwargs, callback) => dispatch(all(kwargs, callback))
          }))(DocSeo)
      );
    }
  });
