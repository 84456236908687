import { post, delete_ } from './request';

export function login(json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SESSION_LOGIN',
    });

    post(`session`, { json })
      .then(result => {
        if (!result.response.ok) {
          callback(result.json);
          dispatch({ type: 'SESSION_ERROR', error: result.json });
        } else {
          callback(null, result.json);
          dispatch({ type: 'SESSION_LOGIN_OK', session: result.json });
        }
      })
      .catch(error => {
        dispatch({ type: 'SESSION_ERROR', error });
        callback(error);
      });
  };
}

export function logout(json, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SESSION_LOGOUT',
    });
    dispatch({ type: 'USER_ACCOUNT_OK', account: null });
    delete_(`session`)
      .then(result => {
        if (!result.response.ok) {
          callback(result.json);
          // dispatch({ type: 'USER_ACCOUNT_OK', account: null });
          dispatch({
            type: 'SESSION_ERROR',
            error: result.response.statusText,
          });
        } else {
          callback(null, result.json);
          // dispatch({ type: 'USER_ACCOUNT_OK', account: null });
          dispatch({ type: 'SESSION_LOGOUT_OK', session: result.json });
        }
      })
      .catch(error => {
        // dispatch({ type: 'USER_ACCOUNT_OK', account: null });
        dispatch({ type: 'SESSION_ERROR', error });
        callback(error);
      });
  };
}

export function sendAuthCode(json) {
  return (dispatch, getState) => {
    dispatch({
      type: 'SERVICE_PARTNER_SEND_AUTH_CODE',
    });
    return post('servicepartners/auth/code', { json })
      .then(result => {
        if (!result.response.ok) {
          dispatch({ type: 'SESSION_ERROR', error: result.json });
          return Promise.reject('Unable to get authorization code');
        } else {
          dispatch({ type: 'AUTH_CODE_OK' });
          return Promise.resolve({});
        }
      })
      .catch(error => {
        dispatch({ type: 'SESSION_ERROR', error });
        return Promise.reject(error);
      });
  };
}

export function servicePartnerLogin(json) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'SESSION_LOGIN',
    });
    return post('servicepartners/auth/login', { json })
      .then(result => {
        if (!result.response.ok) {
          dispatch({ type: 'SESSION_ERROR', error: result.json });
          return Promise.reject('Unable to login user');
        } else {
          dispatch({ type: 'SESSION_LOGIN_OK', session: result.json });
          console.log(result.json);
          return Promise.resolve(result.json);
        }
      })
      .catch(error => {
        dispatch({ type: 'SESSION_ERROR', error });
        return Promise.reject(error);
      });
  };
}
